import React from 'react';
import _ from "lodash";

const TimelineBloc = (props) => {
    const {type, expectedPeriod, periods, totalMonths, formatDate} = props

    return (
        <>
            {periods && periods.map((period, index) => {
                const startDate = formatDate(period.start);
                const endDate = formatDate(period.end);
                // Calculer la largeur de la période en pourcentage par rapport à la période totale
                let periodWidth =  _.round(endDate.diff(startDate, 'days') / totalMonths * 100, 2);
                periodWidth = periodWidth < 0.05 ? 0.05 : periodWidth

                // Calculer la position de la période par rapport au début de la timeline
                const periodPosition = _.round(startDate.diff(formatDate(expectedPeriod.start), 'days') / totalMonths * 100, 2);

                return (
                    <li className={`${type === 'ok' ? 'timeline-ok' : 'timeline-ko'}`}
                        key={index}
                        style={{
                            display: `inline-block`,
                            width: `${periodWidth}%`,
                            left: `${periodPosition}%`,
                        }}>
                        <span className={'d-inline-block text-center w-100'}>
                            <h2 className={'d-inline-block'}>{period.start}{period.end !== period.start ? ` - ${period.end}` : ''}</h2>
                            <h3>Période {type === 'ok' ? 'reçue' : 'manquante'}</h3>
                        </span>
                    </li>
                );
            })}
        </>
    );
};

export default TimelineBloc;