import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {reduxForm, change, formValueSelector, clearFields} from 'redux-form'
import {ADD_ACTIVITY, postAddActivity} from '../../../../../redux/actions/app/activity/activity.actions'
import AddActivity from './AddActivity'
import {getLoadingEntity} from '../../../../../redux/selectors/ui/ui.selectors'
import validate from "./validate";
import moment from "moment";
import {getBelairName, getUserRoles} from "../../../../../redux/selectors/user/user.selectors";
import _ from "lodash";
import {
    postTiersActivityLink,
    tiersGetLink
} from "../../../../../redux/selectors/links/links.selectors";
import {getTiers, TIERS} from "../../../../../redux/actions/app/tiers/tiers.actions";
import {getTiersData} from "../../../../../redux/selectors/tiers/tiers.selectors";

import ErrorHttpComponent from "../../../../Commun/Error/ErrorHttpComponent";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector(`addActivityForm`)
    const tiers = getTiersData(state)
    const loaded = getLoadingEntity(state, TIERS) === false

    return {
        loading: getLoadingEntity(state, ADD_ACTIVITY),
        url: _.replace(postTiersActivityLink(state), '{id}', ownProps.match.params.id),
        title: loaded ? `${tiers.belair_id} - ${tiers.titre} ${tiers.name}` : '',
        uri_tiers: tiersGetLink(state),
        idTiers: ownProps.match.params.id,
        categorie: selector(state, 'categorie'),
        paniere: selector(state, 'paniere'),
        type: selector(state, 'type'),
        initialValues: {
            'date_rappel': moment(),
        },
        nameConseiller: getBelairName(state),
        roles: getUserRoles(state),
        activityType: 'tiers',
        isAuthorizedToCreate: true
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getTiers: ({query, id}) => getTiers({query, id}),
    submitPostAddActivity: ({form, query}) => postAddActivity({form, query}),
    onChangeValue: (name, file) => change('addActivityForm', name, file),
    unsetValue: (field) => clearFields('addActivityForm', false, false, field),
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {submitPostAddActivity, getTiers} = dispatchProps
    const {url, uri_tiers, idTiers} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => {
            getTiers({query: _.replace(uri_tiers, '{id}', idTiers), id: idTiers})
        },
        onSubmit: (form) => submitPostAddActivity({form, query: url})
    }
}
const AddActivityTiersContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'addActivityForm',
        validate
    }),
    loader({ErrorIndicator: ErrorHttpComponent})
)(AddActivity)

export default AddActivityTiersContainer
