import React, {Component} from 'react';
import moment from "moment";
import VirementSinistreListContainer from "./VirementSinistreList/VirementSinistreListContainer";
import darkLoupe from "../../../../../assets/img/icons/loupe_dark.png";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import {LocalizationProvider} from "@mui/x-date-pickers";

class VirementsSinistres extends Component {
    constructor(props) {
        super(props);
        localStorage.getItem('sort_vir_sinistre') === null && localStorage.setItem('sort_vir_sinistre', 'date')
        this.state = {
            query: this.props.uri,
            focused: false,
            dateVirement: localStorage.getItem('date_vir_sinistre') ? moment(localStorage.getItem('date_vir_sinistre'), 'DD/MM/YYYY') : null,
            numSinistre: localStorage.getItem('num_sinistre'),
        }
    }

    handleChangeSort = (e) => {
        localStorage.setItem('sort_vir_sinistre', e.target.value)
        this.props.getVirements({
            query: this.state.query,
            sortVirement: localStorage.getItem('sort_vir_sinistre')
        });
    }

    handleChangeFilter = ({name, value}) => {
        localStorage.setItem(name, value)
        this.props.getVirements({
            query: this.state.query,
            sortVirement: localStorage.getItem('sort_vir_sinistre'),
            [name]: value
        });
    }

    handleChangeDates = (date) => {
        localStorage.setItem('date_vir_sinistre', moment(date).format('L'))
        this.setState({
            dateVirement: date
        });
        this.props.getVirements({
            query: this.state.query,
            sortVirement: localStorage.getItem('sort_vir_sinistre'),
            dateVirement: moment(date).format('L') || '',
        });
    }

    resetFilters = () => {
        localStorage.setItem('date_vir_sinistre', '')
        localStorage.setItem('montant_vir_sinistre', '')
        localStorage.setItem('beneficiaires_prelevements_vir_sinistre', '')
        localStorage.setItem('num_sinistre', '')
        this.setState({
            dateVirement: null
        });
        this.props.getVirements({
            query: this.state.query,
            sortVirement: localStorage.getItem('sort_vir_sinistre'),
            dateVirement: '',
        });
    }

    render() {
        const {meta} = this.props
        return (
            <div className={'virements-container tasks-container container'}>
                <div className={'row'}>
                    <div className={'col-12'}>
                        <div className={'row'}>
                            <div className={'col-8'}>
                                <h1 className={'title-primary title-virements'}>Virements en attente</h1>
                                {meta.total > 0
                                    ? <small className={'bold text-uppercase'}>{meta.total} virements à valider</small>
                                    : <small className={'bold text-uppercase'}>0 virement à valider</small>
                                }
                            </div>
                            <div className={'col-4 notifications text-right'} style={{marginTop:0}}>
                                <p><span className={'task'}>{meta.total_validate}</span> virements validés aujourd'hui</p>
                            </div>
                        </div>
                    </div>
                    <div className={'col-12 filters'}>
                        <div className={'content'}>
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
                                <DatePicker
                                    className={'mr-1 small date-picker'}
                                    label='Date des virements'
                                    value={this.state.dateVirement}
                                    onChange={(newValue) => {
                                        this.handleChangeDates(newValue)
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            <select
                                name="filter_montant_vir_sinistre"
                                className={'small-select select-filter-tasks custom-select mb-0'}
                                value={localStorage.getItem('montant_vir_sinistre') || ''}
                                onChange={e => this.handleChangeFilter({
                                    name: e.target.name.substring(7),
                                    value: e.target.value
                                })}
                            >
                                <option value={''}>Montant</option>
                                <option value="1">De 0 € à 1000 €</option>
                                <option value="2">Entre 1000 € et 2500 €</option>
                                <option value="3">Entre 2500 € et 5000 €</option>
                                <option value="4">Entre 5000 € et 10000 €</option>
                                <option value="5">Plus de 10000 €</option>
                            </select>
                            <div style={{width: '17%'}} className={'d-inline-block'}>
                                <div className={'d-flex'}>
                                    <input
                                        name="filter_num_sinistre"
                                        value={this.state.numSinistre}
                                        onChange={e => this.setState({numSinistre: e.target.value})}
                                        className={'filters small-input with-icon select-filter-tasks'}
                                        type="text"
                                        placeholder="N° de sinistre"
                                        ref={input => this.inputNumSinistre = input}
                                    />
                                    <span
                                        onClick={() => this.handleChangeFilter({
                                            name: this.inputNumSinistre.name.substring(7),
                                            value: this.inputNumSinistre.value
                                        })}
                                        className={'icon-small-input'}
                                    >
                                        <img src={darkLoupe} alt="Loupe"/>
                                    </span>
                                </div>
                            </div>

                            <select
                                name="filter_beneficiaires_prelevements_vir_sinistre"
                                className={'small-select select-filter-tasks custom-select mb-0'}
                                value={localStorage.getItem('beneficiaires_prelevements_vir_sinistre') || ''}
                                onChange={e => this.handleChangeFilter({
                                    name: e.target.name.substring(7),
                                    value: e.target.value
                                })}
                            >
                                <option value={''}>Tous les bénéficiaires</option>
                                <option value="reparateur">Réparateur</option>
                                <option value="client-prvt">Clients en prélèvement</option>
                                <option value="client-sans-prvt">Clients non prélevés</option>
                            </select>

                            <select
                                name="sort_vir_sinistre"
                                onChange={this.handleChangeSort}
                                className={'small-select select-sort-tasks custom-select mb-0'}
                                value={localStorage.getItem('sort_vir_sinistre') || ''}
                            >
                                <option value="date">Du + ancien au + récent</option>
                                <option value="-date">Du + récent au + ancien</option>
                            </select>
                            <div>
                                <small onClick={this.resetFilters}>Réinitialiser les filtres</small>
                            </div>
                        </div>
                    </div>
                </div>

                <VirementSinistreListContainer/>

            </div>
        );
    };
}

VirementsSinistres.defaultProps = {
    meta: {
        total: 0
    }
}

export default VirementsSinistres;
