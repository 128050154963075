export const COMMISSIONS = '[Commissions]'

export const GET_COMMISSIONS = `${COMMISSIONS} Get`
export const SET_COMMISSIONS = `${COMMISSIONS} Set`

export const getCommissions = ({query}) => ({
    type: GET_COMMISSIONS,
    payload: {
        data: query
    }
})

export const setCommissions = ({commissions}) => ({
    type: SET_COMMISSIONS,
    payload: {
        data: commissions
    }
})
