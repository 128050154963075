import React, {Component, Fragment} from 'react'
import VirementTableCard from "../../../../../Commun/Card/VirementTableCard";
import _ from 'lodash'
import Spinner from "../../../../../Commun/Spinner/Spinner";

class VirementsList extends Component {
    constructor(props) {
        super(props)
        localStorage.getItem('sort_virements') === null && localStorage.setItem('sort', 'date')
        this.state = {
            date: localStorage.getItem('date_virements') ? localStorage.getItem('date_virements') : null,
            disabled: false,
        }
    }

    handleValidateVirements = () => {
        this.setState({
            disabled: true,
        });
        this.props.postValidateListVirements({
            virements: _.map(this.props.virements, v => v.id_encaissement)
        })
    }

    render() {
        const {virements, meta, loading, confirmPostVirement, getVirementsProd} = this.props
        const {date, disabled} = this.state
        const total = _.sumBy(virements, item => parseFloat(_.replace(item.montant, ',', '.')))

        return (
            <Fragment>
                {
                    confirmPostVirement ? <div className="alert alert-success text-center" role="alert">
                        <h1 className="title-primary d-block mb-3">Les virements ont bien été enregistrés</h1>
                        <button
                            className={'btn btn-ternary'}
                            onClick={() => getVirementsProd()}
                        >
                            Charger les prochains virements
                        </button>
                    </div> : virements && meta.total > 0 ?
                        <Fragment>
                            <div className={'col-12'}>
                                <div className={'row'}>
                                    <div className={'col-6'}>
                                        {date &&
                                            <h2 className={'title-secondary'}>Remboursements demandés le {date}</h2>}
                                    </div>
                                    <div className={'col-4 offset-md-2'}>
                                        <h3 className={'title-primary float-right'}>Montant global
                                            : {Number(total).toFixed(2)}€</h3>
                                    </div>
                                </div>
                            </div>
                            <table className="table table-virements">
                                <thead>
                                <tr>
                                    <th scope="col">Date d'enc.</th>
                                    <th scope="col">N° de police</th>
                                    <th scope="col">Situation du contrat</th>
                                    <th scope="col">Date AV/RES</th>
                                    <th scope="col">Motif</th>
                                    <th scope="col">Montant</th>
                                    <th>&nbsp;</th>
                                </tr>
                                </thead>
                                <tbody>
                                {virements.map((virement, index) => (
                                    <VirementTableCard virement={virement} key={index}/>
                                ))}
                                </tbody>
                            </table>
                            <div className={'text-center header-row'}>
                                {
                                    (loading || disabled) ? <Spinner/> : <button
                                        className={'btn btn-ternary header-row'}
                                        onClick={() => {
                                            this.handleValidateVirements(virements)
                                        }}
                                        disabled={loading || disabled}
                                    >
                                        Valider les virements
                                    </button>
                                }
                            </div>
                        </Fragment>
                        : <p className={'text-center'}>Vous n'avez pas de virements en attente de validation</p>

                }
            </Fragment>
        )
    }
}

export default VirementsList
