export const REDUXFORM = '[Redux form]'

export const RESET_FORM = `${REDUXFORM} Reset form`

export const resetForm = ({formName, entity}) => ({
    type: `${RESET_FORM} ${entity}`,
    payload: {
        data: formName
    }
})
