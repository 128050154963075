import {connect} from 'react-redux'
import {change, reduxForm} from "redux-form";
import {bindActionCreators, compose} from 'redux'
import DocumentItem from './DocumentItem'
import moment from 'moment'
import {putDocuments} from '../../../../../../../../redux/actions/app/documents/documents.actions'
import _ from "lodash";
import {
    getContractId,
    getContractIdSouscripteur
} from "../../../../../../../../redux/selectors/contract/contract.selectors";
import {
    postContractDocumentUpdate
} from "../../../../../../../../redux/selectors/links/links.selectors";

const mapStateToProps = (state, ownProps) => {
    return {
        tiersId: getContractIdSouscripteur(state),
        risqueNum: ownProps.risqueNum,
        accidentNum: ownProps.document.id_sinistre,
        uri: postContractDocumentUpdate(state),
        contractId: getContractId(state),
        formName: ownProps.form,
        initialValues: {
            "commentaire": ownProps.document.commentaire,
            "dateRecu": ownProps.document.dateReception ? moment(ownProps.document.dateReception).format('L') : "",
            "dateTraitement": ownProps.document.dateTraitement ? moment(ownProps.document.dateTraitement).format('L') : ""
        }
    }
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
    changeValue: (field, value) => change(ownProps.form, field, value),
    putDocuments: ({tiers, risque, accident, form, query, formName}) => putDocuments({
        tiers,
        risque,
        accident,
        form,
        query,
        formName
    }),
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {tiersId, risqueNum, accidentNum, uri, contractId, formName} = stateProps
    const {putDocuments} = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form) => putDocuments({
            tiers: tiersId,
            risque: risqueNum,
            accident: accidentNum,
            form,
            query: _.replace(uri, '{id}', contractId),
            formName
        }),
    }
}

const DocumentItemContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm()
)(DocumentItem)

export default DocumentItemContainer



