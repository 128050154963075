import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {
    POST_CHANGE_FRACTIONNEMENT,
    CHANGE_FRACTIONNEMENT, setChangeFractionnement,
} from "../../../actions/app/change_fractionnement/change_fractionnement.actions";


export const changeFractionnementMiddleware = () => next => action => {
    next(action)

    const {payload} = action
    let message = null

    switch (action.type) {
        case POST_CHANGE_FRACTIONNEMENT:
            next([
                apiRequest({
                    body: (payload.body),
                    method: 'POST',
                    url: action.payload.data,
                    entity: CHANGE_FRACTIONNEMENT
                }),
                setLoader({state: true, entity: CHANGE_FRACTIONNEMENT})
            ])
            break

        case `${CHANGE_FRACTIONNEMENT} ${API_SUCCESS}`:
            message = `<p>Le fractionnement a bien été modifié</p>`
            next([
                setChangeFractionnement({state: true}),
                setNotification({
                    entity: CHANGE_FRACTIONNEMENT,
                    body: message,
                    type: 'success',
                    title: 'Fractionnement modifié!'
                }),
                setLoader({state: false, entity: CHANGE_FRACTIONNEMENT}),
                setError({state: false, entity: CHANGE_FRACTIONNEMENT}),
            ])
            break

        case `${CHANGE_FRACTIONNEMENT} ${API_ERROR}`:
            message = `
                <p>Votre demande n'a pas pu être envoyée. ${payload.data.response.data?.message ?? ''}</p>
            `
            next([
                setNotification({
                    entity: CHANGE_FRACTIONNEMENT,
                    body: message,
                    type: 'error',
                    title: 'Erreur lors de l\'edition !',
                    timeout: '0'
                }),
                setError({state: true, entity: CHANGE_FRACTIONNEMENT}),
                setLoader({state: false, entity: CHANGE_FRACTIONNEMENT}),
            ])
            break

        default:
            break
    }
    return null
}
