import React from 'react';
import moment from "moment/moment";
import {Link} from "react-router-dom";

const DashboardTeleventeTasksContent = (props) => {
    const {tasks, meta, links, getDashboardTelevente} = props

    const handleChangePage = (query) => {
        getDashboardTelevente({query: query})
    }

    return (
        <div>

            <div className={'row'}>
                <div className={'col'}>
                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">Sujet</th>
                            <th scope="col">Créé le</th>
                            <th scope="col">Mis à jour le</th>
                            <th scope="col">Statut</th>
                            <th scope="col">Détails</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tasks.map((task, index) =>
                            <tr key={index}>
                                <td>{task.subject}</td>
                                <td>{moment(task.createdat).format('DD/MM/YYYY HH:mm')}</td>
                                <td>{moment(task.updatedat).format('DD/MM/YYYY HH:mm')}</td>
                                <td>{task.statut}</td>
                                <td><Link to={`/dashboard-tlv/tache/${task.id}`} className={'btn btn-more'}/></td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
            {meta &&
                <div className={'row'}>
                    <nav>
                        <p>{meta.to}/{meta.total} tâches</p>
                        <ul className="pagination">
                            {meta.current_page > 2 &&
                                <li className="page-item" onClick={() => handleChangePage(links.first)}><span
                                    className="page-link">{`<<`}</span></li>}
                            {meta.current_page > 1 &&
                                <li className="page-item" onClick={() => handleChangePage(links.prev)}><span
                                    className="page-link"> {`<`} </span></li>}
                            <li className="page-item"><span className={`page-link current`}>{meta.current_page}</span>
                            </li>
                            {meta.last_page > meta.current_page &&
                                <li className="page-item" onClick={() => handleChangePage(links.next)}><span
                                    className="page-link">{`>`}</span></li>}
                            {meta.last_page >= meta.current_page + 2 &&
                                <li className="page-item" onClick={() => handleChangePage(links.last)}><span
                                    className="page-link">{`>>`}</span></li>}
                        </ul>
                    </nav>
                </div>
            }
        </div>
    );
};

export default DashboardTeleventeTasksContent;
