import {connect} from 'react-redux'
import {formValueSelector} from 'redux-form'
import Documents from './Documents'

const mapStateToProps = state => {
    const selector = formValueSelector('declaration-sinistre')

    return {
        categorie: selector(state, 'catsinorigin')
    }
}

const DocumentsContainer = connect(mapStateToProps)(Documents)

export default DocumentsContainer
