import React, {useState, useEffect} from 'react';
import Toggle from './Toggle';

const DarkModeToggle = () => {
    const [ darkMode, setDarkMode ] = useState(localStorage.getItem('darkMode') === 'true')

    useEffect(() => {
        localStorage.setItem('darkMode', darkMode)
        if (darkMode){
            document.getElementsByTagName('body')[0].classList.remove('light-mode')
            document.getElementsByTagName('body')[0].classList.add('dark-mode')
        } else {
            document.getElementsByTagName('body')[0].classList.remove('dark-mode')
            document.getElementsByTagName('body')[0].classList.add('light-mode')
        }
    }, [darkMode])

    return (
        <div className="dark-mode-toggle float-right">
            <button type="button" onClick={() => setDarkMode(true)}>
                ☀
            </button>
            <Toggle darkMode={darkMode} setDarkMode={setDarkMode}/>
            <button type="button" onClick={() => setDarkMode(false)}>
                ☾
            </button>
        </div>
    );
};

export default DarkModeToggle;
