import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import GarantieItem from "./GarantieItem";
import {change, reduxForm} from "redux-form";
import _ from "lodash";
import {
    POST_GARANTIE_ACCIDENT,
    postUpdateAccidentGaranties
} from "../../../../../../redux/actions/app/accidents/accidents.actions";
import formValueSelector from "redux-form/lib/formValueSelector";
import './garantieItem.scss'
import {updateAccidentGarantieGetLink} from "../../../../../../redux/selectors/links/links.selectors";
import {getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector(ownProps.form)
    const loaded = getLoadingEntity(state, POST_GARANTIE_ACCIDENT);

    return {
        formName: ownProps.form,
        uri: updateAccidentGarantieGetLink(state),
        contractId: ownProps.contract,
        accidentId: ownProps.accident,
        code: selector(state, 'code'),
        loaded: loaded === undefined || loaded === false,
        franchiseValue: selector(state, 'franchise'),
        initialValues: {
            "code": ownProps.garantie.code || "",
            "sinistree": ownProps.garantie.sinistree === "OUI",
            "franchise": ownProps.garantie.franchise || '',
            "cabinet": ownProps.garantie.cabinet ? Number(ownProps.garantie.cabinet).toFixed(2) : '',
            "recours": ownProps.garantie.recours? Number(ownProps.garantie.recours).toFixed(2) : ''
        }
    }
}


const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
    changeValue: (field, value) => change(ownProps.form, field, value),
    postUpdateAccidentGaranties
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uri, contractId, accidentId, formName} = stateProps
    const {postUpdateAccidentGaranties} = dispatchProps


    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: form => postUpdateAccidentGaranties({
            query: _.replace(_.replace(uri, '{id}', contractId), '{ids}', accidentId),
            form,
            formName
        })
    }
}


const GarantieItemContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm()
)(GarantieItem)


export default GarantieItemContainer
