import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import Documentation from './Documentation'
import ErrorHttpComponent from '../../../../Commun/Error/ErrorHttpComponent'
import {DOCUMENTATION, getDocumentation} from '../../../../../redux/actions/app/documentation/documentation.actions'
import {getErrorEntity, getLoadingEntity} from '../../../../../redux/selectors/ui/ui.selectors'
import {DocumentationLink} from '../../../../../redux/selectors/links/links.selectors'
import {getDataDocumentation} from "../../../../../redux/selectors/support/support.selectors";
import {expandQuestion} from "../../../../../redux/actions/app/faq/faq.actions";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state) => {
    return {
        documentation: getDataDocumentation(state),
        uri: DocumentationLink(state),
        loaded: getLoadingEntity(state, DOCUMENTATION) === false,
        error: getErrorEntity(state, DOCUMENTATION)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getDocumentation: (query) => getDocumentation({query}),
    expandQuestion
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getDocumentation} = dispatchProps
    const {uri} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getDocumentation(uri)
    }
}

const DocumentationContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(Documentation)

export default DocumentationContainer
