import React from 'react';
import moment from 'moment'

const DocumentItemLight = (props) => {
    const {document, type} = props


    return (
        <tr>
            <td>{document.libelle}</td>
            <td>{document.commentaire}</td>
            {
                type === "sinistre" &&
                <td>{moment(document.dateAttendu).format('L')}</td>
            }
            {document.recu
                ? <td>{moment(document.dateReception).format('L')}</td>
                : <td><strong>En attente</strong></td>
            }
            {document.traite
                ? <td><span className={'active'}>&nbsp;</span></td>
                : <td><span className={'inactive'}>&nbsp;</span><em>En attente</em></td>
            }
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
    );
};

export default DocumentItemLight;
