import React from 'react'

const ModifDateEffet = (props) => {
    const {task: {title, description}} = props

    return (
        <div className={'container'}>
            <div className={'header-row row'}>
                <div className={'col-12'}>
                    <p className={'form-content text-center'}>
                        Type : {title}<br/>
                        Description : {description ? <span dangerouslySetInnerHTML={{__html: description}}/> : '-'}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ModifDateEffet
