import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import NavigationActivityContrats from "./NavigationActivityContrats";
import {navigationContractGetLink} from "../../../../redux/selectors/links/links.selectors";
import {getErrorEntity, getLoadingEntity} from "../../../../redux/selectors/ui/ui.selectors";
import _ from "lodash";

import ErrorHttpComponent from "../../Error/ErrorHttpComponent";
import {
    getNavigationContract,
    NAVIGATION_CONTRACT
} from "../../../../redux/actions/app/navigation_contract/navigation_contract.actions";
import {getNavigatioContractData} from "../../../../redux/selectors/navigation_contract/navigation_contract.selector";
import loader from "../../Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    return {
        uri: navigationContractGetLink(state),
        loaded: getLoadingEntity(state, NAVIGATION_CONTRACT) === false,
        error: getErrorEntity(state, NAVIGATION_CONTRACT),
        tiers_id: ownProps.tiers,
        contract_id: ownProps.current,
        navigation_contract: getNavigatioContractData(state)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getNavigationContract,
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getNavigationContract} = dispatchProps
    const {uri, tiers_id, contract_id} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getNavigationContract({query: _.replace(_.replace(uri, '{id}', tiers_id), '{ids}', contract_id)})
    }
}

const NavigationActivityContratsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(NavigationActivityContrats)


export default NavigationActivityContratsContainer
