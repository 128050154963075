import React from 'react';
import {rolesCorrespondant} from "../../../../../utils/optionsSelect";
import {Field} from "redux-form";
import TextFieldRedux from "../../../../Commun/Input/Text/TextFieldRedux";
import SelectFieldRedux from "../../../../Commun/Input/Select/SelectFieldRedux";
import Spinner from "../../../../Commun/Spinner/Spinner";
import CheckboxFieldRedux from "../../../../Commun/Input/Checkbox/CheckboxFieldRedux";


const AddCorrespondant = (props) => {
    const {handleSubmit, loading, edit, correspondantPrincipal} = props

    return (
        <div className={'contract-container container'}>
            {
                !edit &&
                <div className={'row return-content'}>
                    <div className={'col'}>
                        <button onClick={() => props.history.go(-1)} className={'btn btn-return'}>Retour</button>
                    </div>
                </div>
            }
            <div className={'row header-row'}>
                <div className={'col-12'}>
                    <form onSubmit={handleSubmit} className={'form-content'}>
                        <div className="row">
                            <div className="col">
                                <Field
                                    name='role'
                                    component={SelectFieldRedux}
                                    label={'Rôle'}
                                >
                                    <option value={""}>Sélectionnez un rôle</option>
                                    {rolesCorrespondant.map((role, index) => (
                                        <option key={index} value={role.label}>{role.label}</option>
                                    ))}
                                </Field>
                            </div>
                            <div className="col">
                                <Field
                                    name={'titre'}
                                    component={SelectFieldRedux}
                                    label={'Titre :'}
                                    required
                                >
                                    <option value={""}>Sélectionner un titre</option>
                                    <option value="MME">Madame</option>
                                    <option value="MR">Monsieur</option>
                                    <option value="MRME">Madame et monsieur</option>
                                </Field>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <Field
                                    name='nom'
                                    component={TextFieldRedux}
                                    label={'Nom :'}
                                    normalize={v => v.toUpperCase()}
                                    required
                                />
                            </div>
                            <div className="col">
                                <Field
                                    name='prenom'
                                    component={TextFieldRedux}
                                    label={'Prénom :'}
                                    normalize={v => v.toUpperCase()}
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <Field
                                    name='telephone'
                                    component={TextFieldRedux}
                                    label={'Téléphone :'}
                                />
                            </div>
                            <div className="col">
                                <Field
                                    name='email'
                                    component={TextFieldRedux}
                                    label={'Email :'}
                                />
                            </div>
                        </div>
                        {
                            !correspondantPrincipal &&
                            <div className="row">
                                <div className="col">
                                    <Field
                                        component={CheckboxFieldRedux}
                                        name={'principal'}
                                        label={`Correspondant principal`}
                                        type={'checkbox'}
                                    />
                                </div>
                            </div>
                        }

                        {
                            loading ? <Spinner style={{display: 'block', margin: 'auto'}}/> :
                                <button className={'btn btn-primary d-block'} data-test-id="avenant_simple_btn_submit"
                                        type={'submit'}>
                                    {edit ? 'Editer le correspondant' : 'Ajouter le correspondant'}
                                </button>
                        }

                    </form>
                </div>
            </div>
        </div>
    );
};


AddCorrespondant.defaultProps = {};


AddCorrespondant.propTypes = {};


export default AddCorrespondant;
