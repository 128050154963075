import {connect} from 'react-redux'
import {compose} from "redux";
import InitFormFleet from "./InitFormFleet";
import {getFleetGetLink} from "../../../../../../redux/selectors/links/links.selectors";
import {getErrorEntity, getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {FLOTTE_DETAIL, getFlotteDetail} from "../../../../../../redux/actions/app/flotte/flotte.actions";
import _ from "lodash";
import loader from "../../../../../Commun/Spinner/Loader/Loader";


const mapStateToProps = state => {
    return {
        query: getFleetGetLink(state),
        loaded: getLoadingEntity(state, FLOTTE_DETAIL) === false,
        error: getErrorEntity(state, FLOTTE_DETAIL)
    }
}

const mapDispatchToProps = {
    getFlotteDetail,
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {query} = stateProps
    const {getFlotteDetail} = dispatchProps
    const {idVehicule} = ownProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getFlotteDetail({query: _.replace(query, '{id}', idVehicule)}),
    }
}

const InitFormFleetContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(InitFormFleet)

export default InitFormFleetContainer
