export const CAS_IDA = '[Cas IDA]'
export const POST_CAS_IDA = `${CAS_IDA} Get`
export const SET_CAS_IDA = `${CAS_IDA} Set`

export const postCasIda = ({vehicule_a, vehicule_a_bis, vehicule_b, vehicule_b_bis, type, query}) => ({
    type: POST_CAS_IDA,
    payload: {
        data: query,
        body: {vehicule_a, vehicule_a_bis, vehicule_b, vehicule_b_bis, type},
    }
})

export const setCasIda = ({data}) => ({
    type: SET_CAS_IDA,
    payload: {
        data
    }
})
