export const ADD_EXPERT_REPORT = '[Add rapport d\'expert]'
export const POST_ADD_EXPERT_REPORT = `${ADD_EXPERT_REPORT} Post`
export const SET_ADD_EXPERT_REPORT = `${ADD_EXPERT_REPORT} Set`

export const postAddExpertReport = ({query, form}) => ({
    type: POST_ADD_EXPERT_REPORT,
    payload: {
        data: query,
        body: form
    }
})