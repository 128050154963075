import React from 'react';
import moment from 'moment';

const AgRecla = (props) => {
    const {task: {date, type, paniere}} = props

    return (
        <div className={'container'}>
            <div className={'row'}>
                <div className={'col text-center'}>
                    <h2 className={'title-primary text-uppercase'}>Tâche {paniere}</h2>
                    <p className={'subtitle'}>Tâche du {moment(date).format('L')}. Recu par {type}</p>
                </div>
            </div>
            <div className={'header-row row'}>
                <div className={'col-12'}>
                    <div className={'form-content text-center'}>
                        <p>L'accusé de réception a été envoyé mais la réclamation n'est toujours pas terminée.</p>

                        <p>Merci d'apporter une réponse au client ou de faire le nécessaire si nous sommes dans
                            l'attente d'une réponse d'un tiers.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AgRecla;
