import {connect} from 'react-redux'
import {compose} from "redux";
import FlotteAccidentsList from "./FlotteAccidentsList";
import {getAccidentsData} from "../../../../../../../redux/selectors/accidents/accidents.selectors";
import {getErrorEntity, getLoadingEntity} from "../../../../../../../redux/selectors/ui/ui.selectors";
import {FLOTTE_ACCIDENTS} from "../../../../../../../redux/actions/app/flotte/flotte.actions";
import ErrorHttpComponent from "../../../../../../Commun/Error/ErrorHttpComponent";
import loader from "../../../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    return {
        loaded: getLoadingEntity(state, FLOTTE_ACCIDENTS) === false,
        loading: getLoadingEntity(state, FLOTTE_ACCIDENTS),
        error: getErrorEntity(state, FLOTTE_ACCIDENTS),
        accidents: getAccidentsData(state)
    }
}

const FlotteAccidentsListContainer = compose(
    connect(mapStateToProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(FlotteAccidentsList)

export default FlotteAccidentsListContainer
