import React, {useEffect, useState} from 'react';
import Down from "../../../../assets/img/icons/chevron_bottom_light.png";

const GoToBottom = () => {
    const [scrollY, setScrollY] = useState(0)
    const [bottomTopPoint, setbottomTopPoint] = useState(window.innerHeight)

    const GoToBottom = () => {
        let body = document.querySelector(".page-container");
        if (body) {
            body.scrollIntoView({block: "end", behavior: 'smooth'})
        }
    }

    const handleScroll = () => {
        setScrollY(window.pageYOffset + window.innerHeight)

        if (bottomTopPoint === window.innerHeight) {
            let body = document.querySelector(".page-container");
            if (body) {
                setbottomTopPoint((body.getBoundingClientRect().top + window.scrollY + body.clientHeight));
            }
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])
    return (
        <button onClick={GoToBottom} className={`btn btn-down-rounded ${scrollY < bottomTopPoint ? 'active' : ''}`}
                title="bas de page">
            <img src={Down} alt=""/>
        </button>
    );
};

export default GoToBottom;
