export const PARRAINAGES = '[Parrainages]';

export const GET_PARRAINAGES = `${PARRAINAGES} Get`;
export const SET_PARRAINAGES = `${PARRAINAGES} Set`;

export const getParrainages = ({query}) => ({
    type: GET_PARRAINAGES,
    payload: {
        data: query
    }
})

export const setParrainages = ({data}) => ({
    type: SET_PARRAINAGES,
    payload: {data},
    meta: {
        entity: PARRAINAGES
    }
})
