import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import TasksContent from './TasksContent'
import ErrorHttpComponent from '../../../Commun/Error/ErrorHttpComponent'
import {getTasks} from '../../../../redux/actions/app/tasks/tasks.actions'
import {tasksLink} from '../../../../redux/selectors/links/links.selectors'
import {getTasksMeta} from "../../../../redux/selectors/tasks/tasks.selectors";
import {getUserPanieres, getUserRoles} from "../../../../redux/selectors/user/user.selectors";
import {setLoader} from "../../../../redux/actions/app/ui/ui.actions";
import {CONTRACT} from "../../../../redux/actions/app/contracts/contracts.actions";
import loader from "../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    return {
        roles: getUserRoles(state),
        uri: tasksLink(state),
        meta: getTasksMeta(state) || {},
        panieres: getUserPanieres(state) || {}
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getTasks: ({
                   query,
                   sort,
                   courtier,
                   produit,
                   contrat_situation,
                   portefeuille,
                   paniere,
                   type_agenda,
                   date_start,
                   date_end,
                   agendas,
                   multiple,
                   page,
                   date_start_create_popup_range,
                   date_end_create_popup_range,
                   date_create_task,
                   resil_date
               }) =>
        getTasks(
            {
                query,
                sort,
                courtier,
                produit,
                contrat_situation,
                portefeuille,
                paniere,
                type_agenda,
                date_start,
                date_end,
                agendas,
                multiple,
                page,
                date_start_create_popup_range,
                date_end_create_popup_range,
                date_create_task,
                resil_date
            }
        ),
    setLoadingContractTrue: () => setLoader({state: true, entity: CONTRACT})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getTasks} = dispatchProps
    const {uri} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getTasks(
            {
                query: uri,
                type_agenda: localStorage.getItem('type_agenda') || '',
                portefeuille: localStorage.getItem('portefeuille') || '',
                paniere: localStorage.getItem('paniere') || '',
                contrat_situation: localStorage.getItem('contrat_situation') || '',
                agendas: localStorage.getItem('agendas') || '',
                multiple: localStorage.getItem('multiple') || '',
                sort: localStorage.getItem('sort')
            }),
    }
}

const TasksContentContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(TasksContent)

export default TasksContentContainer
