import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import _ from "lodash";
import {
    DASHBOARD_CONSEILLER_TLV,
    GET_DASHBOARD_CONSEILLER_TLV, setDashboardConseillerTelevente
} from "../../../actions/app/dashboard_conseiller_televente/dashboard_conseiller_televente.actions";

export const dashboardConseillerTeleventeMiddleware = () => next => action => {
    next(action)

    switch (action.type) {
        case GET_DASHBOARD_CONSEILLER_TLV:
            let url = _.includes(action.payload.data, '?') ? action.payload.data : `${action.payload.data}?`

            if (action.payload.filters) {
                for (let [key, value] of Object.entries(action.payload.filters)) {
                    if(key && value){
                        url = `${url}&${key}=${value}`
                    }
                }
            }

            next([
                apiRequest({body: null, method: 'GET', url, entity: DASHBOARD_CONSEILLER_TLV}),
                setLoader({state: true, entity: DASHBOARD_CONSEILLER_TLV})
            ])
            break

        case `${DASHBOARD_CONSEILLER_TLV} ${API_SUCCESS}`:

            next([
                setDashboardConseillerTelevente({data: action.payload.data}),
                setError({state: false, entity: DASHBOARD_CONSEILLER_TLV}),
                setLoader({state: false, entity: DASHBOARD_CONSEILLER_TLV}),
            ])
            break

        case `${DASHBOARD_CONSEILLER_TLV} ${API_ERROR}`:
            next([
                setError({state: true, entity: DASHBOARD_CONSEILLER_TLV}),
                setLoader({state: false, entity: DASHBOARD_CONSEILLER_TLV})
            ])
            break

        default:
            break
    }
    return null
}
