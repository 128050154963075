import {createSelector} from 'reselect'

const tasksSelector = state => state.tasks
export const getTasksData = createSelector(
    tasksSelector,
    tasks => tasks.data
)

export const getTasksMeta = createSelector(
    tasksSelector,
    tasks => tasks.meta
)
