import {Field} from "redux-form";
import DatePickerFieldRedux from "../../../../Commun/Input/DatePicker/DatePickerFieldRedux";
import Spinner from "../../../../Commun/Spinner/Spinner";
import moment from "moment";

const AddRIAntecedent = (props) => {
    const {handleSubmit, loading, type} = props

    return (
        <div className={'contract-container container'}>
            <div className={'row'}>
                <div className={'col'}>
                    <h1 className={'title-primary'}>Ajout d'une période {type} reçue</h1>
                </div>
            </div>
            <div className={'row header-row'}>
                <div className={'col-12'}>
                    <form onSubmit={handleSubmit} className={'form-content'}>
                        <div className="row">
                            <div className="col-6">
                                <Field
                                    name={"startDate"}
                                    component={DatePickerFieldRedux}
                                    label={'Date de début de la période reçue'}
                                    maxDate={moment()}
                                />
                            </div>
                            <div className="col-6">
                                <Field
                                    name={"endDate"}
                                    component={DatePickerFieldRedux}
                                    label={'Date de fin'}
                                    maxDate={moment()}
                                />
                            </div>
                        </div>
                        {
                            loading ? <Spinner style={{display: 'block', margin: 'auto'}}/> :
                            <button className={'btn btn-primary d-block'} type={'submit'}>
                                Ajouter la période
                            </button>
                        }
                    </form>
                </div>
            </div>
        </div>
    );
};
export default AddRIAntecedent;
