import {connect} from 'react-redux'
import {compose} from 'redux'
import {reduxForm} from "redux-form";
import _ from "lodash";
import moment from 'moment'
import Reclamation from "./Reclamation";
import validate from './validate'
import ErrorHttpComponent from "../../../../Commun/Error/ErrorHttpComponent";
import {getTiers, TIERS} from "../../../../../redux/actions/app/tiers/tiers.actions";
import {
    complainPostLink,
    contractDefaultGetLink,
    tiersGetLink
} from "../../../../../redux/selectors/links/links.selectors";
import {getTiersData} from "../../../../../redux/selectors/tiers/tiers.selectors";
import {getErrorEntity, getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {POST_RECLAMATION, postReclamation} from "../../../../../redux/actions/app/reclamation/reclamation.actions";
import loader from "../../../../Commun/Spinner/Loader/Loader";
import {CONTRACT, getContract} from "../../../../../redux/actions/app/contracts/contracts.actions";
import {getContractData} from "../../../../../redux/selectors/contract/contract.selectors";

const getLoaded = (ownProps, state) => {
    if (ownProps.idc) {
        return getLoadingEntity(state, CONTRACT) === false
    } else {
        return getLoadingEntity(state, TIERS) === false
    }
}

const mapStateToProps = (state, ownProps) => {
    const {id, idc, ids} = ownProps.match.params

    return {
        id, idc, ids,
        uriContract: contractDefaultGetLink(state),
        uriTiers: tiersGetLink(state),
        queryPost: complainPostLink(state),
        contract: getContractData(state),
        tiers: getTiersData(state),
        loading: getLoadingEntity(state, POST_RECLAMATION),
        loaded: getLoaded(ownProps.match.params, state),
        error: getErrorEntity(state, TIERS),
        initialValues: {
            date_reception: moment(),
            tiers: id,
            contract: idc || null,
            sinistre: ids || null,
            recla_initiale: 'T',
            description: "",
            niveau_recla: "1"
        }
    }
}

const mapDispatchToProps = {
    getTiers,
    getContract,
    postReclamation
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uriContract, uriTiers, id, idc, queryPost} = stateProps
    const {getTiers, postReclamation, getContract} = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => {
             if (idc) {
                 getContract({query: _.replace(uriContract, '{id}', idc), id: idc})
             }
            getTiers({query: _.replace(uriTiers, '{id}', id), id: id})
        },
        onSubmit: form => postReclamation({query: _.replace(queryPost, '{id}', id), form})
    }
}

const ReclamationContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'reclamation',
        validate
    }),
    loader({ErrorIndicator: ErrorHttpComponent})
)(Reclamation)

export default ReclamationContainer
