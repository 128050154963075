import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import ChangeRemboursementAccident from "./ChangeRemboursementAccident";
import {reduxForm} from "redux-form";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {updateRefundRepairerAccidentGetLink} from "../../../../../redux/selectors/links/links.selectors";
import _ from "lodash";
import {
    POST_REFUND_REPAIRER_ACCIDENT,
    postChangeRefundRepairerAccident
} from "../../../../../redux/actions/app/accidents/accidents.actions";

const mapStateToProps = (state, ownProps) => {

    return {
        uri: updateRefundRepairerAccidentGetLink(state),
        accidentId: ownProps.location.state.accident.id,
        contractId: ownProps.location.state.contract.id,
        loading: getLoadingEntity(state, POST_REFUND_REPAIRER_ACCIDENT),
        initialValues: {
            "refund_repairer": ownProps.location.state.refund,
        }
    }
}


const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
    postChangeRefundRepairerAccident,
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uri, accidentId, contractId} = stateProps
    const {postChangeRefundRepairerAccident} = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: form => postChangeRefundRepairerAccident({
            query: _.replace(_.replace(uri, '{id}', contractId), '{ids}', accidentId),
            form
        })
    }
}


const ChangeRemboursementAccidentContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'change-refund'
    })
)(ChangeRemboursementAccident)


export default ChangeRemboursementAccidentContainer
