const validate = values => {
    const errors = {}

    const requiredFields = [
        /* Déclaration */
        'catsinorigin',
        'indentify',
        'datesurvorigin',
        'lieu',
        'conduct',
        'corpoconduct',
        'temoin',
        'vehiculeDommage',
        'constat_accident',
        'dommagesDeclares',
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Champ obligatoire'
        }
    })

    return errors
}

export default validate
