import React from 'react';
import moment from "moment";
import DetailsReglementRow from "./DetailsReglementRow/DetailsReglementRow";


const DetailsReglement = (props) => {
    const {reglementDetail} = props

    return (
        <div className={'contract-container container'}>
            <div className={'row'}>
                <div className={'col'}>
                    <h2 className={'title-primary'}>Détail du règlement pour le sinistre n°{reglementDetail.numCbt}</h2>
                </div>
            </div>
            <div className={'row header-row'}>
                <div className={'col-7'}>
                    <div className={'form-content'}>
                        <DetailsReglementRow title={reglementDetail.typeReglement}
                                             value={`${reglementDetail.montantTypeReglement} €`}/>
                        <DetailsReglementRow title={'Vétusté'} value={`${reglementDetail.vetuste} €`}/>
                        <DetailsReglementRow title={'Valeur de l\'officiel du cycle'}
                                             value={`${reglementDetail.valeurCycleOfficiel} €`}/>
                        <DetailsReglementRow title={'Majoration valeur expertise'}
                                             value={`${reglementDetail.majorationValeurExpertise} €`}/>
                        <DetailsReglementRow title={'Valeur d\'achat'} value={`${reglementDetail.valeurAchat} €`}/>
                        <DetailsReglementRow title={'- 0 > 12 mois'} value={reglementDetail.valeur12 ? 'Oui' : 'Non'}/>
                        <DetailsReglementRow title={'- 12 > 24 mois'} value={reglementDetail.valeur24 ? 'Oui' : 'Non'}/>
                        <DetailsReglementRow title={'Valeur de sauvetage'}
                                             value={`${reglementDetail.valeurSauvetage} €`}/>
                        <DetailsReglementRow title={'Moyens de protection'}
                                             value={`${reglementDetail.moyenProtection} €`}/>
                        <DetailsReglementRow title={'Casque et gants (si RC 100%)'}
                                             value={`${reglementDetail.casque} €`}/>
                        <DetailsReglementRow title={'Accessoires 10%'} value={`${reglementDetail.accessoires10} €`}/>
                        <DetailsReglementRow title={'Accessoires 20%'} value={`${reglementDetail.accessoires20} €`}/>
                        <DetailsReglementRow title={'Equipement de protection du conducteur'}
                                             value={`${reglementDetail.equipement} €`}/>
                        <DetailsReglementRow title={'Accessoires et équipements'}
                                             value={`${reglementDetail.accessoires} €`}/>
                        <DetailsReglementRow title={'Effet ou objet personnel'}
                                             value={`${reglementDetail.objetPerso} €`}/>
                        <DetailsReglementRow title={'Remorquage'} value={`${reglementDetail.remorquage} €`}/>
                        <DetailsReglementRow title={'Rachat de franchise - vol'}
                                             value={reglementDetail.rachatFranchiseV ? 'Oui' : 'Non'}/>
                        <DetailsReglementRow title={'Rachat de franchise - tous accidents'}
                                             value={reglementDetail.rachatFranchiseDTA ? 'Oui' : 'Non'}/>
                        <hr/>
                        <DetailsReglementRow title={'Sous-total'} value={`${reglementDetail.sousTotalReglement1} €`}/>

                        <hr/>
                        <DetailsReglementRow title={'Franchise'} value={`${reglementDetail.franchise} €`}/>
                        <DetailsReglementRow title={'Absence gravage'} value={`${reglementDetail.absenceGravage} €`}/>
                        <DetailsReglementRow title={'Absence antivol'} value={`${reglementDetail.absenceAntivol} €`}/>
                        <DetailsReglementRow title={'Absence remisage de nuit'}
                                             value={`${reglementDetail.absenceRemisage} €`}/>
                        <DetailsReglementRow title={'Absence alarme'} value={`${reglementDetail.absenceAlarme} €`}/>
                        <DetailsReglementRow title={'Conduite exclusive'} value={`${reglementDetail.conduiteExclu} €`}/>
                        <DetailsReglementRow title={'Autres pénalités'} value={`${reglementDetail.autresPenalites} €`}/>
                        <hr/>
                        <DetailsReglementRow title={'Sous-total'} value={`${reglementDetail.sousTotalPenalites} €`}/>

                        <hr/>
                        <DetailsReglementRow title={'Total règlement'} value={`${reglementDetail.totalReglement} €`}/>
                    </div>
                </div>
                <div className={'col-5'}>
                    <div className={'form-content'}>
                        <DetailsReglementRow title={'Portefeuille'} value={reglementDetail.portefeuille}/>
                        <DetailsReglementRow title={'Produit'} value={reglementDetail.produit}/>
                        <DetailsReglementRow title={'N° de sinistre cabinet'} value={reglementDetail.numCie}/>
                        <DetailsReglementRow title={'N° de sinistre compagnie'} value={reglementDetail.numCbt}/>
                        <DetailsReglementRow title={'Date de déclaration'}
                                             value={reglementDetail.dateDecla ? moment(reglementDetail.dateDecla).format('L') : ''}/>
                        <DetailsReglementRow title={'Date de clôture matériel'}
                                             value={reglementDetail.dateClotureMateriel ? moment(reglementDetail.dateClotureMateriel).format('L') : ''}/>
                        <DetailsReglementRow title={'Date survenance'}
                                             value={reglementDetail.dateSurv ? moment(reglementDetail.dateSurv).format('L') : ''}/>
                        <DetailsReglementRow title={'Cas IDA'} value={reglementDetail.ida}/>

                        <hr/>
                        <p className={'font-weight-bold'}>Vol</p>

                        <DetailsReglementRow title={'Véhicule dans garage'}
                                             value={`${reglementDetail.vehiculeGarage} €`}/>
                        <DetailsReglementRow title={'Véhicule gravé'} value={`${reglementDetail.vehiculeGrave} €`}/>
                        <DetailsReglementRow title={'Véhicule attaché'} value={`${reglementDetail.vehiculeAttache} €`}/>
                        <DetailsReglementRow title={'Antivol anti-démarrage'}
                                             value={reglementDetail.antivolDemarrage ? 'Oui' : 'Non'}/>
                        <DetailsReglementRow title={'Antivol electronique'}
                                             value={reglementDetail.antivolElec ? 'Oui' : 'Non'}/>
                        <DetailsReglementRow title={'Antivol mécanique'}
                                             value={reglementDetail.antivolMeca ? 'Oui' : 'Non'}/>
                        <DetailsReglementRow title={'Agréé SRA'} value={reglementDetail.agreeSra ? 'Oui' : 'Non'}/>
                        <DetailsReglementRow title={'Dommages causés'}
                                             value={reglementDetail.dommagesCauses ? 'Oui' : 'Non'}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailsReglement;
