import _ from 'lodash'

import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {
    EDIT_RECLAMATION,
    GET_RECLAMATION,
    POST_ACCUSE_RECLAMATION,
    POST_CLOSE_RECLAMATION,
    POST_EDIT_DOCUMENT_RECLAMATION,
    POST_PENDING_RECLAMATION,
    POST_RECLAMATION,
    POST_TRANSFERT_RECLAMATION,
    RECLAMATION,
    setReclamation
} from "../../../actions/app/reclamation/reclamation.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import Swal from 'sweetalert2';
import { truncateMiddle } from '@/components/Commun/APILink/APILink';
import { tokenUser } from '@/redux/selectors/localStorage/localStorage';
import { toast } from '@/redux/middlewares/core/notifications/notifications.middleware';

export const reclamationMiddleware = () => next => action => {
    next(action)
    let clone;

    switch (action.type) {

        case GET_RECLAMATION:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: RECLAMATION}),
                setLoader({state: true, entity: RECLAMATION})
            ])
            break

        case `${RECLAMATION} ${API_SUCCESS}`:
            const {data} = action.payload

            next([
                setReclamation({data}),
                setError({state: false, entity: RECLAMATION}),
                setLoader({state: false, entity: RECLAMATION})
            ])
            break

        case `${RECLAMATION} ${API_ERROR}`:
            next([
                setError({state: true, entity: RECLAMATION}),
                setLoader({state: false, entity: RECLAMATION})
            ])
            break

        case POST_RECLAMATION:

            clone = _.cloneDeep(action.payload.body)
            clone.date_reception = clone.date_reception.format('YYYY-MM-DD')

            let otherData = {
                transfert: clone.transfert
            }

            next([
                apiRequest({
                    body: (clone),
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_RECLAMATION,
                    otherData
                }),
                setLoader({state: true, entity: POST_RECLAMATION})
            ])
            break

        case `${POST_RECLAMATION} ${API_SUCCESS}`:
            next([
                setError({state: false, entity: POST_RECLAMATION}),
                setLoader({state: false, entity: POST_RECLAMATION})
            ])

            if (action.payload.meta.otherData && action.payload.meta.otherData.transfert) {
                window.location.pathname = `/ma-reclamation/transfert-niveau-2/${action.payload.data.id_tiers}/${action.payload.data.id}`
            } else {
                window.location.pathname = `/ma-reclamation/${action.payload.data.id_tiers}/${action.payload.data.id}`
            }

            break

        case `${POST_RECLAMATION} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_RECLAMATION,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_RECLAMATION}),
                setLoader({state: false, entity: POST_RECLAMATION})
            ])
            break


        case EDIT_RECLAMATION:
            clone = _.cloneDeep(action.payload.body)
            clone.date_reception = clone.date_reception.format('YYYY-MM-DD')

            next([
                apiRequest({
                    body: (clone),
                    method: 'POST',
                    url: action.payload.data,
                    entity: EDIT_RECLAMATION
                }),
                setLoader({state: true, entity: EDIT_RECLAMATION})
            ])
            break

        case `${EDIT_RECLAMATION} ${API_SUCCESS}`:
            next([
                setError({state: false, entity: EDIT_RECLAMATION}),
                setLoader({state: false, entity: EDIT_RECLAMATION})
            ])

            window.location.pathname = `/ma-reclamation/${action.payload.data.id_tiers}/${action.payload.data.id}`

            break

        case `${EDIT_RECLAMATION} ${API_ERROR}`:
            next([
                setNotification({
                    entity: EDIT_RECLAMATION,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: EDIT_RECLAMATION}),
                setLoader({state: false, entity: EDIT_RECLAMATION})
            ])
            break

        case POST_TRANSFERT_RECLAMATION:
            next([
                apiRequest({
                    body: (action.payload.body),
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_TRANSFERT_RECLAMATION
                }),
                setLoader({state: true, entity: POST_TRANSFERT_RECLAMATION})
            ])
            break

        case `${POST_TRANSFERT_RECLAMATION} ${API_SUCCESS}`:
            next([
                setError({state: false, entity: POST_TRANSFERT_RECLAMATION}),
                setLoader({state: false, entity: POST_TRANSFERT_RECLAMATION})
            ])

            window.location.pathname = `/ma-reclamation/${action.payload.data.id_tiers}/${action.payload.data.id}`

            break

        case `${POST_TRANSFERT_RECLAMATION} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_TRANSFERT_RECLAMATION,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_TRANSFERT_RECLAMATION}),
                setLoader({state: false, entity: POST_TRANSFERT_RECLAMATION})
            ])
            break

        case POST_CLOSE_RECLAMATION:
            next([
                apiRequest({
                    body: (action.payload.body),
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_CLOSE_RECLAMATION,
                    otherData: action.payload.otherData
                }),
                setLoader({state: true, entity: POST_CLOSE_RECLAMATION})
            ])
            break

        case `${POST_CLOSE_RECLAMATION} ${API_SUCCESS}`:
            next([
                setError({state: false, entity: POST_CLOSE_RECLAMATION}),
                setLoader({state: false, entity: POST_CLOSE_RECLAMATION})
            ])

            window.location.pathname = `/ma-reclamation/${action.payload.meta.otherData.id}/${action.payload.meta.otherData.ids}`
            break

        case `${POST_CLOSE_RECLAMATION} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_CLOSE_RECLAMATION,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_CLOSE_RECLAMATION}),
                setLoader({state: false, entity: POST_CLOSE_RECLAMATION})
            ])
            break


        case POST_PENDING_RECLAMATION:
            next([
                apiRequest({
                    body: (action.payload.body),
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_PENDING_RECLAMATION,
                    otherData: action.payload.otherData
                }),
                setLoader({state: true, entity: POST_PENDING_RECLAMATION})
            ])
            break

        case `${POST_PENDING_RECLAMATION} ${API_SUCCESS}`:
            next([
                setError({state: false, entity: POST_PENDING_RECLAMATION}),
                setLoader({state: false, entity: POST_PENDING_RECLAMATION})
            ])

            window.location.pathname = `/ma-reclamation/${action.payload.meta.otherData.id}/${action.payload.meta.otherData.ids}`
            break

        case `${POST_PENDING_RECLAMATION} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_PENDING_RECLAMATION,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_PENDING_RECLAMATION}),
                setLoader({state: false, entity: POST_PENDING_RECLAMATION})
            ])
            break


        case POST_ACCUSE_RECLAMATION:
            next([
                apiRequest({body: null, method: 'POST', url: action.payload.data, entity: POST_ACCUSE_RECLAMATION}),
                setLoader({state: true, entity: POST_ACCUSE_RECLAMATION})
            ])
            break

        case `${POST_ACCUSE_RECLAMATION} ${API_SUCCESS}`:
            let nextActions = [
                setError({state: false, entity: POST_ACCUSE_RECLAMATION}),
                setLoader({state: false, entity: POST_ACCUSE_RECLAMATION})
            ]

            if (action.payload.data.data.message !== "") {
                nextActions.push(setNotification({
                    entity: POST_ACCUSE_RECLAMATION,
                    body: 'La date d\'envoi de l\'avis de réception a bien été mise à jour.',
                    type: 'success',
                    title: 'Merci !'
                }))
                nextActions.push(setNotification({
                    entity: POST_ACCUSE_RECLAMATION,
                    body: `<p>${action.payload.data.data.message}</p>`,
                    type: 'error',
                    title: 'Attention !',
                    timeout: '0'
                }))
            } else {
                nextActions.push(setNotification({
                    entity: POST_ACCUSE_RECLAMATION,
                    body: 'L\'accusé de réception de la réclamation a bien été envoyé',
                    type: 'success',
                    title: 'Merci !'
                }))
            }

            next(nextActions)
            break

        case `${POST_ACCUSE_RECLAMATION} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_ACCUSE_RECLAMATION,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_ACCUSE_RECLAMATION}),
                setLoader({state: false, entity: POST_ACCUSE_RECLAMATION})
            ])
            break

        case POST_EDIT_DOCUMENT_RECLAMATION:
            next([
                apiRequest({
                    body: (action.payload.body),
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_EDIT_DOCUMENT_RECLAMATION
                }),
                setLoader({state: true, entity: POST_EDIT_DOCUMENT_RECLAMATION})
            ])
            break

        case `${POST_EDIT_DOCUMENT_RECLAMATION} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_EDIT_DOCUMENT_RECLAMATION,
                    body: '',
                    type: 'success',
                    title: 'Le document a été edité avec succès'
                }),
                setError({state: false, entity: POST_EDIT_DOCUMENT_RECLAMATION}),
                setLoader({state: false, entity: POST_EDIT_DOCUMENT_RECLAMATION})
            ])


            const url = action.payload.data.data
            const filename = url.split('?')[1].split('=')[1]

            const loadingToast = Swal.fire({
                toast: true,
                position: 'top',
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading();
                },
                titleText: `Téléchargement du fichier ${truncateMiddle(filename, 20)}...`
            });

            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${tokenUser.trim()}`
                }
            })
                .then(response => response.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(error => {
                    toast({
                        type: 'error',
                        title: 'Téléchargement échoué'
                    })
                })
                .finally(() => {
                    loadingToast.close()
                });
            break

        case `${POST_EDIT_DOCUMENT_RECLAMATION} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_EDIT_DOCUMENT_RECLAMATION,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_EDIT_DOCUMENT_RECLAMATION}),
                setLoader({state: false, entity: POST_EDIT_DOCUMENT_RECLAMATION})
            ])
            break

        default:
            break
    }
    return null
}
