import React, {Component, Fragment} from 'react';
import TextFieldRedux from "../../../../../../../Commun/Input/Text/TextFieldRedux";
import {Field} from "redux-form";
import RadioFieldRedux from "../../../../../../../Commun/Input/Radio/RadioFieldRedux";
import MyButton from '../../../../../../../Commun/Button/Button'
import CallToAction from "../../../../../../../Commun/Button/CallToAction/CallToAction";
import PostalCodeCity from "../../../../../../../Commun/PostalCodeCity/PostalCodeCity";
import FilteredAutocomplete from "../../../../../../../Commun/Forms/FilteredAutocomplete/FilteredAutocomplete";
import _ from "lodash";
import {codeCompagnys} from "../../../../../../../../utils/optionsSelect";


class FieldArrayAdversaires extends Component {

    componentDidMount() {
        this.props.fields.push({})
    }

    render() {
        const {fields, compagnys, adversaires, changeValue} = this.props

        return (
            <div>
                {
                    fields.map((adversaire, index) => (
                        <div key={index} className={'cadre'}>
                            <span className={'title-cadre'}>Partie adverse {index + 1}</span>
                            {' '}
                            <CallToAction
                                action={'delete'}
                                toolText={'Supprimer'}
                                onClick={(e) => {
                                    e.preventDefault()
                                    fields.remove(index)
                                }}
                            />
                            <Field
                                name={`${adversaire}.immat`}
                                component={TextFieldRedux}
                                label='Immatriculation'
                            />

                            <Field
                                name={`${adversaire}.police`}
                                component={TextFieldRedux}
                                label="Numéro de contrat d'assurance"
                            />

                            <FilteredAutocomplete
                                name={`${adversaire}.cie`}
                                label="Compagnie d'assurance"
                                labelOtherValue="Autre compagnie"
                                dataFiltered={_.filter(compagnys, c => _.includes(codeCompagnys, c.value))}
                                data={compagnys}
                                changeValue={changeValue}
                            />

                            <Field
                                name={`${adversaire}.titre`}
                                component={RadioFieldRedux}
                                label={'Civilité'}
                                data={[
                                    {value: "M", label: "Monsieur"},
                                    {value: "Mme", label: "Madame"},
                                    {value: "Societe", label: "Société"}
                                ]}
                            />

                            <Field
                                name={`${adversaire}.nom`}
                                component={TextFieldRedux}
                                label="Nom"
                            />

                            {(adversaires[index] && adversaires[index] !== undefined) &&
                                adversaires[index].titre !== 'Societe' &&
                                <Field
                                    name={`${adversaire}.prenom`}
                                    component={TextFieldRedux}
                                    label="Prénom"
                                />
                            }

                            <Field
                                name={`${adversaire}.blesse`}
                                component={RadioFieldRedux}
                                label={'Y-a-t-il des blessés dans cette partie adverse ?'}
                                data={[
                                    {value: "T", label: "Oui"},
                                    {value: "F", label: "Non"}
                                ]}
                            />

                            <Field
                                name={`${adversaire}.adresse`}
                                component={RadioFieldRedux}
                                label={'Avez-vous son adresse ?'}
                                data={[
                                    {value: "T", label: "Oui"},
                                    {value: "F", label: "Non"}
                                ]}
                            />

                            {
                                adversaires[index] && adversaires[index].adresse === 'T' && <Fragment>
                                    <Field
                                        name={`${adversaire}.adresse1`}
                                        component={TextFieldRedux}
                                        label="Adresse"
                                    />

                                    <PostalCodeCity
                                        nameCodePostal={`${adversaire}.cp`}
                                        nameVille={`${adversaire}.ville`}
                                        label={'Ville de résidence'}
                                        changeValue={changeValue}
                                    />

                                </Fragment>
                            }

                        </div>
                    ))
                }
                <MyButton
                    onClick={(e) => {
                        e.preventDefault();
                        fields.push({})
                    }}
                    className={'btn btn-secondary'}
                >
                    Ajouter une partie adverse
                </MyButton>
            </div>
        );
    }
}

export default FieldArrayAdversaires;
