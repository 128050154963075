import React from 'react'
import moment from "moment";

const RelanceTel = (props) => {
    const {task: {date, type, paniere, contract, tiers_id}} = props
    let type_contrat = contract.apporteur1 === '43397' ? 'client' : 'courtier'

    return (
        <div className={'container'}>
            <div className={'text-center'}>
                {paniere !== 'CTRL_N_CALL' ?
                    <h2 className={'title-primary text-uppercase'}>Relance Téléphonique {paniere} - Tiers
                        n°{tiers_id}</h2> :
                    <h2 className={'title-primary text-uppercase'}>Popup Vérification des pièces + appel client</h2>}
                <p className={'subtitle'}>Tâche du {moment(date).format('L')}. Recu par {type}</p>
            </div>
            <div className={'header-row row'}>
                <div className={'col-12'}>
                    <div className={'alert alert-warning'}>
                        {(paniere === 'REJ') && `Un rejet de prélévement est remonté sur ce contrat. Merci de rappeler le ${type_contrat}.`}
                        {paniere === 'REJ_2' && <p className={'m-0'}>
                            - Si présence d'acompte client ou contrat : affecter l'argent sur la quittance, effacer les
                            tableaux de prélèvements encore présents, s'il reste un impayé éditer la LR rejet, puis
                            ajouter le libellé sur toutes les quittances impayées : LR + date du jour (sauf quittance
                            Terme avec une date future) et enlever le code banque<br/>
                            - Si présence d'une quittance négative, il faut la solder<br/>
                            - Si présence d'une quittance Terme avec une date future, il faut enlever le libellé dans la
                            quittance
                        </p>
                        }

                        {(paniere === 'SEA_KO') && 'Le contrat d"assurance du client est passé en Résilié. Vous pouvez faire la pré-déclaration si le sinistre a eu lieu pendant la période d’assurance'}
                        {paniere === 'CTRL_N_CALL' &&
                            <p className={'m-0'}>Merci de vérifier l'intégralité du dossier et des pièces reçues.<br/>Passer
                                en cours le contrat si possible.<br/>Sinon contacter le client afin de lui demander les
                                documents manquants</p>}
                        {(paniere === 'VOL_J30') && 'Appelez le client et pensez à ajouter les pièces attendues sur le dossier sinistre.'}
                        {(paniere === 'LR_50' || paniere === 'RTEL_1' || paniere === 'RTEL_2') && 'Merci de vérifier les pièces obligatoires avant d’effectuer l\'appel.'}
                        {(paniere === 'RTEL_CIE' || paniere === 'RTEL2_CIE' || paniere === 'RTEL3_CIE') && 'Appelez la compagnie pour faire un point sur les pièces en attente'}
                        {(paniere === 'RTEL_VOL' || paniere === 'RTEL_CLI' || paniere === 'RTEL2_CLI' || paniere === 'RTEL3_CLI') && 'Appelez le client pour faire un point sur les pièces en attente.'}
                        {(paniere === 'RTEL_PV' || paniere === 'RTEL2_PV' || paniere === 'RTEL3_PV') && 'Appelez la compagnie pour relance le PV + Appelez le client pour le rassurer dans le suivi de son dossier'}
                    </div>
                    <p className={'form-content text-center'}>
                        Nom du {type_contrat} :&nbsp;
                        {contract.apporteur1 === '43397' ? contract.souscripteur.nom : contract.apporteurs.apporteur1.raisonSociale}
                        <br/>
                        Numéro du {type_contrat} :&nbsp;
                        {contract.apporteur1 === '43397' ? contract.souscripteur.phone : contract.apporteurs.apporteur1.phone}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default RelanceTel
