import {connect} from 'react-redux'
import {compose} from "redux";
import {getReason} from "../../../../../../../utils/reasons";
import {getContratAccidentClaimLink} from "../../../../../../../redux/selectors/contract/contract.selectors";
import {getUserPermissions} from "../../../../../../../redux/selectors/user/user.selectors";
import {logAction} from "../../../../../../../redux/actions/core/logs/logs.actions";
import Buttons from "./Buttons";

const mapStateToProps = state => {
    return {
        permissions: getUserPermissions(state),
        reasonAccident: getReason(getContratAccidentClaimLink(state)),
    }
}

const mapDispatchToProps = {
    postLogAction: ({entity, type, contrat}) => logAction({entity, type, contrat})
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {postLogAction} = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        postLogActionFormAccident: () => postLogAction({
            entity: null,
            type: 'CLICK_FORM_ACCIDENT',
            contrat: ownProps.contract.id,
            tiers: ownProps.contract.souscripteur.id
        })
    }
}

const ButtonsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(Buttons)

export default ButtonsContainer
