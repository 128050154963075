export const PRODUITS = '[Produits commande]'

export const SET_PRODUITS = `${PRODUITS} Set`
export const FETCH_PRODUITS = `${PRODUITS} Fetch`
export const PUT_PRODUITS = `${PRODUITS} Put`

export const fetchProduits = ({query}) => ({
    type: FETCH_PRODUITS,
    payload: {
        data: query
    }
})

export const setProduits = ({data}) => ({
    type: SET_PRODUITS,
    payload: {
        data
    }
})

export const putProduits = ({query, form}) => ({
    type: PUT_PRODUITS,
    payload: {
        data: query,
        body: form,
        header: 'application/json'
    }
})
