import React from 'react'
import moment from "moment";

const Souscripteur = ({souscripteur, conducteur}) => {
    return (
        <div className={'bloc-info bloc-souscripteur'}>
            <h2>Souscripteur / Conducteur</h2>
            <div className={'content row overflow-auto'}>
                <div className={'col-5 left-content'}><p>Date de naissance&nbsp;:</p></div>
                <div className={'col-7 right-content'}><p>{moment(conducteur.naissance).format('L') || '-'}</p></div>
            </div>
            <div className={'content row overflow-auto'}>
                <div className={'col-5 left-content'}><p>Numéro de permis&nbsp;:</p></div>
                <div className={'col-7 right-content'}><p>{conducteur.numPermis || '-'}</p></div>
            </div>
            <div className={'content row overflow-auto'}>
                <div className={'col-5 left-content'}><p>Adresse&nbsp;:</p></div>
                <div className={'col-7 right-content'}><p>{souscripteur.rue1}</p></div>
            </div>
            {souscripteur.rue2 &&
                <div className={'content row overflow-auto'}>
                    <div className={'col-5 left-content'}><p>Complément d'adresse&nbsp;:</p></div>
                    <div className={'col-7 right-content'}><p>{souscripteur.rue2}</p></div>
                </div>
            }
            {souscripteur.rue3 &&
                <div className={'content row overflow-auto'}>
                    <div className={'col-5 left-content'}><p>Complément d'adresse&nbsp;:</p></div>
                    <div className={'col-7 right-content'}><p>{souscripteur.rue3}</p></div>
                </div>
            }
            <div className={'content row overflow-auto'}>
                <div className={'col-5 left-content'}><p>Code Postal - Ville&nbsp;:</p></div>
                <div className={'col-7 right-content'}><p>{souscripteur.codePostal} {souscripteur.ville}</p></div>
            </div>
            <div className={'content row overflow-auto'}>
                <div className={'col-5 left-content'}><p>Téléphone&nbsp;:</p></div>
                <div className={'col-7 right-content'}><p>{souscripteur.phone || '-'}</p></div>
            </div>
            <div className={'content row overflow-auto'}>
                <div className={'col-5 left-content'}><p>Email&nbsp;:</p></div>
                <div className={'col-7 right-content'}><p>{souscripteur.email || '-'}</p></div>
            </div>
        </div>
    )
}

export default Souscripteur
