import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import Commissions from './Commissions'
import {COMMISSIONS, getCommissions} from "../../../../../../redux/actions/app/commissions/commissions.actions";
import {getErrorEntity, getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {getCommissionsLink} from "../../../../../../redux/selectors/links/links.selectors";
import {
    getDadsCommissions,
    getDataCommissions
} from "../../../../../../redux/selectors/commissions/commissions.selector";
import _ from "lodash";
import ErrorHttpComponent from "../../../../../Commun/Error/ErrorHttpComponent";
import loader from "../../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    return {
        commissions: getDataCommissions(state),
        dads: getDadsCommissions(state),
        uri: getCommissionsLink(state),
        loaded: getLoadingEntity(state, COMMISSIONS) === false,
        error: getErrorEntity(state, COMMISSIONS)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getCommissions: (query) => getCommissions({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uri} = stateProps
    const {getCommissions} = dispatchProps
    const {id} = ownProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getCommissions(_.replace(uri, '{id}', id)),
    }
}

const CommissionsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(Commissions)

export default CommissionsContainer
