import React from 'react';
import {FieldArray} from "redux-form";
import FieldArrayTemoins from "./FieldArrayTemoins";

const Temoins = (props) => {
    return (
        <div className={`form-content`}>
            <h1 className={'title-primary'}>Témoin(s)</h1>

            <FieldArray
                name='temoins'
                component={FieldArrayTemoins}
                changeValue={props.changeValue}
            />
        </div>
    );
};

export default Temoins;
