import React from 'react';
import moment from "moment";
import {Link} from "react-router-dom";

const FlotteAccidentsList = (props) => {
    const {accidents, contract} = props

    return (
        <div className={'content row'}>
            {accidents.data.length >= 1
                ?
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Numero du sinistre</th>
                        <th scope="col">Immatriculation</th>
                        <th scope="col">Situation</th>
                        <th scope="col">Responsabilité</th>
                        <th scope="col">Corporel</th>
                        <th scope="col">Catégorie</th>
                        <th scope="col">Détails</th>
                    </tr>
                    </thead>
                    <tbody>
                    {accidents.data.map((accident, index) =>
                        <tr key={index}>
                            <td>{moment(accident.dateSurv).format('L')}</td>
                            <td>{accident.numCbt}</td>
                            <td>{accident.immatVehicule}</td>
                            <td>{accident.situation}</td>
                            <td>{accident.tauxResp}%</td>
                            <td>{accident.type === 'Corporel' ? 'Oui' : 'Non'}</td>
                            <td>{accident.categorie}</td>
                            <td><Link to={{
                                pathname: `${contract.id}/sinistre/${accident.id}`,
                                state: {
                                    souscripteur: contract.souscripteur
                                }
                            }}
                                      className={'btn btn-more'}
                            /></td>
                        </tr>
                    )}
                    </tbody>
                </table>
                :
                <p className={'no-result'}>Il n'y a pas de sinistre déclaré sur le contrat</p>
            }
        </div>
    );
};

export default FlotteAccidentsList;