import {connect} from 'react-redux'
import {compose} from "redux";
import ReportAccidentNonDeclare from "./ReportAccidentNonDeclare";
import {contractDefaultGetLink} from "../../../../../redux/selectors/links/links.selectors";
import {CONTRACT, getContract} from "../../../../../redux/actions/app/contracts/contracts.actions";
import _ from "lodash";

import ErrorHttpComponent from "../../../../Commun/Error/ErrorHttpComponent";
import {getErrorEntity, getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    return {
        idVehicule: ownProps.match.params.idVehicule,
        idContract: ownProps.match.params.id,
        loaded: getLoadingEntity(state, CONTRACT) === false,
        error: getErrorEntity(state, CONTRACT),
        uri: contractDefaultGetLink(state)
    }
}

const mapDispatchToProps = {
    getContract: (query) => getContract({query})
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getContract} = dispatchProps
    const {uri, idContract} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getContract(_.replace(uri, '{id}', idContract)),
    }
}

const ReportAccidentNonDeclareContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent}),
)(ReportAccidentNonDeclare)

export default ReportAccidentNonDeclareContainer
