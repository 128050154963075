import React from 'react';
import PropTypes from 'prop-types'

const TasksProcessed = (props) => {
    const {nbTasks} = props

    return (
        <div className={'col-2 notifications'}>
            <p><span className={'task'}>{nbTasks}</span> tâches traitées aujourd'hui</p>
        </div>
    );
};

TasksProcessed.defaultProps = {
    nbTasks: 0
};

TasksProcessed.propTypes = {
    nbTasks: PropTypes.number
};

export default TasksProcessed;
