import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import ChangeAccidentStatus from "./ChangeAccidentStatus";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {
    POST_STATUS_ACCIDENT,
    postUpdateAccidentStatus
} from "../../../../../redux/actions/app/accidents/accidents.actions";
import {formValueSelector, reduxForm} from "redux-form";
import {updateAccidentStatusGetLink} from "../../../../../redux/selectors/links/links.selectors";
import _ from "lodash";
import validate from "./validate";

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector('accident-status')

    return {
        uri: updateAccidentStatusGetLink(state),
        accidentId: ownProps.location.state.accident.id,
        contractId: ownProps.match.params.id,
        loading: getLoadingEntity(state, POST_STATUS_ACCIDENT),
        situationCode: ownProps.location.state.accident.situationCode,
        decDommages: ownProps.location.state.accident.decDommages,
        tauxResp: ownProps.location.state.accident.tauxResp,
        garanties: ownProps.location.state.accident.garanties.map((g, index) => {
            return g.sinistree === 'OUI';
        }),
        initialValues: {
            "status": ownProps.location.state.accident.situationCode,
        },
        statusSelected: selector(state, 'status'),
        motifSelected: selector(state, 'motif')
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    postUpdateAccidentStatus,
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uri, accidentId, contractId} = stateProps
    const {postUpdateAccidentStatus} = dispatchProps


    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: form => postUpdateAccidentStatus({
            query: _.replace(_.replace(uri, '{id}', contractId), '{ids}', accidentId),
            form
        })
    }
}


const ChangeAccidentStatusContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'accident-status',
        validate
    })
)(ChangeAccidentStatus)


export default ChangeAccidentStatusContainer
