import React, {useEffect, useState, Fragment} from 'react';
import {Field} from "redux-form";
import RadioFieldRedux from "../../../../../../../Commun/Input/Radio/RadioFieldRedux";
import _ from "lodash";
import TextFieldRedux from "../../../../../../../Commun/Input/Text/TextFieldRedux";
import CheckboxFieldRedux from "../../../../../../../Commun/Input/Checkbox/CheckboxFieldRedux";
import {normalizeBooleanForNoobBelair} from "../../../../../../../../utils/normalizeInputs";
import SelectFieldRedux from "../../../../../../../Commun/Input/Select/SelectFieldRedux";

const FieldsVirementsSinistre = (props) => {

    const {
        poste: {libelle, vetuste, value, commentaire, text, complement, maxValue = null},
        changeValue,
        type,
        index,
        montantTypeReg,
        formValues
    } = props

    const checkFieldValue = formValues && formValues[type] && formValues[type][index] && formValues[type][index].check;

    const [check, setCheck] = useState(false)
    const [majoration, setMajoration] = useState(0)

    useEffect(() => {
        if (value && !check) {
            changeValue(`[${type}][${index}][check]`, 'T')
            handleChange();
        }
    }, [value])

    useEffect(() => {
        if(formValues && formValues[type] && formValues[type][8] && formValues[type][8].check === 'T') {
            changeValue(`[${type}][8][montant]`, Number(15 * montantTypeReg / 100))
        }
        setMajoration(Number(15 * montantTypeReg / 100))
    }, [montantTypeReg])

    const handleChange = () => {
        setCheck(!check)
        changeValue(`[${type}][${index}][libelle]`, libelle)
        changeValue(`[${type}][${index}][type]`, type)
        changeValue(`[${type}][${index}][maxValue]`, maxValue)
        if (vetuste) {
            check ? changeValue(`[${type}][${index}][vetuste]`, "") : changeValue(`[${type}][${index}][vetuste]`, "F")
        }
        if (check) {
            changeValue(`[${type}][${index}][montant]`, "")
        } else if (type === 'indemnisation' && index === 8) {
            changeValue(`[${type}][8][montant]`, Number(15 * montantTypeReg / 100))
        } else if (type === 'penalite' && index === 7) {
            changeValue(`[${type}][7][montant]`, 450)
        }
    }

    return (
        <div className={'section-checkbox'}>
            <Field
                name={`[${type}][${index}][check]`}
                component={RadioFieldRedux}
                type={'checkbox'}
                data={[{label: _.replace(_.capitalize(_.lowerCase(text)), 'sra', 'SRA')}]}
                onChange={() => handleChange()}
                normalize={normalizeBooleanForNoobBelair}
            />

            {
                (check || checkFieldValue === 'T') &&
                <div>
                    {text === 'rachat_de_franchise' && <Fragment>
                        <Field
                            name={`[${type}][${index}][valeur]`}
                            component={SelectFieldRedux}
                            label={`${_.replace(_.capitalize(_.lowerCase(text)), 'sra', 'SRA')}`}
                        >
                            <option value={null}>Sélectionner la franchise</option>
                            <option value={'vol'}>Vol</option>
                            <option value={'accident'}>Tous accident</option>
                        </Field>
                    </Fragment>}

                    {text === 'equipements' && <Field
                        name={`[${type}][${index}][montant]`}
                        component={TextFieldRedux}
                        type={'number'}
                        max={'750'}
                        subLabel={'Montant maximum: 750€'}
                        label={`Montant ${_.replace(_.capitalize(_.lowerCase(text)), 'sra', 'SRA')}`}
                    />}

                    {text === 'casque_et_gants' && <Fragment>
                        <Field
                            name={`[${type}][${index}][montant]`}
                            component={TextFieldRedux}
                            type={'number'}
                            max={'250'}
                            subLabel={'Montant maximum: 250€'}
                            label={`Montant ${_.replace(_.capitalize(_.lowerCase(text)), 'sra', 'SRA')}`}
                        />
                    </Fragment>}

                    {text === 'majoration_valeur_expertise' && <Field
                        name={`[${type}][${index}][montant]`}
                        component={TextFieldRedux}
                        type={'number'}
                        input={{
                            value: majoration,
                            disabled: 'disabled'
                        }}
                        label={`Montant ${_.replace(_.capitalize(_.lowerCase(text)), 'sra', 'SRA')}`}
                    />}

                    {text === 'surfranchise_ma_periode_hivernale' && <Fragment>
                        <Field
                            name={`[${type}][${index}][montant]`}
                            component={TextFieldRedux}
                            type={'number'}
                            label={`Montant ${_.capitalize(_.lowerCase(text))}`}
                            disabled={true}
                        />
                    </Fragment>}

                    {(text !== 'equipements' && text !== 'casque_et_gants' && text !== 'majoration_valeur_expertise' && text !== 'rachat_de_franchise' && text !== 'surfranchise_ma_periode_hivernale') &&
                        <>
                            <Field
                                name={`[${type}][${index}][montant]`}
                                component={TextFieldRedux}
                                label={`Montant ${_.replace(_.capitalize(_.lowerCase(text)), 'sra', 'SRA')}`}
                                subLabel={maxValue ? `Montant maximum: ${maxValue}€` : null}
                            />
                        </>
                    }
                    {
                        vetuste && <Field
                            name={`[${type}][${index}][vetuste]`}
                            component={CheckboxFieldRedux}
                            label={'Vétusté déduite'}
                            normalize={normalizeBooleanForNoobBelair}
                        />
                    }

                    {
                        commentaire && <Field
                            name={`[${type}][${index}][commentaire]`}
                            component={TextFieldRedux}
                            label={'Commentaire'}
                        />
                    }
                    {
                        complement && <Field
                            name={`[${type}][${index}][complement]`}
                            component={TextFieldRedux}
                            label={`Montant des accessoires et equipements`}
                        />
                    }
                </div>
            }
        </div>
    );
};

export default FieldsVirementsSinistre;
