import React, {Component} from 'react'
import Autosuggest from 'react-autosuggest'
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import './autocomplete.scss'
import _ from 'lodash'

/* Wait data = [{label: 'stringLabel', value: 'stringValue'}] */

// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
const escapeRegexCharacters = str => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

const getSuggestionValue = (suggestion, defaultReplace = false, replace = false, replaceBy = '') => {
    let label = defaultReplace ? suggestion.label.replace(/[.*+?^${}()\-_|[\]\\]/g, '') : suggestion.label;
    return replace ? _.replace(label, new RegExp(`[${replace}]`, 'g'), replaceBy) : label;
}

const renderSuggestion = (suggestion, {query}) => {
    const suggestionText = `${suggestion.label}`;
    const matches = AutosuggestHighlightMatch(suggestionText, query);
    const parts = AutosuggestHighlightParse(suggestionText, matches);

    return (<span className={'suggestion-content ' + suggestion.twitter}>
      <span className="name">
        {parts.map((part, index) => {
            const className = part.highlight ? 'highlight' : null;

            return (<span className={className} key={index}>{part.text}</span>);
        })}
      </span>
    </span>);
}

class Autocomplete extends Component {
    constructor(props) {
        super(props);

        this.state = {
            replace: this.props.replace ?? false,
            replaceBy: this.props.replaceBy ?? '',
            value: this.props.input.value || this.props.defaultValue || '',
            suggestions: [],
            setQuery: this.props.setQuery || false,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // update suggestions when data change
        if (prevProps.data !== this.props.data) {
            this.setState({
                suggestions: this.getSuggestions(this.state.value)
            })
            const item = _.find(this.state.suggestions, ['label', this.state.value.toUpperCase()])
            if (item) {
                this.props.input.onChange(item.value)
                this.props.afterValidate && this.props.afterValidate(item.value)
            }
        }
    }


    getSuggestions(value) {
        const escapedValue = escapeRegexCharacters(value.trim());

        if (escapedValue === '') {
            return [];
        }

        const regex = new RegExp('\\b' + escapedValue, 'i');

        return _.slice(this.props.data.filter(item => regex.test(getSuggestionValue(item, true, this.state.replace, this.state.replaceBy))), 0, 10);
    }

    onChange = (event, {newValue, method}) => {
        this.setState({
            value: newValue
        });

        if (this.state.setQuery) {
            this.props.setQuery(newValue);
        }

        const item = _.find(this.state.suggestions, ['label', newValue])

        if (item) {
            this.props.input.onChange(item.value)
        }
    };

    onSuggestionsFetchRequested = ({value}) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    render() {
        const {meta: {touched, error}, subLabel} = this.props
        const {value = false, suggestions} = this.state;

        const inputProps = {
            placeholder: this.props.placeholder || '', value, onChange: this.onChange,
        };

        return (<div className={'form-group'}>
                <label>
                    {this.props.label}
                </label>
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps}/>
                {touched && error && <small className="invalid-feedback">{error}</small>}
                {subLabel && <small>{subLabel}</small>}
            </div>);
    }
}

export default Autocomplete
