import React, {useEffect} from 'react';
import Spinner from "../../../Commun/Spinner/Spinner";
import {tokenUser} from "../../../../redux/selectors/localStorage/localStorage";
import {API_URL} from "../../../../constants/constants";

const HelpRedirection = (props) => {
    const redirectWithAuth = async () => {
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);

        let headers = new Headers();
        headers.append('Authorization', `Bearer ${tokenUser.trim()}`);
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS');
        headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token');

        const blob = await fetch(API_URL + '/help/connexion', {headers})
            .then((response) => {
                if (response.ok) {
                    return response.blob();
                }
                return undefined
            })

        let objectUrl = window.URL.createObjectURL(blob);
        anchor.href = objectUrl;

        anchor.target = props.target || "_blank";
        anchor.click();

        setTimeout(function() {
            document.body.removeChild(anchor);
            window.URL.revokeObjectURL(objectUrl);
        }, 100);
    }


    useEffect(() => {
        redirectWithAuth()
    }, []);

    return <div className={'text-center'}>
        Redirection vers la page d'aide en cours...
        <Spinner/>
    </div>;
};

export default HelpRedirection;
