import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import {TIERS, getTiers} from '../../../../redux/actions/app/tiers/tiers.actions'
import {getErrorEntity, getLoadingEntity} from '../../../../redux/selectors/ui/ui.selectors'
import Tiers from './Tiers'
import {tiersGetLink} from '../../../../redux/selectors/links/links.selectors'
import ErrorHttpComponent from '../../../Commun/Error/ErrorHttpComponent'
import {getTiersData} from '../../../../redux/selectors/tiers/tiers.selectors'
import _ from "lodash";
import {getUserRoles} from "../../../../redux/selectors/user/user.selectors";
import loader from "../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    return {
        idTiers: ownProps.match.params.id,
        tiers: getTiersData(state),
        uri: tiersGetLink(state),
        loaded: getLoadingEntity(state, TIERS) === false,
        error: getErrorEntity(state, TIERS),
        roles: getUserRoles(state)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getTiers: ({query, id}) => getTiers({query, id}),
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getTiers} = dispatchProps
    const {uri, idTiers} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getTiers({query: (_.replace(uri, '{id}', idTiers)), id: idTiers}),
    }
}

const TiersContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(Tiers)

export default TiersContainer
