import {connect} from 'react-redux'
import {formValueSelector} from 'redux-form'
import Adversaires from './Adversaires'

const mapStateToProps = state => {
    const selector = formValueSelector('declaration-sinistre')

    return {
        adversaires: selector(state, 'adversaires'),
        nbAdversaires: selector(state, 'nb_vehicules')
    }
}

const AdversairesContainer = connect(mapStateToProps)(Adversaires)

export default AdversairesContainer
