import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {getErrorEntity, getLoadingEntity} from '../../../../../redux/selectors/ui/ui.selectors'
import Pieces from './Pieces'
import {PIECES, getPieces} from '../../../../../redux/actions/app/pieces/pieces.actions'
import {getPiecesData} from '../../../../../redux/selectors/pieces/pieces.selector'
import ErrorHttpComponent from '../../../../Commun/Error/ErrorHttpComponent'
import {piecesContractGetLink} from "../../../../../redux/selectors/links/links.selectors";
import loader from "../../../../Commun/Spinner/Loader/Loader";
import {getIsWarkamContract} from "../../../../../redux/selectors/contract/contract.selectors";

const mapStateToProps = state => {
    return {
        pieces: getPiecesData(state),
        uri: piecesContractGetLink(state),
        loaded: getLoadingEntity(state, PIECES) === false,
        error: getErrorEntity(state, PIECES),
        isWakam: getIsWarkamContract(state)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getPieces: (query) => getPieces({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getPieces} = dispatchProps
    const {uri} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getPieces(uri)
    }
}

const PiecesContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(Pieces)

export default PiecesContainer
