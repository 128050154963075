import {combineReducers} from 'redux'
import {reducer as form} from 'redux-form'
import {linksReducer} from './links/links.reducer'
import {userReducer} from './user/user.reducer'
import {uiReducer} from './ui/ui.reducer'
import {tasksReducer} from './tasks/tasks.reducer'
import {faqReducer} from './faq/faq.reducer'
import {contractReducer} from './contract/contract.reducer'
import {documentsReducer} from './documents/documents.reducer'
import {documentsUtilesReducer} from './documents_utiles/documents_utiles.reducer'
import {taskDetailReducer} from './task/task.reducer'
import {tiersReducer} from './tiers/tiers.reducer'
import {accidentsReducer} from './accidents/accidents.reducer'
import {activityReducer} from './activity/activity.reducer'
import {contractsReducer} from './contract/contracts.reducer'
import {close_taskReducer} from './close_task/close_task.reducer'
import {quittancesReducer} from './quittances/quittances.reducer'
import {accidentReducer} from './accidents/accident.reducer'
import {activitiesReducer} from './grc/grc.reducer'
import {notesReducer} from './notes/notes.reducer'
import {reassign_taskReducer} from './reassign_task/reassign_task.reducer'
import {piecesReducer} from './pieces/pieces.reducer'
import {searchReducer} from './search/search.reducer'
import {documentationReducer} from './documentation/documentation.reducer'
import {virementsReducer} from './virements/virements.reducer'
import {virementReducer} from './virements/virement.reducer'
import {quittanceReducer} from './quittances/quittance.reducer'
import {insurersReducer} from './insurers/insurers.reducer'
import {correspondantsReducer} from './correspondants/correspondants.reducer'
import {commerciauxReducer} from './commerciaux/commerciaux.reducer'
import {apporteursReducer} from './apporteurs/apporteurs.reducer'
import {statsReducer} from './stats_courtier/stats_courtier.reducer'
import {dashboardReducer} from './dashboard/dashboard.reducer'
import {ficheReducer} from "./fiche/fiche.reducer";
import {reclamationReducer} from "./reclamation/reclamation.reducer";
import {faqCategoriesReducer} from "./faq_categories/faq_categories.reducer";
import {companysReducer} from "./companys/companys.reducer";
import {flotteReducer} from "./flotte/flotte.reducer";
import {flotteDetailReducer} from "./flotte/flotte_detail.reducer";
import {commissionsReducer} from "./commissions/commissions.reducer";
import {correspondantReducer} from "./correspondants/correspondant.reducer";
import {reglementDetailReducer} from "./reglement_detail/reglement_detail.reducer";
import {commandeProduitsReducer} from "./commande_produits/commande_produits.reducer";
import {signaturesReducer} from "./signatures/signatures.reducer";
import {navigationActivitiesReducer} from "./navigation_activities/navigation_activities.reducer";
import {navigationContractsReducer} from "./navigation_contracts/navigation_contracts.reducer";
import {rapportBcaReducer} from "./bca/bca.reducer";
import {casIdaReducer} from "./cas_ida/cas_ida.reducer";
import {accessoiresReducer} from "./accessoires/accessoires.reducer";
import {garageReducer} from "./garage/garage.reducer";
import {feedbackReducer} from "./feedback/feedback.reducer";
import {quotesReducer} from "./quotes/quotes.reducer";
import {accessoireReducer} from "./accessoires/accessoire.reducer";
import {parrainagesReducer} from "./parrainages/parrainages.reducer";
import {versionningReducer} from "./versionning/versionning.reducer";
import {dashboardTeleventeReducer} from "./dashboard_televente/dashboard_televente.reducer";
import {tiersPayeurReducer} from "./tiers_payeur/tiers_payeur.reducer";
import {derogationsReducer} from './derogations/derogations.reducer'
import {
    dashboardConseillerTeleventeReducer
} from "./dashboard_conseiller_televente/dashboard_conseiller_televente.reducer";
import {settingsReducer} from "./settings/settings.reducer";
import {courtiersReducer} from "./courtiers/courtiers.reducer";
import {antecedentsReducer} from "./antecedents/antecedents.reducer";
import {giftCardsReducer} from "./gift_cards/gift_cards.reducer";
import {quoteReducer} from "@/redux/reducers/quotes/quote.reducer";
import {antecedentPeriodReducer} from "./antecedents/antecedent_period.reducer";
import {tarifContractReducer} from "./contract/tarif_contract.reducer";
import {fidelisationsReducer} from "./fidelisations/fidelisations.reducer";
import {fidelisationReducer} from "./fidelisations/fidelisation.reducer";

export const AppReducers = combineReducers({
    form,
    links: linksReducer,
    user: userReducer,
    ui: uiReducer,
    tasks: tasksReducer,
    faq: faqReducer,
    faq_categories: faqCategoriesReducer,
    contract: contractReducer,
    documents: documentsReducer,
    documents_utiles: documentsUtilesReducer,
    task: taskDetailReducer,
    tiers: tiersReducer,
    accidents: accidentsReducer,
    activity: activityReducer,
    contracts: contractsReducer,
    close_task: close_taskReducer,
    quittances: quittancesReducer,
    accident: accidentReducer,
    activities: activitiesReducer,
    reassign_task: reassign_taskReducer,
    notes: notesReducer,
    pieces: piecesReducer,
    search: searchReducer,
    documentation: documentationReducer,
    virements: virementsReducer,
    virement: virementReducer,
    quittance: quittanceReducer,
    insurers: insurersReducer,
    correspondant: correspondantReducer,
    correspondants: correspondantsReducer,
    commerciaux: commerciauxReducer,
    apporteurs: apporteursReducer,
    stats: statsReducer,
    dashboard: dashboardReducer,
    dashboard_televente: dashboardTeleventeReducer,
    dashboard_conseiller_televente: dashboardConseillerTeleventeReducer,
    fiche: ficheReducer,
    reclamation: reclamationReducer,
    companys: companysReducer,
    flotte: flotteReducer,
    flotte_detail: flotteDetailReducer,
    commissions: commissionsReducer,
    reglement_detail: reglementDetailReducer,
    commande_produits: commandeProduitsReducer,
    signatures: signaturesReducer,
    navigation_activities: navigationActivitiesReducer,
    navigation_contract: navigationContractsReducer,
    rapport_bca: rapportBcaReducer,
    cas_ida: casIdaReducer,
    garage: garageReducer,
    feedback: feedbackReducer,
    accessoires: accessoiresReducer,
    quote: quoteReducer,
    quotes: quotesReducer,
    accessoire: accessoireReducer,
    parrainages: parrainagesReducer,
    versionning: versionningReducer,
    tiers_payeur: tiersPayeurReducer,
    derogations: derogationsReducer,
    settings: settingsReducer,
    courtiers: courtiersReducer,
    antecedent_period: antecedentPeriodReducer,
    antecedents: antecedentsReducer,
    gift_cards: giftCardsReducer,
    tarif_contract: tarifContractReducer,
    fidelisations: fidelisationsReducer,
    fidelisation: fidelisationReducer
})
