import React, {useEffect} from 'react';
import {FieldArray} from "redux-form";
import FieldArrayBlesses from "./FieldArrayBlesses";

const Blesses = (props) => {
    useEffect(() => {
        document.getElementById('declaration-pers').scrollIntoView({behavior: 'smooth'})
    }, [])

    return (
        <div className="col-md-6 col-sm-12" style={{marginBottom: 30}}>
            <div className={`form-content h-100`}>
                <h1 className={'title-primary'}>Bléssé(e)(s)</h1>

                <FieldArray
                    name='victimes'
                    component={FieldArrayBlesses}
                />
            </div>
        </div>
    );
};

export default Blesses;
