import {connect} from 'react-redux'
import TasksProcessed from "./TasksProcessed";
import {getNbTasksUi} from "../../../../redux/selectors/ui/ui.selectors";
import {userGetLink} from "../../../../redux/selectors/links/links.selectors";
import _ from "lodash";

const mapStateToProps = state => {
    return {
        nbTasks: getNbTasksUi(state) ? _.parseInt(getNbTasksUi(state)) : 0,
        query: userGetLink(state)
    }
}

const TasksProcessedContainer = connect(mapStateToProps)(TasksProcessed)

export default TasksProcessedContainer
