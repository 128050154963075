import {SET_VERSIONNING} from '../../actions/app/versionning/versionning.actions'

export const versionningReducer = (versionning = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_VERSIONNING) {
        return payload
    } else {
        return versionning
    }
}
