import _ from "lodash";
import moment from "moment/moment";

const validate = (values, { contract }) => {
    console.log('values', values);

    const dateSurvMin = contract.situation.dateAffaireNouvelle;
    const dateSurvMax = contract.situation.statutCode === "6" && contract.situation.dateFin !== null ? moment(contract.situation.dateFin) : moment();

    const errors = {};

    const requiredFields = ['catsinorigin', 'numCie'];

    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Champ obligatoire';
        }
    });

    if (values.numCie !== undefined && !_.startsWith(values.numCie.toLowerCase(), 'c')) {
        errors.numCie =
            'La référence Compagnie dans ce formulaire doit commencer par un "C", si ce n\'est pas le cas il faut utiliser le formulaire de déclaration classique';
    }

    if (values.dateSurv && values.dateSurv.isBefore(dateSurvMin)) {
        errors.dateSurv = `La date de survenance doit être postérieure au ${moment(dateSurvMin).format('DD/MM/YYYY')}`;
    }

    if (values.dateSurv && values.dateSurv.isAfter(dateSurvMax)) {
        errors.dateSurv = `La date de survenance doit être antérieure au ${moment(dateSurvMax).format('DD/MM/YYYY')}`;
    }

    return errors;
};

export default validate
