export const getCategoryByTypeAccident = type_sinistre => {
    const categories = {
        accident: [
            {
                value: "103.1",
                label: "Un accident avec un véhicule"
            },
            {
                value: "103.2",
                label: "Un accident avec plusieurs véhicules"
            },
            {
                value: "103.3",
                label: "Un accident avec un piéton/vélo "
            },
            {
                value: "104.1",
                label: "Un accident avec un délit de fuite"
            },
            {
                value: "104.2",
                label: "Un accident seul"
            },
            {
                value: "104.3",
                label: "Un accident en stationnement"
            },
            {
                value: "104.4",
                label: "Un choc avec un animal"
            }
        ]
    }

    return categories[type_sinistre] || []
}
