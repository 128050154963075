export const QUITTANCES = '[Quittances]'

export const GET_QUITTANCES = `${QUITTANCES} Get`
export const SET_QUITTANCES = `${QUITTANCES} Set`

export const getQuittances = ({query}) => ({
    type: GET_QUITTANCES,
    payload: {
        data: query
    }
})

export const setQuittances = ({data}) => ({
    type: SET_QUITTANCES,
    payload: {data},
    meta: {
        entity: QUITTANCES
    }
})
