import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import Requetes from './Requetes'
import {BordereauxGetLink, CartesVertesComptaGetLink} from "../../../../redux/selectors/links/links.selectors";
import {
    postExtractionCartesVertes,
    postGenerateBordereaux
} from "../../../../redux/actions/app/requetes/requetes.actions";
import loader from "../../../Commun/Spinner/Loader/Loader";
import {getUserRoles} from "../../../../redux/selectors/user/user.selectors";

const mapStateToProps = (state) => {
    return {
        uri: CartesVertesComptaGetLink(state),
        uri_bordereaux: BordereauxGetLink(state),
        roles: getUserRoles(state)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    postExtractionCartesVertes,
    postGenerateBordereaux
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {postExtractionCartesVertes, postGenerateBordereaux} = dispatchProps
    const {uri, uri_bordereaux} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        submitExtractionCVComptabilite: () => postExtractionCartesVertes({query: uri}),
        submitBordereaux: () => postGenerateBordereaux({query: uri_bordereaux}),
    }
}

const RequetesContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Requetes)

export default RequetesContainer
