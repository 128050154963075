import React from 'react'
import {withRouter} from "react-router"
import _ from 'lodash'
import moment from "moment";

const SearchCard = (props) => {
    const {item, toggleSearch, setLocalStorage} = props
    const {type, tiers, id_tiers, valeur} = item

    const redirect = (item) => {
        toggleSearch()
        setLocalStorage && setLocalStorage(item)
        switch (item.type) {
            case 'TIERS':
            default:
                return window.location = `/tiers/${item.id_tiers}`
            case 'SINISTRE':
                return window.location = `/contrat/${item.id_contrat}/sinistre/${item.id_sinistre}`
            case 'CONTRAT':
                return window.location = `/contrat/${item.id_contrat}`
        }
    }

    return (
        <div className={`col-4 card-search`}>
            <div className={`content ${type} ${tiers && tiers.type.indexOf("1") !== -1 ? 'client' : ''}`}>
                <div onClick={() => redirect(item)}>
                    <h3 className={'border-bottom'}>{id_tiers}</h3>
                    <div>
                        <p className={'text-uppercase'}>{_.capitalize(item.type)}&nbsp;-&nbsp;{valeur}</p>
                        {tiers && tiers.address_city ?
                            <p>{tiers.address_street_1} {tiers.address_postalcode} {tiers.address_city}</p> : null}
                        {tiers && tiers.date_naissance !== null ?
                            <p>{moment(tiers.date_naissance).format('L') || '-'}</p> : null}
                    </div>
                </div>
            </div>
        </div>
    )
}


export default withRouter(SearchCard)
