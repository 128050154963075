import React from 'react'

export const Badge = ({status, noText = false, classBadge = false}) => {
    switch (status) {
        case 'En cours':
        case 'Actif':
        case 'vert':
            return <span
                className={`badge badge-success badge-${noText} ${classBadge ? classBadge : ''}`}>{!noText && status}</span>
        case 'En établissement':
        case 'Suspendue':
        case 'A régulariser':
        case 'in_progress':
        case 'orange':
            return <span
                className={`badge badge-warning badge-${noText} ${classBadge ? classBadge : ''}`}>{!noText && status}</span>
        case 'Avt en attente':
        case 'Avenant':
        case 'Dont/acte':
            return <span
                className={`badge badge-info badge-${noText} ${classBadge ? classBadge : ''}`}>{!noText && status}</span>
        case 'Résiliée':
        case 'Résiliation':
        case 'Résil/Impayé':
        case 'fin de pièce':
        case 'Inactif':
            return <span
                className={`badge badge-danger badge-${noText} ${classBadge ? classBadge : ''}`}>{!noText && status}</span>
        case 'sans effet':
        case 'sans suite':
            return <span
                className={`badge badge-secondary badge-${noText} ${classBadge ? classBadge : ''}`}>{!noText && status}</span>
        case 'has_account_false':
        case 'has_account_undefined':
            return <span className={`badge badge-danger ${classBadge ? classBadge : ''}`}>Pas d'espace assuré</span>
        case 'has_demat_null':
        case 'has_demat_T':
            return <span className={`badge badge-danger ${classBadge ? classBadge : ''}`}>Dématérialisation désactivé</span>
        case 'has_account_true':
            return <span className={`badge badge-success ${classBadge ? classBadge : ''}`}>Espace assuré crée</span>
        case 'has_demat_F':
            return <span className={`badge badge-success ${classBadge ? classBadge : ''}`}>Dématérialisation active</span>
        case 'sinistre clos':
            return <span className={`badge badge-danger ${classBadge ? classBadge : ''}`}>Sinistre clos</span>
        case 'sinistre terminé':
            return <span className={`badge badge-danger ${classBadge ? classBadge : ''}`}>Sinistre terminé</span>
        case 'sinistre ouvert':
            return <span className={`badge badge-success ${classBadge ? classBadge : ''}`}>Sinistre ouvert</span>
        case 'En banque':
            return <span className={`badge badge-success ${classBadge ? classBadge : ''}`}>{status}</span>
        case 'REFUS':
            return <span className={`badge badge-danger ${classBadge ? classBadge : ''}`}>{status}</span>
        case 'VALIDE':
        case 'sinistre pré-déclaré':
        case 'sinistre en attente':
            return <span className={`badge badge-warning ${classBadge ? classBadge : ''}`}>{status}</span>
        default:
            return status
    }
}

export default Badge
