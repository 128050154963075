import React, {Fragment} from 'react';
import DashboardTeleventeTasksContentContainer
    from "./DashboardTeleventeTasksContent/DashboardTeleventeTasksContentContainer";
import {Link} from "react-router-dom";
import {redirectInNewWindow} from "../../../../utils/function";
import {openInWindow} from "../../../../constants/constants";

const DashboardTelevente = (props) => {
    return (
        <div className={'suivi-container tasks-container container'}>
            <div className={'row'}>
                <div className={'col-12 text-right mb-4'}>
                    <Link className={'btn btn-primary btn-small'} onClick={(e) => redirectInNewWindow({
                        e,
                        url: `/dashboard-tlv-extraction`,
                        name: openInWindow.extractQuote
                    })}>Extraire les données gestionnaire</Link>
                </div>

                <hr className={'col-12 my-4'}/>
                <div className={'col-12 buttons-dashboard'}>
                    <div className={'row'}>
                        <div className={'col-6'}>
                            <h1 className={`title-primary title-dashboard title-suivi`}>Tâches ADM</h1>
                        </div>
                    </div>
                </div>
            </div>
            <Fragment>
                <div className={'margin-top panieres'}>
                    <DashboardTeleventeTasksContentContainer/>
                </div>
            </Fragment>
        </div>
    );
};

export default DashboardTelevente;
