import { connect } from 'react-redux';
import { compose } from 'redux';
import DerogationItem from './DerogationItem';
import { getContractData } from '../../../../../redux/selectors/contract/contract.selectors';
import { DELETE_DEROGATIONS, deleteDerogations } from '../../../../../redux/actions/app/derogations/derogations.actions';
import _ from 'lodash';
import { getLoadingEntity } from '../../../../../redux/selectors/ui/ui.selectors';
import { deleteDerogationLink } from '../../../../../redux/selectors/links/links.selectors';

const mapStateToProps = (state, ownProps) => {
    const contract = getContractData(state);

    return {
        idTiers: contract.souscripteur.id,
        idContract: contract.id,
        idAccident: ownProps.idAccident ?? null,
        uri: deleteDerogationLink(state),
        loading: getLoadingEntity(state, DELETE_DEROGATIONS) === true,
    };
};

const mapDispatchToProps = {
    deleteDerogations,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { deleteDerogations } = dispatchProps;
    const { uri, idTiers, idContract, idAccident } = stateProps;
    const { id: idDerogation } = ownProps.derogation;

    let query = _.replace(uri, '{tiers_id}', idTiers);
    query = _.replace(query, '{contract_id}', idContract);
    query = _.replace(query, '{accident_id}', idAccident);
    query = _.replace(query, '{derogation_id}', idDerogation);

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        onSubmitDelete: () => deleteDerogations({ query }),
    };
};

const DerogationItemContainer = compose(connect(mapStateToProps, mapDispatchToProps, mergeProps))(DerogationItem);

export default DerogationItemContainer;
