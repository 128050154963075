import {connect} from 'react-redux'
import {change, formValueSelector} from 'redux-form'
import Degats from './Degats'
import {bindActionCreators} from "redux";

const mapStateToProps = state => {
    const selector = formValueSelector('constat')

    return {
        vehiculeDommage: selector(state, 'vehiculeDommage'),
        dommagesDeclares: selector(state, 'dommagesDeclares'),
        equipementsDommage: selector(state, 'equipementsDommage'),
        constatCheck: selector(state, 'constat_accident'),
        catsin: selector(state, 'catsinorigin'),
        document_police: selector(state, 'document_police'),
        point_choc: selector(state, 'point_choc'),
        gants: selector(state, 'equipementsDommageGants'),
        bottes: selector(state, 'equipementsDommageBottes'),
        blouson: selector(state, 'equipementsDommageBlouson'),
        casque: selector(state, 'equipementsDommageCasque'),
        airbag: selector(state, 'equipementsDommageAirbag'),
        autres: selector(state, 'equipementsDommageAutres'),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    changeValue: (field, value) => change('constat', field, value),
}, dispatch)

const DegatsContainer = connect(mapStateToProps, mapDispatchToProps)(Degats)

export default DegatsContainer
