import _ from 'lodash';
import CallToAction from '../../../../Commun/Button/CallToAction/CallToAction';

const Hamon = ({ hamon, contract, postRemoveHamon, postConfirmResilHamon, query_resil_hamon, ...props }) => {
    const handleConfirmHamonChange = (value) => {
        postConfirmResilHamon({
            query: _.replace(query_resil_hamon, '{id}', contract.id),
            form: { confirm_resil: value },
        });
    };

    return (
        <div className={'bloc-info bloc-contrat'}>
            <h2>Souscription Loi Hamon</h2>
            <CallToAction
                url={{
                    pathname: `/contrat/${contract.id}/modifier-informations-hamon`,
                    state: {
                        title: `${contract.souscripteur.titre} ${contract.souscripteur.nom} ${contract.souscripteur.id}`,
                        subtitle: `Contrat n°${contract.numeroPolice} - ${contract.title}`,
                        id_assureur: hamon.id,
                        assureur: hamon.raisonSociale,
                        contrat: hamon.oldPolice,
                        id_contrat: contract.id,
                    },
                }}
                action={'edit-note'}
                toolText={'Editer les informations'}
            />
            <CallToAction
                action={'delete-hamon'}
                toolText={'Supprimer les informations'}
                onClick={() => {
                    if (window.confirm('Êtes-vous sûr(e) de vouloir supprimer la Loi Hamon ?')) postRemoveHamon();
                }}
            />

            <div className={'content row'}>
                <div className={'col-5 left-content'}>
                    <p>Raison sociale :</p>
                    <p>Adresse :</p>
                    <p>Code Postal - Ville :</p>
                    <p>Numéro de contrat :</p>
                    <p>Confirmation de la résiliation :</p>
                </div>
                <div className={'col-7 right-content'}>
                    <p>{hamon.raisonSociale}</p>
                    <p>
                        {hamon.adresse.rue1} {hamon.adresse.rue2}
                    </p>
                    <p>
                        {hamon.adresse.codePostal} {hamon.adresse.ville}
                    </p>
                    <p>{hamon?.oldPolice.length > 0 ? hamon.oldPolice : 'Non renseigné'}</p>
                    <p>
                        {hamon.confirm_resil === 'T' ? 'Oui' : hamon.confirm_resil === 'F' ? 'Non' : 'Sans réponse'} { ' | '}
                        {hamon.confirm_resil !== 'T' && (
                            <a onClick={() => {
                                if (window.confirm('Êtes-vous sûr(e) de vouloir confirmer la résiliation ?')) handleConfirmHamonChange('T');
                            }} className={'pointer'}>
                                Confirmer la résiliation
                            </a>
                        )}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Hamon;
