import {GET_COURTIERS, setCourtiers, COURTIERS} from '../../../actions/app/courtiers/courtiers.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setIsLogin, setLoader} from '../../../actions/app/ui/ui.actions'

export const courtiersMiddleware = () => next => action => {
    next(action)

    switch (action.type) {

        case GET_COURTIERS:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: COURTIERS}),
                setLoader({state: true, entity: COURTIERS})
            ])
            break

        case `${COURTIERS} ${API_SUCCESS}`:
            next([
                setIsLogin({state: true, entity: COURTIERS}),
                setCourtiers({courtiers: action.payload.data}),
                setError({state: false, entity: COURTIERS}),
                setLoader({state: false, entity: COURTIERS})
            ])
            break

        case `${COURTIERS} ${API_ERROR}`:
            next([
                setIsLogin({state: false, entity: COURTIERS}),
                setError({state: true, entity: COURTIERS}),
                setLoader({state: false, entity: COURTIERS})
            ])
            break

        default:
            break
    }
    return null
}
