import React, { Fragment, useState } from 'react';
import moment from "moment";
import CallToAction from "../Button/CallToAction/CallToAction";
import { redirectInNewWindow } from "../../../utils/function";
import _ from "lodash";
import { openInWindow } from "../../../constants/constants";

const QuittanceCard = ({ quittance, publishUrl, contractId, tiersId, roles, postPayReceipt, postLogActionEditDocument }) => {
    const [disabledBtnPay, setDisabledBtnPay] = useState(false);

    const handleClick = () => {
        postPayReceipt({
            contract: quittance.id_contrat,
            receipt: quittance.id,
        });
        setDisabledBtnPay(true);
    };

    const handleClickEditDocument = (quittanceId) => {
        postLogActionEditDocument(quittanceId);
    };

    return (
        <Fragment>
            <tr>
                <td>
                    du {moment(quittance.dateDebut).format('L')} <br /> au {moment(quittance.dateFin).format('L')}
                </td>
                <td>{quittance.numPiece}</td>
                <td>{quittance.type}</td>
                <td>{quittance.infos}</td>
                <td>{quittance.montant}&nbsp;€</td>
                <td>{quittance.fraisGestion}&nbsp;€</td>
                <td>{quittance.solde}&nbsp;€</td>
                <td>
                    {!_.includes(roles, 'gestionnaire televente') && (
                        <CallToAction
                            action={'edit-document'}
                            toolText={'Editer un document'}
                            url={`${publishUrl}&contrat=${contractId}&tiers=${tiersId}&quittance=${quittance.id_belair}`}
                            publish
                            link_externe
                            onClick={() => {
                                handleClickEditDocument(quittance.id_belair);
                            }}
                        />
                    )}
                </td>
                <td>
                    {quittance.pay_link && (
                        <CallToAction
                            link_externe
                            action={'paiement'}
                            toolText={'Faire un paiement'}
                            url={`${quittance.pay_link}${quittance.pay_link.indexOf('?') > 0 ? '&' : '?'}url_return=${window.location.href}`}
                        />
                    )}
                    {!quittance.pay_link_mail_reason[0] && (
                        <CallToAction
                            action={'paiement-mail'}
                            toolText={'Envoyer le lien de paiement au client'}
                            onClick={handleClick}
                            disabled={disabledBtnPay}
                            url={'#'}
                        />
                    )}
                </td>
                <td>
                    {!_.includes(roles, 'gestionnaire televente') && (
                        <button
                            className={'btn btn-more'}
                            onClick={(e) => redirectInNewWindow({
                                e,
                                url: `/quittances/${quittance.id_belair}`,
                                name: openInWindow.quittance
                            })}
                        />
                    )}
                </td>
            </tr>
        </Fragment>
    );
};

export default QuittanceCard;
