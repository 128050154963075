import {SET_CONTRACT, SET_VEHICULE_CONTRACT} from '../../actions/app/contracts/contracts.actions'

export const contractReducer = (contract = {}, action = {}) => {
    const {payload} = action

    switch (action.type) {
        case SET_CONTRACT:
            return payload
        case SET_VEHICULE_CONTRACT:
            return {...contract, data: { ...contract.data, vehicule: payload.data}}
        default:
            return contract
    }
}
