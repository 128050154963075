import {REASSIGN_TASK} from "../../actions/app/tasks/tasks.actions";

export const reassign_taskReducer = (reassign = false, action = {}) => {
    const {payload} = action

    if (action.type === REASSIGN_TASK) {
        return payload
    } else {
        return reassign
    }
}
