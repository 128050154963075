export const REGLEMENT_DETAIL = '[Reglement détail]'

export const GET_REGLEMENT_DETAIL = `${REGLEMENT_DETAIL} Get`
export const SET_REGLEMENT_DETAIL = `${REGLEMENT_DETAIL} Set`

export const getReglementDetail = ({query}) => ({
    type: GET_REGLEMENT_DETAIL,
    payload: {
        data: query
    }
})


export const setReglementDetail = ({data}) => ({
    type: SET_REGLEMENT_DETAIL,
    payload: data
})