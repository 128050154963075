import React from 'react';
import PropTypes from 'prop-types'
import moment from "moment";

const CallBye = (props) => {
    const {task: {paniere, date, description, type}} = props

    return (
        <div className={'container'}>
            <div className={'row'}>
                <div className={'col text-center'}>
                    <h2 className={'title-primary text-uppercase'}>{paniere}</h2>
                    <p className={'subtitle'}>Tâche du {moment(date).format('L')}. Recu par {type}</p>
                </div>
            </div>
            <div className={'header-row row'}>
                <div className={'col-12'}>
                    <p className={'form-content text-center'}>
                        {description && <span dangerouslySetInnerHTML={{__html: description}}/>}
                    </p>
                </div>
            </div>
        </div>
    );
};

CallBye.defaultProps = {
    task: {
        paniere: '',
        date: moment(),
        description: '',
        type: ''
    }
};

CallBye.propTypes = {
    task: PropTypes.object
};

export default CallBye;
