import React from 'react';
import {FieldArray} from "redux-form";
import Spinner from "../../../../Commun/Spinner/Spinner";
import AccessoiresFieldArray from "../../Tasks/TaskDetail/Facture/AccessoiresFieldArray";


const AddAccessoires = (props) => {
    const {handleSubmit, loading, change, accessoires, accident_obj} = props

    return (
        <div className={'contract-container container'}>
            <div className={'row header-row'}>
                <div className={'col-12'}>
                    <form onSubmit={handleSubmit} className={'form-content'}>
                        <div className="row">
                            <div className="col">
                                <FieldArray
                                    name={'accessoires'}
                                    component={AccessoiresFieldArray}
                                    accessoires={accessoires}
                                    dateSurv={accident_obj.dateSurv}
                                    change={change}
                                    reparateur={accident_obj?.reparateur}
                                />
                            </div>
                        </div>
                        {
                            loading ? <Spinner style={{display: 'block', margin: 'auto'}}/> :
                                <button className={'btn btn-primary d-block mt-5'}
                                        data-test-id="avenant_simple_btn_submit" type={'submit'}>
                                    Ajouter
                                </button>
                        }

                    </form>
                </div>
            </div>
        </div>
    );
};


AddAccessoires.defaultProps = {};


AddAccessoires.propTypes = {};


export default AddAccessoires;
