import React, {useState} from 'react';
import './virementsSinistre.scss'
import VirementsSinistreContainer from "./Steps/Virement/VirementsSinistreContainer";
import DetailsReglementContainer from "./Steps/DetailsReglement/DetailsReglementContainer";
import _ from "lodash";
import Spinner from "../../../../Commun/Spinner/Spinner";

const VirementsSinistreInitForm = (props) => {
    const {
        handleSubmit,
        accident,
        confirmeVirement,
        contract,
        changeValue,
        loading
    } = props

    const [step, setStep] = useState(0)
    const [clients] = useState(_.filter(accident.intervenants, ['type', 'Client']))
    const [reparateurs] = useState(_.filter(accident.intervenants, {'type': 'Réparateur'}))
    const [lese] = useState(_.filter(accident.intervenants, {'type': 'Lésé'}))
    const [choiceBeneficiaire, setChoiceBeneficiaire] = useState(false)

    const setBeneficiaire = (beneficiaire) => {
        const intervenantBeneficiaire = _.find(accident.intervenants, ['id', beneficiaire])
        setChoiceBeneficiaire(intervenantBeneficiaire)
        if (intervenantBeneficiaire) {
            changeValue('iban', intervenantBeneficiaire.iban)
            changeValue('titulaire', intervenantBeneficiaire.titulaire)
            changeValue('reglement_cheque', 'F')
        }
    }

    return (
        <div className={'contract-container container'}>
            <div className={'row'}>
                <div className={'col-12'}>
                    <h1 className={'title-primary'}>{`${contract.souscripteur.titre} ${contract.souscripteur.nom} ${contract.souscripteur.id} - Sinistre n°${accident.numCbt} - ${accident.marqueVehicule} ${accident.modeleVehicule}`}</h1>
                </div>
            </div>
            {
                confirmeVirement ?
                    <div className={'row margin-top text-center'}>
                        <div className={'col'}>
                            <h2 className={'title-primary title-success'}>Le virement a bien été enregistré&nbsp;!</h2>
                        </div>
                    </div>
                    : confirmeVirement === false ?
                        <div className={'row margin-top text-center'}>
                            <div className={'col'}>
                                <h2 className={'title-primary d-block'}>Une erreur est survenue le virement n'a pas été
                                    enregistré&nbsp;!</h2>
                                <span className={'btn btn-primary'} onClick={() => window.location.reload()}>Recommencer le virement</span>
                            </div>
                        </div>
                        :  <div className={'row header-row'}>
                            <div className={'offset-md-2 col-8 header-row'}>
                                <form className={'form-content'} id="virements-sinistre" onSubmit={handleSubmit}>
                                    {loading ? <div className="text-center"><p className={"font-weight-bolder"}>Chargement en cours</p><Spinner /></div> : <>
                                        {
                                            step === 0 ?
                                                <DetailsReglementContainer
                                                    {...props}
                                                    clients={clients}
                                                    reparateurs={reparateurs}
                                                    lese={lese}
                                                    setStep={setStep}
                                                    setBeneficiaire={setBeneficiaire}
                                                />
                                                : <VirementsSinistreContainer
                                                    {...props}
                                                    clients={clients}
                                                    reparateurs={reparateurs}
                                                    lese={lese}
                                                    setBeneficiaire={setBeneficiaire}
                                                    choiceBeneficiaire={choiceBeneficiaire}
                                                />
                                        }
                                    </> }
                                </form>
                            </div>
                        </div>
            }
        </div>
    );
};

export default VirementsSinistreInitForm;
