import {connect} from 'react-redux'
import {compose} from "redux";
import {reduxForm} from "redux-form";
import loader from "../../../../../Commun/Spinner/Loader/Loader";
import ErrorHttpComponent from "../../../../../Commun/Error/ErrorHttpComponent";
import {
    postAnalyseFraudeGetLink,
} from "../../../../../../redux/selectors/links/links.selectors";
import _ from "lodash";
import {getErrorEntity, getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {
    CLOSE_TASK,
    POST_ANALYSE_FRAUDE_TASK,
    postAnalyseFraudeTask
} from "../../../../../../redux/actions/app/tasks/tasks.actions";
import validate from './validate'
import AnaFraude from "./AnaFraude";

const mapStateToProps = (state,ownProps) => {
    return {
        task: ownProps.task,
        uri: postAnalyseFraudeGetLink(state),
        terminateQuery: ownProps.terminateTask,
        loadingTerminate: getLoadingEntity(state, CLOSE_TASK),
        loadingFraude: getLoadingEntity(state, POST_ANALYSE_FRAUDE_TASK),
        errorFraude: getErrorEntity(state, POST_ANALYSE_FRAUDE_TASK),
        errorTerminate: getErrorEntity(state, CLOSE_TASK),
    }
}

const mapDispatchToProps = {
    postAnalyseFraudeTask,
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { uri, terminateQuery, task } = stateProps
    const { postAnalyseFraudeTask } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form) => postAnalyseFraudeTask({
            query: _.replace(uri, '{id}', task.id),
            form,
            terminateQuery
        })
    }
}

const AnaFraudeContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'tache_fraude',
        validate
    }),
    loader({ErrorIndicator: ErrorHttpComponent})
)(AnaFraude)

export default AnaFraudeContainer