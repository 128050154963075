import moment from "moment";
import Spinner from "../../../../../Commun/Spinner/Spinner";
import MyButton from "../../../../../Commun/Button/Button";
import React from "react";

const BcaErr = (props) => {
    const { task: { title, description, date, type, paniere, tiers_id }, message, loading, loadingTerminate, postResendMissionBca } = props;

    console.log(postResendMissionBca, props);

    let descriptionContent = (
            message ? message : description ? <span dangerouslySetInnerHTML={{ __html: description }} /> : '-'
    );

    return (
        <div className={'container'}>
            <div className={'row'}>
                <div className={'col text-center'}>
                    <h2 className={'title-primary text-uppercase'}>{paniere} - Tiers n°{tiers_id}</h2>
                    <p className={'subtitle'}>Tâche du {moment(date).format('L')}. Reçu par {type}</p>
                </div>
            </div>
            <div className={'header-row row'}>
                <div className={'col-12'}>
                    <div className={'header-row row'}>
                        <div className={'col-12'}>
                            <p className={'form-content text-center'}>
                                Type: {title}<br />
                                Description: {descriptionContent}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'d-flex w-100'}>
                {loadingTerminate ? <Spinner/>
                    : <MyButton
                        variant='raised'
                        color='secondary'
                        size='large'
                        className={'btn btn-primary mt-1 mx-auto'}
                        disabled={loading}
                        onClick={postResendMissionBca}
                    >
                        {loading && <Spinner absolute/>}
                        Ré-envoyer la mission au BCA
                    </MyButton>
                }
            </div>
        </div>
    )
}

export default BcaErr;
