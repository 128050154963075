import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import ExtractWallet from "./ExtractWallet";
import {change, formValueSelector, reduxForm} from "redux-form";

import ErrorHttpComponent from "../../../../Commun/Error/ErrorHttpComponent";
import _ from "lodash";
import {getTiers, TIERS} from "../../../../../redux/actions/app/tiers/tiers.actions";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {
    tiersGetLink,
    userWalletExtractionLink
} from "../../../../../redux/selectors/links/links.selectors";
import {getTiersType} from "../../../../../redux/selectors/tiers/tiers.selectors";
import moment from "moment";
import {POST_EXTRACTION, postExtraction} from "../../../../../redux/actions/app/wallet/wallet.actions";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector('extraction-wallet')

    return {
        loading: getLoadingEntity(state, POST_EXTRACTION),
        url: userWalletExtractionLink(state),
        uri_tiers: tiersGetLink(state),
        idTiers: ownProps.match.params.id,
        loaded: getLoadingEntity(state, TIERS) === false,
        isCourtier: _.includes(getTiersType(state), '7'),
        type: selector(state, 'type'),
        initialValues: {
            'dateStart': moment().startOf('month').subtract(1, 'year'),
            'dateEnd': moment().endOf('month'),
            'isCourtier': _.includes(getTiersType(state), '7')
        },
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    getTiers: ({query, id}) => getTiers({query, id}),
    changeValue: (field, value) => change('extraction-wallet', field, value),
    postExtraction,
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {postExtraction, getTiers} = dispatchProps
    const {url, uri_tiers, idTiers} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => {
            getTiers({query: _.replace(uri_tiers, '{id}', idTiers), id: idTiers})
        },
        onSubmit: form => postExtraction({form, query: _.replace(url, '{id}', idTiers)})
    }
}


const ExtractWalletContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'extraction-wallet'
    }),
    loader({ErrorIndicator: ErrorHttpComponent})
)(ExtractWallet)


export default ExtractWalletContainer
