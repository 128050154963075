export const FIDELISATIONS = '[Fidelisations]';

export const GET_FIDELISATIONS = `${FIDELISATIONS} Get All`;
export const SET_FIDELISATIONS = `${FIDELISATIONS} Set All`;

export const GET_FIDELISATION = `${FIDELISATIONS} Get`;
export const SET_FIDELISATION = `${FIDELISATIONS} Set`;

export const POST_FIDELISATIONS = `${FIDELISATIONS} Post`;
export const UPDATE_FIDELISATIONS = `${FIDELISATIONS} Update`;

export const getFidelisations = ({ query }) => ({
    type: GET_FIDELISATIONS,
    payload: {
        data: query,
    },
});

export const setFidelisations = ({ fidelisations }) => ({
    type: SET_FIDELISATIONS,
    payload: {
        data: fidelisations,
    },
});

export const getFidelisation = ({ query }) => ({
    type: GET_FIDELISATION,
    payload: {
        data: query,
    },
});

export const setFidelisation = ({ fidelisation }) => ({
    type: SET_FIDELISATION,
    payload: {
        data: fidelisation,
    },
});

export const postFidelisations = ({ form, query }) => ({
    type: POST_FIDELISATIONS,
    payload: {
        data: query,
        body: form,
    },
});

export const updateFidelisations = ({ form, query }) => ({
    type: UPDATE_FIDELISATIONS,
    payload: {
        data: query,
        body: form,
    },
});
