import React from 'react'
import {Field} from "redux-form";
import MyButton from "../../../../Commun/Button/Button";
import Spinner from "../../../../Commun/Spinner/Spinner";
import DatePickerFieldRedux from "../../../../Commun/Input/DatePicker/DatePickerFieldRedux";
import moment from "moment";

const ExpirationDateSejour = (props) => {
    const {loading, handleSubmit, tiers} = props

    return (
        <div className={'contract-container container'}>
            <div className={'row return-content'}>
                <div className={'col'}>
                    <button onClick={() => props.history.go(-1)} className={'btn btn-return'}>Retour</button>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col'}>
                    <h1 className={'title-primary'}>{tiers.id} - {tiers.titre} {tiers.lastname} {tiers.firstname}</h1>
                </div>
            </div>
            <div className={'row header-row'}>
                <div className={'col-12'}>
                    <form className={'form-content'} onSubmit={handleSubmit}>
                        <Field
                            name='date'
                            label={'Date d\'expiration du titre administratif'}
                            component={DatePickerFieldRedux}
                            minDate={moment()}
                        />
                        <MyButton
                            variant='raised'
                            color='secondary'
                            size='large'
                            className={'btn btn-primary'}
                            disabled={loading}
                        >
                            {loading && <Spinner absolute/>}
                            Ajouter la date
                        </MyButton>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ExpirationDateSejour
