export const EMAIL = '[Email]'
export const EMAILS_ENVOYES = '[Emails Envoyés]'

export const POST_EMAIL = `${EMAIL} Post`
export const SET_EMAILS_ENVOYES = `${EMAILS_ENVOYES} Set`
export const CLEAR_EMAILS_ENVOYES = `${EMAILS_ENVOYES} Clear`

export const postEmail = ({form, query, tiers_id, otherData}) => ({
    type: POST_EMAIL,
    payload: {
        data: query,
        body: {...form, tiers_id},
        otherData: otherData
    }
})

export const setEmailsEnvoyes = ({data}) => ({
    type: SET_EMAILS_ENVOYES,
    payload: {
        data
    }
})

export const clearEmailsEnvoyes = () => ({
    type: CLEAR_EMAILS_ENVOYES
})