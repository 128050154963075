import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {getLoadingEntity} from '../../../../../../redux/selectors/ui/ui.selectors'
import {
    UPDATE_PREMIUM,
    postUpdatePremium
} from "../../../../../../redux/actions/app/status_premium/status_premium.actions";
import {updatePremiumLink} from "../../../../../../redux/selectors/links/links.selectors";
import _ from "lodash";
import InfoCourtier from "./InfoCourtier";

const mapStateToProps = (state, ownProps) => {
    return {
        tiers: ownProps.tiers,
        uri: updatePremiumLink(state),
        loading: getLoadingEntity(state, UPDATE_PREMIUM),
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    submitUpdatePremium: ({form, query}) => postUpdatePremium({form, query}),
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {submitUpdatePremium} = dispatchProps
    const {uri, tiers} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        postSubmitUpdatePremium: (form) => submitUpdatePremium({form, query: _.replace(uri, '{id}', tiers.belair_id)})
    }
}
const InfoCourtierContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(InfoCourtier)

export default InfoCourtierContainer
