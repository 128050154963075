import { useState } from "react";
import { redirectInNewWindow } from "../../../../../utils/function";
import { listMailJet, openInWindow } from "../../../../../constants/constants";
import _ from "lodash";

const ValideContrat = ({ loading, contract, tiers, delaisResil, postValidateContract }) => {
    const [disabled, setDisabled] = useState(false);

    const handleClick = () => {
        setDisabled(true);
        postValidateContract();
    };

    const nbJours = contract.vehicule.codeProduit === '7311_VAE' || contract.vehicule.codeProduit === '7311_NVEI' ? '30' : '60';

    return (
        <div className={'row'}>
            <div className={'col-12'}>
                <div className={'alert alert-warning row'}>
                    <div className={'col-8'}>
                        <strong>Le contrat est en établissement</strong>
                        <p className={'text-danger font-weight-bold mb-2'}>
                            {delaisResil < 0 && `Le J+${nbJours} est dépassé. Merci d'intervenir sur le dossier.`}
                            {delaisResil === 0 && 'Dernier jour avant la résiliation si nous ne recevons pas tous les documents.'}
                            {delaisResil > 0 && `J-${delaisResil} avant la résiliation si nous ne recevons pas tous les documents.`}
                        </p>
                        <p>Si toutes les pièces bloquantes ont été recues, passez le contrat en cours pour le valider.</p>
                    </div>
                    <div className={'col-4'}>
                        <span className={'btn btn-secondary btn-small float-right'} onClick={(e) => redirectInNewWindow({
                            e,
                            url: `/envoyer-email/pmq/${listMailJet.pmq}/${tiers}/${contract.id}`,
                            name: openInWindow.email
                        })}>Envoi d'un email de traitement des pièces</span>
                        <button
                            className={'btn btn-secondary btn-small float-right'}
                            onClick={handleClick}
                            disabled={loading || disabled}
                        >
                            Passer le contrat en cours
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ValideContrat;
