import {connect} from 'react-redux'
import {compose} from "redux";
import Feedback from "./Feedback";
import {change, reduxForm} from "redux-form";
import {getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {
    CLOSE_TASK,
    POST_UPDATE_INSATISFACTION_REASON_TASK_FEEDBACK, postUpdateInsatisfactionReasonFeedbackTask
} from "../../../../../../redux/actions/app/tasks/tasks.actions";
import _ from "lodash";
import {updateInsatisfactionFeedbackLink} from "../../../../../../redux/selectors/links/links.selectors";

const mapStateToProps = (state, ownProps) => {
    return {
        task: ownProps.task,
        terminateQuery: ownProps.terminateTask,
        uri: updateInsatisfactionFeedbackLink(state),
        loadingTerminate: getLoadingEntity(state, CLOSE_TASK),
        loadingPost: getLoadingEntity(state, POST_UPDATE_INSATISFACTION_REASON_TASK_FEEDBACK),
    }
}

const mapDispatchToProps = {
    postUpdateInsatisfactionReasonFeedbackTask,
    changeValue: (field, value) => change('insatisfaction-reason-feedback', field, value),
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {postUpdateInsatisfactionReasonFeedbackTask} = dispatchProps
    const {uri, terminateQuery, task} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form) => {
            postUpdateInsatisfactionReasonFeedbackTask({
                query: _.replace(uri, '{id}', task.id),
                queryCloseTask: terminateQuery,
                form
            })
        },
    }
}

const FeedbackContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'insatisfaction-reason-feedback'
    }),
)(Feedback)

export default FeedbackContainer
