import {Fragment} from 'react'
import moment from "moment";
import _ from "lodash";
import ButtonsContainer from "./Buttons/ButtonsContainer";
import Documents from "./Documents/Documents";

const FlotteDetail = (props) => {
    const {vehiculeId, vehicule, contract, roles} = props

    return (
        <div className={'cd-main-content'}>
            <div className={'contract-container container'}>
                <div className={'row return-content'}>
                    <div className={'col-2'}>
                        <button onClick={() => props.history.go(-1)} className={'btn btn-return'}>Retour</button>
                    </div>
                </div>

                {!_.includes(roles, 'gestionnaire televente') &&
                    <ButtonsContainer contract={contract} vehicule={vehicule}/>}

                <div className={'row margin-top'}>
                    <div className={'col'}>
                        <div className={'bloc-info bloc-souscripteur'}>
                            <h2>Véhicule</h2>
                            <div className={'content row overflow-auto'}>
                                <div className={'col-5 left-content'}>
                                    <p>Adhésion&nbsp;:</p>
                                    <p>Véhicule&nbsp;:</p>
                                    <p>Cylindrée&nbsp;:</p>
                                    <p>Immatriculation&nbsp;:</p>
                                    <p>Date de MEC&nbsp;:</p>
                                    <p>Date d'entrée&nbsp;:</p>
                                    <p>Date de sortie&nbsp;:</p>
                                    <p>Financement en leasing&nbsp;:</p>
                                </div>
                                <div className={'col-7 right-content'}>
                                    <p>{vehicule.adhesion}</p>
                                    <p>{vehicule.marque} {vehicule.modele}</p>
                                    <p>{vehicule.cylindree ? <>{vehicule.cylindree}cm<sup>3</sup></> : '-'}</p>
                                    <p>{vehicule.immatriculation}</p>
                                    <p>{vehicule.dateMEC ? moment(vehicule.dateMEC).format('L') : '-'}</p>
                                    <p>{vehicule.dateEntree ? moment(vehicule.dateEntree).format('L') : '-'}</p>
                                    <p>{vehicule.dateSortie ? moment(vehicule.dateSortie).format('L') : '-'}</p>
                                    <p>{vehicule.leasing === 'T' ? 'OUI' : 'NON'}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'col'}>
                        <div className={'bloc-info bloc-garanties'}>
                            <h2>Garanties</h2>
                            <div className={'content row overflow-auto'}>
                                <div className={'col-12'}>
                                    {
                                        vehicule.garanties.map((g, i) => (
                                            <Fragment key={i}><p>{g.libelle}</p></Fragment>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    vehicule.documents?.length > 0 &&
                    <>
                        <br/>
                        <Documents
                            className={'loader'}
                            contractId={contract.id}
                            tiersId={contract.souscripteur.id}
                            risqueNum={vehiculeId}
                            documents={vehicule.documents}
                        />
                    </>

                    /*<div className="bloc-info bloc-documents margin-top">
                        <h2>Base documentaire</h2>
                        <div className={'content row'}>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th scope="col">Document</th>
                                    <th scope="col">Commentaires</th>
                                    <th scope="col">Date de création</th>
                                    <th scope="col">Recu</th>
                                    <th scope="col">Traité</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    vehicule.documents.map((document, index) => (
                                        <tr key={index}>
                                            <td>{document.libelle}</td>
                                            <td>{document.commentaire}</td>
                                            <td>{moment(document.dateAttendu).format('DD/MM/YYYY')}</td>
                                            <td>{document.recu ? document.dateReception : "En attente"}</td>
                                            <td>{document.traite ? document.dateTraitement : "En attente"}</td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>*/
                }

            </div>
        </div>

    )
}

export default FlotteDetail
