import React from 'react'
import moment from "moment";

const DecContrl = (props) => {
    const {task: {title, description, date, type, paniere, tiers_id}} = props

    return (
        <div className={'container'}>
            <div className={'row'}>
                <div className={'col text-center'}>
                    <h2 className={'title-primary text-uppercase'}>{paniere} - Tiers n°{tiers_id}</h2>
                    <p className={'subtitle'}>Tâche du {moment(date).format('L')}. Recu par {type}</p>
                </div>
            </div>
            <div className={'header-row row'}>
                <div className="col-12  alert alert-danger" role="alert">
                    <p>Attention, une déclaration de sinistre a été faite sur une ancienne pièce. Merci de réattribuer
                        le sinistre sur la bonne pièce.</p>
                </div>
                <div className={'col-12 form-content text-center'}>
                    <p>
                        Type : {title}<br/>
                        Description : {description ? <span dangerouslySetInnerHTML={{__html: description}}/> : '-'}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default DecContrl
