export const EXTRACTION = '[Extraction]'
export const TRANSFERT = '[Transfert]'
export const EXTRACTION_TLV = '[Extraction Televente]'

export const POST_EXTRACTION = `${EXTRACTION} Post`
export const POST_TRANSFERT = `${TRANSFERT} Post`
export const POST_EXTRACTION_TLV = `${EXTRACTION_TLV} Post`

export const postExtraction = ({form, query}) => ({
    type: POST_EXTRACTION,
    payload: {
        data: query,
        body: form
    }
})

export const postTransfert = ({form, query}) => ({
    type: POST_TRANSFERT,
    payload: {
        data: query,
        body: form
    }
})

export const postExtractionTlvQuotes = ({form, query}) => ({
    type: POST_EXTRACTION_TLV,
    payload: {
        data: query,
        body: form
    }
})
