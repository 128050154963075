import React from 'react';
import SearchCard from "../../../../Commun/Card/SearchCard";

const SearchHistory = (props) => {
    const {toggleSearch} = props

    const history = JSON.parse(localStorage.getItem('historySearch'))

    return (
        <div>
            {
                history && <div className={'margin-top'}>
                    <p className={'text-left text-light'}>Historique de recherche</p>
                    <div className={'row'}>
                        {history.map((item, index) =>
                            <SearchCard item={item} key={index} toggleSearch={toggleSearch}/>
                        )}
                    </div>
                </div>
            }
        </div>
    );
};

export default SearchHistory;
