export const ACTIVITY = '[Activity]'
export const ADD_ACTIVITY = '[Add Activity]'
export const TRANSFER_ACTIVITY = '[Transfer Activity]'

export const GET_ACTIVITY = `${ACTIVITY} Get`
export const SET_ACTIVITY = `${ACTIVITY} Set`

export const GET_ACTIVITY_FILES = `${ACTIVITY} Get files`
export const SET_ACTIVITY_FILES = `${ACTIVITY} Set files`

export const POST_ADD_ACTIVITY = `${ADD_ACTIVITY} Post`
export const SET_ADD_ACTIVITY = `${ADD_ACTIVITY} Set`

export const POST_TRANSFER_ACTIVITY = `${TRANSFER_ACTIVITY} Post`


export const getActivity = ({query}) => ({
    type: GET_ACTIVITY,
    payload: {
        data: query
    }
})

export const setActivity = ({data}) => ({
    type: SET_ACTIVITY,
    payload: {data},
    meta: {
        entity: ACTIVITY
    }
})

export const postAddActivity = ({form, query, otherData = {}}) => ({
    type: POST_ADD_ACTIVITY,
    payload: {
        data: query,
        body: form,
        otherData: otherData
    }
})

export const setAddActivity = ({state}) => ({
    type: SET_ADD_ACTIVITY,
    payload: {
        data: state
    }
})

export const getActivityFiles = ({query}) => ({
    type: GET_ACTIVITY_FILES,
    payload: {
        data: query
    }
})


export const setActivityFiles = ({data}) => ({
    type: SET_ACTIVITY_FILES,
    payload: {data}
})

export const postTransferActivity = ({query, form}) => ({
    type: POST_TRANSFER_ACTIVITY,
    payload: {
        data: query,
        body: form
    }
})
