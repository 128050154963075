import {
    NOTES,
    ADD_NOTE,
    GET_NOTES,
    POST_ADD_NOTE,
    setNotes,
    setAddNote,
    POST_EDIT_NOTE,
    EDIT_NOTE,
    DELETE_NOTE,
    POST_DELETE_NOTE
} from '../../../actions/app/notes/notes.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {setNotification} from "../../../actions/core/notifications/notifications.actions";


export const notesMiddleware = () => next => action => {
    next(action)

    const {payload} = action
    let message = null

    switch (action.type) {
        case GET_NOTES:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: NOTES}),
                setLoader({state: true, entity: NOTES})
            ])
            break

        case `${NOTES} ${API_SUCCESS}`:
            const {data} = action.payload.data
            next([
                setNotes({data}),
                setError({state: false, entity: NOTES}),
                setLoader({state: false, entity: NOTES})
            ])
            break

        case `${NOTES} ${API_ERROR}`:
            next([
                setError({state: true, entity: NOTES}),
                setLoader({state: false, entity: NOTES})
            ])
            break

        case POST_ADD_NOTE:
            next([
                apiRequest({
                    body: (action.payload.body),
                    method: 'POST',
                    url: action.payload.data,
                    entity: ADD_NOTE
                }),
                setLoader({state: true, entity: ADD_NOTE})
            ])
            break

        case `${ADD_NOTE} ${API_SUCCESS}`:
            message = `<p>La note d'information a bien été ajoutée</p>`
            next([
                setAddNote(true),
                setNotification({entity: ADD_NOTE, body: message, type: 'success', title: 'Note créée!'}),
                setLoader({state: false, entity: ADD_NOTE}),
                setError({state: false, entity: ADD_NOTE}),
            ])
            break

        case `${ADD_NOTE} ${API_ERROR}`:
            message = `<p>Erreur lors de l'ajout de la note d'information: ${payload.data.response.data?.message ?? ''}</p>`
            next([
                setNotification({entity: ADD_NOTE, body: message, type: 'error', title: 'Attention !', timeout: '0'}),
                setError({state: true, entity: ADD_NOTE}),
                setLoader({state: false, entity: ADD_NOTE}),
            ])
            break

        case POST_EDIT_NOTE:
            next([
                apiRequest({body: action.payload.body, method: 'POST', url: action.payload.data, entity: EDIT_NOTE}),
                setLoader({state: true, entity: EDIT_NOTE})
            ])
            break

        case `${EDIT_NOTE} ${API_SUCCESS}`:
            message = `<p>La note d'information a bien été mise à jour</p>`
            next([
                setAddNote(true),
                setNotification({entity: EDIT_NOTE, body: message, type: 'success', title: 'Note editée!'}),
                setLoader({state: false, entity: EDIT_NOTE}),
                setError({state: false, entity: EDIT_NOTE}),
            ])
            break

        case `${EDIT_NOTE} ${API_ERROR}`:
            message = `<p>Erreur lors de l'édition de la note d'information: ${payload.data.response.data?.message ?? ''}</p>`
            next([
                setNotification({entity: EDIT_NOTE, body: message, type: 'error', title: 'Attention !', timeout: '0'}),
                setError({state: true, entity: EDIT_NOTE}),
                setLoader({state: false, entity: EDIT_NOTE}),
            ])
            break

        case POST_DELETE_NOTE:
            next([
                apiRequest({
                    body: (action.payload.body),
                    method: 'POST',
                    url: action.payload.data,
                    entity: DELETE_NOTE
                }),
                setLoader({state: true, entity: DELETE_NOTE})
            ])
            break

        case `${DELETE_NOTE} ${API_SUCCESS}`:
            message = `<p>La note d'information a bien été supprimé</p>`
            next([
                setAddNote(true),
                setNotification({entity: DELETE_NOTE, body: message, type: 'success', title: 'Note Supprimée!'}),
                setLoader({state: false, entity: DELETE_NOTE}),
                setError({state: false, entity: DELETE_NOTE}),
                window.location.reload()
            ])
            break

        case `${DELETE_NOTE} ${API_ERROR}`:
            message = `<p>Erreur lors de la suppression de la note d'information.</p>`
            next([
                setNotification({
                    entity: DELETE_NOTE,
                    body: message,
                    type: 'error',
                    title: 'Attention !',
                    timeout: '0'
                }),
                setError({state: true, entity: DELETE_NOTE}),
                setLoader({state: false, entity: DELETE_NOTE}),
            ])
            break

        default:
            break;
    }
    return null
}
