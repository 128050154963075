import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import ErrorHttpComponent from '../../../../Commun/Error/ErrorHttpComponent'
import _ from "lodash";
import {
    contractDefaultGetLink, InsurersGetLink,
    postChangeSituationContractLink,
} from "../../../../../redux/selectors/links/links.selectors";
import {
    postChangeSituationContract,
    CHANGE_SITUATION_CONTRACT
} from "../../../../../redux/actions/app/change_situation_contract/change_situation_contract.actions";
import {getErrorEntity, getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import ResilContrat from './ResilContrat'
import {CONTRACT, getContract} from "../../../../../redux/actions/app/contracts/contracts.actions";
import {getContractData} from "../../../../../redux/selectors/contract/contract.selectors";
import {getInsurers} from "../../../../../redux/actions/app/insurers/insurers.actions";
import {getInsurersData} from "../../../../../redux/selectors/insurers/insurers.selector";
import {change, formValueSelector, reduxForm} from "redux-form";
import validate from "./validate";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector('resil_contrat')

    return {
        uri: postChangeSituationContractLink(state),
        loading: getLoadingEntity(state, CHANGE_SITUATION_CONTRACT),
        contract: getContractData(state),
        uriContrat: contractDefaultGetLink(state),
        uriInsurers: InsurersGetLink(state),
        loaded: getLoadingEntity(state, CONTRACT) === false,
        error: getErrorEntity(state, CONTRACT),
        id: ownProps.match.params.id,
        errorResil: getErrorEntity(state, CHANGE_SITUATION_CONTRACT),
        insurers: getInsurersData(state),
        motif_details: selector(state, 'motif_details') || null
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    postResilContract: ({form, type, query}) => postChangeSituationContract({form, type, query}),
    getInsurers: (query) => getInsurers({query}),
    getContract: (query) => getContract({query}),
    changeValue: (field, value) => change('resil_contrat', field, value)
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {postResilContract, getContract, getInsurers} = dispatchProps
    const {uri, uriContrat, id, uriInsurers} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => {
            getContract(_.replace(uriContrat, '{id}', id))
            getInsurers(uriInsurers)
        },
        onSubmit: (form) => postResilContract({
            form,
            type: 'terminate',
            query: _.replace(uri, '{id}', id)
        })
    }
}

const ResilContratContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'resil_contrat',
        validate
    }),
    loader({ErrorIndicator: ErrorHttpComponent})
)(ResilContrat)

export default ResilContratContainer
