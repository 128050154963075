const validate = values => {
    const errors = {}

    const requiredFields = [
        'arrive',
        'moment_vie',
        'nature',
        'date_reception',
        'assureur',
        'niveau_recla',
        'qualite_emetteur'
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Ce champ est obligatoire'
        }
    })


    return errors
}

export default validate
