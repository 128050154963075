import _ from 'lodash';
import { useEffect, useState } from 'react';
import { listMailJet, openInWindow } from '../../../../../../../constants/constants';
import { redirectInNewWindow } from '../../../../../../../utils/function';
import AddDocumentContainer from '../../../../Forms/AddDocument/AddDocumentContainer';
import DocumentItemContainer from './DocumentItem/DocumentItemContainer';
import './documents.scss';

const Documents = ({ documents = [], logAction, risqueNum }) => {

    return (
        <div className={'bloc-info bloc-documents'}>
            <h2>Base documentaire</h2>

            <div className={'content row'}>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Document</th>
                            <th scope="col">Commentaire</th>
                            <th scope="col" className={'text-center'}>Recu</th>
                            <th scope="col" className={'text-center'}>Traité</th>
                            <th scope="col">Détails</th>
                        </tr>
                    </thead>
                    <tbody>
                        {documents.map((document, index) =>
                            <DocumentItemContainer
                                risqueNum={risqueNum}
                                logAction={logAction}
                                document={document}
                                key={`document-${document.code}-${index}`}
                                form={`document-${document.code}-${index}`}
                            />
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Documents;
