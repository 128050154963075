
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {
    POST_CHANGE_PERSONAL_INFOS,
    CHANGE_PERSONAL_INFOS,
    setChangePersonalInfos
} from "../../../actions/app/personal_infos/personal_infos.actions";
import {reset} from "redux-form";

export const changePersonalInfosMiddleware = () => next => action => {
    next(action)

    const {payload} = action
    let message = null

    switch (action.type) {
        case POST_CHANGE_PERSONAL_INFOS:
            next([
                apiRequest({
                    body: (action.payload.body),
                    method: 'POST',
                    url: action.payload.data,
                    entity: CHANGE_PERSONAL_INFOS
                }),
                setLoader({state: true, entity: CHANGE_PERSONAL_INFOS})
            ])
            break

        case `${CHANGE_PERSONAL_INFOS} ${API_SUCCESS}`:
            message = `<p>Les informations personnelles ont bien été modifiés</p>`
            next([
                setChangePersonalInfos({state: true}),
                setNotification({
                    entity: CHANGE_PERSONAL_INFOS,
                    body: message,
                    type: 'success',
                    title: 'Informations personnelles mise à jour!'
                }),
                setLoader({state: false, entity: CHANGE_PERSONAL_INFOS}),
                setError({state: false, entity: CHANGE_PERSONAL_INFOS}),
                reset('changePersonalInfosForm')
            ])
            break

        case `${CHANGE_PERSONAL_INFOS} ${API_ERROR}`:
            message = `
                <p>Votre demande n'a pas pu être envoyée. ${payload.data.response.data?.message ?? ''}</p>
            `
            next([
                setNotification({
                    entity: CHANGE_PERSONAL_INFOS,
                    body: message,
                    type: 'error',
                    title: 'Erreur lors de la modification des informations personnelles !',
                    timeout: '0'
                }),
                setError({state: true, entity: CHANGE_PERSONAL_INFOS}),
                setLoader({state: false, entity: CHANGE_PERSONAL_INFOS}),
            ])
            break

        default:
            break
    }
    return null
}
