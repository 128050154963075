import {connect} from 'react-redux'
import DashboardTeleventeTasksContent from "./DashboardTeleventeTasksContent";
import {
    getDashboardTeleventeTasksData, getDashboardTeleventeTasksLinks, getDashboardTeleventeTasksMeta
} from "../../../../../redux/selectors/dashboard_televente/dashboard_televente.selectors";
import {dashboardTeleventeGetLink} from "../../../../../redux/selectors/links/links.selectors";
import {bindActionCreators, compose} from "redux";
import {getDashboardTelevente} from "../../../../../redux/actions/app/dashboard_televente/dashboard_televente.actions";
import loader from "../../../../Commun/Spinner/Loader/Loader";


const mapStateToProps = state => {
    return {
        uri: dashboardTeleventeGetLink(state),
        tasks: getDashboardTeleventeTasksData(state),
        meta: getDashboardTeleventeTasksMeta(state) || {},
        links: getDashboardTeleventeTasksLinks(state) || {}
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getDashboardTelevente,
}, dispatch)


const DashboardTeleventeTasksContentContainer = compose(
    connect(mapStateToProps, mapDispatchToProps),
    loader()
)(DashboardTeleventeTasksContent)

export default DashboardTeleventeTasksContentContainer