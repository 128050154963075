

export const VALIDATE_VIREMENT = '[Validate Virement]'
export const REFUSE_VIREMENT_PROD = '[Validate Virement Prod]'

export const POST_VALIDATE_VIREMENT = `${VALIDATE_VIREMENT} Post`
export const SET_VALIDATE_VIREMENT = `${VALIDATE_VIREMENT} Set`
export const POST_REFUSE_VIREMENT_PROD = `${REFUSE_VIREMENT_PROD} Post`
export const SET_REFUSE_VIREMENT_PROD = `${REFUSE_VIREMENT_PROD} Set`

export const postValidateVirement = ({validation_titulaire, commentaire, valide, query}) => ({
    type: POST_VALIDATE_VIREMENT,
    payload: {
        data: query,
        body: ({commentaire, valide, validation_titulaire}),
        otherData: {
            valide: valide
        }
    }
})

export const setValidateVirement = ({state}) => ({
    type: SET_VALIDATE_VIREMENT,
    payload: state
})

export const postRefuseVirementProd = ({popup, form, query}) => ({
    type: POST_REFUSE_VIREMENT_PROD,
    payload: {
        data: query,
        body: {...form, popup}
    }
})

export const setRefuseVirementProd = ({state}) => ({
    type: SET_REFUSE_VIREMENT_PROD,
    payload: state
})
