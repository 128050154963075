import React from 'react'
import {Field} from "redux-form";
import {Link} from "react-router-dom";
import SelectFieldRedux from "../../../../Commun/Input/Select/SelectFieldRedux";
import TextFieldRedux from "../../../../Commun/Input/Text/TextFieldRedux";
import Spinner from "../../../../Commun/Spinner/Spinner";
import MyButton from '../../../../Commun/Button/Button'

const ReversementCompagnies = (props) => {
    const {loading, handleSubmit} = props

    return (
        <div className={'contract-container container'}>
            <div className={'row return-content'}>
                <div className={'col'}>
                    <Link to={`/comptabilite`} className={'btn btn-return'}>Retour à la liste</Link>
                </div>
            </div>
            <div className={'row header-row'}>
                <div className={'col-8 offset-md-2'}>
                    <form className={'form-content'} onSubmit={handleSubmit}>
                        <Field
                            name='compagnie'
                            component={SelectFieldRedux}
                            label='Sélectionnez la compagnie'
                        >
                            <option value={null}>Sélectionner la compagnie</option>
                            <option value={'7311'}>Allianz</option>
                            {/*<option value={'35'}>Mondial Assistance</option>*/}
                            <option value={'1715'}>Caarea</option>
                            <option value={'33220'}>Protexia</option>
                            <option value={'2955'}>Solucia</option>
                        </Field>
                        <Field
                            name='transaction_id'
                            component={TextFieldRedux}
                            label={'Numéro de transaction'}
                            type={'number'}
                        />
                        <MyButton
                            variant='raised'
                            color='secondary'
                            size='large'
                            className={'btn btn-primary'}
                            disabled={loading}
                        >
                            {loading && <Spinner absolute/>}
                            Lancer
                        </MyButton>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ReversementCompagnies
