import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import {change, reduxForm, formValueSelector} from 'redux-form'
import InitForm from './InitForm'
import ErrorHttpComponent from "../../../../../Commun/Error/ErrorHttpComponent";
import {
    POST_ACCIDENT,
    postAccident,
    postRejectAccident
} from "../../../../../../redux/actions/app/accidents/accidents.actions";
import moment from "moment";
import {getDocumentsNeededForAccident, getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {
    getContractGarantiesOptions, getIsFleetContract, getIsWarkamContract
} from "../../../../../../redux/selectors/contract/contract.selectors";
import validate from './validate'
import {accidentsRejectContractGetLink} from "../../../../../../redux/selectors/links/links.selectors";
import _ from "lodash";
import {logAction} from "../../../../../../redux/actions/core/logs/logs.actions";
import {getGarantiesFlotteDetailData} from "../../../../../../redux/selectors/flotte/flotte.selectors";
import loader from "../../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector('declaration-sinistre')
    const {idVehicule, contract} = ownProps

    return {
        uri: contract.accident_claim_link,
        uriRejectAccident: accidentsRejectContractGetLink(state),
        isWakam: getIsWarkamContract(state),
        idContract: contract.id,
        categorie: selector(state, 'catsinorigin'),
        vehicule: selector(state, 'vehicule'),
        identify: selector(state, 'indentify'),
        temoin: selector(state, 'temoin'),
        blesse: selector(state, 'blesse'),
        type_sinistre: selector(state, 'type_sinistre'),
        loading: getLoadingEntity(state, POST_ACCIDENT),
        garanties: getIsFleetContract(state) ? getGarantiesFlotteDetailData(state) : getContractGarantiesOptions(state),
        initialValues: {
            'conduct': getIsFleetContract(state) ? null : contract.souscripteur.nom,
            'datesurvorigin': moment(),
            'corpoconduct': 'F',
            'temoin': 'F',
            'vehiculeDommage': 'F',
            'risque': idVehicule || contract.adhesionPrincipale.toString(),
            'blesse': 'F',
            'type_contrat': contract.vehicule.codeProduit === '7311_VAE' ? 'VAE' : idVehicule ? 'flotte' : null,
        },
        listDocuments: getDocumentsNeededForAccident(state) || [],
    }
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
    changeValue: (field, value) => change('declaration-sinistre', field, value),
    postAccident: ({form, query, documents}) => postAccident({form, query, documents}),
    postRejectAccident: ({cause, query}) => postRejectAccident({query, cause}),
    logAction: () => logAction({
        entity: POST_ACCIDENT,
        type: 'SHOW_REPORT_ACCIDENT',
        contrat: ownProps.contract.id,
        tiers: ownProps.contract.souscripteur.id
    })
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {postAccident, postRejectAccident} = dispatchProps
    const {uri, uriRejectAccident, idContract, listDocuments} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form) => postAccident({form, query: uri, documents: listDocuments}),
        postRejectAccident: (cause) => postRejectAccident({
            cause,
            query: _.replace(uriRejectAccident, '{id}', idContract)
        })
    }
}

const InitFormContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'declaration-sinistre',
        validate
    }),
    loader({ErrorIndicator: ErrorHttpComponent})
)(InitForm)

export default InitFormContainer
