import React, {Fragment, useState} from 'react';
import moment from "moment/moment";
import DashboardTeleventeConseillerDetailContainer
    from "./DashboardTeleventeConseillerDetail/DashboardTeleventeConseillerDetailContainer";
import TextField from "@mui/material/TextField";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";

const DashboardTeleventeConseiller = (props) => {
    const {getDashboardConseillerTelevente, uri} = props
    const [dateState, setDateState] = useState({
        startDate: localStorage.getItem('start_date_tlv') ? moment(localStorage.getItem('start_date_tlv'), "DD/MM/YYYY") : null,
        endDate: localStorage.getItem('end_date_tlv') ? moment(localStorage.getItem('end_date_tlv'), "DD/MM/YYYY") : null,
    });
    const [focusedInput, setFocusedInput] = useState(null)

    const handleChangeFilterPeriod = (e) => {
        let filter = e.target.name.substring(7)
        localStorage.setItem(filter, e.target.value)

        setDateState({
            startDate: null,
            endDate: null,
        })
        localStorage.removeItem('start_date_tlv')
        localStorage.removeItem('end_date_tlv')

        handleReload({
            period: e.target.value,
            startDate: null,
            endDate: null,
        });
    }

    const handleChangeDates = (startDate, endDate) => {
        setDateState({ startDate, endDate });
        if(endDate){
            localStorage.setItem(`end_date_tlv`, moment(endDate).format('DD/MM/YYYY'))
        } else {
            localStorage.setItem('end_date_tlv', moment().format('DD/MM/YYYY'))
        }
        localStorage.setItem(`start_date_tlv`, moment(startDate).format('DD/MM/YYYY'))
        localStorage.removeItem('period_tlv')

        handleReload({
            period: null,
            start_date:  moment(startDate).format('DD/MM/YYYY'),
            end_date: endDate !== undefined ? moment(endDate).format('DD/MM/YYYY') : null
        });
    }

    const handleReload = (newFilters) => {
        getDashboardConseillerTelevente({query: uri,
            filters: newFilters
        });
    }

    return (
        <div className={'suivi-container tasks-container container'}>
            <div className={'row'}>
                <div className={'col-12 buttons-dashboard'}>
                    <div className={'row'}>
                        <div className={'col-6'}>
                            <h1 className={`title-primary title-dashboard title-suivi`}>Mon tableau de bord</h1>
                        </div>
                    </div>
                </div>
            </div>
            <Fragment>
                <div className={'margin-top row'}>
                    <div className={'col-12 filters'}>
                        <div className={'content'}>
                            <label><small>Filtre par période</small></label><br/>
                            <select
                                name="filter_period_tlv"
                                onChange={handleChangeFilterPeriod}
                                className={'small-select select-sort-courtier custom-select'}
                                value={localStorage.getItem('period_tlv') || (!dateState.startDate ? 'aujourdhui' : '')}
                            >
                                <option value={"aujourdhui"}>Aujourd’hui</option>
                                <option value={"hier"}>Hier</option>
                                <option value={"semaine"}>Semaine en cours</option>
                                <option value={"semaine_pre"}>Semaine précédente</option>
                                <option value={"mois"}>Mois en cours</option>
                                <option value={"mois_pre"}>Mois précédent</option>
                            </select>
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
                                <DatePicker
                                    className={'mr-2 small date-picker'}
                                    label='Date de début'
                                    value={dateState.startDate}
                                    onChange={(newValue) => {
                                        handleChangeDates(newValue, dateState.endDate)
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>

                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
                                <DatePicker
                                    className={"date-picker small"}
                                    label='Date de fin'
                                    value={dateState.endDate}
                                    onChange={(newValue) => {
                                        handleChangeDates(dateState.startDate, newValue)
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                    minDate={dateState.startDate}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                    <div className={'col-12'}>
                        <DashboardTeleventeConseillerDetailContainer className='loader'/>
                    </div>
                </div>
            </Fragment>
        </div>
    );
};

export default DashboardTeleventeConseiller;