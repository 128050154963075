import {connect} from 'react-redux'
import {compose} from "redux";
import DetailsAntecedentsBloc from "./DetailsAntecedentsBloc";
import loader from "../../../../../Commun/Spinner/Loader/Loader";
import ErrorHttpComponent from "../../../../../Commun/Error/ErrorHttpComponent";
import {getErrorEntity, getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {ANTECEDENTS_MOTO, ANTECEDENTS_AUTO, getAntecedents} from "../../../../../../redux/actions/app/antecedents/antecedents.actions";
import {antecedentsGetLink, deleteVersionningLink} from "../../../../../../redux/selectors/links/links.selectors";
import _ from "lodash";
import {
    getAntecedentsAuto,
    getAntecedentsMoto
} from "../../../../../../redux/selectors/antecedents/antecedents.selectors";
import {postDeleteVersionning} from "../../../../../../redux/actions/app/versionning/versionning.actions";

const mapStateToProps = (state, ownProps) => {
    const type = ownProps.type;
    return {
        contract_id: ownProps.contract,
        uri: antecedentsGetLink(state),
        uri_delete: deleteVersionningLink(state),
        loaded: type === 'moto' ? getLoadingEntity(state, ANTECEDENTS_MOTO) === false : getLoadingEntity(state, ANTECEDENTS_AUTO) === false,
        error: type === 'moto' ? getErrorEntity(state, ANTECEDENTS_MOTO) : getErrorEntity(state, ANTECEDENTS_AUTO),
        type: type,
        antecedents: type === 'moto' ? getAntecedentsMoto(state) : getAntecedentsAuto(state)
    }
}

const mapDispatchToProps = {
    getAntecedents, postDeleteVersionning
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uri, uri_delete, type, contract_id} = stateProps
    const {getAntecedents, postDeleteVersionning} = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getAntecedents({query: _.replace(_.replace(uri, '{id}', contract_id), '{type}', type), type: type}),
        onSubmitDeletePeriode: (id) => postDeleteVersionning({
            query: _.replace(uri_delete, '{id}', contract_id),
            id: id
        })
}
}

const DetailsAntecedentsBlocContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(DetailsAntecedentsBloc)

export default DetailsAntecedentsBlocContainer