export const FAQ = '[Faq]'

export const GET_FAQ = `${FAQ} Get`
export const SET_FAQ = `${FAQ} Set`
export const EXPAND_FAQ = `${FAQ} Expand`

export const getFaq = ({query}) => ({
    type: GET_FAQ,
    payload: {
        data: query
    }
})

export const setFaq = ({faq}) => ({
    type: SET_FAQ,
    payload: {
        data: faq
    }
})

export const expandQuestion = ({question, category}) => ({
    type: EXPAND_FAQ,
    meta: {
        'analytics': {
            'event': 'click',
            'actionGoogle': 'openQuestion',
            'libelle': question,
            'categorie': category,
            'unique': true,
        },
        entity: FAQ
    }
})
