import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setFilters, setLoader} from '../../../actions/app/ui/ui.actions'
import {
    CANCEL_QUOTE,
    FETCH_QUOTE,
    FETCH_QUOTES,
    QUOTE,
    QUOTES,
    setQuote, setQuotes
} from "../../../actions/app/quotes/quotes.actions";
import _ from "lodash";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
export const quotesMiddleware = () => next => action => {
    next(action)

    switch (action.type) {

        case FETCH_QUOTE:
            let url = _.includes(action.payload.data, '?') ? action.payload.data : `${action.payload.data}?`

            if (action.payload.filters) {
                for (let [key, value] of Object.entries(action.payload.filters)) {
                    url = `${url}&${key}=${value}`
                }
            }

            next([
                apiRequest({body: null, method: 'GET', url, entity: QUOTE}),
                setFilters({entity: QUOTE, filters: {...action.payload.filters}}),
                setLoader({state: true, entity: QUOTE})
            ])
            break

        case `${QUOTE} ${API_SUCCESS}`:
            next([
                setQuote({data: action.payload.data}),
                setError({state: false, entity: QUOTE}),
                setLoader({state: false, entity: QUOTE})
            ])
            break

        case `${QUOTE} ${API_ERROR}`:
            next([
                setError({state: true, entity: QUOTE}),
                setLoader({state: false, entity: QUOTE})
            ])
            break

        case FETCH_QUOTES:
            let lien = _.includes(action.payload.data, '?') ? action.payload.data : `${action.payload.data}?`

            if (action.payload.filters) {
                for (let [key, value] of Object.entries(action.payload.filters)) {
                    lien = `${lien}&${key}=${value}`
                }
            }

            next([
                apiRequest({body: null, method: 'GET', url: lien, entity: QUOTES}),
                setFilters({entity: QUOTES, filters: {...action.payload.filters}}),
                setLoader({state: true, entity: QUOTES})
            ])
            break

        case `${QUOTES} ${API_SUCCESS}`:
            next([
                setQuotes({data: action.payload.data}),
                setError({state: false, entity: QUOTES}),
                setLoader({state: false, entity: QUOTES})
            ])
            break

        case `${QUOTES} ${API_ERROR}`:
            next([
                setError({state: true, entity: QUOTES}),
                setLoader({state: false, entity: QUOTES})
            ])
            break




        case CANCEL_QUOTE:
            next([
                apiRequest({
                    body: (action.payload.body),
                    method: 'POST',
                    url: action.payload.data,
                    entity: CANCEL_QUOTE
                }),
                setLoader({state: true, entity: CANCEL_QUOTE})
            ])
            break

        case `${CANCEL_QUOTE} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: CANCEL_QUOTE,
                    body: `<p>Le devis a bien été désactivé</p>`,
                    type: 'success',
                    title: 'Désactivation effectuée !'
                }),
                setLoader({state: false, entity: CANCEL_QUOTE}),
                setError({state: false, entity: CANCEL_QUOTE})
            ])
            break

        case `${CANCEL_QUOTE} ${API_ERROR}`:
            next([
                setNotification({
                    entity: CANCEL_QUOTE,
                    body: `<p>Une erreur est survenue. ${action?.payload?.data?.response?.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: CANCEL_QUOTE}),
                setLoader({state: false, entity: CANCEL_QUOTE}),
            ])
            break

        default:
            break
    }
    return null
}
