import React from 'react';
import {Field} from "redux-form";
import TextFieldRedux from "../../../../../../../Commun/Input/Text/TextFieldRedux";


const InfosVehiculeVae = (props) => {
    return (
        <>
            <div className="col">
                <Field
                    name='vin_vehicule'
                    data-test-id="avenant_simple_vin_vehicule_input"
                    component={TextFieldRedux}
                    label={'Numéro de série :'}
                />
            </div>
            <div className="col">
                <Field
                    name='modele_vehicule'
                    data-test-id="avenant_simple_modele_marque_vae_input"
                    component={TextFieldRedux}
                    label={'Désignation du véhicule :'}
                    normalize={v => v.toUpperCase()}
                />
            </div>
        </>
    );
};


InfosVehiculeVae.defaultProps = {};


InfosVehiculeVae.propTypes = {};


export default InfosVehiculeVae;