import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import ChangeConducteur from "./ChangeConducteur";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {
    POST_UPDATE_CONDUCTEUR_ACCIDENT, postUpdateConducteurAccident
} from "../../../../../redux/actions/app/accidents/accidents.actions";
import {reduxForm} from "redux-form";
import {
    updateAccidentConducteurGetLink
} from "../../../../../redux/selectors/links/links.selectors";
import _ from "lodash";

const mapStateToProps = (state, ownProps) => {
    return {
        uri: updateAccidentConducteurGetLink(state),
        accidentId: ownProps.location.state.accident,
        contractId: ownProps.location.state.contract,
        loading: getLoadingEntity(state, POST_UPDATE_CONDUCTEUR_ACCIDENT)
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    postUpdateConducteurAccident,
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uri, accidentId, contractId} = stateProps
    const {postUpdateConducteurAccident} = dispatchProps


    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: form => postUpdateConducteurAccident({
            query: _.replace(_.replace(uri, '{id}', contractId), '{ids}', accidentId),
            form
        })
    }
}


const ChangeConducteurContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'accident-responsabilite'
    })
)(ChangeConducteur)


export default ChangeConducteurContainer
