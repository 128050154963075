import React from 'react'
import StatsCard from "../../../../../Commun/Card/StatsCard";
import moment from "moment";

const Stats = (props) => {
    const {stats: {AFFNOU, SEFFET, ENCOUR, RESIL, SINOUV, P_CLI, P_CIE, EVOL, REGSIN}, stats} = props
    const oneYearAgo = moment().subtract(1, 'year').subtract(72, 'days').format('L');
    const endDate = moment().subtract(72, 'days').format('L');

    return (
        stats.AFFNOU ?
            <div className={'bloc-info bloc-documents'}>
                <h2>Stats</h2>
                <div className={'content row'}>
                    <div className={'col-12'}>
                        <div className={'row'}>
                            <div className={'col-5 left-content'}>
                                <p>Taux de chute (année glissante) :</p>
                            </div>
                            <div className={'col-7 right-content'}>
                                <p>{((stats.chute_afn / stats.total_afn) * 100).toFixed(2)}% <small>Du {oneYearAgo} au {endDate}</small></p>

                            </div>
                        </div>
                    </div>
                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">&nbsp;</th>
                            {
                                Object.keys(AFFNOU).map((key, i) => {
                                    return <th key={i}>{key}</th>
                                })
                            }
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Affaire Nouvelle</td>
                            <StatsCard obj={AFFNOU}/>
                        </tr>
                        <tr>
                            <td>Sans effet</td>
                            <StatsCard obj={SEFFET}/>
                        </tr>
                        <tr>
                            <td>Affaire En cours</td>
                            <StatsCard obj={ENCOUR}/>
                        </tr>
                        <tr>
                            <td>Résiliations</td>
                            <StatsCard obj={RESIL}/>
                        </tr>
                        <tr>
                            <td>Évolutions</td>
                            <StatsCard obj={EVOL}/>
                        </tr>
                        <tr>
                            <td>Sinistres ouverts</td>
                            <StatsCard obj={SINOUV}/>
                        </tr>
                        <tr>
                            <td>Réglements sinistres</td>
                            <StatsCard obj={REGSIN} montant/>
                        </tr>
                        <tr>
                            <td>Prime compagnie</td>
                            <StatsCard obj={P_CIE} montant/>
                        </tr>
                        <tr>
                            <td>Prime clients</td>
                            <StatsCard obj={P_CLI} montant/>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            : ''
    )
}

export default Stats
