import {SET_DOCUMENTS_UTILES} from '../../actions/app/documents_utiles/documents_utiles.actions'

export const documentsUtilesReducer = (documents_utiles = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_DOCUMENTS_UTILES) {
        return payload.data
    } else {
        return documents_utiles
    }
}
