import {connect} from 'react-redux'
import {compose} from "redux";
import AddExpertReport from "./AddExpertReport";
import _ from "lodash";
import {reduxForm} from "redux-form";

import ErrorHttpComponent from "../../../../Commun/Error/ErrorHttpComponent";
import {ACCIDENT, getAccident} from "../../../../../redux/actions/app/accidents/accidents.actions";
import {getErrorEntity, getExpertReportPost, getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {accidentGetLink, addExpertReportAccidentGetLink} from "../../../../../redux/selectors/links/links.selectors";
import {getAccidentData} from "../../../../../redux/selectors/accident/accident.selectors";
import {
    POST_ADD_EXPERT_REPORT,
    postAddExpertReport
} from "../../../../../redux/actions/app/expert_report/expert_report.actions";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    return {
        loaded: getLoadingEntity(state, ACCIDENT) === false,
        error: getErrorEntity(state, ACCIDENT),
        uri: addExpertReportAccidentGetLink(state),
        loading: getLoadingEntity(state, POST_ADD_EXPERT_REPORT),
        uri_accident: accidentGetLink(state),
        contractId: ownProps.match.params.id,
        accidentId: ownProps.match.params.ids,
        accident: getAccidentData(state),
        expertReportPost: getExpertReportPost(state)
    }
}

const mapDispatchToProps = {
    getAccident,
    postAddExpertReport
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uri, uri_accident, contractId, accidentId} = stateProps
    const {getAccident, postAddExpertReport} = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getAccident({
            query: _.replace(_.replace(uri_accident, '{id}', contractId), '{ids}', accidentId),
            id: accidentId
        }),
        onSubmit: (form) => postAddExpertReport({
            query: _.replace(_.replace(uri, '{id}', contractId), '{ids}', accidentId),
            form
        })
    }
}

const AddExpertReportContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'add-expert-report'
    }),
    loader({ErrorIndicator: ErrorHttpComponent})
)(AddExpertReport)

export default AddExpertReportContainer
