import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import DevisTable from "./DevisTable";
import {getDataQuotes, getMetaQuotes} from "../../../../../../redux/selectors/quotes/quotes.selectors";
import {getErrorEntity, getFiltersEntity, getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {fetchQuote, QUOTES} from "../../../../../../redux/actions/app/quotes/quotes.actions";
import {getQuotesLink} from "../../../../../../redux/selectors/links/links.selectors";
import loader from "../../../../../Commun/Spinner/Loader/Loader";
import {getUserRoles} from "../../../../../../redux/selectors/user/user.selectors";


const mapStateToProps = state => {
    return {
        quotes: getDataQuotes(state),
        meta: getMetaQuotes(state),
        query: getQuotesLink(state),
        loaded: getLoadingEntity(state, QUOTES) === false,
        error: getErrorEntity(state, QUOTES),
        roles: getUserRoles(state)
    }
}


const DevisTableContainer = compose(
    connect(mapStateToProps),
    loader()
)(DevisTable)

export default DevisTableContainer
