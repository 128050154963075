import React from 'react'
import {Valid, Warning} from "../Icons/Icons";

const KofaxDetailCard = ({file, loopIndex}) => {
    return (
        <div className={'card-kofax'}>
            <div className={'row'}>
                <div className={'col-12 card-title'}>
                    <h2>{file.libelle || `Document ${loopIndex}`}</h2>
                </div>
                <div className={'col-12'}>
                    <div className={'row'}>
                        {file.fields.map((field, index) =>
                            <div className={`kofax-col col-2`} key={index}>
                                <p className={'title'}>{field.nomChamps}</p>
                                <p className={'value'}>
                                    {field.valide === 'T' ? <Valid/> : <Warning/>}
                                    {field.valeurRecu || '-'}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default KofaxDetailCard
