import _ from 'lodash';
import { Field } from 'redux-form';
import CheckboxFieldRedux from '../../../../Commun/Input/Checkbox/CheckboxFieldRedux';

const Circonstances = (props) => {
    const { onSubmit, onSubmitCession, onSubmitDeces, accident, permissions, contrat, isWakam, formValues } = props;
    console.log('props', props)

    const handleSubmit = (e) => {
        let dataForm = new FormData();
        let jsonForm = {};
        dataForm.append('pieces[0][codeatt]', '29');

        for (const [key, value] of dataForm.entries()) {
            jsonForm[key] = value;
        }

        if (_.find(accident.documents, ['code', '29']) || !e.target.checked) {
            jsonForm = null;
        }

        onSubmit({ attentePV: e.target.checked }, jsonForm);
    };

    return (
        <div className={'bloc-info bloc-circonstance'}>
            <h2>Circonstances et dommages</h2>
            <div className={'content row'}>
                <div className={'col full-content'}>
                    {accident.motif_deplacement && (
                        <p>
                            <span className={'bold'}>Motif du déplacement :</span> {accident.motif_deplacement}
                        </p>
                    )}
                    <p>
                        <span className={'bold'}>Circonstances :</span> {accident.circonstances}
                    </p>
                    <p>
                        <span className={'bold'}>Dommages :</span> {accident.decDommages}
                    </p>
                    {_.includes(permissions, 'update accident informations') ? (
                        <div className={'bloc-align-left'}>
                            <div>
                                <Field
                                    component={CheckboxFieldRedux}
                                    name={'attentePV'}
                                    label={`En attente de PV`}
                                    type={'checkbox'}
                                    onChange={(e) => handleSubmit(e)}
                                />
                            </div>

                            {!isWakam && accident.type === 'Corporel' && (
                                <div>
                                    <Field
                                        component={CheckboxFieldRedux}
                                        name={'decesConducteur'}
                                        label={`Décès du conducteur`}
                                        type={'checkbox'}
                                        onChange={(e) => {
                                            const isChecked = e.target.checked;
                                            const message =  'Voulez-vous vraiment déclarer le décès du conducteur ?'

                                            if (window.confirm(message)) {
                                                onSubmitDeces({ decesConducteur: Boolean(isChecked) });
                                            }
                                        }}
                                        disabled={formValues?.decesConducteur ?? false}
                                    />
                                </div>
                            )}

                            {!isWakam &&
                                contrat.situation.statutCode !== '5' &&
                                contrat.situation.statutCode !== '6' &&
                                contrat.situation.statutCode !== 'I' &&
                                contrat.situation.statutCode !== '9' && (
                                    <button
                                        type={'button'}
                                        onClick={() => {
                                            if (
                                                window.confirm('Voulez-vous lancer le processus de cession compagnie ?')
                                            )
                                                onSubmitCession();
                                        }}
                                        className={'btn btn-cta btn-ternary mt-3'}
                                    >
                                        Cession compagnie
                                    </button>
                                )}
                        </div>
                    ) : (
                        <>
                            {accident.attentePV ? (
                                <p>
                                    <span className={'bold'}>En attente de PV</span>
                                </p>
                            ) : (
                                ''
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Circonstances;
