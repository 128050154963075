import {connect} from 'react-redux'
import {compose} from 'redux'
import FormAvenantSimpleDateEffet from "./FormAvenantSimpleDateEffet";
import {formValueSelector} from "redux-form";

const mapStateToProps = state => {
    const selector = formValueSelector('avenant-simple')
    return {
        loiHamon: selector(state, 'loiHamon'),
    }
}

const FormAvenantSimpleDateEffetContainer = compose(
    connect(mapStateToProps)
)(FormAvenantSimpleDateEffet)


export default FormAvenantSimpleDateEffetContainer