import {SET_CORRESPONDANTS} from '../../actions/app/correspondants/correspondants.actions'

export const correspondantsReducer = (correspondants = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_CORRESPONDANTS) {
        return payload.data
    } else {
        return correspondants
    }
}
