import {SET_NAVIGATION_ACTIVITIES} from "../../actions/app/navigation_activities/navigation_activities.actions";

export const navigationActivitiesReducer = (activities = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_NAVIGATION_ACTIVITIES) {
        return payload
    } else {
        return activities
    }
}
