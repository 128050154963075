import React from 'react';
import {Field} from "redux-form";
import TextFieldRedux from "../../../../../Commun/Input/Text/TextFieldRedux";
import Spinner from "../../../../../Commun/Spinner/Spinner";

const FormAddPenalties = (props) => {
    const {handleSubmit,loading} = props

    return (
        <form className={'form-content'} onSubmit={handleSubmit}>
            <Field
                name='penalties'
                component={TextFieldRedux}
                label={'Pénalité à ajouter sur le dossier'}
            />
            <div className={'alert alert-warning'}>
                <p>Indiquez une phrase complète (exemple : "Absence antivol = 300€ et absence gravage = 200€.")</p>
            </div>

            {
                loading ? <Spinner style={{display: 'block', margin: 'auto'}}/> :
                    <button className={'btn btn-primary d-block'}
                            data-test-id="avenant_simple_btn_submit" type={'submit'}>
                        Ajouter
                    </button>
            }
        </form>
    );
};

export default FormAddPenalties;