export const INSURERS = '[Insurers]'

export const GET_INSURERS = `${INSURERS} Get`
export const SET_INSURERS = `${INSURERS} Set`

export const getInsurers = ({query}) => ({
    type: GET_INSURERS,
    payload: {
        data: query
    }
})

export const setInsurers = ({insurers}) => ({
    type: SET_INSURERS,
    payload: {
        data: insurers
    }
})
