import React, {Component} from 'react';
import imgRotate from "../../../assets/img/icons/rotate_light.png";
import imgZoomIn from "../../../assets/img/icons/zoomIn_light.png";
import imgZoomOut from "../../../assets/img/icons/zoomOut_light.png";
import imgReset from "../../../assets/img/icons/center_light.png";
import APIImage from "../APILink/APIImage";

class ReaderJpeg extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rotate: 0,
            zoom: 500
        };
    }

    render() {
        const {url} = this.props

        const rotate = () => {
            this.setState({
                rotate: this.state.rotate + 90
            })
        }

        const zoomIn = () => {
            this.setState({
                zoom: this.refImg.clientWidth + 100
            })
        }

        const zoomOut = () => {
            this.setState({
                zoom: this.refImg.clientWidth - 100
            })
        }

        const reset = () => {
            this.setState({
                zoom: 500
            })
        }

        let styleImg = {
            transform: `rotate(${this.state.rotate}deg)`,
            width: `${this.state.zoom}px`,
            display: 'block',
            margin: 'auto'
        }

        return (
            <>
                <div className={'col-10 task-img-container mt-5'}>
                    <APIImage src={`${url}`} style={styleImg} ref={el => this.refImg = el} alt={'Document'}/>
                </div>
                <div className={'col-1 mt-5'}>
                    <div className={'task-nav-img'}>
                        <span className={'task-btn-img'} onClick={() => rotate()}
                              title={'Faire pivoter vers la droite'}><img src={imgRotate} alt="Rotation"/></span>
                        <span className={'task-btn-img'} onClick={() => zoomIn()} title={'Zoom avant'}><img
                            src={imgZoomIn} alt="zoom in"/></span>
                        <span className={'task-btn-img'} onClick={() => zoomOut()} title={'Zoom arrière'}><img
                            src={imgZoomOut} alt="zoom out"/></span>
                        <span className={'task-btn-img'} onClick={() => reset()} title={'Ajuster à la page'}><img
                            src={imgReset} alt="centre"/></span>
                    </div>
                </div>
            </>
        );
    }
}

export default ReaderJpeg;
