import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {reduxForm} from 'redux-form'
import {getLoadingEntity} from '../../../../../redux/selectors/ui/ui.selectors'
import ExpirationDateSejour from "./ExpirationDateSejour";
import {
    EXPIRATION_DATE_SEJOUR,
    postExpirationDateSejour
} from "../../../../../redux/actions/app/date_sejour/date_sejour.actions";
import {dateSejourLink} from "../../../../../redux/selectors/links/links.selectors";
import moment from "moment";
import _ from "lodash";

const mapStateToProps = (state, ownProps) => {
    return {
        tiers: ownProps.location.state.tiers,
        contract: ownProps.location.state.contract,
        uri: dateSejourLink(state),
        loading: getLoadingEntity(state, EXPIRATION_DATE_SEJOUR),
        initialValues: {
            'date': moment()
        }
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    submitPostExpirationDateSejour: ({form, query}) => postExpirationDateSejour({form, query}),
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {submitPostExpirationDateSejour} = dispatchProps
    const {uri, contract, tiers} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form) => {
            form.tiers = tiers.id
            return submitPostExpirationDateSejour({form, query: _.replace(uri, '{id}', contract)})
        }
    }
}
const ExpirationDateSejourContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'expirationDateSejourForm'
    })
)(ExpirationDateSejour)

export default ExpirationDateSejourContainer
