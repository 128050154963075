import {connect} from 'react-redux'
import {compose} from "redux";
import ChangeDateExpertVisitAccident from "./ChangeDateExpertVisitAccident";
import _ from "lodash";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {garageGetLink,} from "../../../../../redux/selectors/links/links.selectors";
import {GARAGE, getGarage} from "../../../../../redux/actions/app/garage/garage.actions";
import loader from "../../../../Commun/Spinner/Loader/Loader";


const mapStateToProps = (state, ownProps) => {
    return {
        repairerId: ownProps.location.state.repairerId,
        uri: garageGetLink(state),
        loaded: getLoadingEntity(state, GARAGE) === false
    }
}

const mapDispatchToProps = {
    getGarage
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {repairerId, uri} = stateProps
    const {getGarage} = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getGarage({query: _.replace(uri, '{id}', repairerId)})
    }
}

const ChangeDateExpertVisitAccidentContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(ChangeDateExpertVisitAccident)

export default ChangeDateExpertVisitAccidentContainer