import React from 'react'
import moment from "moment";
import {Contrat} from "../Icons/Icons"
import Badge from "../Badge/Badge"
import {Link} from 'react-router-dom'
import {withRouter} from "react-router"
import {redirectInNewWindow} from "../../../utils/function";
import {openInWindow} from "../../../constants/constants";

const TaskCard = (props) => {
    const {task, history, showColTiers, showColContrat, showColSinistre} = props
    const {id, contract, tiers_id, sinistre} = task

    const myRedirect = (e) => {
        redirectInNewWindow({e, url: `/tache/${id}`, name: openInWindow.task})

        if (sinistre !== null) {
            history.push({
                pathname: `/contrat/${contract.id}/sinistre/${sinistre.id}`
            })
        } else if (contract !== null && sinistre === null) {
            history.push({
                pathname: `/contrat/${contract.id}`
            })
        } else {
            history.push({
                pathname: `/tiers/${tiers_id}`
            })
        }
    }

    return (
        <div className={`card-task ${task.typeCode === 'AGEND' ? 'card-task-reassign' : ''}`}
             onClick={(e) => myRedirect(e)}>
            <div className={"row"}>
                <div
                    className={`task-info ${!showColContrat && !showColTiers && !showColSinistre ? 'col-12' : 'col-5'}`}>
                    {contract !== null && <Badge status={contract.situation.statut} noText/>}
                    <h2>{task.title}</h2>
                    <p>
                        <strong>{task.paniere}</strong> -
                        {task.typeCode === 'AGEND' ? 'A traiter le ' : 'Reçue le '}
                        <strong>{moment(task.date).format('L')}</strong> par <strong>{task.type}</strong>
                    </p>
                </div>
                {(showColTiers && contract !== null) &&
                    <div className={`task-tiers ${showColContrat ? 'col-3' : 'col-7'}`}>
                        <p className={'text-uppercase'}>{contract.souscripteur.titre} {contract.souscripteur.nom}</p>
                        <h3 className={'border-bottom'}>
                            <Link onClick={(e) => {
                                e.stopPropagation()
                            }} to={{pathname: `/tiers/${contract.souscripteur.id}`}}>
                                {contract.souscripteur.id}
                            </Link>
                        </h3>
                        <h3>&nbsp;{`- ${!contract.vehicule.formule ? contract.vehicule.formuleCode : contract.vehicule.formule}`}</h3>
                        <small>{contract.title}</small>
                    </div>}
                {(showColContrat && contract !== null) &&
                    <div className={`task-contrat ${showColTiers ? 'col-4' : 'col-7'}`}>
                        <div>
                            <Contrat/>
                            <div className={'display-inline'}>
                                <p className={'text-uppercase'}>Contrat en date
                                    du {moment(contract.situation.date).format('L')}</p>
                                <h3 className={'border-bottom'}>{contract.numeroPolice}</h3>
                            </div>
                        </div>
                        <Badge status={contract.situation.statut}/>
                        <span className={'nbTasks'}>{task.nbTaches} tâche{task.nbTaches > 1 && 's'} à traiter</span>
                    </div>}
                {(showColSinistre && sinistre !== null) && <div className={`task-contrat col-4`}>
                    <div>
                        <Contrat/>
                        <div className={'display-inline'}>
                            <p className={'text-uppercase'}>Sinistre en date
                                du {moment(sinistre.dateSurv).format('L')}</p>
                            <h3 className={'border-bottom'}>{sinistre.numCbt}</h3>
                        </div>
                    </div>
                    <Badge status={sinistre.situation}/>
                </div>}
                {(showColContrat && showColTiers && contract === null) && <div className={`task-contrat col-7`}>
                    <p>La tâche est assignée au tiers. Nous ne pouvons pas remonter les informations du
                        contrat.<br/> Pour traiter la tâche vous allez être redirigé sur la fiche du tiers</p>
                </div>}
            </div>
        </div>
    )
}

TaskCard.defaultProps = {
    showColTiers: true,
    showColContrat: true,
}

export default withRouter(TaskCard)
