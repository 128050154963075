import React, {Fragment} from 'react'
import TaskCard from "../../../../Commun/Card/TaskCard"

const Tasks = (props) => {
    const {tasks} = props

    return (
        <Fragment>
            {tasks ?
                tasks.map((task, index) => (
                    <TaskCard task={task} key={index} showColSinistre={false}/>
                ))
                : <p className={'text-center'}>Vous n'avez pas de tâches à traiter</p>
            }
        </Fragment>
    )
}

export default Tasks
