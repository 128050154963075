import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {
    VERSIONNING,
    getVersionning,
    postValidateVersionning
} from '../../../../../redux/actions/app/versionning/versionning.actions'
import {getErrorEntity, getLoadingEntity} from '../../../../../redux/selectors/ui/ui.selectors'
import Versionning from './Versionning'
import {validateVersionningLink, versionningLink} from '../../../../../redux/selectors/links/links.selectors'
import ErrorHttpComponent from '../../../../Commun/Error/ErrorHttpComponent'
import {getVersionningData} from '../../../../../redux/selectors/versionning/versionning.selectors'
import {CONTRACT} from "../../../../../redux/actions/app/contracts/contracts.actions";
import _ from "lodash";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    return {
        versionning: getVersionningData(state),
        uri: versionningLink(state, CONTRACT),
        uri_validate: validateVersionningLink(state, CONTRACT),
        loaded: getLoadingEntity(state, VERSIONNING) === false,
        error: getErrorEntity(state, VERSIONNING),
        tiers: ownProps.tiers,
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getVersionning: (query) => getVersionning({query}),
    postValidateVersionning
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getVersionning, postValidateVersionning} = dispatchProps
    const {uri, uri_validate} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getVersionning(_.replace(uri, '{id}', ownProps.contract)),
        submitPostValidateVersionning: (id) => postValidateVersionning({
            query: _.replace(uri_validate, '{id}', ownProps.contract),
            id: id
        }),
    }
}

const VersionningContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(Versionning)

export default VersionningContainer
