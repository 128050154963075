import {createSelector} from 'reselect'

const accidentsSelectors = state => state.accidents

export const getAccidents = createSelector(
    accidentsSelectors,
    accidents => accidents
)
export const getAccidentsData = createSelector(
    accidentsSelectors,
    accidents => accidents.data || {}
)

export const getAccidentsMeta = createSelector(
    getAccidentsData,
    accidents => accidents.meta
)

export const getAccidentsLinks = createSelector(
    getAccidentsData,
    accidents => accidents.links
)
