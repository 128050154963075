import {connect} from 'react-redux'
import ListenLocation from './ListenLocation'
import {userGetNbTaches} from '../../../redux/selectors/links/links.selectors'
import {bindActionCreators} from 'redux'
import {getNbTasks} from "../../../redux/actions/app/tasks/tasks.actions";
import {getNbTasksUi} from "../../../redux/selectors/ui/ui.selectors";
import {nouvelOnglet} from "../../../utils/function";

const mapStateToProps = state => {
    return {
        nbTasks: getNbTasksUi(state),
        query: userGetNbTaches(state),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getNbTasks,
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getNbTasks} = dispatchProps
    const {query} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        getNbTasks: () => !nouvelOnglet() ? getNbTasks({query}) : null
    }
}


const ListenLocationContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(ListenLocation)

export default ListenLocationContainer
