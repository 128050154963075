import {SET_ACTIVITY, SET_ACTIVITY_FILES} from '../../actions/app/activity/activity.actions'

export const activityReducer = (activity = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_ACTIVITY) {
        return payload
    } else if (action.type === SET_ACTIVITY_FILES) {
        payload['files'] = payload.data.data
        return payload
    } else {
        return activity
    }
}
