import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import ErrorHttpComponent from '../../../../Commun/Error/ErrorHttpComponent'
import _ from "lodash";
import {postChangeSituationContractLink} from "../../../../../redux/selectors/links/links.selectors";
import {
    postChangeSituationContract,
    CHANGE_SITUATION_CONTRACT
} from "../../../../../redux/actions/app/change_situation_contract/change_situation_contract.actions";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import ValideContrat from './ValideContrat'
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    return {
        uri: postChangeSituationContractLink(state),
        loading: getLoadingEntity(state, CHANGE_SITUATION_CONTRACT),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    postValidateContract: ({type, query}) => postChangeSituationContract({type, query}),
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {postValidateContract} = dispatchProps
    const {uri} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        postValidateContract: () => postValidateContract({
            type: 'validate',
            query: _.replace(uri, '{id}', ownProps.contract.id)
        }),
    }
}

const ValideContratContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(ValideContrat)

export default ValideContratContainer
