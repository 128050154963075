import {createSelector} from 'reselect'

const fidelisations = state => state.fidelisations.data
const fidelisation = state => state.fidelisation.data

export const getFidelisationsData = createSelector(
    fidelisations,
    fidelisations => fidelisations || [],
)

export const getFidelisationData = createSelector(
    fidelisation,
    fidelisation => fidelisation || {},
)
