const validate = values => {
    const errors = {}

    const requiredFields = [
        'titre',
        'type',
        'categorie',
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Le champ est obligatoire'
        }
        if (values.categorie === 'agenda' && !values.date_rappel) {
            errors.date_rappel = 'Ce champ est obligatoire'
        }
        if (values.categorie === 'agenda' && (!values.paniere || values.paniere === 'Sélectionner')) {
            errors.paniere = 'Ce champ est obligatoire'
        }
    })

    return errors
}

export default validate
