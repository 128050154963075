const validate = values => {
    const errors = {}

    const requiredFields = [
        'titulaire',
        'iban',
        'domiciliation',
        'bic'
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Ce champ est obligatoire'
        }
    })

    return errors
}

export default validate
