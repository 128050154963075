
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {POST_CREATE_ACCOUNT, CREATE_ACCOUNT, setCreateAccount} from "../../../actions/app/account/account.actions";

export const accountMiddleware = () => next => action => {
    next(action)

    const {payload} = action
    let message = null

    switch (action.type) {
        case POST_CREATE_ACCOUNT:
            next([
                apiRequest({
                    body: (action.payload.body),
                    method: 'POST',
                    url: action.payload.data,
                    entity: CREATE_ACCOUNT
                }),
                setLoader({state: true, entity: CREATE_ACCOUNT})
            ])
            break

        case `${CREATE_ACCOUNT} ${API_SUCCESS}`:
            message = `<p>Le compte a bien été créé. Un mail va être envoyé au client afin qu'il puisse définir son mot de passe</p>`
            next([
                setCreateAccount({state: true}),
                setNotification({entity: CREATE_ACCOUNT, body: message, type: 'success', title: 'Compte créé!'}),
                setLoader({state: false, entity: CREATE_ACCOUNT}),
                setError({state: false, entity: CREATE_ACCOUNT}),
            ])
            break

        case `${CREATE_ACCOUNT} ${API_ERROR}`:
            message = `
                <p>Votre demande n'a pas pu être envoyée. ${payload.data.response.data?.message ?? ''}</p>
            `
            next([
                setNotification({
                    entity: CREATE_ACCOUNT,
                    body: message,
                    type: 'error',
                    title: 'Erreur lors de l\'inscription !',
                    timeout: '0'
                }),
                setError({state: true, entity: CREATE_ACCOUNT}),
                setLoader({state: false, entity: CREATE_ACCOUNT}),
            ])
            break

        default:
            break
    }
    return null
}
