import React from 'react';
import CardCirconstances from "../../../../../../../Commun/Card/CardCirconstances";
import CheckboxAccident from "../../../../../../../Commun/Input/CheckboxAccident/CheckboxAccident";
import {Field} from "redux-form";
import _ from "lodash";

const Circonstances = (props) => {
    const {circonstances, form} = props

    let totalA = 0
    let totalB = 0

    let formCirc = _.get(form, ['circonstances']) || {}
    _.map(formCirc, function (o, n) {
        if (_.startsWith(n, 'coche_a') && o === true) {
            totalA++
        }
        if (_.startsWith(n, 'coche_b') && o === true) {
            totalB++
        }
    });

    const reformatNumber = (number) => {
        return String(number).replace(/\D/g, '');
    }

    return (
        <div className="col-md-12 col-sm-12" style={{marginBottom: 30}}>
            {
                circonstances.map((c, i) =>
                    <CardCirconstances key={i}>
                        <Field name={`circonstances[coche_a${c.number}]`} component={CheckboxAccident}
                               type={"checkbox"} className={'checkboxField float-left position-relative'}
                        />
                        <p style={{display: 'inline-block', width: '90%'}}>
                            <b className={'h5 font-weight-bold'} style={{float: 'left'}}>{reformatNumber(c.number)}</b>
                            {c.text}
                            <b className={'h5 font-weight-bold'} style={{float: 'right'}}>{reformatNumber(c.number)}</b>
                        </p>
                        <Field name={`circonstances[coche_b${c.number}]`} component={CheckboxAccident}
                               type={"checkbox"} className={'checkboxField float-right position-relative'}
                        />
                    </CardCirconstances>
                )
            }
            <p className={'text-center font-weight-bold'} style={{fontSize: 20}}>
                <span className={'float-left'} style={{marginLeft: 25}}>{totalA}</span>
                Total
                <span className={'float-right'} style={{marginRight: 25}}>{totalB}</span>
            </p>
        </div>
    );
};

export default Circonstances;
