export const AVENANT_SIMPLE = '[Avenant Simple]';

export const POST_AVENANT_SIMPLE = `${AVENANT_SIMPLE} Post`;

export const postAvenantSimple = ({query, form}) => ({
    type: POST_AVENANT_SIMPLE,
    payload: {
        data: query,
        body: form
    }
});
