import React from 'react'
import {Field} from "redux-form";
import {Link} from "react-router-dom";
import MyButton from "../../../../Commun/Button/Button";
import Spinner from "../../../../Commun/Spinner/Spinner";
import TextAreaFieldRedux from "../../../../Commun/Input/Text/TextAreaFieldRedux";

const RefuseVirement = (props) => {
    const {loading, handleSubmit} = props

    return (
        <div className={'contract-container container'}>
            <div className={'row return-content'}>
                <div className={'col'}>
                    <Link to={`/virements`} className={'btn btn-return'}>Retour à la liste des virements</Link>
                </div>
            </div>
            <div className={'row header-row'}>
                <div className={'col-12'}>
                    <form className={'form-content'} onSubmit={handleSubmit}>
                        <Field
                            name={'commentaire'}
                            component={TextAreaFieldRedux}
                            label={'Commentaire'}
                            subLabel={'500 caractères maximum'}
                            maxLength={'500'}
                        />
                        <MyButton
                            variant='raised'
                            color='secondary'
                            size='large'
                            className={'btn btn-primary'}
                            disabled={loading}
                        >
                            {loading && <Spinner absolute/>}
                            Refuser le virement
                        </MyButton>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default RefuseVirement
