import {redirectInNewWindow} from "../../../../../../../utils/function";
import {openInWindow} from "../../../../../../../constants/constants";
import _ from "lodash";
import CallToAction from "../../../../../../Commun/Button/CallToAction/CallToAction";
import APILink from "../../../../../../Commun/APILink/APILink";
import React from "react";

const Buttons = (props) => {
    const {postLogActionFormAccident, reasonAccident, contract, vehicule, permissions} = props

    const handleClickFormSinistre = (e) => {
        postLogActionFormAccident()

        redirectInNewWindow({
            e,
            url: `/contrat/${contract.id}/declaration-de-sinistre/${vehicule.adhesion}`,
            name: openInWindow.formulaire
        })
    }

    return (
        <div>
            <CallToAction
                action={'accident'}
                toolText={'Déclarer un sinistre'}
                url={false}
                disabled={!contract.accident_claim_link || contract.situation.statutCode === 'A' || contract.situation.statutCode === '2' || !_.includes(permissions, 'create accident')}
                disabledReason={contract.situation.statutCode !== 'A' ? reasonAccident : 'Déclaration désactivée si contrat résilié / impayé'}
                onClick={(e) => {
                    handleClickFormSinistre(e)
                }}
            />
            { vehicule.memo_link &&
                <APILink rel="noopener noreferrer" download href={vehicule.memo_link} className={'btn btn-cta h-auto'}>Télécharger le Mémo</APILink>
            }
        </div>
    );
};

export default Buttons;
