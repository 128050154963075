import _ from "lodash";
import NavigationActivityButton from "./NavigationActivityButton/NavigationActivityButton";
import PropTypes from 'prop-types'

const NavigationActivity = (props) => {
    const {selected, navigation_activities, tiers_id, roles} = props
    return (
        <div id={"navigationActivity"}>
            <NavigationActivityButton type={'tiers'} element={navigation_activities} selected={selected}/>
            {
                navigation_activities.contracts && _.map(navigation_activities.contracts, function (contract, key) {
                    return <div key={key} className={'ml-4'}>
                        <NavigationActivityButton type={'contrat'} element={contract} selected={selected}/>
                        {
                            _.includes(roles, 'gestionnaire indem') && contract.accidents && _.map(contract.accidents, function (sinistre, key_sin) {
                                return <div key={key_sin} className={'ml-4'}>
                                    <NavigationActivityButton type={'accident'} element={sinistre} contract_id={contract.id}
                                                              selected={selected}/>
                                    {
                                        sinistre.complains && _.map(sinistre.complains, function (recla, key_recla) {
                                            return <div key={key_recla} className={'ml-4'}>
                                                <NavigationActivityButton type={'reclamation'} element={recla} selected={selected}
                                                                          tiers_id={tiers_id}/>
                                            </div>
                                        })
                                    }
                                </div>
                            })
                        }
                        {
                            //Réclamation liée à un contrat
                            contract.complains && _.map(contract.complains, function (recla, key_recla) {
                                return <div key={key_recla} className={'ml-4'}>
                                    <NavigationActivityButton type={'reclamation'} element={recla} selected={selected}
                                                              tiers_id={tiers_id}/>
                                </div>
                            })
                        }
                    </div>
                })
            }
        </div>
    );
};

NavigationActivity.propTypes = {
    selected: PropTypes.exact({
        id: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['tiers', 'contrat', 'accident', 'reclamation']).isRequired,
    }),
    tiers_id: PropTypes.string.isRequired
};


export default NavigationActivity;
