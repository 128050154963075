

export const ACCIDENTS = '[Accidents]'
export const ACCIDENT = '[Accident]'
export const ACCIDENT_NON_DEC = '[Accident Non declare]'
export const REJECT_ACCIDENT = '[Reject Accident]'
export const DOCUMENT_NEEDED = '[Documents Needed Accident]'

export const GET_ACCIDENTS = `${ACCIDENTS} Get`
export const SET_ACCIDENTS = `${ACCIDENTS} Set`

export const GET_ACCIDENT = `${ACCIDENT} Get`
export const SET_ACCIDENT = `${ACCIDENT} Set`
export const POST_ACCIDENT = `${ACCIDENT} Post`
export const POST_ACCIDENT_NON_DEC = `${ACCIDENT_NON_DEC} Post`
export const POST_UPDATE_ACCIDENT = `${ACCIDENT} Post Update`
export const POST_REJECT_ACCIDENT = `${REJECT_ACCIDENT} Post`
export const POST_COMMENT_ACCIDENT = `${ACCIDENT} Post Comment`
export const POST_EMAIL_COMPANY_ACCIDENT = `${ACCIDENT} Post Email Company`
export const GET_DOCUMENT_NEEDED = `${DOCUMENT_NEEDED} Get`
export const POST_REPAIRER_ACCIDENT = `${ACCIDENT} Post Repairer`
export const POST_ATTENTE_PV_ACCIDENT = `${ACCIDENT} Post Attente Pv`
export const POST_STATUS_ACCIDENT = `${ACCIDENT} Post Status`
export const POST_GARANTIE_ACCIDENT = `${ACCIDENT} Post Garanties`
export const POST_DEFINE_AS_COVERED = `${ACCIDENT} Post Define As Covered`
export const POST_VEHICLE_TRANSFER = `${ACCIDENT} Post transfer to company`
export const POST_DECES_CIE_ACCIDENT = `${ACCIDENT} Post Deces`
export const POST_UPDATE_RESP_ACCIDENT = `${ACCIDENT} Post Responsabilite`
export const POST_COMPAGNIE_ADVERSE_ACCIDENT = `${ACCIDENT} Post change compagnie adverse`
export const POST_UPDATE_CONDUCTEUR_ACCIDENT = `${ACCIDENT} Post Conducteur`
export const POST_ADD_EXPERT_ACCIDENT = `${ACCIDENT} Post Add Expert`
export const POST_DELETE_EXPERT_ACCIDENT = `${ACCIDENT} Post Delete Expert`
export const POST_DELETE_REPAIRER_ACCIDENT = `${ACCIDENT} Post Delete Repairer`
export const POST_CHANGE_DATE_EXPERT_VISIT = `${ACCIDENT} Post Change Date Expert Visit`
export const POST_REFUND_REPAIRER_ACCIDENT = `${ACCIDENT} Post Change Remboursement reparateur`
export const POST_ADD_INTERVENANT = `${ACCIDENT} Post Add Intervenant`
export const POST_ADD_PENALTIES = `${ACCIDENT} Post Add Penalties`
export const POST_DELETE_PENALTIES = `${ACCIDENT} Post Delete Penalties`
export const POST_ADD_OPPOSITIONS = `${ACCIDENT} Post Add Oppositions`
export const POST_DELETE_OPPOSITIONS = `${ACCIDENT} Post Delete Oppositions`
export const POST_PROCESSING_PV = `${ACCIDENT} Post Processing PV`
export const POST_UPDATE_ACCIDENT_BY_TASK = `${ACCIDENT} Post Update By Task`

export const getAccidents = ({query}) => ({
    type: GET_ACCIDENTS,
    payload: {
        data: query
    }
})

export const setAccidents = ({data, meta = null}) => ({
    type: SET_ACCIDENTS,
    payload: {data, meta},
    meta: {
        entity: ACCIDENTS
    }
})

export const getAccident = ({query, id}) => ({
    type: GET_ACCIDENT,
    payload: {
        data: query,
        otherData: {
            id: id
        }
    }
})

export const setAccident = ({data}) => ({
    type: SET_ACCIDENT,
    payload: {data},
    meta: {
        entity: ACCIDENT
    }
})

export const postAccident = ({form, query, documents}) => ({
    type: POST_ACCIDENT,
    payload: {
        data: query,
        body: {
            form, documents
        }
    }
})

export const postAccidentNonDec = ({form, query}) => ({
    type: POST_ACCIDENT_NON_DEC,
    payload: {
        data: query,
        body: form
    }
})

export const postRejectAccident = ({query, cause}) => ({
    type: POST_REJECT_ACCIDENT,
    payload: {
        data: query,
        body: ({categorie: cause})
    }
})

export const getDocumentsAccident = ({query}) => ({
    type: GET_DOCUMENT_NEEDED,
    payload: {
        data: query
    }
})

export const postCommentAccident = ({query, form}) => ({
    type: POST_COMMENT_ACCIDENT,
    payload: {
        data: query,
        body: (form)
    }
})

export const postUpdateAccidentRepairer = ({query, form}) => ({
    type: POST_REPAIRER_ACCIDENT,
    payload: {
        data: query,
        body: (form)
    }
});


export const postChangeRefundRepairerAccident = ({query, form}) => ({
    type: POST_REFUND_REPAIRER_ACCIDENT,
    payload: {
        data: query,
        body: (form)
    }
});

export const postDeleteAccidentRepairer = ({query}) => ({
    type: POST_DELETE_REPAIRER_ACCIDENT,
    payload: {
        data: query
    }
});

export const postUpdateAccidentCompagnieAdverse = ({query, form}) => ({
    type: POST_COMPAGNIE_ADVERSE_ACCIDENT,
    payload: {
        data: query,
        body: (form)
    }
});

export const postEmailCompany = ({query, form}) => ({
    type: POST_EMAIL_COMPANY_ACCIDENT,
    payload: {
        data: query,
        body: (form)
    }
})

export const postAttentePvAccident = ({query, form}) => ({
    type: POST_ATTENTE_PV_ACCIDENT,
    payload: {
        data: query,
        body: (form)
    }
})

export const postUpdateAccidentStatus = ({query, form}) => ({
    type: POST_STATUS_ACCIDENT,
    payload: {
        data: query,
        body: (form)
    }
})

export const postUpdateAccidentGaranties = ({query, form, formName}) => ({
    type: POST_GARANTIE_ACCIDENT,
    payload: {
        data: query,
        body: (form),
        formName
    }
})

export const postUpdateAccident = ({form, query}) => ({
    type: POST_UPDATE_ACCIDENT,
    payload: {
        data: query,
        body: form
    }
})

export const postUpdateAccidentByTask = ({form, query, accident, terminateQuery}) => ({
    type: POST_UPDATE_ACCIDENT_BY_TASK,
    payload: {
        data: query,
        body: {accident, ...form},
        otherData: {
            terminate: terminateQuery
        }
    }
})

export const postDefineAsCovered = ({query}) => ({
    type: POST_DEFINE_AS_COVERED,
    payload: {
        data: query
    }
})

export const postCessionCieAccident = ({query}) => ({
    type: POST_VEHICLE_TRANSFER,
    payload: {
        data: query
    }
})

export const postDecesCieAccident = ({form, query}) => ({
    type: POST_DECES_CIE_ACCIDENT,
    payload: {
        data: query,
        body: form
    }
})

export const postUpdateRespAccident = ({form, query}) => ({
    type: POST_UPDATE_RESP_ACCIDENT,
    payload: {
        data: query,
        body: (form)
    }
})

export const postUpdateConducteurAccident = ({form, query}) => ({
    type: POST_UPDATE_CONDUCTEUR_ACCIDENT,
    payload: {
        data: query,
        body: (form)
    }
})

export const postDeleteExpert = ({query}) => ({
    type: POST_DELETE_EXPERT_ACCIDENT,
    payload: {
        data: query
    }
})

export const postAddExpert = ({query}) => ({
    type: POST_ADD_EXPERT_ACCIDENT,
    payload: {
        data: query
    }
})

export const postChangeDateExpertVisitAccident = ({form, query}) => ({
    type: POST_CHANGE_DATE_EXPERT_VISIT,
    payload: {
        data: query,
        body: form
    }
})

export const postAddIntervenant = ({query ,form}) => ({
    type: POST_ADD_INTERVENANT,
    payload: {
        data: query,
        body: form
    }
})

export const postAddPenalties = ({query, form}) => ({
    type: POST_ADD_PENALTIES,
    payload: {
        data: query,
        body: form
    }
});

export const postDeletePenalties = ({query}) => ({
    type: POST_DELETE_PENALTIES,
    payload: {
        data: query
    }
})


export const postAddOppositions = ({query, form}) => ({
    type: POST_ADD_OPPOSITIONS,
    payload: {
        data: query,
        body: form
    }
});

export const postDeleteOppositions = ({query}) => ({
    type: POST_DELETE_OPPOSITIONS,
    payload: {
        data: query
    }
})

export const postProcessingPV = ({query, form, accident, terminateQuery}) => ({
    type: POST_PROCESSING_PV,
    payload: {
        data: query,
        body: {accident, ...form},
        otherData: {
            terminate: terminateQuery
        }
    }
})
