import React from 'react'

/*
Usage :

<Field
  name={String}
  component={TextFieldRedux}
  label={String}
  InputProps={{
    placeholder: {String}
  }}
/>
 */

const SelectFieldRedux = ({
                              meta: {touched, error},
                              input,
                              classes,
                              label,
                              type,
                              InputLabelProps,
                              ...custom
                          }) => {
    return (
        <div className={'form-group'}>
            {label &&
                <label {...InputLabelProps}>
                    {label}
                </label>
            }
            <select
                className={'form-control'}
                {...input}
                {...custom}
            />
            {touched && error && <small className="invalid-feedback">{error}</small>}
        </div>
    )
}

export default (SelectFieldRedux)
