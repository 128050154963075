
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {
    POST_VALIDATE_RIB,
    POST_VALIDATE_RIB_ACCIDENT,
    VALIDATE_RIB, VALIDATE_RIB_ACCIDENT
} from "../../../actions/app/validate_rib/validate_rib.actions";
import {CLOSE_TASK} from "../../../actions/app/tasks/tasks.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import _ from "lodash";

export const validateRibMiddleware = () => next => action => {
    next(action)

    const {payload} = action
    let nextAction = []
    let clone = null;

    switch (action.type) {

        case POST_VALIDATE_RIB:
            clone = _.cloneDeep(payload.body)

            clone.iban = payload.body.iban.replace(/[-]/g, '')

            if(clone.type && clone.type === 'P'){
                clone.birthdate = clone.birthdate.format('YYYY-MM-DD')
            }

            nextAction = [
                apiRequest({
                    body: (_.pickBy(clone, function(value) {return  !_.isNull(value)})),
                    method: 'POST',
                    url: payload.data,
                    entity: VALIDATE_RIB,
                    otherData: payload.otherData
                }),
                setLoader({state: true, entity: VALIDATE_RIB})
            ]

            if (payload.otherData.terminate !== undefined) {
                nextAction.push(setLoader({state: true, entity: CLOSE_TASK}))
            }

            next(nextAction)
            break

        case `${VALIDATE_RIB} ${API_SUCCESS}`:

            nextAction = [
                setError({state: false, entity: VALIDATE_RIB}),
                setLoader({state: false, entity: VALIDATE_RIB})
            ]

            if (action.payload.meta.otherData.terminate !== undefined) {
                nextAction.push(apiRequest({
                    body: null,
                    method: 'POST',
                    url: action.payload.meta.otherData.terminate,
                    entity: CLOSE_TASK
                }))
            } else {
                nextAction.push(setNotification({
                    entity: CLOSE_TASK,
                    body: `<p>Le RIB a bien été modifié</p>`,
                    type: 'success',
                    title: 'RIB mis à jour !'
                }))
            }

            next(nextAction)

            break

        case `${VALIDATE_RIB} ${API_ERROR}`:
            next([
                setError({state: true, entity: VALIDATE_RIB}),
                setLoader({state: false, entity: VALIDATE_RIB})
            ])
            break

        case POST_VALIDATE_RIB_ACCIDENT:
            clone = _.cloneDeep(action.payload.body)

            if (clone.iban) {
                clone.iban = clone.iban.replace(/[-]/g, '')
            }

            next([
                apiRequest({
                    body: (clone),
                    method: 'POST',
                    url: payload.data,
                    entity: VALIDATE_RIB_ACCIDENT
                }),
                setLoader({state: true, entity: VALIDATE_RIB_ACCIDENT})
            ])
            break

        case `${VALIDATE_RIB_ACCIDENT} ${API_SUCCESS}`:
            next([
                setError({state: false, entity: VALIDATE_RIB_ACCIDENT}),
                setLoader({state: false, entity: VALIDATE_RIB_ACCIDENT}),
                setNotification({
                    entity: CLOSE_TASK,
                    body: `<p>Le RIB a bien été modifié</p>`,
                    type: 'success',
                    title: 'RIB mis à jour !'
                })
            ]);
            break

        case `${VALIDATE_RIB_ACCIDENT} ${API_ERROR}`:
            next([
                setError({state: true, entity: VALIDATE_RIB_ACCIDENT}),
                setLoader({state: false, entity: VALIDATE_RIB_ACCIDENT})
            ])
            break

        default:
            break
    }
    return null
}
