import React from 'react';
import moment from "moment";
import {Link} from "react-router-dom";


const Accidents = (props) => {
    const {accidents, getAccidents, meta, links} = props

    return (
        <div className="container">
            <div className={'row'}>
                <div className={'col-12'}>
                    <div className={'bloc-info bloc-accidents'}>
                        <h2>Liste des sinistres</h2>
                        <div className={'content row'}>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th scope="col">Date du sinistre</th>
                                    <th scope="col">Numero du sinistre</th>
                                    <th scope="col">Situation</th>
                                    <th scope="col">Catégorie</th>
                                    <th scope="col">Détails</th>
                                </tr>
                                </thead>
                                <tbody>
                                {accidents.data.map((accident, i) =>
                                    <tr key={i}>
                                        <td>{moment(accident.dateSurv).format('L')}</td>
                                        <td>{accident.numCbt}</td>
                                        <td>{accident.situation}</td>
                                        <td>{accident.categorie}</td>
                                        <td>
                                            <Link to={`/contrat/${accident.id_contrat}/sinistre/${accident.id}`}
                                                  target="_blank" rel="noopener noreferrer" className={'btn btn-more'}/>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                            <div className={'row'}>
                                <nav>
                                    <p>{meta.to}/{meta.total} véhicules</p>
                                    <ul className="pagination">
                                        {meta.current_page > 2 &&
                                            <li className="page-item" onClick={() => getAccidents(links.first)}><span
                                                className="page-link">{`<<`}</span></li>}
                                        {meta.current_page > 1 &&
                                            <li className="page-item" onClick={() => getAccidents(links.prev)}><span
                                                className="page-link"> {`<`} </span></li>}
                                        <li className="page-item"><span
                                            className={`page-link current`}>{meta.current_page}</span></li>
                                        {meta.last_page > meta.current_page &&
                                            <li className="page-item" onClick={() => getAccidents(links.next)}><span
                                                className="page-link">{`>`}</span></li>}
                                        {meta.last_page >= meta.current_page + 2 &&
                                            <li className="page-item" onClick={() => getAccidents(links.last)}><span
                                                className="page-link">{`>>`}</span></li>}
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


Accidents.defaultProps = {};


Accidents.propTypes = {};


export default Accidents;
