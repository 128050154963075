import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import validate from './validate'
import ChangeRib from "./ChangeRib";
import {change, formValueSelector, reduxForm} from "redux-form";
import {
    amendmentChangeIbanGetLink,
    contractDefaultGetLink,
    tiersGetLink
} from "../../../../../redux/selectors/links/links.selectors";
import {CONTRACT, getContract} from "../../../../../redux/actions/app/contracts/contracts.actions";
import {getErrorEntity, getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import ErrorHttpComponent from "../../../../Commun/Error/ErrorHttpComponent";
import _ from "lodash";
import {getContractData} from "../../../../../redux/selectors/contract/contract.selectors";
import {postValidateRib, VALIDATE_RIB} from "../../../../../redux/actions/app/validate_rib/validate_rib.actions";
import {getTiers, TIERS} from "../../../../../redux/actions/app/tiers/tiers.actions";
import {getTiersData} from "../../../../../redux/selectors/tiers/tiers.selectors";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector('validate_rib')

    return {
        tiers: getTiersData(state),
        contract: getContractData(state),
        uri: window.location.href.includes('tiers') ? tiersGetLink(state) : contractDefaultGetLink(state),
        loaded: window.location.href.includes('tiers') ? getLoadingEntity(state, TIERS) === false : getLoadingEntity(state, CONTRACT) === false,
        error: window.location.href.includes('tiers') ? getErrorEntity(state, TIERS) : getErrorEntity(state, CONTRACT),
        id: ownProps.match.params.id,
        uriPostRib: amendmentChangeIbanGetLink(state),
        loadingValidateRib: getLoadingEntity(state, VALIDATE_RIB),
        errorRib: getErrorEntity(state, VALIDATE_RIB),
        ibanIsCheck: Boolean(selector(state, 'domiciliation') && selector(state, 'bic')),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getContract: (query) => getContract({query}),
    getTiers: ({query, id}) => getTiers({query, id}),
    postValidateRib: ({query, form, tiers, terminateQuery}) => postValidateRib({query, form, tiers, terminateQuery}),
    changeValue: (field, value) => change('validate_rib', field, value)
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getContract, postValidateRib, getTiers} = dispatchProps
    const {contract, uri, id, uriPostRib, terminateQuery, tiers} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => {
            if (window.location.href.includes('tiers')) {
                getTiers({query: _.replace(uri, '{id}', id), id: id})
            } else {
                contract.id === undefined && getContract(_.replace(uri, '{id}', id))
            }
        },
        onSubmit: (form) => {
            if (window.location.href.includes('tiers')) {
                postValidateRib({query: uriPostRib, form, tiers: tiers.belair_id, terminateQuery})
            } else {
                postValidateRib({query: uriPostRib, form, tiers: contract.souscripteur.id, terminateQuery})
            }
        }
    }
}

const ChangeRibContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent}),
    reduxForm({
        form: 'validate_rib',
        validate
    }),
)(ChangeRib)

export default ChangeRibContainer
