import {SET_FAQ} from '../../actions/app/faq/faq.actions'

export const faqReducer = (faq = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_FAQ) {
        return payload.data
    } else {
        return faq
    }
}
