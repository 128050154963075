import {
    SET_DASHBOARD_CONSEILLER_TLV
} from "../../actions/app/dashboard_conseiller_televente/dashboard_conseiller_televente.actions";

export const dashboardConseillerTeleventeReducer = (dashboard = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_DASHBOARD_CONSEILLER_TLV) {
        return payload.data
    } else {
        return dashboard
    }
}
