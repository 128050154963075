import {connect} from 'react-redux'
import Buttons from './Buttons'
import {getReason} from '../../../../../utils/reasons'
import {
    getContratAccidentClaimLink,
    getAvenantNonTarifantLinkReason,
    getContractPrelevement,
    getContratAvenantLinkReason,
    getIsACLContract, getIsEligibleHivernageContract, getDernierHivernageContract, getIsWarkamContract
} from '../../../../../redux/selectors/contract/contract.selectors'
import {PUBLISH_URL} from "../../../../../constants/constants";
import {postNoteContractGetLink} from "../../../../../redux/selectors/links/links.selectors";
import _ from "lodash";
import {bindActionCreators} from "redux";
import {logAction} from "../../../../../redux/actions/core/logs/logs.actions";
import {getUserIdBelair, getUserPermissions, getUserRoles} from "../../../../../redux/selectors/user/user.selectors";
import {getReclamationData} from "../../../../../redux/selectors/reclamation/reclamation.selectors";

const mapStateToProps = (state, ownProps) => {
    return {
        reasonAvenantTarif: getReason(getContratAvenantLinkReason(state)),
        reasonAccident: getReason(getContratAccidentClaimLink(state)),
        reasonAvenant: getReason(getAvenantNonTarifantLinkReason(state)),
        contract: ownProps.contract,
        publishUrl: PUBLISH_URL,
        prelevement: getContractPrelevement(state),
        noteUrl: _.replace(postNoteContractGetLink(state), '{id}', ownProps.contract.id),
        permissions: getUserPermissions(state),
        roles: getUserRoles(state),
        reclamations: getReclamationData(state) || [],
        isACLContract: getIsACLContract(state),
        is_eligible_hivernage : getIsEligibleHivernageContract(state),
        belairId: getUserIdBelair(state),
        isWakam: getIsWarkamContract(state)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    postLogAction: ({entity, type, contrat}) => logAction({entity, type, contrat})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {postLogAction} = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        postLogActionEditDocument: () => postLogAction({
            entity: null,
            type: 'CLICK_EDIT_DOCUMENT',
            contrat: ownProps.contract.id,
            tiers: ownProps.contract.souscripteur.id
        }),
        postLogActionFormAccident: () => postLogAction({
            entity: null,
            type: 'CLICK_FORM_ACCIDENT',
            contrat: ownProps.contract.id,
            tiers: ownProps.contract.souscripteur.id
        }),
        postLogActionFormRib: () => postLogAction({
            entity: null,
            type: 'CLICK_FORM_RIB',
            contrat: ownProps.contract.id,
            tiers: ownProps.contract.souscripteur.id
        }),
        postLogActionFormChangeAdress: () => postLogAction({
            entity: null,
            type: 'CLICK_FORM_CHANGE_ADDRESS',
            contrat: ownProps.contract.id,
            tiers: ownProps.contract.souscripteur.id
        }),
        postLogActionAvenant: () => postLogAction({
            entity: null,
            type: 'CLICK_AVENANT',
            contrat: ownProps.contract.id,
            tiers: ownProps.contract.souscripteur.id
        }),
    }
}


const ButtonsContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Buttons)

export default ButtonsContainer
