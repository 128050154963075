import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import Accessoires from './Accessoires'
import {
    ACCESSOIRES,
    getAccessoires,
    postDeleteAccessoire
} from "../../../../../redux/actions/app/accessoires/accessoires.actions";
import {getErrorEntity, getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {
    accessoiresGetLink,
    deleteAccidentAccessoiresGetLink
} from "../../../../../redux/selectors/links/links.selectors";
import {getAccessoiresData} from "../../../../../redux/selectors/accessoires/accessoires.selectors";
import _ from "lodash";
import ErrorHttpComponent from "../../../../Commun/Error/ErrorHttpComponent";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    return {
        accessoires: getAccessoiresData(state),
        uri: accessoiresGetLink(state),
        loaded: getLoadingEntity(state, ACCESSOIRES) === false,
        error: getErrorEntity(state, ACCESSOIRES),
        uriDelete: deleteAccidentAccessoiresGetLink(state)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getAccessoires: (query) => getAccessoires({query}),
    submitDeleteAccessoire: ({id, query}) => postDeleteAccessoire({id, query}),
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uri, uriDelete} = stateProps
    const {getAccessoires, submitDeleteAccessoire} = dispatchProps
    const {contract, accident} = ownProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getAccessoires(_.replace(_.replace(uri, '{id}', contract.id), '{ids}', accident.id)),
        postSubmitDeleteAccessoire: (id) => submitDeleteAccessoire({
            id,
            query: _.replace(_.replace(uriDelete, '{id}', contract.id), '{ids}', accident.id)
        })
    }
}

const AccessoiresContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(Accessoires)

export default AccessoiresContainer
