import {connect} from 'react-redux'
import {compose} from "redux";
import DashboardTeleventeConseiller from "./DashboardTeleventeConseiller";
import {getLoadingEntity} from "../../../../redux/selectors/ui/ui.selectors";
import {dashboardTeleventeConseillerGetLink} from "../../../../redux/selectors/links/links.selectors";
import {
    DASHBOARD_CONSEILLER_TLV, getDashboardConseillerTelevente
} from "../../../../redux/actions/app/dashboard_conseiller_televente/dashboard_conseiller_televente.actions";
import loader from "../../../Commun/Spinner/Loader/Loader";


const mapStateToProps = state => {
    return {
        loaded: getLoadingEntity(state, DASHBOARD_CONSEILLER_TLV) === false,
        uri: dashboardTeleventeConseillerGetLink(state),
        filters: {
            start_date: localStorage.getItem('start_date_tlv') || null,
            end_date: localStorage.getItem('end_date_tlv') || null,
            period: localStorage.getItem('period_tlv') || null,
        }
    }
}

const mapDispatchToProps = {
    getDashboardConseillerTelevente: ({query, filters}) => getDashboardConseillerTelevente({query, filters}),
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { uri, filters } = stateProps
    const { getDashboardConseillerTelevente } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getDashboardConseillerTelevente({
            query: uri,
            filters
        })
    }
}

const DashboardTeleventeConseillerContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(DashboardTeleventeConseiller)

export default DashboardTeleventeConseillerContainer