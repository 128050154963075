import {connect} from 'react-redux'
import {formValueSelector} from 'redux-form'
import DeclarationBis from './DeclarationBis'
import {
    getContractCodeProduit,
    getContractCylindree,
    getContractGarantiesOptions, getIsFleetContract, getIsWarkamContract
} from "../../../../../../../../redux/selectors/contract/contract.selectors";
import {getGarantiesFlotteDetailData} from "../../../../../../../../redux/selectors/flotte/flotte.selectors";

const mapStateToProps = state => {
    const selector = formValueSelector('declaration-sinistre')

    return {
        categorie: selector(state, 'catsinorigin'),
        vehiculeDommage: selector(state, 'vehiculeDommage'),
        type_sinistre: selector(state, 'type_sinistre'),
        vehiculeIsAttached: selector(state, "vehiculeIsAttached"),
        antiVolMecanique: selector(state, "antiVolMecanique"),
        garanties: getIsFleetContract(state) ? getGarantiesFlotteDetailData(state) : getContractGarantiesOptions(state),
        blesse: selector(state, 'blesse'),
        indentify: selector(state, 'indentify'),
        temoin: selector(state, 'temoin'),
        sraCertified: selector(state, 'sraCertified'),
        cylindree: getContractCylindree(state),
        moreDamages: selector(state, 'moreDamages'),
        corpoconduct: selector(state, 'corpoconduct'),
        codeProduit: getContractCodeProduit(state),
        isWakam: getIsWarkamContract(state)
    }
}

const DeclarationBisContainer = connect(mapStateToProps)(DeclarationBis)

export default DeclarationBisContainer
