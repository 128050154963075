import {SET_ACCESSOIRE_DETAIL} from "../../actions/app/accessoires/accessoires.actions";

export const accessoireReducer = (accessoire = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_ACCESSOIRE_DETAIL) {
        return payload.data
    } else {
        return accessoire
    }
}
