import React, {Fragment} from 'react'
import ButtonsContainer from "../Buttons/ButtonsContainer";
import {redirectInNewWindow} from "../../../../../utils/function";
import CommerciauxContainer from "./Commerciaux/CommerciauxContainer";
import ApporteursContainer from "./Apporteurs/ApporteursContainer";
import CorrespondantsContainer from "./Correspondants/CorrespondantsContainer";
import StatsContainer from "./Stats/StatsContainer";
import _ from "lodash";
import {openInWindow} from "../../../../../constants/constants";
import ReclamationsContainer from "../../Reclamations/ReclamationsContainer";
import ChequesCadeauxContainer from "./ChequesCadeaux/ChequesCadeauxContainer";
import CommissionsContainer from "./Commissions/CommissionsContainer";
import AccidentsContainer from "./Accidents/AccidentsContainer";
import InfoCourtierContainer from "./InfoCourtier/InfoCourtierContainer";
import ComptePaddockContainer from "./ComptePaddock/ComptePaddockContainer";

const Courtier = (props) => {
    const {tiers, permissions, roles} = props

    return (
        <Fragment>
            <div className={'row'}>
                <div className={'col'}>
                    <h1 className={'title-primary'}>{tiers.belair_id} - {tiers.titre} {tiers.name}</h1>
                    {!_.includes(roles, 'gestionnaire televente') &&
                        <span className={'btn btn-cta btn-action float-right'} onClick={(e) => redirectInNewWindow({
                            e,
                            url: `/les-activites/tiers/${tiers.belair_id}`,
                            name: openInWindow.activitiesTiers
                        })}>Les activités du tiers</span>}
                </div>
                <div className={'col-12 mt-3'}>
                    <ButtonsContainer tiers={tiers}/>
                </div>
            </div>
            <div className="row margin-top">
                <div className="col-12">
                    <ComptePaddockContainer/>
                </div>
            </div>
            <div className={'row margin-top'}>
                <div className={'col-6'}>
                    <InfoCourtierContainer tiers={tiers} permissions={permissions}/>
                </div>
                <div className={'col-6'}>
                    <CommerciauxContainer id={tiers.belair_id} className={'loader'}/>
                </div>
            </div>
            <div className={'row margin-top'}>
                <div className={'col'}>
                    <CorrespondantsContainer className={'loader'} id={tiers.belair_id}/>
                </div>
            </div>
            {_.includes(permissions, 'read commissions') && <div className={'row margin-top'}>
                <div className={'col'}>
                    <CommissionsContainer className={'loader'} id={tiers.belair_id}/>
                </div>
            </div>}
            {
                _.includes(tiers.type, ['M', 'D']) && <div className={'row margin-top'}>
                    <div className={'col'}>
                        <ChequesCadeauxContainer id={tiers.belair_id}/>
                    </div>
                </div>
            }

            <div className={'row margin-top'}>
                <div className={'col'}>
                    <StatsContainer id={tiers.belair_id} className={'loader'}/>
                </div>
            </div>
            {_.includes(tiers.type, '7') &&
                <div className={'row margin-top'}>
                    <div className={'col-6'}>
                        <ApporteursContainer id={tiers.belair_id} className={'loader'}/>
                    </div>
                </div>
            }
            <div className="row margin-top">
                <div className="col">
                    <ReclamationsContainer className={'loader'} id={tiers.belair_id}/>
                </div>
            </div>
            <div className={'row margin-top'}>
                <div className={'col'}>
                    <AccidentsContainer className={'loader'}/>
                </div>
            </div>
        </Fragment>
    )
}

export default Courtier
