import {linksMiddleware} from './links/links.middleware'
import {userMiddleware} from './user/user.middleware'
import {loginMiddleware} from './login/login.middleware'
import {tasksMiddleware} from './tasks/tasks.middleware'
import {faqMiddleware} from './faq/faq.middleware'
import {contractMiddleware} from './contracts/contract.middleware'
import {documentsMiddleware} from './documents/documents.middleware'
import {documentsUtilesMiddleware} from './documents_utiles/documents_utiles.middleware'
import {taskDetailMiddleware} from './task/task.middleware'
import {tiersMiddleware} from './tiers/tiers.middleware'
import {accidentsMiddleware} from './accidents/accidents.middleware'
import {activityMiddleware} from './activity/activity.middleware'
import {contractsMiddleware} from './contracts/contracts.middleware'
import {validateDocumentMiddleware} from './validate_documents/validate_documents.middleware'
import {quittancesMiddleware} from './quittances/quittances.middleware'
import {accountMiddleware} from './account/account.middleware'
import {paymentMiddleware} from './payment/payment.middleware'
import {validateRibMiddleware} from './validate_rib/validate_rib.middleware'
import {changePasswordMiddleware} from './password/password.middleware'
import {accidentMiddleware} from './accidents/accident.middleware'
import {changePersonalInfosMiddleware} from './personal_infos/personal_infos.middleware'
import {activitiesMiddleware} from './grc/grc.middleware'
import {changeSituationContractMiddleware} from './change_situation_contract/change_situation_contract.middleware'
import {notesMiddleware} from './notes/notes.middleware'
import {quittanceMiddleware} from './quittances/quittance.middleware'
import {piecesMiddleware} from './pieces/pieces.middleware'
import {searchMiddleware} from './search/search.middleware'
import {documentationMiddleware} from './documentation/documentation.middleware'
import {virementsMiddleware} from './virements/virements.middleware'
import {validateVirementMiddleware} from './validate_virement/validate_virement.middleware'
import {emailMiddleware} from './email/email.middleware'
import {hamonMiddleware} from './hamon/hamon.middleware'
import {statistiquesMiddleware} from './statistiques/statistiques.middleware'
import {validateAddressMiddleware} from './change_address/change_address.middleware'
import {insurersMiddleware} from './insurers/insurers.middleware'
import {correspondantsMiddleware} from './correspondants/correspondants.middleware'
import {commerciauxMiddleware} from './commerciaux/commerciaux.middleware'
import {apporteursMiddleware} from './apporteurs/apporteurs.middleware'
import {statsMiddleware} from './stats_courtier/stats_courtier.middleware'
import {dashboardMiddleware} from './dashboard/dashboard.middleware'
import {ficheMiddleware} from "./fiche/fiche.middleware";
import {reclamationMiddleware} from "./reclamation/reclamation.middleware";
import {chequesCadeauxMiddleware} from "./cheques_cadeaux/cheques_cadeaux.middleware";
import {dateSejourMiddleware} from "./date_sejour/date_sejour.middleware";
import {avenantSimpleMiddleware} from "./avenant_simple/avenant_simple.middleware";
import {faqCategoriesMiddleware} from "./faq_categories/faq_categories.middleware";
import {companysMiddleware} from "./companys/companys.middleware";
import {requetesMiddleware} from "./requetes/requetes.middleware";
import {flotteMiddleware} from "./flotte/flotte.middleware";
import {commissionsMiddleware} from "./commissions/commissions.middleware";
import {correspondantMiddleware} from "./correspondants/correspondant.middleware";
import {reglementDetailMiddleware} from "./reglement_detail/reglement_detail.middleware";
import {commandeProduitsMiddleware} from "./commande_produits/commande_produits";
import {signaturesMiddleware} from "./signatures/signatures.middleware";
import {updatePremiumMiddleware} from "./status_premium/status_premium.middleware";
import {navigationActivitiesMiddleware} from "./navigation_activities/navigation_activities.middleware";
import {walletMiddleware} from "./wallet/wallet.middleware";
import {navigationContractMiddleware} from "./navigation_contract/navigation_contract.middleware";
import {rapportBcaMiddleware} from "./bca/bca.middleware";
import {casIdaMiddleware} from "./cas_ida/cas_ida.middleware";
import {addAccessoiresMiddleware} from "./accessoires/accessoires.middleware";
import {garageMiddleware} from "./garage/garage.middleware";
import {feedbackMiddleware} from "./feedback/feedback.middleware";
import {quotesMiddleware} from "./quotes/quotes.middleware";
import {expertReportMiddleware} from "./expert_report/expert_report.middleware";
import {parrainagesMiddleware} from "./parrainages/parrainages.middleware";
import {versionningMiddleware} from "./versionning/versionning.middleware";
import {dashboardTeleventeMiddleware} from "./dashboard_televente/dashboard_televente.middleware";
import {changeFractionnementMiddleware} from "./change_fractionnement/change_fractionnement.middleware";
import {tiersPayeurMiddleware} from "./tiers_payeur/tiers_payeur.middleware";
import {derogationsMiddleware} from './derogations/derogations.middleware'
import {dashboardConseillerTeleventeMiddleware} from "./dashboard_conseiller_televente/dashboard_conseiller_televente.middleware";
import {settingsMiddleware} from "./settings/settings.middleware";
import {courtiersMiddleware} from "./courtiers/courtiers.middleware";
import {antecedentsMiddleware} from "./antecedents/antecedents.middleware";
import {hivernageMiddleware} from "./hivernage/hivernage.middleware";
import {giftCardsMiddleware} from "./gift_cards/gift_cards.middleware";
import {fidelisationsMiddleware} from "./fidelisations/fidelisations.middleware";

export const appMiddleware = [
    linksMiddleware,
    userMiddleware,
    loginMiddleware,
    tasksMiddleware,
    faqMiddleware,
    faqCategoriesMiddleware,
    contractMiddleware,
    documentsMiddleware,
    documentsUtilesMiddleware,
    taskDetailMiddleware,
    tiersMiddleware,
    accidentsMiddleware,
    activityMiddleware,
    contractsMiddleware,
    validateDocumentMiddleware,
    quittancesMiddleware,
    accountMiddleware,
    paymentMiddleware,
    validateRibMiddleware,
    changePasswordMiddleware,
    accidentMiddleware,
    changePersonalInfosMiddleware,
    activitiesMiddleware,
    changeSituationContractMiddleware,
    notesMiddleware,
    quittanceMiddleware,
    piecesMiddleware,
    searchMiddleware,
    documentationMiddleware,
    virementsMiddleware,
    validateVirementMiddleware,
    emailMiddleware,
    hamonMiddleware,
    statistiquesMiddleware,
    validateAddressMiddleware,
    insurersMiddleware,
    correspondantMiddleware,
    correspondantsMiddleware,
    commerciauxMiddleware,
    apporteursMiddleware,
    statsMiddleware,
    dashboardMiddleware,
    dashboardTeleventeMiddleware,
    dashboardConseillerTeleventeMiddleware,
    ficheMiddleware,
    reclamationMiddleware,
    chequesCadeauxMiddleware,
    dateSejourMiddleware,
    avenantSimpleMiddleware,
    companysMiddleware,
    requetesMiddleware,
    flotteMiddleware,
    commissionsMiddleware,
    reglementDetailMiddleware,
    commandeProduitsMiddleware,
    signaturesMiddleware,
    updatePremiumMiddleware,
    navigationActivitiesMiddleware,
    walletMiddleware,
    navigationContractMiddleware,
    rapportBcaMiddleware,
    casIdaMiddleware,
    garageMiddleware,
    feedbackMiddleware,
    addAccessoiresMiddleware,
    quotesMiddleware,
    expertReportMiddleware,
    parrainagesMiddleware,
    versionningMiddleware,
    changeFractionnementMiddleware,
    tiersPayeurMiddleware,
    derogationsMiddleware,
    settingsMiddleware,
    courtiersMiddleware,
    antecedentsMiddleware,
    hivernageMiddleware,
    giftCardsMiddleware,
    fidelisationsMiddleware
]
