import _ from "lodash";

/**
 * Normalize input IBAN FR11-2222-3333-4444-5555-6666-777
 * @param value
 * @returns {string}
 */
export const normalizeIban = value => {
    if (!value) {
        return value
    }

    const separateur = '-'

    const onlyNums = value.replace(/[!@#$%^&*\-(),.?":{}|<>]/g, '').toUpperCase()

    if (onlyNums.length <= 4) {
        return onlyNums
    }
    if (onlyNums.length <= 8) {
        return `${onlyNums.slice(0, 4)}${separateur}${onlyNums.slice(4, 8)}`
    }

    if (onlyNums.length <= 12) {
        return `${onlyNums.slice(0, 4)}${separateur}${onlyNums.slice(4, 8)}${separateur}${onlyNums.slice(8, 12)}`
    }

    if (onlyNums.length <= 16) {
        return `${onlyNums.slice(0, 4)}${separateur}${onlyNums.slice(4, 8)}${separateur}${onlyNums.slice(8, 12)}${separateur}${onlyNums.slice(12, 16)}`
    }

    if (onlyNums.length <= 20) {
        return `${onlyNums.slice(0, 4)}${separateur}${onlyNums.slice(4, 8)}${separateur}${onlyNums.slice(8, 12)}${separateur}${onlyNums.slice(12, 16)}${separateur}${onlyNums.slice(16, 20)}`
    }

    if (onlyNums.length <= 24) {
        return `${onlyNums.slice(0, 4)}${separateur}${onlyNums.slice(4, 8)}${separateur}${onlyNums.slice(8, 12)}${separateur}${onlyNums.slice(12, 16)}${separateur}${onlyNums.slice(16, 20)}${separateur}${onlyNums.slice(20, 24)}`
    }

    return `${onlyNums.slice(0, 4)}${separateur}${onlyNums.slice(4, 8)}${separateur}${onlyNums.slice(8, 12)}${separateur}${onlyNums.slice(12, 16)}${separateur}${onlyNums.slice(16, 20)}${separateur}${onlyNums.slice(20, 24)}${separateur}${onlyNums.slice(24, 27)}`
}

export const capitalizeInput = value => {
    return value.toUpperCase();
};

/**
 * Normalize input Date DD/MM/YYYY
 * @param value
 * @returns {string}
 */
export const normalizeDate = value => {
    if (!value) {
        return value
    }

    const separateur = '/'

    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length <= 2) {
        return onlyNums
    }
    if (onlyNums.length <= 4) {
        return `${onlyNums.slice(0, 2)}${separateur}${onlyNums.slice(2)}`
    }
    return `${onlyNums.slice(0, 2)}${separateur}${onlyNums.slice(2, 4)}${separateur}${onlyNums.slice(
        4,
        8
    )}`
}
/**
 * Normalize input Date HH:mm
 * @param value
 * @returns {string}
 */
export const normalizeHour = value => {
    if (!value) {
        return value
    }

    const separateur = ':'

    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length <= 2) {
        return onlyNums
    }
    return `${onlyNums.slice(0, 2)}${separateur}${onlyNums.slice(2, 4)}`
}


/**
 * Normalize Boolean
 * @param value
 * @returns {boolean}
 */
export const normalizeBooleanForNoobBelair = value => {

    if (value) {
        return "T";
    } else {
        return "F";
    }

};

/**
 * Normalize Boolean
 * @param value
 * @returns {boolean}
 */
export const normalizeInteger = value => {
    return _.round(value);
};

/**
 * normalizeNumber
 * @param value
 * @returns {boolean}
 */
export const normalizeNumber = value => {
    return (value === '' ? null : (_.round(_.replace(value, ',', '.')*100))/100);
};


export const removeAccents = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const normalizeAndUppercase = (value) => {
    return removeAccents(value).toUpperCase();
};


export const normalizeOnlyNumber = (value) => {
    return value.replace(/[^0-9]/g, '');
};
