import {QUITTANCES, GET_QUITTANCES, setQuittances} from '../../../actions/app/quittances/quittances.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'

export const quittancesMiddleware = () => next => action => {
    next(action)

    switch (action.type) {

        case GET_QUITTANCES:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: QUITTANCES}),
                setLoader({state: true, entity: QUITTANCES})
            ])
            break

        case `${QUITTANCES} ${API_SUCCESS}`:
            const {data} = action.payload.data

            next([
                setQuittances({data}),
                setError({state: false, entity: QUITTANCES}),
                setLoader({state: false, entity: QUITTANCES})
            ])
            break

        case `${QUITTANCES} ${API_ERROR}`:
            next([
                setError({state: true, entity: QUITTANCES}),
                setLoader({state: false, entity: QUITTANCES})
            ])
            break

        default:
            break
    }
    return null
}
