import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {
    DASHBOARD_TASK_TLV,
    DASHBOARD_TLV, DASHBOARD_TLV_GEST, GET_DASHBOARD_TASK_TLV,
    GET_DASHBOARD_TLV, GET_DASHBOARD_TLV_GEST,
    setDashboardTelevente, setDashboardTeleventeTaskDetails, setGestionnairesDashboardTelevente
} from "../../../actions/app/dashboard_televente/dashboard_televente.actions";
import _ from "lodash";

export const dashboardTeleventeMiddleware = () => next => action => {
    next(action)

    switch (action.type) {
        case GET_DASHBOARD_TLV:
            let url = _.includes(action.payload.data, '?') ? action.payload.data : `${action.payload.data}?`

            if (action.payload.filters) {
                for (let [key, value] of Object.entries(action.payload.filters)) {
                    url = `${url}&${key}=${value}`
                }
            }

            next([
                apiRequest({body: null, method: 'GET', url, entity: DASHBOARD_TLV}),
                setLoader({state: true, entity: DASHBOARD_TLV})
            ])
            break

        case `${DASHBOARD_TLV} ${API_SUCCESS}`:
            let {data, meta, links} = action.payload.data

            next([
                setDashboardTelevente({data, meta, links}),
                setError({state: false, entity: DASHBOARD_TLV}),
                setLoader({state: false, entity: DASHBOARD_TLV}),
            ])
            break

        case `${DASHBOARD_TLV} ${API_ERROR}`:
            next([
                setError({state: true, entity: DASHBOARD_TLV}),
                setLoader({state: false, entity: DASHBOARD_TLV})
            ])
            break

        case GET_DASHBOARD_TLV_GEST:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: DASHBOARD_TLV_GEST}),
                setLoader({state: true, entity: DASHBOARD_TLV_GEST})
            ])
            break

        case `${DASHBOARD_TLV_GEST} ${API_SUCCESS}`:
            next([
                setGestionnairesDashboardTelevente({gestionnaires: action.payload.data}),
                setError({state: false, entity: DASHBOARD_TLV_GEST}),
                setLoader({state: false, entity: DASHBOARD_TLV_GEST}),
            ])
            break

        case `${DASHBOARD_TLV_GEST} ${API_ERROR}`:
            next([
                setError({state: true, entity: DASHBOARD_TLV_GEST}),
                setLoader({state: false, entity: DASHBOARD_TLV_GEST})
            ])
            break

        case GET_DASHBOARD_TASK_TLV:
            next([
                apiRequest({
                    body: null,
                    method: 'GET',
                    url: action.payload.data,
                    entity: DASHBOARD_TASK_TLV,
                    otherData: action.payload.otherData
                }),
                setLoader({state: true, entity: DASHBOARD_TASK_TLV}),
            ])
            break

        case `${DASHBOARD_TASK_TLV} ${API_SUCCESS}`:
            next([
                setDashboardTeleventeTaskDetails({
                    task: action.payload.data,
                    task_id: action.payload.meta.otherData.task_id
                }),
                setLoader({state: false, entity: DASHBOARD_TASK_TLV}),
                setError({state: false, entity: DASHBOARD_TASK_TLV})
            ])
            break

        case `${DASHBOARD_TASK_TLV} ${API_ERROR}`:
            next([
                setError({state: true, entity: DASHBOARD_TASK_TLV}),
                setLoader({state: false, entity: DASHBOARD_TASK_TLV})
            ])
            break

        default:
            break
    }
    return null
}
