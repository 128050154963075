export const UPDATE_PREMIUM = '[Update Premium]'
export const POST_UPDATE_PREMIUM = `${UPDATE_PREMIUM} Post`
export const SET_UPDATE_PREMIUM = `${UPDATE_PREMIUM} Set`

export const postUpdatePremium = ({form, query}) => ({
    type: POST_UPDATE_PREMIUM,
    payload: {
        data: query,
        body: form,
    }
})

export const setUpdatePremium = ({state}) => ({
    type: SET_UPDATE_PREMIUM,
    payload: state
})
