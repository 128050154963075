import {SET_FEEDBACK} from "../../actions/app/feedback/feedback.actions";

export const feedbackReducer = (feedback = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_FEEDBACK) {
        return payload.data
    } else {
        return feedback
    }
}
