import React, {Component, Fragment} from 'react'
import {Link} from "react-router-dom";
import _ from "lodash";
import CallToAction from "../../../../Commun/Button/CallToAction/CallToAction";

class Reparateur extends Component {
    constructor(props) {
        super(props)
        this.state = {
            disabledDelete: false
        }
    }

    handleClick = (id) => {
        this.props.postDelete()
        this.setState({
            disabledDelete: true
        })
    }

    render() {
        const {reparateur, contract, accident, permissions} = this.props

        return (
            <div className={'bloc-info bloc-reparateur'}>
                <h2>Réparateur</h2>

                <div>
                    {_.includes(permissions, 'update accident informations') && <>
                        <CallToAction
                            url={{
                                pathname: `/contrat/${contract}/sinistre/${accident}/modification-reparateur`,
                                state: {
                                    reparateur: reparateur,
                                    contract: contract,
                                    accident: accident
                                }
                            }}
                            action={'edit-repairer'}
                            toolText={`${(reparateur && reparateur.raisonSociale) ? 'Modifier' : 'Ajouter'} le réparateur`}
                        />
                        {reparateur.raisonSociale &&
                            <CallToAction
                                action={'delete-note'}
                                toolText={'Supprimer le réparateur'}
                                onClick={() => {
                                    this.handleClick()
                                }}
                                url={'#'}
                                disabled={this.state.disabledDelete}
                            />
                        }
                    </>}
                    <div className={'content row'}>
                        {(reparateur && reparateur.raisonSociale) ?
                            <Fragment>
                                <div className={'col-5 left-content'}>
                                    <p>Réparateur agréé :</p>
                                </div>
                                <div className={'col-7 right-content'}>
                                    <p><Link to={`/tiers/${reparateur.id}`}
                                             target="_blank">{reparateur.raisonSociale}</Link></p>
                                </div>
                                <div className={'col-5 left-content'}>
                                    <p>Adresse postale :</p>
                                </div>
                                <div className={'col-7 right-content'}>
                                    <p>{reparateur.rue1} {reparateur.rue2 !== '' ? `- ${reparateur.rue2}` : ''}</p>
                                </div>
                                <div className={'col-5 left-content'}>
                                    <p>Code postal - Ville :</p>
                                </div>
                                <div className={'col-7 right-content'}>
                                    <p>{reparateur.codePostal} {reparateur.ville}</p>
                                </div>
                                <div className={'col-5 left-content'}>
                                    <p>Téléphone :</p>
                                </div>
                                <div className={'col-7 right-content'}>
                                    <p>{reparateur.phone || '-'}</p>
                                </div>
                                <div className={'col-5 left-content'}>
                                    <p>Email :</p>
                                </div>
                                <div className={'col-7 right-content'}>
                                    <p>{reparateur.email || '-'}</p>
                                </div>
                            </Fragment>
                            : <div className={'col-12'}>
                                <p>Aucun réparateur n'a été missionné pour votre sinistre</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Reparateur
