import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {GARAGE, GET_GARAGE, setGarage} from "../../../actions/app/garage/garage.actions";

export const garageMiddleware = () => next => action => {
    next(action)

    switch (action.type) {
        case GET_GARAGE:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: GARAGE}),
                setLoader({state: true, entity: GARAGE})
            ])
            break

        case `${GARAGE} ${API_SUCCESS}`:
            next([
                setGarage({garage: action.payload.data}),
                setError({state: false, entity: GARAGE}),
                setLoader({state: false, entity: GARAGE})
            ])
            break

        case `${GARAGE} ${API_ERROR}`:
            next([
                setError({state: true, entity: GARAGE}),
                setLoader({state: false, entity: GARAGE})
            ])
            break

        default:
            break
    }
    return null
}
