import {SET_QUITTANCE} from '../../actions/app/quittances/quittance.actions'

export const quittanceReducer = (quittance = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_QUITTANCE) {
        return payload
    } else {
        return quittance
    }
}
