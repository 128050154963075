import React, {Fragment, useEffect} from 'react';
import {Field} from "redux-form";
import RadioFieldRedux from "../../../../../../../Commun/Input/Radio/RadioFieldRedux";
import UploadContainer from "../../../../../../../Commun/Input/Upload/UploadContainer";

const Constat = (props) => {
    const {constatCheck} = props

    useEffect(() => {
        document.getElementById('declaration-ter').scrollIntoView({behavior: 'smooth'})
    }, [])

    return (
        <div className={'form-content h-100'}>
            <h1 className={'title-primary'}>Il y a-t-il un constat amiable?</h1>

            <Field
                name='constat_accident'
                component={RadioFieldRedux}
                label={'Il y a-t-il un constat amiable ?'}
                data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                required
            />
            {constatCheck === 'T' && <Fragment>
                <Field
                    name='constat_sign_tiers'
                    component={RadioFieldRedux}
                    label={'Est-il signé par le Tiers adverse ?'}
                    data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                />
                <Field
                    name='constat_sign_assure'
                    component={RadioFieldRedux}
                    label={'Est-il signé par notre assuré ?'}
                    data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                />

                <Field
                    name='upload'
                    component={UploadContainer}
                    formName='constat'
                    title={'Constat amiable signé ou déclaration circonstanciée (Recto)'}
                    code={`files[C]`}
                />
                <Field
                    name='upload'
                    component={UploadContainer}
                    formName='constat'
                    title={'Constat amiable signé ou déclaration circonstanciée (Verso)'}
                    code={`files[CC]`}
                />
                <div className="alert alert-info" role="alert" style={{margin: '20px 0 0 0'}}>
                    Indiquer au client ou au courtier qu'il peut nous envoyer son constat sur la boîte mail
                    constat@april-moto.com . Il peut également nous l'envoyer par MMS sur cette même adresse email.
                </div>
            </Fragment>}
        </div>
    );
};

export default Constat;
