import {SET_QUOTES} from "../../actions/app/quotes/quotes.actions";

export const quotesReducer = (quotes = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_QUOTES) {
        return payload.data
    } else {
        return quotes
    }
}
