import {connect} from 'react-redux'
import ActivityDetail from './ActivityDetail'
import {getUserPermissions} from "../../../../redux/selectors/user/user.selectors";
import {compose} from "redux";
import {postDeleteTaskDocument} from "../../../../redux/actions/app/documents/documents.actions";
import {ActivityFilesGetLink, postDocumentTaskDeleteGetLink} from "../../../../redux/selectors/links/links.selectors";
import _ from "lodash";
import {getActivityFiles} from "../../../../redux/actions/app/activity/activity.actions";

import ErrorHttpComponent from "../../Error/ErrorHttpComponent";
import {getActivityFilesData} from "../../../../redux/selectors/activity/activity.selectors";
import loader from "../../Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {

    return {
        files: getActivityFilesData(state),
        activity_id: ownProps.match.params.activity_id,
        tiers_id: ownProps.match.params.tiers_id,
        permissions: getUserPermissions(state),
        uri_delete: postDocumentTaskDeleteGetLink(state),
        uri_files_get: ActivityFilesGetLink(state),
    }
}

const mapDispatchToProps = {
    postDeleteTaskDocument,
    getActivityFiles: (query) => getActivityFiles({query})
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {postDeleteTaskDocument, getActivityFiles} = dispatchProps
    const {activity_id, tiers_id, uri_delete, uri_files_get} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        postDeleteTaskDocument: (id) => postDeleteTaskDocument({query: _.replace(_.replace(_.replace(uri_delete, '{id}', activity_id), '{tiers_id}', tiers_id), '{file}', id)}),
        load: () => getActivityFiles(_.replace(_.replace(uri_files_get, '{id}', activity_id), '{tiers_id}', tiers_id))
    }
}

const ActivityDetailContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(ActivityDetail)

export default ActivityDetailContainer



