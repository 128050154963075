import {
    GET_DASHBOARD,
    setDashboard,
    DASHBOARD,
    GET_DASHBOARD_TASK_DETAIL,
    DASHBOARD_TASK_DETAIL,
    DASHBOARD_PANIERE_DETAIL,
    GET_DASHBOARD_PANIERE_DETAIL,
    setManagerTaskDetail,
    setManagerPaniereDetail, GET_DASHBOARD_MORE_TASK_DETAIL, DASHBOARD_MORE_TASK_DETAIL, setManagerMoreTaskDetail
} from '../../../actions/app/dashboard/dashboard.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'

export const dashboardMiddleware = () => next => action => {
    next(action)

    switch (action.type) {
        case GET_DASHBOARD:
            const {filters} = action.payload

            let filter = '';
            filter += `sort=${filters["sort"]}`
            filter += `&service=${filters["service"]}`
            filter += `&service_user=${filters["service_user"]}`

            let url = `${action.payload.data}?${filter}`

            next([
                apiRequest({body: null, method: 'GET', url, entity: DASHBOARD}),
                setLoader({state: true, entity: DASHBOARD})
            ])
            break

        case `${DASHBOARD} ${API_SUCCESS}`:
            next([
                setDashboard({dashboard: action.payload.data}),
                setError({state: false, entity: DASHBOARD}),
                setLoader({state: false, entity: DASHBOARD}),
            ])
            break

        case `${DASHBOARD} ${API_ERROR}`:
            next([
                setError({state: true, entity: DASHBOARD}),
                setLoader({state: false, entity: DASHBOARD})
            ])
            break

        case GET_DASHBOARD_TASK_DETAIL:
            next([
                apiRequest({
                    body: null,
                    method: 'GET',
                    url: action.payload.data,
                    entity: DASHBOARD_TASK_DETAIL,
                    otherData: action.payload.otherData
                }),
                setLoader({state: true, entity: DASHBOARD_TASK_DETAIL}),
            ])
            break

        case `${DASHBOARD_TASK_DETAIL} ${API_SUCCESS}`:
            next([
                setManagerTaskDetail({dashboard: action.payload.data, pro_id: action.payload.meta.otherData.pro_id}),
                setLoader({state: false, entity: DASHBOARD_TASK_DETAIL}),
                setError({state: false, entity: DASHBOARD_TASK_DETAIL})
            ])
            break

        case `${DASHBOARD_TASK_DETAIL} ${API_ERROR}`:
            next([
                setError({state: true, entity: DASHBOARD_TASK_DETAIL}),
                setLoader({state: false, entity: DASHBOARD_TASK_DETAIL})
            ])
            break

        case GET_DASHBOARD_PANIERE_DETAIL:
            next([
                apiRequest({
                    body: null,
                    method: 'GET',
                    url: action.payload.data,
                    entity: DASHBOARD_PANIERE_DETAIL,
                    otherData: action.payload.otherData
                }),
                setLoader({state: true, entity: DASHBOARD_PANIERE_DETAIL}),
            ])
            break

        case `${DASHBOARD_PANIERE_DETAIL} ${API_SUCCESS}`:
            next([
                setManagerPaniereDetail({
                    dashboard: action.payload.data,
                    emetteur: action.payload.meta.otherData.emetteur
                }),
                setLoader({state: false, entity: DASHBOARD_PANIERE_DETAIL}),
                setError({state: false, entity: DASHBOARD_PANIERE_DETAIL})
            ])
            break

        case `${DASHBOARD_PANIERE_DETAIL} ${API_ERROR}`:
            next([
                setError({state: true, entity: DASHBOARD_PANIERE_DETAIL}),
                setLoader({state: false, entity: DASHBOARD_PANIERE_DETAIL})
            ])
            break

        case GET_DASHBOARD_MORE_TASK_DETAIL:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: DASHBOARD_MORE_TASK_DETAIL}),
                setLoader({state: true, entity: DASHBOARD_MORE_TASK_DETAIL}),
            ])
            break

        case `${DASHBOARD_MORE_TASK_DETAIL} ${API_SUCCESS}`:
            next([
                setManagerMoreTaskDetail({dashboard: action.payload.data}),
                setLoader({state: false, entity: DASHBOARD_MORE_TASK_DETAIL}),
                setError({state: false, entity: DASHBOARD_MORE_TASK_DETAIL})
            ])
            break

        case `${DASHBOARD_MORE_TASK_DETAIL} ${API_ERROR}`:
            next([
                setError({state: true, entity: DASHBOARD_MORE_TASK_DETAIL}),
                setLoader({state: false, entity: DASHBOARD_MORE_TASK_DETAIL})
            ])
            break

        default:
            break
    }
    return null
}
