export const CHEQUES_CADEAUX = '[Chèques Cadeaux]'

export const POST_CHEQUES_CADEAUX = `${CHEQUES_CADEAUX} Post`

export const postChequesCadeaux = ({query, body}) => ({
    type: POST_CHEQUES_CADEAUX,
    payload: {
        data: query,
        body
    }
})
