import React from 'react'
import moment from "moment";

const ReponseDossier = (props) => {
    const {task: {date, type, paniere, tiers_id}} = props

    return (
        <div className={'container'}>
            <div className={'row'}>
                <div className={'col text-center'}>
                    <h2 className={'title-primary text-uppercase'}>{paniere} - Tiers n°{tiers_id}</h2>
                    <p className={'subtitle'}>Tâche du {moment(date).format('L')}. Recu par {type}</p>
                </div>
            </div>

            <div className={'header-row row'}>
                <div className={'col-12'}>
                    <p className={'form-content text-center'}>
                        Vérifier pourquoi le dossier est inactif depuis 80 jours et faire une action sur le sinistre si
                        besoin.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ReponseDossier
