export const STATISTIQUES = '[Statistiques]'

export const GET_STATISTIQUES = `${STATISTIQUES} Get`
export const SET_STATISTIQUES = `${STATISTIQUES} Set`

export const getStatistiques = ({query}) => ({
    type: GET_STATISTIQUES,
    payload: {
        data: query
    }
})

export const setStatistiques = ({data}) => ({
    type: SET_STATISTIQUES,
    payload: {data}
})
