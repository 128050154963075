import {createSelector} from 'reselect'

const flotteSelector = state => state.flotte
const flotteDetailSelector = state => state.flotte_detail

export const getFlotteData = createSelector(
    flotteSelector,
    flotte => flotte.data
)

export const getFlotteActiveVehiculesData = createSelector(
    flotteSelector,
    flotte => flotte?.data?.filter(vehicle => !vehicle.inactif)
);

export const getFlotteMeta = createSelector(
    flotteSelector,
    flotte => flotte.meta
)

export const getFlotteLinks = createSelector(
    flotteSelector,
    flotte => flotte.links
)

export const getFlotteDetailData = createSelector(
    flotteDetailSelector,
    flotte_detail => flotte_detail.data
)

export const getGarantiesFlotteDetailData = createSelector(
    getFlotteDetailData,
    data => data.garanties
)
