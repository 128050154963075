import React, {useMemo} from 'react'
import DocumentsUtilesCard from "../../../../Commun/Card/DocumentsUtilesCard";

const DocumentsUtiles = (props) => {
    const {documents} = props

    const documentsByCategory = useMemo(() => {
        return documents.reduce((acc, document) => {
            const {category} = document
            if (!acc[category.name]) {
                acc[category.name] = []
            }
            acc[category.name].push(document)
            return acc
        }, {})
    }, [documents]);

    return (
        <>
            <div className={'row'}>
                <div className={'col-12'}>
                    {documents.length === 0 ?
                        <p>Pas de documents utiles disponibles</p> :
                        Object.keys(documentsByCategory).map((category, index) => (
                            <div className={'row'} key={index}>
                                <div className={'col'}>
                                    <h3 className="title-primary">{category}</h3>
                                    {documentsByCategory[category].map((doc, index) => (
                                        <DocumentsUtilesCard document={doc} index={index} key={index}/>
                                    ))}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}

export default DocumentsUtiles
