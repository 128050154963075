import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {reduxForm} from 'redux-form'
import {refuseVirementProdGetLink} from '../../../../../redux/selectors/links/links.selectors'
import {getLoadingEntity} from '../../../../../redux/selectors/ui/ui.selectors'
import validate from './validate'
import {
    POST_REFUSE_VIREMENT_PROD,
    postRefuseVirementProd
} from "../../../../../redux/actions/app/validate_virement/validate_virement.actions";
import _ from "lodash";
import RefuseVirement from "./RefuseVirement";

const mapStateToProps = (state, ownProps) => {
    return {
        loading: getLoadingEntity(state, POST_REFUSE_VIREMENT_PROD),
        uri: refuseVirementProdGetLink(state),
        id: ownProps.match.params.id,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    submitPostRefuseVirementProd: ({popup, form, query}) => postRefuseVirementProd({popup, form, query})
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {submitPostRefuseVirementProd} = dispatchProps
    const {popup} = ownProps.location.state
    const {uri, id} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form) => submitPostRefuseVirementProd({popup: popup, form, query: _.replace(uri, '{id}', id)})
    }
}
const RefuseVirementContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'refuseVirement',
        validate
    })
)(RefuseVirement)

export default RefuseVirementContainer
