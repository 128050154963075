export const SEARCH = '[Search]'

export const POST_SEARCH = `${SEARCH} Post`
export const SET_SEARCH = `${SEARCH} Set`
export const CLEAR_SEARCH = `${SEARCH} Clear`

export const postSearch = ({query}) => ({
    type: POST_SEARCH,
    payload: {
        data: query,
    }
})

export const setSearch = ({data}) => ({
    type: SET_SEARCH,
    payload: {data}
})

export const clearSearch = () => ({
    type: CLEAR_SEARCH,
    payload: {}
})
