import {GET_APPORTEURS, setApporteurs, APPORTEURS} from '../../../actions/app/apporteurs/apporteurs.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'

export const apporteursMiddleware = () => next => action => {
    next(action)

    switch (action.type) {

        case GET_APPORTEURS:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: APPORTEURS}),
                setLoader({state: true, entity: APPORTEURS})
            ])
            break

        case `${APPORTEURS} ${API_SUCCESS}`:
            next([
                setApporteurs({apporteurs: action.payload.data}),
                setError({state: false, entity: APPORTEURS}),
                setLoader({state: false, entity: APPORTEURS})
            ])
            break

        case `${APPORTEURS} ${API_ERROR}`:
            next([
                setError({state: true, entity: APPORTEURS}),
                setLoader({state: false, entity: APPORTEURS})
            ])
            break

        default:
            break
    }
    return null
}
