import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {
    PARRAINAGES, GET_PARRAINAGES,
    setParrainages
} from "../../../actions/app/parrainages/parrainages.actions";

export const parrainagesMiddleware = () => next => action => {
    next(action)

    switch (action.type) {

        case GET_PARRAINAGES:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: PARRAINAGES}),
                setLoader({state: true, entity: PARRAINAGES})
            ])
            break

        case `${PARRAINAGES} ${API_SUCCESS}`:
            const {data} = action.payload

            next([
                setParrainages({data}),
                setError({state: false, entity: PARRAINAGES}),
                setLoader({state: false, entity: PARRAINAGES})
            ])
            break

        case `${PARRAINAGES} ${API_ERROR}`:
            next([
                setError({state: true, entity: PARRAINAGES}),
                setLoader({state: false, entity: PARRAINAGES})
            ])
            break
        default:
            break
    }
    return null
}
