import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PUBLISH_URL } from '../../../../../constants/constants';
import { postCreateAccount, POST_CREATE_ACCOUNT } from '../../../../../redux/actions/app/account/account.actions';
import { POST_CHANGE_PASSWORD } from '../../../../../redux/actions/app/password/password.actions';
import {postDeleteTiers, postDematStatut} from '../../../../../redux/actions/app/tiers/tiers.actions';
import { logAction } from '../../../../../redux/actions/core/logs/logs.actions';
import {
    getStatutDematLink,
    tiersCreateAccountLink,
    tiersDeleteAccountLink
} from '../../../../../redux/selectors/links/links.selectors';
import { getUserPermissions, getUserRoles } from '../../../../../redux/selectors/user/user.selectors';
import Buttons from './Buttons';

const mapStateToProps = (state, ownProps) => {
    return {
        permissions: getUserPermissions(state),
        tiers: ownProps.tiers,
        publishUrl: PUBLISH_URL,
        uri_account: tiersCreateAccountLink(state),
        // uriDeleteAccount: tiersDeleteAccountLink(state),
        uriDeleteAccount: _.replace(tiersDeleteAccountLink(state), '{id}', ownProps.tiers.belair_id),
        owner_belair_id: 43397,
        roles: getUserRoles(state),
        uri_demat: getStatutDematLink(state),
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            postCreateAccount: ({ email, owner_belair_id, nom, date_naissance, belair_id, query }) =>
                postCreateAccount({
                    email,
                    owner_belair_id,
                    nom,
                    date_naissance,
                    belair_id,
                    query,
                }),
            postDeleteAccount: ({ query }) => postDeleteTiers({ query }),
            logAction: ({ entity, type, tiers }) => logAction({ entity, type, tiers }),
            postDematStatut,
        },
        dispatch,
    );

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { postCreateAccount, logAction, postDeleteAccount } = dispatchProps;
    const { uri_account, uriDeleteAccount } = stateProps;

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        postAccount: ({ email, owner_belair_id, nom, date_naissance, belair_id }) =>
            postCreateAccount({
                email,
                owner_belair_id,
                nom,
                date_naissance,
                belair_id,
                query: uri_account,
            }),
        postLogActionCreateAccount: () =>
            logAction({
                entity: POST_CREATE_ACCOUNT,
                type: 'CLICK_CREATE_ACCOUNT',
                tiers: ownProps.tiers.belair_id,
            }),
        postLogActionChangePassword: () =>
            logAction({
                entity: POST_CHANGE_PASSWORD,
                type: 'CLICK_CHANGE_PASSWORD',
                tiers: ownProps.tiers.belair_id,
            }),
        postLogActionEditDocument: () =>
            logAction({
                entity: null,
                type: 'CLICK_EDIT_DOCUMENT',
                tiers: ownProps.tiers.belair_id,
            }),
        postLogActionFormRib: () =>
            logAction({ entity: null, type: 'CLICK_FORM_RIB', tiers: ownProps.tiers.belair_id }),
        postDeleteAccount: () => postDeleteAccount({ query: uriDeleteAccount }),
    };
};

const ButtonsContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Buttons);

export default ButtonsContainer;
