import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {
    POST_VALIDATE_VIREMENT,
    VALIDATE_VIREMENT,
    setValidateVirement,
    POST_REFUSE_VIREMENT_PROD,
    REFUSE_VIREMENT_PROD, setRefuseVirementProd
} from "../../../actions/app/validate_virement/validate_virement.actions";

export const validateVirementMiddleware = () => next => action => {
    next(action)

    const {payload} = action
    let message = null
    let type = 'success'

    switch (action.type) {
        case POST_VALIDATE_VIREMENT:
            next([
                apiRequest({
                    body: action.payload.body,
                    method: 'POST',
                    url: action.payload.data,
                    entity: VALIDATE_VIREMENT,
                    otherData: payload.otherData
                }),
                setLoader({state: true, entity: VALIDATE_VIREMENT})
            ])
            break

        case `${VALIDATE_VIREMENT} ${API_SUCCESS}`:
            if (payload.meta.otherData.valide) {
                message = `<p>Le virement a été validé</p>`
            } else {
                type = 'warning'
                message = `<p>Le virement a été refusé</p>`
            }
            next([
                setValidateVirement({state: true}),
                setNotification({entity: VALIDATE_VIREMENT, type: type, body: message}),
                setLoader({state: false, entity: VALIDATE_VIREMENT}),
                setError({state: false, entity: VALIDATE_VIREMENT}),
            ])
            break

        case `${VALIDATE_VIREMENT} ${API_ERROR}`:
            message = `
                <p>Votre demande n'a pas pu être envoyée. ${payload.data.response.data?.message ?? ''}. Le virement est repassé en validation par un manager.</p>
            `
            next([
                setNotification({
                    entity: VALIDATE_VIREMENT,
                    body: message,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: VALIDATE_VIREMENT}),
                setLoader({state: false, entity: VALIDATE_VIREMENT}),
            ])
            break

        case POST_REFUSE_VIREMENT_PROD:
            let data = new FormData()
            data.set('with_popup', action.payload.body.popup)
            if (action.payload.body.commentaire !== '') {
                data.set('commentaire', action.payload.body.commentaire)
            }

            next([
                apiRequest({
                    body: data,
                    method: 'POST',
                    url: action.payload.data,
                    entity: REFUSE_VIREMENT_PROD,
                    otherData: payload.otherData
                }),
                setLoader({state: true, entity: REFUSE_VIREMENT_PROD})
            ])
            break

        case `${REFUSE_VIREMENT_PROD} ${API_SUCCESS}`:
            next([
                setRefuseVirementProd({state: true}),
                setNotification({
                    entity: REFUSE_VIREMENT_PROD,
                    body: '',
                    type: 'success',
                    title: 'Le virement a bien été refusé'
                }),
                setLoader({state: false, entity: REFUSE_VIREMENT_PROD}),
                setError({state: false, entity: REFUSE_VIREMENT_PROD}),
            ])
            break

        case `${REFUSE_VIREMENT_PROD} ${API_ERROR}`:
            message = `
                <p>Votre demande n'a pas pu être envoyée. ${payload.data.response.data?.message ?? ''}</p>
            `
            next([
                setNotification({
                    entity: REFUSE_VIREMENT_PROD,
                    body: message,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: REFUSE_VIREMENT_PROD}),
                setLoader({state: false, entity: REFUSE_VIREMENT_PROD}),
            ])
            break

        default:
            break
    }
    return null
}
