import {connect} from 'react-redux'
import {compose} from 'redux'
import VirementSinistreList from "./VirementSinistreList";
import {getVirementsData} from "../../../../../../redux/selectors/virements/virements.selectors";
import {getErrorEntity, getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {VIREMENTS} from "../../../../../../redux/actions/app/virements/virements.actions";

import ErrorHttpComponent from "../../../../../Commun/Error/ErrorHttpComponent";
import loader from "../../../../../Commun/Spinner/Loader/Loader";
import {getUserIdBelair} from "../../../../../../redux/selectors/user/user.selectors";

const mapStateToProps = state => {
    return {
        virements: getVirementsData(state),
        loaded: getLoadingEntity(state, VIREMENTS) === false,
        error: getErrorEntity(state, VIREMENTS),
        idBelair: getUserIdBelair(state),
    }
}

const VirementSinistreListContainer = compose(
    connect(mapStateToProps),
    loader({ErrorIndicator: ErrorHttpComponent}),
)(VirementSinistreList)

export default VirementSinistreListContainer
