import {connect} from 'react-redux'
import {compose} from "redux";
import AddRIAntecedent from "./AddRIAntecedent";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {antecedentsCreateLink} from "../../../../../redux/selectors/links/links.selectors";
import _ from "lodash";
import {change, reduxForm} from "redux-form";
import validate from "./validate";
import loader from "../../../../Commun/Spinner/Loader/Loader";
import {
    POST_CREATE_ANTECEDENT,
    postCreateAntecedent
} from "../../../../../redux/actions/app/antecedents/antecedents.actions";

const mapStateToProps = (state, ownProps) => {
    return {
        query: antecedentsCreateLink(state),
        loading: getLoadingEntity(state, POST_CREATE_ANTECEDENT) === true,
        idContract: ownProps.match.params.id,
        type: ownProps.match.params.type,
        initialValues: {
            type: ownProps.match.params.type === 'moto' ? 'MO' : 'AU'
        },
    }
}

const mapDispatchToProps = {
    postCreateAntecedent,
    changeValue: (field, value) => change('add_antecedents_ri', field, value)
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query, idContract } = stateProps
    const { postCreateAntecedent } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: form => postCreateAntecedent({
            query: _.replace(query, '{id}', idContract),
            form
        })
    }
}

const AddRIAntecedentContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader(),
    reduxForm({
        form: 'add_antecedents_ri',
        validate
    }),
)(AddRIAntecedent)

export default AddRIAntecedentContainer