const showStep = ({step, categorie, vehicule, identify, temoin, blesse, typeSinistre}) => {
    switch (step) {
        case 'reparation':
            return (vehicule === 'T' && categorie !== '10.0')

        case 'adversaires':
            return (categorie === '103.1' || categorie === '103.2' || identify === 'T')
        case 'blesses':
            return (blesse === 'T')

        case 'temoin':
            return (temoin === 'T')

        case 'declarationBis':
            return (categorie !== "104.5" && categorie !== "19.0")

        default:
            return false
    }
}

export default showStep
