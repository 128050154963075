import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {
    GET_NAVIGATION_CONTRACT,
    NAVIGATION_CONTRACT, setNavigationContract
} from "../../../actions/app/navigation_contract/navigation_contract.actions";

export const navigationContractMiddleware = () => next => action => {
    next(action)

    switch (action.type) {
        case GET_NAVIGATION_CONTRACT:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: NAVIGATION_CONTRACT}),
                setLoader({state: true, entity: NAVIGATION_CONTRACT})
            ])
            break

        case `${NAVIGATION_CONTRACT} ${API_SUCCESS}`:
            const {data} = action.payload.data

            next([
                setNavigationContract({data}),
                setError({state: false, entity: NAVIGATION_CONTRACT}),
                setLoader({state: false, entity: NAVIGATION_CONTRACT})
            ])
            break

        case `${NAVIGATION_CONTRACT} ${API_ERROR}`:
            next([
                setError({state: true, entity: NAVIGATION_CONTRACT}),
                setLoader({state: false, entity: NAVIGATION_CONTRACT})
            ])
            break

        default:
            break;
    }
    return null
}
