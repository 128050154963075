import React from 'react'
import moment from "moment";

const CttOk = (props) => {
    const {task: {date, type, paniere}} = props

    return (
        <div className={'container'}>
            <div className={'row'}>
                <div className={'col text-center'}>
                    <h2 className={'title-primary text-uppercase'}>Tâche {paniere}</h2>
                    <p className={'subtitle'}>Tâche du {moment(date).format('L')}. Recu par {type}</p>
                </div>
            </div>
            <div className={'header-row row'}>
                <div className={'col-12'}>
                    <div className={'form-content text-center'}>
                        <p>Le client a fait une résiliation suite à la vente de son véhicule, et il a un contrat en
                            établissement.</p>
                        <ul className={'text-left my-3'}>
                            <li>Passer le contrat en cours</li>
                            <li>Mettre à jour le tableau des pièces attendues</li>
                        </ul>
                        <p>Attention de vérifier le mode de fractionnement de l'ancien et du nouveau contrat (demander
                            RIB et Mandat si besoin)</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CttOk
