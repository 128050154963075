import React, { Fragment, useRef, useState } from 'react';
import _ from 'lodash';
import { Field } from 'redux-form';
import TextFieldRedux from '../../../../Commun/Input/Text/TextFieldRedux';
import { capitalizeInput, normalizeIban } from '../../../../../utils/normalizeInputs';
import Spinner from '../../../../Commun/Spinner/Spinner';
import MyButton from '../../../../Commun/Button/Button';
import SelectFieldRedux from '../../../../Commun/Input/Select/SelectFieldRedux';
import { checkIban } from '../../../../../utils/function';

const ChangeRibAccident = (props) => {
    const { accident, handleSubmit, loadingValidateRib, errorRib, ibanIsCheck, changeValue } = props;
    const [client] = useState(
        _.find(accident.intervenants, function (i) {
            return !i.iban && i.type === 'Client';
        }),
    );
    const [reparateurs] = useState(
        _.filter(accident.intervenants, function (i) {
            return !i.iban && i.type === 'Réparateur';
        }),
    );
    const [choiceTiers, setChoiceTiers] = useState(false);

    const setBeneficiaire = (tiers_id) => {
        const tiersSelected = _.find(accident.intervenants, ['id', tiers_id]);
        setChoiceTiers(tiersSelected);
    };

    const [errorIban, setErrorIban] = useState(null);
    const [loadingIban, setLoadingIban] = useState(false);
    const inputIban = useRef('');

    const fetchCheckIban = async (value) => {
        setLoadingIban(true);
        let iban = await checkIban(value);
        setLoadingIban(false);
        changeValue('domiciliation', iban.bank_data.bank);
        changeValue('bic', iban.bank_data.bic);

        /* Vérifie si le format de l'iban est correct */
        const codeValidationsSuccess = ['001', '002', '003', '004', '005', '006', '007'];
        const fieldValidations = ['account', 'country_support', 'iban', 'length', 'structure'];
        let error = null;

        _.forEach(fieldValidations, (field) => {
            if (!_.includes(codeValidationsSuccess, iban.validations[field].code)) {
                error = iban.validations[field].message;
                return false;
            }
        });

        /* Vérifie si l'iban accepte les prélèvement */
        if (iban.sepa_data.SDD === 'NO' || iban.sepa_data.SCT === 'NO') {
            return setErrorIban("L'IBAN renseignée n'autorise pas les prélèvements bancaires");
        }

        return error ? setErrorIban(`Le format de l'IBAN n'est pas valide. <br /> ${error}`) : setErrorIban(null);
    };

    return (
        <div className={'contract-container container'}>
            <Fragment>
                <div className={'row'}>
                    <div className={'col'}>
                        <h1 className={'title-primary'}>
                            Sinistre n°{accident.numCbt}
                            <span className={'link-icon-primary ml-2'} />
                        </h1>
                    </div>
                </div>
            </Fragment>

            <div className='row header-row'>
                <div className={'col-12'}>
                    <form className={`form-content`} onSubmit={handleSubmit}>
                        <div className={'alert alert-warning'}>
                            <div className='p-3'>
                                <p>L'IBAN saisi servira uniquement pour le virement sinistre.</p>
                            </div>
                        </div>

                        <Field name={'tiers'} component={SelectFieldRedux} label={'Choix du tiers'} onChange={(e) => setBeneficiaire(e.target.value)}>
                            <option value={null}>Sélectionner un tiers</option>
                            {reparateurs &&
                                reparateurs.map((r, index) => {
                                    return (
                                        <option key={index} value={r.id}>
                                            Réparateur agréé : {r.raisonSociale}
                                        </option>
                                    );
                                })}
                            {client && <option value={`${client.id}`}>Client : {client.raisonSociale}</option>}
                        </Field>

                        {choiceTiers && (
                            <>
                                <Field
                                    name='iban'
                                    component={TextFieldRedux}
                                    label={'IBAN'}
                                    ref={inputIban}
                                    normalize={normalizeIban}
                                />

                                {errorIban && <div className={'alert-danger alert mb-0'} dangerouslySetInnerHTML={{ __html: errorIban }} />}

                                {ibanIsCheck && !errorIban && <div className={'alert-success alert mb-0'}>L'IBAN est valide</div>}

                                <span
                                    color='secondary'
                                    className={`${loadingIban && 'disabled'} btn btn-secondary position-relative my-3 position-relative`}
                                    style={{ minHeight: 50, minWidth: 230 }}
                                    onClick={loadingIban ? null : () => fetchCheckIban(inputIban.current.value)}
                                >
                                    {loadingIban && <Spinner absolute />}
                                    Vérifier l'IBAN du garage
                                </span>

                                <Field name='domiciliation' component={TextFieldRedux} label={'Domiciliation de la banque'} normalize={capitalizeInput} required />
                                <Field name='bic' component={TextFieldRedux} label={'Code BIC'} normalize={capitalizeInput} />
                                {loadingValidateRib ? (
                                    <Spinner />
                                ) : (
                                    <MyButton variant='raised' color='secondary' size='large' className={'btn btn-primary'} disabled={loadingValidateRib || errorIban || !ibanIsCheck}>
                                        {loadingValidateRib && <Spinner absolute />}
                                        Valider
                                    </MyButton>
                                )}
                                {errorRib && <h2 className={'title-primary text-uppercase'}>Une erreur est survenue</h2>}
                            </>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ChangeRibAccident;
