import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import {ACCIDENTS, getAccidents} from '../../../../../../redux/actions/app/accidents/accidents.actions'
import {getErrorEntity, getLoadingEntity} from '../../../../../../redux/selectors/ui/ui.selectors'
import Accidents from './Accidents'
import {accidentsGetLink} from '../../../../../../redux/selectors/links/links.selectors'
import ErrorHttpComponent from '../../../../../Commun/Error/ErrorHttpComponent'
import {
    getAccidentsData,
    getAccidentsLinks,
    getAccidentsMeta
} from '../../../../../../redux/selectors/accidents/accidents.selectors'
import {TIERS} from "../../../../../../redux/actions/app/tiers/tiers.actions";
import loader from "../../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    return {
        meta: getAccidentsMeta(state),
        links: getAccidentsLinks(state),
        accidents: getAccidentsData(state),
        uri: accidentsGetLink(state, TIERS),
        loaded: getLoadingEntity(state, ACCIDENTS) === false,
        error: getErrorEntity(state, ACCIDENTS)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getAccidents: (query) => getAccidents({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getAccidents} = dispatchProps
    const {uri} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getAccidents(uri)
    }
}

const AccidentsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(Accidents)

export default AccidentsContainer
