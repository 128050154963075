import React, {Component} from 'react'
import TextFieldSearchRedux from "../../../Commun/Input/Text/TextFieldSearchRedux";
import {Field} from "redux-form";
import MyButton from "../../../Commun/Button/Button";
import {Loupe} from "../../../Commun/Icons/Icons";
import Spinner from "../../../Commun/Spinner/Spinner";
import SearchResult from "./SearchResult";
import SearchHistory from "./SearchHistory/SearchHistory";

class SearchField extends Component {
    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount() {
        if (this.props.search) {
            this.handleSubmit(this.props.initialValues)
        }
    }

    handleSubmit(form) {
        this.props.onSubmit(form)
    }

    render() {
        const {handleSubmit, loading, result, toggleSearch, clearSearch} = this.props


        return (
            <div className={'search-container page-container row'}>
                <div className={'content'}>
                    <div className={'search-form'}>
                        {loading ? <Spinner absolute/>
                            : <form onSubmit={handleSubmit(this.handleSubmit)}>
                                <Field
                                    autoFocus={'autofocus'}
                                    className={'search'}
                                    name='s'
                                    component={TextFieldSearchRedux}
                                    label='Rechercher'
                                    placeholder='Rechercher par police, numéro de tiers...'
                                />
                                <MyButton
                                    variant='raised'
                                    color='secondary'
                                    size='large'
                                    className={'btn btn-search'}
                                    type={'submit'}
                                >
                                    <Loupe/>
                                </MyButton>
                            </form>
                        }
                    </div>
                    {(!loading && result.data) &&
                        <SearchResult result={result} toggleSearch={toggleSearch} clearSearch={clearSearch}/>}
                    <SearchHistory toggleSearch={toggleSearch}/>
                </div>
            </div>
        )
    }
}

export default SearchField
