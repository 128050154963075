import React from 'react'
import moment from "moment";

const ReglementKO = (props) => {
    const {task: {date, description, title, tiers_id}} = props

    return (
        <div className={'container'}>
            <div className={'row'}>
                <div className={'col text-center'}>
                    <h2 className={'title-primary text-uppercase'}>Tâche REG_PROD_KO - Tiers n°{tiers_id}</h2>
                    <p className={'subtitle'}>Tâche du {moment(date).format('L')}.</p>
                </div>
            </div>
            <div className={'header-row row'}>
                <div className={'col-12'}>
                    <p className={'form-content text-center'}>
                        <strong>{title}</strong><br/>
                        {description && <span dangerouslySetInnerHTML={{__html: description}}/>}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ReglementKO
