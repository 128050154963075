import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import Agenda from './Agenda'
import ErrorHttpComponent from '../../../Commun/Error/ErrorHttpComponent'
import {getTasks, TASKS} from '../../../../redux/actions/app/tasks/tasks.actions'
import {tasksLink} from '../../../../redux/selectors/links/links.selectors'
import {getBelairName} from "../../../../redux/selectors/user/user.selectors";
import {getErrorEntity, getLoadingEntity} from "../../../../redux/selectors/ui/ui.selectors";
import {getTasksData, getTasksMeta} from "../../../../redux/selectors/tasks/tasks.selectors";
import loader from "../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    return {
        uri: tasksLink(state),
        belairName: getBelairName(state),
        agendas: getTasksData(state),
        meta: getTasksMeta(state) || {},
        loaded: getLoadingEntity(state, TASKS) === false,
        error: getErrorEntity(state, TASKS)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getTasks: ({query, sort, contrat_situation, paniere}) => getTasks({query, sort, contrat_situation, paniere}),
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getTasks} = dispatchProps
    const {uri, belairName} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getTasks({query: uri, paniere: 'AG_' + belairName, contrat_situation: '', sort: 'date'}),
    }
}

const AgendaContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(Agenda)

export default AgendaContainer
