import React, {useEffect, useState} from 'react';
import {tokenUser} from "../../../redux/selectors/localStorage/localStorage";
import Spinner from "../Spinner/Spinner";

const APIFrame = ({src, ...otherProps}) => {
    const [objectUrl, setObjectUrl] = useState(null)
    const [error, setError] = useState(null)

    let url = new URL(src)
    url.searchParams.delete('access_token');
    url = url.toString()

    const urlSlices = /^.*\/(.*)\.(.*)\?{0,1}.*$/.exec(url)
    const fileName = `${urlSlices[1]}.${urlSlices[2]}`

    useEffect(() => {
        let headers = new Headers();
        headers.append('Authorization', `Bearer ${tokenUser.trim()}`);

        fetch(url, {headers})
            .then((response) => {
                if (response.ok) {
                    response.blob()
                        .then(blob => setObjectUrl(window.URL.createObjectURL(blob)))
                } else {
                    setError(`Erreur lors du téléchargement du fichier ${fileName}`)
                }
            })
    }, [])

    if (error) {
        return <p className={'text-danger'}>{error}</p>
    }

    if (!objectUrl) {
        return <div><Spinner size={12} /> T&eacute;l&eacute;chargement du fichier {fileName}...</div>
    }

    return <iframe src={objectUrl} {...otherProps} title={fileName} />
}

export default APIFrame;
