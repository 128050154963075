export const APP_NAME = 'espace-conseiller-v2'
export const APP_ENV = import.meta.env.VITE_VERCEL_ENV ?? 'development';

export const API_URL = import.meta.env.VITE_PASS_URL;
export const SERVICES_URL = import.meta.env.VITE_SERVICES_URL

export const API_END_POINT = import.meta.env.VITE_PASS_OAUTH_URL
export const APP_CLIENT_ID = import.meta.env.VITE_CLIENT_ID
export const APP_GRANT_TYPE = import.meta.env.VITE_GRANT_TYPE
export const APP_PROVIDER = import.meta.env.VITE_PROVIDER

export const GRAVITEE_URL = import.meta.env.VITE_GRAVITEE_URL
export const GRAVITEE_API_KEY = import.meta.env.VITE_GRAVITEE_API_KEY_IBAN

export const HELP_URL = import.meta.env.VITE_HELP_URL
export const CHECKDOC_URL = import.meta.env.VITE_CHECKDOC_URL
export const PUBLISH_URL = import.meta.env.VITE_PUBLISH_URL


export const listMailJet = {
    'pmq': 1309563,
    'pmqAccident': 'pmq-accident',
    'libre': 1311585
}

export const openInWindow = {
    task: 'Task',
    email: 'Email',
    emailAccident: 'EmailLibreAccident',
    emailTiers: 'EmailLibreTiers',
    emailContract: 'EmailLibreContract',
    addActivities: 'AddActivities',
    addExpert: 'addExpert',
    extraction: 'Extraction',
    activities: 'Activities',
    activitiesTiers: 'activitiesTiers',
    activitiesContract: 'ActivitiesContract',
    activitiesAccident: 'ActivitiesAccident',
    activitiesComplain: 'ActivitiesComplain',
    quittance: 'Quittance',
    formulaire: 'Formulaire',
    reclamation: 'Reclamation',
    avenant: 'Avenant',
    commande: 'Commande',
    dashboardDetail: 'dashboard-detail',
    virements: 'Virements',
    ribAccident: 'rib-accident',
    addAccessoires: 'FactureAccident',
    addRapportExpert: 'RapportExpertAccident',
    extractQuote: 'ExtractQuote',
    derogation: 'Derogation',
    transfert: 'Transfert',
    tiers: 'Tiers',
    hivernage: 'Hivernage',
    devis: 'Devis',
    tracfin: 'Tracfin',
    resilie: 'Resilier',
    paiement: 'Paiement',
    rib: 'rib',

}
