import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import FoiresAuxQuestions from './FoiresAuxQuestions'
import ErrorHttpComponent from '../../../Commun/Error/ErrorHttpComponent'
import {FAQ, getFaq} from '../../../../redux/actions/app/faq/faq.actions'
import {getErrorEntity, getLoadingEntity} from '../../../../redux/selectors/ui/ui.selectors'
import {ConseillerExterneFaqLink} from '../../../../redux/selectors/links/links.selectors'
import {
    getFaqExterneEspace,
    getFaqExterneReglement,
    getFaqExterneDocument,
    getFaqExternePieces,
    getFaqData,
    getFaqExterneResiliations,
    getFaqExterneQuestions,
    getFaqExterneChangeAddress,
    getFaqExterneCompta
} from "../../../../redux/selectors/support/support.selectors";
import {messageUser} from "../../../../redux/selectors/localStorage/localStorage";
import loader from "../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state) => {
    return {
        faqs: getFaqData(state),
        listFaq: {
            espace: getFaqExterneEspace(state),
            reglement: getFaqExterneReglement(state),
            document: getFaqExterneDocument(state),
            pieces: getFaqExternePieces(state),
            resiliations: getFaqExterneResiliations(state),
            questions: getFaqExterneQuestions(state),
            adresses: getFaqExterneChangeAddress(state),
            comptas: getFaqExterneCompta(state)
        },
        uri: ConseillerExterneFaqLink(state),
        loaded: getLoadingEntity(state, FAQ) === false,
        error: getErrorEntity(state, FAQ),
        message: messageUser
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getFaq: (query) => getFaq({query}),
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getFaq} = dispatchProps
    const {uri} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getFaq(uri)
    }
}

const FoiresAuxQuestionsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(FoiresAuxQuestions)

export default FoiresAuxQuestionsContainer
