export const COMMERCIAUX = '[Commerciaux]'

export const GET_COMMERCIAUX = `${COMMERCIAUX} Get`
export const SET_COMMERCIAUX = `${COMMERCIAUX} Set`

export const getCommerciaux = ({query}) => ({
    type: GET_COMMERCIAUX,
    payload: {
        data: query
    }
})

export const setCommerciaux = ({commerciaux}) => ({
    type: SET_COMMERCIAUX,
    payload: {
        data: commerciaux
    }
})
