import React from 'react';
import {Field} from "redux-form";
import TextFieldRedux from "../../../../../../../Commun/Input/Text/TextFieldRedux";


const InfosVehicule = (props) => {

    return (
        <>
            <div className="col">
                <Field
                    name='marque_vehicule'
                    data-test-id="avenant_simple_marque_vehicule_input"
                    component={TextFieldRedux}
                    label={'Marque du véhicule :'}
                />
            </div>
            <div className="col">
                <Field
                    name='modele_vehicule'
                    data-test-id="avenant_simple_modele_vehicule_input"
                    component={TextFieldRedux}
                    label={'Modèle du véhicule :'}
                    normalize={v => v.toUpperCase()}
                />
            </div>
            <div className="col">
                <Field
                    name='cylindree_vehicule'
                    data-test-id="avenant_simple_cylindre_vehicule_input"
                    component={TextFieldRedux}
                    label={'Cylindrée du véhicule :'}
                />
            </div>
            <div className="col">
                <Field
                    name='version_vehicule'
                    data-test-id="avenant_simple_version_vehicule_input"
                    component={TextFieldRedux}
                    label={'Version du véhicule :'}
                    normalize={v => v.toUpperCase()}
                />
            </div>
        </>
    );
};


export default InfosVehicule;