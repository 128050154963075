import React from 'react';
import {Field} from "redux-form";
import TextFieldRedux from "../../../../../Commun/Input/Text/TextFieldRedux";
import _ from "lodash";


const Informations = (props) => {
    const {commande, produits} = props

    return (
        <div className="row">
            <div className="col-lg-6 col-md-12">
                <h4>Mes <span className={'text-color-secondary'}>Informations</span> :</h4>
                <Field
                    name={'name'}
                    component={TextFieldRedux}
                    label={'Mon cabinet'}
                />
                <Field
                    name={'email'}
                    component={TextFieldRedux}
                    label={'Mon Email'}
                />
                <Field
                    name={'delivery_address'}
                    component={TextFieldRedux}
                    label={'Mon adresse de livraison'}
                />
                <Field
                    name={'zip_code'}
                    component={TextFieldRedux}
                    label={'Code postal'}
                />
                <Field
                    name={'city'}
                    component={TextFieldRedux}
                    label={'Ville'}
                />
            </div>
            <div className="col-lg-6 col-md-12">
                <h4>Commande :</h4>

                {_.keys(commande).length === 0
                    ? <div className={'alert-danger alert m-0'}>Vous n'avez sélectionné aucune publicité</div>
                    : <ul>
                        {
                            Object.keys(commande).map(k => (
                                <li key={k}>
                                    {commande[k]} - <b>{_.find(produits, ['id', Number(k)]).title_flyer}</b>
                                </li>
                            ))
                        }
                    </ul>
                }
            </div>
        </div>
    );
};


export default Informations;
