import {GET_STATS, setStats, STATS} from '../../../actions/app/stats_courtier/stats_courtier.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'

export const statsMiddleware = () => next => action => {
    next(action)

    switch (action.type) {

        case GET_STATS:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: STATS}),
                setLoader({state: true, entity: STATS})
            ])
            break

        case `${STATS} ${API_SUCCESS}`:
            next([
                setStats(action.payload.data),
                setError({state: false, entity: STATS}),
                setLoader({state: false, entity: STATS})
            ])
            break

        case `${STATS} ${API_ERROR}`:
            next([
                setError({state: true, entity: STATS}),
                setLoader({state: false, entity: STATS})
            ])
            break

        default:
            break
    }
    return null
}
