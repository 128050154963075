import {connect} from 'react-redux'
import Buttons from './Buttons'
import _ from "lodash";
import {postNoteAccidentGetLink} from "../../../../../redux/selectors/links/links.selectors";
import {PUBLISH_URL} from "../../../../../constants/constants";
import {bindActionCreators} from "redux";
import {logAction} from "../../../../../redux/actions/core/logs/logs.actions";
import {getReclamationData} from "../../../../../redux/selectors/reclamation/reclamation.selectors";
import {getUserPermissions} from "../../../../../redux/selectors/user/user.selectors";
import {getIsWarkamContract} from "../../../../../redux/selectors/contract/contract.selectors";

const mapStateToProps = (state, ownProps) => {
    return {
        idContract: ownProps.idContract,
        isWakam: getIsWarkamContract(state),
        accident: ownProps.accident,
        souscripteur: ownProps.souscripteur,
        noteUrl: _.replace(_.replace(postNoteAccidentGetLink(state), '{id}', ownProps.idContract), '{ids}', ownProps.accident.id),
        publishUrl: PUBLISH_URL,
        permissions: getUserPermissions(state),
        reclamations: getReclamationData(state) || []
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    postLogAction: ({entity, type, sinistre}) => logAction({entity, type, sinistre})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {postLogAction} = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        postLogActionEditDocument: () => postLogAction({
            entity: null,
            type: 'CLICK_EDIT_DOCUMENT',
            sinistre: ownProps.accident.id,
            tiers: ownProps.accident.id_tiers
        }),
        postLogActionFormRib: () => postLogAction({
            entity: null,
            type: 'CLICK_FORM_RIB',
            sinistre: ownProps.accident.id,
            tiers: ownProps.accident.id_tiers
        }),
    }
}

const ButtonsContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Buttons)

export default ButtonsContainer
