export const PAY_RECEIPT = '[Pay Receipt]'

export const POST_PAY_RECEIPT = `${PAY_RECEIPT} Post`
export const SET_PAY_RECEIPT = `${PAY_RECEIPT} Set`

export const QUITTANCE = '[Quittance]'

export const GET_QUITTANCE = `${QUITTANCE} Get`
export const SET_QUITTANCE = `${QUITTANCE} Set`

export const getQuittance = ({query}) => ({
    type: GET_QUITTANCE,
    payload: {
        data: query
    }
})

export const setQuittance = ({details, tableau}) => ({
    type: SET_QUITTANCE,
    payload: {details, tableau},
    meta: {
        entity: QUITTANCE
    }
})

export const postPayReceipt = ({email, contract, receipt, query}) => ({
    type: POST_PAY_RECEIPT,
    payload: {
        data: query,
        body: {email, contract, receipt},
    }
})

export const setPayReceipt = ({state}) => ({
    type: SET_PAY_RECEIPT,
    payload: state
})
