export const STATS = '[Stats]'

export const GET_STATS = `${STATS} Get`
export const SET_STATS = `${STATS} Set`

export const getStats = ({query}) => ({
    type: GET_STATS,
    payload: {
        data: query
    }
})

export const setStats = ({data}) => ({
    type: SET_STATS,
    payload: {
        data
    },
})
