import React, {Fragment} from 'react'
import SearchCard from "../../../Commun/Card/SearchCard";
import _ from 'lodash'

const SearchResult = (props) => {
    const {result, toggleSearch, clearSearch} = props

    const setLocalStorage = data => {
        let search = JSON.parse(localStorage.getItem('historySearch')) || []

        let find = false
        _.forEach(search, value => {
            if (_.isEqual(value, data)) {
                find = true
            }
        })

        if (search.length === 6) {
            search.shift()
        }

        if (!find) {
            search.push(data)
        }

        localStorage.setItem('historySearch', JSON.stringify(search))

        return true

    }

    const redirect = (data) => {
        toggleSearch()
        clearSearch()
        setLocalStorage(data)
        switch (data.type) {
            case 'TIERS':
            default:
                return window.location = `/tiers/${data.id_tiers}`
            case 'SINISTRE':
                return window.location = `/contrat/${data.id_contrat}/sinistre/${data.id_sinistre}`
            case 'CONTRAT':
                return window.location = `/contrat/${data.id_contrat}`
        }
    }

    return (
        <div className={'search-result'}>
            {result && <Fragment>
                {result.total === 0 && <p className={'no-result'}>La recherche n'a donnée aucun résultat.</p>}
                {result.total === 1 && redirect(result.data[0])}
                {result.total > 1 && <Fragment>
                    <div className={'row'}>
                        <div className={'col'}><p className={'text-right'}>{result.total} résultats
                            trouvés {result.total > 15 && ', veuillez affiner votre recherche.'}</p></div>
                    </div>
                    <div className={'row'}>
                        {result.data.map((item, index) =>
                            <SearchCard item={item} key={index} toggleSearch={toggleSearch}
                                        setLocalStorage={setLocalStorage}/>
                        )}
                    </div>
                </Fragment>}
            </Fragment>
            }
        </div>
    )
}

export default SearchResult
