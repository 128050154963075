import React from 'react'
import moment from "moment";
import APIFrame from "../../../../../Commun/APILink/APIFrame";
import APIImage from "../../../../../Commun/APILink/APIImage";

const Agenda = (props) => {
    const {task: {date, title, description, tiers_id}, files} = props

    return (
        <div className={`${files !== undefined ? '' : 'container'}`}>
            <div className={'row'}>
                <div className={'col text-center'}>
                    <h2 className={'title-primary text-uppercase'}>Tâche Agenda - Tiers n°{tiers_id}</h2>
                    <p className={'subtitle'}>A traiter le {moment(date).format('L')}.</p>
                </div>
            </div>
            <div className={'header-row row'}>
                {files !== undefined &&
                    files.map((file) => (
                        file.extension.toLowerCase() === 'pdf' ?
                            <div className={'col-8'} style={{marginBottom: 30}}>
                                <APIFrame src={`${file.url}`} title={`${file.code}`} frameBorder="0"
                                        style={{height: '100%', width: '100%', marginBottom: 30}}/>
                            </div>
                            :
                            <div className={'col-7 task-img-container'}>
                                <APIImage src={`${file.url}`} alt={'Document'}/>
                            </div>
                    ))
                }
                <div className={` ${files !== undefined ? 'col-md-4' : 'col-12'}`}>
                    <p className={'form-content text-center'}>
                        Type : {title}<br/>
                        Description : {description ? <span dangerouslySetInnerHTML={{__html: description}}/> : '-'}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Agenda
