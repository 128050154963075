import React from 'react';
import FormChangeDateExpertVisitAccidentContainer
    from "./FormChangeDateExpertVisitAccident/FormChangeDateExpertVisitAccidentContainer";

const ChangeDateExpertVisitAccident = (props) => {
    const {history} = props

    return (
        <div className={'contract-container container'}>
            <div className={'row return-content'}>
                <div className={'col'}>
                    <button onClick={() => history.go(-1)} className={'btn btn-return'}>Retour</button>
                </div>
            </div>
            <div className="row header-row">
                <div className={'col-12'}>
                    <FormChangeDateExpertVisitAccidentContainer {...props}/>
                </div>
            </div>
        </div>
    );
};

export default ChangeDateExpertVisitAccident;
