import React, {Fragment} from 'react';
import {Field} from "redux-form";
import RadioFieldRedux from "../../../../../../../Commun/Input/Radio/RadioFieldRedux";
import ReparationContainer from "../Reparation/ReparationContainer";

const DommageVehicule = (props) => {
    const {garage, changeValue} = props
    return (
        <Fragment>
            <div className={'form-content h-100'} style={{height: 'auto'}}>
                <h1 className={'title-primary'}>Réparation</h1>
                <Field
                    name='vehicule'
                    component={RadioFieldRedux}
                    label={'Connaissez-vous le garage qui fera les réparations de votre véhicule ?'}
                    data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                />
                {garage === 'T' &&
                    <ReparationContainer changeValue={changeValue}/>
                }
            </div>
        </Fragment>
    );
};

export default DommageVehicule;
