import React, {Fragment} from 'react'

const Commerciaux = (props) => {
    const {commerciaux: {salesrepresentatives, salesassistants}} = props

    return (
        <div className={'bloc-info bloc-intervenants'}>
            <h2>Commerciaux</h2>
            <div className={'content row'}>
                {(salesrepresentatives[0] || salesassistants[0]) ?
                    <Fragment>
                        {
                            salesrepresentatives[0] ?
                                <>
                                    <div className={'col-5 left-content'}>
                                        <p>Commercial(e)</p>
                                        <p>&nbsp;</p>
                                        <p>&nbsp;</p>
                                    </div>
                                    <div className={'col-7 right-content'}>
                                        <p>{salesrepresentatives[0].titre} {salesrepresentatives[0].raisonSociale}</p>
                                        <p>{salesrepresentatives[0].phone}</p>
                                        <p>{salesrepresentatives[0].email}</p>
                                    </div>
                                </> : null
                        }
                        {
                            salesassistants[0] ?
                                <>
                                    <div className={'col-5 left-content'}>
                                        <p>Assistant(e) commercial</p>
                                        <p>&nbsp;</p>
                                        <p>&nbsp;</p>
                                    </div>
                                    <div className={'col-7 right-content'}>
                                        <p>{salesassistants[0].titre} {salesassistants[0].raisonSociale}</p>
                                        <p>{salesassistants[0].phone}</p>
                                        <p>{salesassistants[0].email}</p>
                                    </div>
                                </> : null
                        }
                    </Fragment>
                    : <div className={'col-12'}>
                        <p>Pas de commerciaux associés a ce courtier / concessionnaire</p>
                    </div>
                }
            </div>
        </div>
    )
}

export default Commerciaux
