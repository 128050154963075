import {createSelector} from 'reselect'

const tiersSelector = state => state.tiers.data || {}
const comptesSelector = state => state.tiers.comptes || []

export const getTiersData = createSelector(
    tiersSelector,
    data => data
)

export const getTiersBelairId = createSelector(
    tiersSelector,
    data => data.belair_id
)

export const getTiersChequesCadeaux = createSelector(
    tiersSelector,
    data => data.cheques_cadeaux
)

export const getTiersName = createSelector(
    tiersSelector,
    data => data.name
)

export const getTiersEmail = createSelector(
    tiersSelector,
    data => data.address_mail
)

export const getTiersType = createSelector(
    tiersSelector,
    data => data.type
)

export const getTiersAddressStreet1 = createSelector(
    tiersSelector,
    data => data.address_street_1
)

export const getTiersAddressPostalCode = createSelector(
    tiersSelector,
    data => data.address_postalcode
)

export const getTiersAddressCity = createSelector(
    tiersSelector,
    data => data.address_city
)

export const getTiersComptes = createSelector(
    comptesSelector,
    comptes => comptes || []
)
