export const NOTES = '[Notes]'
export const ADD_NOTE = '[Add Note]'
export const EDIT_NOTE = '[Edit Note]'
export const DELETE_NOTE = '[Delete Note]'

export const GET_NOTES = `${NOTES} Get`
export const SET_NOTES = `${NOTES} Set`

export const POST_ADD_NOTE = `${ADD_NOTE} Post`
export const SET_ADD_NOTE = `${ADD_NOTE} Set`

export const POST_EDIT_NOTE = `${EDIT_NOTE} Post`
export const SET_EDIT_NOTE = `${EDIT_NOTE} Set`

export const POST_DELETE_NOTE = `${DELETE_NOTE} Post`
export const SET_DELETE_NOTE = `${DELETE_NOTE} Set`

export const getNotes = ({query}) => ({
    type: GET_NOTES,
    payload: {
        data: query
    }
})

export const setNotes = ({data}) => ({
    type: SET_NOTES,
    payload: {data},
    meta: {
        entity: NOTES
    }
})

export const postAddEditNote = ({form, query}) => ({
    type: POST_ADD_NOTE,
    payload: {
        data: query,
        body: form
    }
})

export const setAddNote = ({state}) => ({
    type: SET_ADD_NOTE,
    payload: {
        data: state
    }
})

export const postDeleteNote = ({id, query}) => ({
    type: POST_DELETE_NOTE,
    payload: {
        data: query,
        body: {id}
    }
})

export const setDeleteNote = ({state}) => ({
    type: SET_DELETE_NOTE,
    payload: {
        data: state
    }
})
