import React, {Fragment} from 'react'
import FaqCard from "../../../Commun/Card/FaqCard";

const FoiresAuxQuestions = (props) => {
    const {listFaq: {espace, reglement, document, pieces, resiliations, questions, adresses, comptas}, message} = props

    return (
        <Fragment>
            <div className={`support-container container`}>
                <div className={'row'}>
                    <div className={'col'}>
                        <h2 className={'title-primary title-faq'}>Foire aux questions</h2>
                    </div>
                </div>
                {message && <div className={'row'}>
                    <div className={'col-md-8 offset-md-2'}>
                        <p className={'alert alert-light'}>
                            {message}
                        </p>
                    </div>
                </div>}
                <div className={'row'}>
                    <div className={'col'}>
                        <nav className="navbar navbar-expand-lg navbar-faq">
                            <div className="collapse navbar-collapse">
                                <div className="navbar-nav">
                                    <p>Choisissez une rubrique :</p>
                                    <span className="btn btn-smaller"
                                          onClick={() => window.document.getElementById('espace').scrollIntoView({behavior: 'smooth'})}>
                                        Espace conseiller
                                    </span>
                                    <span className="btn btn-smaller"
                                          onClick={() => window.document.getElementById('reglement').scrollIntoView({behavior: 'smooth'})}>
                                        Règlement
                                    </span>
                                    <span className="btn btn-smaller"
                                          onClick={() => window.document.getElementById('document').scrollIntoView({behavior: 'smooth'})}>
                                        Renvoyer un document
                                    </span>
                                    <span className="btn btn-smaller"
                                          onClick={() => window.document.getElementById('pieces').scrollIntoView({behavior: 'smooth'})}>
                                        Pièces justificatives
                                    </span>
                                    <span className="btn btn-smaller"
                                          onClick={() => window.document.getElementById('resiliations').scrollIntoView({behavior: 'smooth'})}>
                                        Résiliation
                                    </span>
                                    <span className="btn btn-smaller"
                                          onClick={() => window.document.getElementById('questions').scrollIntoView({behavior: 'smooth'})}>
                                        Questions diverses
                                    </span>
                                    <span className="btn btn-smaller"
                                          onClick={() => window.document.getElementById('change_address').scrollIntoView({behavior: 'smooth'})}>
                                        Changement d'adresse
                                    </span>
                                    <span className="btn btn-smaller"
                                          onClick={() => window.document.getElementById('comptas').scrollIntoView({behavior: 'smooth'})}>
                                        Comptabilité
                                    </span>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
                <div className={'accordion row'} id="espace">
                    <div className={'col'}>
                        <h2 className={"title-primary title-list"}>Espace conseiller</h2>
                        {espace.map((faq, index) => (
                            <FaqCard faq={faq} index={index} key={index}/>
                        ))}
                    </div>
                </div>
                <div className={'accordion row'} id="reglement">
                    <div className={'col'}>
                        <h2 className={"title-primary title-list"}>Règlement</h2>
                        {reglement.map((faq, index) => (
                            <FaqCard faq={faq} index={index} key={index}/>
                        ))}
                    </div>
                </div>
                <div className={'accordion row'} id="document">
                    <div className={'col'}>
                        <h2 className={"title-primary title-list"}>Renvoyer un document</h2>
                        {document.map((faq, index) => (
                            <FaqCard faq={faq} index={index} key={index}/>
                        ))}
                    </div>
                </div>
                <div className={'accordion row'} id="pieces">
                    <div className={'col'}>
                        <h2 className={"title-primary title-list"}>Pièces justificatives</h2>
                        {pieces.map((faq, index) => (
                            <FaqCard faq={faq} index={index} key={index}/>
                        ))}
                    </div>
                </div>
                <div className={'accordion row'} id="resiliations">
                    <div className={'col'}>
                        <h2 className={"title-primary title-list"}>Résiliation</h2>
                        {resiliations.map((faq, index) => (
                            <FaqCard faq={faq} index={index} key={index}/>
                        ))}
                    </div>
                </div>
                <div className={'accordion row'} id="questions">
                    <div className={'col'}>
                        <h2 className={"title-primary title-list"}>Questions diverses</h2>
                        {questions.map((faq, index) => (
                            <FaqCard faq={faq} index={index} key={index}/>
                        ))}
                    </div>
                </div>
                <div className={'accordion row'} id="change_address">
                    <div className={'col'}>
                        <h2 className={"title-primary title-list"}>Changement d'adresse</h2>
                        {adresses.map((faq, index) => (
                            <FaqCard faq={faq} index={index} key={index}/>
                        ))}
                    </div>
                </div>
                <div className={'accordion row'} id="comptas">
                    <div className={'col'}>
                        <h2 className={"title-primary title-list"}>Comptabilité</h2>
                        {comptas.map((faq, index) => (
                            <FaqCard faq={faq} index={index} key={index}/>
                        ))}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default FoiresAuxQuestions
