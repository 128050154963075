import {connect} from 'react-redux'
import {compose} from "redux";
import TachePv from "./TachePV";
import {clearFields, formValueSelector, reduxForm} from "redux-form";
import loader from "../../../../../Commun/Spinner/Loader/Loader";
import ErrorHttpComponent from "../../../../../Commun/Error/ErrorHttpComponent";
import {postProcessingPVAccidentGetLink} from "../../../../../../redux/selectors/links/links.selectors";
import _ from "lodash";
import {POST_PROCESSING_PV, postProcessingPV} from "../../../../../../redux/actions/app/accidents/accidents.actions";
import {getErrorEntity, getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {CLOSE_TASK} from "../../../../../../redux/actions/app/tasks/tasks.actions";
import validate from './validate'
import moment from "moment";

const mapStateToProps = (state,ownProps) => {
    const selector = formValueSelector('tache_pv')

    return {
        task: ownProps.task,
        uri: postProcessingPVAccidentGetLink(state),
        terminateQuery: ownProps.terminateTask,
        loadingTerminate: getLoadingEntity(state, CLOSE_TASK),
        loadingProcessingPV: getLoadingEntity(state, POST_PROCESSING_PV),
        errorProcessingPV: getErrorEntity(state, POST_PROCESSING_PV),
        errorTerminate: getErrorEntity(state, CLOSE_TASK),
        vol_violence: selector(state, 'vol_violence'),
        local: selector(state, 'local'),
        elements_voles: selector(state, 'elements_voles'),
        immat_contrat: selector(state, 'immat_contrat'),
        vol_autres: selector(state, 'vol_autres'),
        initialValues: {
            'date_debut': moment().subtract(1, 'day'),
            'date_fin': moment()
        }
    }
}

const mapDispatchToProps = {
    postProcessingPV,
    unsetValue: (field) => clearFields('tache_pv', false, false, field),
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { uri, terminateQuery, task } = stateProps
    const { postProcessingPV } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form) => postProcessingPV({
            query: _.replace(_.replace(uri, '{id}', task.contract.id), '{ids}', task.sinistre.id),
            form,
            accident: task.sinistre.id,
            terminateQuery
        })
    }
}

const TachePvContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'tache_pv',
        validate
    }),
    loader({ErrorIndicator: ErrorHttpComponent})
)(TachePv)

export default TachePvContainer