import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import VirementDetail from './VirementDetail'
import ErrorHttpComponent from '../../../../../Commun/Error/ErrorHttpComponent'
import {VIREMENT, getVirement} from '../../../../../../redux/actions/app/virements/virements.actions'
import {getErrorEntity, getLoadingEntity} from '../../../../../../redux/selectors/ui/ui.selectors'
import {
    virementGetLink,
    validateVirementGetLink,
    accidentGetLink
} from '../../../../../../redux/selectors/links/links.selectors'
import {getVirementDetailData} from '../../../../../../redux/selectors/virements/virements.selectors'
import _ from 'lodash'
import {
    postValidateVirement,
    VALIDATE_VIREMENT
} from "../../../../../../redux/actions/app/validate_virement/validate_virement.actions";
import {formValueSelector, reduxForm} from "redux-form";
import {getAccident} from "../../../../../../redux/actions/app/accidents/accidents.actions";
import {getAccidentData} from "../../../../../../redux/selectors/accident/accident.selectors";
import validate from "./validate";
import loader from "../../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector('validateVirement')

    return {
        idAccident: ownProps.match.params.id,
        validateTitulaire: selector(state, 'validation_titulaire'),
        accident: getAccidentData(state),
        idOrdre: ownProps.match.params.ids,
        idTiers: ownProps.match.params.tiers_id,
        virement: getVirementDetailData(state),
        uri: virementGetLink(state),
        uriGetAccident: accidentGetLink(state),
        uriValidate: validateVirementGetLink(state),
        loaded: getLoadingEntity(state, VIREMENT) === false,
        error: getErrorEntity(state, VIREMENT),
        loading: getLoadingEntity(state, VALIDATE_VIREMENT),
        isValidate: getLoadingEntity(state, VALIDATE_VIREMENT) === false && getErrorEntity(state, VALIDATE_VIREMENT) === false,
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getVirement: (query) => getVirement({query}),
    getAccident: ({query, id}) => getAccident({query, id}),
    postValidateVirement: ({validation_titulaire, commentaire, valide, query}) => postValidateVirement({validation_titulaire, commentaire, valide, query}),
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getVirement, postValidateVirement} = dispatchProps
    const {uri, idAccident, idOrdre, idTiers, uriValidate} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getVirement(_.replace(_.replace(_.replace(uri, '{id}', idAccident), '{ids}', idOrdre), '{tiers_id}', idTiers)),
        onSubmit: (form) => postValidateVirement({
            commentaire: form.commentaire,
            valide: form.valide,
            validation_titulaire: form.validation_titulaire,
            query: _.replace(_.replace(_.replace(uriValidate, '{id}', idAccident), '{ids}', idOrdre), '{tiers_id}', idTiers)
        })
    }
}

const VirementDetailContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent}),
    reduxForm({
        form: 'validateVirement'
    })
)(VirementDetail)

export default VirementDetailContainer
