import {connect} from 'react-redux'
import DetailsReglementCalculFranchise from "./DetailsReglementCalculFranchise";
import {formValueSelector} from "redux-form";
import {
    getAccidentCodeProduit,
    getAccidentCreditFranchise,
    getAccidentFranchiseReglee
} from "../../../../../../../../redux/selectors/accident/accident.selectors";

const mapStateToProps = state => {
    const selector = formValueSelector('virement-sinistre')

    return {
        codeProduit: getAccidentCodeProduit(state),
        taux_credit_franchise: getAccidentCodeProduit(state) === "7311_MOT1" ? parseFloat(getAccidentCreditFranchise(state)) / 100 : 0,
        credit_franchise: getAccidentCreditFranchise(state),
        type_franchise: selector(state, 'type_franchise'),
        montant_franchise: selector(state, 'montant_franchise'),
        pourcentage_franchise: selector(state, 'pourcentage_franchise') ? selector(state, 'pourcentage_franchise') / 100 : null,
        valeur_min_franchise: selector(state, 'valeur_min_franchise'),
        valeur_max_franchise: selector(state, 'valeur_max_franchise'),
        franchise_reglee: getAccidentFranchiseReglee(state)
    }
}

const DetailsReglementCalculFranchiseContainer = connect(mapStateToProps)(DetailsReglementCalculFranchise)

export default DetailsReglementCalculFranchiseContainer
