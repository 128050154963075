import React from 'react'
import {Field} from "redux-form";
import MyButton from "../../../../Commun/Button/Button";
import Spinner from "../../../../Commun/Spinner/Spinner";
import RadioFieldRedux from "../../../../Commun/Input/Radio/RadioFieldRedux";

const ChangeRemboursementAccident = (props) => {
    const {loading, handleSubmit} = props

    return (
        <div className={'contract-container container'}>
            <div className={'row return-content'}>
                <div className={'col'}>
                    <button onClick={() => props.history.go(-1)} className={'btn btn-return'}>Retour</button>
                </div>
            </div>
            <div className={'row header-row'}>
                <div className={'col-12'}>
                    <form className={'form-content'} onSubmit={handleSubmit}>
                        <Field
                            name='refund_repairer'
                            component={RadioFieldRedux}
                            label={'Est-ce que le client souhaite que l\'on rembourse directement le réparateur'}
                            data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                        />
                        <MyButton
                            variant='raised'
                            color='secondary'
                            size='large'
                            className={'btn btn-primary mt-5'}
                            disabled={loading}
                        >
                            {loading && <Spinner absolute/>}
                            Modifier
                        </MyButton>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ChangeRemboursementAccident
