import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress';

const style = theme => ({
    root: {
        margin: '0 20px'
    },
    absolute: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -20,
        marginLeft: -20
    }
})

const Spinner = ({
                     absolute,
                     classes,
                     ...custom
                 }) => {
    return (
        <CircularProgress {...custom} thickness={7} style={absolute ? {position: 'absolute', top: '50%', left: '50%', marginTop: -20, marginLeft: -20} : null}/>
    )
}

Spinner.propTypes = {
    absolute: PropTypes.bool,
    classes: PropTypes.object
}

export default Spinner
