import React, {useEffect, useRef} from 'react'
/*
Usage :

<Field
  name={String}
  component={TextFieldRedux}
  label={String}
  InputProps={{
    placeholder: {String}
  }}
/>
 */

const FileFieldRedux = ({
                            input,
                            meta: {touched, error},
                            classes,
                            label,
                            subLabel,
                            type,
                            InputLabelProps,
                            onChangeValue
                        }) => {
    const ref = useRef();
    const clearFile = () => {
        ref.current.value = ''
        onChangeValue(input.name, '')
    }

    useEffect(() => {
        if (!input.value) {
            clearFile();
        }
    }, [input.value]);

    return (
        <div className={'form-group'}>
            <label {...InputLabelProps}>
                {label}
            </label>
            <small className={'float-right'}>{subLabel}</small>
            <input
                className={'form-control'}
                type='file'
                onChange={(e) => onChangeValue(input.name, e.target.files[0])}
                accept='image/*, .pdf, .doc, .docx, .eml, .csv, .xls'
                ref={ref}
            />
            {touched && error && <small className="invalid-feedback">{error}</small>}

            {input.value &&
                <button onClick={clearFile} type='button' className={'btn btn-cta btn-secondary mt-3 ml-0'}>Vider le
                    fichier</button>}
        </div>
    )
}

export default (FileFieldRedux)
