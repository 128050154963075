import React from 'react'

/*
Usage :

<Field
  name={String}
  component={TextAreaFieldRedux}
  label={String}
  InputProps={{
    placeholder: {String}
  }}
/>
 */

const TextAreaFieldRedux = ({
                                input,
                                meta: {touched, error},
                                classes,
                                label,
                                subLabel,
                                InputLabelProps,
                                ...custom
                            }) => {
    return (
        <div className={'form-group'}>
            <label {...InputLabelProps}>
                {label}
            </label>
            <textarea
                className={'form-control ' + (touched ? Boolean(error) ? 'is-invalid' : 'is-valid' : '')}
                {...input}
                {...custom}
            />
            <small className={'float-right'}>{subLabel}</small>
            {touched && error && <small className="invalid-feedback">{error}</small>}
        </div>
    )
}

export default (TextAreaFieldRedux)
