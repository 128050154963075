import _ from "lodash";

const validate = values => {
    const errors = {}
    const requiredFields = [
        'motif',
        'date'
    ]

    if (values.motif === "K" || values.motif === "F") {
        requiredFields.push('motif_description');
    }
    if (values.motif === "Z" && (!values.motif_details || !_.some(values.motif_details, (val) => val === true))) {
        errors['motif_details.manque_cv'] = 'Vous devez sélectionner au moins un détail de motid de résilation'
    }

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Ce champ est obligatoire'
        }
        if (values.motif === '0' && !values.insurer) {
            errors.insurer = 'Ce champ est obligatoire'
        }
    })

    return errors
}

export default validate
