import React from 'react';
import moment from "moment";


const AcpteCb = (props) => {
    const {task: {date, type, paniere, tiers_id}} = props

    return (
        <div className={'container'}>
            <div className={'row'}>
                <div className={'col text-center'}>
                    <h2 className={'title-primary text-uppercase'}>{paniere} - Tiers n°{tiers_id}</h2>
                    <p className={'subtitle'}>Tâche du {moment(date).format('L')}. Recu par {type}</p>
                </div>
            </div>
            <div className={'header-row row'}>
                <div className={'col-12'}>
                    <p className={'form-content text-center'}>
                        Suivi relation client.<br/><br/>
                        Réception d'un paiement CB pour régulariser le comptant d'une affaire nouvelle, un rejet ou un
                        prorata de résiliation.
                        Vérifier les quittances et tableaux de prélèvement afin d'affecter l'acompte client au bon
                        endroit.
                        Editer un avis de prélèvement à envoyer au client si un tableau a été modifié.<br/><br/>
                        Si le contrat est "Résilié/Impayé", merci de créer un Agenda Vie de Contrat.
                    </p>
                </div>
            </div>
        </div>
    );
};


export default AcpteCb;
