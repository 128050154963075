import React, {Fragment} from 'react';
import FaqCard from "../../../Commun/Card/FaqCard";
import DocumentationContainer from "./Documentation/DocumentationContainer";
import DocumentsUtilesContainer from "./DocumentsUtiles/DocumentsUtilesContainer";
import Collapse from "../../../Commun/Collapse/Collapse";

const Support = (props) => {
    const {faq_categories, message, expandQuestion} = props
    return (
        <Fragment>
            <div className={`support-container container`}>
                <div className={'row'}>
                    <div className={'col'}>
                        <h2 className={'title-primary title-faq'}>Foire aux questions</h2>
                    </div>
                </div>
                {message && <div className={'row'}>
                    <div className={'col-md-8 offset-md-2'}>
                        <p className={'alert alert-light'}>
                            {message}
                        </p>
                    </div>
                </div>}
                <div className={'row'}>
                    <div className={'col-12 text-center d-none d-lg-block'}>
                        <p>Choisissez une rubrique :</p>
                    </div>

                    <div className={'col-12'}>
                        <nav className="navbar navbar-expand-lg navbar-faq">
                            <div className="collapse navbar-collapse">
                                <div className="navbar-nav flex-wrap">
                                    {faq_categories.map((cat, index) => (
                                        <span key={index} className="btn btn-smaller"
                                              onClick={() => window.document.getElementById('categorie' + cat.id).scrollIntoView({behavior: 'smooth'})}>
                                            {cat.title}
                                        </span>
                                    ))}
                                    <span className="btn btn-smaller"
                                          onClick={() => window.document.getElementById('categoriedispositionsgen').scrollIntoView({behavior: 'smooth'})}>
                                        Dispositions générales
                                    </span>
                                    <span className="btn btn-smaller"
                                          onClick={() => window.document.getElementById('categoriedocutiles').scrollIntoView({behavior: 'smooth'})}>
                                        Documents utiles
                                    </span>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>

                {faq_categories.map((cat, index) => (
                    <div className={'accordion row'} id={`categorie${cat.id}`}>
                        <div className={'col'}>
                            <h2 className={"title-primary title-list"}>{cat.title}</h2>
                            {cat.faqs.map((faq, index) => (
                                <FaqCard faq={faq} index={index} key={index} onClickOpen={() => expandQuestion({
                                    question: faq.question,
                                    category: cat.title
                                })}/>
                            ))}
                        </div>
                    </div>
                ))}
                <div className={'accordion row'} id='categoriedispositionsgen'>
                    <div className={'col'}>
                        <h2 className={"title-primary title-list"}>Dispositions générales</h2>

                        <DocumentationContainer className={'loader'}/>
                    </div>
                </div>
                <div className={'accordion row'} id='categoriedocutiles'>
                    <div className={'col'}>
                        <h2 className={"title-primary title-list"}>Documents utiles</h2>

                        <Collapse title={'Documents utiles'} id={'docsutiles'} onClickOpen={() => expandQuestion({
                            question: `Documents utiles`,
                            category: 'Documents utiles'
                        })}>
                            <DocumentsUtilesContainer className={'loader'}/>
                        </Collapse>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Support
