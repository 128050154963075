import {SET_VIREMENT} from '../../actions/app/virements/virements.actions'

export const virementReducer = (virement = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_VIREMENT) {
        return payload
    } else {
        return virement
    }
}
