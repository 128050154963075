import {createSelector} from 'reselect'

const virementsSelector = state => state.virements
const virementSelector = state => state.virement

export const getVirementsItemsData = createSelector(
    virementsSelector,
    virements => virements.items
)

export const getVirementsTotalData = createSelector(
    virementsSelector,
    virements => virements.total
)

export const getVirementsData = createSelector(
    virementsSelector,
    virements => virements.data
)

export const getVirementsMeta = createSelector(
    virementsSelector,
    virements => virements.meta
)

export const getVirementDetailData = createSelector(
    virementSelector,
    virement => virement.data
)
