import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {reduxForm} from "redux-form";

import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {
    dashboardTeleventeExtractionGetLink,
    dashboardTeleventeGestionnairesGetLink
} from "../../../../../redux/selectors/links/links.selectors";
import moment from "moment";
import ExtractQuotesTlv from "./ExtractQuotesTlv";
import {
    DASHBOARD_TLV_GEST,
    getGestionnairesDashboardTelevente
} from "../../../../../redux/actions/app/dashboard_televente/dashboard_televente.actions";
import {
    POST_EXTRACTION_TLV,
    postExtractionTlvQuotes
} from "../../../../../redux/actions/app/wallet/wallet.actions";
import {
    getDashboardTeleventeGestionnairesData
} from "../../../../../redux/selectors/dashboard_televente/dashboard_televente.selectors";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    return {
        gestionnaires: getDashboardTeleventeGestionnairesData(state),
        loading: getLoadingEntity(state, POST_EXTRACTION_TLV),
        url: dashboardTeleventeExtractionGetLink(state),
        uri_gest: dashboardTeleventeGestionnairesGetLink(state),
        loaded: getLoadingEntity(state, DASHBOARD_TLV_GEST) === false,
        initialValues: {
            'dateStart': moment().startOf('month'),
            'dateEnd': moment().endOf('month')
        },
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    getGestionnairesDashboardTelevente,
    postExtractionTlvQuotes,
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getGestionnairesDashboardTelevente, postExtractionTlvQuotes} = dispatchProps
    const {uri_gest, url} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getGestionnairesDashboardTelevente({query: uri_gest}),
        onSubmit: form => postExtractionTlvQuotes({form, query: url})
    }
}


const ExtractQuotesTlvContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'extraction-gestionnaire-tlv'
    }),
    loader()
)(ExtractQuotesTlv)


export default ExtractQuotesTlvContainer
