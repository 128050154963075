import React from 'react';
import './radio.scss'

const RadioFieldRedux = (props) => {
    const {
        input: {onChange, value, name, checked},
        meta: {touched, error},
        label,
        data,
        type = 'radio',
        className
    } = props

    let newChecked = checked
    if (value === "F") {
        newChecked = false
    }

    return (
        <div className={`form-group ${className}`}>
            <label
                className={`d-block ${touched ? Boolean(error) ? 'is-invalid text-danger' : 'is-valid' : ''}`}>{label}</label>
            <div className="btn-group-toggle" data-toggle="buttons">
                {
                    data.map((radio, index) => (
                        <label
                            className={`btn btn-outline-info btn-radio ${value === radio.value && 'active'} ${newChecked && 'checked'}`}
                            key={index}>
                            <input type={type} name={name} value={radio.value} onChange={onChange}
                                   checked={newChecked}/> {radio.label}
                        </label>
                    ))
                }
                {touched && error && <small className="invalid-feedback">{error}</small>}
            </div>
        </div>
    );
};

export default RadioFieldRedux;
