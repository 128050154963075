import {connect} from 'react-redux'
import {getUserSubscribeLinks} from "../../../../../redux/selectors/user/user.selectors";
import NouveauDevis from "./NouveauDevis";

const mapStateToProps = state => {
    return {
        SubscribeLinks: getUserSubscribeLinks(state)
    }
}

const NouveauDevisContainer = connect(mapStateToProps)(NouveauDevis)

export default NouveauDevisContainer
