import React, {Fragment, useRef, useState} from 'react';
import {Field} from "redux-form";
import _ from 'lodash'
import TextFieldRedux from "../../../../../Commun/Input/Text/TextFieldRedux";
import {capitalizeInput} from "../../../../../../utils/normalizeInputs";
import MyButton from "../../../../../Commun/Button/Button";
import ChangeTitulaire from "../../../../../Commun/Forms/ChangeTitulaire/ChangeTitulaire";
import Spinner from "../../../../../Commun/Spinner/Spinner";
import moment from "moment";
import {checkIban} from "../../../../../../utils/function";
import APIFrame from "../../../../../Commun/APILink/APIFrame";

const ValidateRib = (props) => {
    const {
        handleSubmit,
        errorRib,
        errorTerminate,
        loadingTerminate,
        contract,
        changeValue,
        loadingValidateRib,
        task,
        ibanIsCheck
    } = props
    const [errorIban, setErrorIban] = useState(null)
    const [loadingIban, setLoadingIban] = useState(false)
    const inputIban = useRef("");

    const fetchCheckIban = async (value) => {
        setLoadingIban(true)
        let iban = await checkIban(value)
        setLoadingIban(false)
        changeValue('domiciliation', iban.bank_data.bank)
        changeValue('bic', iban.bank_data.bic)

        /* Vérifie si le format de l'iban est correct */
        const codeValidationsSuccess = ['001', '002', '003', '004', '005', '006', '007'];
        const fieldValidations = ['account', 'country_support', 'iban', 'length', 'structure'];
        let error = null

        _.forEach(fieldValidations, field => {
            if (!_.includes(codeValidationsSuccess, iban.validations[field].code)) {
                error = iban.validations[field].message
                return false;
            }
        });

        /* Vérifie si l'iban accepte les prélèvement */
        if (iban.sepa_data.SDD === "NO" || iban.sepa_data.SCT === "NO") {
            return setErrorIban("L'IBAN renseignée n'autorise pas les prélèvements bancaires")
        }

        return error ? setErrorIban(`Le format de l'IBAN n'est pas valide. <br /> ${error}`) : setErrorIban(null)
    }

    return (
        <Fragment>
            <div className={'row'}>
                <div className={'col text-center'}>
                    <h2 className={'title-primary text-uppercase'}>Changement de RIB - Tiers n°{task.tiers_id}</h2>
                    <p className={'subtitle'}><span
                        className={'text-uppercase'}>Reçu le {moment(task.date).format('L')} </span>Par {task.type}
                    </p>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-8'} style={{marginBottom: 30}}>
                    {task.files_url.map((file, index) =>
                        <APIFrame src={`${file.url}`} title={'RIB'} frameBorder="0"
                                style={{height: '100%', width: '100%', marginBottom: 30}} key={index}/>
                    )}
                </div>
                <div className={'col-4'}>
                    <form className={`form-content`} onSubmit={handleSubmit}>
                        {contract && <Fragment>
                            <p><u>Information actuelle :</u></p>
                            <p>Nom titulaire : <i>{contract.banque.titulaire || 'Non renseigné'}</i></p>
                            <p>IBAN : <i>{contract.banque.iban || 'Non renseigné'}</i></p>
                            <p>Domiciliation : <i>{contract.banque.domiciliation || 'Non renseigné'}</i></p>
                            <p>BIC : <i>{contract.banque.bic || 'Non renseigné'}</i></p>
                        </Fragment>}

                        <Field
                            name='iban'
                            component={TextFieldRedux}
                            label={'IBAN'}
                            ref={inputIban}
                            normalize={value => value && value.toUpperCase()}
                        />
                        {
                            ibanIsCheck && <>
                                <Field
                                    name='domiciliation'
                                    component={TextFieldRedux}
                                    label={'Domiciliation de la banque'}
                                    normalize={capitalizeInput}
                                    disabled
                                />
                                <Field
                                    name='bic'
                                    component={TextFieldRedux}
                                    label={'Code BIC'}
                                    normalize={capitalizeInput}
                                    disabled
                                />
                            </>
                        }

                        {
                            errorIban &&
                            <div className={'alert-danger alert mb-0'} dangerouslySetInnerHTML={{__html: errorIban}}/>
                        }

                        {
                            ibanIsCheck && !errorIban && <div className={'alert-success alert mb-0'}>
                                l'IBAN est valide
                            </div>
                        }

                        <span
                            color='secondary'
                            className={`${loadingIban && 'disabled'} btn btn-secondary position-relative my-3 position-relative`}
                            style={{minHeight: 50, minWidth: 230}}
                            onClick={loadingIban ? null : () => fetchCheckIban(inputIban.current.value)}
                        >
                                {loadingIban && <Spinner absolute/>}
                            Vérifier l'IBAN du client
                            </span>

                        <ChangeTitulaire
                            souscripteur={`${contract.souscripteur.lastname} ${contract.souscripteur.firstname ?? ''}`}
                            changeValue={changeValue}/>
                        {loadingTerminate ? <Spinner/>
                            : <MyButton
                                variant='raised'
                                color='secondary'
                                size='large'
                                className={'btn btn-primary'}
                                disabled={loadingValidateRib}
                            >
                                {loadingValidateRib && <Spinner absolute/>}
                                Valider
                            </MyButton>
                        }
                        {(errorRib || errorTerminate) &&
                            <h2 className={'title-primary text-uppercase'}>Une erreur est survenue</h2>}
                    </form>
                </div>
            </div>
        </Fragment>
    );
};

export default ValidateRib;
