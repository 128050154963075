import {Field} from "redux-form";
import moment from "moment";
import RadioFieldRedux from "../../../../../Commun/Input/Radio/RadioFieldRedux";
import Spinner from "../../../../../Commun/Spinner/Spinner";
import MyButton from "../../../../../Commun/Button/Button";
import TextFieldRedux from "../../../../../Commun/Input/Text/TextFieldRedux";

const AnaFraude = (props) => {
    const {task: {title, description, date, type, paniere, tiers_id},handleSubmit, loadingTerminate, loadingFraude} = props

    return (
        <div className={'container-fluid'}>
            <div className={'row'}>
                <div className={'col text-center'}>
                    <h2 className={'title-primary text-uppercase'}>{paniere} - Tiers n°{tiers_id}</h2>
                    <p className={'subtitle'}>Tâche du {moment(date).format('L')}. Recu par {type}</p>
                </div>
            </div>
            <div className={'header-row row'}>
                <div className={'col-8'}>
                    <p className={'form-content text-center'}>
                        Type : {title}<br/>
                        Description : {description ? <span dangerouslySetInnerHTML={{__html: description}}/> : '-'}
                    </p>
                </div>
                <div className={'col-4'}>
                    <form className={`form-content`} onSubmit={handleSubmit}>
                        {paniere === 'ANA_FRAUDE' ?
                            <Field
                                name={`analyse_fraude`}
                                component={RadioFieldRedux}
                                label={''}
                                data={[{value: "non_avere_agenda", label: "Suspicion non avérée"}, {
                                    value: "remonter_compagnie",
                                    label: "Fraude à faire remonter à la compagnie"
                                }]}
                                required
                            /> : <Field
                                name={`analyse_fraude`}
                                component={RadioFieldRedux}
                                label={''}
                                data={[{value: "non_avere", label: "Suspicion non avérée"}, {
                                    value: "traiter_compagnie",
                                    label: "Fraude remontée à la compagnie"
                                }]}
                                required
                            />
                        }
                        <Field
                            name={'commentaire'}
                            component={TextFieldRedux}
                            label={'Commentaire'}
                        />

                        {loadingTerminate ? <Spinner/>
                            : <MyButton
                                variant='raised'
                                color='secondary'
                                size='large'
                                className={'btn btn-primary mt-5'}
                                disabled={loadingFraude}
                            >
                                {loadingFraude && <Spinner absolute/>}
                                Valider
                            </MyButton>
                        }
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AnaFraude;