import {SET_SIGNATURES} from '../../actions/app/signatures/signatures.actions'

export const signaturesReducer = (signatures = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_SIGNATURES) {
        return payload
    } else {
        return signatures
    }
}
