export const DOCUMENTS = '[Documents]'
export const DELETE_TASK_DOCUMENT = '[Delete Task Document]'
export const DELETE_DOCUMENT = '[Delete Document]'

export const GET_DOCUMENTS = `${DOCUMENTS} Get`
export const SET_DOCUMENTS = `${DOCUMENTS} Set`
export const PUT_DOCUMENTS = `${DOCUMENTS} Put`
export const POST_DELETE_TASK_DOCUMENT = `${DELETE_TASK_DOCUMENT} Post`
export const POST_DELETE_DOCUMENT = `${DELETE_DOCUMENT} Post`

export const getDocuments = ({query}) => ({
    type: GET_DOCUMENTS,
    payload: {
        data: query
    }
})

export const setDocuments = ({data}) => ({
    type: SET_DOCUMENTS,
    payload: {data},
    meta: {
        entity: DOCUMENTS
    }
})

export const putDocuments = ({form, query, tiers, risque, accident, formName}) => ({
    type: PUT_DOCUMENTS,
    payload: {
        data: query,
        body: {tiers, risque, accident, ...form},
        formName
    }
})

export const postDeleteTaskDocument = ({query}) => ({
    type: POST_DELETE_TASK_DOCUMENT,
    payload: {
        data: query,
    }
})

export const postDeleteDocument = ({query, tiers, risque, accident, codeatt}) => ({
    type: POST_DELETE_DOCUMENT,
    payload: {
        data: query,
        body: {tiers, risque, accident, codeatt}
    }
})
