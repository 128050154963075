import {connect} from 'react-redux'
import {compose} from "redux";
import {getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {updatePenaltiesAccidentGetLink,
} from "../../../../../../redux/selectors/links/links.selectors";
import {reduxForm} from "redux-form";
import {
    POST_ADD_PENALTIES,
    postAddPenalties
} from "../../../../../../redux/actions/app/accidents/accidents.actions";
import _ from "lodash";
import {getAccidentData} from "../../../../../../redux/selectors/accident/accident.selectors";
import FormAddPenalties from "./FormAddPenalties";

const mapStateToProps = (state, ownProps) => {
    const accident= getAccidentData(state);

    return {
        query: updatePenaltiesAccidentGetLink(state),
        contractId: ownProps.contractId,
        accidentId: ownProps.accidentId,
        loading: getLoadingEntity(state, POST_ADD_PENALTIES),
        initialValues: {
            'penalties' : accident.penalties
        }
    }
}

const mapDispatchToProps = {
    postAddPenalties,
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {postAddPenalties} = dispatchProps
    const {query, contractId, accidentId} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: form => postAddPenalties({form, query: _.replace(_.replace(query, '{id}', contractId), '{ids}', accidentId)})
    }
}

const FormAddPenaltiesContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'penalty'
    })
)(FormAddPenalties)

export default FormAddPenaltiesContainer