import React, {Component, Fragment} from 'react';
import {Field} from 'redux-form'
import _ from 'lodash'
import {getCompagnys} from "../../../../../../../../utils/function";
import TextFieldRedux from "../../../../../../../Commun/Input/Text/TextFieldRedux";
import RadioFieldRedux from "../../../../../../../Commun/Input/Radio/RadioFieldRedux";
import PostalCodeCity from "../../../../../../../Commun/PostalCodeCity/PostalCodeCity";
import FilteredAutocomplete from "../../../../../../../Commun/Forms/FilteredAutocomplete/FilteredAutocomplete";
import {codeCompagnys} from "../../../../../../../../utils/optionsSelect";

class Adversaires extends Component {
    constructor(props) {
        super(props)
        this.state = {
            compagnys: []
        }
    }

    componentDidMount() {
        this.fetchCompagnys()
    }

    async fetchCompagnys() {
        let compagnys = await getCompagnys()

        const listcompanys = compagnys.map(c => (
            {
                value: c.id,
                codegta: c.codegta,
                label: c.id + ' - ' + c.name + ' - ' + c.adresse + ' - ' + c.codePostal + ' ' + c.ville
            }
        ))

        this.setState({
            compagnys: listcompanys
        })
    }

    renderAdversairesFields(nbAdversaires) {
        let adversairesFields = [];
        let index = 1

        if (Number(nbAdversaires) > 1){
            index = 2
        }

        for (let i = 0; i < index; i++) {
            adversairesFields.push(
                <div className="col-6" style={{marginBottom: 30}}>
                    <div className={`form-content h-100`}>
                        <h1 className={'title-primary'}>Informations de l'adversaire {i + 1}</h1>

                        <Field
                            name={`adversaires.${i}.immat`}
                            component={TextFieldRedux}
                            label='Immatriculation'
                        />

                        <Field
                            name={`adversaires.${i}.police`}
                            component={TextFieldRedux}
                            label="Numéro de contrat d'assurance"
                        />

                        <FilteredAutocomplete
                            name={`adversaires.${i}.cie`}
                            label="Compagnie d'assurance"
                            labelOtherValue="Autre compagnie"
                            dataFiltered={_.filter(this.state.compagnys, c => _.includes(codeCompagnys, c.value))}
                            data={this.state.compagnys}
                            onChange={(value) => {
                                this.props.changeValue('codegta', _.find(this.state.compagnys, ['value', value])?.codegta)
                            }}
                            changeValue={this.props.changeValue}
                            required
                        />

                        <Field
                            name={`adversaires.${i}.titre`}
                            component={RadioFieldRedux}
                            label={'Civilité'}
                            data={[
                                {value: "M", label: "Monsieur"},
                                {value: "Mme", label: "Madame"},
                                {value: "Societe", label: "Société"}
                            ]}
                            required
                        />

                        <Field
                            name={`adversaires.${i}.nom`}
                            component={TextFieldRedux}
                            normalize={v => v.toUpperCase()}
                            label="Nom"
                        />

                        {(_.get(this.props.data, `adversaires.${i}.titre`) === 'M' || _.get(this.props.data, `adversaires.${i}.titre`) === 'Mme') &&
                            <Field
                                name={`adversaires.${i}.prenom`}
                                component={TextFieldRedux}
                                normalize={v => v.toUpperCase()}
                                label="Prénom"
                            />
                        }

                        <Field
                            name={`adverse_${i}_adresse`}
                            component={RadioFieldRedux}
                            label={'Avez-vous son adresse ?'}
                            data={[
                                {value: "T", label: "Oui"},
                                {value: "F", label: "Non"}
                            ]}
                        />

                        {_.get(this.props.data, `adverse_${i}_adresse`) === 'T' && <Fragment>
                            <Field
                                name={`adversaires.${i}.adresse1`}
                                component={TextFieldRedux}
                                label="Adresse"
                            />

                            <PostalCodeCity
                                nameCodePostal={`adversaires.${i}.cp`}
                                nameVille={`adversaires.${i}.ville`}
                                label={'Ville de résidence'}
                                changeValue={this.props.changeValue}
                            />

                        </Fragment>
                        }

                        <div className="alert alert-warning" role="alert" style={{margin: '40px 0 20px 0'}}>
                            Attention, pour finaliser l'ouverture du sinistre, vous devez renseigner la compagnie, le
                            nom et
                            l'immatriculation du Tiers adverse.<br/><br/>Si vous n'avez pas l'ensemble de ces
                            informations,
                            le sinistre s'importera en situation "En attente"
                        </div>
                    </div>
                </div>
            );
        }

        return adversairesFields;
    }

    render() {
        const {nbAdversaires = 1} = this.props

        return this.renderAdversairesFields(nbAdversaires)
    }
};

export default Adversaires;
