import {createSelector} from 'reselect'
import _ from 'lodash'
import {reformatPenaliteIndem, reformatPosteIndem} from "../../../utils/valueMatching";

const reglementDetailSelector = state => state.reglement_detail

export const getReglementDetailData = createSelector(
    reglementDetailSelector,
    reglement => reglement
)

export const getReglementDetailTypeRegCode = createSelector(
    getReglementDetailData,
    reglement => reglement.typeReglementCode
)

export const getPostesIndemnisationRegles = createSelector(
    getReglementDetailData,
    reglement => {
        const indemReglable = [
            'majorationValeurExpertise',
            'casque',
            'accessoires10',
            'accessoires20',
            'accessoires',
            'objetPerso',
            'remorquage',
            'equipement',
            'moyenProtection',
            'franchise'
        ];

        let newArray = [];


        _.forEach(reglement, function (value, key) {
            if(indemReglable.includes(key) && Number(value) > 0){
                newArray = [
                    ...newArray,
                    reformatPosteIndem(key)
                ]
            }
        });
        return newArray;
    }
)

export const getPenalitesRegles = createSelector(
    getReglementDetailData,
    reglement => {
        const indemReglable = [
            'franchise',
            'absenceAntivol',
            'absenceGravage',
            'absenceRemisage',
            'conduiteExclu',
        ];

        let newArray = [];


        _.forEach(reglement, function (value, key) {
            if(indemReglable.includes(key) && Number(value) > 0){
                newArray = [
                    ...newArray,
                    reformatPenaliteIndem(key)
                ]
            }
        });
        return newArray;
    }
)
