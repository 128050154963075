import React from 'react';
import moment from 'moment';

const Feedback = (props) => {
    const { feedbacks, tiers } = props;

    return (
        <div className="bloc-info bloc-satisfaction">
            <h2>Satisfaction client</h2>
            <div className="bloc-info h-auto">
                <div className="content row">
                    <div className="col-5 left-content">
                        <p>Dernier contact client :</p>
                    </div>
                    <div className="col-7 right-content">
                        <p>
                            {tiers.dernier_contact && tiers.dernier_contact.type
                                ? `${tiers.dernier_contact.type} du ${moment(tiers.dernier_contact.date).format('L')}`
                                : '-'}
                        </p>
                    </div>
                </div>
            </div>
            {feedbacks && feedbacks.map((feedback, index) => (
                <div className="h-auto my-3" key={index}>
                    <div className="content row">
                        {(feedback.service == null) ?
                            <>
                                <div className="col-7 left-content">
                                    <p>
                                        Moment de vie <u>{feedback.sinistre ? 'Indemnisation' : 'Vie de contrat'}</u> du {moment(feedback.feedback_date).format('L')} :
                                    </p>
                                </div>
                                <div className="col-5 right-content">
                                    <p>{feedback.note}/10</p>
                                </div>
                            </>
                            :
                            <>
                                <div className="col-7 left-content">
                                    <p>
                                    Feedback à chaud du {moment(feedback.feedback_date).format('L')} :
                                    </p>
                                </div>
                                <div className="col-5 right-content">
                                    <p>{feedback.note}/10</p>
                                </div>
                            </>
                        }
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Feedback;
