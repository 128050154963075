import {SET_VIREMENTS, SET_VIREMENTS_PROD} from '../../actions/app/virements/virements.actions'

export const virementsReducer = (virements = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_VIREMENTS || action.type === SET_VIREMENTS_PROD) {
        return payload
    } else {
        return virements
    }
}
