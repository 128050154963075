import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, formValueSelector, reduxForm } from 'redux-form';
import {
    FLOTTE,
    getFlotte,
    postDeleteVehicles,
    POST_DELETE_VEHICLES,
} from '../../../../../redux/actions/app/flotte/flotte.actions';
import { getFlotteActiveVehiculesData } from '../../../../../redux/selectors/flotte/flotte.selectors';
import { getFleetLink, getFleetVehiclesDeleteLink } from '../../../../../redux/selectors/links/links.selectors';
import { getLoadingEntity } from '../../../../../redux/selectors/ui/ui.selectors';
import ErrorHttpComponent from '../../../../Commun/Error/ErrorHttpComponent';
import loader from '../../../../Commun/Spinner/Loader/Loader';
import DeleteVehiculesFlotte from './DeleteVehiculesFlotte';

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector('delete_vehicules');

    return {
        loading: getLoadingEntity(state, POST_DELETE_VEHICLES),
        loaded: getLoadingEntity(state, FLOTTE) === false,
        uri: getFleetLink(state),
        data: getFlotteActiveVehiculesData(state) || {},
        contractId: ownProps.match.params.id,
        selected_vehicules: selector(state, 'selected_vehicules'),
        uri_delete: getFleetVehiclesDeleteLink(state),
    };
};

const mapDispatchToProps = {
    getFlotte,
    changeValue: (field, value) => change('delete_vehicules', field, value),
    postDeleteVehicles,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { getFlotte, postDeleteVehicles } = dispatchProps;
    const { uri, contractId, uri_delete } = stateProps;

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        load: () => getFlotte({ query: uri, sort: '', contrat: contractId, immat: '', active: '' }),
        onSubmit: (form) => {
            form.contract_id = contractId;
            postDeleteVehicles({ form, query: uri_delete });
        },
    };
};

const DeleteVehiculesFlotteContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'delete_vehicules',
    }),
    loader({ ErrorIndicator: ErrorHttpComponent }),
)(DeleteVehiculesFlotte);

export default DeleteVehiculesFlotteContainer;
