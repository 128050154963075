
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {CAS_IDA, POST_CAS_IDA, setCasIda} from "../../../actions/app/cas_ida/cas_ida.actions";

export const casIdaMiddleware = () => next => action => {
    next(action)

    const {payload} = action
    let message = null

    switch (action.type) {
        case POST_CAS_IDA:
            next([
                apiRequest({
                    body: (action.payload.body),
                    method: 'POST',
                    url: action.payload.data,
                    entity: CAS_IDA
                }),
                setLoader({state: true, entity: CAS_IDA})
            ])
            break

        case `${CAS_IDA} ${API_SUCCESS}`:
            next([
                setCasIda({data: action.payload.data.data}),
                setLoader({state: false, entity: CAS_IDA}),
                setError({state: false, entity: CAS_IDA}),
            ])
            break

        case `${CAS_IDA} ${API_ERROR}`:
            message = `
                <p>Votre demande n'a pas pu être envoyée. ${payload.data.response.data?.message ?? ''}</p>
            `
            next([
                setNotification({entity: CAS_IDA, body: message, type: 'error', title: 'Erreur', timeout: '0'}),
                setError({state: true, entity: CAS_IDA}),
                setLoader({state: false, entity: CAS_IDA}),
            ])
            break

        default:
            break
    }
    return null
}
