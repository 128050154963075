import moment from "moment";
import {Link} from "react-router-dom"
import {statutDevis} from "@/utils/valueMatching";
import {redirectInNewWindow} from "@/utils/function";
import {openInWindow} from "@/constants/constants";

const AutresDevis = (props) => {
    const { devis, currentId } = props

    const filteredDevis = devis.filter((d) => d.id !== currentId);

    return (
        <div className={'bloc-info bloc-contracts'}>
            <h2>Autres devis de ce tiers</h2>
            <div className={'content row'}>
                {filteredDevis && filteredDevis.length >= 1 ? (
                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">Crée le</th>
                            <th scope="col">Numéro de devis</th>
                            <th scope="col">Véhicule</th>
                            <th scope="col">Statut</th>
                            <th scope="col">Acte de gestion</th>
                        </tr>
                        </thead>
                        <tbody>
                        {filteredDevis.map((d) => (
                            <tr key={d.id}>
                                <td>{moment(d.created_at).format('L')}</td>
                                <td>{d.id}</td>
                                <td>{d.vehicule.marque} {d.vehicule.modele}</td>
                                <td>
                                    <strong className={`text-${statutDevis(d.statut)?.color}`}>
                                        {statutDevis(d.statut)?.label}
                                    </strong>
                                </td>
                                <td>{d.acteGestion === 'AN' ? 'Affaire nouvelle' : 'Avenant'}</td>
                                <td>
                                    <Link
                                        className={'btn btn-more'}
                                        onClick={(e) => redirectInNewWindow({
                                            e,
                                            url: `/devis/${d.id}`,
                                            name: openInWindow.devis
                                        })}
                                    />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                ) : (
                    <p className={'no-result'}>Le tiers n'a pas d'autres devis</p>
                )}
            </div>
        </div>
    );
};

export default AutresDevis;