const validate = values => {
    const errors = {}
    if (values.reassign) {
        const requiredFields = [
            'commentaire'
        ]
        requiredFields.forEach(field => {
            if (!values[field]) {
                errors[field] = 'Ce champ est obligatoire'
            }
        })
    } else {
        if (!values.refuse) {
            const requiredFields = [
                'motif',
                'date'
            ]

            if (values.motif === "K" || values.motif === "F") {
                requiredFields.push('motif_description');
            }

            requiredFields.forEach(field => {
                if (!values[field]) {
                    errors[field] = 'Ce champ est obligatoire'
                }
                if (values.motif === '0' && !values.insurer) {
                    errors.insurer = 'Ce champ est obligatoire'
                }
            })
        }
    }

    return errors
}

export default validate
