import moment from 'moment';
import { Fragment, useRef } from 'react';
import { Field } from 'redux-form';
import APIFrame from '../../../../../Commun/APILink/APIFrame';
import APIImage from '../../../../../Commun/APILink/APIImage';
import CheckboxFieldRedux from '../../../../../Commun/Input/Checkbox/CheckboxFieldRedux';
import SelectFieldRedux from '../../../../../Commun/Input/Select/SelectFieldRedux';
import Spinner from "../../../../../Commun/Spinner/Spinner";
import MyButton from "../../../../../Commun/Button/Button";

const listMotifFluxMail = [
    { value: 'DDE_FID', label: 'Geste commercial ou révision du tarif' },
    { value: 'INFO_PMQ', label: 'Information sur les pièces manquantes' },
    { value: 'INFO_COTI', label: 'Information tarif / remboursement / prime' },
    { value: 'DDE_MAIL', label: 'Information générale' },
    { value: 'RECLA', label: 'Insatisfaction / réclamation client' },
    { value: 'DDE_AV', label: 'Modification du contrat' },
    { value: 'RES', label: 'Résiliation' },
];

const FluxMail = (props) => {
    const {
        task: { date, type, tiers_id },
        files,
        formValues,
        changeValue,
        handleSubmit, loading,
    } = props;

    const refImg = useRef(null);

    return (
        <Fragment>
            <div className={'row'}>
                <div className={'col text-center'}>
                    <h2 className={'title-primary text-uppercase'}>Tâche DDE_MAIL - Tiers n°{tiers_id}</h2>
                    <p className={'subtitle'}>
                        Tâche du {moment(date).format('L')}. Recu par {type}
                    </p>
                </div>
            </div>
            <div className={'header-row row'}>
                {files !== undefined &&
                    files.map((file, index) =>
                        file.extension.toLowerCase() === 'pdf' ? (
                            <div className={'col-8'} style={{ marginBottom: 30 }} key={index}>
                                <APIFrame
                                    src={`${file.url}`}
                                    title={`${file.code}`}
                                    frameBorder="0"
                                    style={{ height: '100%', width: '100%', marginBottom: 30 }}
                                />
                            </div>
                        ) : (
                            <div className={'col-7 task-img-container'} key={index}>
                                <APIImage src={`${file.url}`} ref={refImg} alt={'Document'} />
                            </div>
                        ),
                    )}
                <form
                    className={`col-md-4 form-content ${files !== undefined ? '' : 'offset-md-4'}`}
                    onSubmit={handleSubmit}
                >
                    <Field
                        name="mail_empty"
                        component={CheckboxFieldRedux}
                        label="Le mail est vide ou sans message particulier : clôturer la tâche"
                        onChange={(e) => {
                            changeValue('mail_empty', e.target.checked);
                            changeValue('mail_derogation', null);
                            changeValue('paniere', null);
                        }}
                        required
                    />
                    <Field
                        name="mail_derogation"
                        component={CheckboxFieldRedux}
                        label="Je traite la demande du client (post-it, édition)"
                        onChange={(e) => {
                            changeValue('mail_derogation', e.target.checked);
                            changeValue('mail_empty', null);
                            changeValue('paniere', null);
                        }}
                        required
                    />

                    <Field
                        name={'paniere'}
                        component={SelectFieldRedux}
                        label={'Pour une demande client, sélectionner un motif :'}
                        onChange={(e) => {
                            console.log(e);
                            changeValue('paniere', e.target.value);
                            changeValue('mail_empty', null);
                            changeValue('mail_derogation', null);
                        }}
                        required
                    >
                        <option value={null}>Sélectionner un motif</option>
                        {listMotifFluxMail.map((motif, index) => (
                            <option key={index} value={motif.value}>
                                {motif.label}
                            </option>
                        ))}
                    </Field>

                    <MyButton
                        variant="raised"
                        color="secondary"
                        size="large"
                        className={'btn btn-primary mt-5'}
                        disabled={
                            loading ||
                            (!formValues?.mail_empty && !formValues?.mail_derogation && !formValues.paniere)
                        }
                    >
                        {loading && <Spinner absolute />}
                        Valider & clôturer
                    </MyButton>
                </form>
            </div>
        </Fragment>
    );
};

export default FluxMail;
