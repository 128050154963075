import {SET_COMMERCIAUX} from '../../actions/app/commerciaux/commerciaux.actions'

export const commerciauxReducer = (commerciaux = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_COMMERCIAUX) {
        return payload.data
    } else {
        return commerciaux
    }
}
