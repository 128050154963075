import {connect} from 'react-redux'
import {compose} from "redux";
import AddPenalties from "./AddPenalties";
import {getErrorEntity, getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {
    accidentGetLink,
} from "../../../../../redux/selectors/links/links.selectors";
import {reduxForm} from "redux-form";
import {
    ACCIDENT,
    getAccident,
} from "../../../../../redux/actions/app/accidents/accidents.actions";
import _ from "lodash";

import ErrorHttpComponent from "../../../../Commun/Error/ErrorHttpComponent";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    return {
        query_accident: accidentGetLink(state),
        contractId: ownProps.match.params.id,
        accidentId: ownProps.match.params.ids,
        loaded: getLoadingEntity(state, ACCIDENT) === false,
        error: getErrorEntity(state, ACCIDENT)
    }
}

const mapDispatchToProps = {
    getAccident,
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getAccident} = dispatchProps
    const {query_accident, contractId, accidentId} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getAccident({
            query: _.replace(_.replace(query_accident, '{id}', contractId), '{ids}', accidentId),
            id: accidentId
        }),
    }
}

const AddPenaltiesContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(AddPenalties)

export default AddPenaltiesContainer