import React from 'react';
import GrcCard from "../Card/GrcCard";
import CallToAction from "../Button/CallToAction/CallToAction";
import {redirectInNewWindow} from "../../../utils/function";
import {openInWindow} from "../../../constants/constants";
import NavigationActivityContratsContainer from "./NavigationActivityContrats/NavigationActivityContratsContainer";
import NavigationActivityContainer from "./NavigationActivity/NavigationActivityContainer";
import ActivitiesListAccidentFilters from "./ActivitiesListAccidentFilters/ActivitiesListAccidentFilters";
import ActivitiesListContractFilters from "./ActivitiesListContractFilters/ActivitiesListContractFilters";

const ActivitiesList = (props) => {
    const {
        uriClose,
        uriActivity,
        postCloseActivity,
        logAction,
        activities,
        type,
        tiers,
        accident,
        contract,
        setLocalStorage,
        roles,
        currentId,
        getActivities
    } = props

    return (
        <>
            <div className={'container'}>
                {type === 'contrat' &&
                    <NavigationActivityContratsContainer tiers={contract.souscripteur.id} current={contract.id}/>
                }
            </div>
            <div className={'container-fluid'}>
                <div className={'row'}>
                    <div className={'col-12 col-md-3 order-2 order-md-1'}>
                        <NavigationActivityContainer selected={{'id': currentId, 'type': type}}
                                                     tiers_id={tiers.belair_id ? tiers.belair_id : (contract && contract.souscripteur ? contract.souscripteur.id : null)}/>
                    </div>
                    <div className={'col-12 col-md-7 order-1 order-md-2'}>
                        <div>
                            <h2 className={'title-suivi'}>Suivi relation client</h2>

                            {type !== 'reclamation' &&
                                <div className={'mt-3 mb-3'}>
                                    {type === 'contrat' &&
                                        <>
                                            <CallToAction
                                                action={'add-chrono'}
                                                toolText={'Ajouter un chrono / agenda'}
                                                onClick={(e) => redirectInNewWindow({
                                                    e,
                                                    url: `/contrat/${contract.id}/ajout-suivi`,
                                                    name: openInWindow.addActivities
                                                })}
                                            />

                                            <ActivitiesListContractFilters uriActivity={uriActivity} type={type}
                                                                           getActivities={getActivities}/>
                                        </>
                                    }
                                    {type === 'accident' &&
                                        <>
                                            <div>
                                                <CallToAction
                                                    action={'add-chrono'}
                                                    toolText={'Ajouter un chrono / agenda'}
                                                    onClick={(e) => redirectInNewWindow({
                                                        e,
                                                        url: `/contrat/${contract.id}/sinistre/${accident.id}/ajout-suivi`,
                                                        name: openInWindow.addActivities
                                                    })}
                                                />
                                            </div>

                                            <ActivitiesListAccidentFilters uriActivity={uriActivity} type={type}
                                                                           getActivities={getActivities}/>
                                        </>
                                    }
                                    {type === 'tiers' && <CallToAction
                                        action={'add-chrono'}
                                        toolText={'Ajouter un chrono / agenda'}
                                        onClick={(e) => redirectInNewWindow({
                                            e,
                                            url: `/tiers/${tiers.belair_id}/ajout-suivi`,
                                            name: openInWindow.addActivities
                                        })}
                                    />}
                                </div>
                            }
                        </div>
                        <div>
                            <div>
                                {activities.map((activity, index) =>
                                    <GrcCard postCloseActivity={postCloseActivity} uriClose={uriClose}
                                             logAction={logAction} activity={activity} key={index} index={index}
                                             setLocalStorage={setLocalStorage} roles={roles}/>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ActivitiesList;
