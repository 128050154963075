import React, { useState, useEffect } from 'react';
import TasksContainer from './TasksList/TasksContainer';
import { courtiersPremium, typeAgenda } from '../../../../utils/optionsSelect';
import moment from 'moment';
import { getBoolean } from '../../../../utils/function';
import TraitementCheckdocContainer from '../TraitementCheckdoc/TraitementCheckdocContainer';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';

const TasksContent = (props) => {
    const {
        uri,
        setLoadingContractTrue,
        getTasks,
        meta,
        panieres,
        roles
    } = props;

    const [query, setQuery] = useState(uri);
    const [showMultiple, setShowMultiple] = useState(getBoolean(localStorage.getItem('multiple')));
    const [showAgendas, setShowAgendas] = useState(getBoolean(localStorage.getItem('agendas')));
    const [startDate, setStartDate] = useState(localStorage.getItem('date_start_create_contract') ? moment(localStorage.getItem('date_start_create_contract'), 'DD/MM/YYYY') : null);
    const [endDate, setEndDate] = useState(localStorage.getItem('date_end_create_contract') ? moment(localStorage.getItem('date_end_create_contract'), 'DD/MM/YYYY') : null);
    const [startDatePopUp, setStartDatePopUp] = useState(localStorage.getItem('date_start_create_popup_range') ? moment(localStorage.getItem('date_start_create_popup_range'), 'DD/MM/YYYY') : null);
    const [endDatePopUp, setEndDatePopUp] = useState(localStorage.getItem('date_end_create_popup_range') ? moment(localStorage.getItem('date_end_create_popup_range'), 'DD/MM/YYYY') : null);
    const [taskDate, setTaskDate] = useState(localStorage.getItem('date_create_task') ? moment(localStorage.getItem('date_create_task'), 'DD/MM/YYYY') : null);

    const minDate = moment('01/01/2020', 'DD/MM/YYYY');

    useEffect(() => {
        setLoadingContractTrue();
    }, [setLoadingContractTrue]);

    const handleChangeAgenda = () => {
        const newShowAgendas = !showAgendas;
        setShowAgendas(newShowAgendas);
        setStartDatePopUp(null);
        setEndDatePopUp('');
        localStorage.setItem('agendas', newShowAgendas);
        localStorage.setItem('date_end_create_popup_range', '');
        localStorage.setItem('date_start_create_popup_range', '');
        localStorage.setItem('type_agenda', '');
        getTasks({
            query,
            sort: localStorage.getItem('sort'),
            agendas: localStorage.getItem('agendas') || ''
        });
    };

    const handleChangeMulti = () => {
        const newShowMultiple = !showMultiple;
        setShowMultiple(newShowMultiple);
        localStorage.setItem('multiple', newShowMultiple);
        getTasks({
            query,
            sort: localStorage.getItem('sort'),
            multiple: localStorage.getItem('multiple') || ''
        });
    };

    const handleChangeSort = (e) => {
        localStorage.setItem('sort', e.target.value);
        getTasks({
            query,
            sort: localStorage.getItem('sort')
        });
    };

    const handleChangeFilter = (e) => {
        const filter = e.target.name.substring(7);
        localStorage.setItem(filter, e.target.value);
        getTasks({
            query,
            sort: localStorage.getItem('sort'),
            [filter]: localStorage.getItem(filter)
        });
    };

    const handleChangeDates = (start, end, type) => {
        const formattedEnd = end ? moment(end).format('L') : moment().format('L');
        localStorage.setItem(`date_end_${type}`, formattedEnd);
        localStorage.setItem(`date_start_${type}`, moment(start).format('L'));

        if (type === 'create_contract') {
            setStartDate(start);
            setEndDate(end);
            getTasks({
                query,
                sort: localStorage.getItem('sort'),
                date_start: localStorage.getItem(`date_start_${type}`) || '',
                date_end: localStorage.getItem(`date_end_${type}`) || ''
            });
        } else if (type === 'create_popup_range') {
            localStorage.setItem('date_create_task', '');
            setStartDatePopUp(start);
            setEndDatePopUp(end);
            setTaskDate(null);
            getTasks({
                query,
                sort: localStorage.getItem('sort'),
                date_start_create_popup_range: localStorage.getItem(`date_start_${type}`) || '',
                date_end_create_popup_range: localStorage.getItem(`date_end_${type}`) || ''
            });
        }
    };

    const handleChangeDate = (date, type) => {
        localStorage.setItem(`date_${type}`, moment(date).format('L'));

        if (type === 'create_task') {
            localStorage.setItem('date_end_create_popup_range', '');
            localStorage.setItem('date_start_create_popup_range', '');
            setTaskDate(date);
            setStartDatePopUp(null);
            setEndDatePopUp(null);
            getTasks({
                query,
                sort: localStorage.getItem('sort'),
                date_create_popup_range: localStorage.getItem(`date_${type}`) || ''
            });
        }
    };

    const resetFilters = () => {
        const filtersToReset = [
            'date_end_create_contract', 'date_start_create_contract',
            'date_end_create_popup_range', 'date_start_create_popup_range',
            'date_create_task', 'contrat_situation', 'paniere',
            'type_agenda', 'portefeuille', 'produit', 'courtier', 'resil_date'
        ];
        filtersToReset.forEach(filter => localStorage.setItem(filter, ''));

        localStorage.setItem('produit', '-WAKAM_LIV');

        setStartDate(null);
        setEndDate(null);
        setStartDatePopUp(null);
        setEndDatePopUp(null);
        setTaskDate(null);

        getTasks({
            query,
            sort: localStorage.getItem('sort'),
            date_start: '',
            date_end: ''
        });
    };

    return (
        <div className='tasks-container container'>
            <div className='row'>
                <div className='col-12'>
                    <div className='d-flex' style={{ gap: '12px', marginBottom: '20px' }}>
                        <NavLink to='/tiers/create' className='btn btn-primary btn-small' style={{ whiteSpace: 'nowrap' }}>
                            Créer un tiers
                        </NavLink>
                        {_.includes(roles, 'manager qualite') && <TraitementCheckdocContainer />}
                    </div>
                    <h1 className='title-primary title-task'>Mes tâches assignées</h1>
                    {meta.total > 0 && <small className='bold text-uppercase'>{meta.total} tâches</small>}
                    <div className='styled-checkbox float-right'>
                        <input
                            type='checkbox'
                            name='filter_agenda'
                            id='agenda'
                            onChange={handleChangeAgenda}
                            value={showAgendas}
                            defaultChecked={showAgendas}
                        />
                        <label htmlFor='agenda'>Afficher les agendas ?</label>
                        <input
                            type='checkbox'
                            name='filter_multiple'
                            id='multiple'
                            onChange={handleChangeMulti}
                            value={showMultiple}
                            defaultChecked={showMultiple}
                        />
                        <label htmlFor='multiple'>Afficher les contrats avec plusieurs tâches ?</label>
                    </div>
                </div>
                <div className='col-12 filters'>
                    <div className='content'>
                        <div className='mb-3'>
                            <small className='label'>Filtre par AFN</small>
                            <small className='label-right' onClick={resetFilters}>Réinitialiser les filtres</small>
                        </div>
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='fr'>
                            <DatePicker
                                className='mr-1 small date-picker'
                                label='Date de début'
                                value={startDate}
                                onChange={(newValue) => handleChangeDates(newValue, endDate, 'create_contract')}
                                renderInput={(params) => <TextField {...params} />}
                                minDate={minDate}
                            />
                        </LocalizationProvider>

                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='fr'>
                            <DatePicker
                                className='mr-1 small date-picker'
                                label='Date de fin'
                                value={endDate}
                                onChange={(newValue) => handleChangeDates(startDate, newValue, 'create_contract')}
                                renderInput={(params) => <TextField {...params} />}
                                minDate={startDate}
                            />
                        </LocalizationProvider>

                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='fr'>
                            <DatePicker
                                className='mr-1 small date-picker'
                                label='Date de la tâche'
                                value={taskDate}
                                onChange={(newValue) => handleChangeDate(newValue, 'create_task')}
                                renderInput={(params) => <TextField {...params} />}
                                minDate={startDate}
                            />
                        </LocalizationProvider>

                        <select
                            name='filter_courtier'
                            onChange={handleChangeFilter}
                            className='small-select select-sort-courtier custom-select'
                            value={localStorage.getItem('courtier')}
                        >
                            <option value=''>Courtier premium</option>
                            {courtiersPremium.map((courtier, index) => (
                                <option key={index} value={courtier.code}>{courtier.libelle}</option>
                            ))}
                        </select>

                        <select
                            name='filter_contrat_situation'
                            onChange={handleChangeFilter}
                            className='small-select select-filter-tasks custom-select'
                            value={localStorage.getItem('contrat_situation')}
                        >
                            <option value=''>Situation du contrat</option>
                            <option value='1'>En cours</option>
                            <option value='2'>En établissement</option>
                            <option value='6'>Résilié</option>
                            <option value='5'>Suspendue</option>
                            <option value='I'>Résilié/Impayé</option>
                            <option value='A'>Avenant en attente</option>
                        </select>

                        {panieres.length > 0 && (
                            <select
                                name='filter_paniere'
                                onChange={handleChangeFilter}
                                className='small-select select-filter-tasks custom-select'
                                value={localStorage.getItem('paniere')}
                            >
                                <option value=''>Panière</option>
                                {panieres.map((paniere, index) => (
                                    <option key={index} value={paniere}>{paniere}</option>
                                ))}
                            </select>
                        )}

                        <select
                            name='filter_portefeuille'
                            onChange={handleChangeFilter}
                            className='small-select select-filter-tasks custom-select'
                            value={localStorage.getItem('portefeuille')}
                        >
                            <option value=''>Portefeuille</option>
                            <option value='AGG'>AGGRAVES</option>
                            <option value='AMA'>AMA</option>
                            <option value='BQC'>BQ_CASINO</option>
                            <option value='COM'>COMPARATEURS</option>
                            <option value='CON'>CONCESSIONNAIRE</option>
                            <option value='COP'>CONCESS_PREMIUM</option>
                            <option value='COU'>COURTIER</option>
                            <option value='DIR'>DIRECT</option>
                            <option value='DUC'>DUCATI</option>
                            <option value='GPV'>GPVL</option>
                            <option value='MAP'>MARCHE_PRO</option>
                            <option value='MCO'>AIMCO</option>
                            <option value='MVA'>MVAGUSTA</option>
                            <option value='PRE'>PREMIUM</option>
                        </select>

                        <select
                            name='filter_produit'
                            onChange={handleChangeFilter}
                            className='small-select select-filter-tasks custom-select'
                            value={localStorage.getItem('produit')}
                        >
                            <option value='-WAKAM_LIV'>Tous sauf Livraison</option>
                            <option value='7311_SC'>Cyclo</option>
                            <option value='7311_MOT1'>Moto & Scooter</option>
                            <option value='7311_PVE'>Moto Verte</option>
                            <option value='7311_PVK'>Quad</option>
                            <option value='7311_NVEI'>Trottinette</option>
                            <option value='7311_VAE'>Vélo</option>
                            <option value='WAKAM_LIV'>Livraison</option>
                        </select>

                        <select
                            name='filter_resil_date'
                            onChange={handleChangeFilter}
                            className='small-select select-filter-tasks custom-select'
                            value={localStorage.getItem('resil_date')}
                        >
                            <option value=''>Date de résiliation</option>
                            <option value='inferieure'>Inférieure à 14 mois</option>
                            <option value='superieure'>Supérieure à 14 mois</option>
                        </select>

                        <select
                            name='sort'
                            onChange={handleChangeSort}
                            className='small-select select-sort-tasks custom-select'
                            value={localStorage.getItem('sort')}
                        >
                            <option value='date'>Du + ancien au + récent</option>
                            <option value='-date'>Du + récent au + ancien</option>
                            <option value='paniere'>Panière A-Z</option>
                            <option value='-paniere'>Panière Z-A</option>
                        </select>
                    </div>
                    {showAgendas && (
                        <div className='content'>
                            <select
                                name='filter_type_agenda'
                                onChange={handleChangeFilter}
                                className='small-select select-filter-tasks custom-select'
                                value={localStorage.getItem('type_agenda')}
                            >
                                <option value=''>Type d'agenda</option>
                                {typeAgenda
                                    .sort((a, b) => a.libelle.localeCompare(b.libelle))
                                    .map((paniere, index) => (
                                        <option key={index} value={paniere.codeBelair}>{paniere.libelle}</option>
                                    ))
                                }
                            </select>
                        </div>
                    )}
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <TasksContainer className='loader' />
                </div>
            </div>
        </div>
    );
};

export default TasksContent;
