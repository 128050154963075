import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {getErrorEntity, getLoadingEntity} from '../../../../../../redux/selectors/ui/ui.selectors'
import ValidateDocuments from './ValidateDocuments'
import {validateDocumentGetLink} from '../../../../../../redux/selectors/links/links.selectors'
import {getTaskDetailData} from "../../../../../../redux/selectors/tasks/task.selectors";
import {reduxForm, formValueSelector, reset, change} from "redux-form";
import {VALIDATE_DOCUMENT} from "../../../../../../redux/actions/app/validate_documents/validate_documents.actions";
import {setValidateDocumentsPending} from "../../../../../../redux/actions/app/ui/ui.actions";
import validate from './validate'
import {getValidateDocumentsPending} from "../../../../../../redux/selectors/ui/ui.selectors";

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector(`validate_documents-${ownProps.index}`)

    return {
        taskDetail: getTaskDetailData(state),
        uri: validateDocumentGetLink(state),
        statut: selector(state, 'statut'),
        codeatt: selector(state, 'codeatt'),
        commentaire: selector(state, 'commentaire'),
        errorDocument: getErrorEntity(state, VALIDATE_DOCUMENT),
        stateValidateDocument: getValidateDocumentsPending(state),
        initialValues: {
            'codeatt': ownProps.file ? ownProps.file.code : null,
            'statut': ownProps.file ? ownProps.file.status : null
        },
        libelle: ownProps.file ? ownProps.file.libelle : '',
        form: `validate_documents-${ownProps.index}`,
        loading: getLoadingEntity(state, VALIDATE_DOCUMENT),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    postValid: ({form, index}) => setValidateDocumentsPending({form, index}),
    resetForm: (formName) => reset(formName),
    changeValue: (formName, field, value) => change(formName, field, value),
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {postValid} = dispatchProps
    const {form} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (formulaire) => postValid({
            index: form,
            form: formulaire
        })
    }
}

const ValidateDocumentsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        validate
    }),
)(ValidateDocuments)

export default ValidateDocumentsContainer
