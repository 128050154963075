import React from 'react';
import FormAddPenaltiesContainer from "./FormAddPenalties/FormAddPenaltiesContainer";

const AddPenalties = (props) => {
    const {history, contractId, accidentId} = props

    return (
        <div className={'contract-container container'}>
            <div className={'row return-content'}>
                <div className={'col'}>
                    <button onClick={() => history.go(-1)} className={'btn btn-return'}>Retour</button>
                </div>
            </div>
            <div className={'row header-row'}>
                <div className={'col-12'}>
                    <FormAddPenaltiesContainer contractId={contractId} accidentId={accidentId}/>
                </div>
            </div>
        </div>
    );
};

export default AddPenalties;