import {connect} from 'react-redux'
import {compose} from 'redux'

import ComptePaddock from "./ComptePaddock";
import {
    GET_TIERS_BU_MDP,
    getTiersBUMdp,
    getTiersBusinessUnit
} from "../../../../../../redux/actions/app/tiers/tiers.actions";
import {getUpdateUserBUPassword, getUsersBuLink} from "../../../../../../redux/selectors/links/links.selectors";
import {getTiersBelairId, getTiersComptes} from "../../../../../../redux/selectors/tiers/tiers.selectors";
import {getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import loader from "../../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    return {
        query: `${getUsersBuLink(state)}?&belair_id=${getTiersBelairId(state)}`,
        queryMDP: getUpdateUserBUPassword(state),
        comptes: getTiersComptes(state),
        loading: getLoadingEntity(state, GET_TIERS_BU_MDP)
    }
}

const mapDispatchToProps = {
    getTiersBusinessUnit,
    getTiersBUMdp
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {query} = stateProps
    const {getTiersBusinessUnit} = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getTiersBusinessUnit({query})
    }
}

const ComptePaddockContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(ComptePaddock)

export default ComptePaddockContainer
