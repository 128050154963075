import {SET_COMPANYS} from '../../actions/app/companys/companys.actions'

export const companysReducer = (companys = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_COMPANYS) {
        return payload.data
    } else {
        return companys
    }
}
