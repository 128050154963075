export const TypeDeSuivi = [
    {libelle: 'Appel entrant'},
    {libelle: 'Appel sortant'},
]

export const interlocuteurSelect = [
    {libelle: 'Courtier'},
    {libelle: 'Client'},
]

export const canalArrivee = [
    {libelle: 'AG Fid'},
    {libelle: 'AG espace'},
    {libelle: 'Mail'},
    {libelle: 'Tchat'},
    {libelle: 'Réseaux sociaux'},
]

export const casUsage = [
    {libelle: 'Révision tarifaire : devis APRIL Moto'},
    {libelle: 'Révision tarifaire : devis concurrentiel'},
    {libelle: 'Révision tarifaire : avis d\'échéance'},
    {libelle: 'Révision tarifaire : en cours d\'année'},
    {libelle: 'Demande résiliation échéance : hausse tarifaire'},
    {libelle: 'Demande RI hausse tarifaire'},
    {libelle: 'Autres'},
]

export const reponseFid = [
    {libelle: 'Acceptation'},
    {libelle: 'Refus'},
    {libelle: 'En réflexion'},
]