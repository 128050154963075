import React from 'react';
import './statistiques.scss'
import _ from 'lodash'
import moment from 'moment'

const Statistiques = (props) => {
    const {statistiques: {data: {data: {login_ko_aprilon, login_today, online}}}} = props

    const myStats = {
        login_ko_aprilon: {
            courtier: login_ko_aprilon.courtier ? login_ko_aprilon.courtier : 0,
            concessionnaire: login_ko_aprilon.concessionnaire ? login_ko_aprilon.concessionnaire : 0,
        },
        login_today: {
            courtier: login_today.courtier ? login_today.courtier : 0,
            concessionnaire: login_today.concessionnaire ? login_today.concessionnaire : 0,
        },
        online: {
            courtier: _.filter(online, ['role', 'courtier']).length,
            concessionnaire: _.filter(online, ['role', 'concessionnaire']).length,
        }
    }

    return (
        <div className="container">
            <div className={'row'} id={'statistiques'}>
                <div className={'col-lg stats'}>
                    <div className="row">
                        <div className="col title">
                        <span className="big">
                            {(myStats.online.courtier + myStats.online.concessionnaire) || 0}
                        </span>
                            <span className="subtitle">Connectés en direct</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col bloc">
                        <span className={'number'}>
                            {myStats.online.courtier}
                        </span>
                            <span className="description">Courtiers</span>
                        </div>
                        <div className="col bloc">
                        <span className={'number'}>
                            {myStats.online.concessionnaire}
                        </span>
                            <span className="description">Concessionnaires</span>
                        </div>
                    </div>
                </div>

                <div className={'col-lg stats'}>
                    <div className="row">
                        <div className="col title">
                        <span className="big">
                            {((myStats.login_today.courtier + myStats.login_today.concessionnaire) + (myStats.login_ko_aprilon.courtier + myStats.login_ko_aprilon.concessionnaire))}
                        </span>
                            <span className="subtitle">Connectés sur la journée</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col bloc">
                        <span className={'number'}>
                            {(myStats.login_today.courtier + myStats.login_today.concessionnaire) || 0}
                        </span>
                            <span className="description">Connexions OK</span>
                        </div>
                        <div className="col bloc">
                        <span className={'number'}>
                            {(myStats.login_ko_aprilon.courtier + myStats.login_ko_aprilon.concessionnaire)}
                        </span>
                            <span className="description">Connexions KO</span>
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            <div className="row mt-3">
                <div className="col">
                    <h3>Courtiers en direct :</h3>
                    {
                        _.orderBy(_.filter(online, ['role', 'courtier']), 'date', 'desc').map((courtier, i) => (
                            <p key={i}><b>{courtier.name}</b> à {moment(courtier.date).format('LTS')}</p>
                        ))
                    }
                </div>
                <div className="col">
                    <h3>Concessionnaires en direct :</h3>
                    {
                        _.orderBy(_.filter(online, ['role', 'concessionnaire']), 'date', 'desc').map((concessionnaire, i) => (
                            <p key={i}><b>{concessionnaire.name}</b> à {moment(concessionnaire.date).format('LTS')}</p>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

Statistiques.defaultProps = {
    statistiques: {
        data: {
            data: {
                login_ko_aprilon: {
                    courtier: 0,
                    concessionnaire: 0
                },
                login_today: {
                    courtier: 0,
                    concessionnaire: 0
                },
                online: {
                    courtier: 0,
                    concessionnaire: 0
                }
            }
        }
    }
}

export default Statistiques;
