import React from 'react'

const Promo = ({promo}) => {
    return (
        <div className={'bloc-info bloc-promo'}>
            <h2>Code Promo</h2>
            <div className={'content row'}>
                <div className={'col-5 left-content'}>
                    <p>Campagne :</p>
                    <p>Code promo :</p>
                    <p>Réduction :</p>
                </div>
                <div className={'col-7 right-content'}>
                    <p>{promo.campagne}</p>
                    <p>{promo.code}</p>
                    <p>{promo.reduc}</p>
                </div>
            </div>
        </div>
    )
}

export default Promo
