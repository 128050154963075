import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {reduxForm, formValueSelector} from 'redux-form'
import InformationsNaissance from "./InformationsNaissance";
import {
    getTiers,
    POST_UPDATE_BIRTH_INFOS_TIERS,
    postUpdateBirthInfosTiers, TIERS
} from "../../../../../redux/actions/app/tiers/tiers.actions";
import _ from "lodash";
import {changeBirthInfosTiersLink, tiersGetLink} from "../../../../../redux/selectors/links/links.selectors";
import {getTiersData} from "../../../../../redux/selectors/tiers/tiers.selectors";

import ErrorHttpComponent from "../../../../Commun/Error/ErrorHttpComponent";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {postAddEditNote} from "../../../../../redux/actions/app/notes/notes.actions";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector('changeInformationsNaissance')
    const tiers = getTiersData(state)

    return {
        tiers,
        tiersId: ownProps.match.params.id,
        uri: tiersGetLink(state),
        initialValues: {
            'pays': tiers.code_pays_naissance ?? null,
            'ville': tiers.ville_naissance ?? null,
            'nom': tiers.nom_naissance ?? null,
            'statut_juridique': tiers.statut_juridique ?? null,
            'siren_siret': tiers.num_siren_siret ?? null,
        },
        uriPost: changeBirthInfosTiersLink(state),
        loaded: getLoadingEntity(state, TIERS) === false,
        loading: getLoadingEntity(state, POST_UPDATE_BIRTH_INFOS_TIERS),
        societe: (getTiersData(state).titre && (getTiersData(state).titre === 'SOC' || (getTiersData(state).num_siren_siret && getTiersData(state).num_siren_siret !== "0")))  ?? false,
        pays: selector(state, 'pays'),
        ville: selector(state, 'ville')
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getTiers: ({query, id}) => getTiers({query, id}),
    submitPostBirthInfos: ({form, query}) => postUpdateBirthInfosTiers({form, query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getTiers, submitPostBirthInfos} = dispatchProps
    const {uri, tiersId, uriPost} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getTiers({query: _.replace(uri, '{id}', tiersId), id: tiersId}),
        onSubmit: (form) => submitPostBirthInfos({form, query: _.replace(uriPost, '{id}', tiersId)})
    }
}

const InformationsNaissanceContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent}),
    reduxForm({
        form: 'changeInformationsNaissance'
    }),
)(InformationsNaissance)

export default InformationsNaissanceContainer
