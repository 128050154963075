import React from 'react';

const ReclaSin = (props) => {
    const {task: {title, description}} = props

    return (
        <div className={'container'}>
            <div className={'header-row row'}>
                <div className={'col-12'}>
                    <div className={'form-content text-center'}>
                        <p>
                            Type : {title}<br/>
                            Description : {description ? <span dangerouslySetInnerHTML={{__html: description}}/> : '-'}
                        </p>
                        <p>La réclamation est à ouvrir via le formulaire de réclamation de l'espace conseiller.</p>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default ReclaSin;
