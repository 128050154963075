import {SET_GIFT_CARDS} from "../../actions/app/gift_cards/gift_cards.actions";

export const giftCardsReducer = (gift_cards = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_GIFT_CARDS) {
        return payload.data
    } else {
        return gift_cards
    }
}
