import React from 'react';
import _ from "lodash";
import TimeLine from "./TimeLine/TimeLine";
import {Link} from "react-router-dom";
import {redirectInNewWindow} from "../../../../../../utils/function";
import {openInWindow} from "../../../../../../constants/constants";

const DetailsAntecedentsBloc = (props) => {
    const {type, antecedentDeclare, antecedentsManquants, antecedents, contract_id, onSubmitDeletePeriode} = props

    const texte_duree = (periode) => {
        let texte = '';

        if(periode?.years > 0){
            texte = `${periode.years} année${periode.years > 1 ? 's' : ''}`
        }
        if(periode?.months > 0){
            texte = `${texte !== '' ? `${texte} et ` : ''}${periode.months} mois`
        }
        if(periode?.weeks > 0){
            texte = `${texte !== '' ? `${texte} et ` : ''}${periode.weeks} semaine${periode.weeks > 1 ? 's' : ''}`
        }
        if(periode?.days > 0){
            texte = `${texte !== '' ? `${texte} et ` : ''}${periode.days} jour${periode.days > 1 ? 's' : ''}`
        }

        if(texte !== ''){
            texte = `${texte} validé(e)s`
        }
        return texte;
    }

    return (
        <div className={'content row'}>
            <table className="table mb-4">
                <thead>
                <tr>
                    <th scope="col">Antécédents {_.upperFirst(type)} : {antecedentDeclare} mois déclarés</th>
                    <span className={'btn btn-cta btn-action float-right'} onClick={(e) => redirectInNewWindow({
                        e,
                        url: `/contrat/${contract_id}/ajout-antecedents/${type}`,
                        name: openInWindow.addActivities
                    })}>
                        Ajouter une période
                    </span>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {antecedents?.received_periods_per_doc?.length > 0 ? antecedents.received_periods_per_doc.map((periode, index) =>
                    <tr key={index}>
                        <td className={'font-weight-normal'}>{periode.start} au {periode.end} : {texte_duree(periode.duration)}</td>

                        <td>
                            <Link to={`/contrat/${contract_id}/edition-periode-antecedents/${periode.id}`}
                                  className={'btn btn-edit'}
                                  target={'_blank'}>&nbsp;</Link>
                        </td>
                        <td>
                            <button onClick={() => {
                                if (window.confirm(`Êtes-vous sûr(e) de vouloir supprimer cette période de RI ?`)) onSubmitDeletePeriode(periode.id)
                            }} className={'btn btn-delete-red'}/>
                        </td>
                    </tr>
                ) : <tr>
                    <td colSpan={2} className={'font-weight-normal'}>Aucune période reçue</td>
                </tr>
                }
                </tbody>
            </table>


            <div className={'w-100'}>
            {
                    antecedents?.received_periods?.length > 0 ?
                    <TimeLine
                        expectedPeriod={antecedents.requested_periods}
                        receivedPeriods={antecedents?.received_periods}
                        missingPeriods={antecedents.missing_periods_text ? antecedents?.missing_periods : []}
                        antecedentsManquants={antecedentsManquants}
                        /> : ''
                }
                {
                    (antecedentsManquants && antecedents.missing_periods_text) ?
                        <p className={'alert alert-warning'}>{antecedents.missing_periods_text}</p>
                        : ''
                }
                {
                    !antecedents.missing_periods_text ?
                        <p className={'alert alert-success'}>Tous les relevés d'informations ont été reçus</p>
                        : ''
                }
            </div>
        </div>
    );
};

export default DetailsAntecedentsBloc;