import React, {Component, Fragment} from 'react'
import MyButton from "../Button/Button";

export default class InfoSupCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            info: false,
        }
        this.toggleDetail = this.toggleDetail.bind(this);
    }


    toggleDetail() {
        this.setState({info: !this.state.info})
    }

    render() {
        const {info, show = this.state.info} = this.props

        return (
            <Fragment>
                <p className={'inline'}>
                    {info.raisonSociale}
                    <MyButton
                        className={'btn btn-rounded'}
                        onClick={this.toggleDetail}
                    >
                        {!show ? '+' : '-'}
                    </MyButton>
                </p>
                <div className={`${!show ? 'hidden' : ''}`}>
                    <p>{info.rue1}</p>
                    {info.rue2 && <p>{info.rue2}</p>}
                    <p>{info.codePostal} {info.ville}</p>
                    <p>{info.phone}</p>
                    <p>{info.email}</p>
                </div>
            </Fragment>
        )
    }
}
