import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import UpdateAccident from "./UpdateAccident";
import {accidentGetLink, contractDefaultGetLink} from "../../../../../redux/selectors/links/links.selectors";
import {getErrorEntity, getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {CONTRACT, getContract} from "../../../../../redux/actions/app/contracts/contracts.actions";
import {getAccidentData} from "../../../../../redux/selectors/accident/accident.selectors";
import {ACCIDENT, getAccident} from "../../../../../redux/actions/app/accidents/accidents.actions";
import _ from "lodash";

import {getContractData} from "../../../../../redux/selectors/contract/contract.selectors";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    return {
        idContract: ownProps.match.params.id,
        idAccident: ownProps.match.params.ids,
        accident: getAccidentData(state),
        contract: getContractData(state),
        uri: accidentGetLink(state),
        uri_contract: contractDefaultGetLink(state),
        loaded: getLoadingEntity(state, ACCIDENT) === false && getLoadingEntity(state, CONTRACT) === false,
        error: getErrorEntity(state, ACCIDENT) && getErrorEntity(state, CONTRACT) === false,
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    getAccident: ({query, id}) => getAccident({query, id}),
    getContract: (query) => getContract({query}),
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getAccident, getContract} = dispatchProps
    const {uri, uri_contract, idContract, idAccident} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => {
            getAccident({query: _.replace(_.replace(uri, '{id}', idContract), '{ids}', idAccident), id: idAccident})
            getContract(_.replace(uri_contract, '{id}', idContract))
        }
    }
}

const UpdateAccidentContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(UpdateAccident);

export default UpdateAccidentContainer
