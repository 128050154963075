import React, {Fragment, useEffect, useState} from 'react';
import {Field} from "redux-form";
import RadioFieldRedux from "../../../../../../../Commun/Input/Radio/RadioFieldRedux";
import SelectFieldRedux from "../../../../../../../Commun/Input/Select/SelectFieldRedux";
import './resp.scss'
import {getImgCasIda} from "./getImgCasIda";

const Responsabilite = (props) => {
    const {cas_ida, rc, accordCheck, cas_ida_calc, rc_calc, changeValue, defineManuel, codegta, categorie} = props

    const [casIda, setCasIda] = useState(null)
    const [responsabilite, setResponsabilite] = useState(null)
    const [disabled, setDisabled] = useState(false)

    const handleClick = () => {
        setCasIda(cas_ida_calc)
        setResponsabilite(rc_calc)

        changeValue('manuel_ida', cas_ida_calc)
        changeValue('manuel_resp', rc_calc)
        changeValue('accord_ida', 'T')
        if (defineManuel !== 'STATUER') {
            changeValue('define_ida', null)
        }
        setDisabled(true)
    }

    useEffect(() => {
        if (defineManuel !== 'T') {
            if (codegta !== undefined) {
                setCasIda(cas_ida_calc ?? cas_ida)
                setResponsabilite(rc_calc ?? rc)

                changeValue('manuel_ida', cas_ida_calc ?? cas_ida)
                changeValue('manuel_resp', rc_calc ?? rc)
            } else {
                let ida;
                if (rc === 100) {
                    setCasIda('HIZ')
                    ida = 'HIZ'
                } else if (rc === 75) {
                    setCasIda('HIW')
                    ida = 'HIW'
                } else if (rc === 50) {
                    setCasIda('HIY')
                    ida = 'HIY'
                } else if (rc === 25) {
                    setCasIda('HIV')
                    ida = 'HIV'
                } else if (rc === 0) {
                    setCasIda('HIX')
                    ida = 'HIX'
                }

                setResponsabilite(rc)
                changeValue('manuel_ida', ida)
                changeValue('manuel_resp', rc)
            }

            if (categorie === '104.2') {
                setCasIda('HIZ')
                setResponsabilite(100)

                changeValue('manuel_ida', 'HIZ')
                changeValue('manuel_resp', 100)
            }
        }
    }, [rc])

    return (
        <Fragment>
            {((casIda !== null && casIda !== undefined) && casIda !== '50/51' && (responsabilite !== null && responsabilite !== undefined)) &&
                <div>
                    <div className="alert alert-warning" role="alert" style={{marginTop: 20}}>
                        <p>Attention ! Cette proposition n'est pas exhaustive. Vérifier que le croquis et les
                            commentaires correspondent.</p>
                    </div>
                    <div className={'card-ida'}>
                        <h1 className={'title-primary'}>Cas IDA calculé :</h1>
                        <strong className={'cas_ida'}
                                style={{fontSize: 40, display: 'block'}}>{casIda || 'A définir'}</strong>
                    </div>
                    <div className={'card-ida'}>
                        <h1 className={'title-primary'}>Responsabilité calculée :</h1>
                        <strong className={'cas_ida'}
                                style={{fontSize: 40, display: 'block'}}>{responsabilite + '%' || 'A définir'}</strong>
                    </div>
                    <div className={'col-12 text-center'} style={{marginBottom: 40}}>
                        {getImgCasIda(casIda)}
                    </div>
                </div>
            }
            {cas_ida === '50/51' && <div className="alert alert-warning" role="alert" style={{marginTop: 20}}>
                <p>Nous avons pu calculer la responsabilité en automatique mais pas l'IDA, merci de saisir le code 50 ou
                    51 selon les circonstances du sinistre.</p>
            </div>}
            {(cas_ida === null && casIda === null && responsabilite === null && rc === null) &&
                <div className="alert alert-warning" role="alert" style={{marginTop: 20}}>
                    <p>Les circonstances du sinistre ne permettent pas de définir une responsabilité et un cas IDA par
                        défaut, merci de saisir ces informations manuellement.</p>
                </div>
            }
            <div className={'form-content h-auto'}>
                {((casIda !== null && casIda !== undefined) && casIda !== '50/51' && (responsabilite !== null && responsabilite !== undefined)) ?
                    <Field
                        name='accord_ida'
                        component={RadioFieldRedux}
                        label={'Êtes vous d\'accord avec le cas et la responsabilité proposés ?'}
                        data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}, {
                            value: "STATUER",
                            label: "A statuer"
                        }]}
                        onChange={() => setDisabled(false)}
                    /> : <Field
                        name='define_ida'
                        component={RadioFieldRedux}
                        label={'Connaissez vous le cas IDA et la responsabilité ?'}
                        data={[{value: "T", label: "Oui, définir l'IDA et la responsabilité"}, {
                            value: "STATUER",
                            label: "Non, à statuer"
                        }]}
                    />}

                {(accordCheck === 'F' || defineManuel === 'T') && <Fragment>
                    <h1 className={'title-primary'}>Merci de saisir manuellement le cas IDA et la responsabilité</h1>
                    <Field
                        name='manuel_ida'
                        component={SelectFieldRedux}
                        label={'Cas IDA'}
                        disabled={disabled}
                    >
                        <option value={null}>Choisissez le cas IDA</option>
                        <option value={'HIX'}>HIX (0%)</option>
                        <option value={'HIV'}>HIV (25%)</option>
                        <option value={'HIY'}>HIY (50%)</option>
                        <option value={'HIW'}>HIW (75%)</option>
                        <option value={'HIZ'}>HIZ (100%)</option>
                        {codegta !== undefined && <Fragment>
                            <option value={'40Y'}>40Y</option>
                            <option value={'40X'}>40X</option>
                            <option value={'51Y'}>51Y</option>
                            <option value={'51X'}>51X</option>
                            <option value={'56X'}>56X</option>
                            <option value={'10Y'}>10Y</option>
                            <option value={'15Y'}>15Y</option>
                            <option value={'10X'}>10X</option>
                            <option value={'15X'}>15X</option>
                            <option value={'13X'}>13X</option>
                            <option value={'17X'}>17X</option>
                            <option value={'20Y'}>20Y</option>
                            <option value={'20X'}>20X</option>
                            <option value={'21X'}>21X</option>
                            <option value={'30X'}>30X</option>
                            <option value={'30Y'}>30Y</option>
                            <option value={'50X'}>50X</option>
                            <option value={'50Y'}>50Y</option>
                            <option value={'51X'}>51X</option>
                            <option value={'51Y'}>51Y</option>
                        </Fragment>}
                    </Field>
                    <small style={{marginBottom: 30, display: 'block'}}>Y = 100 et X = 0</small>
                    {casIda === '50/51' &&
                        <div className="alert alert-warning" role="alert">Merci de preciser la valeur 50 ou 51 selon
                            s'il n'a pas observé un signal de priorité (cas 51) ou un feu rouge (cas 50)</div>}
                    <Field
                        name='manuel_resp'
                        component={SelectFieldRedux}
                        label={'Responsabilité'}
                        disabled={disabled}
                    >
                        <option value={null}>Choisissez le taux</option>
                        <option value={0}>0%</option>
                        <option value={25}>25%</option>
                        <option value={50}>50%</option>
                        <option value={75}>75%</option>
                        <option value={100}>100%</option>
                    </Field>

                    <span className={'btn btn-primary btn-lg float-right'}
                          onClick={() => handleClick()}
                    >
                        Calculer
                    </span>
                </Fragment>
                }
                {(accordCheck === 'STATUER' || defineManuel === 'STATUER') &&
                    <div className="alert alert-danger" role="alert" style={{marginTop: 20}}>
                        Attention, le bouton "A statuer" ne doit être utilisé que si vous attendez une validation d’un
                        manager ou un retour de la compagnie
                    </div>
                }
            </div>
        </Fragment>
    );
};

export default Responsabilite;
