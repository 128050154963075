import React from 'react'
import moment from "moment";

const Activity = (props) => {
    const {activity_logs} = props

    return (
        <div className={'bloc-info bloc-activity'}>
            <h2>Activités sur son espace assuré</h2>
            <div className={'content row'}>
                {activity_logs.data.length >= 1 &&
                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Titre</th>
                            <th scope="col">Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        {activity_logs.data.map((activity, index) =>
                            <tr key={index}>
                                <td>{moment(activity.created_at).format('L')}</td>
                                <td>{activity.title}</td>
                                <td>{activity.description}</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                }
            </div>
        </div>
    )
}

export default Activity
