import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {reduxForm, change, formValueSelector, clearFields} from 'redux-form'
import {ADD_ACTIVITY, postAddActivity} from '../../../../../redux/actions/app/activity/activity.actions'
import AddActivity from './AddActivity'
import {getLoadingEntity} from '../../../../../redux/selectors/ui/ui.selectors'
import validate from "./validate";
import moment from "moment";
import {getBelairName, getUserRoles} from "../../../../../redux/selectors/user/user.selectors";
import _ from "lodash";
import {complainActivityPostLink, complainGetLink} from "../../../../../redux/selectors/links/links.selectors";
import {getReclamationData} from "../../../../../redux/selectors/reclamation/reclamation.selectors";
import {getReclamation, RECLAMATION} from "../../../../../redux/actions/app/reclamation/reclamation.actions";

import ErrorHttpComponent from "../../../../Commun/Error/ErrorHttpComponent";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector(`addActivityForm`)
    const loaded = getLoadingEntity(state, RECLAMATION) === false
    const reclamation = getReclamationData(state)

    return {
        loaded: loaded,
        loading: getLoadingEntity(state, ADD_ACTIVITY),
        uri_recla: complainGetLink(state),
        reclamation: getReclamationData(state),
        idTiers: ownProps.match.params.id,
        idRecla: ownProps.match.params.ids,
        url: _.replace(_.replace(complainActivityPostLink(state), '{id}', ownProps.match.params.id), '{ids}', ownProps.match.params.ids),
        cloture: ownProps.cloture === true,
        title: loaded ? reclamation.motif : '',
        categorie: selector(state, 'categorie'),
        paniere: selector(state, 'paniere'),
        type: selector(state, 'type'),
        initialValues: {
            'date_rappel': moment(),
            'clotureReclamation': ownProps.cloture === true,
            'categorie': ownProps.cloture === true ? "chrono" : ""
        },
        nameConseiller: getBelairName(state),
        roles: getUserRoles(state),
        clotureReclamation: ownProps.cloture === true,
        activityType: 'complain',
        isAuthorizedToCreate: true
    }
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
    getReclamation,
    submitPostAddActivity: ({form, query}) => postAddActivity({
        form,
        query,
        otherData: {id: ownProps.match.params.id, ids: ownProps.match.params.ids}
    }),
    onChangeValue: (name, file) => change('addActivityForm', name, file),
    unsetValue: (field) => clearFields('addActivityForm', false, false, field),
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {submitPostAddActivity, getReclamation} = dispatchProps
    const {url, uri_recla, idTiers, idRecla} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => {
            getReclamation({query: _.replace(_.replace(uri_recla, '{id}', idTiers), '{ids}', idRecla)})
        },
        onSubmit: (form) => submitPostAddActivity({form, query: url})
    }
}
const AddActivityReclamationContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'addActivityForm',
        validate
    }),
    loader({ErrorIndicator: ErrorHttpComponent})
)(AddActivity)

export default AddActivityReclamationContainer
