import {createSelector} from 'reselect'
import _ from "lodash";

const piecesSelector = state => state.pieces

export const getPiecesData = createSelector(
    piecesSelector,
    pieces => pieces.data
)

export const getLastFIDPiece = createSelector(
    getPiecesData,
    pieces => {
        const FIDpieces = _.orderBy(_.filter(pieces, 'FID'), ['date'], ['desc'])
        if(!_.isEmpty(FIDpieces)){
            return FIDpieces[0];
        }
        return {};
    }
)