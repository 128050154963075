import {CLEAR_SEARCH, SET_SEARCH} from '../../actions/app/search/search.actions'

const init = {}

export const searchReducer = (search = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_SEARCH) {
        return payload
    } else if (action.type === CLEAR_SEARCH) {
        return init
    } else {
        return search
    }
}
