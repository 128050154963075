import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import ErrorHttpComponent from '../../../../Commun/Error/ErrorHttpComponent'
import {change, formValueSelector, reduxForm} from "redux-form";
import {postVirement, POST_VIREMENT} from "../../../../../redux/actions/app/virements/virements.actions";
import {getConfirmeVirement, getErrorEntity, getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {ACCIDENT, getAccident} from '../../../../../redux/actions/app/accidents/accidents.actions'
import _ from "lodash";
import {getAccidentData} from "../../../../../redux/selectors/accident/accident.selectors";
import {
    accessoiresGetLink,
    accidentGetLink,
    contractDefaultGetLink, postRegulationDetailAccidentGetLink, rapportBcaGetLink,
    virementCreateLink
} from "../../../../../redux/selectors/links/links.selectors";
import validate from './validate'
import {CONTRACT, getContract} from "../../../../../redux/actions/app/contracts/contracts.actions";
import {getContractData} from "../../../../../redux/selectors/contract/contract.selectors";
import VirementsSinistreInitForm from "./VirementsSinistreInitForm";
import {ACCESSOIRES, getAccessoires} from "../../../../../redux/actions/app/accessoires/accessoires.actions";
import {getRapportBca, RAPPORT_BCA} from "../../../../../redux/actions/app/bca/bca.actions";
import loader from "../../../../Commun/Spinner/Loader/Loader";
import {
    getReglementDetail,
    REGLEMENT_DETAIL
} from "../../../../../redux/actions/app/reglement_detail/reglement_detail.actions";

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector('virement-sinistre')
    return {
        loading:getLoadingEntity(state, POST_VIREMENT),
        confirmeVirement: getConfirmeVirement(state),
        uri_post_virement: virementCreateLink(state),
        uri: accidentGetLink(state),
        uri_BCA: rapportBcaGetLink(state),
        uri_accessoires: accessoiresGetLink(state),
        uri_contract: contractDefaultGetLink(state),
        uri_detail_reglement: postRegulationDetailAccidentGetLink(state),
        idContract: ownProps.match.params.id,
        idAccident: ownProps.match.params.ids,
        contract: getContractData(state),
        accident: getAccidentData(state),
        loaded: getLoadingEntity(state, ACCIDENT) === false && getLoadingEntity(state, CONTRACT) === false && getLoadingEntity(state, ACCESSOIRES) === false && getLoadingEntity(state, RAPPORT_BCA) === false && getLoadingEntity(state, REGLEMENT_DETAIL) === false,
        error: getErrorEntity(state, ACCIDENT) && getErrorEntity(state, CONTRACT) && getErrorEntity(state, ACCESSOIRES) && getErrorEntity(state, RAPPORT_BCA) && getErrorEntity(state, REGLEMENT_DETAIL),
        rapportsExpertCoches: selector(state, 'rapportsExpertCoches'),
        facturesCochees: selector(state, 'facturesCochees'),
        montant_penalite_franchise: selector(state, '[penalite][3][montant]'),
        initialValues: {
            'facturesCochees': [],
            'rapportsExpertCoches': [],
            'verification_manager': 'F',
            'reglement_cheque' : 'F',
            'blocage_verification_client_ok': false
        }
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    changeValue: (field, value) => change('virement-sinistre', field, value),
    postVirement: ({form, query}) => postVirement({form, query}),
    getAccident: ({query, id}) => getAccident({query, id}),
    getContract: (query) => getContract({query}),
    getRapportBca: (query) => getRapportBca({query}),
    getAccessoires: (query) => getAccessoires({query}),
    getReglementDetail: (query) => getReglementDetail({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {postVirement, getAccident, getContract, getRapportBca, getAccessoires, getReglementDetail} = dispatchProps
    const {uri, uri_post_virement, uri_accessoires, uri_contract, uri_BCA, uri_detail_reglement, idContract, idAccident} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form) => postVirement({
            form,
            query: _.replace(_.replace(uri_post_virement, '{id}', idContract), '{ids}', idAccident)
        }),
        load: () => {
            getAccident({query: _.replace(_.replace(uri, '{id}', idContract), '{ids}', idAccident), id: idAccident})
            getContract(_.replace(uri_contract, '{id}', idContract))
            getRapportBca(_.replace(_.replace(uri_BCA, '{id}', idContract), '{ids}', idAccident))
            getAccessoires(_.replace(_.replace(uri_accessoires, '{id}', idContract), '{ids}', idAccident))
            getReglementDetail(_.replace(_.replace(uri_detail_reglement, '{id}', idContract), '{ids}', idAccident))
        }
    }
}

const VirementsSinistreInitFormContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent}),
    reduxForm({
        form: 'virement-sinistre',
        validate,
        onSubmitFail: () => {
            return setTimeout(function() {
                return document.getElementsByClassName('is-invalid')[0]?.scrollIntoView({behavior: "smooth", block: "center"})
            }, 100);
        }
    })
)(VirementsSinistreInitForm)

export default VirementsSinistreInitFormContainer
