import {SET_PIECES} from '../../actions/app/pieces/pieces.actions'

export const piecesReducer = (pieces = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_PIECES) {
        return payload
    } else {
        return pieces
    }
}
