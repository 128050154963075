import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {reduxForm} from 'redux-form'
import AddNote from './AddNote'
import {getLoadingEntity} from '../../../../../redux/selectors/ui/ui.selectors'
import validate from "./validate";
import {ADD_NOTE, postAddEditNote} from "../../../../../redux/actions/app/notes/notes.actions";
import {postNoteUpdateGetLink} from "../../../../../redux/selectors/links/links.selectors";

const mapStateToProps = (state, ownProps) => {
    const {note, edit} = ownProps.location.state

    return {
        loading: getLoadingEntity(state, ADD_NOTE),
        url: ownProps.location.state.url,
        title: ownProps.location.state.title,
        subtitle: ownProps.location.state.subtitle,
        edit: edit,
        note: note || null,
        isAccident: ownProps.match.params.ids ?? false,
        initialValues: {
            'titre': note ? note.titre : null,
            'description': note ? note.commentaire : null
        },
        uriEdit: postNoteUpdateGetLink(state)
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    submitPostAddNote: ({form, query}) => postAddEditNote({form, query})
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {submitPostAddNote} = dispatchProps
    const {url, edit, uriEdit} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form) => {
            if (edit) {
                submitPostAddNote({form, query: uriEdit})
            } else {
                submitPostAddNote({form, query: url})
            }
        }
    }
}
const AddNoteContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'addNoteForm',
        validate
    })
)(AddNote)

export default AddNoteContainer
