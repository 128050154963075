import {TASKS, GET_TASKS, setTasks, GET_NB_TASKS} from '../../../actions/app/tasks/tasks.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader, setNbTasks} from '../../../actions/app/ui/ui.actions'
import _ from 'lodash'

export const tasksMiddleware = () => next => action => {
    next(action)

    switch (action.type) {

        case GET_TASKS:
            const {filters, page, sort} = action.payload

            let filter = '';
            if (_.includes(window.location.pathname, 'mes-taches') || _.includes(window.location.pathname, 'mon-agenda')) {
                filter += `&sort=${sort}`
                filter += `&contrat_situation=${filters["contrat_situation"]}`
                filter += `&courtier=${filters["courtier"]}`
                filter += `&portefeuille=${filters["portefeuille"]}`
                filter += `&paniere=${filters["paniere"]}`
                filter += `&type_agenda=${filters["type_agenda"]}`
                filter += `&date_start=${filters["date_start"]}`
                filter += `&date_end=${filters["date_end"]}`
                filter += `&agendas=${filters["agendas"]}`
                filter += `&multiple=${filters["multiple"]}`
                filter += `&date_start_create_popup_range=${filters["date_start_create_popup_range"]}`
                filter += `&date_end_create_popup_range=${filters["date_end_create_popup_range"]}`
                filter += `&date_create_task=${filters["date_create_task"]}`
                filter += `&produit=${filters["produit"]}`
                filter += `&resil_date=${filters["resil_date"]}`
            }

            if (filters["tiers"]) {
                filter += `&tiers=${filters["tiers"]}`
            }

            let url = `${action.payload.data}?page=${page}${filter}`

            next([
                apiRequest({body: null, method: 'GET', url, entity: TASKS}),
                setLoader({state: true, entity: TASKS})
            ])
            break

        case `${TASKS} ${API_SUCCESS}`:
            const {data, meta, links} = action.payload.data

            next([
                setTasks({data, meta, links}),
                setError({state: false, entity: TASKS}),
                setLoader({state: false, entity: TASKS})
            ])

            break

        case `${TASKS} ${API_ERROR}`:
            next([
                setError({state: true, entity: TASKS}),
                setLoader({state: false, entity: TASKS})
            ])
            break

        case GET_NB_TASKS:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: GET_NB_TASKS}),
            ])
            break

        case `${GET_NB_TASKS} ${API_SUCCESS}`:
            next([
                setNbTasks({data: action.payload.data.nb_taches}),
                setError({state: false, entity: GET_NB_TASKS})
            ])

            break

        case `${GET_NB_TASKS} ${API_ERROR}`:
            next([
                setError({state: true, entity: GET_NB_TASKS})
            ])
            break

        default:
            break
    }
    return null
}
