import React, {useEffect, useState} from 'react';
import {Field} from "redux-form";
import moment from "moment";
import SelectFieldRedux from "../../../../../Commun/Input/Select/SelectFieldRedux";
import CallToAction from "../../../../../Commun/Button/CallToAction/CallToAction";
import TextFieldRedux from "../../../../../Commun/Input/Text/TextFieldRedux";
import DatePickerFieldRedux from "../../../../../Commun/Input/DatePicker/DatePickerFieldRedux";
import vetuste from "../../../../../../utils/vetuste";
import RadioFieldRedux from "../../../../../Commun/Input/Radio/RadioFieldRedux";
import AutoComplete from "../../../../../Commun/Input/Autocomplete/Autocomplete";
import {getReparateurAccident} from "../../../../../../utils/function";
import _ from "lodash";

const AccessoiresFieldArray = (props) => {
    const {fields, accessoires, dateSurv, change, reparateur} = props
    const [reparateurs, setReparateurs] = useState([])
    const [listeReparateurs, setListeReparateurs] = useState([])

    async function fetchReparateurs() {
        let arrayReparateurs = await getReparateurAccident()
        let listReparateurs = _.orderBy(arrayReparateurs, ['raisonSociale'], ['asc'])
        setReparateurs(listReparateurs);

        listReparateurs = listReparateurs.map(reparateur => ({
            value: reparateur.id,
            label: reparateur.raisonSociale + ' - ' + reparateur.adresse + ' - ' + reparateur.codePostal + ' ' + reparateur.ville + ' - ' + reparateur.phone
        }))

        setListeReparateurs(listReparateurs)

        if(fields.length > 0) {
            fields.map((accessoire, index) => (
                change(`accessoires[${index}][reparateur]`, '')
            ));
        }
    }

    const defaultAccessoires = {
        dateAchat: moment(),
        vetuste: 'F',
    }

    useEffect(() => {
        fetchReparateurs();
        fields.push(defaultAccessoires)
    }, [])

    const calculVetuste = (index) => {
        const result = vetuste('accessoire', moment(dateSurv), moment(accessoires[index].dateAchat), accessoires[index].valeurAchat)

        change(`accessoires[${index}][montant]`, result.valeurRemboursement)
        change(`accessoires[${index}][palier]`, result.palier)
        change(`accessoires[${index}][explication]`, result.explication)
        change(`accessoires[${index}][niveau_vetuste]`, result.niveau_vetuste)
    }

    const handleChange = (type, index) => {
        if (type === 'reparation') {
            change(`accessoires[${index}][libelle]`, 'reparation')
            change(`accessoires[${index}][valeurAchat]`, 0)
        }
        if (type === 'airbag') {
            change(`accessoires[${index}][libelle]`, 'airbag')
            change(`accessoires[${index}][valeurAchat]`, 0)
        }
        if (type === 'antivol') {
            change(`accessoires[${index}][libelle]`, 'antivol')
            change(`accessoires[${index}][valeurAchat]`, 0)
        }
        if (type === 'facture_gants') {
            change(`accessoires[${index}][libelle]`, 'Facture Gants')
            change(`accessoires[${index}][valeurAchat]`, 0)
        }
        if (type === 'remorquage_gardiennage') {
            change(`accessoires[${index}][libelle]`, 'remorquage / gardiennage')
            change(`accessoires[${index}][valeurAchat]`, 0)
        }
        if (type === 'gravage') {
            change(`accessoires[${index}][libelle]`, 'gravage')
            change(`accessoires[${index}][valeurAchat]`, 0)
        }
        if (type === 'vehicule') {
            change(`accessoires[${index}][libelle]`, 'vehicule')
            change(`accessoires[${index}][valeurAchat]`, 0)
        }
        if (type === 'pantalon') {
            change(`accessoires[${index}][libelle]`, 'Facture Pantalon')
            change(`accessoires[${index}][valeurAchat]`, 0)
        }
        if (type === 'autres') {
            change(`accessoires[${index}][valeurAchat]`, 0)
        }
    }

    const calculateTaxe = (index) => {
        const currentValue = accessoires[index].montant;
        const newValue = parseFloat(currentValue) * 1.20; // Ajoute 20% de taxe
        change(`accessoires[${index}][montant]`, newValue.toFixed(2));
    };

    const calculateTaxeAchat = (index) => {
        const currentValue = accessoires[index].valeurAchat;
        const newValue = parseFloat(currentValue) * 1.20; // Ajoute 20% de taxe
        change(`accessoires[${index}][valeurAchat]`, newValue.toFixed(2));
    };

    return (
        <div>
            {fields.length > 0 &&
                fields.map((accessoire, i) => (
                    <div key={i}>
                        <div className="mb-3">
                            {i > 0 && <hr/>}
                            <span className={'mr-2'}>Remboursement n° {i + 1}</span>
                            {i > 0 && <div className="float-right" style={{marginRight: '-30px'}}>
                                <CallToAction
                                    action={'delete'}
                                    toolText={"Supprimer l'accessoires et équipements"}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        fields.remove(i)
                                    }}
                                />
                            </div>}
                        </div>
                        <Field
                            name={`${accessoire}.type`}
                            component={SelectFieldRedux}
                            label="Sélectionner le type de facture"
                            onChange={(e) => handleChange(e.target.value, i)}
                        >
                            <option value={''}>Sélectionner le type de facture</option>
                            <option value={'antivol'}>Facture Antivol SRA</option>
                            <option value={'accessoires'}>Facture des accessoires & équipements</option>
                            <option value={'reparation'}>Facture des réparations</option>
                            <option value={'vehicule'}>Facture du véhicule</option>
                            <option value={'airbag'}>Facture Gilet Airbag</option>
                            <option value={'gravage'}>Facture Gravage</option>
                            <option value={'facture_gants'}>Facture Gants</option>
                            <option value={'pantalon'}>Facture Pantalon</option>
                            <option value={'remorquage_gardiennage'}>Facture Remorquage / Gardiennage</option>
                            <option value={'autres'}>Facture Autres (sans vétusté)</option>
                        </Field>
                        {(accessoires[i] && accessoires[i].type === 'autres') &&
                            <Field
                                name={`${accessoire}.libelle`}
                                component={TextFieldRedux}
                                label={'Préciser'}
                                subLabel={'30 caractères maximum'}
                                maxLength={30}
                                required
                            />
                        }

                        {(accessoires[i] && accessoires[i].type === 'accessoires') ?
                            <>
                                <Field
                                    name={`${accessoire}.libelle`}
                                    component={SelectFieldRedux}
                                    label="Sélectionner les accessoires et équipements"
                                >
                                    <option value={''}>Sélectionner les accessoires et équipements</option>
                                    <option value={'casque'}>Casque</option>
                                    <option value={'gants'}>Gants</option>
                                    <option value={'blouson'}>Blouson</option>
                                    <option value={'bottes'}>Bottes</option>
                                    <option value={'top_case'}>Top Case</option>
                                    <option value={'tablier'}>Tablier</option>
                                    <option value={'combinaison'}>Combinaison</option>
                                    <option value={'echappement'}>Echappement</option>
                                    <option value={'top_bloc'}>Top Bloc</option>
                                    <option value={'autres'}>Autre équipement</option>
                                </Field>
                                {accessoires[i].libelle === 'autres' &&
                                    <Field
                                        name={`${accessoire}.libelle_autres`}
                                        component={TextFieldRedux}
                                        label={'Préciser l\'accessoire ou équipement'}
                                        subLabel={'30 caractères maximum'}
                                        maxLength={30}
                                    />
                                }

                                <Field
                                    name={`${accessoire}.dateAchat`}
                                    component={DatePickerFieldRedux}
                                    label={'Date d\'achat'}
                                    maxDate={moment()}
                                />

                                <Field
                                    name={`${accessoire}.valeurAchat`}
                                    component={TextFieldRedux}
                                    label={'Valeur d\'achat'}
                                    type={'number'}
                                />

                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        calculateTaxeAchat(i);
                                    }}
                                    className={'btn btn-primary btn-small mb-3'}
                                    disabled={!accessoires[i] && !accessoires[i].valeurAchat}
                                    style={{marginRight:10}}
                                >
                                    <u>Calculer la taxe</u>
                                </button>

                                <button
                                    onClick={(e) => {
                                        e.preventDefault()
                                        calculVetuste(i)
                                    }}
                                    className={'btn btn-primary btn-small mb-3'}
                                    disabled={!accessoires[i] && !accessoires[i].valeurAchat}
                                >
                                    <u>Calculer la vétusté</u>
                                </button>

                                <Field
                                    name={`${accessoire}.montant`}
                                    component={TextFieldRedux}
                                    label={'Valeur de la facture'}
                                    type={'number'}
                                    disabled={accessoires[i]}
                                />
                                {
                                    (accessoires[i] && accessoires[i].explication) && <div>
                                        <p>Durée de vétusté : <b>{accessoires[i].palier}</b></p>
                                        <p>Explication : <b>{accessoires[i].explication}</b></p>
                                    </div>
                                }
                            </>
                            : <>
                                <Field
                                    name={`${accessoire}.montant`}
                                    component={TextFieldRedux}
                                    label={'Valeur de la facture'}
                                    type={'number'}
                                />

                                <Field
                                    name={`${accessoire}.dateAchat`}
                                    component={DatePickerFieldRedux}
                                    label={'Date d\'achat'}
                                    maxDate={moment()}
                                />

                                {accessoires[i].libelle === 'vehicule' && <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        calculateTaxe(i);
                                    }}
                                    className={'btn btn-primary btn-small mb-3'}
                                    disabled={!accessoires[i] && !accessoires[i].montant}
                                    style={{marginRight:10}}
                                >
                                    <u>Calculer la taxe</u>
                                </button> }
                            </>
                        }
                        {(accessoires[i] && accessoires[i].type === 'reparation') ?
                            <>
                                <p>Nom du réparateur : {reparateur.raisonSociale ?? '-'}</p>

                                <Field
                                    name={`${accessoire}.reparateur_facture`}
                                    component={RadioFieldRedux}
                                    label={'Le réparateur correspond-t-il à celui de la facture ?'}
                                    data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                                    required
                                />

                                {
                                    (accessoires[i].reparateur_facture && accessoires[i].reparateur_facture === 'F') &&
                                    <Field
                                        name={`${accessoire}.reparateur`}
                                        component={AutoComplete}
                                        label="Chercher un réparateur par nom, code postal ou téléphone"
                                        data={listeReparateurs}
                                    />
                                }

                                <Field
                                    name={`${accessoire}.facture_soldee`}
                                    component={RadioFieldRedux}
                                    label={'La facture est-elle soldée ?'}
                                    data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                                    required
                                />

                                {
                                    // On vérifie soit que si on prend le réparateur actuel du sinistre que celui-ci à un email
                                    // Ou si on modifie le réparateur que le nouveau ait un email
                                    (
                                        ((!accessoires[i].reparateur_facture || accessoires[i].reparateur_facture === 'T') && reparateur && !reparateur.email) ||
                                        (accessoires[i].reparateur_facture && accessoires[i].reparateur_facture === 'F' && accessoires[i].reparateur && _.find(reparateurs, function (r) { return r.id === accessoires[i].reparateur && !r.email; }))
                                    ) &&
                                    <div className={'alert-danger alert'}>
                                        <p>Attention la PEC ne va pas peut pas s'envoyer car le réparateur n'a pas d'adresse email.</p>
                                    </div>
                                }
                                {
                                    ((!accessoires[i].reparateur_facture || accessoires[i].reparateur_facture === 'T') && !reparateur?.raisonSociale ) &&
                                    <div className={'alert-danger alert'}>
                                        <p>Pas de réparateur pour cet accident. La PEC ne va pas pouvoir être envoyée en automatique.</p>
                                    </div>
                                }
                            </>
                            : null
                        }

                    </div>
                ))
            }

            <button
                onClick={(e) => {
                    e.preventDefault();
                    fields.push(defaultAccessoires)
                }}
                className={'btn btn-secondary btn-smaller mt-3'}
            >
                Ajouter un remboursement
            </button>
        </div>
    );
};

export default AccessoiresFieldArray;
