import {createSelector} from 'reselect'

const userSelector = state => state.user

export const getUserName = createSelector(
    userSelector,
    user => user.name
)
export const getUserTasks = createSelector(
    userSelector,
    user => user.nb_taches
)


export const getBelairName = createSelector(
    userSelector,
    user => user.belair_name
)

export const getUserEmail = createSelector(
    userSelector,
    user => user.address_mail
)

export const getUserPermissions = createSelector(
    userSelector,
    user => user.permissions
)

export const getUserRoles = createSelector(
    userSelector,
    user => user.roles
)

export const getUserIdBelair = createSelector(
    userSelector,
    user => user.belair_id
)

export const getUserPanieres = createSelector(
    userSelector,
    user => user.panieres
)

export const getUserSubscribeLinks = createSelector(
    userSelector,
    user => user.subscribe_links_conseiller
)
