import {createSelector} from 'reselect'

const quotesSelector = state => state.quotes
const quoteSelector = state => state.quote

/***** Helpers *****/
export const getLibelleFractionnementByCode = ({code}) => {
    switch (code) {
        case 'A':
            return 'Annuel'
        case 'S':
            return 'Semestriel'
        case 'M':
            return 'Mensuel'
        default:
            return '-'
    }
}
export const getPrimeByFractionnement = ({prime, code, per}) => {
    switch (code) {
        case 'A':
            return per ? `${prime} € / an` : prime
        case 'S':
            return per ? `${(prime / 2).toFixed(2)} € / semestre` : (prime / 2).toFixed(2)
        case 'M':
            return per ? `${(prime / 12).toFixed(2)} € / mois` : (prime / 12).toFixed(2)
        default:
            return '-'
    }
}

/***** DATA QUOTES *****/
export const getDataQuotes = createSelector(
    quotesSelector,
    quotes => quotes.data
)

/***** META QUOTES *****/
export const getMetaQuotes = createSelector(
    quotesSelector,
    quotes => quotes.meta
)


/***** CONDUCTEUR QUOTE *****/
export const getQuote = createSelector(
    quoteSelector,
    quote => quote || {}
)
export const getConducteurQuote = createSelector(
    quoteSelector,
    quote => quote.conducteur || {}
)

/***** SOUSCRIPTEUR QUOTE *****/
export const getSouscripteurQuote = createSelector(
    quoteSelector,
    quote => quote.souscripteur || {}
)

/***** VEHICULE QUOTE *****/
export const getVehiculeQuote = createSelector(
    quoteSelector,
    quote => quote.vehicule || {}
)

/***** FLAT DATA QUOTE *****/
export const getIdQuote = createSelector(
    quoteSelector,
    quote => quote.id
)
export const getFormuleChoisieLibelleQuote = createSelector(
    quoteSelector,
    quote => quote.formuleChoisieLibelle
)
export const getCodeProduitQuote = createSelector(
    quoteSelector,
    quote => quote.codeProduit || ''
)
export const getLibelleProduitQuote = createSelector(
    getCodeProduitQuote,
    codeProduit => codeProduit.substring(0, codeProduit.length - 3)
)
export const getCreatedAtQuote = createSelector(
    quoteSelector,
    quote => quote.created_at
)
export const getDateEffetQuote = createSelector(
    quoteSelector,
    quote => quote.dateEffet
)
export const getFractionnementQuote = createSelector(
    quoteSelector,
    quote => getLibelleFractionnementByCode({code: quote.fractionnement})
)
export const getPrimeFractionneTTCQuote = createSelector(
    quoteSelector,
    quote => getPrimeByFractionnement({prime: quote.primeFC, code: quote.fractionnement})
)
export const getPrimeFAQuote = createSelector(
    quoteSelector,
    quote => quote.primeFA
)
export const getTauxApporteur1Quote = createSelector(
    quoteSelector,
    quote => quote.txApporteur1 * 100
)
export const getHonorairesQuote = createSelector(
    quoteSelector,
    quote => quote.honoraires
)
export const getActeGestionQuote = createSelector(
    quoteSelector,
    quote => quote.acteGestion
)
export const getAccountQuote = createSelector(
    quoteSelector,
    quote => quote.account_id
)
export const getStatutQuote = createSelector(
    quoteSelector,
    quote => quote.statut
)
export const getRealisateurName = createSelector(
    quoteSelector,
    quote => quote.realisateur_name
)
export const getUrlSouscriptionQuote = createSelector(
    quoteSelector,
    quote => quote.url_souscription
)
export const getUrlRepriseQuote = createSelector(
    quoteSelector,
    quote => quote.url_reprise
)
export const getUrlPdfQuote = createSelector(
    quoteSelector,
    quote => quote.url_pdf
)
export const getUrlPdfContract = createSelector(
    quoteSelector,
    quote => quote.url_pdf_contrat || ''
)
export const getApporteur1 = createSelector(
    quoteSelector,
    quote => quote.apporteur1 || {}
)
export const getApporteur2 = createSelector(
    quoteSelector,
    quote => quote.apporteur2 || {}
)
export const getCodePromoQuote = createSelector(
    quoteSelector,
    quote => quote.codePromo || ''
)
export const getMsgFeuQuote = createSelector(
    quoteSelector,
    quote => quote.messageFeuTricolore || ''
)