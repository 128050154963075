export const HIVERNAGE = '[Hivernage]'
export const HIVERNAGE_REDUC = '[Hivernage Reduction]'

export const GET_HIVERNAGE_REDUC = `${HIVERNAGE_REDUC} Get`
export const POST_HIVERNAGE = `${HIVERNAGE} Post`
export const POST_FIN_HIVERNAGE = `${HIVERNAGE} Post Fin`

export const getHivernageReduc = ({query, form}) => ({
    type: GET_HIVERNAGE_REDUC,
    payload: {
        data: query,
        body: form
    }
})
export const postHivernage = ({query, form}) => ({
    type: POST_HIVERNAGE,
    payload: {
        data: query,
        body: form
    }
})
export const postFinHivernage = ({query}) => ({
    type: POST_FIN_HIVERNAGE,
    payload: {
        data: query
    }
})