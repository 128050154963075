import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {reduxForm} from "redux-form";
import validate from "./validate";
import AddCorrespondant from "./AddCorrespondant";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {correspondantGetLink, updateCorrespondantsGetLink} from "../../../../../redux/selectors/links/links.selectors";
import {
    postUpdateCorrespondant,
    POST_UPDATE_CORRESPONDANT, getCorrespondant, CORRESPONDANT
} from "../../../../../redux/actions/app/correspondants/correspondants.actions";
import _ from "lodash";

import {getCorrespondantData} from "../../../../../redux/selectors/correspondant/correspondant.selector";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    const correspondant = getCorrespondantData(state);

    return {
        loaded: getLoadingEntity(state, CORRESPONDANT) === false,
        uri: correspondantGetLink(state),
        uri_update: updateCorrespondantsGetLink(state),
        loading: getLoadingEntity(state, POST_UPDATE_CORRESPONDANT),
        edit: true,
        idTiers: ownProps.match.params.id,
        idCorrespondant: ownProps.match.params.ids,
        correspondantPrincipal: correspondant.principal,
        initialValues: {
            'role': correspondant.role,
            'titre': correspondant.titre,
            'nom': correspondant.nom,
            'prenom': correspondant.prenom,
            'telephone': correspondant.phone,
            'email': correspondant.email,
            'principal': correspondant.principal,
        }
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    postUpdateCorrespondant,
    getCorrespondant,
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uri, uri_update, idTiers, idCorrespondant} = stateProps
    const {postUpdateCorrespondant, getCorrespondant} = dispatchProps


    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => {
            getCorrespondant({query: _.replace(_.replace(uri, '{id}', idTiers), '{ids}', idCorrespondant)})
        },
        onSubmit: form => postUpdateCorrespondant({
            query: _.replace(_.replace(uri_update, '{id}', idTiers), '{ids}', idCorrespondant),
            form
        })
    }
}


const EditCorrespondantContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader(),
    reduxForm({
        form: 'edit_correspondant',
        validate
    }),
)(AddCorrespondant)


export default EditCorrespondantContainer
