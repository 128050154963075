import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {
    getAccidentsData,
    getAccidentsMeta,
    getAccidentsLinks
} from "../../../../redux/selectors/accidents/accidents.selectors";
import {accidentsIntervenantGetLink} from "../../../../redux/selectors/links/links.selectors";
import {TIERS} from "../../../../redux/actions/app/tiers/tiers.actions";
import {ACCIDENTS, getAccidents} from "../../../../redux/actions/app/accidents/accidents.actions";
import {getErrorEntity, getLoadingEntity} from "../../../../redux/selectors/ui/ui.selectors";
import ErrorHttpComponent from "../../../Commun/Error/ErrorHttpComponent";
import Accidents from "./Accidents";
import _ from "lodash";
import loader from "../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    return {
        idTiers: ownProps.match.params.id,
        accidents: getAccidentsData(state),
        meta: getAccidentsMeta(state) || {},
        links: getAccidentsLinks(state) || {},
        uri: accidentsIntervenantGetLink(state, TIERS),
        loaded: getLoadingEntity(state, ACCIDENTS) === false,
        error: getErrorEntity(state, ACCIDENTS)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getAccidents: (query) => getAccidents({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getAccidents} = dispatchProps
    const {uri, idTiers} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        getAccidents: (query) => getAccidents(query),
        load: () => getAccidents(_.replace(uri, '{id}', idTiers))
    }
}

const AccidentsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(Accidents)

export default AccidentsContainer
