export const CHANGE_FRACTIONNEMENT = '[Change Fractionnement]'
export const POST_CHANGE_FRACTIONNEMENT = `${CHANGE_FRACTIONNEMENT} Post`
export const SET_CHANGE_FRACTIONNEMENT = `${CHANGE_FRACTIONNEMENT} Set`

export const postChangeFractionnement = ({form, query}) => ({
    type: POST_CHANGE_FRACTIONNEMENT,
    payload: {
        data: query,
        body: form,
    }
})

export const setChangeFractionnement = ({state}) => ({
    type: SET_CHANGE_FRACTIONNEMENT,
    payload: state
})
