import React from 'react'
import moment from "moment";

const TacheSimple = (props) => {
    const { task: { title, description, date, type, paniere, tiers_id, contract }, message } = props;

    let descriptionContent = (
        contract?.vehicule?.codeProduit === 'WAKAM_LIV' && paniere === 'ACPTE_AV' ?
            'Solder toutes les quittances négatives et laisser le trop-perçu en acompte contrat' :
            message ? message :
                description ? <span dangerouslySetInnerHTML={{ __html: description }} /> : '-'
    );

    return (
        <div className={'container'}>
            <div className={'row'}>
                <div className={'col text-center'}>
                    <h2 className={'title-primary text-uppercase'}>{paniere} - Tiers n°{tiers_id}</h2>
                    <p className={'subtitle'}>Tâche du {moment(date).format('L')}. Reçu par {type}</p>
                </div>
            </div>
            <div className={'header-row row'}>
                <div className={'col-12'}>
                    <div className={'header-row row'}>
                        <div className={'col-12'}>
                            <p className={'form-content text-center'}>
                                Type: {title}<br />
                                Description: {descriptionContent}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TacheSimple;
