const validate = values => {
    const errors = {};

    if (!values.refus_rgt && !values.reassign_paniere) {
        errors.reassign_paniere = 'Ce champ est obligatoire';
    }

    return errors;
};

export default validate;
