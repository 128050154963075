export const SETTINGS = '[Settings]'
export const UPDATE_SETTINGS = '[UPDATE_SETTINGS]'

export const GET_SETTINGS = `${SETTINGS} Get`
export const SET_SETTINGS = `${SETTINGS} Set`

export const POST_UPDATE_SETTINGS = `${SETTINGS} Post`
export const SET_UPDATE_SETTINGS = `${SETTINGS} Post`

export const getSettings = ({query}) => ({
    type: GET_SETTINGS,
    payload: {
        data: query
    }
})

export const setSettings = ({data}) => ({
    type: SET_SETTINGS,
    payload: {
        data
    }
})

export const postUpdateSettings = ({query}) => ({
    type: POST_UPDATE_SETTINGS,
    payload: {
        data: query
    }
})

export const setValidateVersionning = ({state}) => ({
    type: SET_UPDATE_SETTINGS,
    payload: state
})
