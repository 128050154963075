import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {
    CONTRACT,
    getContract,
} from '../../../../redux/actions/app/contracts/contracts.actions'
import {getErrorEntity, getLoadingEntity} from '../../../../redux/selectors/ui/ui.selectors'
import Contract from './Contract'
import {contractDefaultGetLink} from '../../../../redux/selectors/links/links.selectors'
import ErrorHttpComponent from '../../../Commun/Error/ErrorHttpComponent'
import {getContractData} from '../../../../redux/selectors/contract/contract.selectors'
import _ from "lodash";
import {getUserRoles} from "../../../../redux/selectors/user/user.selectors";
import loader from "../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    return {
        idContract: ownProps.match.params.id,
        contract: getContractData(state),
        uri: contractDefaultGetLink(state),
        loaded: getLoadingEntity(state, CONTRACT) === false,
        error: getErrorEntity(state, CONTRACT),
        roles: getUserRoles(state),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getContract
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getContract} = dispatchProps
    const {uri, idContract} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getContract({query: _.replace(uri, '{id}', idContract), id: idContract}),
    }
}

const ContractContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(Contract)

export default ContractContainer
