import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import {getErrorEntity, getLoadingEntity} from '../../../../../../redux/selectors/ui/ui.selectors'
import {correspondantsGetLink} from '../../../../../../redux/selectors/links/links.selectors'
import ErrorHttpComponent from '../../../../../Commun/Error/ErrorHttpComponent'
import {
    CORRESPONDANTS,
    getCorrespondants
} from "../../../../../../redux/actions/app/correspondants/correspondants.actions";
import {getCorrespondantsData} from "../../../../../../redux/selectors/correspondants/correspondants.selector";
import Correspondants from "./Correspondants";
import _ from "lodash";
import {getUserPermissions} from "../../../../../../redux/selectors/user/user.selectors";
import loader from "../../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    return {
        correspondants: getCorrespondantsData(state),
        uri: correspondantsGetLink(state),
        loaded: getLoadingEntity(state, CORRESPONDANTS) === false,
        error: getErrorEntity(state, CORRESPONDANTS),
        permissions: getUserPermissions(state)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getCorrespondants: (query) => getCorrespondants({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getCorrespondants} = dispatchProps
    const {uri} = stateProps
    const {id} = ownProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getCorrespondants(_.replace(uri, '{id}', id))
    }
}

const CorrespondantsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(Correspondants)

export default CorrespondantsContainer
