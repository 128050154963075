import {createSelector} from 'reselect'

const correspondantSelector = state => state.correspondant

export const getCorrespondantData = createSelector(
    correspondantSelector,
    correspondant => correspondant.data || {}
)

export const getCorrespondantRoleData = createSelector(
    getCorrespondantData,
    correspondant => correspondant.role
)
