import React from 'react'
import check from '../../../assets/img/icons/check_light.png'


export const Check = ({isCheck}) => {
    switch (isCheck) {
        case 'T':
            return <img alt='icon' src={check}/>
        case 'F':
        case '':
        default:
            return ''
    }
}

export default Check
