import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {reduxForm} from 'redux-form'
import {PAYMENT, postPayment} from '../../../../../redux/actions/app/payment/payment.actions'
import Payment from './Payment'
import {contractDefaultGetLink, payDepositLink} from '../../../../../redux/selectors/links/links.selectors'
import {getLoadingEntity} from '../../../../../redux/selectors/ui/ui.selectors'
import validate from './validate'
import moment from 'moment'
import loader from "../../../../Commun/Spinner/Loader/Loader";
import ErrorHttpComponent from "../../../../Commun/Error/ErrorHttpComponent";
import _ from "lodash";
import {CONTRACT, getContract} from "../../../../../redux/actions/app/contracts/contracts.actions";
import {getContractData} from "../../../../../redux/selectors/contract/contract.selectors";

const mapStateToProps = (state, ownProps) => {
    return {
        loading: getLoadingEntity(state, PAYMENT),
        loaded: getLoadingEntity(state, CONTRACT) === false,
        idContract: ownProps.match.params.id,
        uri_contract: contractDefaultGetLink(state),
        uri: payDepositLink(state),
        contract: getContractData(state),
        dateLimite: moment().isAfter(moment().format('YYYY-MM') + '-24', 'day')
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getContract: (query) => getContract({query}),
    submitPostPayment: ({id_tiers, amount, url_return, by_mail, query}) => postPayment({
        id_tiers,
        amount,
        url_return,
        by_mail,
        query
    })
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {submitPostPayment, getContract} = dispatchProps
    const {uri, contract, uri_contract, idContract,} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getContract(_.replace(uri_contract, '{id}', idContract)),
        onSubmit: (form) => submitPostPayment({
            id_tiers: contract.souscripteur.id,
            amount: form.amount.replace(/,/g, '.'),
            url_return: !form.by_mail ? window.location.origin + '/contrat/' + contract.id : null,
            by_mail: form.by_mail || false,
            query: uri
        })
    }
}
const PaymentContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'paymentForm',
        validate
    }),
    loader({ErrorIndicator: ErrorHttpComponent})
)(Payment)

export default PaymentContainer
