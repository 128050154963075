import React from 'react'

const Garanties = ({garanties, accessoires}) => {
    return (
        <div className={'bloc-info bloc-garanties'}>
            <h2>Garanties / options</h2>
            <div className={'content row'}>
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">Libellé</th>
                        <th scope="col">Capital</th>
                        <th scope="col">Franchise</th>
                    </tr>
                    </thead>
                    <tbody>
                    {garanties.map((garantie, index) =>
                        (garantie.code !== 'PVRCC' && garantie.code !== 'PVRCM') &&
                        <tr key={index}>
                            <td>{garantie.libelle}</td>
                            <td>{garantie.capital > 0 && garantie.capital + '€'}</td>
                            <td>{garantie.franchise}</td>
                        </tr>
                    )}
                    {accessoires > 0 &&
                        <tr>
                            <td>Valeur des accessoires et équipements</td>
                            <td>{accessoires > 0 ? accessoires + '€' : '-'}</td>
                            <td>&nbsp;</td>
                        </tr>
                    }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Garanties
