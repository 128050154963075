import React from 'react';
import {Link} from "react-router-dom";
import _ from "lodash";


const CorrespondantItem = (props) => {
    const {index, idTiers, correspondant, submitDeleteCorrespondant, permissions} = props

    return (
        <tr key={index}>
            <td>{correspondant.titre}</td>
            <td>{correspondant.nom}</td>
            <td>{correspondant.prenom}</td>
            <td>{correspondant.role}</td>
            <td>{correspondant.phone}</td>
            <td>{correspondant.email}</td>
            <td>{correspondant.principal ? 'Oui' : 'Non'}</td>
            {
                _.includes(permissions, 'edit correspondent') &&
                <>
                    <td>
                        <Link to={`/tiers/${idTiers}/correspondant/${correspondant.id}`} className={'btn btn-edit'}
                              target={'_blank'}>&nbsp;</Link>
                    </td>
                    <td>
                        <button onClick={() => {
                            if (window.confirm(`Êtes-vous sûr(e) de vouloir supprimer le correspondant "${correspondant.prenom} ${correspondant.nom}" ?`)) submitDeleteCorrespondant()
                        }} className={'btn btn-delete-red'}/>
                    </td>
                </>
            }
        </tr>
    );
};


export default CorrespondantItem;
