import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader, setUpdateAntecedentPeriod} from '../../../actions/app/ui/ui.actions'
import {
    ANTECEDENTS_AUTO,
    ANTECEDENTS_MOTO, GET_ANTECEDENT_PERIOD,
    GET_ANTECEDENTS, POST_CREATE_ANTECEDENT, POST_UPDATE_ANTECEDENT_PERIOD, setAntecedentPeriod,
    setAntecedents
} from "../../../actions/app/antecedents/antecedents.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import _ from "lodash";

export const antecedentsMiddleware = () => next => action => {
    next(action)
    const {payload} = action
    let entity, message, clone;

    switch (action.type) {
        case GET_ANTECEDENTS:
            entity = payload.otherData.type === 'moto' ? ANTECEDENTS_MOTO : ANTECEDENTS_AUTO;
            next([
                apiRequest({body: null, method: 'GET', url: payload.data, entity: entity, otherData: payload.otherData}),
                setLoader({state: true, entity: entity})
            ])
            break

        case `${ANTECEDENTS_MOTO} ${API_SUCCESS}`:
        case `${ANTECEDENTS_AUTO} ${API_SUCCESS}`:
            entity = action.payload.meta.otherData.type === 'moto' ? ANTECEDENTS_MOTO : ANTECEDENTS_AUTO;
            next([
                setAntecedents({antecedents: payload.data, type: action.payload.meta.otherData.type}),
                setError({state: false, entity: entity}),
                setLoader({state: false, entity: entity})
            ])
            break

        case `${ANTECEDENTS_MOTO} ${API_ERROR}`:
        case `${ANTECEDENTS_AUTO} ${API_ERROR}`:
            entity = action.payload.meta.otherData.type === 'moto' ? ANTECEDENTS_MOTO : ANTECEDENTS_AUTO;
            next([
                setError({state: true, entity: entity}),
                setLoader({state: false, entity: entity})
            ])
            break

        case GET_ANTECEDENT_PERIOD:
            next([
                apiRequest({body: null, method: 'GET', url: payload.data, entity: GET_ANTECEDENT_PERIOD, otherData: payload?.otherData}),
                setLoader({state: true, entity: GET_ANTECEDENT_PERIOD})
            ])
            break

        case `${GET_ANTECEDENT_PERIOD} ${API_SUCCESS}`:
            next([
                setAntecedentPeriod({data: payload.data?.data}),
                setError({state: false, entity: GET_ANTECEDENT_PERIOD}),
                setLoader({state: false, entity: GET_ANTECEDENT_PERIOD})
            ])
            break

        case `${GET_ANTECEDENT_PERIOD} ${API_ERROR}`:
            next([
                setError({state: true, entity: GET_ANTECEDENT_PERIOD}),
                setLoader({state: false, entity: GET_ANTECEDENT_PERIOD})
            ])
            break

        case POST_UPDATE_ANTECEDENT_PERIOD:
            clone = _.cloneDeep(action.payload.body);
            clone.startDate = clone.startDate.format('DD/MM/YYYY');
            clone.endDate = clone.endDate.format('DD/MM/YYYY');

            next([
                apiRequest({
                    body: (clone),
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_UPDATE_ANTECEDENT_PERIOD
                }),
                setLoader({state: true, entity: POST_UPDATE_ANTECEDENT_PERIOD})
            ])
            break

        case `${POST_UPDATE_ANTECEDENT_PERIOD} ${API_SUCCESS}`:
            message = `<p>La période a bien été modifiée</p>`
            next([
                setUpdateAntecedentPeriod({state: true}),
                setNotification({
                    entity: POST_UPDATE_ANTECEDENT_PERIOD,
                    body: message,
                    type: 'success',
                    title: 'Période modifiée !'
                }),
                setLoader({state: false, entity: POST_UPDATE_ANTECEDENT_PERIOD}),
                setError({state: false, entity: POST_UPDATE_ANTECEDENT_PERIOD}),
            ])
            break

        case `${POST_UPDATE_ANTECEDENT_PERIOD} ${API_ERROR}`:
            message = `
        <p>Votre demande n'a pas pu être envoyée. ${payload.data.response.data?.message ?? ''}</p>
    `
            next([
                setNotification({
                    entity: POST_UPDATE_ANTECEDENT_PERIOD,
                    body: message,
                    type: 'error',
                    title: 'Erreur lors de la modification',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_UPDATE_ANTECEDENT_PERIOD}),
                setLoader({state: false, entity: POST_UPDATE_ANTECEDENT_PERIOD}),
            ])
            break

        case POST_CREATE_ANTECEDENT:
            clone = _.cloneDeep(action.payload.body);
            clone.startDate = clone.startDate.format('DD/MM/YYYY');
            clone.endDate = clone.endDate.format('DD/MM/YYYY');
            clone.type = clone.type

            next([
                apiRequest({
                    body: (clone),
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_CREATE_ANTECEDENT
                }),
                setLoader({state: true, entity: POST_CREATE_ANTECEDENT})
            ])
            break

        case `${POST_CREATE_ANTECEDENT} ${API_SUCCESS}`:
            message = `<p>La période a bien été ajoutée</p>`
            next([
                setNotification({
                    entity: POST_CREATE_ANTECEDENT,
                    body: message,
                    type: 'success',
                    title: 'Période ajoutée !'
                }),
                setLoader({state: false, entity: POST_CREATE_ANTECEDENT}),
                setError({state: false, entity: POST_CREATE_ANTECEDENT}),
            ])
            break

        case `${POST_CREATE_ANTECEDENT} ${API_ERROR}`:
            message = `
        <p>Votre demande n'a pas pu être envoyée. ${payload.data.response.data?.message ?? ''}</p>
    `
            next([
                setNotification({
                    entity: POST_CREATE_ANTECEDENT,
                    body: message,
                    type: 'error',
                    title: 'Erreur lors de l\'ajout',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_CREATE_ANTECEDENT}),
                setLoader({state: false, entity: POST_CREATE_ANTECEDENT}),
            ])
            break

        default:
            break
    }
    return null
}
