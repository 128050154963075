export const CHANGE_PASSWORD = '[Change Password]'

export const POST_CHANGE_PASSWORD = `${CHANGE_PASSWORD} Post`
export const SET_CHANGE_PASSWORD = `${CHANGE_PASSWORD} Set`

export const postChangePassword = ({email, password, password_confirmation, query}) => ({
    type: POST_CHANGE_PASSWORD,
    payload: {
        data: query,
        body: {email, password, password_confirmation},
    }
})

export const setChangePassword = ({state}) => ({
    type: SET_CHANGE_PASSWORD,
    payload: state
})
