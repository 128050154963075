export const waitingDocsContract = [
    {libelle: 'Attestation de bridage', code: 'TT'},
    {libelle: 'Attestation de conduite 2 roues recto', code: 'U'},
    {libelle: 'Attestation de conduite 2 roues verso', code: 'UA'},
    {libelle: 'Carte grise', code: 'B'},
    {libelle: 'Déclaration d\'antécédents', code: 'I'},
    {libelle: 'Dispositions particulières signées', code: 'H'},
    {libelle: 'Divers', code: 'L'},
    {libelle: 'Facture d\'achat du véhicule', code: 'Q'},
    {libelle: 'Facture d\'achat du vélo', code: 'QQ'},
    {libelle: 'Fiche conseil signée', code: 'W'},
    {libelle: 'Mandat complété et signé', code: 'V'},
    {libelle: 'Mandat Loi Hamon', code: 'Z'},
    {libelle: 'Pièce d\'identité du souscripteur recto', code: 'F'},
    {libelle: 'Pièce d\'identité du souscripteur verso', code: 'FA'},
    {libelle: 'Permis de conduire moto', code: 'S'},
    {libelle: 'Permis de conduire recto', code: 'A'},
    {libelle: 'Permis de conduire verso', code: 'AA'},
    {libelle: 'Règlement', code: 'K'},
    {libelle: 'Relevé d\'informations', code: 'R'},
    {libelle: 'Relevé d\'informations MOTO sur 24 mois', code: 'C'},
    {libelle: 'Relevé d\'informations AUTO sur 24 mois', code: 'D'},
    {libelle: 'Relevé d\'informations MOTO sur 36 mois', code: 'N'},
    {libelle: 'Relevé d\'informations AUTO sur 36 mois', code: 'P'},
    {libelle: 'RIB', code: 'G'},
    {libelle: 'Titre de séjour recto', code: 'X'},
    {libelle: 'Titre de séjour verso', code: 'XX'},
]

export const waitingDocsSinistres = [
    {libelle: 'Arrêté de Catastrophes Naturelles', code: 'U'},
    {libelle: 'Attestation de gravage', code: '13'},
    {libelle: 'Attestation de non-decouverte', code: '20'},
    {libelle: 'Attestation de non alcoolemie', code: '8'},
    {libelle: 'Attestation de TVA', code: '30'},
    {libelle: 'Certificat de cession', code: '18'},
    {libelle: 'Certificat de non gage', code: '19'},
    {libelle: 'Clés antivol', code: '17'},
    {libelle: 'Clés du véhicule', code: '16'},
    {libelle: 'Complément de rapport', code: '0'},
    {libelle: 'Constat amiable signé', code: 'C'},
    {libelle: 'Coordonnées du conducteur', code: '31'},
    {libelle: 'Coordonnées garage', code: '23'},
    {libelle: 'Déclaration achat', code: '22'},
    {libelle: 'Déclaration circonstanciée', code: 'F'},
    {libelle: 'Devis de réparations', code: '32'},
    {libelle: 'Divers', code: '21'},
    {libelle: 'Elements divers CIE', code: 'J'},
    {libelle: 'Facture des réparations', code: '5'},
    {libelle: 'Facture dépannage/gardiennage', code: '12'},
    {libelle: 'Facture équipement et accessoires', code: '11'},
    {libelle: 'Franchise', code: 'K'},
    {libelle: 'Justificatif d\'achat du véhicule', code: '14'},
    {libelle: 'Justificatif antivol SRA', code: '10'},
    {libelle: "Lettre Mise en cause", code: "4"},
    {libelle: 'Original carte grise', code: '15'},
    {libelle: "Permis de conduire du conducteur", code: "27"},
    {libelle: "Pièce d'identité du conducteur", code: "28"},
    {libelle: 'Prêt de guidon', code: '24'},
    {libelle: 'Prise en charge signée', code: '7'},
    {libelle: "Procès-verbal d'accident", code: "29"},
    {libelle: 'Procès-verbal de vol d\'accessoires', code: 'O'},
    {libelle: 'Procès-verbal de découverte de véhicule', code: 'Q'},
    {libelle: 'Procès-verbal de dégradation volontaire', code: 'R'},
    {libelle: 'Procès-verbal de tentative de vol', code: 'P'},
    {libelle: 'Procès-verbal de vol du véhicule', code: 'H'},
    {libelle: 'Questionnaire vol', code: '2'},
    {libelle: 'Rapport de l\'expert', code: '6'},
    {libelle: "Réclamation Cie Adverse", code: "D"},
    {libelle: 'RIB', code: '26'},
    {libelle: 'RIB garage', code: '25'},
    {libelle: 'Témoignage', code: 'E'}
]

export const typePaniere = [
    {code: 'AG_AN', libelle: 'Agenda Affaire Nouvelle'},
    {code: 'AG_INDEM', libelle: 'Agenda Indemnisation'},
    {code: 'AG_RES', libelle: 'Agenda Résiliation'},
    {code: 'AG_AV', libelle: 'Agenda Vie de Contrat'},
    {code: 'AG_IMPAYES', libelle: 'Agenda Impayés'},
    {code: 'AG_COMPTA', libelle: 'Agenda Comptabilité'}
]

export const typeSuivi = [
    {code: 'AUTRE', libelle: 'Autre entrant'},
    {code: 'AUTRS', libelle: 'Autre sortant'},
    {code: 'AUTO', libelle: 'Autorigin'},
    {code: 'TELE', libelle: 'Appel entrant'},
    {code: 'TELS', libelle: 'Appel sortant'},
    {code: 'FAXE', libelle: 'Fax entrant'},
    {code: 'FAXS', libelle: 'Fax sortant'},
    {code: 'COURE', libelle: 'Courrier entrant'},
    {code: 'COURS', libelle: 'Courrier sortant'},
    {code: 'EMAIE', libelle: 'Email entrant'},
    {code: 'EMAIS', libelle: 'Email sortant'},
    {code: 'VISIT', libelle: 'Visite sur site'},
    {code: 'VISIR', libelle: 'Relance visite'},
    {code: 'WEBE', libelle: 'Web courtier entrant'},
    {code: 'WEBS', libelle: 'Web courtier sortant'},
    {code: 'WDE', libelle: 'Web client entrant'},
    {code: 'WDS', libelle: 'Web client sortant'},
    {code: 'SMSS', libelle: 'SMS sortant'},
    {code: 'ASS', libelle: 'Assistance'},
    {code: 'RESC', libelle: 'Reseaux sociaux'},
    {code: 'SURV', libelle: 'Surveillance Portefeuille'},
    {code: 'FDBCK', libelle: 'Feedback'},
    {code: 'TCHAT', libelle: 'Tchat'},
    {code: 'IMP', libelle: 'RDV Impayés'},
]

export const natureAppelSuivi = [
    {code: 'CJ', libelle: 'Client'},
    {code: 'CK', libelle: 'Courtier'},
    {code: 'CL', libelle: 'Compagnie'},
    {code: 'CX', libelle: 'Compagnie adverse'},
    {code: 'CZ', libelle: 'Concessionnaire'},
    {code: 'EA', libelle: 'Expert'},
    {code: 'AD', libelle: 'Assistance'},
    {code: 'RH', libelle: 'Réparateur'},
    {code: 'TM', libelle: 'Témoin'},
    {code: 'FO', libelle: 'Forces de police'},
]

export const natureTlv = [
    {code: 'R6', libelle: 'Répondeur 1'},
    {code: 'R7', libelle: 'Répondeur 2'},
    {code: 'R8', libelle: 'Répondeur 3'},
    {code: 'R9', libelle: 'Répondeur 4'},
    {code: 'A4', libelle: 'Absence de répondeur'},
    {code: 'FN', libelle: 'Faux numéro'},
    {code: 'RO', libelle: 'Refus de répondre'},
    {code: 'RW', libelle: 'Rappel personnel'},
    {code: 'S3', libelle: 'S’engage à faire le nécessaire'},
    {code: 'C6', libelle: 'Client refuse envoi de pièces'},
    {code: 'P1', libelle: 'Pièces reçues non conformes'},
    {code: 'TS', libelle: 'Transfert autre service'},
    {code: 'EI', libelle: 'Erreur interne - pièce déjà validée'},
    {code: 'A5', libelle: 'Aide pour envoi'},
    {code: 'D4', libelle: 'Dossier complet après appel'},
    {code: 'D5', libelle: 'Dossier ok avant appel'},
    {code: 'RY', libelle: 'Résiliation / Rétractation'},
]

export const natureAutreSuivi = [
    {code: 'AO', libelle: 'ARGOS'},
    {code: 'CT', libelle: 'Constat'},
    {code: 'C2', libelle: 'CTRL_N_CALL_OK'},
    {code: 'C3', libelle: 'CTRL_N_CALL_KO'},
    {code: 'C4', libelle: 'CTRL_N_CALL_VALIDE'},
    {code: 'DE', libelle: 'Dérogation'},
    {code: 'DB', libelle: 'Dérogation Bonus'},
    {code: 'BD', libelle: 'Dispositions Particulières / Contrat'},
    {code: 'EB', libelle: 'Explication comptable'},
    {code: 'FA', libelle: 'Facture'},
    {code: 'FI', libelle: 'Fidélisation'},
    {code: 'FR', libelle: 'Fraude avérée'},
    {code: 'GC', libelle: 'Geste Co'},
    {code: 'LV', libelle: 'Levée de suspicion Compagnie'},
    {code: 'MB', libelle: 'MAJ Déclaration'},
    {code: 'PF', libelle: 'Paiement iQera OK'},
    {code: 'PW', libelle: 'Prise en charge'},
    {code: 'PV', libelle: 'Procès verbal'},
    {code: 'RX', libelle: 'Rapport d\'expert'},
    {code: 'R5', libelle: 'RTEL_KO'},
    {code: 'R4', libelle: 'RTEL_OK'},
    {code: 'RJ', libelle: 'Rejet de remboursement'},
    {code: 'SP', libelle: 'Suspicion de Fraude'},
    {code: 'SL', libelle: 'TRACFIN'},
    {code: 'AU', libelle: 'Autre'},

]

// Alignement devis APRIL Moto
// Alignement concurrence
// Réception avis échéance - hausse tarif
// Hors échéance - tarif
// Demande résiliation - hausse tarif
// Demande RI - hausse tarif

export const motifSuivi = [
    {label: "Alignement devis APRIL Moto"},
    {label: "Alignement concurrence"},
    {label: "Réception avis échéance - hausse tarif"},
    {label: "Hors échéance - tarif"},
    {label: "Demande résiliation - hausse tarif"},
    {label: "Demande RI - hausse tarif"},
]

export const revuesContrat = [
    {label: "Modification des garanties"},
    {label: "Changement d’usage"},
    {label: "Franchises fixes"},
    {label: "Changement fractionnement"},
    {label: "Retrait – ajout options"},
]


export const motifResil = [
    {code: '0', libelle: 'Résiliation Loi Hamon'},
    {code: '2', libelle: 'Résil/Vol'},
    {code: '3', libelle: 'Résil/Assuré'},
    {code: '4', libelle: 'Résil/Sinistre'},
    {code: 'A', libelle: 'Résiliation suite à vente'},
    {code: 'B', libelle: 'Résiliation à échéance'},
    {code: 'C', libelle: 'Résiliation alcoolémie / stupéfiant'},
    {code: 'D', libelle: 'Résiliation pour augmentation'},
    {code: 'F', libelle: 'Résiliation pour aggravation du risque'},
    {code: 'H', libelle: 'Résiliation / Echéance / Compagnie'},
    {code: 'K', libelle: 'Résiliation pour fausse déclaration'},
    {code: 'L', libelle: 'Résiliation Loi Chatel'},
    {code: 'Q', libelle: 'Résiliation rétractation 14 jours'},
    {code: 'W', libelle: 'Suspendu'},
    {code: 'X', libelle: 'Résilié non achat'},
    {code: 'Z', libelle: 'Résil/Omission/Fausse déclaration'},
    {code: '9', libelle: 'Sans effet'},
]

export const motifResilVAE = [
    {code: '2', libelle: 'Résil/Vol'},
    {code: '3', libelle: 'Résil/Assuré'},
    {code: '4', libelle: 'Résil/Sinistre'},
    {code: 'A', libelle: 'Résiliation suite à vente'},
    {code: 'B', libelle: 'Résiliation à échéance'},
    {code: 'D', libelle: 'Résiliation pour augmentation'},
    {code: 'K', libelle: 'Résiliation pour fausse déclaration'},
    {code: 'L', libelle: 'Résiliation Loi Chatel'},
    {code: 'Q', libelle: 'Résiliation rétractation 14 jours'},
    {code: 'V', libelle: 'Nullité de contrat'},
    {code: 'X', libelle: 'Résilié non achat'},
    {code: '9', libelle: 'Sans effet'},
]

export const motifResilNVEI = [
    {code: '3', libelle: 'Résil/Assuré'},
    {code: '4', libelle: 'Résil/Sinistre'},
    {code: 'D', libelle: 'Résiliation pour augmentation'},
    {code: 'Q', libelle: 'Résiliation rétractation 14 jours'},
    {code: '9', libelle: 'Sans effet'},
    {code: 'B', libelle: 'Résiliation à échéance'},
    {code: 'L', libelle: 'Résiliation Loi Chatel'},
    {code: 'V', libelle: 'Nullité de contrat'},
    {code: 'Z', libelle: 'Résil/Omission/Fausse déclaration'},
]


export const courtiersPremium = [
    {code: '248674', libelle: 'AGL Assurance'},
    {code: '112345', libelle: 'Andre Fontaine assurances'},
    {code: '111357', libelle: 'Assuparis'},
    {code: '109283', libelle: 'ASSUR MA'},
    {code: '120134', libelle: 'Assurance & cie'},
    {code: '120046', libelle: 'Assurquad'},
    {code: '356294', libelle: 'Azur assurances 06'},
    {code: '30057', libelle: 'Clamart services'},
    {code: '30680', libelle: 'Delahaye Regis'},
    {code: '541833', libelle: 'FORT ASSURANCES 93'},
    {code: '30727', libelle: 'Jaouen Philippe'},
    {code: '111538', libelle: 'Legitim conseil'},
    {code: '122463', libelle: 'Motoassure'},
    {code: '252439', libelle: 'Polaris assurances'},
    {code: '30471', libelle: 'Raynaud Frederic'},
    {code: '105738', libelle: 'Retro-quattro assurances'},
    {code: '182396', libelle: 'Urbassur'},
    {code: '116348', libelle: 'Zen assurance'},
    {
        code: '248674,112345,111357,109283,120134,120046,356294,30057,30680,30727,111538,122463,252439,30471,105738,182396,116348',
        libelle: 'Tous les Premium'
    }
]

export const typeAgenda = [
    {code: 'Avenant', codeBelair: 'Avenant', libelle: 'Avenant'},
    {code: 'CB avant rejet', codeBelair: 'CB avant rejet', libelle: 'CB avant rejet'},
    {code: 'Changement de véhicule', codeBelair: 'Changement de véhicule', libelle: 'Changement de véhicule'},
    {
        code: 'Changement de fractionnement',
        codeBelair: 'Changement de fractionnement',
        libelle: 'Changement de fractionnement'
    },
    {
        code: 'Délivrer une prise en charge',
        codeBelair: 'Delivrer une prise en charge',
        libelle: 'Délivrer une prise en charge'
    },
    {
        code: 'Demande d\'opposition',
        codeBelair: 'Demande d\'opposition',
        libelle: 'Demande d\'opposition'
    },
    {code: 'Expiration d\'une pièce', codeBelair: 'Expiration d\'une piece', libelle: 'Expiration d\'une pièce'},
    {code: 'Info prime', codeBelair: 'Info prime', libelle: 'Info prime'},
    {code: 'Loi Hamon', codeBelair: 'Loi Hamon', libelle: 'Loi Hamon'},
    {code: 'Edition de document', codeBelair: 'Edition de document', libelle: 'Edition de document'},
    {code: 'MAJ déclaration', codeBelair: 'MAJ declaration', libelle: 'MAJ déclaration'},
    {code: 'Ordre de Placement', codeBelair: 'Ordre de Placement', libelle: 'Ordre de placement (ODP)'},
    {code: 'Rappel client', codeBelair: 'Rappel client', libelle: 'Rappel client'},
    {code: 'Résiliation', codeBelair: 'Resiliation', libelle: 'Résiliation'},
    {code: 'Règlement à faire', codeBelair: 'Reglement a faire', libelle: 'Règlement à faire'},
    {code: 'Re credit CB', codeBelair: 'Re credit CB', libelle: 'Re credit CB'},
    {code: 'Relance expert', codeBelair: 'Relance expert', libelle: 'Relance expert'},
    {code: 'Relance compagnie', codeBelair: 'Relance compagnie', libelle: 'Relance compagnie'},
    {code: 'Sinistre en attente', codeBelair: 'Sinistre en attente', libelle: 'Sinistre en attente'},
    {code: 'Traitement dossier', codeBelair: 'Traitement dossier', libelle: 'Traitement dossier'},
    {code: 'Suivi de la réclamation', codeBelair: 'Suivi de la reclamation', libelle: 'Suivi de la réclamation'},
    {code: 'Virement rejeté', codeBelair: 'Virement rejeté', libelle: 'Virement rejeté'},
]

export const typeAgendaAN = [
    {code: 'MAJ déclaration', codeBelair: 'MAJ declaration', libelle: 'MAJ déclaration'},
    {code: 'Info prime', codeBelair: 'Info prime', libelle: 'Info prime'},
    {code: 'Edition de document', codeBelair: 'Edition de document', libelle: 'Edition de document'},
    {code: 'Rappel client', codeBelair: 'Rappel client', libelle: 'Rappel client'},
    {code: 'Résiliation', codeBelair: 'Resiliation', libelle: 'Résiliation'},
    {code: 'Traitement dossier', codeBelair: 'Traitement dossier', libelle: 'Traitement dossier'},
    {code: 'Sinistre en attente', codeBelair: 'Sinistre en attente', libelle: 'Sinistre en attente'},
]

export const typeAgendaAG_AV = [
    {code: 'Demande d\'avenant', codeBelair: 'Demande d avenant', libelle: 'Demande d\'avenant'},
    {code: 'Info prime', codeBelair: 'Info prime', libelle: 'Info prime'},
    {code: 'Edition de document', codeBelair: 'Edition de document', libelle: 'Edition de document'},
    {code: 'Ordre de Placement', codeBelair: 'Ordre de Placement', libelle: 'Ordre de placement (ODP)'},
    {code: 'Rappel client', codeBelair: 'Rappel client', libelle: 'Rappel client'},
    {code: 'Règlement à faire', codeBelair: 'Reglement a faire', libelle: 'Règlement à faire'},
    {code: 'Traitement dossier', codeBelair: 'Traitement dossier', libelle: 'Traitement dossier'},
]

export const typeAgendaIndemn = [
    {
        code: 'Délivrer une prise en charge',
        codeBelair: 'Delivrer une prise en charge',
        libelle: 'Délivrer une prise en charge'
    },
    {code: 'Expiration d\'une pièce', codeBelair: 'Expiration d\'une piece', libelle: 'Expiration d\'une pièce'},
    {code: 'Edition de document', codeBelair: 'Edition de document', libelle: 'Edition de document'},
    {code: 'Rappel client', codeBelair: 'Rappel client', libelle: 'Rappel client'},
    {code: 'Règlement à faire', codeBelair: 'Reglement a faire', libelle: 'Règlement à faire'},
    {code: 'Relance expert', codeBelair: 'Relance expert', libelle: 'Relance expert'},
    {code: 'Relance compagnie', codeBelair: 'Relance compagnie', libelle: 'Relance compagnie'},
    {code: 'Traitement dossier', codeBelair: 'Traitement dossier', libelle: 'Traitement dossier'},
    {code: 'Virement rejeté', codeBelair: 'Virement rejeté', libelle: 'Virement rejeté'},
]

export const typeAgendaResil = [
    {code: 'Expiration d\'une pièce', codeBelair: 'Expiration d\'une piece', libelle: 'Expiration d\'une pièce'},
    {code: 'Info prime', codeBelair: 'Info prime', libelle: 'Info prime'},
    {code: 'Edition de document', codeBelair: 'Edition de document', libelle: 'Edition de document'},
    {code: 'Rappel client', codeBelair: 'Rappel client', libelle: 'Rappel client'},
    {code: 'Résiliation', codeBelair: 'Resiliation', libelle: 'Résiliation'},
    {code: 'Règlement à faire', codeBelair: 'Reglement a faire', libelle: 'Règlement à faire'},
    {code: 'Traitement dossier', codeBelair: 'Traitement dossier', libelle: 'Traitement dossier'},
]

export const typeAgendaCompta = [
    {code: 'CB avant rejet', codeBelair: 'CB avant rejet', libelle: 'CB avant rejet'},
    {code: 'Re credit CB', codeBelair: 'Re credit CB', libelle: 'Re-crédit CB'},
    {code: 'Demande d\'opposition', codeBelair: 'Demande d\'opposition', libelle: 'Demande d\'opposition'},
]

export const typeAgendaQualite = [
    {code: 'Suivi de la réclamation', codeBelair: 'Suivi de la reclamation', libelle: 'Suivi de la réclamation'},
    {code: 'Suivi suspicion Fraude', codeBelair: 'Suivi suspicion Fraude', libelle: 'Suivi suspicion Fraude'},
]

export const typeAgendaImpayes = [
    {code: 'Suivi impayés', codeBelair: 'Suivi impayés', libelle: 'Suivi impayés'},
]

export const paniereActivityCompta = [
    {code: 'REG_CHQ', title: 'Demande de règlement par chèque par Service Compta'},
]

export const typeAgendaFid = [
    {
        code: 'Alignement devis APRIL Moto',
        codeBelair: 'Alignement devis APRIL Moto',
        libelle: 'Alignement devis APRIL Moto'
    },
    {
        code: 'Alignement concurrence',
        codeBelair: 'Alignement concurrence',
        libelle: 'Alignement concurrence'
    },
    {
        code: 'Réception avis échéance - hausse tarif',
        codeBelair: 'Réception avis échéance - hausse tarif',
        libelle: 'Réception avis échéance - hausse tarif'
    },
    {
        code: 'Hors échéance - tarif',
        codeBelair: 'Hors échéance - tarif',
        libelle: 'Hors échéance - tarif'
    },
    {
        code: 'Demande résiliation - hausse tarif',
        codeBelair: 'Demande résiliation - hausse tarif',
        libelle: 'Demande résiliation - hausse tarif'
    },
    {
        code: 'Demande RI - hausse tarif',
        codeBelair: 'Demande RI - hausse tarif',
        libelle: 'Demande RI - hausse tarif'
    }
]

export const typeAgendaSuiviFid = [
    {
        code: 'Rappel client',
        codeBelair: 'Rappel client',
        libelle: 'Rappel client'
    },
    {
        code: 'Rappel courtier',
        codeBelair: 'Rappel courtier',
        libelle: 'Rappel courtier'
    },
]

export const emailCompagnieSinistre = [
    'allianzccau@allianz.fr',
    'corporel@allianz.fr',
    'allianzdpr1@allianz.fr',
    'allianzsia@allianz.fr',
    'dibcvei@allianz.fr'
]

export const idas = [
    {code: '10X', libelle: '10X : 0'},
    {code: '10Y', libelle: '10Y : 100'},
    {code: '13X', libelle: '13X : 50'},
    {code: '13Y', libelle: '13Y : 50'},
    {code: '15X', libelle: '15X : 0'},
    {code: '15Y', libelle: '15Y : 100'},
    {code: '17X', libelle: '17X : 50'},
    {code: '17Y', libelle: '17Y : 50'},
    {code: '20X', libelle: '20X : 0'},
    {code: '20Y', libelle: '20Y : 100'},
    {code: '21X', libelle: '21X : 50'},
    {code: '21Y', libelle: '21Y : 50'},
    {code: '30X', libelle: '30X : 0'},
    {code: '30Y', libelle: '30Y : 100'},
    {code: '31X', libelle: '31X : 50'},
    {code: '31Y', libelle: '31Y : 50'},
    {code: '40X', libelle: '40X : 0'},
    {code: '40Y', libelle: '40Y : 100'},
    {code: '43X', libelle: '43X : 50'},
    {code: '43Y', libelle: '43Y : 50'},
    {code: '50X', libelle: '50X : 0'},
    {code: '50Y', libelle: '50Y : 100'},
    {code: '51X', libelle: '51X : 0'},
    {code: '51Y', libelle: '51Y : 100'},
    {code: '56X', libelle: '56X : 50'},
    {code: '56Y', libelle: '56Y : 50'},
    {code: 'HIX', libelle: 'HIX : 0'},
    {code: 'HIY', libelle: 'HIY : 50'},
    {code: 'HIZ', libelle: 'HIZ : 100'},
    {code: 'HIV', libelle: 'HIV : 25'},
    {code: 'HIW', libelle: 'HIW : 75'},
];

export const categorieSin = [
    {code: '103', libelle: 'Accident avec Tiers'},
    {code: '104', libelle: 'Accident sans Tiers'},
    {code: '19', libelle: 'Evenements climatiques'},
    {code: '18', libelle: 'Incendie'},
    {code: '105', libelle: 'Tentative de vol'},
    {code: '24', libelle: 'Vandalisme'},
    {code: '10', libelle: 'Vol Total'},
    {code: '9', libelle: 'Volé et retrouvé'}
]

export const paysSinistre = [
    {value: "albanie", label: "Albanie"},
    {value: "allemagne", label: "Allemagne"},
    {value: "andorre", label: "Andorre"},
    {value: "autriche", label: "Autriche"},
    {value: "azerbaidjan", label: "Azerbaïdjan"},
    {value: "belgique", label: "Belgique"},
    {value: "bielorussie", label: "Biélorussi"},
    {value: "bosnie", label: "Bosnie-Herzegovine"},
    {value: "bulgarie", label: "Bulgarie"},
    {value: "chypre", label: "Chypre"},
    {value: "croatie", label: "Croatie"},
    {value: "danemark", label: "Danemark"},
    {value: "espagne", label: "Espagne"},
    {value: "estonie", label: "Estonie"},
    {value: "finlande", label: "Finlande"},
    {value: "gb", label: "Grande-Bretagne"},
    {value: "grece", label: "Grèce"},
    {value: "hongrie", label: "Hongrie"},
    {value: "italie", label: "Italie"},
    {value: "iran", label: "Iran"},
    {value: "irlande", label: "Irlande"},
    {value: "islande", label: "Islande"},
    {value: "israel", label: "Israël"},
    {value: "lettonie", label: "Lettonie"},
    {value: "lituanie", label: "Lituanie"},
    {value: "luxembourg", label: "Luxembourg"},
    {value: "macedoine", label: "Macédoine du Nord"},
    {value: "malte", label: "Malte"},
    {value: "maroc", label: "Maroc"},
    {value: "moldavie", label: "Moldavie"},
    {value: "montenegro", label: "Monténégro"},
    {value: "norvege", label: "Norvège"},
    {value: "pologne", label: "Pologne"},
    {value: "portugal", label: "Portugal"},
    {value: "tcheque", label: "République-Tchèque"},
    {value: "roumanie", label: "Roumanie"},
    {value: "russie", label: "Russie"},
    {value: "serbie", label: "Serbie"},
    {value: "slovaquie", label: "Slovaquie"},
    {value: "slovenie", label: "Slovénie"},
    {value: "suede", label: "Suède"},
    {value: "suisse", label: "Suisse"},
    {value: "tunisie", label: "Tunisie"},
    {value: "turquie", label: "Turquie"},
    {value: "ukraine", label: "Ukraine"}
]

export const rolesCorrespondant = [
    {label: "Administrateur"},
    {label: "Agent immobilier"},
    {label: "Associé"},
    {label: "Associé Gérant"},
    {label: "contremaitre"},
    {label: "Co-Gérant"},
    {label: "Commercial"},
    {label: "Consultant"},
    {label: "Contrôleur de Gestion"},
    {label: "Directeur Commercial"},
    {label: "Directeur Général"},
    {label: "Directeur Général Délégué"},
    {label: "Directeur Général Unique"},
    {label: "Directeur Informatique"},
    {label: "Directeur Production"},
    {label: "Directeur Ressources Humaines"},
    {label: "Expert comptable"},
    {label: "Exploitant"},
    {label: "Fondé de Pouvoir"},
    {label: "Gérant"},
    {label: "Gérant Non Associé"},
    {label: "Inspecteur"},
    {label: "chef comptable"},
    {label: "Liquidateur"},
    {label: "ouvrier arboricole"},
    {label: "Président du Conseil d'Administration"},
    {label: "Président du Conseil de Surveillance"},
    {label: "Président Directeur Général"},
    {label: "Président du Directoire"},
    {label: "Personne engageant l'Assujetti"},
    {label: "Président"},
    {label: "Représentant en France Société Etrangère"},
    {label: "Secrétaire"},
    {label: "chef des ventes"},
    {label: "Webmaster"},
    {label: "Spécialiste MOTO"},
    {label: "Producteur Moto"}
]

export const accidentStatus = [
    {value: "A", label: "Sinistre Pré-déclaré"},
    {value: "3", label: "Sinistre En attente"},
    {value: "1", label: "Sinistre Ouvert"},
    {value: "8", label: "Sinistre Clos"},
    {value: "9", label: "Sans suite"},
]

export const accidentStatusEnAttentePreDec = [
    {value: "A", label: "Sinistre Pré-déclaré"},
    {value: "3", label: "Sinistre En attente"},
    {value: "1", label: "Sinistre Ouvert"},
    {value: "9", label: "Sans suite"},
]

export const accidentStatusOuvert = [
    {value: "1", label: "Sinistre Ouvert"},
    {value: "8", label: "Sinistre Clos"},
    {value: "9", label: "Sans suite"},
]

export const accidentClosMotif = [
    {label: "Règlement"},
    {label: "Non déclaré"},
    {label: "Dommages non couvert"},
    {label: "Recours KO"},
    {label: "Client demande la clôture"},
    {label: "Dommages inférieurs à la franchise"},
    {label: "Rapport de carence"},
    {label: "Prescription (sinistre + 2 ans)"},
    {label: "Autre"},
    {label: "Ouverture pour corpo : dossier transmis à Allianz"},
]

export const motifInsatisfactionFeedback = [
    {code: 'A2', libelle: 'Accessibilité'},
    {code: 'A3', libelle: 'Attente de l’action sous 48h'},
    {code: 'C1', libelle: 'Client injoignable'},
    {code: 'D1', libelle: 'Délai de traitement'},
    {code: 'D2', libelle: 'Désaccord sur application des règles'},
    {code: 'E1', libelle: 'Engagement non tenu'},
    {code: 'F1', libelle: 'Fluidité dossier / échange'},
    {code: 'Q1', libelle: 'Qualité de service'},
    {code: 'S1', libelle: 'Savoir faire'},
    {code: 'S2', libelle: 'Savoir être'},
    {code: 'T1', libelle: 'Traitement ou réponse erroné'},
    {code: 'N2', libelle: 'Ne se prononce pas'},
]

export const tiersPayeurRelation = [
    {code: 'C', libelle: 'Conjoint'},
    {code: 'P', libelle: 'Parent'},
    {code: 'FS', libelle: 'Frère/Sœur'},
    {code: 'GP', libelle: 'Grand-parent'},
    {code: 'E', libelle: 'Enfant'},
    {code: 'A', libelle: 'Autres'},
]

export const listFormeJuridique = [
    {'id': "EARL", 'name': 'EARL'},
    {'id': "EURL", 'name': 'EURL'},
    {'id': "GIE", 'name': 'GIE'},
    {'id': "SA", 'name': 'SA'},
    {'id': "SARL", 'name': 'SARL'},
    {'id': "SAS", 'name': 'SAS'},
    {'id': "SASU", 'name': 'SASU'},
    {'id': "SC", 'name': 'SC'},
    {'id': "SCA", 'name': 'SCA'},
    {'id': "SCI", 'name': 'SCI'},
    {'id': "SCM", 'name': 'SCM'},
    {'id': "SCOP", 'name': 'SCOP'},
    {'id': "SCP", 'name': 'SCP'},
    {'id': "SCS", 'name': 'SCS'},
    {'id': "SELRL", 'name': 'SELRL'},
    {'id': "SNC", 'name': 'SNC'},
    {'id': "SAEM", 'name': 'SAEM'},
    {'id': "ETS", 'name': 'ETS'},
    {'id': "EIRL", 'name': 'EIRL'},
]

export const codeCompagnys = ['390001', '390282', '164180', '390007', '390006', '390321', '390134', '390008', '390009', '390010', '390012', '390219', '390014', '390017', '390018', '390019', '390020', '390021', '390022', '390023', '390024', '390025', '33276', '390071', '390334', '390027', '390028', '390029', '390030', '390031', '390004', '390032', '390033', '390034', '390036', '390037', '390038', '390039', '390040', '390041', '390280', '390043', '390044', '390045', '390046', '390273', '390047', '390048', '390049', '390050', '390058', '390051', '390052', '390053', '390054', '390055', '390056', '390057', '390059', '225173', '136746', '222340', '164180', '185466', '390060', '390061', '390062', '390063', '390064', '390065', '390066', '390067', '390069', '390070', '124246', '390071', '390072', '390073', '390074', '390075', '390076', '390078', '390079', '390080', '390081', '390082', '390083', '390084', '390085', '390086', '390087', '113179', '176971', '390089', '390090', '390103', '390091', '390092', '390093', '390094', '390096', '390097', '390007', '390100'];
