import React from 'react';
import {Field} from "redux-form";
import SelectFieldRedux from "../../../../Commun/Input/Select/SelectFieldRedux";
import Spinner from "../../../../Commun/Spinner/Spinner";
import TextAreaFieldRedux from "../../../../Commun/Input/Text/TextAreaFieldRedux";


const ChangeResponsabilite = (props) => {
    const {loading, handleSubmit} = props

    return (
        <div className={'contract-container container'}>
            <div className={'row return-content'}>
                <div className={'col'}>
                    <button onClick={() => props.history.go(-1)} className={'btn btn-return'}>Retour</button>
                </div>
            </div>
            <div className={'row header-row'}>
                <div className={'col-12'}>
                    <form className={'form-content'} onSubmit={handleSubmit}>
                        <Field
                            name='resp'
                            component={SelectFieldRedux}
                            label='Taux de responsabilité'
                        >
                            <option>Sélectionnez un taux</option>
                            <option value="0">0%</option>
                            <option value="50">50%</option>
                            <option value="100">100%</option>
                        </Field>

                        <Field
                            name={'description'}
                            component={TextAreaFieldRedux}
                            label={'Description du motif de changement de responsabilité'}
                            subLabel={'255 caractères maximum'}
                            maxLength={'255'}
                            rows="4"
                        />
                        <div className={'alert alert-warning'}>
                            Attention, ce motif sera inclus dans l’email qui sera envoyé automatiquement au client à la
                            validation du changement de responsabilité.
                        </div>

                        {
                            loading ? <Spinner style={{display: 'block', margin: 'auto'}}/> :
                                <button className={'btn btn-primary d-block'} type={'submit'}>
                                    Mettre à jour
                                </button>
                        }
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ChangeResponsabilite;
