import {connect} from 'react-redux'
import {compose} from 'redux'

import ErrorHttpComponent from '../../../Commun/Error/ErrorHttpComponent'
import RedirectToList from "./RedirectToList";
import {getUserRoles} from "../../../../redux/selectors/user/user.selectors";
import loader from "../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    return {
        roles: getUserRoles(state)
    }
}

const RedirectToListContainer = compose(
    connect(mapStateToProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(RedirectToList)

export default RedirectToListContainer
