import React from 'react';
import CardChoc from "./CardChoc/CardChoc";

const Chocs = (props) => {
    const {produit} = props
    return (
        <div className={'form-content h-100'}>
            <h1 className={'title-primary'}>Cocher le point de choc initial de l'accident</h1>

            <CardChoc produit={produit}/>
        </div>
    );
};

export default Chocs;
