import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, formValueSelector, reduxForm } from 'redux-form';
import { postTaskFluxMail, POST_FLUX_MAIL } from '../../../../../../redux/actions/app/tasks/tasks.actions';
import { postTaskFluxMailGetLink } from '../../../../../../redux/selectors/links/links.selectors';
import { getErrorEntity, getLoadingEntity } from '../../../../../../redux/selectors/ui/ui.selectors';
import ErrorHttpComponent from '../../../../../Commun/Error/ErrorHttpComponent';
import loader from '../../../../../Commun/Spinner/Loader/Loader';
import FluxMail from './FluxMail';

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector('flux_mail');

    return {
        task: ownProps.task,
        formValues: selector(state, 'mail_empty', 'mail_derogation', 'paniere'),
        uri: postTaskFluxMailGetLink(state),
        loading: getLoadingEntity(state, POST_FLUX_MAIL),
        error: getErrorEntity(state, POST_FLUX_MAIL),
    };
};

const mapDispatchToProps = {
    changeValue: (field, value) => change(`flux_mail`, field, value),
    postTaskFluxMail,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { uri, task } = stateProps;
    const { postTaskFluxMail } = dispatchProps;

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        onSubmit: (form) =>
            postTaskFluxMail({
                query: _.replace(uri, '{taskId}', task.id),
                form,
            }),
    };
};

const FluxMailContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'flux_mail',
    }),
    loader({ ErrorIndicator: ErrorHttpComponent }),
)(FluxMail);

export default FluxMailContainer;
