import {bindActionCreators, compose} from 'redux'
import _ from 'lodash'
import {connect} from 'react-redux'

import {
    TASK,
    getTask,
    postCloseTask,
    CLOSE_TASK
} from '../../../../../redux/actions/app/tasks/tasks.actions'
import {
    getErrorEntity,
    getLoadingEntity,
    getValidateDocumentsPending
} from '../../../../../redux/selectors/ui/ui.selectors'
import TaskDetail from './TaskDetail'
import {
    taskDetailGetLink,
    validateDocumentGetLink,
    postChangeSituationContractLink
} from '../../../../../redux/selectors/links/links.selectors'
import {getTaskDetailData} from "../../../../../redux/selectors/tasks/task.selectors";
import {getCloseTask} from "../../../../../redux/selectors/close_task/close_task.selectors";
import {
    POST_VALIDATE_DOCUMENTS_TASK,
    postValidateDocumentsTask,
} from "../../../../../redux/actions/app/validate_documents/validate_documents.actions";
import {
    postChangeSituationContract,
    CHANGE_SITUATION_CONTRACT
} from "../../../../../redux/actions/app/change_situation_contract/change_situation_contract.actions";
import {getReassignTask} from "../../../../../redux/selectors/reassign_task/reassign_task.selectors";
import {logAction} from "../../../../../redux/actions/core/logs/logs.actions";
import loader from "../../../../Commun/Spinner/Loader/Loader";
import {getUserIdBelair} from "../../../../../redux/selectors/user/user.selectors";

const mapStateToProps = (state, ownProps) => {
    const taskDetail = getTaskDetailData(state);


    return {
        belair_id: getUserIdBelair(state),
        taskDetail: taskDetail,
        uri: taskDetailGetLink(state),
        id: ownProps.match.params.id,
        terminate: getCloseTask(state),
        reassign: getReassignTask(state),
        loaded: getLoadingEntity(state, TASK) === false,
        error: getErrorEntity(state, TASK),
        goToForm: ownProps.location.state ? ownProps.location.state.goToForm : false,
        validateDocumentsPending: getValidateDocumentsPending(state),
        uriValidateDocument: validateDocumentGetLink(state),
        uriValidateContract: postChangeSituationContractLink(state),
        loading: getLoadingEntity(state, CHANGE_SITUATION_CONTRACT),
        loadingTerminate: getLoadingEntity(state, CLOSE_TASK),
        loadingDoc: getLoadingEntity(state, POST_VALIDATE_DOCUMENTS_TASK),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
    getTask: (query) => getTask({query}),
    postCloseTask: (query) => postCloseTask({query}),
    postValidateContract: ({type, query}) => postChangeSituationContract({type, query}),
    postValid: ({tiers, risque, pieces, query, queryCloseTask}) => postValidateDocumentsTask({
        tiers,
        risque,
        pieces,
        query,
        queryCloseTask
    }),
    postValidSinistre: ({tiers, risque, pieces, query, queryCloseTask, sinistre}) => postValidateDocumentsTask({
        tiers,
        risque,
        pieces,
        query,
        queryCloseTask,
        sinistre
    }),
    logAction: (paniere) => logAction({type: 'SHOW_TASK', task: ownProps.match.params.id, paniere})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getTask, postValid, postValidateContract, postValidSinistre} = dispatchProps
    const {taskDetail, uri, id, validateDocumentsPending, uriValidateDocument, uriValidateContract} = stateProps

    const souscripteurId = taskDetail && taskDetail.contract && taskDetail.contract.souscripteur ? taskDetail.contract.souscripteur.id : null

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getTask(_.replace(uri, '{id}', id)),
        postValidateContract: () => postValidateContract({
            type: 'validate',
            query: _.replace(uriValidateContract, '{id}', taskDetail.contract.id)
        }),
        postValid: () => postValid({
            tiers: souscripteurId,
            risque: taskDetail.contract?.adhesionPrincipale,
            pieces: validateDocumentsPending,
            query: _.replace(uriValidateDocument, '{id}', taskDetail.contract.id),
            queryCloseTask: taskDetail.terminate_url
        }),
        postValidSinistre: () => postValidSinistre({
            tiers: souscripteurId,
            risque: taskDetail.contract?.adhesionPrincipale,
            pieces: validateDocumentsPending,
            query: _.replace(uriValidateDocument, '{id}', taskDetail.contract.id),
            queryCloseTask: taskDetail.terminate_url,
            sinistre: taskDetail.sinistre.id,
        }),
    }
}

const TaskDetailContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(TaskDetail)

export default TaskDetailContainer
