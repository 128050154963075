import {connect} from 'react-redux';
import {compose} from 'redux';
import _ from 'lodash';
import FlotteDetail from "./FlotteDetail";
import {contractDefaultGetLink, getFleetGetLink} from "../../../../../../redux/selectors/links/links.selectors";
import {getFlotteDetail, FLOTTE_DETAIL} from "../../../../../../redux/actions/app/flotte/flotte.actions";
import {getFlotteDetailData} from "../../../../../../redux/selectors/flotte/flotte.selectors";
import {getErrorEntity, getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {getContractData} from "../../../../../../redux/selectors/contract/contract.selectors";
import {CONTRACT, getContract} from "../../../../../../redux/actions/app/contracts/contracts.actions";
import {getUserRoles} from "../../../../../../redux/selectors/user/user.selectors";
import loader from "../../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    return {
        uri: getFleetGetLink(state),
        uri_contract: contractDefaultGetLink(state),
        contractId: ownProps.match.params.id,
        vehiculeId: ownProps.match.params.ids,
        vehicule: getFlotteDetailData(state),
        contract: getContractData(state),
        loaded: getLoadingEntity(state, FLOTTE_DETAIL) === false && getLoadingEntity(state, CONTRACT) === false,
        error: getErrorEntity(state, FLOTTE_DETAIL) && getLoadingEntity(state, CONTRACT),
        roles: getUserRoles(state),
    }
}

const mapDispatchToProps = {
    getFlotteDetail,
    getContract,
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uri, uri_contract, vehiculeId, contractId} = stateProps
    const {getFlotteDetail, getContract} = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => {
            getFlotteDetail({query: _.replace(uri, '{id}', vehiculeId)});
            getContract({query: _.replace(uri_contract, '{id}', contractId), id: contractId});
        },

    }
}

const FlotteDetailContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(FlotteDetail)

export default FlotteDetailContainer
