import React, {useEffect} from 'react'
import {Field} from "redux-form";
import TextFieldRedux from "../../../../Commun/Input/Text/TextFieldRedux";
import MyButton from "../../../../Commun/Button/Button";
import Spinner from "../../../../Commun/Spinner/Spinner";

const SendSignature = (props) => {
    const {loading, handleSubmit, phone, email} = props

    useEffect(() => {
        props.changeValue('email', email)
        props.changeValue('phone', phone)
    }, [props.changeValue]);

    return (
        <div className={'contract-container container'}>
            <div className={'row return-content'}>
                <div className={'col'}>
                    <button onClick={() => props.history.go(-1)} className={'btn btn-return'}>Retour</button>
                </div>
            </div>
            <div className={'row header-row'}>
                <div className={'col-12'}>
                    <form className={'form-content'} onSubmit={handleSubmit}>
                        <Field
                            name='email'
                            component={TextFieldRedux}
                            label={'Adresse email'}
                            required
                        />
                        <Field
                            name='phone'
                            component={TextFieldRedux}
                            label={'Téléphone mobile'}
                            required
                        />
                        <MyButton
                            variant='raised'
                            color='secondary'
                            size='large'
                            className={'btn btn-primary'}
                            disabled={loading}
                        >
                            {loading && <Spinner absolute/>}
                            Envoyer la procédure
                        </MyButton>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default SendSignature
