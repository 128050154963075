export const VIREMENTS = '[Virements Sinistre]'
export const VIREMENTS_PROD = '[Virements Prod]'
export const VIREMENT = '[Virement]'
export const VALIDATE_VIREMENTS_PROD = '[Validate Virements Prod]'

export const GET_VIREMENTS = `${VIREMENTS} Get`
export const SET_VIREMENTS = `${VIREMENTS} Set`

export const GET_VIREMENTS_PROD = `${VIREMENTS_PROD} Get`
export const SET_VIREMENTS_PROD = `${VIREMENTS_PROD} Set`

export const GET_VIREMENT = `${VIREMENT} Get`
export const SET_VIREMENT = `${VIREMENT} Set`
export const POST_VIREMENT = `${VIREMENT} Post`

export const SET_VALIDATE_VIREMENTS_PROD = `${VALIDATE_VIREMENTS_PROD} Set`
export const POST_VALIDATE_VIREMENTS_PROD = `${VALIDATE_VIREMENTS_PROD} Post`


export const getVirements = ({
    query,
    sortVirement = localStorage.getItem('sort_vir_sinistre') || 'date',
    montantVirement = localStorage.getItem('montant_vir_sinistre') || '',
    dateVirement = localStorage.getItem('date_vir_sinistre') || '',
    numSinistre = localStorage.getItem('num_sinistre') || '',
    beneficiairesPrelevementsVirement = localStorage.getItem('beneficiaires_prelevements_vir_sinistre') || '',
    pageVirement = '1'
}) => ({
    type: GET_VIREMENTS,
    payload: {
        data: query,
        sortVirement,
        pageVirement,
        filters_vir_sinistre: {
            montantVirement,
            dateVirement,
            numSinistre,
            beneficiairesPrelevementsVirement
        }
    }
})

export const setVirements = ({data, meta, links}) => ({
    type: SET_VIREMENTS,
    payload: {data, meta, links}
})


export const getVirementsProd = ({
     query,
     sort = localStorage.getItem('sort_virements') || 'date',
                                     fractionnement = localStorage.getItem('fractionnement') || '',
     contrat_situation = localStorage.getItem('contrat_situation_virements') || '',
     contrat = localStorage.getItem('contrat') || '',
     motif = localStorage.getItem('motif_virements') || '',
     montant = localStorage.getItem('montant') || '',
     multiple = localStorage.getItem('multiple_virements') || '',
     duplicate = localStorage.getItem('duplicate_amount') || '',
     date = localStorage.getItem('date_virements') || '',
     page = '1'
 }) => ({
    type: GET_VIREMENTS_PROD,
    payload: {
        data: query,
        sort,
        page,
        filters: {
            fractionnement,
            contrat_situation,
            contrat,
            montant,
            motif,
            date,
            multiple,
            duplicate
        }
    }
})

export const setVirementsProd = ({data, meta, links}) => ({
    type: SET_VIREMENTS_PROD,
    payload: {data, meta, links}
})


export const getVirement = ({query}) => ({
    type: GET_VIREMENT,
    payload: {
        data: query,
    }
})

export const setVirement = ({data}) => ({
    type: SET_VIREMENT,
    payload: {data}
})

export const postVirement = ({form, query}) => ({
    type: POST_VIREMENT,
    payload: {
        data: query,
        body: form
    }
})

export const postValidateListVirements = ({virements, query}) => ({
    type: POST_VALIDATE_VIREMENTS_PROD,
    payload: {
        data: query,
        body: {virements},
    }
})

export const setValidateListVirements = ({state}) => ({
    type: SET_VALIDATE_VIREMENTS_PROD,
    payload: state
})
