import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import Statistiques from './Statistiques'

import {getStatsLinks} from "../../../../redux/selectors/links/links.selectors";
import {getStatistiques} from '../../../../redux/actions/app/statistiques/statistiques.actions'
import ErrorHttpComponent from "../../../Commun/Error/ErrorHttpComponent";
import {getStatistiquesUi} from "../../../../redux/selectors/ui/ui.selectors";
import loader from "../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    return {
        uri: getStatsLinks(state),
        statistiques: getStatistiquesUi(state)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getStatistiques: ({query}) => getStatistiques({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getStatistiques} = dispatchProps
    const {uri} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getStatistiques({query: uri})
    }
}

const StatistiquesContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(Statistiques)

export default StatistiquesContainer
