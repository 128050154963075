import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import AutresDevis from './AutresDevis'
import {getQuotesLink} from '@/redux/selectors/links/links.selectors'
import ErrorHttpComponent from '../../../../../Commun/Error/ErrorHttpComponent'
import loader from "../../../../../Commun/Spinner/Loader/Loader";
import {FETCH_QUOTES, QUOTES, fetchQuotes} from "@/redux/actions/app/quotes/quotes.actions";
import {getErrorEntity, getLoadingEntity} from "@/redux/selectors/ui/ui.selectors";
import {getDataQuotes} from "@/redux/selectors/quotes/quotes.selectors";

const mapStateToProps = (state, ownProps) => {
    return {
        account_id: ownProps.account_id,
        currentId: ownProps.currentId,
        query: getQuotesLink(state),
        loaded: getLoadingEntity(state, QUOTES) === false,
        error: getErrorEntity(state, QUOTES),
        devis: getDataQuotes(state),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchQuotes: ({query, filters}) => fetchQuotes({query, filters})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {query, account_id} = stateProps
    const {fetchQuotes} = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: ({filters= { account_id }}) => fetchQuotes({query, filters})
    }
}

const AutresDevisContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(AutresDevis)

export default AutresDevisContainer
