import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {
    GET_GIFT_CARDS,
    GIFT_CARDS,
    setGiftCards
} from "../../../actions/app/gift_cards/gift_cards.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";

export const giftCardsMiddleware = () => next => action => {
    next(action)

    switch (action.type) {
        case GET_GIFT_CARDS:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: GIFT_CARDS}),
                setLoader({state: true, entity: GIFT_CARDS})
            ])
            break

        case `${GIFT_CARDS} ${API_SUCCESS}`:
            next([
                setGiftCards({gift_cards: action.payload.data}),
                setError({state: false, entity: GIFT_CARDS}),
                setLoader({state: false, entity: GIFT_CARDS})
            ])
            break

        case `${GIFT_CARDS} ${API_ERROR}`:
            next([
                setError({state: true, entity: GIFT_CARDS}),
                setLoader({state: false, entity: GIFT_CARDS})
            ])
            break

        default:
            break
    }
    return null
}
