import React, {Component, Fragment} from 'react'
import moment from "moment";
import {Link} from "react-router-dom";
import CallToAction from "../../../../Commun/Button/CallToAction/CallToAction";
import _ from "lodash";

export default class Antecedents extends Component {
    constructor(props) {
        super(props)
        this.state = {
            disabledDelete: false
        }
    }

    handleClick = () => {
        this.props.PostExpirationDateSejour({
            date: undefined,
            tiers: this.props.tiers.id
        })
        this.setState({
            disabledDelete: true
        })
    }

    render() {
        const {antecedents, permis, conducteur, tiers, contract, codeProduit, roles} = this.props

        const isVAE = codeProduit === '7311_VAE'
        const isNVEI = codeProduit === '7311_NVEI'

        return (
            <div className={'bloc-info bloc-antecedents'}>
                <h2>Antécédents</h2>
                <div className={'content row'}>
                    <div className={'col-5 left-content'}>
                        <p>Expiration titre administratif</p>
                    </div>
                    <div className={'col-7 right-content'}>
                        <p>
                            {!_.includes(roles, 'gestionnaire televente') ?
                                <Fragment>
                                    {antecedents.dateSejour ?
                                        <Fragment>
                                            {moment(antecedents.dateSejour).format('L')}
                                            <CallToAction
                                                url={{
                                                    pathname: `/contrat/${contract}/expiration-sejour`,
                                                    state: {tiers: tiers, contract: contract}
                                                }}
                                                action={'edit-note'}
                                                toolText={'Modifier la date'}
                                            />
                                            <CallToAction
                                                url={'#'}
                                                action={'delete-note'}
                                                toolText={'Supprimer la date'}
                                                onClick={() => {
                                                    this.handleClick()
                                                }}
                                                disabled={this.state.disabledDelete}
                                            />
                                        </Fragment>
                                        : <Link to={{
                                            pathname: `/contrat/${contract}/expiration-sejour`,
                                            state: {tiers: tiers, contract: contract}
                                        }}>
                                            Ajouter une date d'expiration
                                        </Link>
                                    }
                                </Fragment> : ' - '
                            }
                        </p>
                    </div>
                    {
                        !isVAE && !isNVEI && <>
                            <div className={'col-5 left-content'}>
                                <p>Numéro de permis&nbsp;:</p>
                            </div>
                            <div className={'col-7 right-content'}>
                                <p>{conducteur.numPermis || '-'}</p>
                            </div>
                            {permis.map((p, index) =>
                                <Fragment key={index}>
                                    <div className={'col-5 left-content'}><p>Permis {p.type}&nbsp;:</p></div>
                                    <div className={'col-7 right-content'}><p key={index}>{moment(p.date).format('L')}</p></div>
                                </Fragment>
                            )}

                            <div className={'col-5 left-content'}>
                                <p>CRM Auto&nbsp;:</p>
                            </div>
                            <div className={'col-7 right-content'}>
                                <p className={'small'}>{antecedents.crmAutoAct || '-'}</p>
                            </div>

                            <div className={'col-5 left-content'}>
                                <p>CRM Moto&nbsp;:</p>
                            </div>
                            <div className={'col-7 right-content'}>
                                <p className={'small'}>{antecedents.crmMotoAct || '-'}</p>
                            </div>

                            <div className={'col-5 left-content'}>
                                <p>CRM Date&nbsp;:</p>
                            </div>
                            <div className={'col-7 right-content'}>
                                <p>{moment(antecedents.dateCrm).format('L') || '-'}</p>
                            </div>

                            <div className={'col-5 left-content'}>
                                <p>CRM Date&nbsp;:</p>
                            </div>
                            <div className={'col-7 right-content'}>
                                <p>{moment(antecedents.dateCrm).format('L') || '-'}</p>
                            </div>

                            {(antecedents.antecedentAssurance1 !== undefined || antecedents.antecedentAssurance2 !== undefined) &&
                                <>
                                    <div className={'col-5 left-content'}>
                                        <p>Antécédents&nbsp;:</p>
                                    </div>
                                    <div className={'col-7 right-content'}>
                                        {antecedents.antecedentAssurance1 !== undefined &&
                                            <p>{antecedents.antecedentAssurance1} mois
                                                d'assurances {antecedents.antecedentType1}</p>}
                                        {antecedents.antecedentAssurance2 !== undefined &&
                                            <p>{antecedents.antecedentAssurance2} mois
                                                d'assurances {antecedents.antecedentType2}</p>}
                                    </div>
                                </>
                            }
                            {antecedents.motifsAggravants.length >= 1 && <>
                                <div className={'col-5 left-content'}>
                                    <p>Motifs aggravants&nbsp;</p>
                                </div>
                                <div className={'col-7 right-content'}>
                                    {antecedents.motifsAggravants.map((motif, index) =>
                                        <p key={index}>{motif.motif} le {moment(motif.date).format('L')}</p>
                                    )}
                                </div>
                            </>}
                        </>
                    }
                </div>
            </div>
        )
    }
}
