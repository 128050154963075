export const GIFT_CARDS = '[GiftCards]'

export const GET_GIFT_CARDS = `${GIFT_CARDS} Get`
export const SET_GIFT_CARDS = `${GIFT_CARDS} Set`

export const getGiftCards = ({query}) => ({
    type: GET_GIFT_CARDS,
    payload: {
        data: query
    }
})

export const setGiftCards = ({gift_cards}) => ({
    type: SET_GIFT_CARDS,
    payload: {
        data: gift_cards
    }
})