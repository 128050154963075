import React from 'react';
import SelectFieldRedux from "../../../../Commun/Input/Select/SelectFieldRedux";
import {Field} from "redux-form";
import DatePickerFieldRedux from "../../../../Commun/Input/DatePicker/DatePickerFieldRedux";
import moment from "moment";
import Spinner from "../../../../Commun/Spinner/Spinner";
import _ from "lodash";

const ExtractQuotesTlv = (props) => {
    const {handleSubmit, gestionnaires, loading} = props

    return (
        <div className={'contract-container container'}>
            <div className={'row header-row'}>
                <div className={'col-12'}>
                    <form className={'form-content'} onSubmit={handleSubmit}>
                        <Field
                            component={SelectFieldRedux}
                            name='gestionnaire_id'
                            required
                        >
                            <option value={""}>Sélectionnez un gestionnaire</option>
                            {!_.isEmpty(gestionnaires) ? <option value={'all'}>Tous les gestionnaires</option> : ''}
                            {gestionnaires.map((gestionnaire, index) =>
                                <option key={index} value={gestionnaire.id}>{gestionnaire.username}</option>
                            )}
                        </Field>

                        <Field
                            name={"dateStart"}
                            component={DatePickerFieldRedux}
                            label={'Date de début'}
                            value={moment()}
                            required
                        />
                        <Field
                            name={"dateEnd"}
                            component={DatePickerFieldRedux}
                            label={'Date de fin'}
                            value={moment()}
                            required
                        />

                        {
                            loading ? <Spinner style={{display: 'block', margin: 'auto'}}/> :
                                <button className={'btn btn-primary d-block m-auto'} type={'submit'}>
                                    Lancer l'extraction
                                </button>
                        }
                    </form>
                </div>
            </div>
        </div>
    );
};


export default ExtractQuotesTlv;