import React, {useEffect} from 'react';
import {Field} from "redux-form";
import TextAreaFieldRedux from "../../../../../../../Commun/Input/Text/TextAreaFieldRedux";
import TextFieldRedux from "../../../../../../../Commun/Input/Text/TextFieldRedux";
import DatePickerFieldRedux from "../../../../../../../Commun/Input/DatePicker/DatePickerFieldRedux";

const Declaration = ({categorie, isWakam, type_sinistre}) => {

    useEffect(() => {
        document.getElementById('declaration-ter').scrollIntoView({behavior: 'smooth'})
    }, [])

    return (
        <div className={'form-content'}>
            <h1 className={'title-primary'}>Quelles sont les circonstances ?</h1>

            <Field
                name='commentaire'
                component={TextAreaFieldRedux}
                label={'Circonstances'}
                maxLength={'120'}
                subLabel={'120 caractères maximum'}
            />

            {
                isWakam && categorie !== "10.0" && <Field
                    name={"birthdate_conduct"}
                    component={DatePickerFieldRedux}
                    label={'Date de naissance du conducteur'}
                />
            }

            {
                !isWakam || (isWakam && type_sinistre === "accident") && <Field
                    name='conduct'
                    component={TextFieldRedux}
                    label='Nom du conducteur'
                />
            }

        </div>
    );
};

export default Declaration;
