import React, {Fragment, Component} from 'react';
import {Field} from "redux-form";
import SelectFieldRedux from "../../../../../Commun/Input/Select/SelectFieldRedux";
import TextAreaFieldRedux from "../../../../../Commun/Input/Text/TextAreaFieldRedux";
import _ from 'lodash'
import {Warning} from "../../../../../Commun/Icons/Icons";
import imgRotate from '../../../../../../assets/img/icons/rotate_light.png';
import imgZoomIn from '../../../../../../assets/img/icons/zoomIn_light.png';
import imgZoomOut from '../../../../../../assets/img/icons/zoomOut_light.png';
import imgReset from '../../../../../../assets/img/icons/center_light.png';
import Spinner from "../../../../../Commun/Spinner/Spinner";
import moment from "moment";
import APIFrame from "../../../../../Commun/APILink/APIFrame";
import APIImage from "../../../../../Commun/APILink/APIImage";

class ValidateDocumentAlcoolemie extends Component {
    constructor(props) {
        super(props);
        this.state = {
            renderList: [],
            labelSelect: this.props.libelle,
            rotate: 0,
            zoom: null
        };
    }

    rotate = () => {
        this.setState({
            rotate: this.state.rotate + 90
        })
    }

    zoomIn = () => {
        this.setState({
            zoom: this.refImg.clientWidth + 100
        })
    }

    zoomOut = () => {
        this.setState({
            zoom: this.refImg.clientWidth - 100
        })
    }

    reset = () => {
        this.setState({
            zoom: 500
        })
    }

    handleChangeStatut = (e) => {
        let index = e.nativeEvent.target.selectedIndex;
        let value = e.nativeEvent.target[index].value;
        if (value === 'I') {
            this.props.changeValue(this.props.form, 'commentaire', 'Lisible')
        } else {
            this.props.changeValue(this.props.form, 'commentaire', '')
        }
    }

    pushDocument = (form) => {
        let renderList = this.state.renderList
        let statut
        let doc = []

        switch (form.statut) {
            case 'C':
                statut = 'Conforme'
                break;
            case 'P':
                statut = 'Incomplet/provisoire'
                break;
            case 'I':
                statut = 'Illisible'
                break;
            case 'R':
                statut = 'Refusé'
                break;
            default:
                statut = 'Refusé'
        }

        doc.statut = statut
        doc.code = form.codeatt
        doc.codeStatut = form.statut
        doc.commentaire = form.commentaire
        doc.libelle = this.state.labelSelect

        renderList.push(doc)
        this.setState({
            renderList
        })
    }

    delete = doc => {
        this.setState({
            renderList: _.reject(this.state.renderList, ['code', doc.code])
        })
    }

    submitFormSingleDoc = (form) => {
        this.props.onSubmit([form])
    }

    render() {
        const {
            handleSubmit,
            file,
            stateValidateDocument,
            form,
            loading,
            message,
            task: {date, type, paniere, tiers_id}
        } = this.props
        const {statutDoc, renderList} = this.state

        let styleImg = {
            transform: `rotate(${this.state.rotate}deg)`,
            width: `${this.state.zoom}px`,
            display: 'block',
            margin: 'auto'
        }
        return (
            <Fragment>
                {
                    message && <div className={'header-row row'}>
                        <div className={'col-md-6 offset-md-4'}>
                            <p className={'form-content text-center'}>
                                {message}
                            </p>
                        </div>
                    </div>
                }
                <div className={'row'}>
                    <div className={'col text-center'}>
                        <h2 className={'title-primary text-uppercase'}>Tâche {paniere} - Tiers n°{tiers_id}</h2>
                        <p className={'subtitle'}>Tâche du {moment(date).format('L')}. Recu par {type}</p>
                    </div>
                </div>
                {!_.get(stateValidateDocument, form) &&
                    <div className={'row header-row'}>
                        {
                            file.extension.toLowerCase() === 'pdf' ?
                                <div className={'col-8'} style={{marginBottom: 30}}>
                                    <APIFrame src={`${file.url}`} title={`${file.code}`} frameBorder="0"
                                            style={{height: '100%', width: '100%', marginBottom: 30}}/>
                                </div>
                                :
                                <Fragment>
                                    <div className={'col-7 task-img-container'}>
                                        <APIImage src={`${file.url}`} style={styleImg} ref={el => this.refImg = el}
                                             alt={'Document'}/>
                                    </div>
                                    <div className={'col-1'}>
                                        <div className={'task-nav-img'}>
                                        <span className={'task-btn-img'} onClick={() => this.rotate()}
                                              title={'Faire pivoter vers la droite'}>
                                            <img src={imgRotate} alt="Rotation"/>
                                        </span>
                                            <span className={'task-btn-img'} onClick={() => this.zoomIn()}
                                                  title={'Zoom avant'}>
                                            <img src={imgZoomIn} alt="zoom in"/>
                                        </span>
                                            <span className={'task-btn-img'} onClick={() => this.zoomOut()}
                                                  title={'Zoom arrière'}>
                                            <img src={imgZoomOut} alt="zoom out"/>
                                        </span>
                                            <span className={'task-btn-img'} onClick={() => this.reset()}
                                                  title={'Ajuster à la page'}>
                                            <img src={imgReset} alt="centre"/>
                                        </span>
                                        </div>
                                    </div>
                                </Fragment>
                        }
                        <div className={'col-4'}>
                            <form className={'form-content'} onSubmit={handleSubmit(this.pushDocument.bind(this))}>
                                <Fragment>
                                    <Field
                                        name='statut'
                                        component={SelectFieldRedux}
                                        label='Le document est'
                                        onChange={this.handleChangeStatut}
                                    >
                                        <option value={null}>Sélectionner la visibilité</option>
                                        <option value={'C'}>Conforme</option>
                                        <option value={'P'}>Incomplet/provisoire</option>
                                        <option value={'I'}>Illisible</option>
                                        <option value={'R'}>Refusé</option>
                                    </Field>

                                    <Field
                                        name='commentaire'
                                        component={TextAreaFieldRedux}
                                        label={'Commentaire'}
                                        value={statutDoc}
                                    />
                                </Fragment>

                                {renderList.length <= 0 &&
                                    <button
                                        className={'btn btn-primary btn-smaller'}
                                        onClick={handleSubmit(values => this.submitFormSingleDoc({...values}))}
                                        disabled={loading}
                                    >
                                        {loading && <Spinner absolute/>}
                                        Valider
                                    </button>
                                }
                            </form>
                            {file.fields.length > 0 &&
                                <div className={'validation-document-list'}>
                                    {_.find(file.fields, field => {
                                        return field.valide !== 'T'
                                    }) && <p className={'validation-document-title'}>
                                        Il y a des incohérences avec les informations du dossier
                                    </p>
                                    }
                                    {file.fields.map((field, index) => (
                                        field.valide !== 'T' &&
                                        <div className={'validation-document-list-content'}>
                                            <div className={'validation-document-piece kofax'} key={index}>
                                                <div className="row">
                                                    <div className="col">
                                                        <p><Warning/> {field.nomChamps}</p>
                                                        <div className={'value'}>
                                                            <span>Valeur trouvée</span>
                                                            <strong>{field.valeurRecu || '-'}</strong>
                                                        </div>
                                                        <div className={'value'}>
                                                            <span>Valeur déclarée</span>
                                                            <strong>{field.valeurAttendu}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                }
            </Fragment>
        );
    }
}

export default ValidateDocumentAlcoolemie;
