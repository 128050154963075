import {SET_TASK} from '../../actions/app/tasks/tasks.actions'

export const taskDetailReducer = (task = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_TASK) {
        return payload
    } else {
        return task
    }
}
