import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import DocumentsUtiles from './DocumentsUtiles'
import ErrorHttpComponent from '../../../../Commun/Error/ErrorHttpComponent'
import {
    DOCUMENTS_UTILES,
    getDocumentsUtiles
} from '../../../../../redux/actions/app/documents_utiles/documents_utiles.actions'
import {getErrorEntity, getLoadingEntity} from '../../../../../redux/selectors/ui/ui.selectors'
import {DocumentsUtilesLink} from '../../../../../redux/selectors/links/links.selectors'
import {getDocumentsUtilesData} from "../../../../../redux/selectors/support/support.selectors";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    return {
        documents: getDocumentsUtilesData(state),
        uri: DocumentsUtilesLink(state),
        loaded: getLoadingEntity(state, DOCUMENTS_UTILES) === false,
        error: getErrorEntity(state, DOCUMENTS_UTILES)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getDocumentsUtiles: (query) => getDocumentsUtiles({query}),
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getDocumentsUtiles} = dispatchProps
    const {uri} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getDocumentsUtiles(uri)
    }
}

const DocumentsUtilesContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(DocumentsUtiles)

export default DocumentsUtilesContainer
