import {SET_COMMISSIONS} from "../../actions/app/commissions/commissions.actions";

export const commissionsReducer = (commissions = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_COMMISSIONS) {
        return payload.data
    } else {
        return commissions
    }
}
