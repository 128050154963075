import React from 'react'
import moment from "moment";
import {Link} from "react-router-dom"

const Accidents = (props) => {
    const {accidents, uri, meta, links, getAccidents} = props

    const handleChangePage = (query) => {
        getAccidents(query)
    }

    return (
        <div className={'bloc-info bloc-accidents'}>
            <h2>Liste des sinistres</h2>
            <div className={'content row'}>
                {accidents.data.length >= 1 ? <>
                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">Date du sinistre</th>
                            <th scope="col">Numero du sinistre</th>
                            <th scope="col">Situation</th>
                            <th scope="col">Catégorie</th>
                            <th scope="col">Détails</th>
                        </tr>
                        </thead>
                        <tbody>
                        {accidents.data.map((accident, i) =>
                            <tr key={i}>
                                <td>{moment(accident.dateSurv).format('L')}</td>
                                <td>{accident.numCbt}</td>
                                <td>{accident.situation}</td>
                                <td>{accident.categorie}</td>
                                <td>
                                    <Link to={{
                                        pathname: `/contrat/${accident.id_contrat}/sinistre/${accident.id}`,
                                        state: {
                                            souscripteur: accident.conducteur
                                        }
                                    }}
                                          className={'btn btn-more'}
                                    />
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                    {(meta && meta.total > 10) &&
                        <div className={'row'}>
                            <nav>
                                <p>{meta.to}/{meta.total} accidents</p>
                                <ul className="pagination">
                                    {meta.current_page > 2 &&
                                        <li className="page-item" onClick={() => handleChangePage(links.first)}><span
                                            className="page-link">{`<<`}</span></li>}
                                    {meta.current_page > 1 &&
                                        <li className="page-item" onClick={() => handleChangePage(links.prev)}><span
                                            className="page-link"> {`<`} </span></li>}
                                    <li className="page-item"><span
                                        className={`page-link current`}>{meta.current_page}</span>
                                    </li>
                                    {meta.last_page > meta.current_page &&
                                        <li className="page-item" onClick={() => handleChangePage(links.next)}><span
                                            className="page-link">{`>`}</span></li>}
                                    {meta.last_page >= meta.current_page + 2 &&
                                        <li className="page-item" onClick={() => handleChangePage(links.last)}><span
                                            className="page-link">{`>>`}</span></li>}
                                </ul>
                            </nav>
                        </div>
                    }
                </>:
                    <p className={'no-result'}>Le tiers n'a pas de sinistre déclaré</p>
                }
            </div>
        </div>
    )
}

export default Accidents
