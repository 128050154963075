import React, {useEffect} from 'react';
import {Field} from "redux-form";
import RadioFieldRedux from "../../../../../../Commun/Input/Radio/RadioFieldRedux";


const FormAvenantSimpleCivilite = props => {
    const {contract: {souscripteur, conducteur}, souscripteur_civilite, changeValue} = props

    useEffect(() => {
        if (souscripteur.nom === `${conducteur.nom} ${conducteur.prenom}`) {
            changeValue('conducteur_civilite', souscripteur_civilite);
        }
    }, [souscripteur_civilite]);

    return (
        <>
            <Field
                name={`souscripteur_civilite`}
                component={RadioFieldRedux}
                label={'Civilité du souscripteur'}
                data={[
                    {value: "MR", label: "Monsieur"},
                    {value: "MME", label: "Madame"},
                    {value: "SOC", label: "Société"}
                ]}
            />
            <p>{souscripteur.nom}</p>

            {
                souscripteur.nom !== `${conducteur.nom} ${conducteur.prenom}` ?
                    <>
                        <Field
                            name={`conducteur_civilite`}
                            component={RadioFieldRedux}
                            label={'Civilité du conducteur'}
                            data={[
                                {value: "MR", label: "Monsieur"},
                                {value: "MME", label: "Madame"},
                                {value: "SOC", label: "Société"}
                            ]}
                        />
                        <p>{conducteur.nom} {conducteur.prenom}</p>
                    </> : ''
            }
        </>
    );
};


export default FormAvenantSimpleCivilite;
