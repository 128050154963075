import React from 'react'
import {Field} from "redux-form";
import MyButton from "../../../../Commun/Button/Button";
import Spinner from "../../../../Commun/Spinner/Spinner";
import TextAreaFieldRedux from "../../../../Commun/Input/Text/TextAreaFieldRedux";
import SelectFieldRedux from "../../../../Commun/Input/Select/SelectFieldRedux";

const EditDocument = (props) => {
    const {loading, handleSubmit} = props

    return (
        <div className={'contract-container container'}>
            <div className={'row header-row'}>
                <div className={'col-12'}>
                    <form className={'form-content'} onSubmit={handleSubmit}>
                        <Field
                            name={'message'}
                            component={TextAreaFieldRedux}
                            label={'Saisissez le texte à saisir pour la réponse à la réclamation :'}
                            subLabel={'500 caractères maximum'}
                            maxLength={'500'}
                            required
                        />
                        <Field
                            name={'document'}
                            component={SelectFieldRedux}
                            label={'Sélectionnez le document à éditer pour la réclamation : '}
                            required
                        >
                            <option value={""}>Sélectionner le type de destinataire</option>
                            <option value={'DOCLIBRERECLA'}>Document libre réclamation</option>
                            <option value={'DOCLIBRERECLAN2'}>Document libre réclamation niveau 2</option>
                        </Field>
                        <MyButton
                            variant='raised'
                            color='secondary'
                            size='large'
                            className={'btn btn-primary'}
                            disabled={loading}
                        >
                            {loading && <Spinner absolute/>}
                            Editer le document
                        </MyButton>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default EditDocument
