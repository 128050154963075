import {connect} from 'react-redux'
import {bindActionCreators, compose} from "redux";
import FlotteAccidents from "./FlotteAccidents";
import {
    getAccidentsData,
    getAccidentsLinks,
    getAccidentsMeta
} from "../../../../../../redux/selectors/accidents/accidents.selectors";
import {flotteAccidentsContractGetLink} from "../../../../../../redux/selectors/links/links.selectors";
import {CONTRACT} from "../../../../../../redux/actions/app/contracts/contracts.actions";
import {getErrorEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import ErrorHttpComponent from "../../../../../Commun/Error/ErrorHttpComponent";
import {FLOTTE_ACCIDENTS, getAccidentsFlotte} from "../../../../../../redux/actions/app/flotte/flotte.actions";
import loader from "../../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    return {
        accidents: getAccidentsData(state),
        uri: flotteAccidentsContractGetLink(state, CONTRACT),
        error: getErrorEntity(state, FLOTTE_ACCIDENTS),
        contract: ownProps.contract,
        meta: getAccidentsMeta(state),
        links: getAccidentsLinks(state)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getAccidentsFlotte: ({query, immat}) => getAccidentsFlotte({query, immat})

}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getAccidentsFlotte} = dispatchProps
    const {uri} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getAccidentsFlotte({query: uri, immat: ''}),
    }
}

const FlotteAccidentsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(FlotteAccidents)

export default FlotteAccidentsContainer
