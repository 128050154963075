export const DOCUMENTATION = '[Documentation]'

export const GET_DOCUMENTATION = `${DOCUMENTATION} Get`
export const SET_DOCUMENTATION = `${DOCUMENTATION} Set`

export const getDocumentation = ({query}) => ({
    type: GET_DOCUMENTATION,
    payload: {
        data: query
    }
})

export const setDocumentation = ({documentation}) => ({
    type: SET_DOCUMENTATION,
    payload: {
        data: documentation
    }
})
