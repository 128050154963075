import {connect} from 'react-redux'
import {compose} from "redux";
import Parrainages from "./Parrainages";
import {PARRAINAGES, getParrainages} from "../../../../../../redux/actions/app/parrainages/parrainages.actions";
import _ from "lodash";

import {getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {parrainagesLink} from "../../../../../../redux/selectors/links/links.selectors";
import {getParrainagesData} from "../../../../../../redux/selectors/parrainages/parrainages.selectors";
import loader from "../../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    return {
        uri: parrainagesLink(state),
        idTiers: ownProps.tiers.belair_id,
        loaded: getLoadingEntity(state, PARRAINAGES) === false,
        parrainages: getParrainagesData(state)
    }
}

const mapDispatchToProps = {
    getParrainages,
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uri, idTiers} = stateProps
    const {getParrainages} = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getParrainages({query: _.replace(uri, '{id}', idTiers)})
    }
}

const ParrainagesContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Parrainages)

export default ParrainagesContainer
