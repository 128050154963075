import React, {Component} from 'react';
import VirementsListContainer from './VirementsList/VirementsListContainer'
import moment from "moment";
import darkLoupe from '../../../../../assets/img/icons/loupe_dark.png'
import {getBoolean} from "../../../../../utils/function";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";

class VirementsProdContent extends Component {
    constructor(props) {
        super(props)
        localStorage.getItem('sort_virements') === null && localStorage.setItem('sort', 'date')
        localStorage.getItem('multiple_virements') === null && localStorage.setItem('multiple_virements', false)
        localStorage.getItem('duplicate_amount') === null && localStorage.setItem('duplicate_amount', false)
        this.state = {
            query: this.props.uri,
            focused: false,
            showMultiple: localStorage.getItem('multiple_virements') ? getBoolean(localStorage.getItem('multiple_virements')) : false,
            showOnlyDuplicate: localStorage.getItem('duplicate_amount') ? getBoolean(localStorage.getItem('duplicate_amount')) : false,
            date: localStorage.getItem('date_virements') ? moment(localStorage.getItem('date_virements'), 'DD/MM/YYYY') : null,
            police: localStorage.getItem('contrat')
        }
    }

    handleChangeSort = (e) => {
        localStorage.setItem('sort_virements', e.target.value)
        this.props.getVirementsProd({
            query: this.state.query,
            sort: localStorage.getItem('sort_virements')
        })
    }

    handleChangeFilter = ({name, value}) => {
        localStorage.setItem(name, value)
        this.props.getVirementsProd({
            query: this.state.query,
            sort: localStorage.getItem('sort'),
            [name]: value
        })
    }

    handleChangeDates = (date) => {
        localStorage.setItem('date_virements', moment(date).format('L'))
        this.setState({
            date: date
        })
        this.props.getVirementsProd({
            query: this.state.query,
            sort: localStorage.getItem('sort_virements'),
            date: moment(date).format('L') || '',
        })
    }

    handleChangeMulti = (e) => {
        this.setState({showMultiple: !this.state.showMultiple})
        localStorage.setItem('multiple_virements', !this.state.showMultiple)
        this.props.getVirementsProd({
            query: this.state.query,
            sort: localStorage.getItem('sort_virements'),
            multiple: localStorage.getItem('multiple_virements') || ''
        })
    }

    handleChangeDuplicate = (e) => {
        this.setState({showOnlyDuplicate: !this.state.showOnlyDuplicate})
        localStorage.setItem('duplicate_amount', !this.state.showOnlyDuplicate)
        this.props.getVirementsProd({
            query: this.state.query,
            sort: localStorage.getItem('sort_virements'),
            duplicate: localStorage.getItem('duplicate_amount') || ''
        })
    }

    handleKeypress = (e) => {
        if (e.key === 'Enter') {
            this.handleChangeFilter({name: this.inputPolice.name.substring(7), value: this.inputPolice.value})
        }
    }

    resetFilters = () => {
        localStorage.setItem('fractionnement', '')
        localStorage.setItem('date_virements', '')
        localStorage.setItem('contrat_situation_virements', '')
        localStorage.setItem('multiple_virements', '')
        localStorage.setItem('duplicate_amount', '')
        localStorage.setItem('numeroPolice', '')
        localStorage.setItem('motif_virements', '')
        localStorage.setItem('montant', '')
        localStorage.setItem('contrat', '')
        this.setState({
            date: null
        })
        this.props.getVirementsProd({
            query: this.state.query,
            sort: localStorage.getItem('sort_virements'),
            date: '',
        })
    }

    render() {
        const {meta} = this.props
        return (
            <div className={'virements-container tasks-container container'}>
                <div className={'row'}>
                    <div className={'col-12'}>
                        <h1 className={'title-primary title-task'}>Virements en attente.</h1>
                        <small className={'bold text-uppercase'}>{meta.total} virements à valider</small>
                        <div className={'float-right'}>
                            <div className={'styled-checkbox'}>
                                <input
                                    type="checkbox"
                                    name="filter_multiple_virements"
                                    id="multiple_virements"
                                    onChange={this.handleChangeMulti}
                                    value={this.state.showMultiple}
                                    defaultChecked={this.state.showMultiple}
                                />
                                <label htmlFor="multiple_virements">Afficher les contrats avec plusieurs virements
                                    ?</label>
                            </div>
                            <div className={'styled-checkbox'}>
                                <input
                                    type="checkbox"
                                    name="filter_duplicate_amount"
                                    id="duplicate_amount"
                                    onChange={this.handleChangeDuplicate}
                                    value={this.state.showOnlyDuplicate}
                                    defaultChecked={this.state.showOnlyDuplicate}
                                />
                                <label htmlFor="duplicate_amount">Afficher les contrats ayant un remboursement identique
                                    ?</label>
                            </div>
                        </div>
                    </div>
                    <div className={'col-12 filters'}>
                        <div className={'content'}>
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
                                <DatePicker
                                    className={'mr-4 small date-picker'}
                                    label='Date des virements'
                                    value={this.state.date}
                                    onChange={(newValue) => {
                                        this.handleChangeDates(newValue)
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>

                            <select
                                name="filter_fractionnement"
                                onChange={e => this.handleChangeFilter({
                                    name: e.target.name.substring(7),
                                    value: e.target.value
                                })}
                                className={'small-select-contrat select-filter-tasks custom-select mb-0'}
                                value={localStorage.getItem('fractionnement')}
                            >
                                <option value={''}>Fractionnement du contrat</option>
                                <option value="annuel">Annuel</option>
                                <option value="mensuel">Mensuel</option>
                                <option value="semetriel">Semestriel</option>
                            </select>
                            <select
                                name="filter_contrat_situation_virements"
                                onChange={e => this.handleChangeFilter({
                                    name: e.target.name.substring(7),
                                    value: e.target.value
                                })}
                                className={'small-select-contrat select-filter-tasks custom-select mb-0'}
                                value={localStorage.getItem('contrat_situation_virements')}
                            >
                                <option value={''}>Situation du contrat</option>
                                <option value="1">En cours</option>
                                <option value="2">En établissement</option>
                                <option value="6">Résilié</option>
                                <option value="5">Suspendue</option>
                                <option value="I">Résilié/Impayé</option>
                                <option value="A">Avenant en attente</option>
                            </select>
                            <div style={{width: '17%'}} className={'d-inline-block'}>
                                <div className={'d-flex'}>
                                    <input
                                        name="filter_contrat"
                                        value={this.state.police}
                                        onChange={e => this.setState({police: e.target.value})}
                                        className={'filters small-input with-icon select-filter-tasks'}
                                        type="text"
                                        placeholder="N° de Police"
                                        ref={input => this.inputPolice = input}
                                        onKeyPress={this.handleKeypress}
                                    />
                                    <span
                                        onClick={() => this.handleChangeFilter({
                                            name: this.inputPolice.name.substring(7),
                                            value: this.inputPolice.value
                                        })}
                                        className={'icon-small-input'}
                                    >
                                        <img src={darkLoupe} alt="Loupe"/>
                                    </span>
                                </div>
                            </div>
                            <select
                                name="filter_motif_virements"
                                onChange={e => this.handleChangeFilter({
                                    name: e.target.name.substring(7),
                                    value: e.target.value
                                })}
                                className={'small-select select-filter-tasks custom-select mb-0'}
                                value={localStorage.getItem('motif_virements')}
                            >
                                <option value={''}>Motif du remboursement</option>
                                <option value={'2'}>Résil / VOL</option>
                                <option value={'4'}>Résil/sinistre</option>
                                <option value={'5'}>Résil/impayé</option>
                                <option value={'7'}>Expir/contractuelle</option>
                                <option value={'9'}>Sans effet</option>
                                <option value={'A'}>Résiliation suite à vente</option>
                                <option value={'B'}>Résiliation à échéance</option>
                                <option value={'G'}>Résil / Cie / Sinistres</option>
                                <option value={'F'}>Résil/aggravation risque</option>
                                <option value={'I'}>Résil NPP prime réglée</option>
                                <option value={'L'}>Résiliation loi Chatel</option>
                                <option value={'W'}>Suspendu</option>
                                <option value={'X'}>Résilier / Non achat</option>
                                <option value={'Z'}>Résil / Omission / Fausse Déclaration</option>
                                <option value={'0'}>Résiliation Loi HAMON</option>
                                <option value={'Q'}>Résiliation rétractation 14 jours</option>
                            </select>
                            <select
                                name="filter_montant"
                                onChange={e => this.handleChangeFilter({
                                    name: e.target.name.substring(7),
                                    value: e.target.value
                                })}
                                className={'mb-0 small-select-montant select-filter-tasks custom-select'}
                                value={localStorage.getItem('montant')}
                            >
                                <option value={''}>Montant</option>
                                <option value="0">Supérieur ou égal à 0€</option>
                                <option value="5">Entre 0€ et -5€</option>
                                <option value="1">Entre -5€ et -100€</option>
                                <option value="2">Entre -100€ et -250€</option>
                                <option value="3">Entre -250€ et -500€</option>
                                <option value="4">Plus de -500€</option>
                            </select>
                            <select
                                name="sort"
                                onChange={this.handleChangeSort}
                                className={'small-select mb-0 select-sort-tasks custom-select'}
                                value={localStorage.getItem('sort_virements')}
                            >
                                <option value="date">Du + ancien au + récent</option>
                                <option value="-date">Du + récent au + ancien</option>
                                <option value="montant">Du montant le + élevé</option>
                                <option value="-moment">Du montant le - élevé</option>
                            </select>
                            <div>
                                <small onClick={this.resetFilters}>Réinitialiser les filtres</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col'}>
                        <VirementsListContainer className='loader'/>
                    </div>
                </div>
            </div>
        );
    }
};

export default VirementsProdContent;
