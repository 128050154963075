const validate = values => {
    const errors = {}

    const requiredFields = [
        'raison_transfert',
        'description_mecontentement',
        'reponse',
        'cause_mecontentement',
        'issue'
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Ce champ est obligatoire'
        }
    })


    return errors
}

export default validate
