import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import FlotteContent from './FlotteContent'
import ErrorHttpComponent from '../../../../Commun/Error/ErrorHttpComponent'
import {getFlotte} from '../../../../../redux/actions/app/flotte/flotte.actions'
import {getFleetLink} from '../../../../../redux/selectors/links/links.selectors'
import {getFlotteLinks, getFlotteMeta} from "../../../../../redux/selectors/flotte/flotte.selectors";
import {getContractId} from "../../../../../redux/selectors/contract/contract.selectors";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    return {
        uri: getFleetLink(state),
        meta: getFlotteMeta(state) || {},
        links: getFlotteLinks(state) || {},
        contractId: getContractId(state),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getFlotte: ({query, sort, contrat, immat, active}) => getFlotte({query, sort, contrat, immat, active}),
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getFlotte} = dispatchProps
    const {uri, contractId} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getFlotte({query: uri, sort: '', contrat: contractId, immat: '', active: ''}),
    }
}

const FlotteContentContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(FlotteContent)

export default FlotteContentContainer
