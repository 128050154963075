import React, {useState, useEffect} from 'react';
import {Field} from "redux-form";
import TextFieldRedux from "../../../../Commun/Input/Text/TextFieldRedux";
import Spinner from "../../../../Commun/Spinner/Spinner";
import {getInsurers} from "../../../../../utils/function";
import AutoComplete from "../../../../Commun/Input/Autocomplete/Autocomplete";
import _ from 'lodash'

const UpdateHamon = (props) => {
    const {location: {state: {title, subtitle, assureur}}, loading, handleSubmit, changeValue} = props
    const [listInsurers, setListInsurers] = useState(false)

    async function fetchInsurers() {
        let compagnys = await getInsurers()
        const listcompanys = compagnys.map(company => ({
            value: company.code,
            label: company.libelle
        }))

        const defaultInsurer = _.find(listcompanys, ['label', assureur])

        changeValue('compagnie', defaultInsurer.value)

        setListInsurers(listcompanys)
    }

    useEffect(() => {
        if (!listInsurers) {
            fetchInsurers()
        }
    }, [listInsurers]);

    return (
        <div className={'contract-container container'}>
            <div className={'row return-content'}>
                <div className={'col'}>
                    <button onClick={() => props.history.go(-1)} className={'btn btn-return'}>Retour</button>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-12'}>
                    <h1 className={'title-primary'}>{title}</h1>
                </div>
                {subtitle &&
                    <div className={'col-12'}>
                        <span className={'bold subtitle'}>{subtitle}</span>
                    </div>
                }
            </div>
            <div className={'row header-row'}>
                <div className={'col-12'}>
                    <div className={'alert alert-warning'}>Pensez à bien éditer le nouveau mandat de résiliation depuis
                        le Publish après avoir fait la modification.
                    </div>
                </div>
                <div className={'col-12 header-row'}>
                    <form className={'form-content'} onSubmit={handleSubmit}>
                        <Field
                            name={'compagnie'}
                            component={AutoComplete}
                            label="Compagnie d'assurance"
                            data={listInsurers}
                            defaultValue={assureur}
                        />
                        <Field
                            name={'contrat'}
                            component={TextFieldRedux}
                            label={'Numéro de contrat'}
                        />
                        <button
                            className={'btn btn-primary'}
                            disabled={loading}
                        >
                            {loading && <Spinner absolute/>}
                            Valider
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UpdateHamon;
