import {SET_CORRESPONDANT} from '../../actions/app/correspondants/correspondants.actions'

export const correspondantReducer = (correspondant = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_CORRESPONDANT) {
        return payload.data
    } else {
        return correspondant
    }
}
