import APIFrame from "../../../../../Commun/APILink/APIFrame";
import moment from "moment";
import APIImage from "../../../../../Commun/APILink/APIImage";
import { CHECKDOC_URL } from "../../../../../../constants/constants";
import { tokenUser } from "../../../../../../redux/selectors/localStorage/localStorage";

const TacheCheckdoc = ({ task, files }) => {
    return (
        <>
            <div className={'row'}>
                <div className={'col text-center'}>
                    <h2 className={'title-primary text-uppercase'}>Documents - Tiers n°{task.tiers_id}</h2>
                    <p className={'subtitle'}>
                        <span className={'text-uppercase'}>Reçu le {moment(task.date).format('L')}</span> Par {task.type}
                    </p>
                </div>
            </div>
            <div className={'header-row row'}>
                <div className={'col-8'}>
                    {files !== undefined &&
                        files.map((file, index) => (
                            file.extension.toLowerCase() === 'pdf' ? (
                                <div style={{ marginBottom: 30 }} key={index}>
                                    <APIFrame
                                        src={`${file.url}`}
                                        title={`${file.code}`}
                                        frameBorder="0"
                                        style={{ height: '100%', width: '100%', marginBottom: 30 }}
                                    />
                                </div>
                            ) : (
                                <div className={'task-img-container'} key={index}>
                                    <APIImage src={`${file.url}`} alt={'Document'} />
                                </div>
                            )
                        ))
                    }
                </div>
                <div className={`col-md-4 ${files !== undefined ? '' : 'offset-md-4'}`}>
                    <a
                        className={'btn btn-primary'}
                        href={`${CHECKDOC_URL}/ma-tache/AN/${task.id}?access_token=${tokenUser.trim()}`}
                        target={'_blank'}
                        rel="noreferrer"
                    >
                        Ouvrir sur le Checkdoc
                    </a>
                </div>
            </div>
        </>
    );
};

export default TacheCheckdoc;
