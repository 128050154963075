import React from 'react';
import {Field} from "redux-form";
import Spinner from "../../../../Commun/Spinner/Spinner";
import TextFieldRedux from "../../../../Commun/Input/Text/TextFieldRedux";

const AddExpertReport = (props) => {
    const {handleSubmit, loading, expertReportPost} = props

    return (
        <div className={'contract-container container'}>
            <div className={'row header-row'}>
                <div className={'col-12'}>
                    {
                        expertReportPost ?
                            <div className={'row margin-top text-center'}>
                                <div className={'col'}>
                                    <h2 className={'title-primary title-success'}>Le rapport de l'expert a bien été
                                        enregistré&nbsp;!</h2>
                                </div>
                            </div>
                            :
                            <form onSubmit={handleSubmit} className={'form-content'}>
                                <div className="row">
                                    <div className="col">
                                        <Field
                                            name='montant_expertise'
                                            component={TextFieldRedux}
                                            label={'Montant de l\'expertise'}
                                            required
                                        />
                                        <Field
                                            name='commentaire'
                                            component={TextFieldRedux}
                                            label={'Commentaire'}
                                            required
                                        />
                                    </div>
                                </div>
                                {
                                    loading ? <Spinner style={{display: 'block', margin: 'auto'}}/> :
                                        <button className={'btn btn-primary d-block mt-5'} type={'submit'}>
                                            Ajouter
                                        </button>
                                }

                            </form>
                    }
                </div>
            </div>
        </div>
    );
};

export default AddExpertReport;
