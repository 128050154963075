import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { postRemoveHamon } from '../../../../../redux/actions/app/hamon/hamon.actions';
import { getContractData } from '../../../../../redux/selectors/contract/contract.selectors';
import {
    confirmationResilHamonGetLink,
    postRemoveHamonGetLink
} from '../../../../../redux/selectors/links/links.selectors';
import Hamon from './Hamon';
import {postConfirmResilHamon} from "../../../../../redux/actions/app/contracts/contracts.actions";

const mapStateToProps = (state, ownProps) => {
    return {
        contract: getContractData(state),
        query_resil_hamon: confirmationResilHamonGetLink(state),
        uriDeleteHamon: _.replace(postRemoveHamonGetLink(state), '{id}', ownProps.contract.id),
    };
};

const mapDispatchToProps = {
    postRemoveHamon,
    postConfirmResilHamon
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { uriDeleteHamon } = stateProps;
    const { postRemoveHamon } = dispatchProps;


    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        postRemoveHamon: () => postRemoveHamon({ query: uriDeleteHamon }),
    };
};

const HamonContainer = compose(connect(mapStateToProps, mapDispatchToProps, mergeProps))(Hamon);

export default HamonContainer;
