import {createSelector} from 'reselect'
import _ from "lodash";

const quittancesSelector = state => state.quittances
const quittanceSelector = state => state.quittance

export const getQuittancesData = createSelector(
    quittancesSelector,
    quittances => quittances.data
)

export const getQuittanceDetails = createSelector(
    quittanceSelector,
    quittance => quittance.details
)

export const getQuittanceTableau = createSelector(
    quittanceSelector,
    quittance => _.orderBy(quittance.tableau, ['date'], ['asc'])
)
