import {connect} from 'react-redux'
import {formValueSelector, getFormValues} from 'redux-form'
import Adversaires from './Adversaires'

const mapStateToProps = state => {
    const selector = formValueSelector('constat')

    return {
        nbAdversaires: selector(state, 'nb_vehicules'),
        data: getFormValues("constat")(state),
    }
}

const AdversairesContainer = connect(mapStateToProps)(Adversaires)

export default AdversairesContainer
