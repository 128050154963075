import React from 'react';
import {Field} from "redux-form";
import RadioFieldRedux from "../../../../../../../Commun/Input/Radio/RadioFieldRedux";
import _ from "lodash";

const Documents = (props) => {
    const {listDocuments, constatCheck, roles, belairIdAuth, belairId} = props

    return (
        <div className="col-md-12 col-sm-12">
            <div className={`form-content h-100`}>
                <h1 className={'title-primary'}>Liste des documents attendus</h1>

                {listDocuments.map((doc, index) => (
                    <div className={'col'} key={index}>
                        {
                            <div className="row">
                                <div className="col-10">
                                    <p>{doc.description}</p>
                                </div>
                            </div>
                        }
                    </div>
                ))}

                {(constatCheck === 'T' && _.includes(belairIdAuth, belairId) && _.includes(roles, 'gestionnaire indem')) && <>
                    <h1 className={'title-primary'}>Réception de documents</h1>
                    <Field
                        name='reception_constat'
                        component={RadioFieldRedux}
                        label={'Avez-vous réceptionné le constat du client ou du courtier ?'}
                        data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                        required
                    />
                </>
                }
            </div>
        </div>
    );
};

export default Documents;
