import React, { Component } from 'react'
import Spinner from "../Spinner";

/*
* LoadingIndicator, ErrorIndicator
* Permet de choisir quel composant afficher pour le loader ou les errors
*
* print
* Permet d'attendre qu'une props soit affiché
* Component = ({ data }) => <div>Component {data}</div>
* loader({print: ['data']})
*
* load
* Fonction lancé au loading du Composant
*
* error
* Props qui determine si le composant est entrée en erreur
*
* delay
* Le composant sera rendu APRES le delay (ms)
* loader({ delay: 200 })
*
* */

const getTypeOf = (something) => {
    const getType = {}
    return something && getType.toString.call(something)
}

const isFunction = (functionToCheck) => {
    const type = getTypeOf(functionToCheck)
    return type && type === '[object Function]'
}

const isString = (stringToCheck) => {
    const type = getTypeOf(stringToCheck)
    return type && type === '[object String]'
}

const hasStatus = (prop, propProcessor, defaultProp, defaultValue) => (props, state, context) => {
    if (prop === undefined) {
        const status = props[defaultProp]
        return status === undefined ? defaultValue : !!status
    }

    if (Array.isArray(prop)) {
        const boolProps = prop.map(p => !!props[p])
        return propProcessor(boolProps)
    }

    if (isFunction(prop)) {
        return !!prop(props, context)
    }

    return !!prop
}

const getDisplayName = c => c.displayName || c.name || 'Component'

const loader = (props = {}) => {
    const { LoadingIndicator, ErrorIndicator, print, load, error, delay } = props

    const loadFunctionName = isString(load) ? load : 'load'
    const isLoadFunction = isFunction(load)

    const isLoaded = hasStatus(print, bs => !bs.includes(false), 'loaded', true)
    const isInError = hasStatus(error, bs => bs.includes(true), 'error', false)

    return (ComposedComponent) => {
        const displayName = `Loader(${getDisplayName(ComposedComponent)})`

        return class extends Component {
            static displayName = displayName

            static getDerivedStateFromProps(props, state) {
                const isLoadAFunction = isFunction(props[loadFunctionName])

                if (isLoadAFunction) {
                    return {
                        ...state,
                        props: {
                            ...props,
                            [loadFunctionName]: undefined,
                        },
                    }
                }

                return { ...state, props }
            }

            constructor(props, context) {
                super(props, context)

                this.state = {
                    props: {},
                    print: true,
                }
            }

            componentDidMount() {
                if (isLoadFunction) {
                    load(this.props, this.context)
                }

                const loadFunction = this.props[loadFunctionName]
                if (isFunction(loadFunction)) {
                    loadFunction(this.props, this.context)
                }

                if (delay) {
                    this.setState(state => ({ ...state, print: false }))
                    this.timer = setTimeout(() => this.setState(state => ({ ...state, print: true })), delay)
                }
            }

            componentWillUnmount() {
                if (this.timer) {
                    clearTimeout(this.timer)
                }
            }

            render() {
                const { props } = this.state
                if (isInError(this.props, this.state, this.context)) {
                    return ErrorIndicator === undefined ? <p {...props}>Une erreur est survenue</p> : <ErrorIndicator {...props} />
                }

                if (isLoaded(this.props, this.state, this.context)) {
                    return <ComposedComponent {...props} />
                }

                if (!this.state.print) {
                    return null
                }

                return LoadingIndicator === undefined ? <div className="text-center"><p className={"font-weight-bolder"}>Chargement en cours</p><Spinner /></div> : <LoadingIndicator {...props} />
            }
        }
    }
}

export default loader
