import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import AddAccessoires from "./AddAccessoires";
import {formValueSelector, reduxForm, change} from "redux-form";
import ErrorHttpComponent from "../../../../Commun/Error/ErrorHttpComponent";
import {getErrorEntity, getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {
    ADD_ACCESSOIRES,
    postAddAccessoires
} from "../../../../../redux/actions/app/accessoires/accessoires.actions";
import {accidentGetLink, updateAccidentAccessoiresGetLink} from "../../../../../redux/selectors/links/links.selectors";
import _ from "lodash";
import {ACCIDENT, getAccident} from "../../../../../redux/actions/app/accidents/accidents.actions";
import {getAccidentData} from "../../../../../redux/selectors/accident/accident.selectors";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector('add_accessoires')

    return {
        uri: updateAccidentAccessoiresGetLink(state),
        loadingAddAccessoires: getLoadingEntity(state, ADD_ACCESSOIRES),
        errorAddAccessoires: getErrorEntity(state, ADD_ACCESSOIRES),
        loaded: getLoadingEntity(state, ACCIDENT) === false,
        error: getErrorEntity(state, ACCIDENT),
        accessoires: selector(state, 'accessoires') || {},
        contract: ownProps.match.params.id,
        accident: ownProps.match.params.ids,
        uri_accident: accidentGetLink(state),
        accident_obj: getAccidentData(state)

    }
}
const mapDispatchToProps = dispatch => bindActionCreators({
    postAddAccessoires: ({query, form, accident}) => postAddAccessoires({query, form, accident}),
    getAccident: ({query, id}) => getAccident({query, id}),
    change: (field, value) => change('add_accessoires', field, value)
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {postAddAccessoires, getAccident} = dispatchProps
    const {uri, contract, accident, uri_accident} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getAccident({
            query: _.replace(_.replace(uri_accident, '{id}', contract), '{ids}', accident),
            id: accident
        }),
        onSubmit: (form) => postAddAccessoires({
            query: _.replace(_.replace(uri, '{id}', contract), '{ids}', accident),
            form
        }),
    }
}

const AddAccessoiresContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'add_accessoires'
    }),
    loader({ErrorIndicator: ErrorHttpComponent})
)(AddAccessoires)

export default AddAccessoiresContainer
