import {createSelector} from 'reselect'

const dashboardConseillerTeleventeSelectors = state => state.dashboard_conseiller_televente

export const getDashboardConseillerTeleventeData = createSelector(
    dashboardConseillerTeleventeSelectors,
    dashboard => dashboard
)
export const getDashboardConseillerTeleventeTeamResults = createSelector(
    dashboardConseillerTeleventeSelectors,
    dashboard => dashboard.team_results
)

export const getDashboardConseillerTeleventeUserResult = createSelector(
    dashboardConseillerTeleventeSelectors,
    dashboard => dashboard.user_result
)