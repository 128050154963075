import {connect} from 'react-redux'
import Chocs from './Chocs'
import {getContractCodeProduit} from "../../../../../../../../redux/selectors/contract/contract.selectors";

const mapStateToProps = state => {
    return {
        produit: getContractCodeProduit(state),
    }
}

const ChocsContainer = connect(mapStateToProps)(Chocs)

export default ChocsContainer
