const validate = values => {
    const errors = {}

    const requiredFields = [
        'destinataire_id',
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Ce champ est obligatoire'
        }
    })

    if (values['files0'] && values['files0'].size > 4000000) {
        errors['files0'] = 'Le fichier est trop volumineux.'
    }
    if (values['files1'] && values['files1'].size > 4000000) {
        errors['files1'] = 'Le fichier est trop volumineux.'
    }
    if (values['files2'] && values['files2'].size > 4000000) {
        errors['files2'] = 'Le fichier est trop volumineux.'
    }
    if (values.destinataire_id === 'Autres' && !values.profile) {
        errors.profile = 'Ce champ est obligatoire'
    }
    if (values.destinataire_id === 'Autres' && !values.email) {
        errors.email = 'Ce champ est obligatoire'
    }
    if (values.copieLibre && !values.copieLibreEmail) {
        errors.copieLibreEmail = 'Ce champ est obligatoire'
    }
    if (values.copieLibre && !values.copieLibreProfile) {
        errors.copieLibreProfile = 'Ce champ est obligatoire'
    }

    return errors
}

export default validate
