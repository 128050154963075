import {SET_ANTECEDENTS} from "../../actions/app/antecedents/antecedents.actions";

export const antecedentsReducer = (antecedent = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_ANTECEDENTS) {
        return {data: {...antecedent?.data, [payload.type]: payload.data.data}};
    } else {
        return antecedent
    }
}
