import {SET_QUOTE} from "../../actions/app/quotes/quotes.actions";

export const quoteReducer = (quote = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_QUOTE) {
        return payload.data
    } else {
        return quote
    }
}
