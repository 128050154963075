import { SET_DEROGATIONS } from '../../actions/app/derogations/derogations.actions';

export const derogationsReducer = (derogations = [], action = {}) => {
    const { payload } = action;

    if (action.type === SET_DEROGATIONS) {
        return payload.data;
    } else {
        return derogations;
    }
};
