import React from 'react'
import {Link} from "react-router-dom";

const Apporteurs = (props) => {
    const {apporteurs} = props

    return (
        apporteurs.length >= 1 &&
        <div className={'bloc-info bloc-contracts'}>
            <h2>Concessionnaires attachés au courtier</h2>
            <div className={'content row'}>
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">Nom</th>
                        <th scope="col">Détail</th>
                    </tr>
                    </thead>
                    <tbody>
                    {apporteurs.map((a, index) =>
                        <tr key={index}>
                            <td>{a.name}</td>
                            <td><Link to={`/tiers/${a.codeBelair}`} className={'btn btn-more'}/></td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Apporteurs
