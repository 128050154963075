import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {getFaqCategoriesData} from "../../../../redux/selectors/support/support.selectors";
import {ConseillerFaqLink} from "../../../../redux/selectors/links/links.selectors";
import {getErrorEntity, getLoadingEntity} from "../../../../redux/selectors/ui/ui.selectors";
import {FAQ_CAT, getFaqCat} from "../../../../redux/actions/app/faq_categories/faq_categories.actions";
import {messageUser} from "../../../../redux/selectors/localStorage/localStorage";
import Support from "./Support";

import ErrorHttpComponent from "../../../Commun/Error/ErrorHttpComponent";
import {expandQuestion} from "../../../../redux/actions/app/faq/faq.actions";
import loader from "../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    return {
        faq_categories: getFaqCategoriesData(state),
        uri: ConseillerFaqLink(state),
        loaded: getLoadingEntity(state, FAQ_CAT) === false,
        error: getErrorEntity(state, FAQ_CAT),
        message: messageUser
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    getFaqCat: (query) => getFaqCat({query}),
    expandQuestion
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getFaqCat} = dispatchProps
    const {uri} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getFaqCat(uri)
    }
}


const SupportContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(Support)


export default SupportContainer
