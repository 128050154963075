import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {getErrorEntity, getLoadingEntity} from '../../../../../redux/selectors/ui/ui.selectors'
import { giftCardsGetLink} from '../../../../../redux/selectors/links/links.selectors'
import ErrorHttpComponent from '../../../../Commun/Error/ErrorHttpComponent'
import loader from "../../../../Commun/Spinner/Loader/Loader";
import GiftCards from "./GiftCards";
import {getGiftCards, GIFT_CARDS} from "../../../../../redux/actions/app/gift_cards/gift_cards.actions";
import _ from "lodash";
import {getGiftCardsData} from "../../../../../redux/selectors/gift_cards/gift_cards.selectors";

const mapStateToProps = (state, ownProps) => {
    return {
        gift_cards: getGiftCardsData(state),
        query: giftCardsGetLink(state),
        loaded: getLoadingEntity(state, GIFT_CARDS) === false,
        error: getErrorEntity(state, GIFT_CARDS),
        contract_id: ownProps.contract?.id,
        tiers_id: ownProps.contract?.souscripteur?.id
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getGiftCards
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getGiftCards} = dispatchProps
    const {query, contract_id, tiers_id} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getGiftCards({query: _.replace(_.replace(query, '{id}', tiers_id), '{ids}', contract_id)})
    }
}

const GiftCardsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(GiftCards)

export default GiftCardsContainer
