import React, {useEffect, useState} from 'react';
import DatePickerFieldRedux from "../../../../../../Commun/Input/DatePicker/DatePickerFieldRedux";
import moment from "moment";
import TextFieldRedux from "../../../../../../Commun/Input/Text/TextFieldRedux";
import {normalizeHour} from "../../../../../../../utils/normalizeInputs";
import {Field} from "redux-form";
import CheckboxFieldRedux from "../../../../../../Commun/Input/Checkbox/CheckboxFieldRedux";
import SelectFieldRedux from "../../../../../../Commun/Input/Select/SelectFieldRedux";
import {getInsurers} from "../../../../../../../utils/function";


const FormAvenantSimpleDateEffet = (props) => {
    const {changeValue, isManagerQualite, isManagerEC, contract, loiHamon} = props
    const [listInsurers, setListInsurers] = useState(false)

    async function fetchInsurers() {
        let compagnys = await getInsurers()
        setListInsurers(compagnys)
    }

    useEffect(() => {
        if (!listInsurers) {
            fetchInsurers()
        }
    }, [listInsurers]);

    useEffect(() => {
        if (loiHamon) {
            changeValue('dateEffet', moment().add(33, 'd'));
            changeValue('heureEffet', '00:00');
        }
    }, [loiHamon])

    return (
        <>
            {
                contract.situation.statutCode === '2' && !contract.hamon.raisonSociale &&
                <>
                    <div className="row">
                        <div className="col">
                            <Field
                                component={CheckboxFieldRedux}
                                name={'loiHamon'}
                                label={'Souscription en Loi Hamon'}
                            />
                        </div>
                    </div>

                    {loiHamon &&
                        <>
                            <div className="row">
                                <div className="col">
                                    <Field
                                        name='compagnie'
                                        component={SelectFieldRedux}
                                        label="Compagnie d'assurance"
                                    >
                                        {listInsurers && listInsurers.map((insurer, index) => (
                                            <option key={index} value={insurer.code}>{insurer.libelle}</option>
                                        ))}
                                    </Field>
                                </div>

                                <div className="col">
                                    <Field
                                        name={'police'}
                                        component={TextFieldRedux}
                                        label={'Numéro de contrat'}
                                    />
                                </div>
                            </div>

                            <div className={'alert alert-warning'}>
                                <div className="p-3">
                                    <p>Pensez à générer la LR et la déposer sur Neopost après la validation du
                                        formulaire.</p>
                                </div>
                            </div>
                        </>
                    }
                </>
            }

            <div className="row">
                <div className="col">
                    <Field
                        name={"dateEffet"}
                        component={DatePickerFieldRedux}
                        label={'Date d\'effet'}
                        minDate={!isManagerQualite && !isManagerEC ? moment() : null}
                        maxDate={!isManagerQualite && !isManagerEC ? moment().add(40, 'd') : null}
                    />
                </div>
                <div className="col">
                    <Field
                        name='heureEffet'
                        component={TextFieldRedux}
                        label={'Heure d\'effet'}
                        normalize={normalizeHour}
                        placeholder={'HH:mm'}
                    />
                </div>
            </div>
        </>
    );
};

export default FormAvenantSimpleDateEffet;
