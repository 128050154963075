import React, {Fragment} from 'react';
import PropTypes from 'prop-types'
import QuoteCard from "../../../../../Commun/Card/QuoteCard";

const DevisTable = (props) => {
    const {quotes, meta, fetchQuotes, filters, roles} = props

    return (
        <Fragment>
            {quotes ?
                <>
                    {quotes.map((q, index) => (
                        <QuoteCard quote={q} key={index} roles={roles}/>
                    ))}
                    <nav>
                        <ul className="pagination pointer">
                            {meta.current_page > 1 &&
                                <li className="page-item"
                                    onClick={() => fetchQuotes({filters: {...filters, page: meta.current_page - 1}})}>
                                    <span className="page-link">←</span>
                                </li>
                            }
                            <li className="page-item"><span className={`page-link current`}>{meta.current_page}</span>
                            </li>
                            {quotes.length >= 10 &&
                                <li className="page-item"
                                    onClick={() => fetchQuotes({filters: {...filters, page: meta.current_page + 1}})}>
                                    <span className="page-link">→</span>
                                </li>
                            }
                        </ul>
                    </nav>
                </>
                : <p className={'text-center'}>Vous n'avez pas de devis</p>
            }
        </Fragment>
    );
};

DevisTable.propTypes = {
    quotes: PropTypes.array,
    meta: PropTypes.object,
    fetchQuotes: PropTypes.func,
    filters: PropTypes.object
};

export default DevisTable;
