import moment from "moment";
import { Field } from "redux-form";
import SelectFieldRedux from "../../../../../Commun/Input/Select/SelectFieldRedux";
import MyButton from "../../../../../Commun/Button/Button";
import Spinner from "../../../../../Commun/Spinner/Spinner";
import CheckboxFieldRedux from "../../../../../Commun/Input/Checkbox/CheckboxFieldRedux";
import React, {Fragment, useState} from "react";
import {typeAgendaCompta} from "../../../../../../utils/optionsSelect";

const Tracfin = (props) => {
    const { task: { date, type, paniere, tiers_id, description }, loading, loadingTerminate, unsetValue, handleSubmit, isReassign} = props;

    const [refusRgt, setRefusRgt] = useState(false);
    const [selectedPaniere, setSelectedPaniere] = useState('');

    const handleRefusRgtChange = (e) => {
        const { checked } = e.target;
        setRefusRgt(checked);
        if (checked) {
            unsetValue('reassign_paniere')
        }
    };

    const renderDescription = () => {
        const descriptionArray = description.split('<br/>');
        return (
            <>
                {descriptionArray.map((line, index) => (
                    <Fragment key={index}>
                        {line}
                        <br/>
                    </Fragment>
                ))}
            </>
        );
    };

    if (isReassign) {
        return (
            <div className={'text-center'}>
                <h2 className={'title-primary title-success'}>Votre tâche a bien été transférée&nbsp;!</h2>
            </div>
        );
    }

    return (
        <div className={'container'}>
            <div className={'row'}>
                <div className={'col text-center'}>
                    <h2 className={'title-primary text-uppercase'}>{paniere} - Tiers n°{tiers_id}</h2>
                    <p className={'subtitle'}>Tâche du {moment(date).format('L')}. Reçu par {type}</p>
                </div>
            </div>
            <div className={'header-row row'}>
                <div className={'col-8'}>
                    <p className={'form-content text-center'} style={{whiteSpace: 'pre-line'}}>
                        <strong>Un remboursement est prévu pour ce client</strong><br/>
                        Un remboursement est prévu pour ce client mais il a été identifié PPE ou GDA.<br/> Procéder à la
                        vérification ou demander l'accord au service concerné pour procéder au règlement.
                        <hr/>
                        {renderDescription()}
                    </p>
                </div>
                <div className={`col-md-4`}>
                    <form className={'form-content'} onSubmit={handleSubmit}>
                        <Field
                            name='reassign_paniere'
                            component={SelectFieldRedux}
                            label={'Sélectionner la panière à réaffecter'}
                            value={selectedPaniere}
                            onChange={(e) => setSelectedPaniere(e.target.value)}
                        >
                            <option value={''}>Sélectionner la panière</option>
                            <option value={'ACPTE_AV'}>ACPTE_AV</option>
                            <option value={'ACPTE_RES'}>ACPTE_RES</option>
                            <option value={'AG_COMPTA'}>AG_COMPTA</option>
                            <option value={'RGT'}>RGT</option>
                        </Field>
                        <Field
                            name={'refus_rgt'}
                            component={CheckboxFieldRedux}
                            label={'Je refuse le règlement'}
                            checked={refusRgt}
                            onChange={handleRefusRgtChange}
                        />
                        {loadingTerminate ? <Spinner/>
                            : <MyButton
                                variant='raised'
                                color='secondary'
                                size='large'
                                className={'btn btn-primary mt-5'}
                                disabled={loading}
                            >
                                {loading && <Spinner absolute/>}
                                Valider
                            </MyButton>
                        }
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Tracfin;
