import React, {useEffect, useState} from 'react'
import {Field} from "redux-form";
import TextFieldRedux from "../../../../Commun/Input/Text/TextFieldRedux";
import MyButton from "../../../../Commun/Button/Button";
import Spinner from "../../../../Commun/Spinner/Spinner";
import {getCountries, getCitiesFromFrance} from "../../../../../utils/function";
import AutoComplete from "../../../../Commun/Input/Autocomplete/Autocomplete";
import _ from "lodash";
import SelectFieldRedux from "../../../../Commun/Input/Select/SelectFieldRedux";
import {listFormeJuridique} from "../../../../../utils/optionsSelect";

const InformationsNaissance = (props) => {
    const {loading, handleSubmit, tiers, ville, pays, societe} = props
    const [listCountries, setListCountries] = useState(false)
    const [listVilles, setListVilles] = useState([])
    const [query, setQuery] = useState('');
    const [queries, setQueries] = useState([]);

    async function fetchCountries() {
        let countries = await getCountries()
        let list = _.orderBy(countries, ['name'], ['asc'])

        list = list.map(c => ({
            value: c.id,
            label: c.name
        }))

        setListCountries(list)
    }

    useEffect(() => {
        if (!listCountries) {
            fetchCountries()
        }
    }, [listCountries]);

    async function fetchCites(value) {
        let cities = await getCitiesFromFrance(value)
        if (!Array.isArray(cities)) {
            return
        }
        let list = _.orderBy(cities, ['sInsee'], ['asc'])

        list = cities.map(c => ({
            value: c.sBirthplace,
            label: c.sBirthplace
        }))

        const newVilles = _.uniqBy([...listVilles, ...list], 'label')
        setListVilles(newVilles)
    }

    useEffect(() => {
        if (query && query.length >= 2 && pays === 'FRA' && !queries.includes(query)) {
            fetchCites(query)
            setQueries([...queries, query])
        }
    }, [query, pays]);

    async function onHandleSubmit(data) {
        if(pays === 'FRA' && ville) {
            getCitiesFromFrance(ville, 'ValidateFormBirth')
        }
        handleSubmit(data)
    }

    return (
        <div className={'contract-container container'}>
            <div className={'row return-content'}>
                <div className={'col'}>
                    <button onClick={() => props.history.go(-1)} className={'btn btn-return'}>Retour</button>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col'}>
                    <h1 className={'title-primary'}>{tiers.belair_id} - {tiers.titre} {tiers.name}</h1>
                </div>
            </div>
            <div className={'row header-row'}>
                <div className={'col-12'}>
                    <form className={'form-content'} onSubmit={onHandleSubmit}>
                        <div className={'row'}>
                            <div className={'col-6'}>
                                <Field
                                    name={'pays'}
                                    component={AutoComplete}
                                    label="Pays de naissance"
                                    data={listCountries}
                                    required
                                />
                            </div>

                            {
                                !societe ?
                                    <>
                                        <div className={'col-6'}>
                                            {pays === 'FRA' ?
                                                <Field
                                                    name={'ville'}
                                                    component={AutoComplete}
                                                    label={'Ville de naissance'}
                                                    data={listVilles}
                                                    setQuery={setQuery}
                                                    required
                                                />
                                                :
                                                <Field
                                                    name={'ville'}
                                                    component={TextFieldRedux}
                                                    label={'Ville de naissance'}
                                                    required
                                                />
                                            }
                                        </div>

                                        <div className={'col-6'}>
                                            <Field
                                                name={'nom'}
                                                component={TextFieldRedux}
                                                label={'Nom de naissance'}
                                                maxLength={20}
                                            />
                                        </div>
                                    </> :
                                    <>
                                        <div className={'col-6'}>
                                            <Field
                                                name='statut_juridique'
                                                component={SelectFieldRedux}
                                                label={'Statut juridique'}
                                            >
                                                <option value={""}>Sélectionnez le statut juridique de l'entreprise
                                                </option>
                                                {listFormeJuridique.map((item, index) => {
                                                    return <option key={index} value={item.id}>{item.name}</option>
                                                })}
                                            </Field>
                                        </div>
                                        <div className={'col-6'}>
                                            <Field
                                                name={'siren_siret'}
                                                component={TextFieldRedux}
                                                label={'N° SIREN/SIRET'}
                                                type={'tel'}
                                                required
                                            />
                                        </div>
                                    </>
                            }
                        </div>
                        <MyButton
                            variant='raised'
                            color='secondary'
                            size='large'
                            className={'btn btn-primary'}
                            disabled={loading}
                        >
                            {loading && <Spinner absolute/>}
                            Modifier les informations
                        </MyButton>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default InformationsNaissance
