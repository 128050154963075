import React, {useEffect, useState} from 'react';
import Up from "../../../../assets/img/icons/chevron_top_light.png";

const GoToTop = () => {
    const [scrollY, setScrollY] = useState(0)

    const GoToTop = () => {
        window.scroll({top: 0, left: 0, behavior: 'smooth'})
    }

    const handleScroll = () => {
        setScrollY(window.pageYOffset)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return (
        <button onClick={GoToTop} className={`btn btn-up-rounded ${scrollY > 100 ? 'active' : ''}`}
                title="haut de page">
            <img className={'img-up'} src={Up} alt=""/>
        </button>
    );
};

export default GoToTop;
