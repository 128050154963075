import {connect} from 'react-redux'
import {compose} from "redux";
import Opposition from "./Opposition";
import {deleteOppositionsAccidentGetLink} from "../../../../../redux/selectors/links/links.selectors";
import _ from "lodash";
import {postDeleteOppositions} from "../../../../../redux/actions/app/accidents/accidents.actions";

const mapStateToProps = (state, ownProps) => {
    return {
        uri_delete: deleteOppositionsAccidentGetLink(state),
        accidentId: ownProps.accident,
        contractId: ownProps.contract,
    }
}

const mapDispatchToProps = {
    postDeleteOppositions,
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uri_delete, contractId, accidentId } = stateProps
    const { postDeleteOppositions } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        submitDeleteOppositions: () => postDeleteOppositions({query: _.replace(_.replace(uri_delete, '{id}', contractId), '{ids}', accidentId)}),
    }
}

const OppositionContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(Opposition)

export default OppositionContainer