import {SET_FIDELISATIONS} from "../../actions/app/fidelisations/fidelisations.actions";

export const fidelisationsReducer = (fidelisations = [], action = {}) => {
    const { payload } = action;

    if (action.type === SET_FIDELISATIONS) {
        return payload.data;
    } else {
        return fidelisations;
    }
};
