import React from 'react'
import moment from "moment";
import {Field} from "redux-form";
import CheckboxFieldRedux from "../../../../Commun/Input/Checkbox/CheckboxFieldRedux";
import _ from "lodash";
import {tiersPayeurRelation} from "../../../../../utils/optionsSelect";
import PlusCard from "../../../../Commun/Card/PlusCard";
import CallToAction from "../../../../Commun/Button/CallToAction/CallToAction";
import Spinner from "../../../../Commun/Spinner/Spinner";
import {Link} from "react-router-dom";

const Souscripteur = ({isWakam, souscripteur, conducteur, coeff, submitSetDriverNotMinor, tiersPayeur, contract, roles, submitDeleteTiersPayeur}) => {

    return (
        <div className={'bloc-info bloc-souscripteur'}>
            <h2>Souscripteur / Assuré(e)</h2>

            <div className={'content row'}>
                {((conducteur.nom + ' ' + conducteur.prenom !== souscripteur.nom) || (conducteur.naissance !== souscripteur.naissance)) && !isWakam &&
                    <>
                        <div className={'col-5 left-content'}>
                            <p>Assuré&nbsp;:</p>
                        </div>
                        <div className={'col-7 right-content'}>
                            <p>{conducteur.titre} {conducteur.nom} {conducteur.prenom}</p>
                        </div>
                        <div className={'col-5 left-content'}>
                            <p>Date de naissance de l'assuré&nbsp;:</p>
                        </div>
                        <div className={'col-7 right-content'}>
                            <p><br/>{moment(conducteur.naissance).format('L') || '-'}</p>
                        </div>
                    </>
                }
                {
                    !isWakam && <>
                        <div className={'col-5 left-content'}>
                            <p>Souscripteur&nbsp;:</p>
                        </div>
                        <div className={'col-7 right-content'}>
                            <p>{souscripteur.titre} {souscripteur.nom}</p>
                        </div>
                        <div className={'col-5 left-content'}>
                            <p>Date de naissance souscripteur&nbsp;:</p>
                        </div>
                        <div className={'col-7 right-content'}>
                            <p><br/>{moment(souscripteur.naissance).format('L') || '-'}</p>
                        </div>
                    </>
                }
                <div className={'col-5 left-content'}>
                    <p>Adresse&nbsp;:</p>
                </div>
                <div className={'col-7 right-content'}>
                    <p>{souscripteur.adresse.rue1}</p>
                </div>
                {souscripteur.adresse.rue2 &&
                    <>
                        <div className={'col-5 left-content'}>
                            <p>Complément d'adresse&nbsp;:</p>
                        </div>
                        <div className={'col-7 right-content'}>
                            <p>{souscripteur.adresse.rue2}</p>
                        </div>
                    </>
                }
                {souscripteur.adresse.rue3 &&
                    <>
                        <div className={'col-5 left-content'}>
                            <p>Complément d'adresse&nbsp;:</p>
                        </div>
                        <div className={'col-7 right-content'}>
                            <p>{souscripteur.adresse.rue3}</p>
                        </div>
                    </>
                }
                <div className={'col-5 left-content'}>
                    <p>Code Postal - Ville&nbsp;:</p>
                </div>
                <div className={'col-7 right-content'}>

                    <p>{souscripteur.adresse.codePostal} {souscripteur.adresse.ville}</p>
                </div>
                <div className={'col-5 left-content'}>
                    <p>Téléphone&nbsp;:</p>
                </div>
                <div className={'col-7 right-content'}>
                    <p>{souscripteur.phone || '-'}</p>
                </div>

                <div className={'col-5 left-content'}>
                    <p>Email&nbsp;:</p>
                </div>
                <div className={'col-7 right-content'}>
                    <p>{souscripteur.email || '-'}</p>
                </div>

                {coeff &&
                    <>
                        <div className={'col-5 left-content'}>
                            <p>Coeff commercial&nbsp;:</p>
                        </div>
                        <div className={'col-7 right-content'}>
                            <p>{coeff}</p>
                        </div>
                    </>
                }

                <div className={'col-5 left-content'}>
                    <p>Acompte client&nbsp;:</p>
                </div>
                <div className={'col-7 right-content'}>
                    <p>{souscripteur.acompte}€</p>
                </div>

                <div className={'col-12 full-content'}>
                    {
                        souscripteur.conducteurMineur && (
                            <p>Le véhicule a été déclaré pour être conduit par un mineur</p>
                        )
                    }
                </div>
                <div className={'col-5 left-content'}>
                    <p>Tiers payeur&nbsp;:</p>
                </div>
                <div className={'col-7 right-content'}>
                    { tiersPayeur.nom ?
                        <>
                            {
                                tiersPayeur.relation ?
                                    <PlusCard title={`${tiersPayeur.nom} ${tiersPayeur.prenom || ''}`} content={
                                        <>
                                            <p>
                                                {_.find(tiersPayeurRelation, ['code', tiersPayeur.relation]) ? _.find(tiersPayeurRelation, ['code', tiersPayeur.relation]).libelle : 'Non renseigné'}
                                                {tiersPayeur.relation === 'A' && tiersPayeur.raison_autre ?  ` : ${tiersPayeur.raison_autre}` : ''}
                                            </p>
                                        </>
                                    }/>
                                    : <p>{tiersPayeur.nom}</p>
                            }
                            {!_.includes(roles, 'gestionnaire televente') ?
                                <div className={'w-100'}>
                                    <CallToAction
                                        url={{pathname: `/contrat/${contract}/edition-tiers-payeur/${tiersPayeur.id}`}}
                                        action={'edit-tiers-payeur'}
                                        toolText={'Modifier le tiers payeur'}
                                    />
                                    <CallToAction
                                        action={'delete-tiers-payeur'}
                                        toolText={'Supprimer le tiers payeur'}
                                        onClick={() => {
                                            if (window.confirm(`Êtes-vous sûr(e) de vouloir supprimer le tiers payeur ?`)) submitDeleteTiersPayeur(document.code)
                                        }}
                                        url={'#'}
                                    />
                                </div> : null
                            }
                        </> :
                        <>
                            {!_.includes(roles, 'gestionnaire televente') && !tiersPayeur.nom ?
                                <p>
                                    <Link to={{pathname: `/contrat/${contract}/ajout-tiers-payeur`}}>
                                        Ajouter un tiers payeur
                                    </Link>
                                </p>: null
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default Souscripteur
