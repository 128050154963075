import {createSelector} from 'reselect'
import _ from 'lodash'

const faqSelector = state => state.faq
const faqCatSelector = state => state.faq_categories
const documentsUtilesSelector = state => state.documents_utiles
const documentationSelector = state => state.documentation.data

export const getFaqData = createSelector(
    faqSelector,
    faq => faq.data
)

export const getFaqCategoriesData = createSelector(
    faqCatSelector,
    faq_categories => faq_categories.data
)

export const getDocumentsUtilesData = createSelector(
    documentsUtilesSelector,
    documents_utiles => documents_utiles.data
)

export const getDataDocumentation = createSelector(
    documentationSelector,
    documentation => documentation
)

export const getFaqExterneEspace = createSelector(
    faqSelector,
    faq => _.filter(faq.data, ['conseiller_externe_faqs_category_id', 1])
)

export const getFaqExterneReglement = createSelector(
    faqSelector,
    faq => _.filter(faq.data, ['conseiller_externe_faqs_category_id', 2])
)

export const getFaqExterneDocument = createSelector(
    faqSelector,
    faq => _.filter(faq.data, ['conseiller_externe_faqs_category_id', 3])
)

export const getFaqExternePieces = createSelector(
    faqSelector,
    faq => _.filter(faq.data, ['conseiller_externe_faqs_category_id', 4])
)

export const getFaqExterneResiliations = createSelector(
    faqSelector,
    faq => _.filter(faq.data, ['conseiller_externe_faqs_category_id', 5])
)

export const getFaqExterneQuestions = createSelector(
    faqSelector,
    faq => _.filter(faq.data, ['conseiller_externe_faqs_category_id', 6])
)

export const getFaqExterneChangeAddress = createSelector(
    faqSelector,
    faq => _.filter(faq.data, ['conseiller_externe_faqs_category_id', 7])
)

export const getFaqExterneCompta = createSelector(
    faqSelector,
    faq => _.filter(faq.data, ['conseiller_externe_faqs_category_id', 8])
)