import React, {useState} from 'react';
import PropTypes from 'prop-types'
import MyButton from "../Button/Button";

const PlusCard = (props) => {
    const {title, content} = props
    const [show, setShow] = useState(false)

    return (
        <>
            <p className={'inline'}>
                {title}
                <MyButton
                    className={'btn btn-rounded'}
                    onClick={() => setShow(!show)}
                >
                    {!show ? '+' : '-'}
                </MyButton>
            </p>
            <div className={`${!show ? 'hidden' : ''}`}>
                {content}
            </div>
        </>
    );
};

PlusCard.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.node.isRequired
};

export default PlusCard;