import * as variables from "../constants/constants";
import axios from "axios";
import {tokenUser} from "../redux/selectors/localStorage/localStorage";

const defaultOptions = {
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
};

export const requestServices = axios.create({
    baseURL: variables.SERVICES_URL,
    ...defaultOptions,
});

export const requestPass = axios.create({
    baseURL: variables.API_URL,
    ...defaultOptions,
    headers: {
        ...defaultOptions.headers,
        Authorization: `Bearer ${tokenUser ? tokenUser.trim() : null}` // voir pour mettre ça dans les cookies plutôt que le localStorage
    },
});
