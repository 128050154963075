import React, {Component} from 'react'
import _ from "lodash";
import moment from 'moment'
import Accident from '../../../../../assets/img/icons/accidents_light.png'
import Document from '../../../../../assets/img/icons/document.svg'
import Search from '../../../../../assets/img/icons/search.svg'
import Expert from '../../../../../assets/img/icons/expert.svg'
import RapportExpert from '../../../../../assets/img/icons/rapport-expert.svg'
import OpenFolder from '../../../../../assets/img/icons/folder-open.svg'
import CompleteFolder from '../../../../../assets/img/icons/complete-folder.svg'
import Indemnisation from '../../../../../assets/img/icons/indemnisation.svg'
import CloseFolder from '../../../../../assets/img/icons/close-folder.svg'

const defineStepAccident = (accident) => {
    let step = 0
    const documents = accident.documents || []

    if (accident.dateOuverture) {
        step = 2
    } else {
        return step + 1
    }

    if (accident.datePassageExpert) {
        step = 3
    } else {
        return step + 1
    }

    if (moment(accident.datePassageExpert) <= moment().add(15, 'days')) {
        step = 4
    } else {
        return step + 1
    }

    if (accident.dateRapportExpert) {
        step = 5
    } else {
        return step + 1
    }

    if (moment(accident.dateRapportExpert) <= moment().add(10, 'days')) {
        step = 6
    } else {
        return step + 1
    }

    if (documents.length > 0 && _.every(documents, { 'recu': true }) && _.every(documents, { 'traite': true })) {
        step = 7
    } else {
        return step + 1
    }

    if (accident.reglements.length > 0 && _.last(accident.reglements).reference === "VALIDE") {
        step = 8
    } else {
        return step + 1
    }

    if (accident.dateFermeture) {
        step = 9
    } else {
        return step + 1
    }

    return step + 1
}

class Timeline extends Component {
    constructor(props) {
        super(props)
        this.state = {
            startIndex: defineStepAccident(this.props.accident) > 1 ? defineStepAccident(this.props.accident) > 7 ? 6 : defineStepAccident(this.props.accident) - 2 : 0
        }
    }


    render(){
        const currentStep = defineStepAccident(this.props.accident)
        let stepLoad = 5
        const nbShowStep = stepLoad

        const events = [
            { description: 'Déclaration', img: Accident },
            { description: 'Déclaration', img: Accident },
            { description: 'Dossier ouvert', img: Document },
            { description: "Passage de l'expert", img: Search },
            { description: "Estimation de l'expert", img: Expert },
            { description: "Rapport de l'expert reçu", img: RapportExpert },
            { description: 'Analyse du dossier', img: OpenFolder },
            { description: 'Dossier complet', img: CompleteFolder },
            { description: 'Indemnisation', img: Indemnisation },
            { description: 'Clôture du dossier', img: CloseFolder },
            { description: 'Clôture du dossier', img: CloseFolder },
        ];

        const handlePrevClick = () => {
            if (this.state.startIndex > 0) {
                this.setState({startIndex: this.state.startIndex - 1});
            }
        };

        const handleNextClick = () => {
            if (this.state.startIndex < events.length - nbShowStep) {
                this.setState({startIndex: this.state.startIndex + 1});
            }
        };

        return (
            <ul className={`timeline d-flex align-items-baseline`}>
                <button onClick={handlePrevClick} className={`call-to-action ${this.state.startIndex === 0 ? 'disabled' : ''}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path fill="#004161" d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/>
                    </svg>
                </button>

                {events.slice(this.state.startIndex, this.state.startIndex + stepLoad).map((event, index) => {
                    const isActive = currentStep === this.state.startIndex + index
                    const isCompleted = currentStep > this.state.startIndex + index

                    return (
                            <li key={this.state.startIndex + index} className={`timeline-event ${index === 0 || index === (stepLoad - 1) ? 'opacity-0 w0' : ''} ${isCompleted ? 'complete' : ''} ${index === 3 ? 'last' : ''} ${isActive ? 'active' : ''}`}>
                                <span>{event.description}</span>
                                <img src={event.img} alt="Accident" width={40} />
                            </li>
                    )
                })}

                <button onClick={handleNextClick} className={`call-to-action ${this.state.startIndex === events.length - nbShowStep ? 'disabled' : ''}`} >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path fill="#004161" d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/>
                    </svg>
                </button>

            </ul>
        )
    }
}

export default Timeline
