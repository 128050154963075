import React from 'react'
import moment from "moment";

const TacheAvtAdresse = (props) => {
    const {task: {date, type, description, tiers_id}} = props

    return (
        <div className={'container'}>
            <div className={'row'}>
                <div className={'col text-center'}>
                    <h2 className={'title-primary text-uppercase'}>Avenant changement d'adresse - Tiers
                        n°{tiers_id}</h2>
                    <p className={'subtitle'}>Reçu le {moment(date).format('L')}. par {type}</p>
                </div>
            </div>
            <div className={'header-row row'}>
                <div className={'col-12'}>
                    <p className={'form-content text-center'}>
                        Le client a fait une demande de déménagement sur son espace personnel mais la zone de
                        tarification change.
                        Merci de passer l'avenant de changement d'adresse sur le formulaire.<br/>
                        {description && <span dangerouslySetInnerHTML={{__html: description}}/>}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default TacheAvtAdresse
