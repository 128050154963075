import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import _ from 'lodash'
import ChequesCadeaux from "./ChequesCadeaux";
import {getUserPermissions} from "../../../../../../redux/selectors/user/user.selectors";
import {getTiersChequesCadeaux} from "../../../../../../redux/selectors/tiers/tiers.selectors";
import {
    POST_CHEQUES_CADEAUX,
    postChequesCadeaux
} from "../../../../../../redux/actions/app/cheques_cadeaux/cheques_cadeaux.actions";
import {voucherTiersGetLink} from "../../../../../../redux/selectors/links/links.selectors";
import {getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";

const mapStateToProps = (state, ownProps) => {
    return {
        eligible: getTiersChequesCadeaux(state),
        canPost: _.includes(getUserPermissions(state), 'read gift voucher'),
        query: _.replace(voucherTiersGetLink(state), '{id}', ownProps.id),
        loadingPost: getLoadingEntity(state, POST_CHEQUES_CADEAUX)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    postChequesCadeaux: ({query, body}) => postChequesCadeaux({query, body})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {query} = stateProps;
    const {postChequesCadeaux} = dispatchProps;

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        postChequesCadeaux: body => postChequesCadeaux({query, body})
    }
}

const ChequesCadeauxContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(ChequesCadeaux)

export default ChequesCadeauxContainer
