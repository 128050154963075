import {connect} from 'react-redux'
import Courtier from "./Courtier";
import {getUserPermissions} from "../../../../../redux/selectors/user/user.selectors";

const mapStateToProps = state => {
    return {
        permissions: getUserPermissions(state),
    }
}

const CourtierContainer = connect(mapStateToProps)(Courtier)

export default CourtierContainer