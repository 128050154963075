import {SET_TIERS, SET_TIERS_BU} from '../../actions/app/tiers/tiers.actions'

export const tiersReducer = (tiers = {}, action = {}) => {
    const {payload} = action

    switch (action.type) {
        case SET_TIERS:
            return payload
        case SET_TIERS_BU:
            return {...tiers, comptes: payload.data.data}
        default:
            return tiers
    }
}
