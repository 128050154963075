import { useState } from 'react';
import moment from "moment";
import CallToAction from "../../../../Commun/Button/CallToAction/CallToAction";
import _ from "lodash";

const Notes = ({ notes, contract, roles, postDelete }) => {
    const [disabledDelete, setDisabledDelete] = useState(false);

    const handleClick = (id) => {
        postDelete({
            id: id
        });
        setDisabledDelete(true);
    };

    return (
        <>
            {notes && notes.length > 0 &&
                <div className={'row header-row'}>
                    <div className={'col'}>
                        <div className={'row'}>
                            {notes.map((note, index) =>
                                <div className="alert alert-info col-6 note" role="alert" key={index}>
                                    <div className={'top'}>
                                        <h4 className="alert-heading">Note d'information sur le dossier</h4>
                                        {!_.includes(roles, 'gestionnaire televente') &&
                                            <>
                                                <CallToAction
                                                    action={'delete-note'}
                                                    toolText={'Supprimer une note'}
                                                    onClick={() => {
                                                        handleClick(note.id)
                                                    }}
                                                    url={'#'}
                                                    disabled={disabledDelete}
                                                />
                                                <CallToAction
                                                    url={{
                                                        pathname: `/contrat/${contract.id}/note`,
                                                        state: {
                                                            edit: true,
                                                            title: `${contract.souscripteur.titre} ${contract.souscripteur.nom} ${contract.souscripteur.id}`,
                                                            subtitle: `Contrat n°${contract.numeroPolice} - ${contract.title}`,
                                                            note: note
                                                        }
                                                    }}
                                                    action={'edit-note'}
                                                    toolText={'Editer une note'}
                                                />
                                            </>
                                        }
                                    </div>
                                    <small>Créée le {moment(note.created_at).format('L')}</small>
                                    <p>{note.titre}</p>
                                    <hr/>
                                    <p dangerouslySetInnerHTML={{__html: note.commentaire}}/>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default Notes;
