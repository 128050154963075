export const EXPIRATION_DATE_SEJOUR = '[Expiration Date Sejour]'
export const POST_EXPIRATION_DATE_SEJOUR = `${EXPIRATION_DATE_SEJOUR} Post`
export const SET_EXPIRATION_DATE_SEJOUR = `${EXPIRATION_DATE_SEJOUR} Set`

export const postExpirationDateSejour = ({form, query}) => ({
    type: POST_EXPIRATION_DATE_SEJOUR,
    payload: {
        data: query,
        body: form,
    }
})

export const setExpirationDateSejour = ({state}) => ({
    type: SET_EXPIRATION_DATE_SEJOUR,
    payload: state
})
