import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import Signatures from './Signatures'
import {
    SIGNATURES,
    fetchSignatures
} from '../../../../../redux/actions/app/signatures/signatures.actions'
import {CONTRACT} from '../../../../../redux/actions/app/contracts/contracts.actions'
import {getLoadingEntity} from '../../../../../redux/selectors/ui/ui.selectors'
import {signaturesGetLink} from '../../../../../redux/selectors/links/links.selectors'
import ErrorHttpComponent from '../../../../Commun/Error/ErrorHttpComponent'
import {getDataSignatures} from '../../../../../redux/selectors/signatures/signatures.selector'
import _ from "lodash";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    return {
        signatures: getDataSignatures(state),
        uri: signaturesGetLink(state, CONTRACT),
        loaded: getLoadingEntity(state, SIGNATURES) === false
    }
}
const mapDispatchToProps = dispatch => bindActionCreators({
    getSignatures: (query) => fetchSignatures({query}),
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getSignatures} = dispatchProps
    const {uri} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getSignatures(_.replace(uri, '{id}', ownProps.contract)),
    }
}

const SignaturesContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(Signatures)

export default SignaturesContainer
