import React from 'react'

const LargeSelectFieldRedux = (props) => {
    const {meta: {touched, error}, input, label, sublabel, img, disabled, unit, ...custom} = props

    return (
        <div className={`largeselect form-group border text-center p-2 pb-5 h-100 rounded position-relative`}>
            <img src={img} alt={label} className={'w-100'}/>
            <div className={'my-3'}>
                <p className={'font-weight-bold m-0'}>{label}</p>
                {sublabel && <p className={'m-0'}>{sublabel}</p>}
                {unit === "lots de 25 exemplaires" && <small><em>Lot de 25</em></small>}
                {unit === "lot de 10 exemplaires" && <small><em>Lot de 10</em></small>}

                <div className={'position-absolute w-75'}
                     style={{left: '50%', transform: 'translateX(-50%)', bottom: 20}}>
                    <select
                        className={'form-control'}
                        {...input}
                        {...custom}
                        disabled={disabled}
                    />
                    {touched && error && <small className="invalid-feedback">{error}</small>}
                </div>

            </div>
        </div>
    )
}

export default LargeSelectFieldRedux
