import React, {Component} from 'react'
import moment from "moment";
import _ from 'lodash'
import {Link} from "react-router-dom";
import {redirectInNewWindow} from "../../../utils/function";
import APILink from "../APILink/APILink";

export default class GrcCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            question: false
        };
        this.toggleQuestion = this.toggleQuestion.bind(this);
        this.close = this.close.bind(this);
    }

    toggleQuestion() {
        this.setState({question: !this.state.question})
    }

    close() {
        this.props.postCloseActivity(_.replace(_.replace(this.props.uriClose, '{tiers_id}', this.props.activity.numTiers), '{ordre_id}', this.props.activity.id))
    }

    render() {
        const {roles, activity, index, show = this.state.question} = this.props

        let arrayFiles = []
        let arrayDownload = []

        if (activity.files_url) {
            let array = activity.files_url
            _.map(array, file => {
                if (_.includes(['pdf', 'png', 'jpg', 'jpeg'], file.extension.toLowerCase())) {
                    arrayFiles.push(file)
                } else {
                    arrayDownload.push(file)
                }
            })
        }

        return (
            <div className={`card-grc ${activity.statutCode === '0' ? 'card-grc-close' : ''}`}>
                <div className={'card-header d-flex'} id={`heading${index}`}>
                    <button
                        className={`btn-rounded ${show ? 'collapsed' : ''}`}
                        type="button"
                        data-toggle="collapse"
                        data-target={`#collapse${index}`}
                        aria-controls={`collapse${index}`}
                        onClick={this.toggleQuestion}
                    >
                        <h3 className={'d-flex justify-content-between align-items-center'}>
                            <span>
                                <i className={`icon icon-${activity.type.replace(/\s/g, '')}`}></i>
                                <span
                                    className={'user'}>{activity.type} </span>{(activity.natureCode !== 'SC' && activity.nature != null) ? activity.nature + ' - ' : ''}{activity.titre}
                            </span>
                            <small className={'text-right'}>{moment(activity.created_at).format('DD/MM/YYYY à H:mm')}
                                <span className={'paniere'}>{activity.utilisateur}</span></small>
                        </h3>
                    </button>
                    {(!_.includes(roles, 'gestionnaire externe') && activity.statutCode !== '0') &&
                        <div>
                            <span
                                className={'btn btn-secondary btn-small'}
                                onClick={this.close}
                            >
                                Clôturer
                            </span>
                        </div>
                    }
                    {
                        activity.statutCode === '0' &&
                        <div>
                            <Link
                                to={{pathname: `/activite/${activity.id}/${activity.numTiers}/transferer`}}
                                className={'btn btn-secondary btn-small'}
                            >
                                Transférer
                            </Link>
                        </div>
                    }
                </div>
                <div className={`card-body collapse ${show && 'show'}`}
                     id={`collapse${index}`}
                     aria-labelledby={`heading${index}`}
                     data-parent="#grc"
                >
                    <div className={'content'} dangerouslySetInnerHTML={{__html: activity.commentaire}}/>
                    {arrayFiles.length >= 1 &&
                        <>
                            <span className={'btn btn-primary btn-small'}
                                  onClick={(e) => redirectInNewWindow({
                                      e,
                                      url: `/les-activites/document/${activity.id}/${activity.numTiers}`,
                                      name: `document-${activity.id}-${activity.numTiers}`
                                  })}>Voir les documents</span>
                        </>

                    }
                    {arrayDownload.length >= 1 &&
                        arrayDownload.map((file, index) => <APILink href={`${file.url}`}
                                  key={index}
                                  className={'btn btn-primary btn-small'}
                                  target={'_blank'}
                                  rel="noopener noreferrer">
                            Télécharger le document
                        </APILink>
                        )
                    }
                </div>
            </div>
        )
    }
}
