import moment from 'moment';
import { useState, useEffect } from 'react';
import { Field } from 'redux-form';
import DatePickerFieldRedux from '../../../../Commun/Input/DatePicker/DatePickerFieldRedux';
import Spinner from '../../../../Commun/Spinner/Spinner';

const DeleteVehiculesFlotte = ({ handleSubmit, changeValue, selected_vehicules, data, loading }) => {
    const [minDate, setMinDate] = useState(moment().subtract(3, 'months'));

    useEffect(() => {
        const mostRecentDate = data.reduce((latestDate, vehicle) => {
            const vehicleDate = moment(vehicle.dateEntree);
            return vehicleDate.isAfter(latestDate) ? vehicleDate : latestDate;
        }, moment('2020-01-01'));

        setMinDate(mostRecentDate);
    }, [data]);

    const handleVehicleSelection = (isSelected, vehicleId) => {
        const updatedSelection = isSelected
            ? [...(selected_vehicules || []), vehicleId]
            : (selected_vehicules || []).filter((id) => id !== vehicleId);
        changeValue('selected_vehicules', updatedSelection);
    };

    return (
        <div className="contract-container container">
            <div className="row header-row">
                <div className="col-12">
                    <div className="alert alert-warning" role="alert">
                        Conseil APRIL Moto : En retirant le dernier véhicule de votre contrat, celui-ci sera automatiquement terminé. Si vous souhaitez remplacer votre 2-roues par un nouveau véhicule, ajoutez d'abord le nouveau 2-roues.
                    </div>
                </div>
                <form className="form-content" onSubmit={handleSubmit}>
                    <div className="row">
                        <label className="col-12">Choisissez les véhicules à sortir de la flotte :</label>
                    </div>
                    <div className="row">
                        {data.map((vehicle, idx) => (
                            <div key={idx} className="col-md-4">
                                <div className="form-check form-check-inline">
                                    <input
                                        name={`vehicules[${vehicle.adhesion}]`}
                                        type="checkbox"
                                        id={`vehicule-${vehicle.adhesion}`}
                                        style={{ marginRight: '10px' }}
                                        onChange={(e) => handleVehicleSelection(e.target.checked, vehicle.adhesion)}
                                    />
                                    <label className="form-check-label" htmlFor={`vehicule-${vehicle.adhesion}`}>
                                        <strong>
                                            {vehicle.marque} {vehicle.modele}
                                        </strong>{' '}
                                        - {vehicle.immatriculation}
                                    </label>
                                </div>
                            </div>
                        ))}
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-4">
                            <Field
                                name="date_sortie"
                                component={DatePickerFieldRedux}
                                label="Date de sortie"
                                minDate={minDate} // Set the dynamic minDate here
                                maxDate={moment()}
                            />
                        </div>
                        <div className="alert alert-warning" role="alert" style={{ margin: '20px 0' }}>
                            <p>
                                Par véhicule, vous déclarez qu’au moins une des conditions ci-dessous est respectée :
                                <br />
                                - Le véhicule est vendu et le souscripteur dispose d’un certificat de vente <br />
                                - Le véhicule est volé et le souscripteur dispose d’une plainte pour le vol du véhicule
                            </p>
                        </div>
                        <button className="btn btn-primary d-block m-auto" type="submit" disabled={loading}>
                            {loading && <Spinner absolute />}
                            Sortir les véhicules
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default DeleteVehiculesFlotte;
