import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {FEEDBACK, GET_FEEDBACK, setFeedback} from "../../../actions/app/feedback/feedback.actions";

export const feedbackMiddleware = () => next => action => {
    next(action)

    const {payload} = action

    switch (action.type) {
        case GET_FEEDBACK:
            next([
                apiRequest({method: 'GET', url: payload.data, entity: FEEDBACK}),
                setLoader({state: true, entity: FEEDBACK})
            ])
            break

        case `${FEEDBACK} ${API_SUCCESS}`:
            next([
                setFeedback({data: payload.data}),
                setError({state: false, entity: FEEDBACK}),
                setLoader({state: false, entity: FEEDBACK})
            ])
            break

        case `${FEEDBACK} ${API_ERROR}`:
            next([
                setError({state: true, entity: FEEDBACK}),
                setLoader({state: false, entity: FEEDBACK})
            ])
            break

        default:
            break
    }
    return null
}
