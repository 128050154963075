import {createStore, applyMiddleware, compose} from 'redux'
import logger from 'redux-logger'
import {AppReducers} from '../reducers'
import {coreMiddleware} from '../middlewares/core'
import {appMiddleware} from "../middlewares/app"

const middlewares = [...appMiddleware, ...coreMiddleware, logger]

const store = createStore(
    AppReducers,
    compose(
        applyMiddleware(...middlewares)
    )
)

if (window.Cypress) {
    window.__store__ = store
}

export default store
