import {connect} from 'react-redux'
import {compose} from "redux";
import AddOppositions from "./AddOppositions";
import {getErrorEntity, getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {
    accidentGetLink, updateOppositionsAccidentGetLink,
} from "../../../../../redux/selectors/links/links.selectors";
import {reduxForm} from "redux-form";
import {
    ACCIDENT,
    getAccident, POST_ADD_OPPOSITIONS,postAddOppositions
} from "../../../../../redux/actions/app/accidents/accidents.actions";
import _ from "lodash";

import ErrorHttpComponent from "../../../../Commun/Error/ErrorHttpComponent";
import loader from "../../../../Commun/Spinner/Loader/Loader";
import {getAccidentData} from "../../../../../redux/selectors/accident/accident.selectors";

const mapStateToProps = (state, ownProps) => {
    const accident= getAccidentData(state);

    return {
        query: updateOppositionsAccidentGetLink(state),
        query_accident: accidentGetLink(state),
        contractId: ownProps.match.params.id,
        accidentId: ownProps.match.params.ids,
        loaded: getLoadingEntity(state, ACCIDENT) === false,
        error: getErrorEntity(state, ACCIDENT),
        loading: getLoadingEntity(state, POST_ADD_OPPOSITIONS),
        initialValues: {
            'montant' : accident.oppositions
        }
    }
}

const mapDispatchToProps = {
    getAccident, postAddOppositions
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getAccident, postAddOppositions} = dispatchProps
    const {query, query_accident, contractId, accidentId} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getAccident({
            query: _.replace(_.replace(query_accident, '{id}', contractId), '{ids}', accidentId),
            id: accidentId
        }),
        onSubmit: form => postAddOppositions({form, query: _.replace(_.replace(query, '{id}', contractId), '{ids}', accidentId)})
    }
}

const AddOppositionsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'oppositions'
    }),
    loader({ErrorIndicator: ErrorHttpComponent})
)(AddOppositions)

export default AddOppositionsContainer