import React, {useEffect, useState} from 'react';
import {Field} from "redux-form";
import AutoComplete from "../../../../Commun/Input/Autocomplete/Autocomplete";
import Spinner from "../../../../Commun/Spinner/Spinner";
import {getCompagnys} from "../../../../../utils/function";
import _ from "lodash";


const ChangeCompagnieAccident = (props) => {
    const {changeValue, handleSubmit, loading} = props
    const [compagnies, setCompagnies] = useState([])

    useEffect(() => {
        fetchCompagnies();
    }, [])

    async function fetchCompagnies() {
        let arrayCompagnies = await getCompagnys()
        let listCompagnies = _.orderBy(arrayCompagnies, ['name'], ['asc'])

        listCompagnies = listCompagnies.map(c => ({
            value: c.id,
            label: c.id + ' - ' + c.name + ' - ' + c.adresse + ' - ' + c.codePostal + ' ' + c.ville
        }))

        setCompagnies(listCompagnies)
        changeValue('compagnie', '')
    }

    return (
        <div className={'contract-container container'}>
            <div className={'row return-content'}>
                <div className={'col'}>
                    <button onClick={() => props.history.go(-1)} className={'btn btn-return'}>Retour</button>
                </div>
            </div>
            <div className={'row header-row'}>
                <div className={'col-12'}>
                    <form onSubmit={handleSubmit} className={'change-compagnie form-content'}>
                        <Field
                            name={'company'}
                            component={AutoComplete}
                            label="Chercher une compagnie"
                            data={compagnies}
                        />

                        {
                            loading ? <Spinner style={{display: 'block', margin: 'auto'}}/> :
                                <button className={'btn btn-primary d-block'} type={'submit'}>
                                    Mettre à jour
                                </button>
                        }
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ChangeCompagnieAccident;
