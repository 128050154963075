import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import Circonstances from "./Circonstances";
import {change, getFormValues, reduxForm} from "redux-form";
import _ from "lodash";
import {
    postAttentePvAccident,
    postCessionCieAccident, postDecesCieAccident
} from "../../../../../redux/actions/app/accidents/accidents.actions";
import {
    AccidentDecesGetLink,
    AccidentVehicleTranferGetLink,
    updateAccidentAttentePVGetLink, validateDocumentGetLink
} from "../../../../../redux/selectors/links/links.selectors";
import {getContractData, getIsWarkamContract} from "../../../../../redux/selectors/contract/contract.selectors";
import {postValidateDocument} from "../../../../../redux/actions/app/validate_documents/validate_documents.actions";

const mapStateToProps = (state, ownProps) => {
    const contract = getContractData(state)

    return {
        isWakam: getIsWarkamContract(state),
        uri: updateAccidentAttentePVGetLink(state),
        uriDoc: validateDocumentGetLink(state),
        uriCession: AccidentVehicleTranferGetLink(state),
        uriDeces: AccidentDecesGetLink(state),
        accidentId: ownProps.accident.id,
        contractId: ownProps.accident.id_contrat,
        risqueNum: contract.adhesionPrincipale,
        tiersId: contract.souscripteur.id,
        initialValues: {
            "attentePV": ownProps.accident.attentePV === true,
            "decesConducteur": ownProps.accident?.assureDeces === true
        },
        formValues: getFormValues('accident-attente-pv')(state)
    }
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
    postAttentePvAccident,
    postCessionCieAccident,
    postDecesCieAccident,
    changeValue: (field, value) => change(ownProps.form, field, value),
    postValid: ({body, form, query}) => postValidateDocument({body, form, query, addDoc: true}),
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uri, uriCession, uriDeces, accidentId, contractId, risqueNum, tiersId, uriDoc} = stateProps
    const {postAttentePvAccident, postCessionCieAccident, postValid, postDecesCieAccident} = dispatchProps

    let body = {
        tiers: tiersId,
        risque: risqueNum,
    }

    if (accidentId) {
        body.sinistre = accidentId
    }

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form, addDoc) => {
            postAttentePvAccident({
                query: _.replace(_.replace(uri, '{id}', contractId), '{ids}', accidentId),
                form
            })
            if (addDoc){
                postValid({
                    body,
                    form: addDoc,
                    query: _.replace(uriDoc, '{id}', contractId),
                })
            }
        },
        onSubmitDeces: form => postDecesCieAccident({
            query: _.replace(_.replace(uriDeces, '{id}', contractId), '{ids}', accidentId),
            form
        }),
        onSubmitCession: form => postCessionCieAccident({
            query: _.replace(_.replace(uriCession, '{id}', contractId), '{ids}', accidentId),
            form
        })
    }
}


const CirconstancesContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'accident-attente-pv'
    })
)(Circonstances)


export default CirconstancesContainer
