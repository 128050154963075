import {useState, useEffect} from 'react';
import {Field} from "redux-form";
import {normalizeDate} from "../../../../../../../../utils/normalizeInputs";
import moment from 'moment'
import APILink from "../../../../../../../Commun/APILink/APILink";

const DocumentItem = (props) => {
    const {document, type, handleSubmit, changeValue, onSubmit} = props
    const [reception, setReception] = useState(document.recu || false)
    const [traitement, setTraitement] = useState(document.traite || false)
    const [autofocusReception, setautofocusReception] = useState(false)
    const [autofocusTraitement, setautofocusTraitement] = useState(false)
    const [changeDate, setChangeDate] = useState(false)

    useEffect(() => {
        if (changeDate) {
            changeValue(changeDate, moment().format('L'))
            setChangeDate(false)
        }
    }, [changeDate, changeValue]);

    const onSubmitForm = ({values, notReceptionDate, notTraitementDate, type}) => {
        values.codeatt = document.code

        if (notReceptionDate) {
            changeValue("dateRecu", "")
            values.dateRecu = ""
        }
        if (notTraitementDate) {
            changeValue("dateTraitement", "")
            values.dateTraitement = ""
        }

        if (type && (values.dateRecu === "" || values.dateTraitement === "")) {
            setChangeDate(type)
            values[type] = moment().format('L')
        }

        onSubmit(values)
    }

    return (
        <tr>
            <td>{document.libelle}</td>
            <td className={'td-commentaires'}>
                <Field
                    name={"commentaire"}
                    component={"textarea"}
                    className={"documents-input"}
                    onBlur={handleSubmit(values => onSubmitForm({values}))}
                />
                <span className={'tooltips'}>Editer le commentaire</span>
            </td>
            {
                reception ?
                    <td
                        className={"set-waiting text-center"}
                        onClick={handleSubmit(values => {
                            setReception(false)
                            onSubmitForm({values, notReceptionDate: true})
                        })}
                    >
                        <Field
                            name={"dateRecu"}
                            component={"input"}
                            normalize={normalizeDate}
                            autoFocus={autofocusReception}
                            className={'input-date-reception'}
                            onBlur={handleSubmit(values => onSubmitForm({values, type: 'dateRecu'}))}
                        />
                        <span className={'tooltips'}>
                            Retirer la date
                        </span>
                    </td>
                    :
                    <td className={"set-date text-center"}
                        onClick={() => {
                            setReception(true)
                            setautofocusReception(true)
                        }}
                    >
                        <strong>En attente</strong>
                        <span className={'tooltips'}>Ajouter une date</span>
                    </td>

            }
            {
                traitement ?
                    <td
                        className={"set-waiting"}
                        onClick={handleSubmit(values => {
                            setTraitement(false)
                            onSubmitForm({values, notTraitementDate: true})
                        })}
                    >
                        <span className={'active'}>&nbsp;</span>
                        <Field
                            name={"dateTraitement"}
                            component={"input"}
                            normalize={normalizeDate}
                            autoFocus={autofocusTraitement}
                            className={'input-date-traitement'}
                            onBlur={handleSubmit(values => onSubmitForm({values, type: 'dateTraitement'}))}
                        />
                        <span className={'tooltips'}>
                            Retirer la date
                        </span>
                    </td>
                    :
                    <td className={"set-date"}
                        onClick={() => {
                            setTraitement(true)
                            setautofocusTraitement(true)
                        }}
                    >
                        <span className={`inactive ${(document.doc_temporaire && document.nb_piece === '1') ? 'orange' : ''}`}>&nbsp;</span>
                        <em>En attente</em>
                        <span className={'tooltips'}>
                            Ajouter une date
                        </span>
                    </td>
            }
            <td>{document.download_link && <APILink rel="noopener noreferrer" href={document.download_link} className={'btn btn-more'}>&nbsp;</APILink>}</td>
        </tr>
    );
};

export default DocumentItem;
