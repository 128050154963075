import {CONTRACTS, GET_CONTRACTS, setContracts} from '../../../actions/app/contracts/contracts.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'

export const contractsMiddleware = () => next => action => {
    next(action)

    switch (action.type) {
        case GET_CONTRACTS:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: CONTRACTS}),
                setLoader({state: true, entity: CONTRACTS})
            ])
            break

        case `${CONTRACTS} ${API_SUCCESS}`:
            const {items, total} = action.payload.data

            next([
                setContracts({items, total}),
                setError({state: false, entity: CONTRACTS}),
                setLoader({state: false, entity: CONTRACTS})
            ])
            break

        case `${CONTRACTS} ${API_ERROR}`:
            next([
                setError({state: true, entity: CONTRACTS}),
                setLoader({state: false, entity: CONTRACTS})
            ])
            break

        default:
            break;
    }
    return null
}
