import React, {useEffect} from 'react';
import {Field} from "redux-form";
import TextFieldRedux from "../../../../../../../Commun/Input/Text/TextFieldRedux";
import DatePickerFieldRedux from "../../../../../../../Commun/Input/DatePicker/DatePickerFieldRedux";

const Client = (props) => {
    useEffect(() => {
        document.getElementById('declaration-circ').scrollIntoView({behavior: 'smooth'})
    }, [])

    const {marque, modele, immat, souscripteur, isWakam, type_sinistre} = props

    return (
        <div className="col" style={{marginBottom: 30}}>
            <div className={`form-content h-100`}>
                <h1 className={'title-primary'}>Informations de l'assuré</h1>
                <Field
                    name={`assure_marque`}
                    component={TextFieldRedux}
                    label='Marque'
                    input={{
                        value: marque,
                        disabled: 'disabled'
                    }}
                />

                <Field
                    name={`assure_modele`}
                    component={TextFieldRedux}
                    label='Modèle'
                    input={{
                        value: modele,
                        disabled: 'disabled'
                    }}
                />

                <Field
                    name={`assure_immat`}
                    component={TextFieldRedux}
                    label='Immatriculation'
                    input={{
                        value: immat,
                        disabled: 'disabled'
                    }}
                />

                <Field
                    name={`assure_souscripteur`}
                    component={TextFieldRedux}
                    label='Nom et prénom du souscripteur'
                    input={{
                        value: souscripteur,
                        disabled: 'disabled'
                    }}
                />

                {
                    isWakam && type_sinistre !== "vol" && <Field
                        name={"birthdate_conduct"}
                        component={DatePickerFieldRedux}
                        label={'Date de naissance du conducteur'}
                    />
                }

                {
                    (!isWakam || (isWakam && type_sinistre === "accident")) && <Field
                        name={`conduct`}
                        component={TextFieldRedux}
                        normalize={v => v.toUpperCase()}
                        label='Nom et prénom du conducteur'
                    />
                }
            </div>
        </div>
    );
};

export default Client;
