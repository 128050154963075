import React, {Fragment} from 'react'
import _ from "lodash";
import CallToAction from "../../../../Commun/Button/CallToAction/CallToAction";

const Penalites = (props) => {
    const {penalites, permissions, contract, accident, submitDeletePenalties} = props
    return (
        <div className={'bloc-info bloc-penalites'}>
            <h2>Pénalités</h2>

            {_.includes(permissions, 'update accident informations') &&
                <Fragment>
                    {
                        penalites &&
                        <CallToAction
                            onClick={() => {
                                if (window.confirm(`Etes-vous sûr de vouloir supprimer la pénalité`)) submitDeletePenalties()
                            }}
                            action={'delete-penalties'}
                            toolText={'Supprimer la pénalité'}
                        />
                    }
                    <CallToAction
                        url={{
                            pathname: `/contrat/${contract}/sinistre/${accident}/modification-penalites`,
                        }}
                        action={'edit-repairer'}
                        toolText={ `${penalites ? 'Modifier la' : 'Ajouter une'} pénalité`}
                    />
                </Fragment>
            }
            <div className={'content row'}>
                <div className={'col-12'}>
                    <p>{penalites ? penalites : 'Aucune pénalité de renseignée pour ce sinistre'} </p>
                </div>
            </div>
        </div>
    )
}

export default Penalites
