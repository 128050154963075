import {GET_USER, setUser, USER} from '../../../actions/app/user/user.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setIsLogin, setLoader} from '../../../actions/app/ui/ui.actions'

export const userMiddleware = () => next => action => {
    next(action)

    switch (action.type) {

        case GET_USER:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: USER}),
                setLoader({state: true, entity: USER})
            ])
            break

        case `${USER} ${API_SUCCESS}`:
            next([
                setIsLogin({state: true, entity: USER}),
                setUser({user: action.payload.data}),
                setError({state: false, entity: USER}),
                setLoader({state: false, entity: USER})
            ])
            break

        case `${USER} ${API_ERROR}`:
            next([
                setIsLogin({state: false, entity: USER}),
                setError({state: true, entity: USER}),
                setLoader({state: false, entity: USER})
            ])
            break

        default:
            break
    }
    return null
}
