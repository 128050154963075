import React from 'react'
import moment from "moment";

const TacheAncb = (props) => {
    const {task: {date, type, tiers_id}} = props

    return (
        <div className={'container'}>
            <div className={'row'}>
                <div className={'col text-center'}>
                    <h2 className={'title-primary text-uppercase'}>Document - Tiers n°{tiers_id}</h2>
                    <p className={'subtitle'}>Reçu le {moment(date).format('L')}. par {type}</p>
                </div>
            </div>
            <div className={'header-row row'}>
                <div className={'col-12'}>
                    <p className={'form-content text-center'}>
                        Le règlement CB s'est bien effectué.
                        <br/>
                        Passez le contrat "En cours" s'il s'agit de la dernière pièce bloquante.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default TacheAncb
