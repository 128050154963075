import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {getErrorEntity} from '../../../../../redux/selectors/ui/ui.selectors'
import Reparateur from './Reparateur'
import {deleteRepairerGetLink} from "../../../../../redux/selectors/links/links.selectors";
import _ from "lodash";
import {
    POST_DELETE_REPAIRER_ACCIDENT,
    postDeleteAccidentRepairer
} from "../../../../../redux/actions/app/accidents/accidents.actions";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    return {
        accident: ownProps.accident,
        contract: ownProps.contract,
        uri: deleteRepairerGetLink(state),
        error: getErrorEntity(state, POST_DELETE_REPAIRER_ACCIDENT),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    postDeleteAccidentRepairer: ({query}) => postDeleteAccidentRepairer({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {postDeleteAccidentRepairer} = dispatchProps
    const {uri, accident, contract} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        postDelete: () => postDeleteAccidentRepairer({query: _.replace(_.replace(uri, '{id}', contract), '{ids}', accident)})
    }
}

const ReparateurContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Reparateur)

export default ReparateurContainer
