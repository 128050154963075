import React, {useRef, useState} from 'react';
import Loupe from "../../../../../../assets/img/icons/loupe_dark.png";
import {formatImmatriculation} from "../../../../../../utils/function";
import FlotteAccidentsListContainer from "./FlotteAccidentsList/FlotteAccidentsListContainer";

const FlotteAccidents = (props) => {
    const {getAccidentsFlotte, contract, uri, meta, links} = props
    const textInput = useRef(null);
    const [filters, setFilters] = useState([])

    const handleChangePage = (query) => {
        getAccidentsFlotte({
            query: query,
            immat: filters.immat,
        })
    }

    const handleChangeFilter = (value, field) => {
        let newValue = formatImmatriculation(value.replaceAll(' ', ''))
        const newFilters = filters;
        newFilters[field] = newValue;
        setFilters(newFilters);

        getAccidentsFlotte({
            query: uri,
            immat: newFilters.immat,
        })
    }

    return (
        <div>
            <div className={'bloc-info bloc-vehicules'}>
                <div className={'row'}>
                    <h2>Liste des sinistres</h2>
                    <div className={'col-12 filters'}>
                        <div className={'content d-flex'}>
                            <div className="input-group w-auto">
                                <input type="text"
                                       className="form-control small-input align-top mx-0"
                                       placeholder="Immatriculation"
                                       style={{height: 32}}
                                       ref={textInput}
                                       onKeyDown={(e) => e.key === 'Enter' && handleChangeFilter(e.target.value.toUpperCase(), 'immat')}
                                       onChange={(e) => e.target.value.length === 0 && handleChangeFilter('', 'immat')}
                                />
                                <div style={{height: 32}} className="input-group-append pointer"
                                     onClick={() => handleChangeFilter(textInput.current.value.toUpperCase(), 'immat')}>
                                    <span className="input-group-text"><img alt='icon' src={Loupe} className={'pt-1 align-top'} style={{width: 20}}/></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col'}>
                        <FlotteAccidentsListContainer className='loader' contract={contract}/>
                    </div>
                </div>
                {meta &&
                    <div className={'row'}>
                        <nav>
                            <p>{meta.to}/{meta.total} accidents</p>
                            <ul className="pagination">
                                {meta.current_page > 2 &&
                                    <li className="page-item" onClick={() => handleChangePage(links.first)}><span
                                        className="page-link">{`<<`}</span></li>}
                                {meta.current_page > 1 &&
                                    <li className="page-item" onClick={() => handleChangePage(links.prev)}><span
                                        className="page-link"> {`<`} </span></li>}
                                <li className="page-item"><span
                                    className={`page-link current`}>{meta.current_page}</span>
                                </li>
                                {meta.last_page > meta.current_page &&
                                    <li className="page-item" onClick={() => handleChangePage(links.next)}><span
                                        className="page-link">{`>`}</span></li>}
                                {meta.last_page >= meta.current_page + 2 &&
                                    <li className="page-item" onClick={() => handleChangePage(links.last)}><span
                                        className="page-link">{`>>`}</span></li>}
                            </ul>
                        </nav>
                    </div>
                }
            </div>
        </div>
    );
};

export default FlotteAccidents;
