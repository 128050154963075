/**
 * Retourne true si le bouton submit doit être désactivé
 * @param loading
 * @param categorie
 * @param garanties
 * @returns {boolean}
 */

export const validateForm = ({categorie, garanties}) => {

    /* Validation Declaration 1 */

    /* Si le contrat n'est pas en cours à la date de survenance du sinistre */

    /* Si le contrat est en cours ET que la garantie vol n'est pas souscrite ET
        que la categorie est vol,
        tentative de vol,
        vol vehicule retrouver,
        vol de pièce et accessoires,
        evenement climatique
    */

    /*  */

    return false
}
