import {API_ERROR, API_SUCCESS, apiRequest} from "../../../actions/core/api/api.actions";
import {setLoader, setError} from "../../../actions/app/ui/ui.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {
    FETCH_PRODUITS,
    PRODUITS,
    PUT_PRODUITS,
    setProduits
} from "../../../actions/app/commande_produits/commande_produits";

export const commandeProduitsMiddleware = () => next => action => {
    next(action)

    const {payload} = action

    switch (action.type) {
        case FETCH_PRODUITS:
            next([
                apiRequest({body: null, method: 'GET', url: payload.data, entity: PRODUITS}),
                setLoader({state: true, entity: PRODUITS})
            ])
            break

        case `${PRODUITS} ${API_SUCCESS}`:

            next([
                setProduits({data: action.payload.data}),
                setError({state: false, entity: PRODUITS}),
                setLoader({state: false, entity: PRODUITS}),
            ])
            break

        case `${PRODUITS} ${API_ERROR}`:
            next([
                setError({state: true, entity: PRODUITS}),
                setLoader({state: false, entity: PRODUITS}),
            ])
            break

        case PUT_PRODUITS:
            let body = payload.body
            let cart = []


            for (let [key, value] of Object.entries(body.commande)) {
                cart.push({id: key, quantity: value})
            }

            body.cart = cart

            next([
                apiRequest({
                    body: body,
                    method: 'PUT',
                    url: payload.data,
                    entity: PUT_PRODUITS,
                    header: payload.header
                }),
                setLoader({state: true, entity: PUT_PRODUITS})
            ])
            break

        case `${PUT_PRODUITS} ${API_SUCCESS}`:
            next([
                setError({state: false, entity: PUT_PRODUITS}),
                setLoader({state: false, entity: PUT_PRODUITS}),
            ])
            break

        case `${PUT_PRODUITS} ${API_ERROR}`:

            next([
                setNotification({
                    entity: PUT_PRODUITS,
                    body: payload.data.response.data.message,
                    type: 'error',
                    title: 'Attention !',
                    timeout: "0"
                }),
                setError({state: true, entity: PUT_PRODUITS}),
                setLoader({state: false, entity: PUT_PRODUITS}),
            ])
            break

        default:
            break
    }
    return null
}
