import {
    GET_DOCUMENTATION,
    setDocumentation,
    DOCUMENTATION
} from '../../../actions/app/documentation/documentation.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {logAction} from "../../../actions/core/logs/logs.actions";

export const documentationMiddleware = () => next => action => {
    next(action)

    switch (action.type) {
        case GET_DOCUMENTATION:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: DOCUMENTATION}),
                setLoader({state: true, entity: DOCUMENTATION})
            ])
            break

        case `${DOCUMENTATION} ${API_SUCCESS}`:
            next([
                setDocumentation({documentation: action.payload.data}),
                setError({state: false, entity: DOCUMENTATION}),
                setLoader({state: false, entity: DOCUMENTATION}),
                logAction({action: 'SHOW_DOCUMENTS', entity: DOCUMENTATION})
            ])
            break

        case `${DOCUMENTATION} ${API_ERROR}`:
            next([
                setError({state: true, entity: DOCUMENTATION}),
                setLoader({state: false, entity: DOCUMENTATION})
            ])
            break

        default:
            break
    }
    return null
}
