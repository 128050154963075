export const DASHBOARD = '[Dashboard]'
export const DASHBOARD_TASK_DETAIL = '[Dashboard Task Detail]'
export const DASHBOARD_MORE_TASK_DETAIL = '[Dashboard More Task Detail]'
export const DASHBOARD_PANIERE_DETAIL = '[Dashboard Paniere Detail]'

export const GET_DASHBOARD = `${DASHBOARD} Get`
export const SET_DASHBOARD = `${DASHBOARD} Set`

export const GET_DASHBOARD_TASK_DETAIL = `${DASHBOARD_TASK_DETAIL} Get`
export const SET_DASHBOARD_TASK_DETAIL = `${DASHBOARD_TASK_DETAIL} Set`

export const GET_DASHBOARD_PANIERE_DETAIL = `${DASHBOARD_PANIERE_DETAIL} Get`
export const SET_DASHBOARD_PANIERE_DETAIL = `${DASHBOARD_PANIERE_DETAIL} Set`

export const GET_DASHBOARD_MORE_TASK_DETAIL = `${DASHBOARD_MORE_TASK_DETAIL} Get`
export const SET_DASHBOARD_MORE_TASK_DETAIL = `${DASHBOARD_MORE_TASK_DETAIL} Set`

export const getDashboard = ({
                                 query,
                                 sort = localStorage.getItem('sort_dashboard') || '',
                                 service = localStorage.getItem('service') || '',
                                 service_user = localStorage.getItem('service_user') || ''
                             }) => ({
    type: GET_DASHBOARD,
    payload: {
        data: query,
        filters: {
            sort,
            service,
            service_user
        }
    }
})

export const setDashboard = ({dashboard}) => ({
    type: SET_DASHBOARD,
    payload: {
        data: dashboard.data
    }
})

export const getManagerTaskDetail = ({query, pro_id}) => ({
    type: GET_DASHBOARD_TASK_DETAIL,
    payload: {
        data: query,
        otherData: {
            pro_id: pro_id
        }
    }
})

export const setManagerTaskDetail = ({dashboard, pro_id}) => ({
    type: SET_DASHBOARD_TASK_DETAIL,
    payload: {
        data: dashboard.data,
        pro_id: pro_id
    }
})

export const getManagerPaniereDetail = ({query, emetteur}) => ({
    type: GET_DASHBOARD_PANIERE_DETAIL,
    payload: {
        data: query,
        otherData: {
            emetteur: emetteur
        }
    }
})

export const setManagerPaniereDetail = ({dashboard, emetteur}) => ({
    type: SET_DASHBOARD_PANIERE_DETAIL,
    payload: {
        data: dashboard.data,
        emetteur: emetteur
    }
})

export const getManagerMoreTaskDetail = ({query}) => ({
    type: GET_DASHBOARD_MORE_TASK_DETAIL,
    payload: {
        data: query
    }
})

export const setManagerMoreTaskDetail = ({dashboard}) => ({
    type: SET_DASHBOARD_MORE_TASK_DETAIL,
    payload: {
        data: dashboard.data
    }
})
