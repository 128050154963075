/*
Usage :

<Field
  name={String}
  component={TextFieldRedux}
  label={String}
  InputProps={{
    placeholder: {String}
  }}
/>
*/

const TextFieldRedux = ({input, meta: { touched, error }, label, subLabel, type, typeFormat, InputLabelProps, classNameGroup, ...custom}) => {
    const formatDate = (e) => {
        const inputValue = e.target.value;
        const inputLength = inputValue.length;
        if (inputLength === 2 || inputLength === 5) {
            if (e.key !== 'Backspace') {
                e.target.value += '/';
            }
        }
    };

    return (
        <div className={'form-group ' + classNameGroup}>
            {label && <label {...InputLabelProps}>{label}</label>}
            {subLabel && <small className={'float-right'}>{subLabel}</small>}
            <input
                className={'form-control ' + (touched ? (Boolean(error) ? 'is-invalid' : 'is-valid') : '')}
                type={type}
                {...input}
                {...custom}
                onKeyDown={(typeFormat === 'date') ? formatDate : null}
            />
            {touched && error && <small className="invalid-feedback">{error}</small>}
        </div>
    );
};

export default TextFieldRedux;
