import React from 'react';
import {Field} from "redux-form";
import TextFieldRedux from "../../../../Commun/Input/Text/TextFieldRedux";
import Spinner from "../../../../Commun/Spinner/Spinner";

const AddOppositions = (props) => {
    const {history, contractId, accidentId, handleSubmit, loading} = props

    return (
        <div className={'contract-container container'}>
            <div className={'row return-content'}>
                <div className={'col'}>
                    <button onClick={() => history.go(-1)} className={'btn btn-return'}>Retour</button>
                </div>
            </div>
            <div className={'row header-row'}>
                <div className={'col-12'}>
                    <form className={'form-content'} onSubmit={handleSubmit}>
                        <Field
                            name='montant'
                            component={TextFieldRedux}
                            label={'Montant de l\'opposition à ajouter sur le dossier'}
                            type={'number'}
                           /* onChange={(e) => e.replace(/\D/g,'')}*/
                        />
                        {
                            loading ? <Spinner style={{display: 'block', margin: 'auto'}}/> :
                                <button className={'btn btn-primary d-block'} type={'submit'}>
                                    Ajouter
                                </button>
                        }
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddOppositions;