import {SET_NOTES} from '../../actions/app/notes/notes.actions'

export const notesReducer = (notes = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_NOTES) {
        return payload
    } else {
        return notes
    }
}
