

export const CORRESPONDANT = '[Correspondant]'
export const CORRESPONDANTS = '[Correspondants]'

export const GET_CORRESPONDANTS = `${CORRESPONDANTS} Get`
export const SET_CORRESPONDANTS = `${CORRESPONDANTS} Set`
export const GET_CORRESPONDANT = `${CORRESPONDANT} Get`
export const SET_CORRESPONDANT = `${CORRESPONDANT} Set`
export const POST_CORRESPONDANT = `${CORRESPONDANT} Post`
export const POST_UPDATE_CORRESPONDANT = `${CORRESPONDANT} Update`
export const POST_DELETE_CORRESPONDANT = `${CORRESPONDANT} Delete`

export const getCorrespondant = ({query}) => ({
    type: GET_CORRESPONDANT,
    payload: {
        data: query
    }
})

export const setCorrespondant = ({correspondant}) => ({
    type: SET_CORRESPONDANT,
    payload: {
        data: correspondant
    }
})

export const getCorrespondants = ({query}) => ({
    type: GET_CORRESPONDANTS,
    payload: {
        data: query
    }
})

export const setCorrespondants = ({correspondants}) => ({
    type: SET_CORRESPONDANTS,
    payload: {
        data: correspondants
    }
})

export const postCorrespondant = ({query, form, otherData}) => ({
    type: POST_CORRESPONDANT,
    payload: {
        data: query,
        body: (form),
        otherData
    }
})

export const postUpdateCorrespondant = ({query, form}) => ({
    type: POST_UPDATE_CORRESPONDANT,
    payload: {
        data: query,
        body: (form)
    }
})

export const postDeleteCorrespondant = ({query}) => ({
    type: POST_DELETE_CORRESPONDANT,
    payload: {
        data: query
    }
})
