import React, {useState} from 'react';
import PropTypes from 'prop-types'

const Collapse = (props) => {
    const {title, id, children, onClickOpen} = props
    const [collapse, setCollapse] = useState(false)

    const ClickCollapse = () => {
        if (!collapse) {
            onClickOpen();
        }

        setCollapse(!collapse);
    }

    return (
        <div className={'card-collapse'}>
            <div className={'card-header'} id={`heading${id}`}>
                <h2>
                    <button className={`${collapse ? 'collapsed' : ''}`}
                            type="button"
                            data-toggle="collapse"
                            data-target={`#collapse${id}`}
                            aria-controls={`collapse${id}`}
                            onClick={ClickCollapse}
                            dangerouslySetInnerHTML={{__html: title}}
                    />
                </h2>
            </div>
            <div id={`collapse${id}`} className={`card-body collapse ${collapse && 'show'}`}
                 aria-labelledby={`heading${id}`}>
                {children}
            </div>
        </div>
    );
};

Collapse.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    children: PropTypes.node,
    id: PropTypes.string.isRequired
};

Collapse.defaultProps = {
    onClickOpen: () => {
    }
};

export default Collapse;
