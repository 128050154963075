import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import BeneficiaireEffectifItem from "./BeneficiaireEffectifItem";
import {getTiersBelairId} from "../../../../../../../redux/selectors/tiers/tiers.selectors";

const mapStateToProps = (state, ownProps) => {
    return {
        id: ownProps.beneficiaire.id,
        idTiers: getTiersBelairId(state),
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
    }
}


const BeneficiaireEffectifItemContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(BeneficiaireEffectifItem)


export default BeneficiaireEffectifItemContainer
