import { Field } from 'redux-form';
import { normalizeAndUppercase, normalizeOnlyNumber } from '../../../../../utils/normalizeInputs';
import SelectFieldRedux from '../../../../Commun/Input/Select/SelectFieldRedux';
import TextFieldRedux from '../../../../Commun/Input/Text/TextFieldRedux';
import Spinner from '../../../../Commun/Spinner/Spinner';
import AddressFields from './AddressFields';
import IbanField from './IbanField';
import {useEffect} from "react";

const AddTiers = (props) => {
    const { handleSubmit, loading, edit, changeValue, formValues, accidentId, type } = props;

    useEffect(() => {
        if(type === 'temoin'){
            changeValue('type', 'W')
        } else if(accidentId && type !== 'temoin') {
            changeValue('type', '1')
        }
    }, [type]);

    return (
        <div className={'contract-container container'}>
            <div className={'row return-content'}>
                <div className={'col'}>
                    <button onClick={() => props.history.go(-1)} className={'btn btn-return'}>
                        Retour
                    </button>
                </div>
            </div>
            <div className={'row mb-4'}>
                <div className={'col-12'}>
                    <h1 className={'title-primary'}>Création d'un tiers</h1>
                </div>
            </div>
            <form onSubmit={handleSubmit} className={'form-content'}>
                {!accidentId && (
                    <Field name="type" component={SelectFieldRedux} label={'Type'} required>
                        <option value={''} disabled>
                            Choisir un type
                        </option>
                        <option value={'V'}>Bénéficiaire</option>
                        <option value={'9'}>Financement</option>
                        <option value={'P'}>Prospect</option>
                        <option value={'W'}>Témoin</option>
                    </Field>
                )}
                <div className="row">
                    {(type === 'temoin' && edit) ? (
                        <div className="col">
                            <Field
                                name="raisonSociale"
                                component={TextFieldRedux}
                                label={'Raison Sociale'}
                                normalize={normalizeAndUppercase}
                            />
                        </div>
                    ) : (
                        <>
                            <div className="col">
                                <Field
                                    name="nom"
                                    component={TextFieldRedux}
                                    label={'Nom'}
                                    normalize={normalizeAndUppercase}
                                    required
                                />
                            </div>
                            <div className="col">
                                <Field
                                    name="prenom"
                                    component={TextFieldRedux}
                                    label={'Prénom'}
                                    normalize={normalizeAndUppercase}
                                />
                            </div>
                        </>
                    )}
                </div>

                <div className="row">
                    <div className="col">
                        <Field
                            name="tel"
                            component={TextFieldRedux}
                            label={'Téléphone (ex: 0601020304)'}
                            onChange={(e) => changeValue('tel', normalizeOnlyNumber(e.target.value))}
                        />
                    </div>
                    <div className="col">
                        <Field name="email" component={TextFieldRedux} label={'Email'}/>
                    </div>
                </div>

                <hr />

                <AddressFields formValues={formValues} changeValue={changeValue} />

                <hr />

                {accidentId ? (
                    <div className="row">
                        <div className="col">
                            <Field
                                name="immatriculation"
                                component={TextFieldRedux}
                                label={'Immatriculation :'}
                                normalize={(v) => v.toUpperCase()}
                            />
                        </div>
                        <div className="col">
                            <Field name="police" component={TextFieldRedux} label={'N° de police :'} />
                        </div>
                    </div>
                ) : (
                    <IbanField formValues={formValues} changeValue={changeValue} />
                )}

                {loading ? (
                    <Spinner style={{ display: 'block', margin: 'auto' }} />
                ) : (
                    <button className={'btn btn-primary d-block'} type={'submit'}>
                        {edit ? 'Editer le tiers' : 'Ajouter le tiers'}
                    </button>
                )}
            </form>
        </div>
    );
};

export default AddTiers;
