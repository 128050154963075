import {connect} from 'react-redux'
import {compose} from 'redux'
import ChangeImmat from "./ChangeImmat";
import {reduxForm} from "redux-form";
import {getContractData} from "../../../../../redux/selectors/contract/contract.selectors";
import {informationUpdateContractLink,} from "../../../../../redux/selectors/links/links.selectors";
import {
    POST_AVENANT_SIMPLE,
    postAvenantSimple
} from "../../../../../redux/actions/app/avenant_simple/avenant_simple.actions";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import _ from "lodash";
import moment from "moment/moment";

const mapStateToProps = state => {
    const contract = getContractData(state);

    return {
        query: informationUpdateContractLink(state),
        contract: contract,
        loading: getLoadingEntity(state, POST_AVENANT_SIMPLE),
        initialValues: {
            'souscripteur_nom': contract.souscripteur.lastname,
            'souscripteur_prenom': contract.souscripteur.firstname,
            'conducteur_nom': contract.conducteur.nom,
            'conducteur_prenom': contract.conducteur.prenom,
            'adresse1': contract.souscripteur.adresse.rue1,
            'adresse2': contract.souscripteur.adresse.rue2,
            'adresse3': contract.souscripteur.adresse.rue3,
            'code_postal': contract.souscripteur.adresse.codePostal,
            'ville': contract.souscripteur.adresse.ville,
            'immatriculation': contract.vehicule.immatriculation,
            'apporteur1': contract.apporteurs.apporteur1.id,
            'marque_vehicule': contract.vehicule.marque,
            'modele_vehicule': contract.vehicule.modele,
            'version_vehicule': contract.vehicule.version,
            'vin_vehicule': contract.vehicule.VIN,
            'cylindree_vehicule': contract.vehicule.cylindree,
            'loiHamon': false,
            'dateEffet': moment(),
            'heureEffet': moment().format('HH:mm'),
            'souscripteur_civilite': contract.souscripteur.titre,
            'conducteur_civilite': contract.conducteur.titre,
        }
    }
}

const mapDispatchToProps = {
    postAvenantSimple,
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {query, contract} = stateProps
    const {postAvenantSimple} = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: form => postAvenantSimple({form, query: _.replace(query, '{id}', contract.id)})
    }
}

const ChangeImmatContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'avenant-simple-immat'
    })
)(ChangeImmat);

export default ChangeImmatContainer
