export const ADD_ACCESSOIRES = '[Add Accessoires]'
export const ACCESSOIRES = '[Accessoires]'
export const ACCESSOIRE_DETAIL = '[Accessoire detail]'
export const DELETE_ACCESSOIRE = '[Delete Accessoire]'
export const POST_ADD_ACCESSOIRES = `${ADD_ACCESSOIRES} Post`
export const POST_DELETE_ACCESSOIRE = `${DELETE_ACCESSOIRE} Post`

export const GET_ACCESSOIRES = `${ACCESSOIRES} Get`
export const SET_ACCESSOIRES = `${ACCESSOIRES} Set`
export const GET_ACCESSOIRE_DETAIL = `${ACCESSOIRE_DETAIL} Get`
export const SET_ACCESSOIRE_DETAIL = `${ACCESSOIRE_DETAIL} Set`

export const getAccessoires = ({query}) => ({
    type: GET_ACCESSOIRES,
    payload: {
        data: query
    }
})

export const setAccessoires = ({accessoires}) => ({
    type: SET_ACCESSOIRES,
    payload: {
        data: accessoires
    }
})

export const getAccessoireDetail = ({query}) => ({
    type: GET_ACCESSOIRE_DETAIL,
    payload: {
        data: query
    }
})

export const setAccessoireDetail = ({accessoire}) => ({
    type: SET_ACCESSOIRE_DETAIL,
    payload: {
        data: accessoire
    }
})

export const postAddAccessoires = ({query, form, accident, terminateQuery}) => ({
    type: POST_ADD_ACCESSOIRES,
    payload: {
        data: query,
        body: {accident, ...form},
        otherData: {
            terminate: terminateQuery
        }
    }
})

export const postDeleteAccessoire = ({id, query}) => ({
    type: POST_DELETE_ACCESSOIRE,
    payload: {
        data: query,
        body: {id}
    }
})
