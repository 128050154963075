export const emailsAutoSinistre = [
    {
        type: 'Client',
        title: 'Sinistre non couvert',
        value: "sinistre_non_couvert",
        label: "Sinistre non couvert",
        text: "Nous avons reçu une déclaration pour un sinistre survenu le {{$accident->dateSurv}} concernant votre véhicule immatriculé {{$accident->immatVehicule}}.<br/><br/>Nous vous informons que nous ne pourrons pas intervenir pour ce sinistre car vos garanties n'étaient pas acquises ce jour-là.<br/><br/><mark>cause_resiliation</mark>",
        champs: [
            {
                'type': 'select',
                'label': "Cause résiliation",
                'values': [
                    "En effet, votre contrat est résilié depuis le {{$contract->situation_dateFin}}",
                    "En effet, votre contrat est résilié pour non paiement depuis le {{$contract->situation_dateFin}} et de ce fait déjà suspendu 10 jours avant cette date.",
                    "En effet, vous avez souscrit votre contrat auprès de nos services après cette date."
                ],
                'slug': "<mark>cause_resiliation</mark>",
            },
        ],
    },
    {
        type: 'Client',
        title: 'Rapport de carence',
        value: "rapport_carence_suite_accident_A2",
        label: "Rapport de carence suite accident + 2ans",
        text: "Nous vous informons que l'expert vient de nous transmettre un rapport de carence (sans chiffrage) n'ayant pas vu votre véhicule pour effectuer l'expertise.<br/><br/>Nous venons de clôturer votre dossier. <br/><br/> Votre sinistre survenu en date du {{$accident->dateSurv}} ayant plus de 2 ans à ce jour, la clôture est définitive, vous ne pouvez plus prétendre à une quelconque indemnisation."
    },
    {
        type: 'Client',
        title: 'Clôture du dossier + 2 ans',
        value: "cloture_dossier_2ans_client",
        label: "Clôture du dossier + 2 ans",
        text: "Nous vous informons que nous venons de procéder à la clôture définitive de votre dossier concernant le sinistre survenu en date du {{$accident->dateSurv}}.<br/><br/>Celui-ci ayant plus de 2 ans, vous ne pouvez plus prétendre à une quelconque indemnisation."
    },
    {
        type: 'Client',
        title: 'Clôture de votre dossier sinistre sans règlement',
        value: "cloture_dossier_sans_reglement_client",
        label: "Clôture du dossier sans règlement",
        text: "Nous vous informons que nous venons de procéder à la clôture définitive de votre dossier concernant la partie matérielle de votre sinistre survenu en date du {{$accident->dateSurv}}.<br/><br/>Il ne sera pas fait de règlement sur ce dossier pour le motif suivant :<br/><br/><mark>texte_libre</mark>",
        champs: [
            {
                'type': 'text',
                'label': "Texte libre",
                'slug': "<mark>texte_libre</mark>"
            },
        ],
    },
    {
        type: 'Client',
        title: 'Témoignage pour un accident',
        value: "temoignage_accident_client_sans_tier",
        label: "Témoignage d'un accident sans Tiers",
        text: "Vous avez été témoin d’un accident de la circulation survenu en date du {{$accident->dateSurv}}, avec le véhicule de notre assuré {{$accident->marqueVehicule}} {{$accident->immatVehicule}} <br/><br/>Vous trouverez en pièce jointe, une attestation de témoignage à compléter et à nous retourner directement via ce lien : <br/><br/><p class='text-center'><a className='btn' href='{{$accident->upload_documents_link}}'>J'envoie les documents</a></p><br/><br/>Nous vous rappelons que pour être recevable cette attestation doit être signée, écrite et datée de votre main et accompagnée de la photocopie de votre pièce d’identité."
    },
    {
        type: 'Client',
        title: 'Témoignage pour un accident',
        value: "temoignage_accident_client_avec_tier",
        label: "Témoignage d'un accident avec Tiers",
        text: "Vous avez été témoin d’un accident de la circulation survenu en date du {{$accident->dateSurv}}, entre le véhicule de notre assuré {{$accident->marqueVehicule}} {{$accident->immatVehicule}} et le véhicule immatriculé {{$accident->adverse_immat}} <br/><br/>Vous trouverez en pièce jointe, une attestation de témoignage à compléter et à nous retourner directement via ce lien : <br/><br/><p class='text-center'><a className='btn' href='{{$accident->upload_documents_link}}'>J'envoie les documents</a></p><br/><br/>Nous vous rappelons que pour être recevable cette attestation doit être signée, écrite et datée de votre main et accompagnée de la photocopie de votre pièce d’identité."
    },
    {
        type: 'Témoin',
        title: 'Témoignage pour un accident',
        value: "temoignage_accident_client_sans_tier",
        label: "Témoignage d'un accident sans Tiers",
        text: "Vous avez été témoin d’un accident de la circulation survenu en date du {{$accident->dateSurv}}, avec le véhicule de notre assuré {{$accident->marqueVehicule}} {{$accident->immatVehicule}} <br/><br/>Vous trouverez en pièce jointe, une attestation de témoignage à compléter et à nous retourner directement via ce lien : <br/><br/><p class='text-center'><a className='btn' href='{{$accident->upload_documents_link}}'>J'envoie les documents</a></p><br/><br/>Nous vous rappelons que pour être recevable cette attestation doit être signée, écrite et datée de votre main et accompagnée de la photocopie de votre pièce d’identité."
    },
    {
        type: 'Témoin',
        title: 'Témoignage pour un accident',
        value: "temoignage_accident_client_avec_tier",
        label: "Témoignage d'un accident avec Tiers",
        text: "Vous avez été témoin d’un accident de la circulation survenu en date du {{$accident->dateSurv}}, entre le véhicule de notre assuré {{$accident->marqueVehicule}} {{$accident->immatVehicule}} et le véhicule immatriculé {{$accident->adverse_immat}} <br/><br/>Vous trouverez en pièce jointe, une attestation de témoignage à compléter et à nous retourner directement via ce lien : <br/><br/><p class='text-center'><a className='btn' href='{{$accident->upload_documents_link}}'>J'envoie les documents</a></p><br/><br/>Nous vous rappelons que pour être recevable cette attestation doit être signée, écrite et datée de votre main et accompagnée de la photocopie de votre pièce d’identité."
    },
    {
        type: 'Client',
        title: 'Coordonnées Mondial Assistance',
        value: "accident_coordonnees_mondial_assistance_client",
        label: "Coordonnées Mondial Assistance",
        text: "Votre véhicule n’est plus en état de rouler et vous avez besoin de le faire rapatrier.<br/><br/>Vous pouvez contacter notre partenaire Mondial Assistance au 01.49.93.65.82<br/><br/>Des conseillers sont disponibles pour vous 24h/24 et 7 jours/7."
    },
    {
        type: 'Client',
        title: 'Accusé de réception',
        value: "accuse_reception_sinistre_ouvert_client",
        label: "Accusé réception sinistre ouvert",
        text: "Nous accusons réception de votre déclaration de sinistre et nous vous confirmons l'ouverture du dossier.<br/><br/>Nous vous invitons à prendre connaissance des courriers joints qui vous indiquent les éléments enregistrés sur votre dossier et la suite des démarches.<br/><mark>texte_libre</mark>",
        champs: [
            {
                'type': 'text',
                'label': "Texte libre",
                'slug': "<mark>texte_libre</mark>",
                'notRequired': true
            },
        ],
    },
    {
        type: 'Client',
        title: 'Accusé de réception pour votre dossier sinistre',
        value: "accuse_reception_sin_attente_client",
        label: "Accusé réception sinistre en attente",
        text: "Nous accusons réception de votre déclaration de sinistre, afin de pouvoir ouvrir votre dossier, nous avons besoin de recevoir <mark>document</mark>.<br/><br/>Votre dossier est donc en attente pour le moment, à réception du document démandé nous pourrons procéder à l'ouverture de celui-ci et poursuivre son avancement.<br/><mark>texte_libre</mark>",
        champs: [
            {
                'type': 'text',
                'label': "ex.: Circonstances précises de l'accident / constat amiable / dépôt de plainte",
                'slug': "<mark>document</mark>",
            },
            {
                'type': 'text',
                'label': "Texte libre",
                'slug': "<mark>texte_libre</mark>",
                'notRequired': true
            },
        ],
    },
    {
        type: 'Client',
        title: 'Documents en attente pour votre sinistre',
        value: "document_en_attente_client",
        label: "Documents en attente",
        text: "Afin de constituer votre dossier et poursuivre l'avancement nous avons besoin des éléments suivants&nbsp;:<br/><br/>{{$accident->documents_en_attente}}<br/><p class='text-center'><a class='btn' href='{{$accident->upload_documents_link}}'>J'envoie les documents</a></p>",
    },
    {
        type: 'Client',
        title: 'Mission d’expertise',
        value: "mission_expertise_client",
        label: "Mission d'expertise",
        text: "Suite à votre déclaration de sinistre du {{$accident->dateSurv}}, nous venons de missionner notre expert qui examinera votre véhicule le {{$accident->datePassageExpert}} chez le réparateur que vous nous avez communiqué {{$accident->reparateur}}.<br/><br/>Il convient de déposer votre véhicule au garage au minimum la veille du rendez-vous, ainsi que vos équipements et effets personnels si ceux-ci sont endommagés.<br/><br/>IMPORTANT&nbsp;: Veuillez retirer votre véhicule après le passage de l'expert, afin de limiter les frais de gardiennage qui seront à votre charge en cas de dépassement.",
    },
    {
        type: 'Client',
        title: 'Coordonnées réparateur pour votre sinistre',
        value: "coordonnees_reparateur_client",
        label: "Coordonnées réparateur client direct",
        text: "Afin que notre expert puisse examiner votre véhicule, nous avons besoin d'avoir les coordonnées du réparateur de votre choix (nous n'avons pas de garage agréé).<br/><br/>Comment faire ?<br/><ul><li>1- Connectez-vous à votre espace</li><li>2- Cliquez sur la rubrique “Sinistres” puis sur votre sinistre</li><li>3- Sur le bloc “Réparateur”, cliquez sur “Ajouter un réparateur”.</li><li>4- Remplissez le formulaire et choisissez la date de passage de l’expert”</li></ul><br/><br/>Votre véhicule devra être déposé la veille de son passage ou dès l'ouverture du garage.<br/><p class='text-center'><a class='btn' href='https://mon-espace.april-moto.com/'>Mon espace</a></p>",
    },
    {
        type: 'Client',
        title: 'Reprise dossier par la compagnie Allianz',
        value: "reprise_dossier_allianz_client",
        label: "Reprise dossier par la compagnie Allianz",
        text: "La compagnie ALLIANZ va intervenir dans la gestion <mark>type_gestion</mark> de votre dossier.<br/><br/>Voici leurs coordonnées (vous pourrez les contacter sous 15 jours pour toutes questions liées à <mark>type_gestion_contact</mark>)&nbsp;:<br/>La référence de votre dossier corporel&nbsp;: {{$accident->numCie}}<br/>Téléphone&nbsp;: 0 978 978 044<br/>Mail&nbsp;: corporel@allianz.fr",
        champs: [
            {
                'type': 'select',
                'label': "Typologie de gestion (corporel ou recours)",
                'values': [
                    "corporelle",
                    "du recours"
                ],
                'slug': "<mark>type_gestion</mark>",
            },
            {
                'type': 'select',
                'label': "Sélectionnez la valeur associée (si corporel choisir \"vos blessures\", si recours choisir \"recours\")&nbsp;:",
                'values': [
                    "vos blessures",
                    "ce recours"
                ],
                'slug': "<mark>type_gestion_contact</mark>",
            },

        ],
    },
    {
        type: 'Client',
        title: 'Information sur votre indemnisation matérielle',
        value: "montant_reparations_inf_franchise_client",
        label: "Montant réparations inférieur à la franchise",
        text: "Nous venons de réceptionner le rapport de l'expert et nous constatons que le montant des réparations d'un montant de <mark>montant_reparations</mark> euros est inférieur à votre franchise contractuelle de <mark>montant_franchise_contractuelle</mark> euros.<br/><br/>Les réparations restent donc à votre charge. Nous procédons ce jour, à la clôture de votre dossier.",
        champs: [
            {
                'type': 'text',
                'label': "Montant des réparations",
                'slug': "<mark>montant_reparations</mark>"
            },
            {
                'type': 'text',
                'label': "Montant franchise contractuelle",
                'slug': "<mark>montant_franchise_contractuelle</mark>"
            },
        ],
    },
    {
        type: 'Client',
        title: 'Sinistre non garanti',
        value: "sinistre_non_garanti_client",
        label: "Sinistre non garanti",
        text: "J'accuse réception de votre déclaration de sinistre et vous informe qu'au vu de vos garanties, nous ne pourrons malheureusement pas intervenir pour les dommages de votre véhicule.",
    },
    {
        type: 'Client',
        title: 'Rapport de carence suite au vol de votre véhicule',
        value: "rapport_carence_sin_vol_client",
        label: "Rapport de carence suite vol",
        text: "Nous vous informons que notre expert n'a jamais réceptionné l'entier dossier concernant le vol de votre véhicule et qu'il nous a délivré un rapport de carence.<br/><br/>Pour nous permettre de vous indemniser, nous vous remercions de prendre contact avec l'expert afin de lui adresser les documents demandés.",
    },
    {
        type: 'Client',
        title: 'Rapport de carence suite à votre accident',
        value: "rapport_carence_sin_client",
        label: "Rapport de carence suite accident",
        text: "Nous vous informons que l'expert vient de nous transmettre un rapport de carence (sans chiffrage) n'ayant pas vu votre véhicule pour effectuer l'expertise.<br/><br/>Nous clôturons votre dossier. Vous avez 24 mois à compter de la date de survenance de votre sinistre, soit à compter du {{$accident->dateSurv}}, pour prétendre à une indemnisation.<br/><br/>Si vous souhaitez que votre véhicule soit expertisé, nous vous invitons à prendre contact avec nos services afin de réouvrir votre dossier et missionner à nouveau l'expert.",
    },
    {
        type: 'Client',
        title: "Envoi de documents pour votre accident",
        value: "envoi_doc_sin_client",
        label: "Envoi de document",
        text: "Nous faisons suite à votre demande et avons le plaisir de vous transmettre en pièce(s) jointe(s) le(s) document(s) demandé(s).",
    },
    {
        type: 'Client',
        title: "Accusé de réception",
        value: "accuse_reception_prise_charge_client",
        label: "Accusé réception avec accord prise en charge",
        text: "Nous accusons réception de votre déclaration de sinistre et nous vous confirmons l'ouverture du dossier.<br/><br/>Nous vous invitons à prendre connaissance des courriers joints qui vous indiquent les éléments enregistrés sur votre dossier et la suite des démarches.<br/><br/>Dans la mesure où vos garanties le permettent et sous réserve que votre véhicule soit réparable, nous avons pris en compte votre souhait que l'on fasse l'avance des réparations auprès du garage.",
    },
    {
        type: 'Client',
        title: "Votre Protection Juridique Motard",
        value: "accident_coordonnees_allianz_pj_client",
        label: "Coordonnées Allianz PJ",
        text: "Afin de vous accompagner au quotidien, votre contrat APRIL Moto inclut un service de protection juridique couvert par notre partenaire Allianz Protection Juridique.<br><br>Pour déclarer un litige, suivre vos démarches ou poser une question, rendez vous sur<br><a href=\"https://mesdemarches.allianz.fr/am/\" style=\"text-decoration:underline;color:#00e\">https://mesdemarches.allianz.fr/am/</a><br><br>Et si vous ne trouvez pas la réponse à votre question, des conseillers spécialisés peuvent vous aider au 09.78.97.80.75<br><br><div style=\"text-transform:uppercase;color:#f7aa36!important;font-weight:600;text-align:center;margin-bottom:0\">Défendez vos droits</div><div style=\"text-align:center;font-weight:800\">Vous avez eu affaire à un garagiste peu scrupuleux qui a endommagé votre moto ?</div><ul><li>Procurez-vous gratuitement des informations juridiques</li><li>Obtenez la prise en charge de vos frais en cas d’action en justice</li></ul><br><br><p style=\"text-transform:uppercase;color:#f7aa36!important;font-weight:600;text-align:center;margin-bottom:0\">Protégez votre permis</p><div style=\"text-align:center;font-weight:800\">Vous avez perdu des points sur votre permis ?</div><ul><li>Stage de récupération de points pris en charge à hauteur de <span style=\"font-weight:800\">250€.</span></li></ul><div style=\"text-align:center;font-weight:800\">Vous devez repasser votre permis ?</div><ul><li>Nous prendrons en charge vos frais à hauteur de <span style=\"font-weight:800\">500€.</span></li></ul><br><p style=\"color:#278be5!important;font-weight:600;text-align:center;margin-bottom:0\">Pour en savoir plus</p><div style=\"text-align:center\">La garantie Protection Juridique s’applique en cas d’achat, réparation, entretien et vente de votre moto.</div>"
    },
    {
        type: 'Courtier',
        title: 'Coordonnées réparateur pour le sinistre de votre client',
        value: "coordonnees_reparateur_courtier",
        label: "Coordonnées réparateur courtier",
        text: "Afin que notre expert puisse examiner le véhicule de votre client, nous avons besoin d'avoir les coordonnées du réparateur de votre choix (nous n'avons pas de garage agréé).<br/><br/>Il suffit de cliquer sur ce lien afin d'enregistrer ses coordonnées et vous pourrez ensuite choisir avec votre client, la date qui lui convient pour que l'expert puisse passer.<br/><br/>Son véhicule devra être déposé la veille de son passage ou dès l'ouverture du garage.<br/><p class='text-center'><a class='btn' href='https://www.april-on.fr/'>Mon espace</a></p>",
    },
    {
        type: 'Courtier',
        title: 'Envoi de documents pour le sinistre de votre client',
        value: "envoi_doc_cou_sin",
        label: "Envoi de document",
        text: "Nous faisons suite à votre demande et avons le plaisir de vous transmettre en pièce(s) jointe(s) le(s) document(s) demandé(s).",
    },
    {
        type: 'Courtier',
        title: 'Accusé de réception',
        value: "accuse_reception_sinistre_ouvert_cou",
        label: "Accusé réception sinistre ouvert",
        text: "Nous accusons réception de la déclaration de sinistre de votre client et nous vous confirmons l'ouverture de son dossier.<br/><br/>Nous vous invitons à prendre connaissance des courriers joints qui vous indiquent les éléments enregistrés sur son dossier et la suite des démarches.<br/><mark>texte_libre</mark>",
        champs: [
            {
                'type': 'text',
                'label': "Texte libre",
                'slug': "<mark>texte_libre</mark>",
                'notRequired': true
            },
        ],
    },
    {
        type: 'Courtier',
        title: 'Accusé de réception pour le sinistre de votre client',
        value: "accuse_reception_sin_attente_cou",
        label: "Accusé réception sinistre en attente",
        text: "Nous accusons réception de la déclaration de sinistre de votre client, afin de pouvoir ouvrir son dossier, nous avons besoin de recevoir <mark>document</mark>.<br/><br/>Son dossier est donc en attente pour le moment, à réception du document demandé nous pourrons procéder à l'ouverture de celui-ci et poursuivre son avancement.<br/><mark>texte_libre</mark>",
        champs: [
            {
                'type': 'text',
                'label': "ex.: Circonstances précises de l'accident / constat amiable / dépôt de plainte",
                'slug': "<mark>document</mark>",
            },
            {
                'type': 'text',
                'label': "Texte libre",
                'slug': "<mark>texte_libre</mark>",
                'notRequired': true
            },
        ],
    },
    {
        type: 'Courtier',
        title: 'Sinistre non garanti',
        value: "sinistre_non_garanti_cou",
        label: "Sinistre non garanti",
        text: "J'accuse réception de la déclaration de sinistre de votre client et vous informe qu'au vu de ses garanties, nous ne pourrons malheureusement pas intervenir pour les dommages de son véhicule.<br/><br/>Nous vous invitons à lui en faire part, n'ayant pas ses coordonnées pour le prévenir.",
    },
    {
        type: 'Courtier',
        title: 'Documents en attente pour le sinistre de votre client',
        value: "document_en_attente_cou",
        label: "Documents en attente",
        text: "Afin de constituer le dossier sinistre de votre client et poursuivre l'avancement de celui-ci, nous avons besoin des éléments suivants&nbsp;:<br/><br/>{{$accident->documents_en_attente}}<br/><p class='text-center'><a class='btn' href='{{$accident->upload_documents_link}}'>J'envoie les documents</a></p>",
    },
    {
        type: 'Courtier',
        title: 'Déclaration de sinistre non déclarée',
        value: "dec_non_declaree_cou",
        label: "Déclaration de sinistre non déclarée",
        text: "Nous venons de recevoir une mise en cause concernant un accident survenu le {{$accident->dateSurv}} dans lequel le véhicule de votre client immatriculé {{$accident->immatVehicule}} semble impliqué.<br/><br/>Sauf erreur de notre part, cet évènement ne semble pas avoir été déclaré dans nos services.<br/><br/>Nous vous informons qu'une lettre recommandée est envoyée à votre client afin de lui demander des précisions sur cet éventuel sinistre.<br/><br/>Vous trouverez en pièce jointe la copie du courrier envoyé à votre client.",
    },
    {
        type: 'Réparateur',
        title: 'Nous attendons des documents pour la gestion d’un sinistre',
        value: "attente_docs_reparateur",
        label: "Attente doc(s) réparateur",
        text: "Afin de vous rembourser les réparations du véhicule de notre assuré, nous avons besoin des documents suivants&nbsp;:<br/><br/>{{$accident->documents_en_attente}}",
    },
    {
        type: 'Compagnie',
        title: 'Sinistre non déclaré',
        value: "sinistre_non_declare_cie",
        label: "Sinistre non déclaré",
        text: "Nous venons d’ouvrir un dossier concernant le sinistre survenu en date du {{$accident->dateSurv}} et non déclaré par notre client. <br/><br/>Celui-ci porte la référence : {{$accident->numCie}}<br/><br/>Nous faisons partir ce jour une lettre recommandée à notre assuré afin de lui demander pourquoi il ne nous a pas déclaré l’accident et de nous faire parvenir sa version des faits.",
    },
    {
        type: 'Compagnie',
        title: 'Envoi du dossier complet sinistre APRIL Moto',
        value: "envoi_dossier_wakam_corporelle",
        label: "Envoi du dossier à WAKAM - Gestion corporelle",
        text: "Nous vous prions de trouver ci-joint le dossier complet pour la reprise du dossier de notre client. <br/> <br/>Nous vous sollicitons afin de reprendre la gestion de la partie corporelle",
    },
    {
        type: 'Compagnie',
        title: 'Envoi du dossier pour confirmation de la RC',
        value: "envoi_dossier_wakam_rc",
        label: "Envoi du dossier à WAKAM - Confirmation RC",
        text: "Nous vous prions de trouver ci-joint le dossier complet pour la reprise du dossier de notre client.<br/><br/>Nous vous sollicitons afin de nous confirmer la RC de l’assuré.",
    },
    {
        type: 'Compagnie',
        title: 'Envoi du dossier complet pour reprise du recours',
        value: "envoi_dossier_wakam_reprise_recours",
        label: "Envoi du dossier à WAKAM - Reprise recours",
        text: "Nous vous prions de trouver ci-joint le dossier complet pour la reprise du dossier de notre client.<br/> <br/>En effet, malgré nos relances et un courrier en recommandé de mise en demeure envoyé à la Compagnie Adverse {{$accident->compagnieAdverse}}, le recours n'a toujours pas abouti.<br/><br/>Nous vous laissons le soin de reprendre la gestion du recours.",
    },
    {
        type: 'Compagnie',
        title: 'Détail du recours pour la réclamation matérielle',
        value: "detail_recours_reclamation_materielle",
        label: "Mail type 'Recours'",
        text: "Nous revenons vers vous dans le cadre du sinistre n°{{$accident->adverse_police}} pour lequel nous vous présentons notre réclamation matérielle, à savoir :<br/><br/><ul><li>Dommages matériels : <mark>montant_dommages_materiels</mark></li><li>Accessoires / équipement : <mark>montant_accessoires_equipements</mark></li><li>Remorquage : <mark>montant_remorquage</mark></li><li>Frais de gardiennage : <mark>montant_frais</mark></li><li>Autre : <mark>montant_autres</mark></li><br/></ul>Montant total : <mark>montant_total</mark><br/><br/>Vous trouverez ci-joint le rapport d'expertise.<br/><br/>Nous restons dans l’attente de votre règlement (chèque à établir à l’ordre de : <mark>ordre_cheque</mark>), que vous voudrez bien transmettre avec les références de notre sinistre, dès que possible à nos services.",
        champs: [
            {
                'type': 'text',
                'label': "Montant des dommages matériels",
                'slug': "<mark>montant_dommages_materiels</mark>",
                'condition': "<li>Dommages matériels : <mark>montant_dommages_materiels</mark></li>",
                'notRequired': true,
            },
            {
                'type': 'text',
                'label': "Montant des Accessoires et équipements",
                'slug': "<mark>montant_accessoires_equipements</mark>",
                'condition': "<li>Accessoires / équipement : <mark>montant_accessoires_equipements</mark></li>",
                'notRequired': true
            },
            {
                'type': 'text',
                'label': "Montant du Remorquage",
                'slug': "<mark>montant_remorquage</mark>",
                'condition': "<li>Remorquage : <mark>montant_remorquage</mark></li>",
                'notRequired': true
            },
            {
                'type': 'text',
                'label': "Montant des frais de gardiennage",
                'slug': "<mark>montant_frais</mark>",
                'condition': "<li>Frais de gardiennage : <mark>montant_frais</mark></li>",
                'notRequired': true
            },
            {
                'type': 'text',
                'label': "Montant autres",
                'slug': "<mark>montant_autres</mark>",
                'condition': "<li>Autre : <mark>montant_autres</mark></li>",
                'notRequired': true
            },
            {
                'type': 'select',
                'label': "Ordre cheque",
                'values': [
                    "%contract['souscripteur']['nom']%",
                    "APRIL Moto"
                ],
                'slug': "<mark>ordre_cheque</mark>",
            },
            {
                'type': 'text',
                'label': "Montant total",
                'slug': "<mark>montant_total</mark>",
            },
        ]
    },
    {
        type: 'Compagnie',
        title: 'Envoi du dossier complet pour le sinistre {{$accident->numCie}}',
        value: "envoi_dossier_cie",
        label: "Envoi dossier à la Cie - avec confirmation RC",
        text: "Nous vous prions de trouver ci-joint le dossier complet pour la reprise du dossier de notre client.<br/><br/>La référence de votre dossier est&nbsp;: {{$accident->numCie}}.<br/><br/>Nous vous sollicitons afin <mark>sujet_sollicitation</mark> et à réception nous confirmer la RC de l’assuré et le cas IDA.",
        champs: [
            {
                'type': 'text',
                'label': "Sujet de la sollicitation (ex.: de demander le procès-verbal / d’effectuer le recours)",
                'slug': "<mark>sujet_sollicitation</mark>",
            },
        ],
    },
    {
        type: 'Compagnie',
        title: 'Envoi du dossier complet pour le sinistre {{$accident->numCie}}',
        value: "envoi_dossier_cie_sans_confirm",
        label: "Envoi dossier à la Cie - sans confirmation RC",
        text: "Nous vous prions de trouver ci-joint le dossier complet pour la reprise du dossier de notre client.<br/><br/>La référence de votre dossier est&nbsp;: {{$accident->numCie}}.<br/><br/>Nous vous sollicitons afin <mark>sujet_sollicitation</mark>.",
        champs: [
            {
                'type': 'text',
                'label': "Sujet de la sollicitation (ex.: de demander le procès-verbal / d’effectuer le recours)",
                'slug': "<mark>sujet_sollicitation</mark>",
            },
        ],
    },
    {
        type: 'Compagnie',
        title: 'Réponse suite à une déclaration de sinistre compagnie',
        value: "reponse_pour_dec_cie",
        label: "Réponse pour DEC_CIE",
        text: "Vous nous avez informé d'un sinistre non déclaré par notre client survenu en date du {{$accident->dateSurv}}.<br/><br/><mark>proposition_motif</mark>.",
        champs: [
            {
                'type': 'select',
                'label': "Proposition motif",
                'values': [
                    "Son véhicule n'était pas assuré le jour du sinistre, car il y a eu un changement de véhicule entre temps.",
                    "Son véhicule n'était pas assuré le jour du sinistre, car la date d'effet du contrat est postérieure à la date de survenance du sinistre.",
                    "Son contrat a été résilié le <mark>texte_libre_1</mark> suite <mark>texte_libre_2</mark>",
                    "Un dossier est déjà ouvert avec une référence en « E ».",
                    "<mark>Autre_motif</mark>"
                ],
                'slug': "<mark>proposition_motif</mark>",
            },
            {
                'type': 'text',
                'label': "Texte libre 1",
                'slug': "<mark>texte_libre_1</mark>",
                'notRequired': true
            },
            {
                'type': 'text',
                'label': "Texte libre 2",
                'slug': "<mark>texte_libre_2</mark>",
                'notRequired': true
            },
            {
                'type': 'text',
                'label': "Autre motif",
                'slug': "<mark>Autre_motif</mark>",
                'notRequired': true
            },
        ],
    },
    {
        type: 'Compagnie',
        title: 'Dossier complet pour le numéro sinistre {{$accident->numCie}}',
        value: "envoi_dossier_cie_gestion_corpo",
        label: "Envoi dossier à la Cie - gestion corporelle",
        text: "Nous vous prions de trouver ci-joint le dossier complet pour la reprise du dossier de notre client.<br/><br/>La référence de votre dossier est&nbsp;: {{$accident->numCie}}.<br/><br/>Nous vous sollicitons afin de reprendre la gestion de la partie corporelle.",
    },
    {
        type: 'Compagnie',
        title: 'Informations sur le sinistre {{$accident->numCie}}',
        value: "envoi_dossier_cie_adverse",
        label: "Mail à la compagnie adverse",
        text: "Nous revenons vers vous dans le cadre du dossier référencé {{$accident->numCbt}}.<br/><br/>Nous vous précisons que la Compagnie d'assurance garantissant le véhicule de {{$contract->souscripteur_nom}} et gérant la partie corporelle et recours de nos dossiers est&nbsp;:<br/><br/>Allianz<br/>Direction Indemnisation Courtage<br/>Auto Matériels Particuliers, Professionnels<br/>TSA 51014<br/>92087 La Défense Cedex<br/><br/>Voici les références à rappeler pour vos correspondances&nbsp;:<br/><br/>Numéro de contrat&nbsp;: {{$contract->numeroPolice}}<br/>Référence compagnie&nbsp;: {{$accident->numCie}}",
    },
    {
        type: 'Compagnie',
        title: "Envoi de documents pour le sinistre {{$accident->numCie}}",
        value: "envoi_doc_cie",
        label: "Envoi de document",
        text: "Nous faisons suite à votre demande et avons le plaisir de vous transmettre en pièce(s) jointe(s) le(s) document(s) demandé(s).",
    },
    {
        type: 'Expert',
        title: 'Demande complément de rapport',
        value: "demande_complement_rapport_expert",
        label: "Demande complément de rapport",
        text: "Vous assurez une mission d’expertise concernant le véhicule immatriculé {{$accident->immatVehicule}}.<br/><br/>Pouvez-vous établir un complément de rapport <mark>sujet_complement_rapport</mark>",
        champs: [
            {
                'type': 'text',
                'label': "Sujet du complément de rapport (ex.: car la facture de réparation reçue est supérieure à votre chiffrage / concernant la facture complémentaire que vous trouverez en pièce jointe)",
                'slug': "<mark>sujet_complement_rapport</mark>",
            },
        ],
    },
    {
        type: 'Expert',
        title: 'Demande annulation rapport',
        value: "demande_annulation_rapport_expert",
        label: "Demande annulation rapport",
        text: "Nous ne pourrons intervenir dans les dommages du véhicule de notre client, nous venons de l’informer de la clôture de son dossier.<br/><br/>Nous vous remercions de ne pas déposer votre rapport.",
    },
    {
        type: 'Expert',
        title: 'Demande rapport en estimation',
        value: "demande_rapport_expert_en_estimation",
        label: "Demande rapport en estimation",
        text: "Notre client ne va pas effectuer les réparation de son véhicule pour le moment, nous allons donc procéder au règlement directement à son ordre.<br/><br/>Nous vous remercions de déposer votre rapport en estimation dès que possible.",
    },
    {
        type: 'Expert',
        title: 'Véhicule retrouvé dans les 30 jours',
        value: "vehicule_retrouve_30",
        label: "Véhicule retrouvé dans les 30 jours",
        text: "Nous vous informons que le véhicule de notre client immatriculé {{$contract->vehicule_immatriculation}} à été retrouvé dans le délai légal des 30 jours.<br/><br/>Nous vous invitons à clore votre dossier, car nous missionnons l'expert local afin d'examiner le véhicule.",
    },
    {
        type: 'Expert',
        title: 'Envoi du dossier complet pour le sinistre {{$accident->numCbt}}',
        value: "envoi_dossier_expert",
        label: "Envoi dossier à l’expert",
        text: "Une expertise est prévue le {{$accident->datePassageExpert}}, pour vous permettre de mener à bien votre mission, vous trouverez ci-joint les pièces nécessaires correspondantes au dossier de notre assuré.",
    },
];

export const emailsAutoContrat = [
    {
        type: "Client",
        title: "Votre contrat a été résilié pour omission de pièces",
        value: "rev_omission_liste_pieces",
        label: "REV omission - Liste des pièces",
        text: "Votre contrat a été résilié pour omission de pièces et vous souhaitez procéder à sa remise en vigueur afin de réassurer votre véhicule.<br/><br/>Pour cela, il suffit de nous retourner votre dossier complet, voici les éléments manquants:<br/><br/>{{$contract->documents_en_attente}}<br/><br/>Vous avez jusqu'au {{$contract->situation_dateMaxRev}} pour nous les envoyer.<p class='text-center'><a class='btn' href='{{$contract->upload_documents_link}}'>J'envoie les documents</a></p><br/>Passé cette date, vous devrez refaire une nouvelle souscription."
    },
    {
        type: 'Client',
        codeProduit: ["WAKAM_LIV"],
        title: 'Envoi attestation création de flotte livraison',
        value: "attestation_creation_flotte_livraison",
        label: "Envoi attestation création de flotte livraison",
        text: "Veuillez trouver ci-joint l’attestation de création de flotte de livraison à nous retourner complétée et signée.",
    },
    {
        type: "Client",
        title: "Demande d’antécédents d’assurance",
        value: "demande_antecedents_assurance",
        label: "RI Antécédents 24 mois ou 36 mois",
        text: "Nous vous informons qu’il nous manque à ce jour un relevé d’information justifiant de vos antécédents <mark>vehicule</mark> sur les <mark>nombres_mois</mark> derniers mois. Ce document est nécessaire et obligatoire dans l'obtention de la carte verte définitive, qui atteste de votre couverture d'assurance. <br/><br/>Afin de compléter votre dossier, nous vous invitons à contacter votre assureur. Vous trouverez ci-dessous un modèle type d’email que vous pouvez utiliser.<br/><br/><hr/>Bonjour,<br/><br/>Pouvez-vous me faire parvenir par email un relevé d’information justifiant de mes antécédents <mark>vehicule</mark> sur les <mark>nombres_mois</mark> derniers mois. <br/><br/>Je vous remercie par avance, <br/><br/>Cordialement <br/><br/>{{$contract->souscripteur_nom}}<hr/>",
        champs: [
            {
                'type': 'select',
                'label': "Véhicule",
                'values': [
                    "auto",
                    "moto",
                    "auto et moto"
                ],
                'slug': "<mark>vehicule</mark>",
            },{
                'type': 'select',
                'label': "Nombre de mois",
                'values': [
                    "24",
                    "36"
                ],
                'slug': "<mark>nombres_mois</mark>",
            },
        ]
    },
    {
        type: "Client",
        title: "Demande de justificatif d’assurance de votre véhicule",
        value: "demande_justificatif_assurance_vehicule",
        label: "RI véhicule",
        text: "Nous vous informons qu’il nous manque à ce jour le justificatif d’assurance de votre {{$contract->vehicule_marque}} {{$contract->vehicule_modele}}. Ce document est nécessaire et obligatoire dans l'obtention de la carte verte définitive, qui atteste de votre couverture d'assurance.<br/><br/>Afin de compléter votre dossier, nous vous invitons à contacter votre assureur. Vous trouverez ci-dessous un modèle type d’email que vous pouvez utiliser.<br/><br/><hr/>Bonjour,<br/><br/>Pouvez-vous me faire parvenir par email mon relevé d’information pour le véhicule immatriculé {{$contract->vehicule_immatriculation}}.<br/><br/>Je vous remercie par avance, <br/><br/>Cordialement <br/><br/>{{$contract->souscripteur_nom}}<hr/>",
    },
    {
        type: "Client",
        title: "Votre contrat a été résilié pour omission de pièces",
        value: "rev_omission_refus",
        label: "REV omission - Refus",
        text: "Votre contrat a été résilié pour omission de pièces et vous souhaitez procéder à sa remise en vigueur afin de réassurer votre véhicule.<br/><br/>Le délai pour nous les envoyer est dépassé, nous ne pouvons accéder à votre demande.<br/><br/>Cependant, vous pouvez dès à présent refaire une nouvelle souscription et il faudra alors nous envoyer votre dossier complet afin de finaliser votre contrat."
    },
    {
        type: 'Client',
        title: 'Sinistre non couvert',
        value: "sinistre_non_couvert",
        label: "Sinistre non couvert",
        text: "Nous avons reçu une déclaration pour un sinistre survenu le <mark>date_libre</mark> concernant votre véhicule immatriculé {{$contract->vehicule_immatriculation}}.<br/><br/>Nous vous informons que nous ne pourrons pas intervenir pour ce sinistre car vos garanties n'étaient pas acquises ce jour-là.<br/><br/><mark>cause_resiliation</mark>",
        champs: [
            {
                'type': 'select',
                'label': "Cause résiliation",
                'values': [
                    "En effet, votre contrat est résilié depuis le {{$contract->situation_dateFin}}",
                    "En effet, votre contrat est résilié pour non paiement depuis le {{$contract->situation_dateFin}} et de ce fait déjà suspendu 10 jours avant cette date.",
                    "En effet, vous avez souscrit votre contrat auprès de nos services après cette date."
                ],
                'slug': "<mark>cause_resiliation</mark>",
            },
            {
                'type': 'text',
                'label': "Date libre",
                'slug': "<mark>date_libre</mark>"
            },
        ],
    },
    {
        type: 'Client',
        title: "Avis de prélèvement suite à paiement",
        value: "avis_prel_paiement_cb",
        label: "Avis de prélèvement suite à paiement",
        text: "Nous venons de mettre à jour votre tableau de prélèvements suite à votre paiement par <mark>type_reglement</mark>.<br/><br/>Veuillez trouver ci-joint, votre nouvel avis de prélèvement.",
        champs: [
            {
                'type': 'select',
                'label': "Type de réglement",
                'values': [
                    "carte bleue",
                    "chèque",
                ],
                'slug': "<mark>type_reglement</mark>",
            },
        ]
    },
    {
        type: 'Client',
        title: "Accusé de réception de votre demande",
        value: "accuse_reception_demande_client",
        label: "Accusé de réception demande client",
        text: "Vous nous avez récemment transmis une demande <mark>motif_demande</mark><br/><br/>La date d'échéance de votre contrat étant éloignée, votre demande sera traitée par nos services en temps voulu.",
        champs: [
            {
                'type': 'text',
                'label': "Motif de la demande (ex.: de résiliation, de changement de fractionnement, de changement de garantie)",
                'slug': "<mark>motif_demande</mark>",
            },
        ]
    },
    {
        type: 'Client',
        title: "Envoi de documents sur votre contrat",
        value: "envoi_doc_contrat",
        label: "Envoi de document",
        text: "Nous faisons suite à votre demande et avons le plaisir de vous transmettre en pièce(s) jointe(s) le(s) document(s) demandé(s).",
    },
    {
        type: 'Client',
        title: "Modifications mineures sur votre contrat",
        value: "modif_mineures_contrat",
        label: "Modifications mineures",
        text: "Après vérification de vos documents, certaines informations déclarées à la souscription sont erronées, nous venons de les mettre à jour&nbsp;:<br/><mark>informations</mark><br/><br/><mark>consequences</mark>.<br/><br/>Nous vous invitons à signer les nouvelles Dispositions Particulières reçues dans le mail Yousign ou nous les déposer signées directement via le lien ci-dessous.<br/><p class='text-center'><a class='btn' href='{{$contract->upload_documents_link}}'>J'envoie les documents</a></p>",
        champs: [
            {
                'type': 'text',
                'label': "Informations mises à jour",
                'slug': "<mark>informations</mark>",
            },
            {
                'type': 'select',
                'label': "Conséquences modifications",
                'values': [
                    "Ces modifications n’entrainent aucun changement de tarif et vos garanties restent inchangées",
                    "Ces modifications entrainent une hausse tarifaire. Vous recevrez très prochainement par mail votre nouvel avis de prélèvement",
                    "Ces modifications entrainent une hausse tarifaire nous vous invitons à régler le prorata directement sur votre espace assuré",
                    "Ces modifications entraînent une baisse tarifaire, le prorata est déduit de vos prochains prélèvements",
                    "Ces modifications entraînent une baisse tarifaire, le prorata sera déduit de votre prochaine échéance",
                    "Ces modifications entraînent une baisse tarifaire, le trop-perçu vous sera remboursé très prochainement"
                ],
                'slug': "<mark>consequences</mark>",
            },
        ],
    },
    {
        type: 'Client',
        title: "Votre contrat va bientôt être résilié car il nous manque des documents",
        value: "info_lr_60j_contrat",
        label: "Info LR 60 jours",
        text: "Vous avez souscrit un contrat d’assurance auprès de nos services depuis plusieurs jours maintenant et nous vous en remercions.<br/><br/>Pour finaliser votre dossier au plus vite et continuer d'être reconnu comme étant assuré auprès des forces de l'ordre, envoyez-nous les documents suivants&nbsp;:<br/><br/>{{$contract->documents_en_attente}}<br/><p class='text-center'><a class='btn' href='{{$contract->upload_documents_link}}'>J'envoie les documents</a></p><br/>Nous vous informons qu’une lettre recommandée va être envoyée le <mark>date</mark> à votre domicile vous informant d’un délai de 10 jours pour nous transmettre les documents manquants avant résiliation de votre contrat pour omission de pièces.",
        champs: [
            {
                'type': 'text',
                'label': "Date d'envoi de la lettre recommandée",
                'slug': "<mark>date</mark>",
            },
        ],
    },
    {
        type: 'Client',
        title: "Il nous manque des informations pour valider la Loi Hamon",
        value: "info_loi_hamon_contrat",
        label: "Info pour Loi Hamon",
        text: "Vous nous avez mandaté pour procéder à la résiliation de votre contrat chez votre ancien assureur au titre de la Loi Hamon.<br/><br/>Pour cela nous avons besoin d’avoir <mark>sujet_sollicitation</mark><br/><br/>A réception de ces informations nous procèderons à l’envoi de la lettre recommandée afin de mettre fin à votre ancien contrat en respectant le préavis obligatoire de 30 jours.",
        champs: [
            {
                'type': 'text',
                'label': "Sujet de la sollicitation (le numéro de votre contrat / le nom de votre assureur / le numéro de contrat et le nom de votre assureur)",
                'slug': "<mark>sujet_sollicitation</mark>",
            },
        ],
    },
    {
        type: 'Client',
        title: "Confirmation de la résiliation de votre ancien contrat (Loi Hamon)",
        value: "confirm_resil_hamon",
        label: "Confirmation résiliation Loi Hamon",
        text: "Vous avez souscrit un contrat d’assurance pour votre {{$contract->vehicule_marque}} immatriculé {{$contract->vehicule_immatriculation}} auprès de nos services par le biais de la Loi Hamon.<br/><br/>Nous avons envoyé à votre assureur une lettre recommandée afin de résilier le contrat chez {{$contract->hamon_ancien_assureur}}<br/><br/>Pouvez-vous confirmer que vous avez bien reçu la validation de résiliation de votre ancien contrat&nbsp;?<br/><a class='btn' href='https://mon-espace.april-moto.com/'>Mon espace</a><br/>Dans le cas où vous n’avez pas reçu de confirmation, nous vous invitons à vous rapprocher de votre ancien assureur afin de vérifier la prise en compte de la résiliation.",
    },
    {
        type: 'Client',
        title: "Annulation de votre contrat d’assurance",
        value: "sans_effet_contrat",
        label: "Sans effet",
        text: "Nous vous informons que nous venons d’annuler votre contrat portant le numéro de police {{$contract->numeroPolice}}.<br/><br/>En effet, <mark>sujet_sollicitation</mark>.<br/><br/>Les Dispositions particulières que nous vous avons envoyées sont donc annulées.",
        champs: [
            {
                'type': 'text',
                'label': "Sujet de la sollicitation (il y avait un doublon de contrat / le contrat chez votre assureur actuel n’est pas résilié / vous n’avez pas acheté le véhicule)",
                'slug': "<mark>sujet_sollicitation</mark>",
            },
        ],
    },
    {
        type: 'Client',
        title: 'Suspension de votre contrat d’assurance',
        value: "suspension_template_contrat",
        label: "Suspension",
        text: "Nous vous confirmons la suspension de votre contrat suite <mark>sujet_sollicitation</mark>.<br/><br/>Vous trouverez en pièce jointe un avenant de suspension à nous retourner signer.<br/><br/>Cette suspension est valable 12 mois, vous pouvez à tout moment nous demander la remise en vigueur de ce contrat si vous souhaitez réassurer un nouveau véhicule.",
        champs: [
            {
                'type': 'text',
                'label': "Sujet de la sollicitation (au vol de votre véhicule / à la vente de votre véhicule / à la mise en dépôt vente de votre véhicule)",
                'slug': "<mark>sujet_sollicitation</mark>",
            },
        ],
    },
    {
        type: 'Client',
        title: 'Votre adresse postale n’est pas correcte',
        value: "npai_contrat",
        label: "NPAI",
        text: "Nous recevons ce jour un courrier avec la mention NPAI (N’habite Pas à l’Adresse Indiquée).<br/><br/>La dernière adresse connue est&nbsp;:<br/>{{$contract->souscripteur_adresse_rue1}}<br/>{{$contract->souscripteur_adresse_rue2}}<br/>{{$contract->souscripteur_adresse_rue3}}<br/>{{$contract->souscripteur_adresse_codePostal}} {{$contract->souscripteur_adresse_ville}}<br/><br/>Afin de mettre à jour votre dossier, nous vous invitons à nous communiquer votre nouvelle adresse directement sur votre espace perso<br/><p class='text-center'><a class='btn' href='https://mon-espace.april-moto.com/'>Mon espace</a></p>",
    },
    {
        type: 'Client',
        title: 'Proposition d’assurance pour le changement de votre contrat',
        value: "devis_avenant_contrat",
        label: "Devis d’avenant",
        text: "Nous faisons suite à votre demande de modification de contrat, vous trouverez ci-joint la proposition correspondante.<br/><br/>Cette proposition est valable 30 jours, si vous souhaitez la mettre en place il vous suffit de nous la retourner signée ou nous envoyer par mail votre bon pour accord en nous indiquant la date d’effet souhaitée.<br/><br/>A réception, nous procèderons à la validation de l’avenant et nous vous enverrons vos nouvelles Dispositions Particulières."
    },
    {
        type: 'Client',
        title: 'Modalités de résiliation de votre contrat d’assurance',
        value: "modalites_resil_contrat",
        label: "Modalités de résiliation",
        text: "Nous faisons suite à votre demande de résiliation.<br/><br/>Vous avez la possibilité de mettre fin à votre contrat pour les motifs suivants&nbsp;:<br/><br/><ul><li>A l’échéance principale avec un préavis de 2 mois (par Lettre Recommandée)</li><li>Suite à la cession de votre véhicule (certificat de cession à nous transmettre)</li><li>A la mise en épave de votre véhicule, racheté par un épaviste (certificat de destruction ou de cession à nous transmettre)</li><li>À la suite d’un sinistre vol ou destruction</li></ul><br/><br/>Dans le cas contraire, vous êtes toujours propriétaire du véhicule, et de ce fait vous avez une obligation légale de l'assurer au moins en Responsabilité civile, dans le cas où votre véhicule causerait un dommage à un tiers."
    },
    {
        type: "Client",
        title: "Votre véhicule est introuvable (FVA)",
        value: "vehicule_introuvable",
        label: "Véhicule introuvable FVA",
        text: "Bonjour, suite à votre souscription, nous ne retrouvons pas votre véhicule dans le fichier national des véhicules assurés (FVA), merci de nous transmettre l’immatriculation et la marque dans les plus brefs délais en répondant à cet email."
    },
    {
        type: 'Client',
        title: 'Lettre recommandée pour fausse déclaration',
        value: "fausse_dec_contrat",
        label: "Fausse déclaration",
        text: "Nous vous informons que nous sommes contraints de mettre fin à votre contrat.<br/><br/>En effet, les éléments en notre possession ne sont pas conformes à vos déclarations initiales&nbsp;:<br/><mark>declarations</mark><br/><br/>Une lettre recommandée est envoyée à votre domicile le <mark>date</mark>.<br/><br/><mark>effet de la resiliation</mark>",
        champs: [
            {
                'type': 'text',
                'label': "Déclarations",
                'slug': "<mark>declarations</mark>",
            },
            {
                'type': 'text',
                'label': "Date",
                'slug': "<mark>date</mark>",
            },
            {
                'type': 'select',
                'label': "Effet de la résiliation",
                'values': [
                    "La résiliation prendra effet 10 jours après l’envoi de ce courrier.",
                    "La résiliation prend effet à date de souscription de votre contrat, votre véhicule n’est donc plus assuré à ce jour."
                ],
                'slug': "<mark>effet de la resiliation</mark>",
            },
        ],
    },
    {
        type: 'Client',
        title: 'Coordonnées Mondial Assistance',
        value: "contrat_coordonnees_mondial_assistance_client",
        label: "Coordonnées Mondial Assistance",
        text: "Votre véhicule n’est plus en état de rouler et vous avez besoin de le faire rapatrier.<br/><br/>Vous pouvez contacter notre partenaire Mondial Assistance au 01.49.93.65.82<br/><br/>Des conseillers sont disponibles pour vous 24h/24 et 7 jours/7."
    },
    {
        type: 'Client',
        title: "Votre Protection Juridique Motard",
        value: "contrat_coordonnees_allianz_pj_client",
        label: "Coordonnées Allianz PJ",
        text: "Afin de vous accompagner au quotidien, votre contrat APRIL Moto inclut un service de protection juridique couvert par notre partenaire Allianz Protection Juridique.<br><br>Pour déclarer un litige, suivre vos démarches ou poser une question, rendez vous sur<br><a href=\"https://mesdemarches.allianz.fr/am/\" style=\"text-decoration:underline;color:#00e\">https://mesdemarches.allianz.fr/am/</a><br><br>Et si vous ne trouvez pas la réponse à votre question, des conseillers spécialisés peuvent vous aider au 09.78.97.80.75<br><br><div style=\"text-transform:uppercase;color:#f7aa36!important;font-weight:600;text-align:center;margin-bottom:0\">Défendez vos droits</div><div style=\"text-align:center;font-weight:800\">Vous avez eu affaire à un garagiste peu scrupuleux qui a endommagé votre moto ?</div><ul><li>Procurez-vous gratuitement des informations juridiques</li><li>Obtenez la prise en charge de vos frais en cas d’action en justice</li></ul><br><br><p style=\"text-transform:uppercase;color:#f7aa36!important;font-weight:600;text-align:center;margin-bottom:0\">Protégez votre permis</p><div style=\"text-align:center;font-weight:800\">Vous avez perdu des points sur votre permis ?</div><ul><li>Stage de récupération de points pris en charge à hauteur de <span style=\"font-weight:800\">250€.</span></li></ul><div style=\"text-align:center;font-weight:800\">Vous devez repasser votre permis ?</div><ul><li>Nous prendrons en charge vos frais à hauteur de <span style=\"font-weight:800\">500€.</span></li></ul><br><p style=\"color:#278be5!important;font-weight:600;text-align:center;margin-bottom:0\">Pour en savoir plus</p><div style=\"text-align:center\">La garantie Protection Juridique s’applique en cas d’achat, réparation, entretien et vente de votre moto.</div>"
    },
    {
        type: 'Courtier',
        title: "Accusé de réception de votre demande",
        value: "accuse_reception_demande_courtier",
        label: "Accusé de réception demande courtier",
        text: "Vous nous avez récemment transmis une demande <mark>motif_demande</mark> concernant le contrat de votre client.<br/><br/>La date d'échéance de son contrat étant éloignée, votre demande sera traitée par nos services en temps voulu.",
        champs: [
            {
                'type': 'text',
                'label': "Motif de la demande (ex.: de résiliation, de changement de fractionnement, de changement de garantie)",
                'slug': "<mark>motif_demande</mark>",
            },
        ]
    },
    {
        type: 'Courtier',
        codeProduit: ["WAKAM_LIV"],
        title: 'Envoi attestation création de flotte livraison',
        value: "attestation_creation_flotte_livraison",
        label: "Envoi attestation création de flotte livraison",
        text: "Veuillez trouver ci-joint l’attestation de création de flotte de livraison à nous retourner complétée et signée.",
    },
    {
        type: "Courtier",
        title: "Le véhicule de votre client est introuvable (FVA)",
        value: "vehicule_introuvable_courtier",
        label: "Véhicule introuvable FVA - courtier",
        text: "Suite à la souscription de votre client, nous ne retrouvons pas le véhicule dans le fichier national des véhicules assurés (FVA), merci de nous transmettre l’immatriculation et la marque dans les plus brefs délais."
    },
    {
//         Vous avez souscrit le contrat d’assurance pour votre client %raison sociale% pour le véhicule %marque% immatriculé %immat% par le biais de la Loi Hamon.
//
//     Nous avons envoyé à son assureur une lettre recommandée afin de résilier son contrat chez %A_OLDASSUR%
//
// Votre client a-t-il eu confirmation de leur part de la résiliation ? Vous pouvez nous le confirmer ?
//
//     CALL J’accède à mon espace
//
// Vous n’avez pas d’information ?
//     Rapprochez-vous de votre client afin qu’il puisse prendre contact avec son ancien assureur pour vérifier la bonne prise en compte de la résiliation de son contrat.
        type: 'Courtier',
        title: "Confirmation de la résiliation de votre ancien contrat (Loi Hamon)",
        value: "confirm_resil_hamon_courtier",
        label: "Confirmation résiliation Loi Hamon",
        text: "Vous avez souscrit le contrat d’assurance pour votre client {{$contract->souscripteur_nom}} pour le véhicule {{$contract->vehicule_marque}} immatriculé {{$contract->vehicule_immatriculation}} par le biais de la Loi Hamon.<br/><br/>Nous avons envoyé à son assureur une lettre recommandée afin de résilier son contrat chez {{$contract->hamon_ancien_assureur}}<br/><br/>Votre client a-t-il eu confirmation de leur part de la résiliation ? Vous pouvez nous le confirmer ?<br/><a class='btn' href='https://www.april-on.fr/'>Mon espace</a><br/><br/>Vous n’avez pas d’information ?<br/>Rapprochez-vous de votre client afin qu’il puisse prendre contact avec son ancien assureur pour vérifier la bonne prise en compte de la résiliation de son contrat.",
    },
    {
        type: "Courtier",
        title: "Le contrat de votre client a été résilié pour omission de pièces",
        value: "rev_omission_liste_pieces_courtier",
        label: "REV omission - Liste des pièces",
        text: "Le contrat de votre client a été résilié pour omission de pièces et vous souhaitez procéder à sa remise en vigueur afin de réassurer son véhicule.<br/><br/>Pour cela, il suffit de nous retourner son dossier complet, voici les éléments manquants:<br/><br/>{{$contract->documents_en_attente}}<br/><br/>Vous avez jusqu'au {{$contract->situation_dateMaxRev}} pour nous les envoyer.<p class='text-center'><a class='btn' href='{{$contract->upload_documents_link}}'>J'envoie les documents</a></p><br/>Passé cette date, vous devrez refaire une nouvelle souscription."
    },
    {
        type: "Courtier",
        title: "Le contrat de votre client a été résilié pour omission de pièces",
        value: "rev_omission_refus_courtier",
        label: "REV omission - Refus",
        text: "Le contrat de votre client a été résilié pour omission de pièces et vous souhaitez procéder à sa remise en vigueur afin de réassurer son véhicule.<br/><br/>Le délai pour nous les envoyer est dépassé, nous ne pouvons accéder à votre demande.<br/><br/>Cependant, vous pouvez dès à présent refaire une nouvelle souscription et il faudra alors nous envoyer son dossier complet afin de finaliser son contrat."
    },
    {
        type: 'Courtier',
        title: 'Envoi de documents sur le contrat de votre client',
        value: "envoi_doc_cou_contrat",
        label: "Envoi de document",
        text: "Nous faisons suite à votre demande et avons le plaisir de vous transmettre en pièce(s) jointe(s) le(s) document(s) demandé(s).",
    },
    {
        type: 'Courtier',
        title: "Modifications mineures sur le contrat de votre client",
        value: "modif_mineures_contrat_courtier",
        label: "Modifications mineures",
        text: "Après vérification des documents de votre client, certaines informations déclarées à la souscription sont erronées, nous venons de les mettre à jour&nbsp;:<br/><mark>informations</mark><br/><br/><mark>consequences</mark>.<br/><br/>Nous vous invitons à trouver en pièce jointe les nouvelles Dispositions Particulières à faire signer à votre client et à les déposer via votre espace pro ou directement sur le lien ci-dessous.<br/><p class='text-center'><a class='btn' href='{{$contract->upload_documents_link}}'>J'envoie les documents</a></p>",
        champs: [
            {
                'type': 'text',
                'label': "Informations mises à jour",
                'slug': "<mark>informations</mark>",
            },
            {
                'type': 'select',
                'label': "Conséquences modifications",
                'values': [
                    "Ces modifications n’entrainent aucun changement de tarif et ses garanties restent inchangées",
                    "Ces modifications entrainent une hausse tarifaire. Votre client recevra très prochainement par mail un nouvel avis de prélèvement",
                    "Ces modifications entrainent une hausse tarifaire nous vous invitons à régler le prorata par carte bancaire ou envoyer un lien de paiement à votre client directement sur votre espace pro",
                    "Ces modifications entraînent une baisse tarifaire, le prorata est déduit de ses prochains prélèvements",
                    "Ces modifications entraînent une baisse tarifaire, le prorata sera déduit de sa prochaine échéance",
                    "Ces modifications entraînent une baisse tarifaire, le trop-perçu vous sera envoyé par lettre-chèque très prochainement à votre client"
                ],
                'slug': "<mark>consequences</mark>",
            },
        ],
    },
    {
        type: 'Courtier',
        title: "Le contrat de votre client va bientôt être résilié car il nous manque des documents",
        value: "info_lr_60j_contrat_courtier",
        label: "Info LR 60 jours",
        text: "Vous avez souscrit un contrat d’assurance pour votre client depuis plusieurs jours maintenant et nous vous en remercions.<br/><br/>Pour finaliser son dossier au plus vite et continuer d'être reconnu comme étant assuré auprès des forces de l'ordre, envoyez-nous les documents suivants&nbsp;:<br/><br/>{{$contract->documents_en_attente}}<br/><p class='text-center'><a class='btn' href='{{$contract->upload_documents_link}}'>J'envoie les documents</a></p><br/>Nous vous informons qu’une lettre recommandée va être envoyée le <mark>date</mark> à son domicile l’informant d’un délai de 10 jours pour nous transmettre les documents manquants avant résiliation de son contrat pour omission de pièces.",
        champs: [
            {
                'type': 'text',
                'label': "Date d'envoi de la lettre recommandée",
                'slug': "<mark>date</mark>",
            },
        ],
    },
    {
        type: 'Courtier',
        title: "Il nous manque des informations pour valider la Loi Hamon de votre client",
        value: "info_loi_hamon_contrat_courtier",
        label: "Info pour Loi Hamon",
        text: "Nous sommes mandatés pour procéder à la résiliation du contrat de votre client chez son ancien assureur au titre de la Loi Hamon.<br/><br/>Pour cela nous avons besoin d’avoir <mark>sujet_sollicitation</mark><br/><br/>A réception de ces informations nous procèderons à l’envoi de la lettre recommandée afin de mettre fin à son ancien contrat en respectant le préavis obligatoire de 30 jours.",
        champs: [
            {
                'type': 'text',
                'label': "Sujet de la sollicitation (son numéro de contrat / le nom de son assureur / le n° contrat et le nom de son assureur)",
                'slug': "<mark>sujet_sollicitation</mark>",
            },
        ],
    },
    {
        type: 'Courtier',
        title: "Annulation du contrat d’assurance de votre client",
        value: "sans_effet_contrat_courtier",
        label: "Sans effet",
        text: "Nous vous informons que nous venons d’annuler le contrat de votre client numéro {{$contract->numeroPolice}}.<br/><br/>En effet, <mark>sujet_sollicitation</mark>.<br/><br/>Les Dispositions particulières que l’on vous a envoyées sont donc annulées.",
        champs: [
            {
                'type': 'text',
                'label': "Sujet de la sollicitation (il y avait un doublon de contrat / le contrat chez son assureur actuel n’est pas résilié / il n’a pas acheté le véhicule)",
                'slug': "<mark>sujet_sollicitation</mark>",
            },
        ],
    },
    {
        type: 'Courtier',
        title: 'Suspension du contrat d’assurance de votre client',
        value: "suspension_template_contrat_courtier",
        label: "Suspension",
        text: "Nous vous confirmons la suspension du contrat de votre client suite <mark>sujet_sollicitation</mark>.<br/><br/>Vous trouverez en pièce jointe un avenant de suspension à lui faire signer et nous retourner.<br/><br/>Cette suspension est valable 12 mois, vous pouvez à tout moment remettre en vigueur son contrat s’il souhaite réassurer un nouveau véhicule directement via votre Espace Pro.",
        champs: [
            {
                'type': 'text',
                'label': "Sujet de la sollicitation (au vol de son véhicule / à la vente de son véhicule / à la mise en dépôt vente de son véhicule)",
                'slug': "<mark>sujet_sollicitation</mark>",
            },
        ],
    },
    {
        type: 'Courtier',
        title: 'L’adresse postale de votre client n’est pas correcte',
        value: "npai_contrat_courtier",
        label: "NPAI",
        text: "Nous recevons ce jour un courrier avec la mention NPAI (N’habite Pas à l’Adresse Indiquée).<br/><br/>La dernière adresse connue est&nbsp;:<br/>{{$contract->souscripteur_adresse_rue1}}<br/>{{$contract->souscripteur_adresse_rue2}}<br/>{{$contract->souscripteur_adresse_rue3}}<br/>{{$contract->souscripteur_adresse_codePostal}} {{$contract->souscripteur_adresse_ville}}<br/><br/>Afin de mettre à jour son dossier, nous vous invitons à nous communiquer sa nouvelle adresse directement sur votre Espace Pro.<br/><p class='text-center'><a class='btn' href='https://www.april-on.fr/'>Mon espace</a></p>",
    },
    {
        type: 'Courtier',
        title: 'Réponse suite à une déclaration de sinistre compagnie',
        value: "reponse_pour_dec_cie",
        label: "Réponse pour DEC_CIE",
        text: "Vous nous avez informé d'un sinistre non déclaré par notre client survenu en date du <mark>date_libre</mark>.<br/><br/><mark>proposition_motif</mark>.",
        champs: [
            {
                'type': 'text',
                'label': "Date libre",
                'slug': "<mark>date_libre</mark>"
            },
            {
                'type': 'select',
                'label': "Proposition motif",
                'values': [
                    "Son véhicule n'était pas assuré le jour du sinistre, car il y a eu un changement de véhicule entre temps.",
                    "Son véhicule n'était pas assuré le jour du sinistre, car la date d'effet du contrat est postérieure à la date de survenance du sinistre.",
                    "Son contrat a été résilié le <mark>texte_libre_1</mark> suite <mark>texte_libre_2</mark>",
                    "Un dossier est déjà ouvert avec une référence en « E ».",
                    "<mark>Autre_motif</mark>"
                ],
                'slug': "<mark>proposition_motif</mark>",
            },
            {
                'type': 'text',
                'label': "Texte libre 1",
                'slug': "<mark>texte_libre_1</mark>",
                'notRequired': true
            },
            {
                'type': 'text',
                'label': "Texte libre 2",
                'slug': "<mark>texte_libre_2</mark>",
                'notRequired': true
            },
            {
                'type': 'text',
                'label': "Autre motif",
                'slug': "<mark>Autre_motif</mark>",
                'notRequired': true
            }
        ],
    },
    {
        type: 'Courtier',
        title: 'Proposition d’assurance pour le changement du contrat de votre client',
        value: "devis_avenant_contrat_courtier",
        label: "Devis d’avenant",
        text: "Nous faisons suite à votre demande de modification de contrat, vous trouverez ci-joint la proposition correspondante.<br/><br/>Cette proposition est valable 30 jours, si vous souhaitez la mettre en place il vous suffit de la valider directement sur votre Espace pro.<br/><br/>Vous pouvez également nous envoyer par mail votre bon pour accord en nous indiquant la date d’effet souhaitée.<br/><br/>A réception, nous procèderons à la validation de l’avenant et nous vous enverrons les nouvelles Dispositions Particulières à faire signer à votre client."
    },
    {
        type: 'Courtier',
        title: 'Modalités de résiliation du contrat d’assurance de votre client',
        value: "modalites_resil_contrat_courtier",
        label: "Modalités de résiliation",
        text: "Nous faisons suite à votre demande de résiliation.<br/><br/>Vous avez la possibilité de mettre fin au contrat d’assurance de votre client pour les motifs suivants&nbsp;:<br/><br/><ul><li>A l’échéance principale avec un préavis de 2 mois (par Lettre Recommandée)</li><li>Suite à la cession de son véhicule (certificat de cession à nous transmettre)</li><li>A la mise en épave de son véhicule, racheté par un épaviste (certificat de destruction ou de cession à nous transmettre)</li><li>À la suite d’un sinistre vol ou destruction</li></ul><br/><br/>Dans le cas contraire, il est toujours propriétaire du véhicule, et de ce fait il a une obligation légale de l'assurer au moins en Responsabilité civile, dans le cas où son véhicule causerait un dommage à un tiers."
    },
    {
        type: 'Courtier',
        title: 'Lettre recommandée pour fausse déclaration',
        value: "fausse_dec_contrat_courtier",
        label: "Fausse déclaration",
        text: "Nous vous informons que nous sommes contraints de mettre fin au contrat de votre client.<br/><br/>En effet, les éléments en notre possession ne sont pas conformes aux déclarations initiales&nbsp;:<br/><mark>declarations</mark><br/><br/>Une lettre recommandée est envoyée à son domicile le <mark>date</mark> afin de l’informer de la résiliation de son contrat sous 10 jours à compter de l’envoi de cette lettre.",
        champs: [
            {
                'type': 'text',
                'label': "Déclarations",
                'slug': "<mark>declarations</mark>",
            },
            {
                'type': 'text',
                'label': "Date",
                'slug': "<mark>date</mark>",
            }
        ],
    },
    {
        type: 'Courtier',
        title: 'Envoi Allianz suite mise en cause',
        value: "envoi_allianz_suite_cause_courtier",
        label: "Envoi Allianz suite mise en cause",
        text: "Nous venons vers vous suite à la réception d'une mise en cause dans le cadre d'un accident du <mark>date</mark> pour lequel le véhicule immatriculé {{$contract->vehicule_immatriculation}} n'est pas garanti le jour du sinistre.<br/><br/>Vous trouverez en pièce(s) jointe(s) le(s) document(s) reçu(s).<br/><br/>Nous vous invitons à prononcer une exception de garantie auprès de la Compagnie adverse.",
        champs: [
            {
                'type': 'text',
                'label': "Date de l'accident",
                'slug': "<mark>date</mark>",
            },
        ],
    },
    {
        type: 'Courtier',
        title: "Votre client est éligible à l’opération fidélisation !",
        value: "client_eligible_fidelisation_cou",
        label: "COURTIER PREMIUM UNIQUEMENT - Client éligible à l'opération fidélisation",
        text: "Nous venons d’échanger avec votre client {{$contract->souscripteur_nom}} au sujet d’une éventuelle revue du contrat de sa {{$contract->vehicule_marque}} {{$contract->vehicule_modele}} immatriculée {{$contract->vehicule_immatriculation}} numéro : {{$contract->numeroPolice}}. <br/> Bonne nouvelle, votre client est éligible à notre opération fidélisation ! <br/><br/> Nous l’avons invité à se rapprocher de vos services afin de recueillir de nouveau ses besoins et voir si une nouvelle tarification est possible. <br/><br/>Simple et facile, vous pouvez dorénavant effectuer un avenant de fidélisation sur votre espace pour tous vos clients éligibles.",
    },
    {
        type: 'Concessionnaire',
        title: "Votre client est éligible à l’opération fidélisation !",
        value: "client_eligible_fidelisation_concess",
        label: "Client éligible à l'opération fidélisation",
        text: "Nous venons d’échanger avec votre client {{$contract->souscripteur_nom}} au sujet d’une éventuelle revue du contrat de sa {{$contract->vehicule_marque}} {{$contract->vehicule_modele}} immatriculée {{$contract->vehicule_immatriculation}} numéro : {{$contract->numeroPolice}}. <br/> Bonne nouvelle, votre client est éligible à notre opération fidélisation ! <br/><br/> Nous l’avons invité à se rapprocher de vos services afin de recueillir de nouveau ses besoins et voir si une nouvelle tarification est possible. <br/><br/>Simple et facile, vous pouvez dorénavant effectuer un avenant de fidélisation sur votre espace pour tous vos clients éligibles.",
    },
]
