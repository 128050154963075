import loader from "../../../../Commun/Spinner/Loader/Loader";
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {  getFidelisations, FIDELISATIONS } from '../../../../../redux/actions/app/fidelisations/fidelisations.actions';
import { getContractData } from '../../../../../redux/selectors/contract/contract.selectors';
import { getFidelisationsLink } from '../../../../../redux/selectors/links/links.selectors';
import { getErrorEntity, getLoadingEntity } from '../../../../../redux/selectors/ui/ui.selectors';
import Fidelisations from './Fidelisations';
import {getFidelisationsData} from "../../../../../redux/selectors/fidelisations/fidelisations.selectors";

const mapStateToProps = (state, ownProps) => {
    const contract = getContractData(state);

    return {
        idContract: contract.id,
        uri: getFidelisationsLink(state),
        loaded: getLoadingEntity(state, FIDELISATIONS) === false,
        fidelisations: getFidelisationsData(state),
        error: getErrorEntity(state, FIDELISATIONS),
    };
};

const mapDispatchToProps = {
    getFidelisations,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { getFidelisations } = dispatchProps;
    const { uri, idContract} = stateProps;

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        load: () => getFidelisations({ query: _.replace(uri, '{id}', idContract) }),
    };
};

const FidelisationsContainer = compose(connect(mapStateToProps, mapDispatchToProps, mergeProps), loader())(Fidelisations);

export default FidelisationsContainer;
