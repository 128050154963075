import * as React from 'react';
import moment from 'moment'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider, frFR } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';

/* https://mui.com/x/react-date-pickers/getting-started/ */

const DatePickerFieldRedux = (props) => {
    const { meta:{ error }, input, label, placeholder = "DD/MM/YYYY", minDate, maxDate, onChange = () => {}} = props

    let locale = frFR.components.MuiLocalizationProvider.defaultProps.localeText;
    /* Props non traduite dans la lib */
    locale.datePickerDefaultToolbarTitle = 'Sélectionnez une date'


    return (
        <div className={'form-group'}>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr" localeText={locale} >
                <DatePicker
                    className={'w-100 bg-white date-picker'}
                    label={label}
                    value={input.value ? moment(input.value) : null}
                    onChange={(newValue) => {
                        input.onChange(newValue);
                        onChange(newValue);
                    }}
                    renderInput={(params) => {
                        if(moment(input.value).isValid()){
                            params.inputProps.value = moment(input.value, "DD/MM/YYYY", true).format('L')
                        }
                        params.inputProps.placeholder = placeholder;
                        params.InputProps.error = Boolean(error);
                        return <TextField {...params} />
                    }}
                    minDate={minDate}
                    maxDate={maxDate}
                />
                {error && <small className={'invalid-feedback'}>{error}</small>}
            </LocalizationProvider>
        </div>
    );
};

export default DatePickerFieldRedux;

