import {redirectInNewWindow} from "../../../../../utils/function";
import {openInWindow} from "../../../../../constants/constants";


const Fidelisations = (props) => {
    const {fidelisations, contract} = props

    return (
        <div className={'bloc-info bloc-fidelisation'}>
            <h2>Fidélisation</h2>
            <div className={'content'}>
                <table className='table'>
                    <thead>
                    <tr>
                        <th scope='col'>Date de la demande</th>
                        <th scope='col'>Dossier</th>
                        <th scope='col'>Situation</th>
                        <th scope='col'>Détail</th>
                    </tr>
                    </thead>
                    <tbody>
                    {fidelisations.map((fidelisation) => (
                        <tr key={fidelisation.id}>
                            <td>{new Date(fidelisation.date_demande).toLocaleDateString()}</td>
                            <td>{fidelisation.ouvert === 'T' ? 'En attente' : 'Cloturé'}</td>
                            <td>{fidelisation.reponse}</td>
                            <td>
                                <button className={'btn btn-more'}
                                    onClick={(e) => redirectInNewWindow({
                                        e,
                                        url: `/contrat/${contract.id}/fidelisation/${fidelisation.id}`,
                                        name: `${openInWindow.derogation}${fidelisation.id}`
                                    })}
                                />
                            </td>
                        </tr>
                    ))}

                    {fidelisations.length === 0 && (
                        <tr>
                            <td colSpan={5} className={'font-weight-normal'}>
                                Aucune fidélisation
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Fidelisations
