import React, {useRef, useState} from 'react';
import FlotteListContainer from "./FlotteList/FlotteListContainer";
import Loupe from '../../../../../assets/img/icons/loupe_dark.png'
import { formatImmatriculation } from "../../../../../utils/function";
import DocumentsContainer from "../Documents/DocumentsContainer";
import ReclamationsContainer from "../../Reclamations/ReclamationsContainer";
import FlotteAccidentsContainer from "./FlotteAccidents/FlotteAccidentsContainer";

const FlotteContent = ({ meta, links, contractId, contract, roles, getFlotte, uri }) => {
    const [sort, setSort] = useState('');
    const [immat, setImmat] = useState('');
    const textInput = useRef(null);

    const handleChangeImmat = (value) => {
        const newValue = formatImmatriculation(value.replaceAll(' ', ''));
        setImmat(newValue);
        getFlotte({
            query: uri,
            sort,
            contrat: contractId,
            immat: newValue,
            active: ''
        });
    };

    const handleChangeSort = (value) => {
        setSort(value);
        getFlotte({
            query: uri,
            sort: value,
            contrat: contractId,
            immat,
            active: ''
        });
    };

    const handleChangePage = (query) => {
        getFlotte({
            query,
            sort,
            contrat: contractId,
            immat,
            active: ''
        });
    };

    return (
        <div className={'tasks-container'}>
            <div className={'row margin-top'}>
                <div className={'col'}>
                    <div className={'bloc-info bloc-vehicules'}>
                        <div className={'row'}>
                            <h2>Liste des véhicules</h2>
                            <div className={'col-12 filters'}>
                                <div className={'content d-flex'}>
                                    <select
                                        name="sort"
                                        onChange={(e) => handleChangeSort(e.target.value)}
                                        className={'small-select select-sort-tasks custom-select'}
                                        value={sort}
                                    >
                                        <option value={''}>Adhesion</option>
                                        <option value={'date'}>Mise en circulation croissant</option>
                                        <option value={'-date'}>Mise en circulation décroissant</option>
                                    </select>
                                    <div className="input-group w-auto">
                                        <input
                                            type="text"
                                            className="form-control small-input align-top mr-0"
                                            placeholder="Immatriculation"
                                            style={{height: 32}}
                                            ref={textInput}
                                            onKeyDown={(e) => e.key === 'Enter' && handleChangeImmat(textInput.current.value.toUpperCase())}
                                            onChange={(e) => e.target.value.length === 0 && handleChangeImmat('')}
                                        />
                                        <div style={{height: 32}} className="input-group-append pointer" onClick={() => handleChangeImmat(textInput.current.value.toUpperCase())}>
                                            <span className="input-group-text"><img alt='icon' src={Loupe} className={'pt-1 align-top'} style={{width: 20}}/></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col'}>
                                <FlotteListContainer className='loader' contractId={contractId}/>
                            </div>
                        </div>
                        <div className={'row'}>
                            <nav>
                                <p>{meta.to}/{meta.total} véhicules</p>
                                <ul className="pagination">
                                    {meta.current_page > 2 && <li className="page-item" onClick={() => handleChangePage(links.first)}>
                                        <span className="page-link">{`<<`}</span></li>}
                                    {meta.current_page > 1 &&
                                        <li className="page-item" onClick={() => handleChangePage(links.prev)}>
                                            <span className="page-link"> {`<`} </span></li>}
                                    <li className="page-item"><span className={`page-link current`}>{meta.current_page}</span></li>
                                    {meta.last_page > meta.current_page &&
                                        <li className="page-item" onClick={() => handleChangePage(links.next)}>
                                            <span className="page-link">{`>`}</span></li>}
                                    {meta.last_page >= meta.current_page + 2 &&
                                        <li className="page-item" onClick={() => handleChangePage(links.last)}>
                                            <span className="page-link">{`>>`}</span></li>}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'row margin-top'}>
                <div className={'col'}>
                    <DocumentsContainer className={'loader'} contractId={contract.id} tiersId={contract.souscripteur.id} risqueNum={contract.adhesionPrincipale} roles={roles}/>
                </div>
            </div>
            <div className="row margin-top">
                <div className="col">
                    <ReclamationsContainer className={'loader'} id={contract.souscripteur.id}/>
                </div>
            </div>
            <div className={'row margin-top'}>
                <div className={'col'}>
                    <FlotteAccidentsContainer className={'loader'} contract={contract}/>
                </div>
            </div>
        </div>
    );
};

export default FlotteContent;
