import React, {Component} from 'react'
import {Field} from "redux-form";
import TextFieldRedux from "../../../../Commun/Input/Text/TextFieldRedux";
import MyButton from "../../../../Commun/Button/Button";
import Spinner from "../../../../Commun/Spinner/Spinner";
import TextAreaFieldRedux from "../../../../Commun/Input/Text/TextAreaFieldRedux";
import SelectFieldRedux from "../../../../Commun/Input/Select/SelectFieldRedux";

class AddNote extends Component {
    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit(form) {
        if (this.props.edit) {
            form.id = this.props.note.id
        }
        this.props.onSubmit(form)
    }

    render() {
        const {loading, handleSubmit, title, subtitle, edit, isAccident} = this.props

        return (
            <div className={'contract-container container'}>
                <div className={'row return-content'}>
                    <div className={'col'}>
                        <button onClick={() => this.props.history.go(-1)} className={'btn btn-return'}>Retour</button>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-12'}>
                        <h1 className={'title-primary'}>{title}</h1>
                    </div>
                    {subtitle &&
                        <div className={'col-12'}>
                            <span className={'bold subtitle'}>
                                {subtitle}
                            </span>
                        </div>
                    }
                </div>
                <div className={'row header-row'}>
                    <div className={'col-12'}>
                        <div className={'alert alert-info alert-rgpd'}>
                            <p>Merci de veiller à rester objectif dans votre saisie en évitant tout jugement de valeur,
                                ou collecte d'informations sensibles*. En cas de doute dans vos écrits, n'hésitez pas à
                                consulter votre Manager.</p>
                            <em>* État de santé, origine raciale, appartenance syndicale, opinion politique, opinion
                                religieuse, orientation sexuelle...( cf article 9-1 du RGPD)</em>
                        </div>
                    </div>
                    <div className={'col-12 header-row'}>
                        <form className={'form-content'} onSubmit={handleSubmit(this.handleSubmit)}>
                            {!isAccident ?
                                <Field
                                    name={'titre'}
                                    component={SelectFieldRedux}
                                    label={'Titre'}
                                >
                                    <option value={null}>Sélectionner le titre</option>
                                    <option value={'MAJ'}>MAJ</option>
                                    <option value={'Client décédé - coordonnées notaire'}>Client décédé - coordonnées notaire</option>
                                    <option value={'Élément bloquant'}>Élément bloquant</option>
                                    <option value={'Pas de hausse de garanties'}>Pas de hausse de garanties</option>
                                    <option value={'Demande d\'avenant'}>Demande d'avenant</option>
                                    <option value={'Si le client rappelle - transférer l\'appel à'}>Si le client rappelle - transférer l'appel à</option>
                                </Field> :
                                <Field
                                    name={'titre'}
                                    component={TextFieldRedux}
                                    label={'Titre'}
                                    subLabel={'50 caractères maximum'}
                                    maxLength={'50'}
                                />
                            }
                            <Field
                                name={'description'}
                                component={TextAreaFieldRedux}
                                label={'Description'}
                                subLabel={'255 caractères maximum'}
                                maxLength={'255'}
                            />
                            <MyButton
                                variant='raised'
                                color='secondary'
                                size='large'
                                className={'btn btn-primary'}
                                disabled={loading}
                            >
                                {loading && <Spinner absolute/>}
                                {edit ? 'Editer la note' : 'Ajout de la note'}
                            </MyButton>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default AddNote
