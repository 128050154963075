import { useEffect } from 'react';
import { Field } from 'redux-form';
import SelectFieldRedux from '../../../../../Commun/Input/Select/SelectFieldRedux';
import TextAreaFieldRedux from '../../../../../Commun/Input/Text/TextAreaFieldRedux';
import Spinner from '../../../../../Commun/Spinner/Spinner';

const natureReclamation = [
    { value: '01', label: 'Terminée' },
    { value: '02', label: 'Abandonnée' },
    { value: '04', label: 'En attente de retour Assistance' },
    { value: '05', label: 'En attente de retour Compagnie' },
    { value: '06', label: 'En attente de retour Expert' },
    { value: '07', label: 'En attente de retour Assuré' },
    { value: '08', label: 'En attente de retour Manager' },
    { value: '09', label: 'En attente de retour Autre' },
];
const ReclamationClose = (props) => {
    const { handleSubmit, tiers, loading, formValues, change } = props;

    const isAttenteRetour = ['04', '05', '06', '07', '08', '09'].includes(formValues.nature);
    const isAbandonnee = formValues.nature === '02';
    const isTerminer = formValues.nature === '01';

    useEffect(() => {
        if (isAttenteRetour) {
            change('issue', '4');
        } else if (isAbandonnee) {
            change('issue', '5');
        }
    }, [formValues.nature]);

    return (
        <div className={'contract-container container'}>
            <h1 className={'title-primary'}>
                {tiers.titre} {tiers.name} {tiers.belair_id}
            </h1>

            <div className={'row header-row'}>
                <div className={'col-12'}>
                    <form className={`form-content`} onSubmit={handleSubmit}>
                        <h2 className="title-primary text-center d-block">Clotûre de la réclamation</h2>

                        <div className="row">
                            <div className="col-6">
                                <Field component={SelectFieldRedux} name="nature" label={'Nature de la clôture'}>
                                    <option value={''}>Choisissez la nature de la clôture</option>
                                    {natureReclamation.map((item, index) => (
                                        <option key={index} value={item.value}>
                                            {item.label}
                                        </option>
                                    ))}
                                </Field>
                            </div>

                            <div className="col-6">
                                <Field
                                    component={SelectFieldRedux}
                                    name="issue"
                                    label={'Issue'}
                                    disabled={isAttenteRetour || isAbandonnee}
                                >
                                    <option value={''}>Choisissez une issue</option>
                                    <option value={'1'}>Favorable</option>
                                    <option value={'2'}>Partiellement favorable</option>
                                    <option value={'3'}>Refusée</option>
                                    {isTerminer ? null : (
                                        <>
                                            <option value={'4'}>Transfert</option>
                                            <option value={'5'}>Sans Suite</option>
                                        </>
                                    )}
                                </Field>
                            </div>

                            <div className="col-12">
                                <Field
                                    name={'description'}
                                    component={TextAreaFieldRedux}
                                    label={'Description des actions'}
                                    subLabel={'255 caractères maximum'}
                                    maxLength={'255'}
                                    rows="5"
                                />
                            </div>
                        </div>

                        {loading ? (
                            <Spinner class={'float-right'} />
                        ) : (
                            <button className={'btn btn-primary btn-inline-block mt-3 float-right'} type={'submit'}>
                                {formValues.issue === '4' ? 'Passer en attente' : 'Valider la clôture'}
                            </button>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ReclamationClose;
