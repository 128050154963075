import {connect} from 'react-redux'
import Garanties from './Garanties'
import {
    getContractAllGaranties
} from "../../../../../../../../redux/selectors/contract/contract.selectors";
import {change, formValueSelector} from "redux-form";
import {bindActionCreators} from "redux";

const mapStateToProps = state => {
    const selector = formValueSelector('constat')

    return {
        garanties: getContractAllGaranties(state),
        codegta: selector(state, 'codegta'),
        responsabilite: selector(state, 'manuel_resp'),
        blesse: selector(state, 'blesse'),
        adversaires: selector(state, 'adversaires'),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    changeValue: (field, value) => change('constat', field, value),
}, dispatch)

const GarantiesContainer = connect(mapStateToProps, mapDispatchToProps)(Garanties)

export default GarantiesContainer
