import React, {useState} from 'react';
import SelectFieldRedux from "../../Input/Select/SelectFieldRedux";
import {Field} from "redux-form";
import Autocomplete from "../../Input/Autocomplete/Autocomplete";

const FilteredAutocomplete = (props) => {
    const {labelOtherValue, dataFiltered, data, changeValue, name, onChange} = props
    const [showAll, setShowAll] = useState(false);

    return (
        !showAll ?
            <Field
                {...props}
                name={`filtered${name}`}
                component={SelectFieldRedux}
                onChange={(e) => {
                    changeValue(name, e.target.value === 'other' ? null : e.target.value);

                    if(e.target.value === 'other') {
                        setShowAll(true)
                    } else {
                        onChange(e.target.value);
                    }
                }} >
                <option value={null}>Choisissez une valeur</option>
                {dataFiltered.map((c, index) => <option key={index} value={c.value}>{c.label}</option>)}
                <option value={'other'}>{labelOtherValue}</option>
            </Field>
        : <Field {...props} component={Autocomplete} data={data}/>
    );
};

FilteredAutocomplete.defaultProps = {
    labelOtherValue: 'Autre',
    onChange: () => {},
};

export default FilteredAutocomplete;
