import React from 'react';
import moment from "moment";

const GiftCardDetails = (props) => {
    const {gift_card} = props

    return (
        <tr>
            <td>{gift_card.amount}€</td>
            <td>{moment(gift_card.created_at, 'YYYY-MM-DD').format('L')}</td>
            <td>{gift_card.created_for}</td>
            <td>{moment(gift_card.expiration_date, 'YYYY-MM-DD').format('L')}</td>
        </tr>
    );
};

export default GiftCardDetails;