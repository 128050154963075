import {connect} from 'react-redux'
import {compose} from 'redux'

import _ from "lodash";
import ReclamationTransfert from "./ReclamationTransfert";
import {formValueSelector, reduxForm} from "redux-form";
import validate from "./validate";
import {getTiers, TIERS} from "../../../../../../redux/actions/app/tiers/tiers.actions";
import {getTiersData} from "../../../../../../redux/selectors/tiers/tiers.selectors";
import {getErrorEntity, getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {complainTransfertLink, tiersGetLink} from "../../../../../../redux/selectors/links/links.selectors";
import {
    POST_TRANSFERT_RECLAMATION,
    postTransfertReclamation
} from "../../../../../../redux/actions/app/reclamation/reclamation.actions";
import loader from "../../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    const selector = formValueSelector('transfert-reclamation')

    return {
        queryPost: complainTransfertLink(state),
        query: tiersGetLink(state),
        tiers: getTiersData(state),
        raison_transfert: selector(state, 'raison_transfert'),
        loaded: getLoadingEntity(state, TIERS) === false,
        error: getErrorEntity(state, TIERS),
        loading: getLoadingEntity(state, POST_TRANSFERT_RECLAMATION)
    }
}

const mapDispatchToProps = {
    getTiers,
    postTransfertReclamation
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {query, queryPost} = stateProps
    const {getTiers, postTransfertReclamation} = dispatchProps
    const {id, ids} = ownProps.match.params

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getTiers({query: _.replace(query, '{id}', id), id}),
        onSubmit: form => postTransfertReclamation({
            query: _.replace(_.replace(queryPost, '{id}', id), '{ids}', ids),
            form
        })
    }
}

const ReclamationTransfertContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'transfert-reclamation',
        validate
    }),
    loader()
)(ReclamationTransfert)

export default ReclamationTransfertContainer
