import {connect} from 'react-redux'
import {formValueSelector} from 'redux-form'
import Documents from './Documents'
import {getUserRoles} from "../../../../../../../../redux/selectors/user/user.selectors";

const mapStateToProps = state => {
    const selector = formValueSelector('constat')

    return {
        categorie: selector(state, 'catsinorigin'),
        constatCheck: selector(state, 'constat_accident'),
        roles: getUserRoles(state)
    }
}

const DocumentsContainer = connect(mapStateToProps)(Documents)

export default DocumentsContainer
