const vitalsUrl = 'https://vitals.vercel-analytics.com/v1/vitals';
function getConnectionSpeed() {
    return 'connection' in navigator &&
    navigator['connection'] &&
    // @ts-ignore
    'effectiveType' in navigator['connection']
        ? navigator['connection']['effectiveType']
        : '';
}
export function sendToVercelAnalytics(metric) {
    const analyticsId = import.meta.env.VITE_VERCEL_ANALYTICS_ID;
    if (!analyticsId) {
        return;
    }

    const body = {
        dsn: analyticsId,
        id: metric.id,
        page: window.location.pathname,
        href: window.location.href,
        event_name: metric.name,
        value: metric.value.toString(),
        speed: getConnectionSpeed(),
    };

    const blob = new Blob([new URLSearchParams(body).toString()], {
        // This content type is necessary for `sendBeacon`
        type: 'application/x-www-form-urlencoded',
    });
    if (navigator.sendBeacon) {
        navigator.sendBeacon(vitalsUrl, blob);
    } else
        fetch(vitalsUrl, {
            body: blob,
            method: 'POST',
            credentials: 'omit',
            keepalive: true,
        });
}
export const reportWebVitals = (onPerfEntry) => {
    if (onPerfEntry && onPerfEntry instanceof Function) {
        import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
            getCLS && getCLS(onPerfEntry);
            getFID && getFID(onPerfEntry);
            getFCP && getFCP(onPerfEntry);
            getLCP && getLCP(onPerfEntry);
            getTTFB && getTTFB(onPerfEntry);
        });
    }
};