import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {setNotification} from "../../../actions/core/notifications/notifications.actions";

import _ from "lodash";
import {
    GET_THIRD_PARTY_PAYER,
    POST_CREATE_THIRD_PARTY_PAYER,
    POST_DELETE_THIRD_PARTY_PAYER,
    POST_UPDATE_THIRD_PARTY_PAYER,
    setTiersPayeur,
    THIRD_PARTY_PAYER
} from "../../../actions/app/tiers_payeur/tiers_payeur.actions";
import moment from "moment";

export const tiersPayeurMiddleware = () => next => action => {
    next(action)
    let clone = null;

    switch (action.type) {
        case GET_THIRD_PARTY_PAYER:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: THIRD_PARTY_PAYER}),
                setLoader({state: true, entity: THIRD_PARTY_PAYER})
            ])
            break

        case `${THIRD_PARTY_PAYER} ${API_SUCCESS}`:
            next([
                setTiersPayeur({tiers_payeur: action.payload.data}),
                setError({state: false, entity: THIRD_PARTY_PAYER}),
                setLoader({state: false, entity: THIRD_PARTY_PAYER})
            ])
            break

        case `${THIRD_PARTY_PAYER} ${API_ERROR}`:
            next([
                setError({state: true, entity: THIRD_PARTY_PAYER}),
                setLoader({state: false, entity: THIRD_PARTY_PAYER})
            ])
            break

        case POST_CREATE_THIRD_PARTY_PAYER:
            clone = _.cloneDeep(action.payload.body)

            if(clone.type === 'P'){
                const birthdateMoment = moment(clone.birthdate, 'DD/MM/YYYY');
                clone.birthdate = birthdateMoment.format('YYYY-MM-DD');
            }

            next([
                apiRequest({
                    body: (_.pickBy(clone, function(value) {return  !_.isNull(value)})),
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_CREATE_THIRD_PARTY_PAYER,
                    otherData: action.payload.otherData
                }),
                setLoader({state: true, entity: POST_CREATE_THIRD_PARTY_PAYER})
            ])
            break

        case `${POST_CREATE_THIRD_PARTY_PAYER} ${API_SUCCESS}`:
            next([
                setError({state: false, entity: POST_CREATE_THIRD_PARTY_PAYER}),
                setLoader({state: false, entity: POST_CREATE_THIRD_PARTY_PAYER})
            ])

            window.location.pathname = `/contrat/${action.payload.meta.otherData.idContract}`
            break

        case `${POST_CREATE_THIRD_PARTY_PAYER} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_CREATE_THIRD_PARTY_PAYER,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_CREATE_THIRD_PARTY_PAYER}),
                setLoader({state: false, entity: POST_CREATE_THIRD_PARTY_PAYER})
            ])
            break

        case POST_UPDATE_THIRD_PARTY_PAYER:
            clone = _.cloneDeep(action.payload.body)

            if(clone.type === 'P'){
                clone.birthdate = clone.birthdate.format('YYYY-MM-DD')
            }

            next([
                apiRequest({
                    body: (_.pickBy(clone, function(value) {return  !_.isNull(value)})),
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_UPDATE_THIRD_PARTY_PAYER
                }),
                setLoader({state: true, entity: POST_UPDATE_THIRD_PARTY_PAYER})
            ])
            break

        case `${POST_UPDATE_THIRD_PARTY_PAYER} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_UPDATE_THIRD_PARTY_PAYER,
                    body: 'La modification du tiers payeur a été effectuée avec succès',
                    type: 'success',
                    title: 'Modification correspondant'
                }),
                setError({state: false, entity: POST_UPDATE_THIRD_PARTY_PAYER}),
                setLoader({state: false, entity: POST_UPDATE_THIRD_PARTY_PAYER})
            ])

            break

        case `${POST_UPDATE_THIRD_PARTY_PAYER} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_UPDATE_THIRD_PARTY_PAYER,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_UPDATE_THIRD_PARTY_PAYER}),
                setLoader({state: false, entity: POST_UPDATE_THIRD_PARTY_PAYER})
            ])
            break

        case POST_DELETE_THIRD_PARTY_PAYER:
            next([
                apiRequest({
                    body: (action.payload.body),
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_DELETE_THIRD_PARTY_PAYER
                }),
                setLoader({state: true, entity: POST_DELETE_THIRD_PARTY_PAYER})
            ])
            break

        case `${POST_DELETE_THIRD_PARTY_PAYER} ${API_SUCCESS}`:

            next([
                setNotification({
                    entity: POST_DELETE_THIRD_PARTY_PAYER,
                    body: `<p>Le tiers payeur du contrat a été supprimé avec succès</p>`,
                    type: 'success',
                    title: 'Supprimé!'
                }),
                setLoader({state: false, entity: POST_DELETE_THIRD_PARTY_PAYER}),
                setError({state: false, entity: POST_DELETE_THIRD_PARTY_PAYER}),
            ])
            break

        case `${POST_DELETE_THIRD_PARTY_PAYER} ${API_ERROR}`:

            next([
                setNotification({
                    entity: POST_DELETE_THIRD_PARTY_PAYER,
                    body: `<p>Erreur lors de la suppression du tiers payeur : ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Attention !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_DELETE_THIRD_PARTY_PAYER}),
                setLoader({state: false, entity: POST_DELETE_THIRD_PARTY_PAYER})
            ])
            break

        default:
            break
    }
    return null
}
