import {SET_PARRAINAGES} from '../../actions/app/parrainages/parrainages.actions'

export const parrainagesReducer = (parrainages = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_PARRAINAGES) {
        return payload
    } else {
        return parrainages
    }
}
