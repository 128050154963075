import {connect} from 'react-redux'
import {compose} from 'redux'
import ReclamationClose from "./ReclamationClose";
import {getTiersData} from "../../../../../../redux/selectors/tiers/tiers.selectors";
import _ from "lodash";
import {
    complainCloseLink,
    complainPendingeLink,
    tiersGetLink
} from "../../../../../../redux/selectors/links/links.selectors";

import {getErrorEntity, getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {getTiers, TIERS} from "../../../../../../redux/actions/app/tiers/tiers.actions";
import {
    POST_CLOSE_RECLAMATION, POST_PENDING_RECLAMATION,
    postCloseReclamation, postPendingReclamation
} from "../../../../../../redux/actions/app/reclamation/reclamation.actions";
import {change, formValueSelector, reduxForm} from "redux-form";
import validate from "./validate";
import ErrorHttpComponent from "../../../../../Commun/Error/ErrorHttpComponent";
import loader from "../../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    const selector = formValueSelector('fermeture-reclamation')

    return {
        queryClose: complainCloseLink(state),
        queryPending: complainPendingeLink(state),
        query: tiersGetLink(state),
        tiers: getTiersData(state),
        loaded: getLoadingEntity(state, TIERS) === false,
        error: getErrorEntity(state, TIERS),
        loading: getLoadingEntity(state, POST_CLOSE_RECLAMATION)  || getLoadingEntity(state, POST_PENDING_RECLAMATION),
        formValues: selector(state, 'nature', 'issue')
    }
}

const mapDispatchToProps = {
    getTiers,
    postCloseReclamation,
    postPendingReclamation,
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {query, queryClose, queryPending} = stateProps
    const {getTiers, postCloseReclamation, postPendingReclamation} = dispatchProps
    const {id, ids} = ownProps.match.params

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getTiers({query: _.replace(query, '{id}', id), id}),
        onSubmit: function (form) {
            if (form.issue === '4') {
                postPendingReclamation({
                    query: _.replace(_.replace(queryPending, '{id}', id), '{ids}', ids),
                    form,
                    otherData: {id, ids}
                })
            } else {
                postCloseReclamation({
                    query: _.replace(_.replace(queryClose, '{id}', id), '{ids}', ids),
                    form,
                    otherData: {id, ids}
                })
            }
        }
    }
}

const ReclamationCloseContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'fermeture-reclamation',
        validate
    }),
    loader({ErrorIndicator: ErrorHttpComponent})
)(ReclamationClose)

export default ReclamationCloseContainer
