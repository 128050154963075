import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {formValueSelector, reduxForm} from 'redux-form'
import {postTracfinGetLink, tiersGetLink} from '../../../../../redux/selectors/links/links.selectors'
import {getLoadingEntity} from '../../../../../redux/selectors/ui/ui.selectors'
import TracfinForm from "./TracfinForm";
import _ from "lodash";
import {getTiersData} from "../../../../../redux/selectors/tiers/tiers.selectors";
import {
    getTiers,
    POST_TRACFIN_TIERS,
    postTracfinTiers,
    TIERS
} from "../../../../../redux/actions/app/tiers/tiers.actions";
import loader from "../../../../Commun/Spinner/Loader/Loader";
import ErrorHttpComponent from "../../../../Commun/Error/ErrorHttpComponent";

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector('addTracfin')
    const tiers = getTiersData(state)
    return {
        tiers,
        tiersId: ownProps.match.params.id,
        uri: tiersGetLink(state),
        uriPost: postTracfinGetLink(state),
        loaded: getLoadingEntity(state, TIERS) === false,
        type_tracfin: selector(state, 'detection_tracfin'),
        loading: getLoadingEntity(state, POST_TRACFIN_TIERS),
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getTiers: ({query, id}) => getTiers({query, id}),
    submitPostTracfin: ({form, query}) => postTracfinTiers({form, query})
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {submitPostTracfin, getTiers} = dispatchProps
    const {uri, tiersId, uriPost} = stateProps
    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getTiers({query: _.replace(uri, '{id}', tiersId), id: tiersId}),
        onSubmit: (form) => submitPostTracfin({form, query: _.replace(uriPost, '{id}', tiersId)})
    }
}
const TracfinFormContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent}),
    reduxForm({
        form: 'addTracfin'
    })
)(TracfinForm)

export default TracfinFormContainer
