import React, {useEffect} from 'react';
import {Field} from "redux-form";
import UploadContainer from "../../../../../../../Commun/Input/Upload/UploadContainer";

const Documents = (props) => {
    const {listDocuments, categorie} = props

    useEffect(() => {
        document.getElementById('declaration-final').scrollIntoView({behavior: 'smooth'})
    }, [])

    return (
        <div className={`form-content`}>
            <h1 className={'title-primary'}>Documents</h1>

            {listDocuments.map((doc, index) => (
                <div className={'col'} key={index}>
                    {
                        categorie === '10.0' ?
                            <div className="row upload-content">
                                <div className="col-10">
                                    <p>{doc.description}</p>
                                </div>
                            </div>

                            : <Field
                                name='upload'
                                component={UploadContainer}
                                formName='declaration-sinistre'
                                title={doc.description}
                                code={`files[${doc.code}]`}
                            />
                    }

                </div>
            ))}
        </div>
    );
};

export default Documents;
