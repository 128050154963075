import React from 'react';
import {Field} from "redux-form";
import DatePickerFieldRedux from "../../../../Commun/Input/DatePicker/DatePickerFieldRedux";
import Spinner from "../../../../Commun/Spinner/Spinner";
import moment from "moment";

const EditRIAntecedent = (props) => {
    const {handleSubmit, loading, antecedent, update_antecedent} = props

    return (
        <div className={'contract-container container'}>
            <div className={'row'}>
                <div className={'col'}>
                    <h1 className={'title-primary'}>Edition de la période reçue {antecedent.type} du {antecedent.start} au {antecedent.end}</h1>
                </div>
            </div>
            <div className={'row header-row'}>
                <div className={'col-12'}>
                    { update_antecedent
                        ? <div className={'row margin-top text-center'}>
                            <div className={'col'}>
                                <h2 className={'title-primary title-success'}>La période a bien été éditée&nbsp;!</h2>
                            </div>
                        </div>
                        : <form onSubmit={handleSubmit} className={'form-content'}>
                            <div className="row">
                                <div className="col">
                                    <Field
                                        name={"startDate"}
                                        component={DatePickerFieldRedux}
                                        label={'Date de début de la période reçue'}
                                        maxDate={moment()}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Field
                                        name={"endDate"}
                                        component={DatePickerFieldRedux}
                                        label={'Date de fin'}
                                        maxDate={moment()}
                                    />
                                </div>
                            </div>
                            {
                                loading ? <Spinner style={{display: 'block', margin: 'auto'}}/> :
                                    <button className={'btn btn-primary d-block'}
                                            type={'submit'}>
                                        Editer la période
                                    </button>
                            }
                        </form>
                    }
                </div>
            </div>
        </div>
    );
};
export default EditRIAntecedent;
