import React from 'react'

const Button = ({
                    children,
                    ...custom
                }) => {
    return (
        <button {...custom}>
            {children}
        </button>
    )
}

export default Button
