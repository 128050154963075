import React, {Fragment} from 'react'
import _ from "lodash";
import VirementsSinistresContainer from "./Sinistres/VirementsSinistresContainer";
import VirementsProdContainer from "./Prod/VirementsProdContainer";

const RedirectToList = (props) => {
    const {roles} = props

    return (
        <Fragment>
            {_.includes(roles, 'manager indem') && <VirementsSinistresContainer/>}
            {_.includes(roles, 'manager prod') && <VirementsProdContainer/>}
        </Fragment>
    )
}

export default RedirectToList
