import React, {useEffect} from 'react';
import _ from "lodash";
import {Field} from "redux-form";
import CheckboxFieldRedux from "../../../../../../../Commun/Input/Checkbox/CheckboxFieldRedux";
import checkDefaultGarantie from "../../../../../../../../utils/checkDefaultGarantie";

const Garanties = (props) => {
    const {garanties, codegta, responsabilite, changeValue, blesse, adversaires} = props
    const garantiesSin = ['RC', 'DR', 'DA', 'PCC1', 'PCC2', 'PCC3', 'IC', 'ICP', 'ACCEQ', 'EQ', 'VALMAJ', 'RFDTA', 'SCRC', 'SCDR', 'SCDA', 'SCIC', 'RC2', 'RC35', 'PCC0', 'PVRC', 'PVDR', 'PVDC', 'PVDA', 'PVIC']


    const garantiesContrat = _.filter(garanties, (o) => {
        return _.includes(garantiesSin, o.code)
    })

    useEffect(() => {
        checkDefaultGarantie({codegta, responsabilite, garantiesContrat, changeValue, blesse, adversaires})
    }, [codegta, responsabilite, blesse])

    return (
        <div className={`form-content h-100`}>
            <h1 className={'title-primary'}>Sélectionner les garanties sinistrées</h1>

            {
                garantiesContrat.map((g, index) => (
                    <Field
                        name={`garanties[${index}][${g.code}]`}
                        component={CheckboxFieldRedux}
                        label={g.libelle}
                    />
                ))
            }
        </div>
    );
};

export default Garanties;
