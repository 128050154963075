import {connect} from 'react-redux'
import Client from './Client'
import {
    getContractImmatriculation,
    getContractMarque,
    getContractModele, getContractNomSouscripteur, getIsWarkamContract
} from "../../../../../../../../redux/selectors/contract/contract.selectors";

const mapStateToProps = state => {
    return {
        marque: getContractMarque(state),
        modele: getContractModele(state),
        immat: getContractImmatriculation(state),
        souscripteur: getContractNomSouscripteur(state),
        isWakam: getIsWarkamContract(state)
    }
}

const ClientContainer = connect(mapStateToProps)(Client)

export default ClientContainer
