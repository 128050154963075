export const USER = '[User]'

export const GET_USER = `${USER} Get`
export const SET_USER = `${USER} Set`

export const getUser = ({query}) => ({
    type: GET_USER,
    payload: {
        data: query
    }
})

export const setUser = ({user}) => ({
    type: SET_USER,
    payload: {
        data: user
    }
})
