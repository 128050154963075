import {connect} from 'react-redux'
import {compose} from "redux";
import DetailsAntecedents from "./DetailsAntecedents";
import {getDocumentsData} from "../../../../../redux/selectors/documents/documents.selector";

const mapStateToProps = state => {
    return {
        documents: getDocumentsData(state),
    }
}

const DetailsAntecedentsContainer = compose(
    connect(mapStateToProps)
)(DetailsAntecedents)

export default DetailsAntecedentsContainer