import {POST_ACCIDENT} from "../accidents/accidents.actions";

export const UI = '[Ui]'

export const SET_LOADER = `${UI} SET_LOADER`
export const SET_IS_LOGIN = `${UI} SET_IS_LOGIN`
export const SET_IS_LOGOUT = `${UI} SET_IS_LOGOUT`
export const SET_MAINTENANCE = `${UI} SET_MAINTENANCE`
export const SET_ERROR = `${UI} SET_ERROR`
export const SET_ID_ENTITY_SELECTED = `${UI} SET_ID_ENTITY_SELECTED`
export const SET_ACCIDENT_SUCCESS = `${UI} SET_ACCIDENT_SUCCESS`
export const SET_VALIDATE_DOCUMENT_KOFAX_SUCCESS = `${UI} SET_VALIDATE_DOCUMENT_KOFAX_SUCCESS`
export const SET_VALIDATE_DOCUMENTS_PENDING = `${UI} SET_VALIDATE_DOCUMENTS_PENDING`
export const SET_DOCUMENTS_NEEDEED_FOR_ACCIDENT = `${UI} SET_DOCUMENTS_NEEDEED_FOR_ACCIDENT`
export const SET_CONFIRME_VIREMENT = `${UI} SET_CONFIRME_VIREMENT`
export const SET_VALIDATE_POST_ACCIDENT = `${UI} SET_VALIDATE_POST_ACCIDENT`
export const SET_CHANGE_ADDRESS = `${UI} SET_CHANGE_ADDRESS`
export const SET_NB_TASKS = `${UI} SET_NB_TASKS`
export const SET_FILTER = `${UI} SET_FILTER`
export const SET_ADD_EXPERT_REPORT = `${UI} SET_ADD_EXPERT_REPORT`
export const SET_HIVERNAGE_SUCCESS = `${UI} SET_HIVERNAGE_SUCCESS`
export const SET_HIVERNAGE_REDUCTION = `${UI} SET_HIVERNAGE_REDUCTION`
export const SET_UPDATE_ANTECEDENT_PERIOD = `${UI} SET_UPDATE_ANTECEDENT_PERIOD`


export const setLoader = ({state, entity}) => ({
    type: `${entity} ${SET_LOADER}`,
    payload: state,
    meta: entity
})

export const setError = ({state, entity}) => ({
    type: `${entity} ${SET_ERROR}`,
    payload: state,
    meta: entity
})

export const setIsLogin = ({state, entity}) => ({
    type: `${entity} ${SET_IS_LOGIN}`,
    payload: state
})

export const setIsLogout = ({state, entity}) => ({
    type: `${entity} ${SET_IS_LOGOUT}`,
    payload: state
})

export const setMaintenance = ({state, entity}) => ({
    type: `${entity} ${SET_MAINTENANCE}`,
    payload: state
})

export const setAccidentSuccess = ({idAccident, idContract}) => ({
    type: `${POST_ACCIDENT} ${SET_ACCIDENT_SUCCESS}`,
    payload: {
        idAccident,
        idContract
    }
})

export const setValidateDocumentKofax = ({state, formName}) => ({
    type: `${SET_VALIDATE_DOCUMENT_KOFAX_SUCCESS}`,
    payload: {
        formName,
        state
    }
})

export const setValidateDocumentsPending = ({form, index}) => ({
    type: `${SET_VALIDATE_DOCUMENTS_PENDING}`,
    payload: {
        form,
        index
    }
})

export const setDocumentsNeededForAccident = ({documents}) => ({
    type: SET_DOCUMENTS_NEEDEED_FOR_ACCIDENT,
    payload: {
        data: documents
    }
})

export const setConfirmeVirement = ({state}) => ({
    type: SET_CONFIRME_VIREMENT,
    payload: state
})

export const setValidatePostAccident = ({state}) => ({
    type: SET_VALIDATE_POST_ACCIDENT,
    payload: state
})

export const setChangeAddress = ({data, id}) => ({
    type: SET_CHANGE_ADDRESS,
    payload: {
        data, id
    }
})

export const setNbTasks = ({data}) => ({
    type: SET_NB_TASKS,
    payload: {
        data
    }
})

export const setFilters = ({entity, filters}) => ({
    type: `${entity} ${SET_FILTER}`,
    payload: {
        ...filters
    },
    meta: entity
})

export const setAddExpertReport = ({state}) => ({
    type: SET_ADD_EXPERT_REPORT,
    payload: state
})

export const setHivernageSuccess = ({state}) => ({
    type: SET_HIVERNAGE_SUCCESS,
    payload: state
})

export const setHivernageReduction = ({state}) => ({
    type: SET_HIVERNAGE_REDUCTION,
    payload: state
})

export const setUpdateAntecedentPeriod = ({state}) => ({
    type: SET_UPDATE_ANTECEDENT_PERIOD,
    payload: state
})
