import React, {useEffect, useState} from 'react';
import MyButton from "../../../../../Commun/Button/Button";
import _ from "lodash";
import {formatThousandSpace} from "../../../../../../utils/function";
import moment from "moment";


const DashboardPaniereRow = (props) => {
    const {emetteur, uri, getManagerPaniereDetail, detail} = props
    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (open && detail !== []) {
            getManagerPaniereDetail({query: _.replace(uri, '{paniere}', emetteur.paniere), emetteur: emetteur.paniere})
        }
    }, [open])

    const returnClassname = (date) => {
        let diff = moment(moment().format("YYYY-MM-DD")).diff(date, 'days');

        if (diff > 60) {
            return 'alert-danger'
        } else if (diff <= 60 && diff >= 30) {
            return 'alert-warning'
        } else if (diff < 30) {
            return 'alert-prewarning'
        }
    }

    return (
        <>
            <tr>
                <td>{emetteur.paniere}</td>
                <td>{emetteur.pole}</td>
                <td>{formatThousandSpace(emetteur.nbPopups)}</td>
                <td><span
                    className={`badge-date ${returnClassname(emetteur.date_last)}`}>{moment(emetteur.date_last).format('L')}</span>
                </td>
                <td>
                    {emetteur.nbPopups > 1 &&
                        <MyButton
                            className={'btn btn-rounded'}
                            onClick={() => setOpen(!open)}
                        >
                            {!open ? '+' : '-'}
                        </MyButton>
                    }
                </td>
            </tr>
            {open &&
                <>
                    <tr className={'bg-white'}>
                        <td></td>
                        <td><strong>Nombre de popups</strong></td>
                        <td colSpan={3}><strong>Date</strong></td>
                    </tr>
                    {detail !== [] && _.orderBy(detail, ['date'], ['asc']).map((d, index) =>
                        <tr key={index} className={'bg-white'}>
                            <td className={'border-top-0'}></td>
                            <td>{d.nbPopups}</td>
                            <td colSpan={3}>{moment(d.date).format('L')}</td>
                        </tr>
                    )}
                </>
            }
        </>
    );
};


DashboardPaniereRow.defaultProps = {};


DashboardPaniereRow.propTypes = {};


export default DashboardPaniereRow;
