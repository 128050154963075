import {
    GET_DOCUMENTS_UTILES,
    setDocumentsUtiles,
    DOCUMENTS_UTILES
} from '../../../actions/app/documents_utiles/documents_utiles.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setIsLogin, setLoader} from '../../../actions/app/ui/ui.actions'

export const documentsUtilesMiddleware = () => next => action => {
    next(action)

    switch (action.type) {

        case GET_DOCUMENTS_UTILES:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: DOCUMENTS_UTILES}),
                setLoader({state: true, entity: DOCUMENTS_UTILES})
            ])
            break

        case `${DOCUMENTS_UTILES} ${API_SUCCESS}`:
            next([
                setIsLogin({state: true, entity: DOCUMENTS_UTILES}),
                setDocumentsUtiles({documents_utiles: action.payload.data}),
                setError({state: false, entity: DOCUMENTS_UTILES}),
                setLoader({state: false, entity: DOCUMENTS_UTILES})
            ])
            break

        case `${DOCUMENTS_UTILES} ${API_ERROR}`:
            next([
                setIsLogin({state: false, entity: DOCUMENTS_UTILES}),
                setError({state: true, entity: DOCUMENTS_UTILES}),
                setLoader({state: false, entity: DOCUMENTS_UTILES})
            ])
            break

        default:
            break
    }
    return null
}
