import React from 'react'
import moment from "moment";

const RappelNPAI = (props) => {
    const {task: {date, type, paniere, tiers_id}} = props

    return (
        <div className={'container'}>
            <div className={'row'}>
                <div className={'col text-center'}>
                    <h2 className={'title-primary text-uppercase'}>Tâche {paniere} - Tiers n°{tiers_id}</h2>
                    <p className={'subtitle'}>Tâche du {moment(date).format('L')}. Recu par {type}</p>
                </div>
            </div>
            <div className={'header-row row'}>
                <div className={'col-12'}>
                    <p className={'form-content text-center'}>
                        Un email a été envoyé au client/courtier mais celui-ci n'a pas pu être transmis.<br/>
                        Merci de vérifier l'adresse email du Tiers pour voir si celle-ci est valide ou non.<br/><br/>
                        Si l’adresse email n’est pas valide, il faut appeler le client ou courtier et la modifier en
                        utilisant la fonction “Modifier les coordonnées” sur la fiche du Tiers.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default RappelNPAI
