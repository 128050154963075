import React from 'react';
import {getBoolean} from "../../../../utils/function";

const ActivitiesListContractFilters = (props) => {
    const {uriActivity, type, getActivities} = props
    const natureContractDE = getBoolean(localStorage.getItem('task_doc_nature_contract_DE'));
    const typeContractFID = getBoolean(localStorage.getItem('task_doc_type_contract_FID'));
    const interlocContractAN = getBoolean(localStorage.getItem('task_doc_interlocuteur_contract_AN'));

    const getActivitiesPost = () => {
        getActivities({
            query: uriActivity, type, filters: {
                doc_nature_contract_DE: localStorage.getItem('task_doc_nature_contract_DE'),
                doc_type_contract_FID: localStorage.getItem('task_doc_type_contract_FID'),
                doc_interlocuteur_contract_AN: localStorage.getItem('task_doc_interlocuteur_contract_AN'),
            }
        });
        window.scrollTo(0, 0)
    }

    const handleClearFilters = () => {
        localStorage.setItem('task_doc_nature_contract_DE', false)
        localStorage.setItem('task_doc_type_contract_FID', false)
        localStorage.setItem('task_doc_interlocuteur_contract_AN', false)
        getActivitiesPost();
    }

    return (
        <>
            <div className={'d-flex justify-content-between mt-3'}>
                <small><u>Nature de l'activité :</u></small>
                <small onClick={handleClearFilters} className={'pointer'}><u>Réinitialiser les filtres</u></small>
            </div>
            <div className={'d-inline-block'}>
                <div className={'styled-checkbox'}>
                    <input
                        type="checkbox"
                        name="filter_doc_nature_DE"
                        id="docNatureDE"
                        onChange={() => {
                            localStorage.setItem(`task_doc_nature_contract_DE`, !natureContractDE);
                            getActivitiesPost();
                        }}
                        value={natureContractDE}
                        defaultChecked={natureContractDE}
                    />
                    <label htmlFor="docNatureDE">Dérogation</label>
                </div>
            </div>

            <div className={'d-inline-block ml-2'}>
                <div className={'styled-checkbox'}>
                    <input
                        type="checkbox"
                        name="filter_doc_type_FID"
                        id="docTypeFID"
                        onChange={() => {
                            localStorage.setItem(`task_doc_type_contract_FID`, !typeContractFID);
                            getActivitiesPost();
                        }}
                        value={typeContractFID}
                        defaultChecked={typeContractFID}
                    />
                    <label htmlFor="docTypeFID">Fidélisation</label>
                </div>
            </div>

            <div className={'d-inline-block ml-2'}>
                <div className={'styled-checkbox'}>
                    <input
                        type="checkbox"
                        name="filter_doc_interloc_AN"
                        id="docInterlocAN"
                        onChange={() => {
                            localStorage.setItem(`task_doc_interlocuteur_contract_AN`, !interlocContractAN);
                            getActivitiesPost();
                        }}
                        value={interlocContractAN}
                        defaultChecked={interlocContractAN}
                    />
                    <label htmlFor="docInterlocAN">Chrono AN</label>
                </div>
            </div>
        </>
    );
};

export default ActivitiesListContractFilters;
