import React from 'react';
import _ from "lodash";

const DerogationItem = (props) => {
    const { derogation, isManager, onSubmitDelete, loading } = props;
    const isFidelisationTheme = derogation.theme === 'Fidélisation' || derogation.theme === 'Fidélisation - Revue de contrat';

    return (
        <tr key={derogation.id}>
            <td>{new Date(derogation.date).toLocaleDateString()}</td>
            <td>{derogation.theme}</td>
            <td>{derogation.motif}</td>
            <td>{derogation.montant}</td>
            {/*On ne permet pas la suppression des gestes fidélisation*/}
            {(isManager &&  !isFidelisationTheme && !_.includes(['carte cadeau', 'carte carburant'], derogation.motif.toLowerCase())) && (
                <td>
                    <button
                        type='button'
                        onClick={(e) => {
                            if (!loading && window.confirm(`Êtes-vous sûr(e) de vouloir supprimer cette dérogation ?`)) {
                                e.preventDefault();
                                onSubmitDelete();
                            }
                        }}
                        className={'btn btn-delete-red'}
                    />
                </td>
            )}
        </tr>
    );
};

export default DerogationItem;
