import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {reduxForm} from 'redux-form'
import {complainEditDocumentLink} from '../../../../../redux/selectors/links/links.selectors'
import {getLoadingEntity} from '../../../../../redux/selectors/ui/ui.selectors'
import _ from "lodash";
import EditDocument from "./EditDocument";
import {
    POST_EDIT_DOCUMENT_RECLAMATION,
    postEditDocumentReclamation
} from "../../../../../redux/actions/app/reclamation/reclamation.actions";

const mapStateToProps = (state, ownProps) => {
    return {
        loading: getLoadingEntity(state, POST_EDIT_DOCUMENT_RECLAMATION),
        uri: complainEditDocumentLink(state),
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    submitPostEditDocumentReclamation: ({form, query}) => postEditDocumentReclamation({form, query})
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {submitPostEditDocumentReclamation} = dispatchProps
    const {uri} = stateProps
    const {id, ids} = ownProps.match.params

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form) => submitPostEditDocumentReclamation({
            query: _.replace(_.replace(uri, '{id}', id), '{ids}', ids),
            form
        })
    }
}
const EditDocumentContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'editDocumentComplain',
    })
)(EditDocument)

export default EditDocumentContainer
