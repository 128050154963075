import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {reduxForm} from 'redux-form'
import {API_END_POINT} from '../../../constants/constants'
import Login from './Login'
import validate from './validate'
import {getErrorEntity, getLoading, getLoadingEntity, getLogin} from '../../../redux/selectors/ui/ui.selectors'
import _ from "lodash";
import {getFicheLink, userGetLink} from "../../../redux/selectors/links/links.selectors";
import {LOGIN, postLogin} from "../../../redux/actions/app/login/login.actions";
import {getFiche} from "../../../redux/actions/app/fiche/fiche.actions";
import {getDataFiche} from "../../../redux/selectors/fiche/fiche.selector";
import {getUser, USER} from "../../../redux/actions/app/user/user.actions";
import {localStorageAMO} from "../../../redux/selectors/localStorage/localStorage";
import loader from "../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    return {
        fiche: getDataFiche(state),
        queryFiche: getFicheLink(state),
        loading: getLoadingEntity(state, LOGIN),
        errorState: getErrorEntity(state, LOGIN),
        login: getLogin(state),
        urlUser: userGetLink(state),
        loaded: getLoading(state) !== undefined && !getLoadingEntity(state, USER),
        token: localStorageAMO && localStorageAMO.token ? localStorageAMO.token : null,
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    onSubmit: (form) => postLogin({
        username: form.username,
        password: form.password,
        query: API_END_POINT
    }),
    getFiche,
    getUser,
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getFiche, getUser} = dispatchProps
    const {queryFiche, urlUser, token} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => {
            getFiche({query: _.replace(queryFiche, '/{id?}', '')})
            if (token) getUser({query: urlUser})
        }
    }
}

const LoginContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'loginForm',
        validate
    }),
    loader()
)(Login)

export default LoginContainer
