import React from 'react';
import {Redirect} from 'react-router-dom'
import InitFormContainer from "./InitForm/InitFormContainer";
import InitFormFleetContainer from "./InitFormFleet/InitFormFleetContainer";

const Constat = (props) => {
    const {contract, accidentAlreadyPost, validatePostAccident, isFleet, idVehicule} = props

    return (
        <div>
            {
                accidentAlreadyPost &&
                (Number(accidentAlreadyPost.idContract) === contract.id)

                    ? <Redirect
                        to={{
                            pathname: `/contrat/${accidentAlreadyPost.idContract}/sinistre/${accidentAlreadyPost.idAccident}`,
                            state: {
                                souscripteur: contract.souscripteur
                            }
                        }}
                    />
                    : validatePostAccident ?
                        <div className={'row margin-top text-center'}>
                            <div className={'col'}>
                                <h2 className={'title-primary title-success'}>Votre sinistre a bien été
                                    enregistré&nbsp;!</h2>
                            </div>
                        </div>
                        : isFleet ? <InitFormFleetContainer contract={contract} idVehicule={idVehicule}/> :
                            <InitFormContainer contract={contract} idVehicule={idVehicule}/>
            }
        </div>

    );
};

export default Constat;
