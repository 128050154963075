export const ANTECEDENTS = '[Antecedents]'
export const ANTECEDENT_PERIOD = '[Antecedent period]'
export const ANTECEDENTS_MOTO = '[Antecedents Moto]'
export const ANTECEDENTS_AUTO = '[Antecedents Auto]'

export const GET_ANTECEDENTS = `${ANTECEDENTS} Get`
export const SET_ANTECEDENTS = `${ANTECEDENTS} Set`

export const GET_ANTECEDENT_PERIOD = `${ANTECEDENT_PERIOD} Get`
export const SET_ANTECEDENT_PERIOD = `${ANTECEDENT_PERIOD} Set`
export const POST_UPDATE_ANTECEDENT_PERIOD = `${ANTECEDENT_PERIOD} Post Update`
export const POST_CREATE_ANTECEDENT = `${ANTECEDENT_PERIOD} Post Create`

export const getAntecedents = ({query, type}) => ({
    type: GET_ANTECEDENTS,
    payload: {
        data: query,
        otherData: {
            type: type
        }
    }
})

export const setAntecedents = ({antecedents, type}) => ({
    type: SET_ANTECEDENTS,
    payload: {
        data: antecedents,
        type: type
    }
})

export const getAntecedentPeriod = (query) => ({
    type: GET_ANTECEDENT_PERIOD,
    payload: {
        data: query
    }
})

export const setAntecedentPeriod = ({data}) => ({
    type: SET_ANTECEDENT_PERIOD,
    payload: {data},
})

export const postUpdateAntecedentPeriod  = ({form, query}) => ({
    type: POST_UPDATE_ANTECEDENT_PERIOD,
    payload: {
        data: query,
        body: form
    }
})

export const postCreateAntecedent  = ({form, query}) => ({
    type: POST_CREATE_ANTECEDENT,
    payload: {
        data: query,
        body: form
    }
})