import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {reduxForm} from 'redux-form'
import {CHANGE_PASSWORD, postChangePassword} from '../../../../../redux/actions/app/password/password.actions'
import {changePasswordLink} from '../../../../../redux/selectors/links/links.selectors'
import {getLoadingEntity} from '../../../../../redux/selectors/ui/ui.selectors'
import validate from './validate'
import ChangePassword from "./ChangePassword";

const mapStateToProps = (state, ownProps) => {
    return {
        loading: getLoadingEntity(state, CHANGE_PASSWORD),
        uri: changePasswordLink(state),
        tiers: ownProps.location.state.tiers,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    submitPostChangePassword: ({email, password, password_confirmation, query}) => postChangePassword({
        email,
        password,
        password_confirmation,
        query
    })
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {submitPostChangePassword} = dispatchProps
    const {uri, tiers} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form) => submitPostChangePassword({
            email: tiers.address_mail,
            password: form.password,
            password_confirmation: form.password_confirmation,
            query: uri
        })
    }
}
const ChangePasswordContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'changePasswordForm',
        validate
    })
)(ChangePassword)

export default ChangePasswordContainer
