import _ from 'lodash';
import { Fragment, useState } from 'react';
import { listMailJet, openInWindow } from '../../../../../constants/constants';
import { redirectInNewWindow } from '../../../../../utils/function';
import CallToAction from '../../../../Commun/Button/CallToAction/CallToAction';
import ButtonsCourtier from './Courtier/ButtonsCourtier';
import ButtonsGarage from './Garage/ButtonsGarage';

const Buttons = ({
    tiers, publishUrl, postLogActionFormRib, permissions, roles, postAccount, postLogActionCreateAccount, uri_demat,
    postLogActionChangePassword, postLogActionEditDocument, owner_belair_id, postDeleteAccount, postDematStatut
}) => {
    const [disabledBtnAccount, setDisabledBtnAccount] = useState(false);

    const handleClick = () => {
        postAccount({
            email: tiers.address_mail,
            owner_belair_id: owner_belair_id,
            nom: tiers.lastname,
            date_naissance: tiers.date_naissance,
            belair_id: tiers.belair_id,
        });
        postLogActionCreateAccount();
        setDisabledBtnAccount(true);
    };

    const handleClickDeleteAccount = () => {
        postDeleteAccount();
        setDisabledBtnAccount(true);
    };

    const handleClickChangePassword = () => {
        postLogActionChangePassword();
    };

    const handleClickEditDocument = () => {
        postLogActionEditDocument();
    };

    const [dematStatus, setDematStatus] = useState(tiers.statut_demat);
    const handleDematChange = () => {
        const newDematStatus = dematStatus === 'T' ? 'F' : 'T';

        postDematStatut({
            query: uri_demat,
            form: { b_stopemail: newDematStatus, tiers: tiers.belair_id },
        });
    };

    return (
        <Fragment>
            {!_.includes(roles, 'gestionnaire televente') && (
                <div className={'buttons'}>
                    {!['7'].includes(tiers.type) && ['M', 'G', 'MY', 'R', 'V', '9', 'P', 'W'].includes(tiers.type) && (
                        <ButtonsGarage tiers={tiers} postLogRib={postLogActionFormRib} />
                    )}
                    {tiers.type === '7' && <ButtonsCourtier tiers={tiers} />}

                    {_.includes(permissions, 'wallet extraction') && !_.includes(tiers.type, '1') && !['V', '9', 'P', 'W'].includes(tiers.type) && (
                        <CallToAction
                            action={'create-extraction'}
                            toolText={'Extraction portefeuille'}
                            onClick={(e) =>
                                redirectInNewWindow({
                                    e,
                                    url: `/tiers/${tiers.belair_id}/extraction-portefeuille`,
                                    name: openInWindow.extraction,
                                })
                            }
                        />
                    )}

                    {_.includes(permissions, 'wallet transfert') && !_.includes(tiers.type, '1') && !['V', '9', 'P', 'W'].includes(tiers.type) && (
                        <CallToAction
                            action={'create-transfert'}
                            toolText={'Transfert de portefeuille'}
                            onClick={(e) =>
                                redirectInNewWindow({
                                    e,
                                    url: `/tiers/${tiers.belair_id}/transfert-portefeuille`,
                                    name: openInWindow.transfert,
                                })
                            }
                        />
                    )}

                    {_.includes(permissions, 'order flyers') &&
                        (_.includes(tiers.type, 'M') || tiers.type === '7' || tiers.type === 'J') && (
                            <CallToAction
                                url={{ pathname: ``, state: { tiers: tiers } }}
                                action={'order-flyers'}
                                toolText={'Commande de produits'}
                                onClick={(e) =>
                                    redirectInNewWindow({
                                        e,
                                        url: `/tiers/${tiers.belair_id}/commande`,
                                        name: openInWindow.commande,
                                    })
                                }
                            />
                        )}

                    {(tiers.type === '1' || tiers.type === '1P') && (
                        <>
                            {!tiers.has_account ? (
                                <CallToAction
                                    link_externe
                                    action={'create-account'}
                                    toolText={"Créer l'espace assuré"}
                                    onClick={() => {
                                        handleClick();
                                    }}
                                    disabled={disabledBtnAccount || tiers.address_mail === ''}
                                    disabledReason={
                                        tiers.address_mail === '' && "Le tiers n'a pas d'adresse email renseigné"
                                    }
                                />
                            ) : (
                                <CallToAction
                                    link_externe
                                    action={'delete-account'}
                                    toolText={"Supprimer l'espace assuré"}
                                    onClick={() => {
                                        handleClickDeleteAccount();
                                    }}
                                    disabled={disabledBtnAccount}
                                />
                            )}
                            <CallToAction
                                url={{ pathname: `/tiers/${tiers.belair_id}/mot-de-passe`, state: { tiers: tiers } }}
                                action={'mdp-client'}
                                toolText={'Modifier son mot de passe'}
                                onClick={() => {
                                    handleClickChangePassword();
                                }}
                                disabled={!tiers.has_account}
                                disabledReason={!tiers.has_account && "Le tiers n'a pas d'espace assuré"}
                            />
                            <CallToAction
                                link_externe
                                action={'edit-document'}
                                toolText={'Editer un document'}
                                url={`${publishUrl}&tiers=${tiers.belair_id}`}
                                publish
                                onClick={() => {
                                    handleClickEditDocument();
                                }}
                            />
                            <CallToAction
                                url={{
                                    pathname: `/tiers/${tiers.belair_id}/modifier-informations`,
                                    state: { tiers: tiers },
                                }}
                                action={'coordonnees'}
                                toolText={'Modifier les coordonnées'}
                            />
                            <CallToAction
                                url={{
                                    pathname: `/tiers/${tiers.belair_id}/modifier-informations-naissance`,
                                    state: { tiers: tiers.belair_id },
                                }}
                                action={'calendar'}
                                toolText={'Modifier les informations règlementaires'}
                            />
                            <CallToAction
                                action={'add-chrono'}
                                toolText={'Ajouter un chrono / agenda'}
                                onClick={(e) =>
                                    redirectInNewWindow({
                                        e,
                                        url: `/tiers/${tiers.belair_id}/ajout-suivi`,
                                        name: openInWindow.addActivities,
                                    })
                                }
                            />
                        </>
                    )}
                    <CallToAction
                        action={'email'}
                        toolText={'Envoyer un email'}
                        onClick={(e) =>
                            redirectInNewWindow({
                                e,
                                url: `/envoyer-email/libre/${listMailJet.libre}/${tiers.belair_id}`,
                                name: `${openInWindow.emailTiers}${tiers.belair_id}`,
                            })
                        }
                    />
                    <CallToAction
                        action={'tracfin'}
                        toolText={'Tracfin'}
                        onClick={(e) =>
                            redirectInNewWindow({
                                e,
                                url: `/tiers/${tiers.belair_id}/tracfin`,
                                name: openInWindow.tracfin,
                            })
                        }
                    />
                    <CallToAction
                        link_externe
                        action={'change-demat'}
                        toolText={"Mise à jour dématéralisation"}
                        onClick={() => {
                            handleDematChange();
                        }}
                        disabled={disabledBtnAccount}
                    />
                </div>
            )}
        </Fragment>
    );
};

export default Buttons;
