import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import NavigationActivity from "./NavigationActivity";
import {getErrorEntity, getLoadingEntity} from "../../../../redux/selectors/ui/ui.selectors";
import {navigationActivitiesGetLink} from "../../../../redux/selectors/links/links.selectors";
import _ from "lodash";

import ErrorHttpComponent from "../../Error/ErrorHttpComponent";
import {
    getNavigationActivities,
    NAVIGATION_ACTIVITIES
} from "../../../../redux/actions/app/navigation_activities/navigation_activities.actions";
import {
    getNavigationActivitiesData
} from "../../../../redux/selectors/navigation_activities/navigation_activities.selector";
import loader from "../../Spinner/Loader/Loader";
import {getUserRoles} from "../../../../redux/selectors/user/user.selectors";

const mapStateToProps = (state, ownProps) => {
    return {
        tiers_id: ownProps.tiers_id,
        navigation_activities: getNavigationActivitiesData(state),
        uri: navigationActivitiesGetLink(state),
        loaded: getLoadingEntity(state, NAVIGATION_ACTIVITIES) === false,
        error: getErrorEntity(state, NAVIGATION_ACTIVITIES),
        roles: getUserRoles(state),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getNavigationActivities,
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uri, tiers_id} = stateProps
    const {getNavigationActivities} = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getNavigationActivities({query: _.replace(uri, '{id}', tiers_id)})
    }
}


const NavigationActivityContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(NavigationActivity)


export default NavigationActivityContainer
