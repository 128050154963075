import React from 'react';
import {Field} from "redux-form";
import Spinner from "../../../../Commun/Spinner/Spinner";
import TextFieldRedux from "../../../../Commun/Input/Text/TextFieldRedux";


const ChangeConducteur = (props) => {
    const {loading, handleSubmit} = props

    return (
        <div className={'contract-container container'}>
            <div className={'row return-content'}>
                <div className={'col'}>
                    <button onClick={() => props.history.go(-1)} className={'btn btn-return'}>Retour</button>
                </div>
            </div>
            <div className={'row header-row'}>
                <div className={'col-12'}>
                    <form className={'form-content'} onSubmit={handleSubmit}>
                        <Field
                            name={'conducteur'}
                            component={TextFieldRedux}
                            label={'Prénom et nom du conducteur'}
                        />

                        {
                            loading ? <Spinner style={{display: 'block', margin: 'auto'}}/> :
                                <button className={'btn btn-primary d-block'} type={'submit'}>
                                    Mettre à jour
                                </button>
                        }
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ChangeConducteur;
