export const APPORTEURS = '[Apporteurs]'

export const GET_APPORTEURS = `${APPORTEURS} Get`
export const SET_APPORTEURS = `${APPORTEURS} Set`

export const getApporteurs = ({query}) => ({
    type: GET_APPORTEURS,
    payload: {
        data: query
    }
})

export const setApporteurs = ({apporteurs}) => ({
    type: SET_APPORTEURS,
    payload: {
        data: apporteurs
    }
})
