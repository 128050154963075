import React, {useState} from 'react';
import ChoixProduits from "./ChoixProduits/ChoixProduits";
import Spinner from "../../../../Commun/Spinner/Spinner";
import _ from "lodash";
import Informations from "./Informations/Informations";

const CommandeProduits = (props) => {
    const {handleSubmit, commande, produits, putLoading, putError} = props
    const [step, setStep] = useState(1)
    let putSuccess = putLoading === false && putError === false

    commande && Object.keys(commande).map(k => {
        if (!commande[k]) {
            return delete commande[k]
        }
        return false
    })

    return (
        <div className={'contract-container container'}>
            <div className={'row'}>
                <div className={'col-12'}>
                    <h1 className={'title-primary'}>Commande <span className={'text-color-secondary'}>de supports publicitaires</span> et <span
                        className={'text-color-secondary'}>dispositions générales</span> en ligne</h1>
                </div>
                <div className="col-12 header-row">
                    <form onSubmit={handleSubmit} className={`form-content`}>
                        {
                            !putSuccess && step === 1 &&
                            <div>
                                <ChoixProduits {...props}/>

                                <span
                                    className={'d-inline-block btn btn-primary mt-5 float-left max-rounded'}
                                    onClick={() => setStep(2)}
                                >
                                    Je valide ma commande
                                </span>
                            </div>
                        }

                        {
                            !putSuccess && step === 2 &&
                            <>
                                {
                                    !putSuccess && <Informations commande={commande} produits={produits}/>
                                }

                                {
                                    putLoading ?
                                        <Spinner style={{display: 'block', margin: 'auto'}}/> :
                                        <div>
                                        <span
                                            className={'d-inline-block btn btn-secondary mt-5'}
                                            onClick={() => setStep(1)}
                                        >
                                            Retour
                                        </span>
                                            <button
                                                className={`btn btn-primary d-inline-block mt-5`}
                                                disabled={_.keys(commande).length === 0}
                                            >
                                                Je valide ma commande
                                            </button>
                                        </div>
                                }
                            </>
                        }

                        {
                            putSuccess &&
                            <div className={'mt-5'}>
                                <p>Nous vous remercions pour votre commande.</p>
                                <p>Votre commande sera livrée dans un délai de 4 jours ouvrés.</p>
                            </div>
                        }
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CommandeProduits;
