import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import RapportExpert from './RapportExpert'
import {RAPPORT_BCA, getRapportBca, postDeleteRapportBca} from "../../../../../redux/actions/app/bca/bca.actions";
import {getErrorEntity, getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {
    deleteExpertReportAccidentGetLink,
    rapportBcaGetLink
} from "../../../../../redux/selectors/links/links.selectors";
import {getBcaData} from "../../../../../redux/selectors/bca/bca.selectors";
import _ from "lodash";
import ErrorHttpComponent from "../../../../Commun/Error/ErrorHttpComponent";
import {getUserPermissions} from "../../../../../redux/selectors/user/user.selectors";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    return {
        uri_delete: deleteExpertReportAccidentGetLink(state),
        permissions: getUserPermissions(state),
        rapports_bca: getBcaData(state),
        uri: rapportBcaGetLink(state),
        loaded: getLoadingEntity(state, RAPPORT_BCA) === false,
        error: getErrorEntity(state, RAPPORT_BCA)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getRapportBca: (query) => getRapportBca({query}),
    postDeleteRapportBca
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { uri, uri_delete } = stateProps
    const { getRapportBca, postDeleteRapportBca } = dispatchProps
    const { idContract, idAccident } = ownProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getRapportBca(_.replace(_.replace(uri, '{id}', idContract), '{ids}', idAccident)),
        submitDelete: (id) => postDeleteRapportBca({id: id, query: _.replace(_.replace(uri_delete, '{id}', idContract), '{ids}', idAccident)}),
    }
}

const RapportExpertContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(RapportExpert)

export default RapportExpertContainer
