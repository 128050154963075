import React from 'react'
import moment from "moment";

const AVKO = (props) => {
    const {task: {date, type, paniere, tiers_id}} = props

    return (
        <div className={'container'}>
            <div className={'row'}>
                <div className={'col text-center'}>
                    <h2 className={'title-primary text-uppercase'}>{paniere} - Tiers n°{tiers_id}</h2>
                    <p className={'subtitle'}>Tâche du {moment(date).format('L')}. Recu par {type}</p>
                </div>
            </div>
            <div className={'header-row row'}>
                <div className={'col-12'}>
                    <div className={'form-content text-center'}>
                        <ul className={'text-left my-3'}>
                            <li>Passer le contrat en cours</li>
                            <li>Effectuer une quittance de REGUL sur la pièce précédente à date d'effet de l'avenant + Effectuer une quittance AVENANT sur la dernière pièce</li>
                            <li>Si le contrat est en paiement annuel, chèque ou prélèvement unique : solder les quittances négatives</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AVKO
