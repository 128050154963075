import React, {Fragment} from 'react'
import ButtonsContainer from "../Buttons/ButtonsContainer";
import {redirectInNewWindow} from "../../../../../utils/function";
import InfoGarage from "./InfoGarage/InfoGarage";
import CorrespondantsContainer from "./Correspondants/CorrespondantsContainer";
import {openInWindow} from "../../../../../constants/constants";
import ReclamationsContainer from "../../Reclamations/ReclamationsContainer";
import ChequesCadeauxContainer from "../Courtier/ChequesCadeaux/ChequesCadeauxContainer";
import AccidentsContainer from "./Accidents/AccidentsContainer";
import _ from "lodash";

const Garage = (props) => {
    const {tiers, roles} = props

    return (
        <Fragment>
            <div className={'row'}>
                <div className={'col'}>
                    <h1 className={'title-primary'}>{tiers.belair_id} - {tiers.titre} {tiers.name}</h1>
                    {!_.includes(roles, 'gestionnaire televente') &&
                        <span className={'btn btn-cta btn-action float-right'} onClick={(e) => redirectInNewWindow({
                            e,
                            url: `/les-activites/tiers/${tiers.belair_id}`,
                            name: openInWindow.activitiesTiers
                        })}>Les activités du tiers</span>}
                </div>
                <div className={'col-12 mt-3'}>
                    <ButtonsContainer tiers={tiers}/>
                </div>
            </div>
            <div className={'row margin-top'}>
                <div className={'col-6'}>
                    <InfoGarage tiers={tiers}/>
                </div>
            </div>
            <div className={'row margin-top'}>
                <div className={'col'}>
                    <CorrespondantsContainer className={'loader'} id={tiers.belair_id}/>
                </div>
            </div>
            <div className="row margin-top">
                <div className="col">
                    <ReclamationsContainer className={'loader'} id={tiers.belair_id}/>
                </div>
            </div>
            {
                (tiers.type === "J" || tiers.type === "D") && <div className={'row margin-top'}>
                    <div className={'col'}>
                        <ChequesCadeauxContainer id={tiers.belair_id}/>
                    </div>
                </div>
            }
            <div className={'row margin-top'}>
                <div className={'col'}>
                    <AccidentsContainer className={'loader'}/>
                </div>
            </div>
        </Fragment>
    )
}

export default Garage
