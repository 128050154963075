import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import VirementsList from './VirementsList'
import ErrorHttpComponent from '../../../../../Commun/Error/ErrorHttpComponent'
import {
    getErrorEntity,
    getLoadingEntity,
    getValidateVirementProd
} from '../../../../../../redux/selectors/ui/ui.selectors'
import {
    getVirementsProd,
    postValidateListVirements,
    VIREMENTS_PROD
} from "../../../../../../redux/actions/app/virements/virements.actions";
import {getVirementsData, getVirementsMeta} from "../../../../../../redux/selectors/virements/virements.selectors";
import {validateVirementsProdGetLink, virementsGetLink} from "../../../../../../redux/selectors/links/links.selectors";
import loader from "../../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    return {
        virements: getVirementsData(state),
        meta: getVirementsMeta(state),
        loaded: getLoadingEntity(state, VIREMENTS_PROD) === false,
        loading: getLoadingEntity(state, VIREMENTS_PROD),
        error: getErrorEntity(state, VIREMENTS_PROD),
        uri: validateVirementsProdGetLink(state),
        confirmPostVirement: getValidateVirementProd(state),
        queryGetVirements: virementsGetLink(state),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    postValidateListVirements: ({virements, query}) => postValidateListVirements({virements, query}),
    getVirementsProd: ({query}) => getVirementsProd({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {postValidateListVirements, getVirementsProd} = dispatchProps
    const {uri, queryGetVirements} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        postValidateListVirements: ({virements}) => postValidateListVirements({virements, query: uri}),
        getVirementsProd: () => getVirementsProd({query: queryGetVirements})
    }
}

const VirementsListContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(VirementsList)

export default VirementsListContainer
