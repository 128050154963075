import React, {Fragment} from 'react'
import ButtonsContainer from "../Buttons/ButtonsContainer";
import {redirectInNewWindow} from "../../../../../utils/function";
import InfoCompagnie from "./InfoCompagnie/InfoCompagnie";
import {openInWindow} from "../../../../../constants/constants";
import _ from "lodash";

const Compagnie = (props) => {
    const {tiers, roles} = props

    return (
        <Fragment>
            <div className={'row'}>
                <div className={'col'}>
                    <h1 className={'title-primary'}>{tiers.belair_id} - {tiers.titre} {tiers.name}</h1>
                    {!_.includes(roles, 'gestionnaire televente') &&
                        <span className={'btn btn-cta btn-action float-right'} onClick={(e) => redirectInNewWindow({
                            e,
                            url: `/les-activites/tiers/${tiers.belair_id}`,
                            name: openInWindow.activitiesTiers
                        })}>Les activités du tiers</span>}
                </div>
                <div className={'col-12 mt-3'}>
                    <ButtonsContainer tiers={tiers}/>
                </div>
            </div>
            <div className={'row margin-top'}>
                <div className={'col-6'}>
                    <InfoCompagnie tiers={tiers}/>
                </div>
            </div>
        </Fragment>
    )
}

export default Compagnie
