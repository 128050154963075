import CallToAction from "../../../../Commun/Button/CallToAction/CallToAction";
import React, {Fragment} from "react";

const Temoin = props => {
    const {temoin, contract, accident} = props

    return (
        <div className={'bloc-info bloc-intervenants'}>
            <h2>Témoin</h2>
            <Fragment>
                {temoin?.id ?
                    <CallToAction
                        url={{
                            pathname: `/tiers/${temoin.id}/edition`,
                            state: {
                                tiersId: temoin.id,
                                contract: contract,
                                accident: accident,
                                tiers: temoin,
                                type: 'temoin'
                            }
                        }}
                        action={'edit-repairer'}
                        toolText={'Modifier le témoin'}
                    />
                    :
                    <CallToAction
                        url={{
                            pathname: `/tiers/create`,
                            state: {
                                contract: contract,
                                accident: accident,
                                type: 'temoin'
                            }
                        }}
                        action={'edit-repairer'}
                        toolText={'Ajouter un témoin'}
                    />
                }
            </Fragment>
            <div className={'content row'}>
                {temoin?.raisonSociale ?
                    <>
                        <div className={'col-5 left-content'}>
                            <p>Nom & Prénom :</p>
                        </div>
                        <div className={'col-7 right-content'}>
                            <p>{temoin.raisonSociale}</p>
                        </div>
                        <div className={'col-5 left-content'}>
                            <p>Adresse postale :</p>
                        </div>
                        <div className={'col-7 right-content'}>
                            <p>{temoin.rue1} {temoin.rue2 !== '' ? `- ${temoin.rue2}` : ''}</p>
                        </div>
                        <div className={'col-5 left-content'}>
                            <p>Code postal - Ville :</p>
                        </div>
                        <div className={'col-7 right-content'}>
                            <p>{temoin.codePostal} {temoin.ville}</p>
                        </div>
                        <div className={'col-5 left-content'}>
                            <p>Email :</p>
                        </div>
                        <div className={'col-7 right-content'}>
                            <p>{temoin.email || '-'}</p>
                        </div>
                        <div className={'col-5 left-content'}>
                            <p>Téléphone :</p>
                        </div>
                        <div className={'col-7 right-content'}>
                            <p>{temoin.phone || '-'}</p>
                        </div>
                    </>
                    : <div className={'col-12'}>
                        <p>Aucun témoin de renseigné pour ce sinistre</p>
                    </div>
                }
            </div>
        </div>
    )
}

export default Temoin
