import _ from 'lodash';
import moment from 'moment';
import { openInWindow } from '../../../../constants/constants';
import { redirectInNewWindow } from '../../../../utils/function';

const Reclamations = (props) => {
    const { reclamations, id, roles } = props;

    return (
        <div className={'bloc-info bloc-reclamations'}>
            <h2>Réclamations</h2>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Date de déclaration</th>
                        <th scope="col">Description</th>
                        <th scope="col">Situation</th>
                        <th scope="col">Moment de vie</th>
                    </tr>
                </thead>
                <tbody>
                    {_.isArray(reclamations) &&
                        reclamations.map((r) => (
                            <tr key={r.id}>
                                <td>{moment(r.date_enregistrement).format('L')}</td>
                                <td>{r.description}</td>
                                <td>{r.situation}</td>
                                <td>{r.moment_vie}</td>
                                <td>
                                    {!_.includes(roles, 'gestionnaire televente') ? (
                                        <button
                                            className={'btn btn-more'}
                                            onClick={(e) =>
                                                redirectInNewWindow({
                                                    e,
                                                    url: `/ma-reclamation/${id}/${r.id}`,
                                                    name: openInWindow.reclamation,
                                                })
                                            }
                                        />
                                    ) : (
                                        ' '
                                    )}
                                </td>
                                <td>
                                    {!_.includes(roles, 'gestionnaire qualite') || _.includes(roles, 'manager') ? (
                                        <button
                                            className={'btn btn-edit'}
                                            onClick={(e) =>
                                                redirectInNewWindow({
                                                    e,
                                                    url: `/ma-reclamation/${id}/${r.id}/edit`,
                                                    name: openInWindow.reclamation,
                                                })
                                            }
                                        />
                                    ) : (
                                        ' '
                                    )}
                                </td>
                            </tr>
                        ))}
                    {reclamations.length === 0 && (
                        <tr>
                            <td colSpan={5} className={'font-weight-normal'}>
                                Aucune réclamation
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

Reclamations.defaultProps = {
    reclamations: [],
};

Reclamations.propTypes = {};

export default Reclamations;
