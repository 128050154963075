import React, {Component} from 'react';
import _ from 'lodash'
import imgRotate from "../../../../assets/img/icons/rotate_light.png";
import imgZoomIn from "../../../../assets/img/icons/zoomIn_light.png";
import imgZoomOut from "../../../../assets/img/icons/zoomOut_light.png";
import imgReset from "../../../../assets/img/icons/center_light.png";
import APIFrame from "../../APILink/APIFrame";
import APIImage from "../../APILink/APIImage";

class ActivityDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rotate: 0,
            zoom: null
        };
    }

    rotate = () => {
        this.setState({
            rotate: this.state.rotate + 90
        })
    }

    zoomIn = () => {
        this.setState({
            zoom: this.refImg.clientWidth + 100
        })
    }

    zoomOut = () => {
        this.setState({
            zoom: this.refImg.clientWidth - 100
        })
    }

    reset = () => {
        this.setState({
            zoom: 500
        })
    }

    render() {
        const {files, permissions, postDeleteTaskDocument} = this.props
        let btn_supression;

        let styleImg = {
            transform: `rotate(${this.state.rotate}deg)`,
            width: `${this.state.zoom}px`,
            display: 'block',
            margin: 'auto',
            minHeight: '600px'
        }

        return (
            <div className={'contract-container container'}>
                <div>
                    <h2 className={'text-center'}>Documents</h2>
                </div>
                {files && files.map((file, index) => {
                    if (!_.includes(['html'], file.extension.toLowerCase())) {
                        if (_.includes(permissions, 'delete document') && file.type === 'scanner') {
                            btn_supression = <div className={'text-right'}>
                                <span className={'btn btn-cta btn-danger mb-4'}
                                      onClick={() => postDeleteTaskDocument(file.id)}>Supprimer</span>
                            </div>;
                        }

                        if (_.includes(['pdf', 'xml'], file.extension.toLowerCase())) {
                            return <div key={index}>
                                <div className='row'>
                                    <div className={'col'} style={{marginBottom: 30}}>
                                        <APIFrame src={`${file.url}`} title={`${file.code}`} frameBorder="0"
                                                style={{
                                                    height: '100%',
                                                    width: '100%',
                                                    marginBottom: 30,
                                                    minHeight: 600
                                                }}/>
                                    </div>
                                </div>
                                {btn_supression}
                            </div>
                        }

                        if (!_.includes(['pdf', 'xml'], file.extension.toLowerCase())) {
                            return <div key={index}>
                                <div className='row'>
                                    <div className={'col-11 task-img-container'}>
                                        <APIImage src={`${file.url}`} style={styleImg} ref={el => this.refImg = el}
                                             alt={'File'}/>
                                    </div>
                                    <div className={'col-1'}>
                                        <div className={'task-nav-img'}>
                                            <span className={'task-btn-img'} onClick={() => this.rotate()}
                                                  title={'Faire pivoter vers la droite'}>
                                                <img src={imgRotate} alt="Rotation"/>
                                            </span>
                                            <span className={'task-btn-img'} onClick={() => this.zoomIn()}
                                                          title={'Zoom avant'}>
                                                <img src={imgZoomIn} alt="zoom in"/>
                                            </span>
                                            <span className={'task-btn-img'} onClick={() => this.zoomOut()}
                                                          title={'Zoom arrière'}>
                                                <img src={imgZoomOut} alt="zoom out"/>
                                            </span>
                                                    <span className={'task-btn-img'} onClick={() => this.reset()}
                                                          title={'Ajuster à la page'}>
                                                <img src={imgReset} alt="centre"/>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {btn_supression}
                            </div>
                        }
                    }
                })}
            </div>
        );
    }
}

export default ActivityDetail;
