import React from 'react'
import moment from "moment";
import Badge from "../Badge/Badge";
import {Link} from "react-router-dom";
import {redirectInNewWindow} from "../../../utils/function";

const VirementTableCard = (props) => {
    const {virement} = props

    return (
        <tr>
            <td>{moment(virement.created_at).format('L')}</td>
            <td className={'pointer'} onClick={(e) => redirectInNewWindow({
                e,
                url: `/contrat/${virement.contrat_id}`
            })}>{virement.contrat.numeroPolice}</td>
            <td><Badge status={virement.contrat.situation}/></td>
            <td>{virement.contrat.statut === 'Résiliation' ? moment(virement.contrat.dateFin).format('L') : moment(virement.contrat.date).format('L')}</td>
            <td>{virement.contrat.motif}</td>
            <td>{virement.montant} €</td>
            <td>
                <Link
                    to={{
                        pathname: `/virements/${virement.id_encaissement}/refuse`,
                        state: {
                            popup: 1
                        }
                    }}
                    className={'btn btn-danger btn-small'}
                >
                    Refuser
                </Link>
                <Link
                    to={{
                        pathname: `/virements/${virement.id_encaissement}/refuse`,
                        state: {
                            popup: 0
                        }
                    }}
                    className={'btn btn-warning btn-small'}
                >
                    Pas d'action
                </Link>
            </td>
        </tr>
    )
}

export default VirementTableCard
