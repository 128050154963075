export const CONTRACT = '[Contract]'
export const CONTRACTS = '[Contracts]'
export const CONTRACTS_SEARCH_ISICOM = '[Contracts Search Isicom]'
export const TARIF_CONTRACT = '[Tarif Contract]'

export const GET_CONTRACT = `${CONTRACT} Get`
export const SET_CONTRACT = `${CONTRACT} Set`
export const SET_VEHICULE_CONTRACT = `${CONTRACT} Vehicule Set`
export const GET_TARIF_CONTRACT = `${TARIF_CONTRACT} Get`
export const SET_TARIF_CONTRACT = `${TARIF_CONTRACT} Set`


export const GET_CONTRACTS = `${CONTRACTS} Get`
export const SET_CONTRACTS = `${CONTRACTS} Set`
export const SET_CONTRACTS_SEARCH = `${CONTRACTS} Set`

export const POST_DEACTIVATE_LOYALTY_CODE = `${CONTRACTS} Post deactivate loyalty code`
export const POST_CONTRACT_DRIVER_NOT_MINOR = `${CONTRACT} Post the driver is not minor`
export const POST_CONFIRM_RESIL_HAMON = `${CONTRACT} Post Confirm Resil Hamon`


export const getContracts = ({query}) => ({
    type: GET_CONTRACTS,
    payload: {
        data: query,
    }
})

export const setContracts = ({items, total}) => ({
    type: SET_CONTRACTS,
    payload: {items, total}
})

export const setContractsSearchIsicom = ({data, meta, links}) => ({
    type: SET_CONTRACTS_SEARCH,
    payload: {data, meta, links}
})

export const getContract = ({query, id}) => ({
    type: GET_CONTRACT,
    payload: {
        data: query,
        otherData: {
            id: id
        }
    }
})

export const setContract = ({data}) => ({
    type: SET_CONTRACT,
    payload: {data}
})

export const postDeactivateLoyaltyCode = ({query}) => ({
    type: POST_DEACTIVATE_LOYALTY_CODE,
    payload: {
        data: query
    }
})

export const postContractDriverNotMinor = ({query}) => ({
    type: POST_CONTRACT_DRIVER_NOT_MINOR,
    payload: {
        data: query
    }
})

export const setVehiculeInContract = ({data}) => ({
    type: SET_VEHICULE_CONTRACT,
    payload: {
        data
    }
})

export const postConfirmResilHamon = ({query, form}) => ({
    type: POST_CONFIRM_RESIL_HAMON,
    payload: {
        data: query,
        body: form,
    }
})
export const getTarifContract = ({query}) => ({
    type: GET_TARIF_CONTRACT,
    payload: {
        data: query
    }
})

export const setTarifContract = ({data}) => ({
    type: SET_TARIF_CONTRACT,
    payload: {data},
})