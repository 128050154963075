import {connect} from 'react-redux'
import {bindActionCreators, compose} from "redux";
import {
    getContractData,
    getContractSituationDate,
    getIsFleetContract
} from "../../../../../../redux/selectors/contract/contract.selectors";
import {formValueSelector, reduxForm} from "redux-form";
import validate from "./validate";
import InitForm from "./InitForm";
import moment from "moment";
import {getLoadingEntity, getValidatePostAccident} from "../../../../../../redux/selectors/ui/ui.selectors";
import {
    POST_ACCIDENT_NON_DEC,
    postAccidentNonDec
} from "../../../../../../redux/actions/app/accidents/accidents.actions";
import {logAction} from "../../../../../../redux/actions/core/logs/logs.actions";
import {getPiecesData} from "../../../../../../redux/selectors/pieces/pieces.selector";
import {piecesContractWithoutIdGetLink} from "../../../../../../redux/selectors/links/links.selectors";
import _ from "lodash";
import {getPieces} from "../../../../../../redux/actions/app/pieces/pieces.actions";
import loader from "../../../../../Commun/Spinner/Loader/Loader";
import ErrorHttpComponent from "../../../../../Commun/Error/ErrorHttpComponent";

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector('declaration-sinistre-non-dec')
    const contract = getContractData(state);
    const dateSurvMax = contract.situation.statutCode === "6" && contract.situation.dateFin !== null ? moment(contract.situation.dateFin) : moment();

    return {
        uri_pieces: piecesContractWithoutIdGetLink(state),
        contract,
        pieces: getPiecesData(state),
        loading: getLoadingEntity(state, POST_ACCIDENT_NON_DEC),
        uri: contract.accident_claim_link_non_dec,
        validatePostAccident: getValidatePostAccident(state),
        dateSurvMax: dateSurvMax,
        dateSurv: selector(state, 'dateSurv'),
        dateDerPiece: getContractSituationDate(state),
        isFleet: getIsFleetContract(state),
        initialValues: {
            'dateSurv': dateSurvMax,
            'numCie': '',
            'risque': ownProps.idVehicule || contract.adhesionPrincipale.toString(),
        }
    }
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
    postAccidentNonDec,
    getPieces: (query) => getPieces({query}),
    logAction: () => logAction({
        entity: POST_ACCIDENT_NON_DEC,
        type: 'SHOW_REPORT_ACCIDENT',
        contrat: ownProps.contract.id,
        tiers: ownProps.contract.souscripteur.id
    })
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uri, uri_pieces, contract} = stateProps
    const {postAccidentNonDec, getPieces} = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form) => postAccidentNonDec({form, query: uri}),
        load: () => getPieces(_.replace(uri_pieces, '{id}', contract.id))
    }
}

const InitFormContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent}),
    reduxForm({
        form: 'declaration-sinistre-non-dec',
        validate
    })
)(InitForm)

export default InitFormContainer
