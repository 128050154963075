import React, {Component} from 'react'

export default class FaqCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            question: false
        };
        this.toggleQuestion = this.toggleQuestion.bind(this);
    }

    toggleQuestion() {
        this.setState({question: !this.state.question})

        if (!this.state.question && this.props.onClickOpen) {
            this.props.onClickOpen();
        }
    }


    render() {
        const {faq, index, show = this.state.question} = this.props

        return (
            <div className={'card-faq'}>
                <div className={'card-header'} id={`heading${index}`}>
                    <h2>
                        <button className={`${show ? 'collapsed' : ''}`}
                                type="button"
                                data-toggle="collapse"
                                data-target={`#collapse${index}`}
                                aria-controls={`collapse${index}`}
                                onClick={this.toggleQuestion}
                                dangerouslySetInnerHTML={{__html: faq.question}}
                        />
                    </h2>
                </div>
                <div id={`collapse${index}`} className={`card-body collapse ${show && 'show'}`}
                     aria-labelledby={`heading${index}`} data-parent="#faq">
                    <div className={'content'} dangerouslySetInnerHTML={{__html: faq.content_html}}/>
                </div>
            </div>
        )
    }
}
