import React from 'react'
import TaskCard from "../../../Commun/Card/TaskCard"

const Agenda = (props) => {
    const {agendas, meta} = props

    return (
        <div className={'tasks-container container'}>
            <div className={'row'}>
                <div className={'col-12'}>
                    <h1 className={'title-primary title-agenda'}>Mon agenda</h1>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-12'}>
                    {meta.total <= 0 && <p className={'text-center'}>Vous n'avez pas de tâches dans votre agenda</p>}
                    {agendas.length > 0 &&
                        agendas.map((task, index) => (
                            <TaskCard task={task} key={index}/>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Agenda
