import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import CorrespondantItem from "./CorrespondantItem";
import {deleteCorrespondantsGetLink} from "../../../../../../../redux/selectors/links/links.selectors";
import {postDeleteCorrespondant} from "../../../../../../../redux/actions/app/correspondants/correspondants.actions";
import _ from "lodash";

const mapStateToProps = (state, ownProps) => {
    return {
        uri_delete: deleteCorrespondantsGetLink(state),
        idTiers: ownProps.id,
        idCorrespondant: ownProps.correspondant.id,
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    postDeleteCorrespondant,
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uri_delete, idTiers, idCorrespondant} = stateProps
    const {postDeleteCorrespondant} = dispatchProps


    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        submitDeleteCorrespondant: () => postDeleteCorrespondant({query: _.replace(_.replace(uri_delete, '{id}', idTiers), '{ids}', idCorrespondant)})
    }
}


const CorrespondantItemContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(CorrespondantItem)


export default CorrespondantItemContainer
