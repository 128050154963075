import {EMAIL, POST_EMAIL, setEmailsEnvoyes} from "../../../actions/app/email/email.actions";
import {API_ERROR, API_SUCCESS, apiRequest} from "../../../actions/core/api/api.actions";
import {setError, setLoader} from "../../../actions/app/ui/ui.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {reset} from "redux-form";

export const emailMiddleware = () => next => action => {
    next(action)
    let nextAction = []

    switch (action.type) {
        case POST_EMAIL:
            let data = new FormData()
            let body = action.payload.body
            if (body.text) {
                body.text = body.text.replace(/\n/gi, '<br/>')
            }

            Object.keys(body).forEach(key => {
                if (key === 'files') {
                    Object.keys(body[key]).forEach(doc => {
                        if (body[key][doc] && body[key][doc].name !== undefined) {
                            data.append(`files[${doc}]`, body[key][doc])
                        }
                    })
                }
                if (key === 'recipients') {
                    body[key].forEach((cc, index) => {
                        Object.keys(cc).forEach(info => {
                            data.append(`${key}[${index}][${info}]`, cc[info])
                        })
                    })
                } else {
                    data.append(key, body[key])
                }
                return true
            })

            next([
                apiRequest({
                    body: data, method: 'POST', url: action.payload.data, entity: EMAIL, otherData: {
                        label_email_envoye: `${body.template_name} (${body.email})`,
                        cloture: action.payload.body.clotureReclamation,
                        id: action.payload.otherData.id,
                        ids: action.payload.otherData.ids
                    }
                }),
                setLoader({state: true, entity: EMAIL})
            ])
            break

        case `${EMAIL} ${API_SUCCESS}`:
            nextAction = [
                setError({state: false, entity: EMAIL}),
                setLoader({state: false, entity: EMAIL}),
                setNotification({entity: EMAIL, type: 'success', title: 'Email envoyé'}),
                setEmailsEnvoyes({data: action.payload.meta.otherData.label_email_envoye})
            ]

            if (action.payload.meta.otherData.cloture) {
                next(nextAction)
                window.location = `/ma-reclamation/cloturer/${action.payload.meta.otherData.id}/${action.payload.meta.otherData.ids}`
            } else {
                window.scroll(0, 0)
                nextAction.push(reset('send-email'))
                next(nextAction)
            }
            break

        case `${EMAIL} ${API_ERROR}`:
            next([
                setError({state: true, entity: EMAIL}),
                setLoader({state: false, entity: EMAIL}),
                setNotification({
                    entity: EMAIL,
                    body: action.payload.data.response ? action.payload.data.response.data?.message ?? '' : '',
                    type: 'error',
                    title: 'Une erreur est survenue.',
                    timeout: '0'
                }),
            ])
            break

        default:
            break
    }
    return null
}
