import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import SearchField from './SearchField'
import {reduxForm} from "redux-form";
import {searchGetLink} from "../../../../redux/selectors/links/links.selectors";
import {getErrorEntity, getLoadingEntity} from "../../../../redux/selectors/ui/ui.selectors";
import {postSearch, clearSearch, SEARCH} from "../../../../redux/actions/app/search/search.actions";
import _ from "lodash";
import {getResultSearchData} from "../../../../redux/selectors/search/search.selectors";
import {logAction} from "../../../../redux/actions/core/logs/logs.actions";
import {DOCUMENTS} from "../../../../redux/actions/app/documents/documents.actions";
import {CONTRACT, CONTRACTS_SEARCH_ISICOM} from "../../../../redux/actions/app/contracts/contracts.actions";

const mapStateToProps = (state, ownProps) => {
    return {
        uri: searchGetLink(state),
        loading: getLoadingEntity(state, SEARCH),
        error: getErrorEntity(state, SEARCH),
        result: getResultSearchData(state) || [],
        search: ownProps.search,
        initialValues: {
            's': ownProps.search
        }
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    submitPostSearch: (query) => postSearch({query}),
    clearSearch: () => clearSearch(),
    logAction: () => logAction({
        entity: CONTRACT,
        type: 'SEARCH_CONTRACT_TALKDESK',
    })
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {submitPostSearch, logAction} = dispatchProps
    const {uri} = stateProps
    if (ownProps.search) {
        logAction()
    }
    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form) => {
            submitPostSearch(_.replace(uri, '{str}', _.replace(form.s, '/', '-') + '?per_page=15'))
        }
    }
}

const SearchFieldContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'searchForm'
    }),
)(SearchField)

export default SearchFieldContainer
