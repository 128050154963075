import { connect } from 'react-redux';
import { compose } from 'redux';
import Intervenants from './Intervenants';

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = {};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
    };
};

const IntervenantsContainer = compose(connect(mapStateToProps, mapDispatchToProps, mergeProps))(Intervenants);

export default IntervenantsContainer;
