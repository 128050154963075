import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {getErrorEntity, getLoadingEntity} from '../../../../../redux/selectors/ui/ui.selectors'
import Documents from './Documents'
import {DOCUMENTS, getDocuments} from '../../../../../redux/actions/app/documents/documents.actions'
import {getDocumentsData} from '../../../../../redux/selectors/documents/documents.selector'
import ErrorHttpComponent from '../../../../Commun/Error/ErrorHttpComponent'
import {documentsGetLink} from "../../../../../redux/selectors/links/links.selectors";
import {logAction} from "../../../../../redux/actions/core/logs/logs.actions";
import {getContractDocumentsLink, getContractId} from "../../../../../redux/selectors/contract/contract.selectors";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {

    return {
        documents: getDocumentsData(state),
        uri: documentsGetLink(state),
        contractId: getContractId(state),
        contractDocumentsLink: getContractDocumentsLink(state),
        loaded: getLoadingEntity(state, DOCUMENTS) === false,
        error: getErrorEntity(state, DOCUMENTS),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
    getDocuments: (query) => getDocuments({query}),
    logAction: (document_code) => logAction({
        entity: DOCUMENTS,
        type: 'SHOW_DOCUMENT',
        contrat: ownProps.contractId,
        document_code
    })
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getDocuments} = dispatchProps
    const {uri} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getDocuments(uri)
    }
}

const DocumentsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(Documents)

export default DocumentsContainer
