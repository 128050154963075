import React, {Component} from 'react'
import Badge from "../../../../Commun/Badge/Badge";
import {Field} from "redux-form";
import TextFieldRedux from "../../../../Commun/Input/Text/TextFieldRedux";
import MyButton from "../../../../Commun/Button/Button";
import Spinner from "../../../../Commun/Spinner/Spinner";

class Payment extends Component {

    submitForm = (form) => {
        this.props.onSubmit(form)
    }

    sendByMail = (form) => {
        form.by_mail = true
        this.submitForm(form)
    }

    render() {
        const {loading, handleSubmit, contract, dateLimite} = this.props

        return (
            <div className={'contract-container container'}>
                <div className={'row return-content'}>
                    <div className={'col'}>
                        <button onClick={() => this.props.history.go(-1)} className={'btn btn-return'}>Retour</button>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-12'}>
                        <h1 className={'title-primary'}>{contract.souscripteur.titre} {contract.souscripteur.nom} {contract.souscripteur.id}</h1>
                        <Badge status={contract.situation.statut}/>
                    </div>
                    <div className={'col-12'}>
                        <span
                            className={'bold text-uppercase subtitle'}>Contrat n°{contract.numeroPolice} - {contract.title}</span>
                    </div>
                </div>
                <div className={'row header-row'}>
                    {((contract.situation.statutCode !== '2' && contract.situation.statutCode !== '6') && (dateLimite && contract.prelevement === true)) &&
                        <div className={'col-12'}>
                            <div className={'alert alert-warning'}>
                                Il n'est pas possible d'effectuer un paiement du 25 au dernier jour de chaque mois
                            </div>
                        </div>
                    }
                    <div className={'col-12'}>
                        <form className={'form-content'} onSubmit={handleSubmit(this.submitForm.bind(this))}>
                            <Field
                                name='amount'
                                component={TextFieldRedux}
                                label={'Montant du paiement'}
                            />
                            {/*<MyButton
                                style={{marginRight: 15}}
                                className={'btn btn-primary btn-smaller'}
                                disabled={loading || ((contract.situation.statutCode !== '2' && contract.situation.statutCode !== '6') && (dateLimite && contract.prelevement === true))}
                            >
                                {loading && <Spinner absolute/>}
                                Accéder au paiement
                            </MyButton>*/}
                            <span
                                className={'btn btn-primary btn-smaller'}
                                onClick={handleSubmit(values => this.sendByMail({...values}))}
                            >
                                {loading && <Spinner absolute/>}
                                Envoyer le lien par mail
                            </span>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default Payment
