import _ from "lodash";

const validate = values => {
    const errors = {}

    const requiredFields = [
        'beneficiaire',
        'montant_total',
        'montant_type_reglement',
        'titulaire',
        'garantie',
        'type_reglement',
        'categorie_reglement'
    ]

    const posteIndemnisation = [
        'remorquage_et_gardiennage',
        'accessoires',
        'equipements',
        'casque_et_gants',
        'effets_personnels',
        'moyens_de_protection',
        'majoration_valeur_expertise'
    ]

    const penalites = [
        'absence_justificatif_antivol_SRA',
        'absence_gravage',
        'absence_de_remisage_de_nuit',
        'franchise',
        'franchise_conduite_exclusive',
        'rachat_de_franchise'
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Ce champ est obligatoire'
        }
    })

    if (values['deduction'] !== 'AUCUNE' && !values['montant_deduction']) {
        errors.montant_deduction = 'Ce champ est obligatoire'
    }

    if (values['deduction'] === 'INDEMDEJAREGLE' && !values['deduction_commentaire']) {
        errors.detail_deduction = 'Ce champ est obligatoire'
    }

    const indemnisationErrors = []
    posteIndemnisation.forEach((field, index) => {
        const posteError = {}
        if(values['indemnisation'] && values['indemnisation'][index]){
            if (values['indemnisation'][index]['check'] === 'T' && !values['indemnisation'][index]['montant']) {
                posteError.montant = 'Ce champ est obligatoire'
                indemnisationErrors[index] = posteError
            }
            if (values['indemnisation'][index]['libelle'] === 'EQUPMTS' && values['indemnisation'][index]['montant'] > 750) {
                posteError.montant = 'Le montant ne doit pas dépasser 750€'
                indemnisationErrors[index] = posteError
            }
            if (values['indemnisation'][index]['libelle'] === 'CASQUEGANTS' && values['indemnisation'][index]['montant'] > 250) {
                posteError.montant = 'Le montant ne doit pas dépasser 250€'
                indemnisationErrors[index] = posteError
            }
            if (values['indemnisation'][index]['libelle'] === 'REMORQUAGE' && values['indemnisation'][index]['maxValue'] && values['indemnisation'][index]['montant'] > values['indemnisation'][index]['maxValue']) {
                posteError.montant = `Le montant ne doit pas dépasser ${values['indemnisation'][index]['maxValue']}€`
                indemnisationErrors[index] = posteError
            }
        }
    })

    if (indemnisationErrors.length) {
        errors.indemnisation = indemnisationErrors
    }

    const penaliteErrors = []
    penalites.forEach((field, index) => {
        const posteError = {}
        if (values['penalite'] && values['penalite'][index] && values['penalite'][index]['check'] === 'T' && !values['penalite'][index]['montant']) {
            posteError.montant = 'Ce champ est obligatoire'
            penaliteErrors[index] = posteError
        }
    })

    if (penaliteErrors.length) {
        errors.penalite = penaliteErrors
    }

    if (values.montant_total > 23400) {
        errors.montant_total = "Le montant ne doit pas dépasser 23 400€"
    }

    return errors
}

export default validate
