import React from 'react';
import moment from 'moment';
import './timeline_ri.scss';
import TimelineBloc from "./TimelineBloc/TimelineBloc";

const Timeline = ({ expectedPeriod, receivedPeriods, missingPeriods }) => {
    function formatDate(date) {
        return moment(date, 'DD/MM/YYYY');
    }

    const totalMonths = formatDate(expectedPeriod.end).diff(formatDate(expectedPeriod.start), 'days');

    return (
        <div className={'container-timeline mb-4'}>
            <ul className="timeline-events">
                {receivedPeriods && <TimelineBloc type={'ok'} expectedPeriod={expectedPeriod} periods={receivedPeriods} totalMonths={totalMonths} formatDate={formatDate}/> }

                {missingPeriods && <TimelineBloc type={'ko'} expectedPeriod={expectedPeriod} periods={missingPeriods} totalMonths={totalMonths} formatDate={formatDate}/> }
            </ul>
            <ul className="timelines-years">
                <li>{expectedPeriod.start}</li>
                <li>{expectedPeriod.end}</li>
            </ul>
        </div>
    );
};

export default Timeline;
