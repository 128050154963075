import {PAYMENT, POST_PAYMENT} from '../../../actions/app/payment/payment.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {setNotification} from '../../../actions/core/notifications/notifications.actions'
import {reset} from 'redux-form'

export const paymentMiddleware = () => next => action => {
    next(action)

    const {payload} = action
    let message = null
    let nextAction = []

    switch (action.type) {
        case POST_PAYMENT:
            next([
                apiRequest({
                    body: payload.body,
                    method: 'POST',
                    url: payload.data,
                    entity: PAYMENT,
                    otherData: payload.otherData
                }),
                setLoader({state: true, entity: PAYMENT})
            ])
            break

        case `${PAYMENT} ${API_SUCCESS}`:
            nextAction = [
                setLoader({state: false, entity: PAYMENT}),
                setError({state: false, entity: PAYMENT}),
            ]

            if (!payload.meta.otherData.by_mail) {
                window.location = payload.data
            } else {
                nextAction.push(setNotification({
                    entity: PAYMENT,
                    body: `<p>Le mail de paiement a bien été envoyé au client</p>`,
                    type: 'success',
                    title: 'Le mail envoyé !'
                }))
                reset('paymentForm')
            }
            next(nextAction)

            break

        case `${PAYMENT} ${API_ERROR}`:
            message = `<p>Erreur lors du paiement. ${payload.data.response.data?.message ?? ''}</p>`
            next([
                setNotification({entity: PAYMENT, body: message, type: 'error', title: 'Attention !', timeout: '0'}),
                setError({state: true, entity: PAYMENT}),
                setLoader({state: false, entity: PAYMENT}),
            ])
            break

        default:
            break;
    }
    return null
}
