import React, {Fragment} from 'react';
import Spinner from "../../../../Commun/Spinner/Spinner";
import MyButton from "../../../../Commun/Button/Button";
import {Field} from "redux-form";
import DatePickerFieldRedux from "../../../../Commun/Input/DatePicker/DatePickerFieldRedux";
import moment from "moment";


const ShiftDateTask = (props) => {
    const {is_shifted, loading, handleSubmit, task, history} = props

    return (
        <div className={'task-detail-container container'}>
            {!is_shifted ?
                <Fragment>
                    <div className={'row return-content'}>
                        <div className={'col'}>
                            <button onClick={() => history.go(-1)} className={'btn btn-return'}>Retour</button>
                        </div>
                    </div>

                    <div className={'header-row'}>
                        <div className={'col-12'}>
                            <form className={'form-content row'} onSubmit={handleSubmit}>

                                <div className={'col-12'}>
                                    <p><span
                                        className={'font-weight-bold'}>Ancienne date :</span> {moment(task.date).format('L')}
                                    </p>
                                </div>

                                <div className="col">
                                    <Field
                                        name={"date"}
                                        component={DatePickerFieldRedux}
                                        label={'Nouvelle date'}
                                        minDate={moment()}
                                    />
                                </div>

                                <div className={'col-12'}>
                                    <MyButton
                                        variant='raised'
                                        color='secondary'
                                        size='large'
                                        className={'btn btn-primary'}
                                        disabled={loading}
                                    >
                                        {loading && <Spinner absolute/>}
                                        Décaler
                                    </MyButton>
                                </div>
                            </form>
                        </div>
                    </div>
                </Fragment>
                :
                <div className={'text-center'}>
                    <h2 className={'title-primary title-success'}>L'agenda a été décalé&nbsp;!</h2>
                </div>
            }
        </div>
    );
};


export default ShiftDateTask;
