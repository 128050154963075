import React, {Component} from 'react';
import Dropzone from 'react-dropzone';
import './upload.scss'
import CallToAction from "../../Button/CallToAction/CallToAction";


class Upload extends Component {
    constructor() {
        super();
        this.onDrop = (files) => {
            this.handleChange(files)
        };
        this.state = {
            name: null
        };
    }

    onButtonUpload = input => {
        input.click()
    }

    handleChange(file) {

        this.setState({
            name: file[0].name
        })
        this.props.changeValue(this.props.formName, this.props.code, file[0])
    }

    reset() {
        this.setState({
            name: null
        })
        this.props.changeValue(this.props.formName, this.props.code, null)
    }

    render() {
        const {title} = this.props
        const {name} = this.state

        return (
            <Dropzone onDrop={this.onDrop}>
                {({getRootProps, getInputProps, isDragActive}) => (
                    <div {...getRootProps({className: `row upload-content ${isDragActive && 'active'} ${name !== null && 'isSet'}`})}>
                        <div className="col-10">
                            <div className="row">
                                <div className="col">
                                    <p className={'upload-title'}>{title}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <p className={'upload-name-file'}>{name}</p>
                                    {name && <CallToAction
                                        action={'delete'}
                                        toolText={'Supprimer'}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            this.reset()
                                        }}
                                    />}
                                </div>
                            </div>
                        </div>
                        <div className="col-2">
                    <span
                        className={`btn btn-block btn-${name ? 'warning' : 'outline-secondary'}`}
                        onClick={() => this.onButtonUpload(this.inputUpload)}
                    >
                        <input
                            type='file'
                            hidden
                            onChange={(e) => this.handleChange(e.target.files)}
                            ref={inputUpload => {
                                this.inputUpload = inputUpload
                            }}
                            accept='image/*, application/pdf'
                        />
                        {name ? 'Modifier' : 'Parcourir'}
                    </span>
                        </div>
                    </div>
                )}
            </Dropzone>
        );
    }

};

export default Upload;

