import {
    LOGIN,
    LOGOUT,
    POST_LOGIN,
    POST_LOGOUT,
    postLoginSuccess,
    postLoginFailed
} from '../../../actions/app/login/login.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setIsLogin, setIsLogout, setLoader} from '../../../actions/app/ui/ui.actions'
import {clearLocalStorage, setLocalStorage} from '../../../actions/core/localStorage/localStorage.actions'

export const loginMiddleware = () => next => action => {
    next(action)

    const {payload} = action

    switch (action.type) {
        case POST_LOGIN:
            next([
                apiRequest({body: payload.body, method: 'POST', url: payload.data, entity: LOGIN}),
                setLoader({state: true, entity: LOGIN})
            ])
            break

        case `${LOGIN} ${API_SUCCESS}`:
            const {token_type, access_token, message_gestionnaire} = payload.data

            const storage = {
                token: `${token_type} ${access_token}`,
                message: message_gestionnaire
            }

            next([
                setLocalStorage({entity: LOGIN, storage}),
                setIsLogin({state: true, entity: LOGIN}),
                setError({state: false, entity: LOGIN}),
                setLoader({state: false, entity: LOGIN}),
                postLoginSuccess(),
            ])
            break

        case `${LOGIN} ${API_ERROR}`:
            next([
                setError({state: true, entity: LOGIN}),
                setLoader({state: false, entity: LOGIN}),
                postLoginFailed(),
            ])
            break

        case POST_LOGOUT:
            next([
                apiRequest({method: 'POST', url: payload.data, entity: LOGOUT}),
                clearLocalStorage({entity: LOGOUT}),
                setLoader({state: true, entity: LOGOUT})
            ])
            break

        case `${LOGOUT} ${API_SUCCESS}`:
            next([
                setIsLogout({state: true, entity: LOGOUT}),
                setError({state: false, entity: LOGOUT}),
                setLoader({state: false, entity: LOGOUT})
            ])
            window.location.reload()
            break;

        case `${LOGOUT} ${API_ERROR}`:
            next([
                setError({state: true, entity: LOGOUT}),
                setLoader({state: false, entity: LOGOUT})
            ])
            break;

        default:
            break
    }
    return null
}
