import React from 'react';
import {Redirect} from "react-router-dom";
import FormHivernageContainer from "./FormHivernage/FormHivernageContainer";
import Badge from "../../../../Commun/Badge/Badge";

const Hivernage = (props) => {
    const {id_contrat, is_eligible_hivernage, contract} = props
    if (!is_eligible_hivernage){
        return <Redirect to={`/mon-contrat/${id_contrat}`} />
    }

    return (
        <div className={'contract-container container'}>
            <div className={'row'}>
                <div className={'col-12'}>
                    <h1 className={'title-primary'}>{contract.souscripteur.titre} {contract.souscripteur.nom} {contract.souscripteur.id}</h1>
                    <Badge status={contract.situation.statut}/>
                </div>
                <div className={'col-12'}>
                        <span
                            className={'bold text-uppercase subtitle'}>Contrat n°{contract.numeroPolice} - {contract.title}</span>
                </div>
            </div>
            <div className="row header-row">
                <div className={'col-12'}>
                    <h2 className="title-primary text-center d-block">Gérer Ma période hivernale</h2>
                </div>
            </div>

            <div className={'form-content'}>
                <FormHivernageContainer id_contrat={id_contrat}/>
            </div>
        </div>
    );
};
export default Hivernage;