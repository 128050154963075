import {connect} from 'react-redux'
import {formValueSelector} from 'redux-form'
import Temoins from './Temoins'

const mapStateToProps = state => {
    const selector = formValueSelector('declaration-sinistre')

    return {
        temoins: selector(state, 'temoins')
    }
}

const TemoinsContainer = connect(mapStateToProps)(Temoins)

export default TemoinsContainer
