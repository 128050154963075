import {connect} from 'react-redux'
import {compose} from 'redux'

import _ from 'lodash'
import Reclamations from "./Reclamations";
import {complainGetAllLink} from "../../../../redux/selectors/links/links.selectors";
import {getReclamation, RECLAMATION} from "../../../../redux/actions/app/reclamation/reclamation.actions";
import {getReclamationData} from "../../../../redux/selectors/reclamation/reclamation.selectors";
import {getErrorEntity, getLoadingEntity} from "../../../../redux/selectors/ui/ui.selectors";
import {getUserRoles} from "../../../../redux/selectors/user/user.selectors";
import loader from "../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    return {
        query: complainGetAllLink(state),
        reclamations: getReclamationData(state),
        loaded: getLoadingEntity(state, RECLAMATION) === false,
        error: getErrorEntity(state, RECLAMATION),
        roles: getUserRoles(state)
    }
}

const mapDispatchToProps = {
    getReclamation
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {query} = stateProps
    const {getReclamation} = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getReclamation({query: _.replace(query, '{id}', ownProps.id)})
    }
}

const ReclamationsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Reclamations)

export default ReclamationsContainer
