import React, {Component, Fragment} from 'react'
import moment from "moment";
import Badge from "../../../../../Commun/Badge/Badge";
import {Field} from "redux-form";
import TextAreaFieldRedux from "../../../../../Commun/Input/Text/TextAreaFieldRedux";
import Decompte from "./Decompte";
import _ from "lodash";
import CheckboxFieldRedux from "../../../../../Commun/Input/Checkbox/CheckboxFieldRedux";

class VirementDetail extends Component {
    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.state = {
            click: false,
            mustValidate: false,
            mustCheck: false,
        };
    }

    componentDidMount() {
        this.props.getAccident({
            query: _.replace(_.replace(this.props.uriGetAccident, '{id}', this.props.virement.contrat_id), '{ids}', this.props.idAccident),
            id: this.props.idAccident
        })
    }

    handleSubmit(valide, form, validateTitulaire) {
        this.setState({click: true})
        if(this.isMustCheck() && valide && validateTitulaire === undefined){
            this.setState({mustValidate: true})
            this.setState({click: false})
        } else {
            form.valide = valide
            this.props.onSubmit(form)
        }
    }

    isMustCheck() {
        return !_.find(this.props.accident?.intervenants, ['titulaire', this.props.virement.tiers?.nom]);
    }

    render() {
        const {click, mustValidate} = this.state
        const {virement, loading, isValidate, handleSubmit, validateTitulaire, accident} = this.props
        const {created_at, numCbt, tiers, codeProduit, typeAccident, montant, reglements, decompte, verification_manager} = virement

        return (
            <div className={'virement-detail-container container'}>
                {!isValidate ?
                    <form className={''} onSubmit={handleSubmit(this.handleSubmit)}>
                        <div className={'row'}>
                            <div className={'col text-center'}>
                                <h2 className={'title-primary text-uppercase'}>Virement en attente pour le sinistre
                                    n°{numCbt}</h2>
                                <p className={'subtitle'}><span
                                    className={'text-uppercase'}>En date du {moment(created_at).format('L')} </span></p>
                            </div>
                        </div>
                        <div className={'header-row row'}>
                            <div className={'col-md-8 offset-md-2'}>
                                <div className={'form-content virement'}>
                                    {this.isMustCheck() && <div className="alert-warning p-2 text-center mb-3">Attention, le titulaire du compte est différent du bénéficiaire du virement</div>}
                                    {(tiers.type === 'Client' && tiers.email === '') &&
                                    <div className={'alert alert-warning'}>
                                        Le client n'a pas d'adresse email de renseigner, le mail avec le décompte ne
                                        pourra pas lui être envoyé.
                                    </div>
                                    }
                                    {verification_manager &&
                                        <div className={'alert alert-warning'}>
                                            Il existe 2 règlements avec un montant identique ou un réglement a déjà été fait pour l'un des postes d'indemnisation.
                                        </div>
                                    }
                                    <h4>Virement d'un montant de <strong>{montant}€</strong></h4>
                                    {
                                        _.find(this.props.accident?.documents, ['code', '7']) &&
                                        <p className={"my-3"}><b>Une prise en charge a été délivrée</b></p>
                                    }
                                    <p>Bénéficiaire : {tiers.nom} <span className={'text-danger'}>{accident?.assureDeces ? '(assuré décédé)' : ''}</span></p>
                                    <p>IBAN : {tiers.iban || '-'}</p>
                                    <p>Titulaire du compte : {tiers.titulaire || '-'}</p>
                                    <p>Produit : {codeProduit}</p>
                                    <p>Type de sinistre : {typeAccident}</p>
                                    {decompte.reglement && <Fragment>
                                        <p>Réglement : <strong>{decompte.reglement || '-'}</strong> d'un montant
                                            de {decompte.montantReglement + '€' || '-'}</p>
                                        {decompte.deduction
                                            ? <p>Déduction : <strong>{decompte.deduction}</strong> d'un montant
                                                de {decompte.montantDeduction + '€'}</p>
                                            : <p>Pas de déduction</p>
                                        }
                                    </Fragment>}

                                    {decompte.indems && decompte.indems[0] && <Decompte decompte={decompte}/>}
                                    {reglements.length > 0 && <div className={'table-virement row'}>
                                        <h2 className={'title-primary text-uppercase'}>Liste des réglements sur le
                                            sinistre</h2>
                                        <div className={'content col-12'}>
                                            <table className={'table'}>
                                                <thead>
                                                <tr>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Raison sociale</th>
                                                    <th scope="col">Montant</th>
                                                    <th scope="col">Statut</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {reglements.map((reglement, index) =>
                                                    <tr key={index}>
                                                        <td>{moment(reglement.date).format('L')}</td>
                                                        <td>{reglement.raisonSociale}</td>
                                                        <td>{reglement.montant}€</td>
                                                        <td>
                                                            <Badge status={reglement.valide} noText/>
                                                        </td>
                                                    </tr>
                                                )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>}

                                    <Field
                                        name={'commentaire'}
                                        component={TextAreaFieldRedux}
                                        label={'Commentaire en cas de refus'}
                                        subLabel={'500 caractères maximum'}
                                        maxLength={'500'}
                                    />

                                    {
                                        this.isMustCheck() && <Field
                                            name={`validation_titulaire`}
                                            component={CheckboxFieldRedux}
                                            label={'Je valide le titulaire du compte'}
                                        />
                                    }
                                    {mustValidate && <div className="alert-danger p-2 text-center mb-3">Le champs "Je valide le titulaire du compte" est obligatoire</div>}
                                </div>
                            </div>
                        </div>

                        {!click && <div className={'row buttons-container'}>
                            <div className={'col text-center'}>
                                <button
                                    className={`btn btn-primary header-row ${this.isMustCheck() && !validateTitulaire && 'disabled' }`}
                                    onClick={handleSubmit(values => this.handleSubmit(1, values, validateTitulaire))}
                                    disabled={loading}
                                >
                                    Valider
                                </button>
                                <button
                                    className={'btn btn-ternary header-row'}
                                    onClick={handleSubmit(values => this.handleSubmit(0, values, validateTitulaire))}
                                    disabled={loading}
                                >
                                    Refuser
                                </button>
                            </div>
                        </div>}
                    </form>
                    : <div className={'text-center'}>
                        <h2 className={'title-primary title-success'}>Le virement est clos !</h2>
                    </div>
                }
            </div>
        )
    }
}

export default VirementDetail
