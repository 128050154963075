import {SET_QUITTANCES} from '../../actions/app/quittances/quittances.actions'

export const quittancesReducer = (quittances = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_QUITTANCES) {
        return payload
    } else {
        return quittances
    }
}
