import React from 'react';
import PropTypes from 'prop-types'
import {redirectInNewWindow} from "../../../../../utils/function";
import moment from "moment";
import {openInWindow} from "../../../../../constants/constants";


const NavigationActivityButton = (props) => {
    const {type, element, tiers_id, contract_id, selected} = props

    let url = '',
        wName = '',
        label = '',
        id = element.id

    if (type === 'tiers') {
        url = `/les-activites/tiers/${element.id}`
        wName = openInWindow.activitiesTiers
        label = element.name
    } else if (type === 'contrat') {
        url = `/les-activites/contrat/${element.id}`
        wName = openInWindow.activitiesContract
        label = element.title
    } else if (type === 'accident') {
        url = `/les-activites/accident/${element.id}/${contract_id}`
        wName = openInWindow.activitiesAccident
        label = `${element.numCbt} (${moment(element.dateSurv).format('L')})`
    } else if (type === 'reclamation') {
        url = `/les-activites/reclamation/${element.id}/${tiers_id}`
        wName = openInWindow.activitiesComplain
        label = element.motif.replace('Réclamation du', `Réclamation n°${element.numero_reclamation} du`)
    }

    return (
        <button
            className={`navigationActivityBtn btn ${type} ${(selected.type === type && selected.id === id.toString()) ? 'btn-primary' : 'btn-secondary'} w-100 mb-4 text-left`}
            onClick={(e) => redirectInNewWindow({e, url: url, name: wName})}>
            <span>{label}</span>
        </button>
    );
};

NavigationActivityButton.defaultProps = {
    element: {},
};

NavigationActivityButton.propTypes = {
    type: PropTypes.string.isRequired,
    element: PropTypes.object,
    selected: PropTypes.exact({
        id: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['tiers', 'contrat', 'accident', 'reclamation']).isRequired,
    }),
};


export default NavigationActivityButton;
