import React, {useState} from 'react';
import RadioSimpleFieldRedux from "../../../../../../../Commun/Input/RadioSimple/RadioSimpleFieldRedux";
import {Field} from "redux-form";
import TextFieldRedux from "../../../../../../../Commun/Input/Text/TextFieldRedux";
import _ from "lodash";
import {normalizeInteger} from "../../../../../../../../utils/normalizeInputs";
import SelectFieldRedux from "../../../../../../../Commun/Input/Select/SelectFieldRedux";

const DetailsReglementCalculFranchise = (props) => {
    const {
        totalFacturesRapportsExperts,
        credit_franchise,
        taux_credit_franchise,
        type_franchise,
        montant_franchise,
        changeValue,
        montant_penalite_franchise,
        pourcentage_franchise,
        valeur_min_franchise,
        valeur_max_franchise,
        franchise_reglee,
        codeProduit,
        cleanFieldsFranchise
    } = props
    const [franchisePourcentage, setFranchisePourcentage] = useState(undefined)
    const [franchiseMin, setFranchiseMin] = useState(undefined)
    const [franchiseMax, setFranchiseMax] = useState(undefined)
    const [franchiseCalculee, setFranchiseCalculee] = useState(undefined)

    const calculFranchiseFixe = () => {
        let franchiseCalcul = _.round(montant_franchise * (1 - taux_credit_franchise), 2);
        setFranchiseCalculee(franchiseCalcul);
        franchiseCalcul = _.round(franchiseCalcul - franchise_reglee, 2)

        if (franchiseCalcul > 0) {
            changeValue(`[penalite][3][montant]`, franchiseCalcul < 0 ? 0 : franchiseCalcul);
        } else {
            changeValue(`[penalite][3][montant]`, undefined);
        }
    }

    const calculFranchiseProportionnelle = () => {
        //     Si c’est inférieure à Crédit Franchise x Mini alors j’affiche le résultat de ce Crédit Franchise x Mini
        //     Si c’est supérieure à Crédit Franchise x Maxi alors j’affiche le résultat de ce Crédit Franchise x Maxi
        //     Si c’est entre Crédit Franchise x Mini et Crédit Franchise x Maxi alors j’affiche le résultat obtenu
        const franchiseMin = _.round(valeur_min_franchise * (1 - taux_credit_franchise), 2);
        const franchiseMax = _.round(valeur_max_franchise * (1 - taux_credit_franchise), 2);
        const tauxFranchise = _.round(pourcentage_franchise * (1 - taux_credit_franchise), 2);
        const franchise = _.round(totalFacturesRapportsExperts * tauxFranchise, 2);
        let franchiseCalcul = undefined;
        setFranchiseMin(franchiseMin);
        setFranchiseMax(franchiseMax);
        setFranchisePourcentage(tauxFranchise * 100);

        if (franchise < franchiseMin) {
            franchiseCalcul = franchiseMin;
        } else if (franchise > franchiseMax) {
            franchiseCalcul = franchiseMax;
        } else if (franchise > 0) {
            franchiseCalcul = franchise;
        } else {
            setFranchiseMin(undefined);
            setFranchiseMax(undefined);
            setFranchisePourcentage(undefined);
        }

        setFranchiseCalculee(_.round(franchiseCalcul, 2));

        franchiseCalcul = _.round(franchiseCalcul - franchise_reglee, 2)
        changeValue(`[penalite][3][montant]`, franchiseCalcul < 0 ? 0 : franchiseCalcul);
    }

    return (
        <div className={'bloc-info'} style={{width: '80%', margin: '0px auto 40px', padding: 30}}>
            <div className={'row'}>
                <div className={'col'}>
                    <Field
                        name={'type_franchise'}
                        component={RadioSimpleFieldRedux}
                        label={'Choisissez le type de la franchise :'}
                        data={[{value: 'fixe', label: 'Fixe'}, {
                            value: 'proportionnelle',
                            label: 'Proportionnelle'
                        }, {value: 'rachat_franchise', label: 'Rachat de franchise'}]}
                        onChange={cleanFieldsFranchise}
                        className={'mb-5'}
                    />
                </div>
                {codeProduit === '7311_MOT1' && <div className={'col'}>
                    <p className={'float-right'}>Crédit franchise : {credit_franchise}</p>
                </div>}
            </div>
            {
                type_franchise && type_franchise === 'fixe' &&
                <>
                    <div className={'row'}>
                        <div className={'col-md-8 my-auto'}>
                            <label>Indiquez le montant de la franchise contractuelle</label>
                        </div>
                        <div className={'col-md-4'}>
                            <Field
                                name='montant_franchise'
                                component={TextFieldRedux}
                                label={false}
                            />
                        </div>
                    </div>
                    <button
                        type={'button'}
                        onClick={calculFranchiseFixe}
                        className={'btn btn-primary btn-small mb-3'}
                        disabled={!montant_franchise}
                    >
                        Calculer la franchise
                    </button>
                </>
            }
            {
                type_franchise && type_franchise === 'proportionnelle' &&
                <>
                    <div className={'row mb-3'}>
                        <div className={'col'}>
                            <Field
                                name='pourcentage_franchise'
                                component={TextFieldRedux}
                                label={'Pourcentage'}
                                normalize={normalizeInteger}
                            />
                            {montant_penalite_franchise ?
                                <p>La valeur calculée est : {franchisePourcentage}%</p> : null}
                        </div>
                        <div className={'col'}>
                            <Field
                                name='valeur_min_franchise'
                                component={TextFieldRedux}
                                label={'Valeur minimum'}
                            />
                            {montant_penalite_franchise ? <p>La valeur calculée est : {franchiseMin}</p> : null}
                        </div>
                        <div className={'col'}>
                            <Field
                                name='valeur_max_franchise'
                                component={TextFieldRedux}
                                label={'Valeur maximum'}
                            />
                            {montant_penalite_franchise ? <p>La valeur calculée est : {franchiseMax}</p> : null}
                        </div>
                    </div>
                    <p><i className={'fw-500'}>Exemple : 10% mini 400 maxi 800</i></p>

                    <button
                        type={'button'}
                        onClick={calculFranchiseProportionnelle}
                        className={'btn btn-primary btn-small mb-3'}
                        disabled={!pourcentage_franchise || !valeur_min_franchise || !valeur_max_franchise}
                    >
                        Calculer la franchise
                    </button>
                </>
            }
            {
                type_franchise && type_franchise === 'rachat_franchise' &&
                <Field
                    name={`[penalite][5][valeur]`}
                    component={SelectFieldRedux}
                    label={`Rachat de franchise`}
                >
                    <option value={null}>Sélectionner la franchise</option>
                    <option value={'vol'}>Vol</option>
                    <option value={'accident'}>Tous accident</option>
                </Field>
            }

            {franchiseCalculee ? <p>La franchise est de : <strong>{franchiseCalculee}€</strong></p> : null}
        </div>
    );
};

export default DetailsReglementCalculFranchise;
