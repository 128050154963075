import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import {change, reduxForm, formValueSelector, getFormValues} from 'redux-form'
import InitForm from './InitForm'
import ErrorHttpComponent from "../../../../../Commun/Error/ErrorHttpComponent";
import {
    getDocumentsAccident,
    POST_ACCIDENT,
    postAccident,
    postRejectAccident
} from "../../../../../../redux/actions/app/accidents/accidents.actions";
import moment from "moment";
import {getDocumentsNeededForAccident, getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {
    getContractCodeProduit, getContractGarantiesDA,
    getContractGarantiesOptions, getContractPrelevement, getIsFleetContract, getIsWarkamContract
} from "../../../../../../redux/selectors/contract/contract.selectors";
import validate from './validate'
import {
    accidentsRejectContractGetLink,
    casIdaLink,
    documentsNeededAccidentGet
} from "../../../../../../redux/selectors/links/links.selectors";
import _ from "lodash";
import {logAction} from "../../../../../../redux/actions/core/logs/logs.actions";
import {CAS_IDA, postCasIda} from "../../../../../../redux/actions/app/cas_ida/cas_ida.actions";
import {getUserIdBelair, getUserRoles} from "../../../../../../redux/selectors/user/user.selectors";
import {getGarantiesFlotteDetailData} from "../../../../../../redux/selectors/flotte/flotte.selectors";
import loader from "../../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector('constat')
    const {idVehicule} = ownProps

    return {
        formValues: getFormValues("constat")(state),
        isWakam: getIsWarkamContract(state),
        uri: ownProps.contract.accident_claim_link,
        uriRejectAccident: accidentsRejectContractGetLink(state),
        idContract: ownProps.contract.id,
        categorie: selector(state, 'catsinorigin'),
        vehicule: selector(state, 'vehicule'),
        identify: selector(state, 'indentify'),
        temoin: selector(state, 'temoin'),
        blesse: selector(state, 'blesse'),
        constatCheck: selector(state, 'constat_accident'),
        constatTiersCheck: selector(state, 'constat_sign_tiers'),
        constatAssureCheck: selector(state, 'constat_sign_assure'),
        type_sinistre: selector(state, 'type_sinistre'),
        vehiculeDommage: selector(state, 'vehiculeDommage'),
        conducteur: selector(state, 'conduct'),
        adversaireNom: selector(state, 'adversaires[0].nom'),
        adversairePrenom: selector(state, 'adversaires[0].prenom'),
        loading: getLoadingEntity(state, POST_ACCIDENT),
        garanties: getIsFleetContract(state) ? getGarantiesFlotteDetailData(state) : getContractGarantiesOptions(state),
        initialValues: {
            'conduct': getIsFleetContract(state) ? "" : ownProps.contract.souscripteur.nom,
            'datesurvorigin': moment(),
            'corpoconduct': 'F',
            'temoin': 'F',
            'vehiculeDommage': 'F',
            'risque': idVehicule || ownProps.contract.adhesionPrincipale.toString(),
            'blesse': 'F',
            'type_contrat': ownProps.contract.vehicule.codeProduit === '7311_VAE' ? 'VAE' : idVehicule ? 'flotte' : null,
            'type_sinistre': 'accident',
            'assure_conducteur': ownProps.contract.souscripteur.nom,
            'equipementsDommageCasque': 'F',
            'equipementsDommageGants': 'F',
            'equipementsDommageBlouson': 'F',
            'equipementsDommageBottes': 'F',
            'equipementsDommageAirbag': 'F',
            'equipementsDommageAutre': 'F',
            'hasDta': _.includes(getContractGarantiesDA(state)[0], 'DA'),
        },
        listDocuments: getDocumentsNeededForAccident(state) || [],
        uriCasIda: casIdaLink(state),
        loadedIda: getLoadingEntity(state, CAS_IDA) === false,
        uriGetDocument: documentsNeededAccidentGet(state),
        codeProduit: getContractCodeProduit(state),
        hasDta: _.includes(getContractGarantiesDA(state)[0], 'DA'),
        roles: getUserRoles(state),
        belairId: getUserIdBelair(state),
        isPrelevement: getContractPrelevement(state),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
    changeValue: (field, value) => change('constat', field, value),
    postAccident: ({form, query, documents}) => postAccident({form, query, documents}),
    postRejectAccident: ({cause, query}) => postRejectAccident({query, cause}),
    logAction: () => logAction({
        entity: POST_ACCIDENT,
        type: 'SHOW_REPORT_ACCIDENT',
        contrat: ownProps.contract.id,
        tiers: ownProps.contract.souscripteur.id
    }),
    postCasIda: ({vehicule_a, vehicule_a_bis, vehicule_b, vehicule_b_bis, type, query}) => postCasIda({
        vehicule_a,
        vehicule_a_bis,
        vehicule_b,
        vehicule_b_bis,
        type,
        query
    }),
    getDocumentsAccident: ({query}) => getDocumentsAccident({query}),
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {postAccident, postRejectAccident, postCasIda, getDocumentsAccident} = dispatchProps
    const {
        uri,
        uriRejectAccident,
        idContract,
        listDocuments,
        uriCasIda,
        uriGetDocument,
        codeProduit,
        hasDta,
        isPrelevement
    } = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form) => postAccident({form, query: uri, documents: listDocuments}),
        postRejectAccident: (cause) => postRejectAccident({
            cause,
            query: _.replace(uriRejectAccident, '{id}', idContract)
        }),
        postCasIda: ({vehicule_a, vehicule_a_bis, vehicule_b, vehicule_b_bis, type}) => postCasIda({
            vehicule_a,
            vehicule_a_bis,
            vehicule_b,
            vehicule_b_bis,
            type,
            query: uriCasIda
        }),
        getDocumentsAccident: (categorie, equipements, doc_police) => getDocumentsAccident({
            query: _.replace(_.replace(_.replace(_.replace(_.replace(_.replace(_.replace(uriGetDocument,
                                    '{souscategorie}', categorie[1]),
                                '{categorie}', categorie[0]),
                            '{codeProduit}', codeProduit),
                        '/{docPolice?}', doc_police !== undefined ? '/' + doc_police : '/' + null),
                    '/{DTA?}', '/' + hasDta),
                '/{DommageACCESSEQP?}', equipements !== undefined ? '/' + equipements : '/' + false),
                '/{prelevement?}', '/' + isPrelevement),
        }),
    }
}

const InitFormContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'constat',
        validate
    }),
    loader({ErrorIndicator: ErrorHttpComponent})
)(InitForm)

export default InitFormContainer
