import React from 'react'
import moment from "moment";

const RappelLRNPP = (props) => {
    const {task: {date, type, paniere, tiers_id}} = props

    return (
        <div className={'container'}>
            <div className={'row'}>
                <div className={'col text-center'}>
                    <h2 className={'title-primary text-uppercase'}>Tâche {paniere} - Tiers n°{tiers_id}</h2>
                    <p className={'subtitle'}>Tâche du {moment(date).format('L')}. Recu par {type}</p>
                </div>
            </div>
            <div className={'header-row row'}>
                <div className={'col-12'}>
                    <p className={'form-content text-center'}>
                        Merci d'appeler le client avant l'envoi de la LR pour tenter d'obtenir le règlement par carte
                        bancaire.<br/><br/>
                        Attention à bien vérifier s'il y a un acompte client ou si la quittance est soldée.<br/><br/>
                        S'il n'y a pas d'acompte OU que la quittance n'est pas soldée, vous pouvez télécharger le
                        document "Courrier LRNPP" et le glisser dans le répertoire pour envoi via Neopost.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default RappelLRNPP
