import React, {Component} from 'react';
import {FieldArray} from "redux-form";
import AccessoiresFieldArray from "./AccessoiresFieldArray";
import Spinner from "../../../../../Commun/Spinner/Spinner";
import MyButton from "../../../../../Commun/Button/Button";
import APIFrame from "../../../../../Commun/APILink/APIFrame";

class Facture extends Component {
    render() {
        const {task, files, accessoires, change, loadingTerminate, loadingAddFacture, handleSubmit} = this.props

        return (
            <div className="row">
                {files &&
                    <div className={'col-8 left-facture'} style={{marginBottom: 30}}>
                        {files.map((file, index) =>
                            <APIFrame src={`${file.url}`} title={'Facture'} frameBorder="0"
                                    style={{height: '400px', width: '100%', marginBottom: 30}} key={index}/>
                        )}
                    </div>
                }
                <div className={`${files ? 'col-4 right-facture' : 'offset-md-3 col-6'}`}>
                    <form className={`form-content`} onSubmit={handleSubmit}>
                        <FieldArray
                            name={'accessoires'}
                            component={AccessoiresFieldArray}
                            accessoires={accessoires}
                            dateSurv={task.sinistre.dateSurv}
                            reparateur={task.sinistre?.reparateur}
                            change={change}
                        />
                        {loadingTerminate ? <Spinner/>
                            : <MyButton
                                variant='raised'
                                color='secondary'
                                size='large'
                                className={'btn btn-primary mt-5'}
                                disabled={loadingAddFacture}
                            >
                                {loadingAddFacture && <Spinner absolute/>}
                                Valider
                            </MyButton>
                        }
                    </form>
                </div>
            </div>
        );
    };
}

export default Facture;
