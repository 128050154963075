import {connect} from 'react-redux'
import {compose} from "redux";
import {
    addAccidentExpertGetLink,
    deleteAccidentExpertGetLink
} from "../../../../../redux/selectors/links/links.selectors";
import Expert from "./Expert";
import _ from "lodash";
import {postAddExpert, postDeleteExpert} from "../../../../../redux/actions/app/accidents/accidents.actions";

const mapStateToProps = (state, ownProps) => {
    return {
        uri_delete: deleteAccidentExpertGetLink(state),
        uri_add: addAccidentExpertGetLink(state),
        accidentId: ownProps.accidentId,
        contractId: ownProps.contractId,
    }
}

const mapDispatchToProps = {
    postDeleteExpert,
    postAddExpert
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uri_add, uri_delete, contractId, accidentId} = stateProps
    const {postDeleteExpert, postAddExpert} = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        submitDeleteExpert: () => postDeleteExpert({query: _.replace(_.replace(uri_delete, '{id}', contractId), '{ids}', accidentId)}),
        submitPostAddExpert: () => postAddExpert({query: _.replace(_.replace(uri_add, '{id}', contractId), '{ids}', accidentId)})
    }
}

const ExpertContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(Expert)

export default ExpertContainer
