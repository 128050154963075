export const VERSIONNING = '[VERSIONNING]'
export const VALIDATE_VERSIONNING = '[VALIDATE_VERSIONNING]'
export const DELETE_VERSIONNING = '[DELETE_VERSIONNING]'

export const GET_VERSIONNING = `${VERSIONNING} Get`
export const SET_VERSIONNING = `${VERSIONNING} Set`

export const POST_VALIDATE_VERSIONNING = `${VALIDATE_VERSIONNING} Post`
export const SET_VALIDATE_VERSIONNING = `${VALIDATE_VERSIONNING} Set`
export const POST_DELETE_VERSIONNING = `${DELETE_VERSIONNING} Post Delete`

export const getVersionning = ({query}) => ({
    type: GET_VERSIONNING,
    payload: {
        data: query
    }
})

export const setVersionning = ({data}) => ({
    type: SET_VERSIONNING,
    payload: {data},
    meta: {
        entity: VERSIONNING
    }
})

export const postValidateVersionning = ({query, id}) => ({
    type: POST_VALIDATE_VERSIONNING,
    payload: {
        data: query,
        body: {id}
    }
})

export const setValidateVersionning = ({state}) => ({
    type: SET_VALIDATE_VERSIONNING,
    payload: state
})


export const postDeleteVersionning = ({query, id}) => ({
    type: POST_DELETE_VERSIONNING,
    payload: {
        data: query,
        body: {id}
    }
})