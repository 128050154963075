import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import DevisDetail from './DevisDetail'
import {cancelQuoteLink, getQuoteLink} from "../../../../../redux/selectors/links/links.selectors";
import _ from 'lodash';
import {
    CANCEL_QUOTE,
    cancelQuote,
    FETCH_QUOTE,
    fetchQuote,
    QUOTE
} from "../../../../../redux/actions/app/quotes/quotes.actions";
import {getErrorEntity, getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {
    getAccountQuote,
    getActeGestionQuote,
    getApporteur1, getApporteur2, getCodePromoQuote,
    getConducteurQuote,
    getCreatedAtQuote,
    getDateEffetQuote,
    getFormuleChoisieLibelleQuote,
    getFractionnementQuote,
    getHonorairesQuote, getIdQuote,
    getLibelleProduitQuote, getMsgFeuQuote,
    getPrimeFAQuote,
    getPrimeFractionneTTCQuote, getQuote, getRealisateurName,
    getSouscripteurQuote, getStatutQuote,
    getTauxApporteur1Quote, getUrlPdfContract,
    getUrlPdfQuote,
    getUrlRepriseQuote,
    getUrlSouscriptionQuote,
    getVehiculeQuote
} from "../../../../../redux/selectors/quotes/quotes.selectors";
import moment from 'moment'
import loader from "../../../../Commun/Spinner/Loader/Loader";
import {getUserRoles} from "../../../../../redux/selectors/user/user.selectors";

const mapStateToProps = (state, ownProps) => {
    const {id} = ownProps.match.params
    const quote = getQuote(state)

    return {
        quote: {
            ...quote,
            id: getIdQuote(state),
            libelleFormule: getFormuleChoisieLibelleQuote(state),
            libelleProduit: getLibelleProduitQuote(state),
            endValidity: moment(getCreatedAtQuote(state)).add(30, 'days').format('L'),
            desiredDate: getDateEffetQuote(state) ? moment(getDateEffetQuote(state)).format('L') : '-',
            createdDate: getCreatedAtQuote(state),
            fractionnement: getFractionnementQuote(state),
            primeFractionneTTC: getPrimeFractionneTTCQuote(state),
            primeFA: getPrimeFAQuote(state),
            tauxApporteur: getTauxApporteur1Quote(state),
            honoraire: getHonorairesQuote(state),
            urlSouscription: getUrlSouscriptionQuote(state),
            urlReprise: getUrlRepriseQuote(state),
            urlPdf: getUrlPdfQuote(state),
            urlPdfContrat: getUrlPdfContract(state),
            souscripteur: getSouscripteurQuote(state),
            conducteur: getConducteurQuote(state),
            vehicule: getVehiculeQuote(state),
            statut: getStatutQuote(state),
            realisateur_name: getRealisateurName(state),
            apporteur1: getApporteur1(state),
            apporteur2: getApporteur2(state),
            acteGestion: getActeGestionQuote(state),
            account_id: getAccountQuote(state),
            codePromo: getCodePromoQuote(state),
            messageFeuTricolore: getMsgFeuQuote(state)
        },
        is_gestionnaire_commercial: _.includes(getUserRoles(state), 'gestionnaire commercial'),
        is_gestionnaire_televente: _.includes(getUserRoles(state), 'gestionnaire televente') || _.includes(getUserRoles(state), 'manager televente'),
        query: _.replace(getQuoteLink(state), '{id}', id),
        queryCancel: _.replace(cancelQuoteLink(state), '{id}', id),
        loaded: getLoadingEntity(state, QUOTE) === false,
        error: getErrorEntity(state, QUOTE),
        loading: getLoadingEntity(state, FETCH_QUOTE),
        loadingCancel: getLoadingEntity(state, CANCEL_QUOTE),
        roles: getUserRoles(state)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchQuote,
    cancelQuote
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {fetchQuote, cancelQuote} = dispatchProps
    const {query, queryCancel} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        cancelQuote: () => cancelQuote({query: queryCancel}),
        load: () => fetchQuote({query})
    }
}

const DevisDetailContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(DevisDetail)

export default DevisDetailContainer
