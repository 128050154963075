import {SET_COURTIERS} from '../../actions/app/courtiers/courtiers.actions'

export const courtiersReducer = (courtiers = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_COURTIERS) {
        return payload.data
    } else {
        return courtiers
    }
}
