import _ from 'lodash';
import moment from 'moment';
import { Field } from 'redux-form';
import { normalizeBooleanForNoobBelair } from '../../../../../../utils/normalizeInputs';
import CheckboxFieldRedux from '../../../../../Commun/Input/Checkbox/CheckboxFieldRedux';
import DatePickerFieldRedux from '../../../../../Commun/Input/DatePicker/DatePickerFieldRedux';
import SelectFieldRedux from '../../../../../Commun/Input/Select/SelectFieldRedux';
import TextAreaFieldRedux from '../../../../../Commun/Input/Text/TextAreaFieldRedux';
import Spinner from '../../../../../Commun/Spinner/Spinner';
import {useEffect} from "react";

export const canalArrivee = [
    { value: 'FORME', label: 'Formulaire de contact' },
    { value: 'EMAIE', label: 'Email entrant' },
    { value: 'COURE', label: 'Courrier entrant' },
    { value: 'TELE', label: 'Télephone entrant' },
    { value: 'FDBCK', label: 'Feedback' },
    { value: 'RESC', label: 'Réseaux sociaux' },
];

export const natureRecla = [
    { value: '17', label: 'Accessibilité aux services APRIL Moto' },
    { value: '38', label: 'Accessoires et équipements' },
    { value: '21', label: 'APRIL Moto - Refus de résiliation' },
    { value: '42', label: 'Chiffrage expert' },
    { value: '22', label: 'Client - Refus de la résiliation' },
    { value: '04', label: 'Conseils trompeurs' },
    { value: '23', label: 'Contestation contentieux' },
    { value: '24', label: 'Délais compagnie' },
    { value: '25', label: 'Délais de remboursement' },
    { value: '26', label: 'Délais expert' },
    { value: '27', label: 'Délais intervention dépanneur' },
    { value: '43', label: 'Délais instruction' },
    { value: '02', label: 'Délais de traitement' },
    { value: '20', label: 'Désaccord sur application des règles' },
    { value: '36', label: 'Désaccord sur la date de résiliation' },
    { value: '37', label: 'Désaccord Bonus' },
    { value: '13', label: 'Frais' },
    { value: '40', label: 'Frais de gardiennage' },
    { value: '07', label: 'Litiges sur primes' },
    { value: '34', label: 'Litiges sur Responsabilités' },
    { value: '28', label: 'Mauvais ou non traitement de la demande' },
    { value: '29', label: 'Montant du remboursement indemnisation' },
    { value: '19', label: 'Non réception des documents' },
    { value: '41', label: 'Pénalité retenue' },
    { value: '30', label: 'Plafond dépanneur' },
    { value: '44', label: 'Process compagnie' },
    { value: '31', label: 'Qualité dépanneur' },
    { value: '32', label: 'Refus intervention assistance' },
    { value: '11', label: 'Sinistre non-couvert' },
    { value: '39', label: 'Vetusté' },
];

export const momentVie = [
    { value: 'A', label: 'Devis / Souscription' },
    { value: 'C', label: 'Gestion de contrat' },
    { value: 'B', label: 'Tarification / Prime' },
    { value: 'D', label: 'Indemnisation' },
    { value: 'F', label: 'Résiliation / Renonciation' },
    { value: 'E', label: 'Assistance' },
];

export const apporteurs = [
    { value: '7311', label: 'Allianz' },
    { value: '33220', label: 'Allianz Protection Juridique' },
    { value: '35', label: 'Mondial' },
    { value: '2955', label: 'Solucia' },
    { value: '1715', label: 'Caarea' },
    { value : '519943', label: 'Wakam' }
];

export const niveauRecla = [
    { value: '1', label: 'Gestionnaire' },
    { value: '2', label: 'Responsable des réclamations' },
    { value: '3', label: 'Médiateur' },
    { value: '4', label: 'Tribunal' },
];

export const qualiteEmetteur = [
    { value: '1', label: 'Courtier' },
    { value: '2', label: 'Assuré' },
    { value: '3', label: 'Compagnie' },
    { value: '4', label: 'Tiers' },
    { value: '5', label: 'Avocat' },
    { value: '6', label: 'Association de défense' },
    { value: '7', label: 'Prospect' },
];

const ReclamationEdit = (props) => {
    const { handleSubmit, loading, complain } = props;

    useEffect(() => {

        props.initialize({
            date_reception: moment(complain.date_reception),
            description: complain.description,
            niveau_recla: complain.niveau_recla ? niveauRecla.find((n) => n.label.toLowerCase() === complain.niveau_recla.toLowerCase()).value : '',
            nature: complain.nature ? natureRecla.find((n) => n.label.toLowerCase() === complain.nature.toLowerCase())?.value : '',
            moment_vie: complain.moment_vie ? momentVie.find((m) => m.label.toLowerCase() === complain.moment_vie.toLowerCase()).value : '',
            assureur: complain.assureur.id,
            qualite_emetteur: complain.qualite_emetteur ? qualiteEmetteur.find((q) => q.label.toLowerCase() === complain.qualite_emetteur.toLowerCase()).value : '',
            tiers: complain.id_tiers,
            contract: complain.contrat.id,
        });
    }, []);

    return (
        <div className={'contract-container container'}>
            <div className={'row return-content'}>
                <div className={'col-2'}>
                    <button onClick={() => props.history.go(-1)} className={'btn btn-return'}>
                        Retour
                    </button>
                </div>
            </div>

            <div className={'row'}>
                <div className={'col-12'}>
                    <h1 className={'title-primary'}>
                        N° {complain.id} - {complain.motif}
                    </h1>
                </div>
            </div>
            <div className="row header-row">
                <div className={'col-12'}>
                    <form className={`form-content`} onSubmit={handleSubmit}>
                        <h2 className="title-primary text-center d-block">Modifier une réclamation</h2>

                        <div className="row">
                            {/*<div className="col-6">*/}
                            {/*    <Field component={SelectFieldRedux} name="arrive" label={"Canal d'arrivée"}>*/}
                            {/*        <option value={''}>Choisissez un canal d'arrivée</option>*/}
                            {/*        {canalArrivee.map((canal) => (*/}
                            {/*            <option key={canal.value} value={canal.value}>*/}
                            {/*                {canal.label}*/}
                            {/*            </option>*/}
                            {/*        ))}*/}
                            {/*    </Field>*/}
                            {/*</div>*/}
                            <div className="col-6">
                                <Field
                                    name={'date_reception'}
                                    component={DatePickerFieldRedux}
                                    label={'Date de la réclamation'}
                                    maxDate={moment()}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <Field
                                    name={'description'}
                                    component={TextAreaFieldRedux}
                                    label={'Description du mécontentement'}
                                    subLabel={'255 caractères maximum'}
                                    maxLength={'255'}
                                    rows="4"
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <Field component={SelectFieldRedux} name="nature" label={'Nature de la réclamation'}>
                                    <option value={''}>Choisissez la nature de la réclamation</option>
                                    {natureRecla.map((nature) => (
                                        <option key={nature.value} value={nature.value}>
                                            {nature.label}
                                        </option>
                                    ))}
                                </Field>
                            </div>

                            <div className="col-6">
                                <Field component={SelectFieldRedux} name="moment_vie" label={'Moment de vie'}>
                                    <option value={''}>Choisissez un moment de vie</option>
                                    {momentVie.map((moment) => (
                                        <option key={moment.value} value={moment.value}>
                                            {moment.label}
                                        </option>
                                    ))}
                                </Field>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <Field component={SelectFieldRedux} name="assureur" label={"L'assureur"}>
                                    <option value={''}>Choisissez l'assureur</option>
                                    {apporteurs.map((apporteur) => (
                                        <option key={apporteur.value} value={apporteur.value}>
                                            {apporteur.label}
                                        </option>
                                    ))}
                                </Field>
                            </div>
                            <div className="col-6">
                                <Field
                                    component={SelectFieldRedux}
                                    name="niveau_recla"
                                    label={'Niveau de la réclamation'}
                                >
                                    <option value={''}>Choisissez le niveau</option>
                                    {niveauRecla.map((niveau) => (
                                        <option key={niveau.value} value={niveau.value}>
                                            {niveau.label}
                                        </option>
                                    ))}
                                </Field>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <Field
                                    component={SelectFieldRedux}
                                    name="qualite_emetteur"
                                    label={"Qualité de l'émetteur"}
                                >
                                    <option value={''}>Choisissez la qualité de l'emetteur</option>
                                    {qualiteEmetteur.map((qualite) => (
                                        <option key={qualite.value} value={qualite.value}>
                                            {qualite.label}
                                        </option>
                                    ))}
                                </Field>
                            </div>
                        </div>

                        {loading ? (
                            <span className={'d-block text-center'}>
                                <Spinner />
                            </span>
                        ) : (
                            <div className={'d-flex justify-content-between'}>
                                <button className={'btn btn-primary btn-inline-block mt-3'} type={'submit'}>
                                    Enregistrer
                                </button>
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
};

ReclamationEdit.defaultProps = {};

ReclamationEdit.propTypes = {};

export default ReclamationEdit;
