import {connect} from 'react-redux'
import {getFilesTask} from "../../../../../../redux/selectors/tasks/task.selectors";
import KofaxDetail from './KofaxDetail'
import {bindActionCreators} from "redux";
import {logAction} from '../../../../../../redux/actions/core/logs/logs.actions'
import {TASK} from "../../../../../../redux/actions/app/tasks/tasks.actions";

const mapStateToProps = state => {
    return {
        files: getFilesTask(state),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
    logAction: () => logAction({
        entity: TASK,
        type: 'SHOW_TASK_CONFORMITY',
        contrat: ownProps.task.contract ? ownProps.task.contract.id : '',
        tiers: ownProps.task.contract ? ownProps.task.contract.souscripteur.id : ''
    })
}, dispatch)

const KofaxDetailContainer = connect(mapStateToProps, mapDispatchToProps)(KofaxDetail)

export default KofaxDetailContainer
