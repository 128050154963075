import React from 'react';
import './declaration_accident_navigation.scss';
import {Link} from "react-router-dom";
import _ from "lodash";

const DeclarationAccidentNavigation = (props) => {
    const {isWakam, linksDisabled, contract, roles, belairId, idVehicule} = props

    /* Liste belair ID des users qui ont accès au nouveau formulaire */
    const belairIdAuth = [547754, 500054, 500002, 500236, 549069, 548961, 500038, 500040, 500067, 514346, 500161, 500083, 500004, 500249, 550841, 500035]

    return (
        <div className={'navigation-decla-accident container'}>
            <div className="row header-row">
                {
                    !linksDisabled ?
                        <>
                            <div className={'col-12 col-md-6 col-xl-3 mb-4'}>
                                <Link
                                    to={_.includes(belairIdAuth, belairId) && _.includes(roles, 'gestionnaire indem') ? `/contrat/${contract.id}/declaration-accident/${idVehicule}` : `/contrat/${contract.id}/declaration-accident-bo/${idVehicule}`}
                                    className={`bloc-icon d-block text-center py-5 px-2`}>
                                    <div className={'icone icone-accident'}/>
                                    <div className={'d-block mt-4'}>
                                        <p className={'m-0'}>Déclarer un sinistre</p>
                                        <span className={'font-weight-bold'}>Accident</span>
                                    </div>
                                </Link>
                            </div>
                            <div className={'col-12 col-md-6 col-xl-3 mb-4'}>
                                <Link to={`/contrat/${contract.id}/declaration-vol/${idVehicule}`}
                                      className={`bloc-icon d-block text-center py-5 px-2`}>
                                    <div className={'icone icone-vol'}/>
                                    <div className={'d-block mt-4'}>
                                        <p className={'m-0'}>Déclarer un sinistre</p>
                                        <span className={'font-weight-bold'}>Accident-Vol-Tentative de Vol</span>
                                    </div>
                                </Link>
                            </div>
                            <div className={'col-12 col-md-6 col-xl-3 mb-4'}>
                                <Link to={`/contrat/${contract.id}/declaration-autres/${idVehicule}`}
                                      className={`bloc-icon d-block text-center py-5 px-2`}>
                                    <div className={'icone icone-autres'}/>
                                    <div className={'d-block mt-4'}>
                                        <p className={'m-0'}>Déclarer un sinistre</p>
                                        <span className={'font-weight-bold'}>Autres</span> (incendie, vandalisme,
                                        événement climatique…)
                                    </div>
                                </Link>
                            </div>
                            {
                                !isWakam && <div className={'col-12 col-md-6 col-xl-3 mb-4'}>
                                    <Link to={`/contrat/${contract.id}/declaration-non-declare/${idVehicule}`}
                                          className={`bloc-icon d-block text-center py-5 px-2`}>
                                        <div className={'icone icone-non-dec'}/>
                                        <div className={'d-block mt-4'}>
                                            <p className={'m-0'}>Déclarer un sinistre</p>
                                            <span className={'font-weight-bold'}>non-déclaré</span>
                                        </div>
                                    </Link>
                                </div>
                            }
                        </>
                        :
                        <p className={'col-12 text-center'}>Vous ne pouvez pas déclarer de sinistre sur ce contrat.</p>
                }
            </div>
        </div>
    );
};

export default DeclarationAccidentNavigation;
