import {
    GET_CORRESPONDANTS,
    setCorrespondants,
    CORRESPONDANTS,
} from '../../../actions/app/correspondants/correspondants.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'

export const correspondantsMiddleware = () => next => action => {
    next(action)

    switch (action.type) {

        case GET_CORRESPONDANTS:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: CORRESPONDANTS}),
                setLoader({state: true, entity: CORRESPONDANTS})
            ])
            break

        case `${CORRESPONDANTS} ${API_SUCCESS}`:
            next([
                setCorrespondants({correspondants: action.payload.data}),
                setError({state: false, entity: CORRESPONDANTS}),
                setLoader({state: false, entity: CORRESPONDANTS})
            ])
            break

        case `${CORRESPONDANTS} ${API_ERROR}`:
            next([
                setError({state: true, entity: CORRESPONDANTS}),
                setLoader({state: false, entity: CORRESPONDANTS})
            ])
            break

        default:
            break
    }
    return null
}
