import React from 'react';

const Toggle = ({darkMode, setDarkMode}) => {
    return (
        <div className="toggle-control">
            <input
                className="dmcheck"
                type="checkbox"
                checked={darkMode}
                onChange={() => setDarkMode(!darkMode)}
                id="dmcheck"
            />
            <label htmlFor="dmcheck"/>
        </div>
    )
};

export default Toggle;
