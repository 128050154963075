import {connect} from 'react-redux'
import {compose} from "redux";
import FormChangeDateExpertVisitAccident from "./FormChangeDateExpertVisitAccident";
import {reduxForm} from "redux-form";
import _ from "lodash";
import {
    POST_CHANGE_DATE_EXPERT_VISIT,
    postChangeDateExpertVisitAccident
} from "../../../../../../redux/actions/app/accidents/accidents.actions";
import {changeAccidentExpertVisitGetLink} from "../../../../../../redux/selectors/links/links.selectors";
import {getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {getGarageData} from "../../../../../../redux/selectors/garage/garage.selectors";

const mapStateToProps = (state, ownProps) => {
    const garage = getGarageData(state);
    return {
        uri: changeAccidentExpertVisitGetLink(state),
        contractId: ownProps.match.params.id,
        accidentId: ownProps.match.params.ids,
        loading: getLoadingEntity(state, POST_CHANGE_DATE_EXPERT_VISIT),
        garage: garage,
        initialValues: {
            visit: garage && garage.datesVisite ? garage.datesVisite[0] : null
        }
    }
}

const mapDispatchToProps = {
    postChangeDateExpertVisitAccident
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uri, contractId, accidentId} = stateProps
    const {postChangeDateExpertVisitAccident} = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: form => postChangeDateExpertVisitAccident({
            query: _.replace(_.replace(uri, '{id}', contractId), '{ids}', accidentId),
            form
        })
    }
}

const FormChangeDateExpertVisitAccidentContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'change-date-expert-visit'
    })
)(FormChangeDateExpertVisitAccident)

export default FormChangeDateExpertVisitAccidentContainer
