import React, {Fragment} from 'react'
import InfoSupCard from '../../../../Commun/Card/InfoSupCard'
import CallToAction from "../../../../Commun/Button/CallToAction/CallToAction";
import {Link, NavLink} from "react-router-dom";
import _ from "lodash";
import Spinner from "../../../../Commun/Spinner/Spinner";

const Intervenants = ({intervenants, conducteur, compagnieAdverse, contract, accident, idCompagnieAdverse, roles, permissions}) => {
    return (
        <div className={'bloc-info bloc-intervenants'}>
            <h2>Intervenants</h2>
            <div className={'content row'}>
                <div className={'col-5 left-content'}>
                    <p>Conducteur :</p>
                </div>
                <div className={'col-7 right-content'}>
                    <p>{conducteur}
                        {!_.includes(roles, 'gestionnaire televente') &&
                            <CallToAction
                                url={{
                                    pathname: `/contrat/${contract}/sinistre/${accident}/modification-conducteur`,
                                    state: {
                                        contract: contract,
                                        accident: accident
                                    }
                                }}
                                action={'edit-repairer'}
                                toolText={`Modifier le conducteur`}
                            />
                        }
                    </p>
                </div>
                <div className={'col-5 left-content'}>
                    <p>Compagnie adverse :</p>
                </div>
                <div className={'col-7 right-content'}>
                    <p>{compagnieAdverse ? <Link to={`/tiers/${idCompagnieAdverse}`}>{compagnieAdverse}</Link> : "-"}
                        {!_.includes(roles, 'gestionnaire televente') &&
                            <CallToAction
                                url={{
                                    pathname: `/contrat/${contract}/sinistre/${accident}/modification-compagnie`,
                                    state: {
                                        compagnie: idCompagnieAdverse,
                                        adverseNb: 1,
                                        contract: contract,
                                        accident: accident
                                    }
                                }}
                                action={'edit-repairer'}
                                toolText={`${idCompagnieAdverse ? 'Modifier' : 'Ajouter'} la compagnie adverse`}
                            />
                        }
                    </p>
                </div>
                {intervenants.map((intervenant, index) => (
                    <Fragment key={index}>
                        <div className={'col-5 left-content'}>
                            <p>{intervenant.type} :</p>
                        </div>
                        <div className={'col-7 right-content'}>
                            <InfoSupCard index={index} info={intervenant}/>
                        </div>
                    </Fragment>
                ))}
            </div>
            <div className={'content row'}>
                <div className={'col-5 left-content text-right'}>
                    <NavLink to={`/contrat/${contract}/sinistre/${accident}/ajout-intervenant`} className="btn btn-cta btn-ternary mt-3 position-relative">
                        Ajouter un intervenant
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default Intervenants
