export const FAQ_CAT = '[FaqCategories]'

export const GET_FAQ_CAT = `${FAQ_CAT} Get`
export const SET_FAQ_CAT = `${FAQ_CAT} Set`

export const getFaqCat = ({query}) => ({
    type: GET_FAQ_CAT,
    payload: {
        data: query
    }
})

export const setFaqCat = ({faq_categories}) => ({
    type: SET_FAQ_CAT,
    payload: {
        data: faq_categories
    }
})
