import {connect} from 'react-redux'
import {formValueSelector} from 'redux-form'
import Police from './Police'

const mapStateToProps = state => {
    const selector = formValueSelector('constat')

    return {
        interventionPolice: selector(state, 'intervention_police'),
        catsin: selector(state, 'catsinorigin'),
        equipementsDommage: selector(state, 'equipementsDommage'),
    }
}

const PoliceContainer = connect(mapStateToProps)(Police)

export default PoliceContainer
