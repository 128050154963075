import React from 'react';
import moment from 'moment';

const DejaClient = (props) => {
    const {task: {date, type, paniere}} = props

    return (
        <div className={'container'}>
            <div className={'row'}>
                <div className={'col text-center'}>
                    <h2 className={'title-primary text-uppercase'}>Tâche {paniere}</h2>
                    <p className={'subtitle'}>Tâche du {moment(date).format('L')}. Recu par {type}</p>
                </div>
            </div>
            <div className={'header-row row'}>
                <div className={'col-12'}>
                    <div className={'form-content text-center'}>
                        <p>Mettre à jour le tableau des pièces attendues en reprenant les pièces reçues pour le
                            précédent contrat.</p>
                        <p className={'font-weight-bold'}>Attention à vérifier le véhicule précédent + antécédents pour
                            les groupes 17 et 18 et le mode de fractionnement de l'ancien et du nouveau contrat
                            (demander RIB et Mandat si besoin)</p>
                        <p>Passer le contrat en cours si toutes les pièces bloquantes sont présentes au dossier</p>
                        <p>Révoquer l'ancien mandat sur la nouvelle souscription si c'est le même n°IBAN et remettre le nouveau mandat sur l'ancien contrat</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DejaClient;
