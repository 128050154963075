import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setIsLogin, setLoader} from '../../../actions/app/ui/ui.actions'
import {FAQ_CAT, GET_FAQ_CAT, setFaqCat} from "../../../actions/app/faq_categories/faq_categories.actions";

export const faqCategoriesMiddleware = () => next => action => {
    next(action)
    switch (action.type) {
        case GET_FAQ_CAT:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: FAQ_CAT}),
                setLoader({state: true, entity: FAQ_CAT})
            ])
            break

        case `${FAQ_CAT} ${API_SUCCESS}`:
            next([
                setIsLogin({state: true, entity: FAQ_CAT}),
                setFaqCat({faq_categories: action.payload.data}),
                setError({state: false, entity: FAQ_CAT}),
                setLoader({state: false, entity: FAQ_CAT}),
            ])
            break

        case `${FAQ_CAT} ${API_ERROR}`:
            next([
                setIsLogin({state: false, entity: FAQ_CAT}),
                setError({state: true, entity: FAQ_CAT}),
                setLoader({state: false, entity: FAQ_CAT})
            ])
            break

        default:
            break
    }
    return null
}
