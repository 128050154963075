import React, {Component, Fragment} from 'react'
import {Link, NavLink} from 'react-router-dom'
import Logo from '../../../assets/img/logos/logo_light.png'
import {Disconnect, Support, Taches, Loupe, Virements, Agenda, Dashboard} from "../../Commun/Icons/Icons";
import {withRouter} from "react-router";
import _ from 'lodash'
import SearchFieldContainer from "../Page/Search/SearchFieldContainer";
import {nouvelOnglet, redirectInNewWindow} from "../../../utils/function";
import {tokenUser} from "../../../redux/selectors/localStorage/localStorage";
import {HELP_URL} from "../../../constants/constants";

class Nav extends Component {
    constructor(props) {
        super(props)
        this.state = {
            search: false,
        }
        this.toggleSearch = this.toggleSearch.bind(this)
    }

    toggleSearch() {
        this.setState({search: !this.state.search})
    }

    render() {
        const {roles, permissions, nameConseiller, showSearch = this.state.search} = this.props

        return (
            <Fragment>
                <div className={'d-flex main-container'}>
                    <div className={'logo'}>
                        {_.includes(roles, 'gestionnaire televente') ?
                            <Link to={'/'}>
                                <img src={Logo} alt={'APRIL Moto'} className={'float-left'}/>
                            </Link>
                            : <Link to={'/mes-taches'}>
                                <img src={Logo} alt={'APRIL Moto'} className={'float-left'}/>
                            </Link>
                        }
                    </div>
                    <div className={'d-flex justify-content-center align-items-center flex-wrap'}>
                        {!nouvelOnglet() &&
                            <>
                                {_.includes(permissions, 'read request') &&
                                    <NavLink to={'/requetes'} className={'item-nav'}>
                                        <Dashboard/> Requêtes
                                    </NavLink>
                                }
                                {_.includes(permissions, 'read manager dashboard televente') &&
                                    <NavLink to={'/dashboard-tlv'} className={'item-nav'}>
                                        <Dashboard/> Dashboard
                                    </NavLink>
                                }
                                {_.includes(roles, 'gestionnaire televente') &&
                                    <NavLink to={'/mon-tableau-bord'} className={'item-nav'}>
                                        <Dashboard/> Mon tableau de bord
                                    </NavLink>
                                }
                                {_.includes(permissions, 'read bank transfer') &&
                                    <NavLink to={'/virements'} className={'item-nav'}>
                                        <Virements width={30}/> Virements
                                    </NavLink>
                                }
                                {!_.includes(roles, 'gestionnaire televente') &&
                                    <NavLink to={'/mon-agenda'} className={'item-nav'}>
                                        <Agenda/> Mon agenda
                                    </NavLink>
                                }
                                {_.includes(permissions, 'read quotes') &&
                                    <NavLink className={'item-nav'}
                                             onClick={(e) => redirectInNewWindow({
                                                 e,
                                                 url: `/mes-devis`,
                                                 name: 'Mes devis'
                                             })}
                                             to={''}
                                    >
                                        <Taches/> Mes devis
                                    </NavLink>
                                }
                                {(_.includes(permissions, 'create quote') && (_.includes(roles, 'gestionnaire televente') || _.includes(roles, 'manager televente'))) &&
                                    <NavLink to={'/nouveau-devis'} className={'item-nav'}>
                                        <Taches/> Nouveau devis
                                    </NavLink>
                                }
                                {(_.includes(permissions, 'create quote') && _.includes(roles, 'gestionnaire commercial')) &&
                                    <NavLink to={'/nouveau-devis-courtier'} className={'item-nav'}>
                                        <Taches/> Nouveau devis
                                    </NavLink>
                                }
                                {!_.includes(roles, 'gestionnaire televente') &&
                                    <NavLink to={'/mes-taches'} className={'item-nav'}>
                                        <Taches/> Mes tâches
                                    </NavLink>}
                                <span
                                    className={'item-nav pointer'}
                                    onClick={this.toggleSearch}
                                >
                                    <Loupe/> Recherche
                                </span>
                                <a href={`${HELP_URL}/conseiller-redirection?access_token=${tokenUser.trim()}`} target={'_blank'} className={'item-nav'} rel="noreferrer">
                                    <Support/> HELP
                                </a>
                            </>
                        }
                    </div>

                    <div className={'user-connect'}>
                        <p>{nameConseiller}</p>
                        <NavLink to={'/deconnexion'} className={'underline'}>
                            <Disconnect/> Se déconnecter
                        </NavLink>
                    </div>
                </div>
                {showSearch &&
                    <Fragment>
                        <span className={'close-search'} onClick={this.toggleSearch}>x</span>
                        <SearchFieldContainer toggleSearch={this.toggleSearch}/>
                    </Fragment>
                }
            </Fragment>
        )
    }
}

export default withRouter(Nav)
