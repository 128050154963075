export const THIRD_PARTY_PAYER = '[Tiers Payeur]';

export const GET_THIRD_PARTY_PAYER = `${THIRD_PARTY_PAYER} Get`
export const SET_THIRD_PARTY_PAYER = `${THIRD_PARTY_PAYER} Set`
export const POST_CREATE_THIRD_PARTY_PAYER = `${THIRD_PARTY_PAYER} Create`
export const POST_UPDATE_THIRD_PARTY_PAYER = `${THIRD_PARTY_PAYER} Update`
export const POST_DELETE_THIRD_PARTY_PAYER = `${THIRD_PARTY_PAYER} Delete`

export const getTiersPayeur = ({query}) => ({
    type: GET_THIRD_PARTY_PAYER,
    payload: {
        data: query
    }
})

export const setTiersPayeur = ({tiers_payeur}) => ({
    type: SET_THIRD_PARTY_PAYER,
    payload: {
        data: tiers_payeur
    }
})

export const postCreateTiersPayeur = ({query, form, otherData}) => ({
    type: POST_CREATE_THIRD_PARTY_PAYER,
    payload: {
        data: query,
        body: form,
        otherData
    }
})

export const postUpdateTiersPayeur = ({query, form}) => ({
    type: POST_UPDATE_THIRD_PARTY_PAYER,
    payload: {
        data: query,
        body: form
    }
})

export const postDeleteTiersPayeur = ({query}) => ({
    type: POST_DELETE_THIRD_PARTY_PAYER,
    payload: {
        data: query
    }
})