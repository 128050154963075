import React from 'react'

const CardCirconstances = ({children}) => {
    return (
        <div className={`card-circ`}>
            <div className={'card-header'}>
                {children}
            </div>
        </div>
    )
}

export default CardCirconstances
