import React from 'react'
import moment from "moment";
import _ from "lodash";

const Resume = ({accident, contract}) => {
    const dg = accident.piece_docs && _.find(accident.piece_docs, ['type_doc', 'DG']);
    let dp = accident.piece_docs && _.find(accident.piece_docs, ['type_doc', 'DP_SIGN']);

    if(!dp && accident.piece_docs){
        dp = _.find(accident.piece_docs, ['type_doc', 'DP']);
    }

    return (
        <div className={'bloc-info bloc-decla'}>
            <h2>Déclaration</h2>
            <div className={'content row'}>

                <div className={'col-5 left-content'}>
                    <p>Véhicule concerné :</p>
                </div>
                <div className={'col-7 right-content'}>
                    <p>{accident.marqueVehicule} {accident.modeleVehicule}</p>
                </div>

                <div className={'col-5 left-content'}>
                    <p>Immatriculation :</p>
                </div>
                <div className={'col-7 right-content'}>
                    <p>{accident.immatVehicule}</p>
                </div>

                <div className={'col-5 left-content'}>
                    <p>Date mise en circulation :</p>
                </div>
                <div className={'col-7 right-content'}>
                    <p>{moment(accident.dateMECVehicule).format('L')}</p>
                </div>

                <div className={'col-5 left-content'}>
                    <p>Date du sinistre :</p>
                </div>
                <div className={'col-7 right-content'}>
                    <p>{moment(accident.dateSurv).format('L')}</p>
                </div>

                <div className={'col-5 left-content'}>
                    <p>Sinistre ouvert le :</p>
                </div>
                <div className={'col-7 right-content'}>
                    <p>{moment(accident.dateOuverture).format('L')}</p>
                </div>

                <div className={'col-5 left-content'}>
                    <p>Lieu du sinistre :</p>
                </div>
                <div className={'col-7 right-content'}>
                    <p>{accident.codePostalAccident !== '99000' && accident.codePostalAccident} {accident.villeAccident}</p>
                </div>

                <div className={'col-5 left-content'}>
                    <p>Code produit :</p>
                </div>
                <div className={'col-7 right-content'}>
                    <p>{accident.codeProduit}</p>
                </div>

                {
                    accident.codeProduit === "7311_MOT1" &&
                    <>
                        <div className={'col-5 left-content'}>
                            <p>Crédit franchise fidélité :</p>
                        </div>
                        <div className={'col-7 right-content'}>
                            <p>{accident.credit_franchise}</p>
                        </div>
                    </>
                }

                {
                    dp && <>
                        <div className={'col-5 left-content'}>
                            <p>Dispositions Particulières :</p>
                        </div>
                        <div className={'col-7 right-content'}>
                            <p><a href={dp.url} target={'_blank'}>Télécharger</a></p>
                        </div>
                    </>
                }

                {
                    dg && <>
                        <div className={'col-5 left-content'}>
                            <p>Dispositions Générales :</p>
                        </div>
                        <div className={'col-7 right-content'}>
                            <p><a href={dg.url} target={'_blank'}>Télécharger</a></p>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default Resume
