import {createSelector} from 'reselect'
import _ from "lodash";

const accidentSelector = state => state.accident.data

export const getAccidentData = createSelector(
    accidentSelector,
    data => data
)

export const getAccidentCreditFranchise = createSelector(
    getAccidentData,
    data => data.credit_franchise
)

/* Garanties */
export const getAccidentGaranties = createSelector(
    getAccidentData,
    data => data.garanties
)

export const getAccidentGarantiesACCEQ = createSelector(
    getAccidentGaranties,
    data => _.filter(data, ['code', 'ACCEQ'])
)

export const getAccidentGarantiesEQ = createSelector(
    getAccidentGaranties,
    data => _.filter(data, ['code', 'EQ'])
)

export const getAccidentGarantiesRFVI = createSelector(
    getAccidentGaranties,
    data => _.filter(data, ['code', 'RFVI'])
)

export const getAccidentGarantiesACC20 = createSelector(
    getAccidentGaranties,
    data => _.filter(data, ['code', '22'])
)

export const getAccidentGarantiesDTASinistree = createSelector(
    getAccidentGaranties,
    data => _.find(data, (garantie) => {
        return garantie.sinistree === 'OUI' && (garantie.code === 'DTA' || garantie.code === 'DA' || garantie.code === 'SCDA' || garantie.code === 'PVDA')
    })
)

export const getAccidentGarantiesValMaj = createSelector(
    getAccidentGaranties,
    data => _.filter(data, ['code', 'VALMAJ'])
)

export const getAccidentFranchiseReglee = createSelector(
    getAccidentData,
    data => data.franchiseReglee
)

export const getAccidentDocuments = createSelector(
    getAccidentData,
    data => data.documents
)

export const getAccidentDocumentAntivolRecuTraite = createSelector(
    getAccidentDocuments,
    data => _.filter(data, {'code':'10', 'recu': true, 'traite' : true})
)

export const getAccidentDocumentAntivol = createSelector(
    getAccidentDocuments,
    data => _.filter(data, {'code':'10'})
)

export const getAccidentDocumentFacture = createSelector(
    getAccidentDocuments,
    data => _.filter(data, {'code':'5', 'recu': true})
)

export const getAccidentReglements = createSelector(
    getAccidentData,
    data => data.reglements
)

export const getAccidentCodeProduit = createSelector(
    getAccidentData,
    data => data.codeProduit
)

export const getAccidentReglementsEnBanque = createSelector(
    getAccidentReglements,
    reglements => _.filter(reglements, ['type_code', 'J'])
)

export const getAccidentIDA = createSelector(
    getAccidentData,
    data => data.ida
)

export const getAccidentTauxResp = createSelector(
    getAccidentData,
    data => data.tauxResp
)

export const getAccidentOppositions = createSelector(
    getAccidentData,
    data => data.oppositions
)

export const getAccidentPenalites = createSelector(
    getAccidentData,
    data => data.penalties
)

export const getAccidentHivernage = createSelector(
    getAccidentData,
    data => data.hivernage
)

export const getAccidentcategorieCode = createSelector(
    getAccidentData,
    data => data.categorieCode
)
