import React from 'react'
import _ from 'lodash'
import Collapse from "../../../../../Commun/Collapse/Collapse";
import APILink from "../../../../../Commun/APILink/APILink";

const Commissions = (props) => {
    const {commissions, dads} = props
    let properties = Object.keys(commissions).reverse()

    return (
        <div className={'bloc-info bloc-pieces'}>
            <h2>Historique des commissions versées</h2>
            <div className={'content row'}>
                <div className={'col-9'}>
                    {
                        _.map(properties, p => (

                            <Collapse key={p} title={`Commissions versées en ${p}`} id={`commissions-${p}`}>
                                {
                                    _.map(commissions[p], (c, index) => (
                                        <div key={index}
                                             className={`p-3 border-${index > 0 ? 'top' : '0'} border-dark hover-light-background d-flex justify-content-between`}>

                                            <p className={'m-0'}>Commissions de <b>{c.libelle}</b></p>
                                            <div>
                                                <APILink href={c.link_xls} target={'_blank'} rel="noopener noreferrer"
                                                   className={'mr-3 btn btn-primary btn-small'}>Télécharger le .xls</APILink>
                                                <APILink href={c.link_pdf} target={'_blank'}
                                                   className={'btn btn-primary btn-small'} rel="noopener noreferrer">Télécharger
                                                    le .pdf</APILink>
                                            </div>
                                        </div>
                                    ))
                                }
                            </Collapse>
                        ))
                    }
                </div>
                <div className={'col-3'}>
                    {
                        dads.map(d => (
                            <div key={d.year}>
                                <APILink href={d.link} download className={'btn btn-ternary text-center my-3 max-rounded'}>
                                    <i className="far fa-file-pdf"/> Déclaration fiscale {d.year}
                                </APILink>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Commissions
