import React from 'react'
import moment from "moment";

const QuittanceDetail = (props) => {
    const {details, tableau} = props

    return (
        <div className={'container'}>
            <div className={'row'}>
                <div className={'col'}>
                    <h2 className={'text-center'}>Detail de la quittance</h2>
                    {details.length > 0 &&
                        <div className={'margin-top'}>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Débit</th>
                                    <th scope="col">Crédit</th>
                                    <th scope="col">Libellé</th>
                                    <th scope="col">Info complémentaire</th>
                                    <th>Utilisateur</th>
                                </tr>
                                </thead>
                                <tbody>
                                {details.map((detail, index) =>
                                    <tr key={index}>
                                        <td>{moment(detail.date).format('L')}</td>
                                        <td>{detail.debit} €</td>
                                        <td>{detail.credit} €</td>
                                        <td>{detail.infoComplementaire}</td>
                                        <td>{detail.libelle}</td>
                                        <td>{detail.utilisateur}</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    }

                    <h3 className={'text-center margin-top'}>Tableau de prélèvement</h3>
                    {tableau.length > 0 ?
                        <div className={'margin-top'}>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Montant à prélever</th>
                                    <th scope="col">Effectué le</th>
                                    <th scope="col">Motif du rejet</th>
                                    <th scope="col">Date du rejet</th>
                                </tr>
                                </thead>
                                <tbody>
                                {tableau.map((t, index) =>
                                    <tr key={index}>
                                        <td>{moment(t.date).format('L')}</td>
                                        <td>{t.montant} €</td>
                                        <td>{t.datePrelevement && moment(t.datePrelevement).format('L')}</td>
                                        <td>{t.motifRejet}</td>
                                        <td>{t.dateRejet && moment(t.dateRejet).format('L')}</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                        : <p className={'text-center'}>Il n'y a pas de tableau de prélèvement sur cette quittance</p>
                    }
                </div>
            </div>
        </div>
    )
}

export default QuittanceDetail
