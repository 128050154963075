import React from 'react';
import Badge from "../../../../Commun/Badge/Badge";
import {Link} from "react-router-dom";
import FormUpdateAccidentContainer from "./FormUpdateAccident/FormUpdateAccidentContainer";


const UpdateAccident = (props) => {
    const {history, contract, accident, idContract, idAccident} = props

    return (
        <div className={'contract-container container'}>
            <div className={'row return-content'}>
                <div className={'col'}>
                    <button onClick={() => history.go(-1)} className={'btn btn-return'}>Retour</button>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-12'}>
                    <Link to={`/tiers/${contract.souscripteur.id}`} className={'link-title-primary'}>
                        <h1 className={'title-primary'}>
                            {contract.souscripteur.titre} {contract.souscripteur.nom} {contract.souscripteur.id} -
                            Sinistre n°{accident.numCbt}
                            <span className={'link-icon-primary'}/>
                        </h1>
                    </Link>
                    <Badge status={accident.situation}/>
                </div>
                <div className={'col-12'}>
                    <span
                        className={'bold subtitle'}>N° Allianz&nbsp;:&nbsp;{accident.numCie} - {accident.marqueVehicule} {accident.modeleVehicule}</span>
                </div>
            </div>
            <div className="row header-row">
                <div className={'col-12'}>
                    <FormUpdateAccidentContainer idContract={idContract} idAccident={idAccident}/>
                </div>
            </div>
        </div>
    );
};

export default UpdateAccident;
