import React from 'react';
import PropTypes from 'prop-types'

const TraitementCheckdoc = (props) => {
    const { settingRes, submitUpdateSettings } = props;

    return (
        <div className={'col-4'} style={{ whiteSpace: 'nowrap', padding:0}}>
            <span className={'btn btn-primary btn-small'} onClick={submitUpdateSettings}>{settingRes.toString() === '1' ? 'Désactiver' : 'Activer'} les RES sur le checkdoc</span>
        </div>
    );
};

export default TraitementCheckdoc;
