import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import validate from './validate'
import ValidateRib from "./ValidateRib";
import {change, formValueSelector, reduxForm} from "redux-form";
import {
    amendmentChangeIbanGetLink,
    contractDefaultGetLink
} from "../../../../../../redux/selectors/links/links.selectors";
import {CONTRACT, getContract} from "../../../../../../redux/actions/app/contracts/contracts.actions";
import {getErrorEntity, getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import ErrorHttpComponent from "../../../../../Commun/Error/ErrorHttpComponent";
import _ from "lodash";
import {getIdContractInTask} from "../../../../../../redux/selectors/tasks/task.selectors";
import {getContractData} from "../../../../../../redux/selectors/contract/contract.selectors";
import {postValidateRib, VALIDATE_RIB} from "../../../../../../redux/actions/app/validate_rib/validate_rib.actions";
import {CLOSE_TASK} from "../../../../../../redux/actions/app/tasks/tasks.actions";
import loader from "../../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector('validate_rib')

    return {
        contract: getContractData(state),
        uri: contractDefaultGetLink(state),
        loaded: getLoadingEntity(state, CONTRACT) === false,
        error: getErrorEntity(state, CONTRACT),
        idContract: ownProps.task.contract ? getIdContractInTask(state) : null,
        uriPostRib: amendmentChangeIbanGetLink(state),
        terminateQuery: ownProps.terminateTask,
        loadingTerminate: getLoadingEntity(state, CLOSE_TASK),
        loadingValidateRib: getLoadingEntity(state, VALIDATE_RIB),
        errorTerminate: getErrorEntity(state, CLOSE_TASK),
        errorRib: getErrorEntity(state, VALIDATE_RIB),
        task: ownProps.task,
        ibanIsCheck: Boolean(selector(state, 'domiciliation') && selector(state, 'bic')),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getContract: (query) => getContract({query}),
    postValidateRib: ({query, form, tiers, terminateQuery}) => postValidateRib({query, form, tiers, terminateQuery}),
    changeValue: (field, value) => change('validate_rib', field, value)
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getContract, postValidateRib} = dispatchProps
    const {uri, idContract, uriPostRib, terminateQuery, task} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getContract(_.replace(uri, '{id}', idContract)),
        onSubmit: (form) => postValidateRib({query: uriPostRib, form, tiers: task.tiers_id, terminateQuery})
    }
}

const ValidateRibContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'validate_rib',
        validate
    }),
    loader({ErrorIndicator: ErrorHttpComponent})
)(ValidateRib)

export default ValidateRibContainer
