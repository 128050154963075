import React from 'react'
import moment from "moment";

const CtrlNCall = (props) => {
    const {task: {date, type, paniere, tiers_id}} = props

    return (
        <div className={'container'}>
            <div className={'row'}>
                <div className={'col text-center'}>
                    <h2 className={'title-primary text-uppercase'}>{paniere} - Tiers n°{tiers_id}</h2>
                    <p className={'subtitle'}>Tâche du {moment(date).format('L')}. Recu par {type}</p>
                </div>
            </div>
            <div className={'header-row row'}>
                <div className={'col-12'}>
                    <p className={'form-content text-center'}>
                        Type&nbsp;:&nbsp;
                        { paniere === 'CTRL_N_CALL_1' && <>Rappel suite à l’envoi de toutes les pièces</> }
                        { paniere === 'CTRL_N_CALL_2' && <>Rappel suite au manque d’une seule pièce bloquante</> }
                        { paniere === 'CTRL_N_CALL_3' && <>Rappel suite à un dossier incomplet</> }
                        { paniere === 'CTRL_N_CALL_4' && <>Rappel suite à un dossier vide</> }
                        { paniere === 'CTRL_N_CALL_5' && <>Rappel client car manque de pièces</> }
                        <br/>

                        Description&nbsp;:&nbsp;
                        { paniere === 'CTRL_N_CALL_1' &&
                            <>Le client a envoyé toutes ses pièces, mais certaines sont erronées ou incomplètes, merci de prendre contact avec le client /courtier</>
                        }
                        { paniere === 'CTRL_N_CALL_2' &&
                            <>Il nous manque 1 seule pièce bloquante pour le passage en cours du dossier, merci de prendre contact avec le client /courtier</>
                        }
                        { paniere === 'CTRL_N_CALL_3' &&
                            <>Le client a envoyé certaines de ses pièces justificatives, il attend peut-être les documents définitifs, prendre contact avec le client/courtier pour lui expliquer les alternatives possibles</>
                        }
                        { paniere === 'CTRL_N_CALL_4' &&
                            <>Le client ne nous a rien envoyé, merci de le contacter afin d'en connaitre les raisons et de l'inciter à tout nous envoyer rapidement</>
                        }
                        { paniere === 'CTRL_N_CALL_5' &&
                            <>
                                Le contrat n'est pas passé dans le CTRL_N_CALL, CTRL_N_CALL_1, CTRL_N_CALL_2, CTRL_N_CALL_3 ou CTRL_N_CALL_4.<br/>
                                Merci de repasser sur le dossier et rappeler le client si nécessaire.
                            </>
                        }
                    </p>
                </div>
            </div>
        </div>
    )
}

export default CtrlNCall
