import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import {TASKS, getTasks} from '../../../../../../redux/actions/app/tasks/tasks.actions'
import {getErrorEntity, getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {tasksLink} from '../../../../../../redux/selectors/links/links.selectors'
import ErrorHttpComponent from '../../../../../Commun/Error/ErrorHttpComponent'
import {getTasksData} from "../../../../../../redux/selectors/tasks/tasks.selectors";
import Tasks from "./Tasks";
import loader from "../../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    return {
        tasks: getTasksData(state),
        uri: tasksLink(state),
        loaded: getLoadingEntity(state, TASKS) === false,
        error: getErrorEntity(state, TASKS)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getTasks: ({query, tiers}) => getTasks({query, tiers}),
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getTasks} = dispatchProps
    const {uri} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getTasks({query: uri, tiers: ownProps.tiers}),
    }
}

const TasksContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(Tasks)

export default TasksContainer
