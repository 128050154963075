const validate = values => {
    const errors = {}

    const requiredFields = [
        'type',
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Ce champ est obligatoire'
        }
        if (values.type === 'paniere' && !values.paniere) {
            errors.paniere = 'Ce champ est obligatoire'
        }
        if (values.categorie === 'contrat' && !values.contrat) {
            errors.contrat = 'Ce champ est obligatoire'
        }
        if (values.categorie === 'sinistre' && !values.sinistre) {
            errors.contrat = 'Ce champ est obligatoire'
        }
    })

    return errors
}

export default validate
