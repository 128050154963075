import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {getErrorEntity, getLoadingEntity} from '../../../../../redux/selectors/ui/ui.selectors'
import Notes from './Notes'
import {NOTES, getNotes} from '../../../../../redux/actions/app/notes/notes.actions'
import {getNotesData} from '../../../../../redux/selectors/notes/notes.selector'
import ErrorHttpComponent from '../../../../Commun/Error/ErrorHttpComponent'
import {notesTiersGetLink} from "../../../../../redux/selectors/links/links.selectors";
import loader from "../../../../Commun/Spinner/Loader/Loader";
import _ from "lodash";

const mapStateToProps = (state, ownProps) => {
    return {
        notes: getNotesData(state),
        uri: notesTiersGetLink(state),
        loaded: getLoadingEntity(state, NOTES) === false,
        error: getErrorEntity(state, NOTES),
        tiers: ownProps.tiers,
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getNotes: (query) => getNotes({query}),
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getNotes} = dispatchProps
    const {uri, tiers} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getNotes(_.replace(uri, '{id}', tiers)),
    }
}

const NotesContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(Notes)

export default NotesContainer
