import React from 'react'
import moment from "moment";

const TelConstat = (props) => {
    const {task: {date, type, paniere, tiers_id}} = props

    return (
        <div className={'container'}>
            <div className={'row'}>
                <div className={'col text-center'}>
                    <h2 className={'title-primary text-uppercase'}>{paniere} - Tiers n°{tiers_id}</h2>
                    <p className={'subtitle'}>Tâche du {moment(date).format('L')}. Recu par {type}</p>
                </div>
            </div>

            <div className={'header-row row'}>
                <div className={'col-12'}>
                    <p className={'form-content text-center'}>
                        Appeler le client afin de lui demander son constat ou une déclaration circonstanciée afin
                        d'ouvrir son sinistre.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default TelConstat
