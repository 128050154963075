import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {reduxForm} from "redux-form";
import validate from "./validate";
import AddCorrespondant from "./AddCorrespondant";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {createCorrespondantsGetLink} from "../../../../../redux/selectors/links/links.selectors";
import _ from "lodash";
import {
    POST_CORRESPONDANT,
    postCorrespondant
} from "../../../../../redux/actions/app/correspondants/correspondants.actions";

const mapStateToProps = (state, ownProps) => {

    return {
        uri: createCorrespondantsGetLink(state),
        loading: true && getLoadingEntity(state, POST_CORRESPONDANT),
        idTiers: ownProps.match.params.id,
        initialValues: {
            'principal': false,
        }
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    postCorrespondant,
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uri, idTiers} = stateProps
    const {postCorrespondant} = dispatchProps


    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: form => postCorrespondant({query: _.replace(uri, '{id}', idTiers), form, otherData: {idTiers}})
    }
}


const AddCorrespondantContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'add_correspondant',
        validate
    }),
)(AddCorrespondant)


export default AddCorrespondantContainer
