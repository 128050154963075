import React from 'react'
import moment from "moment";
import {Link} from "react-router-dom"
import Badge from "../../../../../Commun/Badge/Badge";

const Contracts = (props) => {
    const {contracts} = props

    return (
        <div className={'bloc-info bloc-contracts'}>
            <h2>Contrats</h2>
            <div className={'content row'}>
                {contracts.total >= 1 ?
                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">Date d'effet</th>
                            <th scope="col">Numéro de contrat</th>
                            <th scope="col">Véhicule</th>
                            <th scope="col">Immatriculation</th>
                            <th scope="col">Situation</th>
                            <th scope="col">Détails</th>
                        </tr>
                        </thead>
                        <tbody>
                        {contracts.items.map((contract) =>
                            <tr key={contract.id}>
                                <td>{moment(contract.situation.dateAffaireNouvelle).format('L')}</td>
                                <td>{contract.numeroPolice}</td>
                                <td>{contract.vehicule.marque}</td>
                                <td>{contract.vehicule.immatriculation}</td>
                                <td><Badge status={contract.situation.statut}/></td>
                                <td><Link to={{pathname: `/contrat/${contract.id}`}} className={'btn btn-more'}/></td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                    :
                    <p className={'no-result'}>Le tiers n'a pas de contrat</p>
                }
            </div>
        </div>
    )
}

export default Contracts
