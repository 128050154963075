import React from 'react';
import PropTypes from 'prop-types'
import {Field} from "redux-form";
import _ from "lodash";

const Commentaires = (props) => {
    const {handleSubmit, onSubmit, permissions, accident} = props

    return (
        <div>
            {_.includes(permissions, 'update accident informations') ?
                <div className={'accident-commentaire'}>
                    <Field
                        name={"commentaire"}
                        component={"textarea"}
                        onBlur={handleSubmit(values => onSubmit(values))}
                        rows={8}
                    />
                    <span className={'tooltips'}>Editer le commentaire</span>
                </div>
                : accident.commentaire ?
                    <p dangerouslySetInnerHTML={{__html: accident.commentaire.replace(/\n/ig, '<br>')}}/> : ''
            }
        </div>
    );
};


Commentaires.propTypes = {
    accident: PropTypes.object.isRequired
};


export default Commentaires;
