import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {
    setAddExpertReport,
    setError,
    setLoader
} from '../../../actions/app/ui/ui.actions'
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {POST_ADD_EXPERT_REPORT} from "../../../actions/app/expert_report/expert_report.actions";

import _ from "lodash";


export const expertReportMiddleware = () => next => action => {
    next(action)

    const {payload} = action

    switch (action.type) {

        case POST_ADD_EXPERT_REPORT:
            let clone = _.cloneDeep(payload.body)

            if (clone.montant_expertise) {
                clone.montant_expertise = clone.montant_expertise.replaceAll(',', '.')
            }

            next([
                apiRequest({
                    body: (clone),
                    method: 'POST',
                    url: payload.data,
                    entity: POST_ADD_EXPERT_REPORT
                }),
                setLoader({state: true, entity: POST_ADD_EXPERT_REPORT})
            ])
            break;

        case `${POST_ADD_EXPERT_REPORT} ${API_SUCCESS}`:
            next([
                setAddExpertReport({state: true}),
                setNotification({
                    entity: POST_ADD_EXPERT_REPORT,
                    body: `<p>Le rapport d'expert a été enregistré.</p>`,
                    type: 'success',
                    title: "Rapport d'expertise"
                }),
                setLoader({state: false, entity: POST_ADD_EXPERT_REPORT}),
                setError({state: false, entity: POST_ADD_EXPERT_REPORT})
            ])

            break;

        case `${POST_ADD_EXPERT_REPORT} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_ADD_EXPERT_REPORT,
                    body: `<p>${payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Une erreur est survenue !',
                    timeout: '0'
                }),
                setLoader({state: false, entity: POST_ADD_EXPERT_REPORT}),
                setError({state: true, entity: POST_ADD_EXPERT_REPORT})
            ])
            break;

        default:
            break
    }
    return null
}
