import {createSelector} from 'reselect'
import _ from "lodash";

const commissionsSelector = state => state.commissions

export const getDataCommissions = createSelector(
    commissionsSelector,
    commissions => _.groupBy(commissions.data, 'year')
)

export const getDadsCommissions = createSelector(
    commissionsSelector,
    commissions => commissions.dads
)
