import React, {Fragment} from 'react'
import moment from "moment";
import ValidateDocumentsContainer from "./ValidateDocuments/ValidateDocumentsContainer";
import ValidateRibContainer from "./ValidateRib/ValidateRibContainer";
import {Link} from "react-router-dom";
import RelanceTel from "./RelanceTel/RelanceTel";
import _ from 'lodash'
import RappelInfo from "./RappelInfo/RappelInfo";
import ResiliationContainer from "./Resiliation/ResiliationContainer";
import TacheAncb from "./TacheAncb/TacheAncb";
import RappelLRNPP from "./RappelLRNPP/RappelLRNPP";
import LoiHamonLR from "./LoiHamonLR/LoiHamonLR";
import RappelNPAI from "./RappelNPAI/RappelNPAI";
import Doublon from "./Doublon/Doublon";
import Agenda from "./Agenda/Agenda";
import DdeMail from "./DdeMail/DdeMail";
import Edi from "./Edi/Edi";
import ReglementKO from "./ReglementKO/ReglementKO";
import Spinner from "../../../../Commun/Spinner/Spinner";
import ResB from "./ResB/ResB";
import TacheAvtAdresse from "./TacheAvtAdresse/TacheAvtAdresse";
import CallBye from "./CallBye/CallBye";
import CttOk from "./CttOk/CttOk";
import DejaClient from "./DejaClient/DejaClient";
import ReclaNiv2 from "./Recla_niv2/Recla_niv2";
import AgRecla from "./AgRecla/AgRecla";
import ImmatDoublon from "./ImmatDoublon/ImmatDoublon";
import ModifDateEffet from "./ModifDateEffet/ModifDateEffet";
import ReponseDossier from "./ReponseDossier/ReponseDossier";
import ValidateDocumentAlcoolemieContainer from "./ValidateDocumentAlcoolemie/ValidateDocumentAlcoolemieContainer";
import TacheSimpleFile from "./TacheSimpleFile/TacheSimpleFile";
import ReclaSin from "./ReclaSin/ReclaSin";
import AcpteCb from "./AcpteCb/AcpteCb";
import TelConstat from "./TelConstat/TelConstat";
import FeedbackContainer from "./Feedback/FeedbackContainer";
import FactureContainer from "./Facture/FactureContainer";
import TacheSimple from "./TacheSimple/TacheSimple";
import ReglementCheque from "./ReglementCheque/ReglementCheque";
import RgtContainer from "./Rgt/RgtContainer";
import DecContrl from "./DecContrl/DecContrl";
import NPAI from "./NPAI/RappelNPAI";
import TacheReassign from "./TacheReassign/TacheReassign";
import AcpteFrac from "./AcpteFrac/AcpteFrac";
import Payeur from "./Payeur/Payeur";
import InfoCoti from "./InfoCoti/InfoCoti";
import TachePvContainer from "./TachePV/TachePvContainer";
import AVKO from "./AVKO/AVKO";
import TacheCheckdoc from "./TacheCheckdoc/TacheCheckdoc";
import CtrlNCall from "./CtrlNCall/CtrlNCall";
import TracfinContainer from "./Tracfin/TracfinContainer";
import AnaFraudeContainer from "./AnaFraude/AnaFraudeContainer";
import FluxMailContainer from "./FluxMail/FluxMailContainer";
import BcaErrContainer from "./BcaErr/BcaErrContainer";
import InfoPmq from "./InfoPmq/InfoPmq";

const RedirectToTaskForm = (props) => {
    const {task, postValid, postCloseTask, loadingTerminate, loading, belair_id} = props

    let arrMultipleFile = ['AN', 'AN_REV', 'ANUPLOAD', 'AN_HAM', 'AV', 'AN_SIGN_HAM', 'DEC', 'ANCHQKO', 'ANCHQ', 'REP_AN', 'AN_VELO', 'AN_NVEI']
    let dontValidateFiles = ['RES', 'RES_AN', 'RES_P', 'RES_SUSP_VOL', 'SUSP_VOL', 'SURV', 'DDE_MAIL', 'DDE_MAIL_AV', 'DDE_MAIL_NIV2', 'RES_B', 'FACTURE', 'NPAI_PROD', 'RES_VENTE']
    let paniereCheckdoc = ['AN', 'ANUPLOAD', 'AV', 'REP_AN'];

    const redirectCheckdoc = _.includes(paniereCheckdoc, task.paniere);
    if(redirectCheckdoc){
        arrMultipleFile = _.filter(arrMultipleFile, (p) => {return !_.includes(paniereCheckdoc, p)})
    }

    const renderFormulaire = (file, index) => {
        if (_.startsWith(task.paniere, 'AG_')) {
            return <Agenda task={task} postValid={postValid} postCloseTask={postCloseTask} files={task.files_url}/>
        }
        if(redirectCheckdoc){
            return <TacheCheckdoc task={task} terminateTask={task.terminate_url} files={task.files_url}/>
        }
        switch (task.paniere) {
            case 'FACTURE':
                return <FactureContainer task={task} terminateTask={task.terminate_url} files={task.files_url}/>
            case 'RIB':
                return <ValidateRibContainer task={task} terminateTask={task.terminate_url}/>
            case 'AN':
            case 'AN_REV':
            case 'ANUPLOAD':
            case 'ANCHQ':
            case 'ANCHQKO':
            case 'AN_HAM':
            case 'AV':
            case 'AN_SIGN_HAM':
            case 'S_AN':
            case 'AN_NVEI':
            case 'AN_VELO':
                return <ValidateDocumentsContainer task={task} terminateTask={task.terminate_url} file={file}
                                                   index={index} key={index}/>
            case 'DEC':
                return <ValidateDocumentsContainer task={task} terminateTask={task.terminate_url} file={file}
                                                   index={index} key={index} sinistre/>
            case 'ATT_ALC':
                return <ValidateDocumentAlcoolemieContainer task={task} terminateTask={task.terminate_url}
                                                            file={task.files_url[0]} key={index}/>
            case 'LR_50':
            case 'RTEL_1':
            case 'RTEL_2':
            case 'REJ':
            case 'REJ_2':
            case 'CTRL_N_CALL':
            case 'RTEL_CIE':
            case 'RTEL2_CIE':
            case 'RTEL3_CIE':
            case 'RTEL_CLI':
            case 'RTEL2_CLI':
            case 'RTEL3_CLI':
            case 'RTEL_PV':
            case 'RTEL2_PV':
            case 'RTEL3_PV':
            case 'RTEL_VOL':
            case 'VOL_J30':
                return <RelanceTel task={task} postValid={postValid} postCloseTask={postCloseTask}/>
            case 'CTRL_N_CALL_1':
            case 'CTRL_N_CALL_2':
            case 'CTRL_N_CALL_3':
            case 'CTRL_N_CALL_4':
            case 'CTRL_N_CALL_5':
                return <CtrlNCall task={task} postValid={postValid} postCloseTask={postCloseTask}/>
            case 'SEA_OK':
            case 'SEA_KO':
                return <RappelInfo task={task} postValid={postValid}/>
            case 'RES':
            case 'RES_AN':
            case 'RES_P':
            case 'RES_SUSP_VOL':
            case 'SUSP_VOL':
            case 'SURV':
            case 'SAUV_CLI':
                return <ResiliationContainer task={task} terminateTask={task.terminate_url} files={task.files_url}/>
            case 'ANCB':
            case 'ANCBKO':
                return <TacheAncb task={task} postCloseTask={postCloseTask}/>
            case 'LR_NPP':
                return <RappelLRNPP task={task} postValid={postValid} postCloseTask={postCloseTask}/>
            case 'LR_HAMON':
                return <LoiHamonLR task={task} postValid={postValid} postCloseTask={postCloseTask}/>
            case 'NPAI_MAIL':
                return <RappelNPAI task={task} postValid={postValid} postCloseTask={postCloseTask}/>
            case 'DOUBLON':
                return <Doublon task={task} postValid={postValid} postCloseTask={postCloseTask}/>
            case 'DDE_MAIL':
            case 'DDE_MAIL_AV':
            case 'DDE_MAIL_NIV2':
            case 'DDE_MAIL_INDEM':
            case 'MAIL_CIE':
            case 'INFO_BCA':
                return <DdeMail task={task} postCloseTask={postCloseTask} files={task.files_url}/>
            case 'FLUX_MAIL':
                return <FluxMailContainer task={task} postCloseTask={postCloseTask} files={task.files_url}/>
            case 'INFO_PMQ':
                return <InfoPmq task={task} files={task.files_url}/>
            case 'BCA_ERR':
                return <BcaErrContainer task={task} postCloseTask={postCloseTask} terminateTask={task.terminate_url}/>
            case 'EDI':
                return <Edi task={task} postValid={postValid} postCloseTask={postCloseTask}/>
            case 'REG_PROD_KO':
                return <ReglementKO task={task} postValid={postValid}/>
            case 'RES_B':
                return <ResB task={task} postCloseTask={postCloseTask} files={task.files_url}/>
            case 'TRACFIN':
                return <TracfinContainer task={task} postCloseTask={postCloseTask} terminateTask={task.terminate_url}/>
            case 'NPAI_PROD':
                return <TacheReassign task={task} postCloseTask={postCloseTask} files={task.files_url}/>
            case 'AV_ADR':
                return <TacheAvtAdresse task={task} postCloseTask={postCloseTask} files={task.files_url}/>
            case 'CALL_BYE':
                return <CallBye task={task}/>
            case 'CTT_OK':
                return <CttOk task={task}/>
            case 'DEJA_CLIENT':
                return <DejaClient task={task}/>
            case 'AG_RECLA':
                return <AgRecla task={task}/>
            case 'REP_AN':
                return <ValidateDocumentsContainer
                    message={'Si vous avez un RIB dans les pièces reçues, pensez à bien le modifier sur la fiche du contrat.'}
                    task={task} terminateTask={task.terminate_url} file={file} index={index} key={index}/>
            case 'RECLA_NIV2':
                return <ReclaNiv2 task={task}/>
            case 'IMMAT':
                return <ImmatDoublon/>
            case 'EFFET_AN':
            case 'EFFET_AV':
                return <ModifDateEffet task={task}/>
            case 'FEEDBACK_AN':
            case 'FEEDBACK_VDC':
            case 'FEEDBACK_INDEM':
            case 'FEEDBACK_VENTE':
            case 'FEEDBACK_NIV1':
            case 'FEEDBACK_SOUS':
            case 'FEEDBACK_SIN':
                return <FeedbackContainer task={task} terminateTask={task.terminate_url}/>
            case 'REP_DOS':
                return <ReponseDossier task={task}/>
            case 'RECLA':
            case 'RECLA_SIN':
                return <ReclaSin task={task}/>
            case 'ACPTE_CB':
                return <AcpteCb task={task}/>
            case 'ACPTE_FRAC':
                return <AcpteFrac task={task}/>
            case 'TEL_CONSTAT':
                return <TelConstat task={task}/>
            case 'RGT':
            case 'INDEM':
                return <RgtContainer task={task} files={task.files_url} terminateTask={task.terminate_url}/>
            case 'REG_CHQ':
                return <ReglementCheque task={task}/>
            case 'DEC_CTRL':
                return <DecContrl task={task}/>
            case 'INFO_COTI':
                return <InfoCoti task={task} files={task.files_url}/>
            case 'CIAL':
            case 'CPTA_COURTIER':
            case 'SUSP_ESP':
            case 'RES_ESP':
            case 'AN_NIV2':
            case 'RES_NIV2':
            case 'CT_EXP':
            case 'RES_VENTE':
                return <TacheSimpleFile task={task} files={task.files_url}/>
            case 'PAYEUR':
                return <Payeur task={task}/>
            case 'TERME_CTX':
                return <TacheSimple task={task}
                                    message={"Le contrat est résilié/impayé, effacer la quittance Terme encore en impayé"}/>
            case 'IMP_PERTE':
                return <TacheSimple task={task}
                                    message={"Il reste un impayé inférieur à 15 euros sur le contrat, passer la somme en perte."}/>
            case 'NPAI':
                return <NPAI task={task} postValid={postValid} postCloseTask={postCloseTask}/>
            case 'PV':
                return <TachePvContainer task={task} files={task.files_url} postValid={postValid}
                                         postCloseTask={postCloseTask} terminateTask={task.terminate_url}/>
            case 'AVKO':
                return <AVKO task={task} files={task.files_url} postValid={postValid}
                                         postCloseTask={postCloseTask} terminateTask={task.terminate_url}/>
            case'ANA_FRAUDE':
            case 'SUSP_FRAUDE':
                return <AnaFraudeContainer task={task} postValid={postValid} postCloseTask={postCloseTask} terminateTask={task.terminate_url}/>
            case 'RES_14MOIS':
            case 'DEC_CIE':
            case 'RES_FID':
            case 'AG_FID':
            case 'AG_SUIVI_FID':
            case 'REG_KO':
            default:
                return <TacheSimple task={task}/>
        }
    }

    return (
        <Fragment>
            {_.includes(arrMultipleFile, task.paniere) ?
                <Fragment>
                    <div className={'row'}>
                        <div className={'col text-center'}>
                            <h2 className={'title-primary text-uppercase'}>Documents - Tiers n°{task.tiers_id}</h2>
                            <p className={'subtitle'}><span
                                className={'text-uppercase'}>Reçu le {moment(task.date).format('L')} </span>Par {task.type}
                            </p>
                        </div>
                    </div>
                    {task.files_url
                        ? task.files_url.map((file, index) =>
                            file.status !== 'C' && renderFormulaire(file, index)
                        )
                        : (task.paniere === 'DEC' ?
                                <p className={'text-center header-row'}>Si vous ne voyez pas de document, c'est que le
                                    contrat du client est passé "En cours" et donc vous pouvez faire la déclaration de
                                    sinistre.</p>
                                : <p className={'text-center header-row'}>Pas de documents à valider</p>
                        )
                    }
                </Fragment>
                : renderFormulaire()
            }
            <div className="container">
                <div className="row">
                    <div className="col text-center">
                        {(_.startsWith(task.paniere, 'AG_')) ?
                            <Link
                                to={{pathname: `/tache/${task.id}/decaler`, state: {task: task}}}
                                className={'btn btn-secondary header-row'}
                            >
                                Décaler la date de l'agenda
                            </Link>
                            : ''
                        }
                        <Link
                            to={{pathname: `/tache/${task.id}/reaffecter`, state: {task: task, keep_old: 0}}}
                            className={'btn btn-secondary header-row'}
                        >
                            Réaffecter à une panière, un contrat ou un sinistre
                        </Link>
                        <button
                            className={'btn btn-ternary header-row'}
                            onClick={() => {
                                _.includes(dontValidateFiles, task.paniere) ?
                                    postCloseTask(task.terminate_url)
                                    : (task.files_url ?
                                            postValid()
                                            : postCloseTask(task.terminate_url)
                                    )
                            }
                            }
                            disabled={task.files_url ? loading : loadingTerminate}
                        >
                            {(task.files_url ? loading : loadingTerminate) && <Spinner absolute/>}
                            Clôturer la tâche
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default RedirectToTaskForm
