import _ from 'lodash';
import {
    FIDELISATIONS,
    GET_FIDELISATION,
    GET_FIDELISATIONS,
    POST_FIDELISATIONS, setFidelisation,
    setFidelisations, UPDATE_FIDELISATIONS
} from '../../../actions/app/fidelisations/fidelisations.actions';
import { setError, setLoader } from '../../../actions/app/ui/ui.actions';
import { API_ERROR, API_SUCCESS, apiRequest } from '../../../actions/core/api/api.actions';
import { setNotification } from '../../../actions/core/notifications/notifications.actions';
import moment from "moment";

export const fidelisationsMiddleware = () => (next) => (action) => {
    next(action);
    let clone = null;
    let arrayGestes = [];

    switch (action.type) {
        case GET_FIDELISATIONS:
            next([
                apiRequest({ body: null, method: 'GET', url: action.payload.data, entity: FIDELISATIONS }),
                setLoader({ state: true, entity: FIDELISATIONS })
            ]);
            break;

        case `${FIDELISATIONS} ${API_SUCCESS}`:
            next([
                setFidelisations({ fidelisations: action.payload.data }),
                setError({ state: false, entity: FIDELISATIONS }),
                setLoader({ state: false, entity: FIDELISATIONS })
            ]);
            break;

        case `${FIDELISATIONS} ${API_ERROR}`:
            next([
                setError({ state: true, entity: FIDELISATIONS }),
                setLoader({ state: false, entity: FIDELISATIONS })
            ]);
            break;

        case GET_FIDELISATION:
            next([
                apiRequest({ body: null, method: 'GET', url: action.payload.data, entity: GET_FIDELISATION }),
                setLoader({ state: true, entity: GET_FIDELISATION })
            ]);
            break;

        case `${GET_FIDELISATION} ${API_SUCCESS}`:
            next([
                setFidelisation({ fidelisation: action.payload.data }),
                setError({ state: false, entity: GET_FIDELISATION }),
                setLoader({ state: false, entity: GET_FIDELISATION })
            ]);
            break;

        case `${GET_FIDELISATION} ${API_ERROR}`:
            next([
                setError({ state: true, entity: GET_FIDELISATION }),
                setLoader({ state: false, entity: GET_FIDELISATION })
            ]);
            break;

        case POST_FIDELISATIONS:
            clone = _.cloneDeep(action.payload.body);

            if (clone.date_rdv) {
                clone.date_rdv = clone.date_rdv.format('DD/MM/YYYY');
            }
            if (clone.date_demande) {
                clone.date_demande = clone.date_demande.format('DD/MM/YYYY');
            }

            const keyGestes = Object.keys(clone.gestes ?? {});

            keyGestes.forEach((key) => {
                if (clone.gestes[key]) {
                    // gestes_attributes
                    const attributes = clone.gestes_attributes[key]

                    if (attributes?.montant_regul) {
                        attributes.montant_regul = parseInt(attributes.montant_regul);
                    }

                    arrayGestes.push({
                        code: key,
                        ...attributes
                    })
                }
            })

            clone.gestes = arrayGestes
            clone.gestes_attributes = undefined

            next([
                apiRequest({ body: jsonToFormData(clone), method: 'POST', url: action.payload.data, entity: POST_FIDELISATIONS, otherData: action.payload.otherData }),
                setLoader({ state: true, entity: POST_FIDELISATIONS }),
            ]);
            break;

        case `${POST_FIDELISATIONS} ${API_SUCCESS}`:
            next([
                setNotification({ title: 'Fidélisation ajoutée avec succès', type: 'success', entity: POST_FIDELISATIONS }),
                setError({ state: false, entity: POST_FIDELISATIONS }),
                setLoader({ state: false, entity: POST_FIDELISATIONS }),
            ]);
            break;

        case `${POST_FIDELISATIONS} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_FIDELISATIONS,
                    body: action.payload.data.response.data?.message ?? '',
                    type: 'error',
                    title: 'Une erreur est survenue.',
                    timeout: '0'
                }),
                setError({ state: true, entity: POST_FIDELISATIONS }),
                setLoader({ state: false, entity: POST_FIDELISATIONS })
            ]);
            break;

        case UPDATE_FIDELISATIONS:
            clone = _.cloneDeep(action.payload.body);

            if (clone.date_rdv) {
                clone.date_rdv = moment(clone.date_rdv).format('DD/MM/YYYY');
            }
            if (clone.date_demande) {
                clone.date_demande = moment(clone.date_demande).format('DD/MM/YYYY');
            }

            const keyGestesEdit = Object.keys(clone.gestes);

            keyGestesEdit.forEach((key) => {
                if (clone.gestes[key]) {
                    // gestes_attributes
                    const attributes = clone.gestes_attributes[key]

                    if (attributes?.montant_regul) {
                        attributes.montant_regul = parseInt(attributes.montant_regul);
                    }

                    arrayGestes.push({
                        code: key,
                        ...attributes
                    })
                }
            })

            clone.gestes = arrayGestes
            clone.gestes_attributes = undefined

            next([
                apiRequest({ body: jsonToFormData(clone), method: 'POST', url: action.payload.data, entity: UPDATE_FIDELISATIONS, otherData: action.payload.otherData }),
                setLoader({ state: true, entity: UPDATE_FIDELISATIONS }),
            ]);
            break;

        case `${UPDATE_FIDELISATIONS} ${API_SUCCESS}`:
            next([
                setNotification({ title: 'Fidélisation modifiée avec succès', type: 'success', entity: UPDATE_FIDELISATIONS }),
                setError({ state: false, entity: UPDATE_FIDELISATIONS }),
                setLoader({ state: false, entity: UPDATE_FIDELISATIONS }),
            ]);
            break;

        case `${UPDATE_FIDELISATIONS} ${API_ERROR}`:
            next([
                setNotification({
                    entity: UPDATE_FIDELISATIONS,
                    body: action.payload.data.response.data?.message ?? '',
                    type: 'error',
                    title: 'Une erreur est survenue.',
                    timeout: '0'
                }),
                setError({ state: true, entity: UPDATE_FIDELISATIONS }),
                setLoader({ state: false, entity: UPDATE_FIDELISATIONS })
            ]);
            break;

        default:
            break;
    }
    return null;
};

function buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? '' : data;

        formData.append(parentKey, value);
    }
}

function jsonToFormData(data) {
    const formData = new FormData();

    buildFormData(formData, data);

    return formData;
}
