const validate = values => {
    const errors = {}

    let requiredFields = [
        'date_debut',
        'date_fin',
        'immat_contrat'
    ]

    if (values['immat_contrat'] && values['immat_contrat'] === 'T') {
        requiredFields.push('vol_violence');
        requiredFields.push('elements_voles');

        if(values['vol_violence'] && values['vol_violence'] === 'F'){
            requiredFields.push('vehicule_attache');
        }

        if(values['local'] && values['local'] === 'F'){
            requiredFields.push('lieux_accident');
        }

        if(values['vol_autres'] && values['vol_autres'] === 'T'){
            requiredFields.push('vol_autres_detail');
        }
    }

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Ce champ est obligatoire'
        }
    })

    return errors
}

export default validate
