import React, {useEffect} from 'react';
import {Field} from "redux-form";
import RadioFieldRedux from "../../../../../../../Commun/Input/Radio/RadioFieldRedux";
import TextAreaFieldRedux from "../../../../../../../Commun/Input/Text/TextAreaFieldRedux";
import _ from "lodash";

const Degats = (props) => {
    const {
        equipementsDommage,
        getDocumentsAccident,
        document_police,
        catsin,
        point_choc,
        changeValue,
        gants,
        bottes,
        blouson,
        casque,
        airbag,
        autres,
        dommagesDeclares
    } = props

    useEffect(() => {
        let desc = ''
        let choc = 'Point de choc : '

        switch (point_choc) {
            case 'bottom':
                choc += 'Arrière - '
                break
            case 'top':
                choc += 'Avant - '
                break
            case 'left':
                choc += 'Coté gauche - '
                break
            case 'right':
                choc += 'Coté droit - '
                break
            case 'top_right':
                choc += 'Avant droit - '
                break
            case 'top_left':
                choc += 'Avant gauche - '
                break
            case 'bottom_left':
                choc += 'Arrière gauche - '
                break
            case 'bottom_right':
                choc += 'Arrière droit - '
                break
            case 'middle_right':
                choc += 'Coté droit - '
                break
            case 'middle_left':
                choc += 'Coté gauche - '
                break
            default:
                choc = 'Point de choc : Non défini - '
        }

        if (gants === 'T') {
            desc += 'Gants -'
        }
        if (bottes === 'T') {
            desc += 'Bottes -'
        }
        if (blouson === 'T') {
            desc += 'Blouson -'
        }
        if (casque === 'T') {
            desc += 'Casque -'
        }
        if (airbag === 'T') {
            desc += 'Airbag -'
        }
        if (autres === 'T') {
            desc += 'Autres equipements -'
        }

        if (dommagesDeclares !== undefined && _.includes(dommagesDeclares, choc + desc)) {
            changeValue('dommagesDeclares', dommagesDeclares)
        } else {
            changeValue('dommagesDeclares', choc + desc)
        }

    }, [point_choc, gants, bottes, blouson, casque, airbag, autres])

    return (
        <div className={`form-content h-100`}>
            <h1 className={'title-primary'}>Quels sont les dégats du véhicule et des équipements ?</h1>

            <Field
                name='vehiculeDommage'
                component={RadioFieldRedux}
                label={'Il y a-t-il des dégâts sur votre véhicule ?'}
                data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
            />

            <Field
                name='equipementsDommage'
                component={RadioFieldRedux}
                label={'Il y a-t-il des dégâts sur vos équipements et accessoires ?'}
                data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                onChange={(e) => e.target.value === 'T' && getDocumentsAccident(_.split(catsin, '.'), true, document_police)}
            />

            {equipementsDommage === 'T' && <div className={'row'}>
                <Field
                    name='equipementsDommageCasque'
                    component={RadioFieldRedux}
                    label={'Casque'}
                    className={'col-4'}
                    data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                />
                <Field
                    name='equipementsDommageGants'
                    component={RadioFieldRedux}
                    label={'Gants'}
                    className={'col-4'}
                    data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                />
                <Field
                    name='equipementsDommageBlouson'
                    component={RadioFieldRedux}
                    label={'Blouson'}
                    className={'col-4'}
                    data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                />
                <Field
                    name='equipementsDommageBottes'
                    component={RadioFieldRedux}
                    label={'Bottes'}
                    className={'col-4'}
                    data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                />
                <Field
                    name='equipementsDommageAirbag'
                    component={RadioFieldRedux}
                    label={'Gilet Airbag'}
                    className={'col-4'}
                    data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                />
                <Field
                    name='equipementsDommageAutre'
                    component={RadioFieldRedux}
                    label={'Autres'}
                    className={'col-4'}
                    data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                />
            </div>}

            <Field
                name='dommagesDeclares'
                component={TextAreaFieldRedux}
                label='Quels sont les dommages du véhicule ?'
                maxLength={'120'}
                subLabel={'120 caractères maximum'}
            />
        </div>
    );
};

export default Degats;
