import {connect} from 'react-redux'
import Circonstances from './Circonstances'

const mapStateToProps = state => {
    return {
        circonstances: [
            {
                number: '1a',
                text: 'en stationnement'
            },
            {
                number: '1b',
                type: 'arret',
                text: 'à l\'arrêt'
            },
            {
                number: 2,
                text: 'quittait un stationnement / ouvrait une portière'
            },
            {
                number: 3,
                text: 'prenait un stationnement'
            },
            {
                number: 4,
                text: 'sortait d\'un parking, d\'un lieu privé, d\'un chemin de terre',
            },
            {
                number: 5,
                text: 's\'engageait dans un parking, un lieu privé, un chemin de terre',
            },
            {
                number: 6,
                text: 's\'engageait sur une place à sens giratoire',
            },
            {
                number: 7,
                text: 'roulait sur une place à sens giratoire',
            },
            {
                number: 8,
                text: 'heurtait à l\'arrière en roulant dans le même sens et sur même file',
            },
            {
                number: 9,
                text: 'roulait dans le meme sens et sur une file différente',
            },
            {
                number: 10,
                text: 'changeait de file',
            },
            {
                number: 11,
                text: 'doublait',
            },
            {
                number: 12,
                text: 'virait à droite',
            },
            {
                number: 13,
                text: 'virait à gauche',
            },
            {
                number: 14,
                text: 'reculait',
            },
            {
                number: 15,
                text: 'empiétait sur une voie reservée à la circulation et en sens inverse',
            },
            {
                number: 16,
                text: 'venait de droite (dans un carrefour)',
            },
            {
                number: 17,
                text: 'n\'avait pas observé un signal de priorité ou un feu rouge',
            },
        ]
    }
}

const CirconstancesContainer = connect(mapStateToProps)(Circonstances)

export default CirconstancesContainer
