import React from 'react'
import PropTypes from 'prop-types'
import {Route, Redirect} from 'react-router-dom'


const PrivateRoute = (props) => {
    const {login, token, urlUser, getUser, fail, component: Component, ...rest} = props

    if ((!token && !login) || (fail && token && !login)) {
        return <Redirect to={'/login'}/>
    }

    if (fail || !token) {
        window.location.reload()
        return null
    }

    if (!login) {
        return getUser(urlUser)
    }

    return (
        <Route
            {...rest}
            render={props => {
                return <Component {...props} className='loader'/>
            }}
        />
    )
}

PrivateRoute.propTypes = {
    component: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
}

export default PrivateRoute
