import {connect} from 'react-redux'
import Responsabilite from './Responsabilite'
import {getIda, getRc} from "../../../../../../../../redux/selectors/cas_ida/cas_ida.selector";
import {change, formValueSelector} from "redux-form";
import {bindActionCreators} from "redux";

const mapStateToProps = state => {
    const selector = formValueSelector('constat')

    return {
        categorie: selector(state, 'catsinorigin'),
        cas_ida: getIda(state),
        rc: getRc(state),
        accordCheck: selector(state, 'accord_ida'),
        defineManuel: selector(state, 'define_ida'),
        cas_ida_calc: selector(state, 'manuel_ida'),
        rc_calc: selector(state, 'manuel_resp'),
        codegta: selector(state, 'codegta')
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    changeValue: (field, value) => change('constat', field, value),
}, dispatch)

const ResponsabiliteContainer = connect(mapStateToProps, mapDispatchToProps)(Responsabilite)

export default ResponsabiliteContainer
