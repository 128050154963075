import React from 'react';
import Spinner from "../../../../../Commun/Spinner/Spinner";

const ChequesCadeaux = (props) => {
    const {eligible, canPost, postChequesCadeaux, loadingPost} = props

    return (
        <div className={'bloc-info bloc-promo'}>
            <h2>Chèques-cadeaux</h2>

            <p>Le
                concessionnaire <b>{eligible === null ? 'n\'est pas dans la liste' : eligible === 'INCLUS' ? 'est inclus' : 'est exclus'}</b> des
                chèques-cadeaux.</p>

            {
                canPost && <div className={"d-flex justify-content-center"}>
                    {
                        loadingPost ? <Spinner/>
                            : <>
                                {eligible !== 'INCLUS' && <span className={'btn btn-cta mx-3'}
                                                                onClick={() => postChequesCadeaux({cheques_cadeaux: 'INCLUS'})}><b>Inclure</b> le concessionnaire des chèques-cadeaux</span>}
                                {eligible !== 'EXCLUS' && <span className={'btn btn-cta mx-3'}
                                                                onClick={() => postChequesCadeaux({cheques_cadeaux: 'EXCLUS'})}><b>Exclure</b> le concessionnaire des chèques-cadeaux</span>}
                            </>
                    }
                </div>
            }
        </div>
    );
};

export default ChequesCadeaux;
