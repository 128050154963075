import {compose} from "redux";
import {connect} from "react-redux";

import ErrorHttpComponent from "../../../../Commun/Error/ErrorHttpComponent";
import DashboardTeleventeConseillerDetail from "./DashboardTeleventeConseillerDetail";
import {
    getDashboardConseillerTeleventeTeamResults, getDashboardConseillerTeleventeUserResult
} from "../../../../../redux/selectors/dashboard_conseiller_televente/dashboard_conseiller_televente.selectors";
import {getUserIdBelair} from "../../../../../redux/selectors/user/user.selectors";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    return {
        belair_id: getUserIdBelair(state),
        team_results: getDashboardConseillerTeleventeTeamResults(state),
        user_result: getDashboardConseillerTeleventeUserResult(state),
    }
}

const DashboardTeleventeConseillerDetailContainer = compose(
    connect(mapStateToProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(DashboardTeleventeConseillerDetail)

export default DashboardTeleventeConseillerDetailContainer