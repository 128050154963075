import React from 'react';
import {Redirect} from 'react-router-dom'
import InitFormContainer from "./InitForm/InitFormContainer";
import InitFormFleetContainer from "./InitFormFleet/InitFormFleetContainer";

const ReportAccident = (props) => {
    const {contract, accidentAlreadyPost, isFleet, idVehicule} = props

    return (
        <div>
            {
                accidentAlreadyPost &&
                (Number(accidentAlreadyPost.idContract) === contract.id)

                    ? <Redirect
                        to={{
                            pathname: `/contrat/${accidentAlreadyPost.idContract}/sinistre/${accidentAlreadyPost.idAccident}`,
                            state: {
                                souscripteur: contract.souscripteur
                            }
                        }}
                    />
                    : isFleet ? <InitFormFleetContainer contract={contract} idVehicule={idVehicule}/> :
                        <InitFormContainer contract={contract} idVehicule={idVehicule}/>
            }
        </div>

    );
};

export default ReportAccident;
