export const FEEDBACK = '[Feedback]'

export const GET_FEEDBACK = `${FEEDBACK} Get`
export const SET_FEEDBACK = `${FEEDBACK} Set`

export const getFeedback = ({query}) => ({
    type: GET_FEEDBACK,
    payload: {
        data: query
    }
})

export const setFeedback = ({data}) => ({
    type: SET_FEEDBACK,
    payload: {
        data
    }
})