import {PIECES, GET_PIECES, setPieces} from '../../../actions/app/pieces/pieces.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'

export const piecesMiddleware = () => next => action => {
    next(action)

    switch (action.type) {

        case GET_PIECES:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: PIECES}),
                setLoader({state: true, entity: PIECES})
            ])
            break

        case `${PIECES} ${API_SUCCESS}`:
            const {data} = action.payload.data

            next([
                setPieces({data}),
                setError({state: false, entity: PIECES}),
                setLoader({state: false, entity: PIECES})
            ])
            break

        case `${PIECES} ${API_ERROR}`:
            next([
                setError({state: true, entity: PIECES}),
                setLoader({state: false, entity: PIECES})
            ])
            break

        default:
            break
    }
    return null
}
