import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {QUITTANCE, getQuittance} from '../../../../../../redux/actions/app/quittances/quittance.actions'
import {getErrorEntity, getLoadingEntity} from '../../../../../../redux/selectors/ui/ui.selectors'
import QuittanceDetail from './QuittanceDetail'
import {quittanceDetailGetLink} from '../../../../../../redux/selectors/links/links.selectors'
import ErrorHttpComponent from '../../../../../Commun/Error/ErrorHttpComponent'
import {
    getQuittanceDetails,
    getQuittanceTableau
} from '../../../../../../redux/selectors/quittances/quittances.selectors'
import _ from "lodash";
import loader from "../../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    return {
        details: getQuittanceDetails(state),
        tableau: getQuittanceTableau(state),
        uri: quittanceDetailGetLink(state),
        loaded: getLoadingEntity(state, QUITTANCE) === false,
        error: getErrorEntity(state, QUITTANCE),
        id_quittance: ownProps.match.params.id,
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getQuittance: (query) => getQuittance({query}),
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getQuittance} = dispatchProps
    const {uri, id_quittance} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getQuittance(_.replace(uri, '{id}', id_quittance)),
    }
}

const QuittanceDetailContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(QuittanceDetail)

export default QuittanceDetailContainer
