import React from 'react'
import moment from "moment";

const Payeur = (props) => {
    const {task: {date, type, paniere, tiers_id, contract}} = props
    const tiersPayeur = contract?.tiers_payeur;

    return (
        <div className={'container'}>
            <div className={'row'}>
                <div className={'col text-center'}>
                    <h2 className={'title-primary text-uppercase'}>{paniere} - Tiers n°{tiers_id}</h2>
                    <p className={'subtitle'}>Tâche du {moment(date).format('L')}. Recu par {type}</p>
                </div>
            </div>
            <div className={'header-row row'}>
                <div className={'col-12'}>
                    <p className={'form-content text-left'}>
                        Payeur Autre : {tiersPayeur.nom} {tiersPayeur.prenom} / Motif d’intervention : {tiersPayeur.raison_autre}<br/><br/>

                        A vous de valider ou non le payeur de la cotisation.<br/><br/>

                        Vous acceptez : Clôturez la tâche et continuez la gestion du dossier<br/><br/>

                        Vous refusez : Clôturez la tâche  + supprimez le payeur + contactez le client afin de l'informer que le payeur de la prime ne peut être la personne déclarer lors de la souscription.<br/><br/>

                        Dans ce cas il faut donc lui demander un nouveau mandat et rib et remettre la pièce en attente dans la base documentaire.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Payeur
