import {createSelector} from 'reselect'

const activitySelectors = state => state.activity

export const getActivityData = createSelector(
    activitySelectors,
    activity => activity
)
export const getActivityFilesData = createSelector(
    activitySelectors,
    activity => activity.files
)
