import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import VirementsSinistres from './VirementsSinistres';
import ErrorHttpComponent from '../../../../Commun/Error/ErrorHttpComponent';
import {getVirements} from '../../../../../redux/actions/app/virements/virements.actions';
import {virementsGetLink} from '../../../../../redux/selectors/links/links.selectors';
import {
    getVirementsData,
    getVirementsMeta,
} from "../../../../../redux/selectors/virements/virements.selectors";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state) => {
    return {
        virements: getVirementsData(state),
        meta: getVirementsMeta(state),
        uri: virementsGetLink(state)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getVirements: ({
                       query,
                       sortVirement,
                       montantVirement,
                       dateVirement,
                       numSinistre,
                       beneficiairesPrelevementsVirement,
                       pageVirement
                   }) => getVirements({
        query,
        sortVirement,
        montantVirement,
        dateVirement,
        numSinistre,
        beneficiairesPrelevementsVirement,
        pageVirement
    }),
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getVirements} = dispatchProps
    const {uri} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getVirements({
            query: uri,
            montantVirement: localStorage.getItem('montant_vir_sinistre') || '',
            sortVirement: localStorage.getItem('sort_vir_sinistre') || '',
            dateVirement: localStorage.getItem('date_vir_sinistre') || '',
            numSinistre: localStorage.getItem('num_sinistre') || '',
            beneficiairesPrelevementsVirement: localStorage.getItem('beneficiaires_prelevements_vir_sinistre') || '',
        })
    }
}

const VirementsSinistresContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent}),
)(VirementsSinistres)

export default VirementsSinistresContainer;
