import React from 'react'
import moment from "moment";
import {Link} from "react-router-dom";

const InfoClient = ({tiers}) => {
    return (
        <div className={'bloc-info bloc-souscripteur'}>
            <h2>Informations du client</h2>
            <div className={'content row'}>
                <div className={'col-5 left-content'}>
                    <p>Nom :</p>
                    <p>Prénom :</p>
                    <p>Adresse :</p>
                    {tiers.address_street_2 && <p>&nbsp;</p>}
                    <p>Code Postal - Ville :</p>
                    <p>Téléphone :</p>
                    <p>Email :</p>
                    <p>Date de naissance :</p>
                    <p>Ville de naissance :</p>
                    <p>Pays de naissance :</p>
                </div>
                <div className={'col-7 right-content'}>
                    <p>{tiers.lastname}</p>
                    <p>{tiers.firstname}</p>
                    <p>{tiers.address_street_1}</p> {tiers.address_street_2 && <p>{tiers.address_street_2}</p>}
                    <p>{tiers.address_postalcode} {tiers.address_city}</p>
                    <p>{tiers.address_phone || '-'}</p>
                    <p>{tiers.address_mail || '-'}</p>
                    <p>{moment(tiers.date_naissance).format('L') || '-'}</p>
                    <p>{tiers.ville_naissance || '-'}</p>
                    <p>{tiers.pays_naissance || '-'}</p>
                </div>
                <div className={'col-12 full-content text-center'}>
                    <hr/>
                    {tiers.parrain &&
                        <p>Tiers parrainé par : <Link to={{pathname: `/tiers/${tiers.parrain.id_parrain}`}}>
                            {tiers.parrain.raison_sociale}
                        </Link></p>}
                </div>
            </div>
        </div>
    )
}

export default InfoClient
