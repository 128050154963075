import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {change, reduxForm} from 'redux-form'
import UpdateHamon from './UpdateHamon'
import validate from "./validate";
import {postEditHamonGetLink} from "../../../../../redux/selectors/links/links.selectors";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {EDIT_HAMON, postEditHamon} from "../../../../../redux/actions/app/hamon/hamon.actions";
import _ from "lodash";

const mapStateToProps = (state, ownProps) => {
    const {contrat} = ownProps.location.state

    return {
        initialValues: {
            'contrat': contrat
        },
        uri: postEditHamonGetLink(state),
        loading: getLoadingEntity(state, EDIT_HAMON),
        idContrat: ownProps.location.state.id_contrat
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    changeValue: (field, value) => change('update-hamon', field, value),
    submitPostEditHamon: ({insurer, police, query}) => postEditHamon({insurer, police, query})
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {submitPostEditHamon} = dispatchProps
    const {uri, idContrat} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form) => submitPostEditHamon({
            insurer: form.compagnie,
            police: form.contrat,
            query: _.replace(uri, '{id}', idContrat)
        })
    }
}
const UpdateHamonContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'update-hamon',
        validate
    })
)(UpdateHamon)

export default UpdateHamonContainer
