import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Field} from 'redux-form'
import {Redirect} from 'react-router-dom'
import MyButton from '../../Commun/Button/Button'
import TextFieldRedux from '../../Commun/Input/Text/TextFieldRedux'
import Spinner from '../../Commun/Spinner/Spinner'
import Logo from '../../../assets/img/logos/logo_small.png'
import BlueBubble from "../../../assets/img/icons/login/blue_bubble.png";

class Login extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const {handleSubmit, login, loading, errorState, fiche} = this.props

        if (login) {
            return <Redirect to={'/'}/>
        }

        return (
            <div className={'login-page'}>
                <div className={'container-fluid text-center bloc-entete'}>
                    <h1>Bienvenue sur votre espace conseiller</h1>
                </div>
                <div className={'container bloc-content'}>
                    <div className={'row'}>
                        <div className={"d-none d-xl-block col-xl-6"}>
                            <img src={fiche.image} className={"photo img-fluid"} alt=""/>
                        </div>

                        <div className={"col-12 col-xl-6"}>

                            {fiche.accroche && (
                                <div className={"titre-fiche  mt-3 mt-xl-5"}>
                                    <div
                                        className={`bulle bulle_g theme${fiche.conseiller_fiche_connexion_themes_id}`}></div>
                                    <h2>{fiche.accroche}</h2>
                                </div>
                            )}

                            <ul>
                                <div className={`bulle_small theme${fiche.conseiller_fiche_connexion_themes_id}`}>
                                    <img src={BlueBubble} alt=""/>
                                </div>
                                <li>Prénom<br/><span>{fiche.prenom}</span></li>
                                <li className={"age"}>Age<br/><span>{fiche.age} ans</span></li>
                                <li>Profil<br/><span dangerouslySetInnerHTML={{__html: fiche.profil}}/></li>
                                {fiche.permis &&
                                    <li>Permis<br/><span>{fiche.permis}</span></li>
                                }
                                <li className={'ville'}>Ville<br/><span>{fiche.ville}</span></li>
                            </ul>

                            {fiche.discours && <p className={"verbatim"}>« {fiche.discours} »</p>}

                            {fiche.baseline && <p className={"baseline"}>{fiche.baseline}</p>}
                            {fiche.reassurance && <p className={"reassurance"}>{fiche.reassurance}</p>}
                        </div>
                    </div>
                </div>
                <footer></footer>
                <div className={"bloc-login container"}>
                    <h2>Connectez-vous avec vos identifiants Paddock</h2>
                    <div className={'d-flex nowrap justify-content-around'}>
                        <div>
                            <img src={Logo} alt="APRIL Moto" className={'img-fluid'}/>
                        </div>

                        <div className={'flex-grow-1 align-self-center'}>
                            <form onSubmit={handleSubmit} className={'row'}>

                                <div className='col-12 col-xl-4'>
                                    <Field
                                        name='username'
                                        component={TextFieldRedux}
                                        label='Identifiant Paddock'
                                        placeholder='Saisissez votre identifiant Paddock'
                                    />
                                </div>

                                <div className='col-12 col-xl-4'>
                                    <Field
                                        name='password'
                                        component={TextFieldRedux}
                                        label='Mot de passe Paddock'
                                        placeholder='Saisissez votre mot de passe Paddock'
                                        type='password'
                                    />
                                </div>

                                <div className='col-12 col-xl-4'>
                                    <MyButton
                                        type='submit'
                                        variant='raised'
                                        color='secondary'
                                        disabled={loading}
                                        size='large'
                                        className={`btn btn-block text-center theme1`}
                                    >
                                        {loading && <Spinner absolute/>}
                                        Ok, je suis prêt(e)
                                    </MyButton>
                                </div>
                                {errorState && <p className='col-12 error mt-2'>Votre adresse email / mot de passe sont
                                    incorrects</p>}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

Login.propTypes = {
    handleSubmit: PropTypes.func
}

export default Login
