import { useState, useEffect, Fragment } from 'react';
import Documents from './Documents/Documents';
import IntervenantsContainer from './Intervenants/IntervenantsContainer';
import Timeline from './Timeline/Timeline';
import Badge from '../../../Commun/Badge/Badge';
import ButtonsContainer from './Buttons/ButtonsContainer';
import Resume from './Resume/Resume';
import TypeSinistreContainer from './TypeSinistre/TypeSinistreContainer';
import CirconstancesContainer from './Circonstances/CirconstancesContainer';
import Garanties from './Garanties/Garanties';
import NotesContainer from './Notes/NotesContainer';
import { Link } from 'react-router-dom';
import { getCompagnys, redirectInNewWindow } from '../../../../utils/function';
import TasksContainer from '../Tiers/Client/Tasks/TasksContainer';
import { openInWindow } from '../../../../constants/constants';
import ReclamationsContainer from '../Reclamations/ReclamationsContainer';
import CommentairesContainer from './Commentaires/CommentairesContainer';
import Reglement from './Reglement/Reglement';
import NavigationAccidentsContainer from './NavigationAccidents/NavigationAccidentsContainer';
import TiersAdverse from './TiersAdverse/TiersAdverse';
import RapportExpertContainer from './RapportExpert/RapportExpertContainer';
import AccessoiresContainer from './Accessoires/AccessoiresContainer';
import ExpertContainer from './Expert/ExpertContainer';
import ReparateurContainer from './Reparateur/ReparateurContainer';
import FeedbackNoteContainer from '../Contract/FeedbackNote/FeedbackNoteContainer';
import PenalitesContainer from './Penalites/PenalitesContainer';
import DerogationsContainer from '../Derogations/DerogationsContainer';
import OppositionContainer from './Opposition/OppositionContainer';
import Temoin from "./Temoin/Temoin";
import moment from "moment";
import _ from "lodash";
import tracfin from "../../../../assets/img/icons/Logo-tracfin-light.png";

const Accident = (props) => {
    const { isWakam, accident, contract, idContract, roles, permissions, submitDefinedAsCovered } = props;
    const [compagnys, setCompagnys] = useState([]);
    const [temoin, setTemoin] = useState(null);

    useEffect(() => {
        const temoinIntervenant = accident.intervenants.find(intervenant => intervenant.type === 'Témoin');

        setTemoin(temoinIntervenant);
    }, [accident.intervenants]);

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchCompagnys();
    }, []);

    async function fetchCompagnys() {
        let list = await getCompagnys();
        setCompagnys(list);
    }


    let garanties = accident.garanties.map((g, index) => {
        return g.sinistree === 'OUI';
    });
    const sinistree = _.includes(garanties, true);

    const RemboursementReparateur =
        accident.remboursement_garage === 'T'
            ? 'OUI'
            : accident.remboursement_garage === 'F'
                ? 'NON'
                : 'Ne sait pas';
    const RembReparateurClassnames =
        accident.remboursement_garage === 'T'
            ? 'alert-success'
            : accident.remboursement_garage === 'F'
                ? 'alert-danger'
                : 'alert-info';

    const cantChangeStatusSinistre = () => {
        let company = _.find(compagnys, ['id', accident.id_compagnie_adverse]);

        if (
            accident.situationCode === '3' &&
            (accident.decDommages === '' || (accident.tauxResp === null || accident.tauxResp === '-') || !sinistree)
        ) {
            return true;
        }

        if (accident.situationCode === '3') {
            if (accident.categorie === '') {
                return true;
            }

            if (accident.categorieCode === '103' || accident.categorieCode === '104') {
                if (accident.decDommages === '' || (accident.circonstances === '' || accident.circonstances === null) || accident.ida === '') {
                    return true;
                }

                if (company && company.codegta !== '') {
                    if (!accident.adverse || !accident.adverse.id) {
                        return true;
                    }

                    if (((accident.adverse.immat === '' || accident.adverse.immat === null) || (accident.adverse.police === '' || accident.adverse.police === null))) {
                        return true;
                    }
                }
            }
        }
        return false;
    };

    return (
        <Fragment>
            <div className={'accident-container container'}>
                <div className={'row return-content'}>
                    <div className={'col'}>
                        <button onClick={() => props.history.go(-1)} className={'btn btn-return'}>
                            Retour
                        </button>
                    </div>
                </div>
                <NavigationAccidentsContainer tiers={contract.souscripteur.id} current={accident.id}/>
                <div className={'row'}>
                    <div className={'col-12'}>
                        <Link to={`/contrat/${contract.id}`} className={'link-title-primary d-inline-block'}>
                            <h1 className={'title-primary'}>
                                {contract.souscripteur.titre} {contract.souscripteur.nom} {contract.souscripteur.id} -
                                Sinistre n°{accident.numCbt}
                                <span className={'link-icon-primary ml-2'}/>
                            </h1>
                        </Link>
                        <Badge status={accident.situation} classBadge={'align-text-top'}/>

                        {_.includes(roles, 'gestionnaire indem') && (
                            <span
                                className={'btn btn-cta btn-action float-right'}
                                onClick={(e) =>
                                    redirectInNewWindow({
                                        e,
                                        url: `/les-activites/accident/${props.idAccident}/${props.idContract}`,
                                        name: openInWindow.activitiesAccident
                                    })
                                }>
                                Les activités du sinistre
                            </span>
                        )}
                    </div>

                    {(!_.includes(roles, 'gestionnaire externe') && !_.includes(roles, 'gestionnaire televente')) && (
                        <div className={'col-12 col-md-8 mt-3'}>
                            <ButtonsContainer accident={accident} idContract={idContract}
                                              souscripteur={contract.souscripteur}/>
                        </div>
                    )}
                    <div className={'col-12 col-md-4 mt-3 text-right'}>
                        <FeedbackNoteContainer tiers_id={contract.souscripteur.id}/>
                    </div>

                    <div className={'col-12 mt-3'}>
                        <span className={'bold subtitle'}>
                            N° Allianz :&nbsp;{accident.numCie} - {accident.marqueVehicule} {accident.modeleVehicule}
                            <br/>
                            N° Contrat :&nbsp;{contract.numeroPolice}
                            <br/>
                            Dernière intervention : {moment(accident.dateDerniereIntervention).format('L')}{' '}
                            {accident.gestionnaireDerniereIntervention ? `par ${accident.gestionnaireDerniereIntervention}` : ''}
                            {accident.hivernage && (
                                <>
                                    <br/>
                                    Sinistre pendant Ma période
                                    hivernale {moment(accident.hivernage.dateDebut, 'YYYY-MM-DD').format('DD/MM/YYYY')} au{' '}
                                    {moment(accident.hivernage.dateFin, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                                </>
                            )}
                        </span>
                    </div>
                </div>
                <div className="row header-row">
                    <div className="col">
                        {_.includes(permissions, 'update accident informations') && (
                            <Fragment>
                                {cantChangeStatusSinistre() ? (
                                    <>
                                        <br/>
                                        <div className={'alert alert-warning row'}>Changement de statut impossible.
                                            Merci de vérifier les données.
                                        </div>
                                    </>
                                ) : (
                                    <Link
                                        to={{
                                            pathname: `/contrat/${contract.id}/sinistre/${accident.id}/modification-statut`,
                                            state: {accident: accident}
                                        }}
                                        className={'btn btn-cta btn-danger mt-3 ml-0'}>
                                        Changer le statut du sinistre
                                    </Link>
                                )}
                            </Fragment>
                        )}
                        {_.includes(permissions, 'update accident informations') && accident.situationCode === '1' && accident.reparateur && accident.reparateur.id && !accident?.assureDeces && (
                            <button
                                type={'button'}
                                onClick={() => {
                                    if (window.confirm('Voulez-vous vraiment déclarer la prise en charge ?')) submitDefinedAsCovered();
                                }}
                                className={'btn btn-cta btn-ternary mt-3 ml-3'}>
                                Prise en charge
                            </button>
                        )}
                    </div>
                    {accident.situation === '3' && (
                        <div className={'col'}>
                            <div className={'alert alert-warning'}>
                                <p>Attention, la situation du sinistre est "En attente" : cela signifie que la
                                    responsabilité et le cas IDA sont à contrôler avant d'ouvrir le sinistre.</p>
                            </div>
                        </div>
                    )}
                </div>
                {((contract.tracfin?.type === 'GDA' && moment(contract.tracfin?.date, 'YYYY-MM-DD') > moment()) || contract.tracfin?.type === 'PPE') &&
                    <div className="row header-row">
                        <div className={'col'}>
                            <div className={'alert alert-danger'}>
                                <p><img src={tracfin} width={40} /> PPE/GDA confirmée - Prévenir le correspondant Tracfin pour tout mouvement sur le dossier</p>
                            </div>
                        </div>
                    </div>
                }
                <div className={'row header-row'}>
                    <div className={'col'}>
                        <TasksContainer className={'loader'} tiers={contract.souscripteur.id}/>
                    </div>
                </div>
                {!isWakam && (
                    <div className={'row header-row'}>
                        <div className={'col-12'}>
                            <Timeline accident={accident}/>
                        </div>
                    </div>
                )}
                {accident.categorieCode !== '10' && (
                    <div className={'row header-row'}>
                        <div className={'col-10 offset-md-1'}>
                            <div className={`alert ${RembReparateurClassnames}`}>
                                <p>
                                    Est-ce que le client souhaite que l'on rembourse directement le réparateur
                                    : {RemboursementReparateur}.
                                    <Link
                                        to={{
                                            pathname: `/contrat/${contract.id}/sinistre/${accident.id}/modification-remboursement-reparateur`,
                                            state: {
                                                refund: accident.remboursement_garage,
                                                contract: contract,
                                                accident: accident
                                            }
                                        }}
                                        className={'btn btn-cta btn mt-0 ml-5'}>
                                        Modifier
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                )}
                <NotesContainer accident={accident} contractId={idContract} souscripteur={contract.souscripteur}
                                className={'loader'}/>
                <div className={'row margin-top'}>
                    <div className={'col-6'}>
                        <CirconstancesContainer accident={accident} permissions={permissions} contrat={contract}/>
                    </div>
                    <div className={'col-6'}>
                        <TypeSinistreContainer accident={accident} contract={contract.id}/>
                    </div>
                </div>
                {_.includes(roles, 'gestionnaire indem') && (
                    <div className={'row margin-top'}>
                        <div className={'col-12'}>
                            <Documents
                                tiersId={contract.souscripteur.id}
                                risqueNum={contract.adhesionPrincipale}
                                documents={accident.documents}
                                contractId={idContract}
                                idSinistre={accident.id}
                                roles={roles}
                            />
                        </div>
                    </div>
                )}
                <div className={'row margin-top'}>
                    <div className={'col-6'}>
                        <Resume accident={accident} contract={contract}/>
                    </div>
                    <div className={'col-6'}>
                        <IntervenantsContainer
                            intervenants={accident.intervenants}
                            conducteur={accident.conducteur}
                            compagnieAdverse={accident.compagnieAdverse}
                            accident={accident.id}
                            contract={contract.id}
                            idCompagnieAdverse={accident.id_compagnie_adverse}
                            roles={roles}
                            permissions={permissions}
                        />
                    </div>
                </div>
                <div className={'row margin-top'}>
                    <div className={'col-6'}>
                        <ExpertContainer
                            permissions={permissions}
                            accidentId={accident.id}
                            reparateur={accident.reparateur}
                            contractId={idContract}
                            expert={accident.expert}
                            dateExpert={accident.datePassageExpert}
                            categorieSinCode={accident.categorieCode}
                        />
                    </div>
                    <div className={'col-6'}>
                        <ReparateurContainer permissions={permissions} reparateur={accident.reparateur}
                                             accident={accident.id} contract={contract.id}/>
                    </div>
                </div>

                {_.includes(roles, 'gestionnaire indem') && (
                    <div className={'row margin-top'}>
                        <div className={'col-6'}>
                            <TiersAdverse
                                adverse={accident.adverse}
                                adverseNb={1}
                                idCompagnieAdverse={accident.adverse.cieAdv}
                                permissions={permissions}
                                accident={accident.id}
                                contract={contract.id}
                                compagnieAdverse={accident.compagnieAdverse}
                                roles={roles}
                            />
                        </div>
                        {accident.adverse2 && (
                            <div className={'col-6'}>
                                <TiersAdverse
                                    adverse={accident.adverse2}
                                    adverseNb={2}
                                    idCompagnieAdverse={accident.adverse2.cieAdv}
                                    permissions={permissions}
                                    accident={accident.id}
                                    contract={contract.id}
                                    compagnieAdverse={accident.compagnieAdverse2}
                                    roles={roles}
                                />
                            </div>
                        )}
                    </div>
                )}
                {_.includes(roles, 'gestionnaire indem') && (
                    <div className={'row margin-top'}>
                        <div className={'col-6'}>
                            <Temoin
                                temoin={temoin}
                                accident={accident.id}
                                contract={contract.id}
                            />
                        </div>
                    </div>
                )}
                <div className="row margin-top">
                    <div className="col">
                        <DerogationsContainer className={'loader'} idAccident={accident.id}/>
                    </div>
                </div>
                <div className="row margin-top">
                    <div className="col">
                        <ReclamationsContainer className={'loader'} id={contract.souscripteur.id}/>
                    </div>
                </div>
                <div className={'row margin-top'}>
                    <div className={'col-12'}>
                        <div className={'bloc-info bloc-circonstance'}>
                            <h2>Commentaires</h2>
                            <div className={'content row'}>
                                <div className={'col full-content'}>
                                    <CommentairesContainer permissions={permissions} accident={accident}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'row margin-top'}>
                    <div className={'col-6'}>
                        <RapportExpertContainer idContract={contract.id} idAccident={accident.id}/>
                    </div>
                    <div className={'col-6'}>
                        <AccessoiresContainer contract={contract} accident={accident}/>
                    </div>
                </div>
                <div className={'row margin-top'}>
                    <div className={'col-6'}>
                        <PenalitesContainer penalites={accident.penalties} permissions={permissions}
                                            accident={accident.id} contract={contract.id}/>
                    </div>
                    <div className={'col-6'}>
                        <OppositionContainer oppositions={accident.oppositions} permissions={permissions}
                                             accident={accident.id} contract={contract.id}/>
                    </div>
                </div>
                <div className={'row margin-top'}>
                    <div className={'col-12'}>
                        <Reglement reglements={accident.reglements} accident={accident.id} contract={contract.id}/>
                    </div>
                </div>
                <div className={'row margin-top'}>
                    <div className={'col-12'}>
                        <Garanties garanties={accident.garanties} permissions={permissions} accident={accident.id}
                                   contract={contract.id} isWakam={isWakam}/>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Accident;
