import moment from "moment";

const Notes = ({ notes }) => {

    return (
        <>
            {notes && notes.length > 0 &&
                <div className={'row header-row'}>
                    <div className={'col'}>
                        <div className={'row'}>
                            {notes.map((note, index) =>
                                <div className="alert alert-info col-6 note" role="alert" key={index}>
                                    <p className={'small mb-2'}>Créée le {moment(note.created_at).format('L')}</p>
                                    <p>{note.titre}</p>
                                    <hr/>
                                    <p dangerouslySetInnerHTML={{__html: note.commentaire}}/>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default Notes;
