import React from 'react'
import moment from "moment";

const RappelInfo = (props) => {
    const {task: {date, type, paniere, tiers_id}} = props

    return (
        <div className={'container'}>
            <div className={'row'}>
                <div className={'col text-center'}>
                    <h2 className={'title-primary text-uppercase'}>Tâche {paniere} - Tiers n°{tiers_id}</h2>
                    <p className={'subtitle'}>Tâche du {moment(date).format('L')}. Recu par {type}</p>
                </div>
            </div>
            <div className={'header-row row'}>
                <div className={'col-12'}>
                    <div className={'alert alert-warning'}>
                        {(paniere === 'SEA_KO') && 'Le contrat d\'assurance du client est passé en "Résilié". Vous pouvez faire la pré-déclaration si le sinistre a eu lieu pendant la période d’assurance.'}
                        {(paniere === 'SEA_OK') && 'Le contrat d\'assurance du client est passé "En cours". Vous pouvez faire la pré-déclaration de sinistre.'}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RappelInfo
