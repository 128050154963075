import React, {Component, Fragment} from 'react';
import {Field} from "redux-form";
import MyButton from "../../../../Commun/Button/Button";
import Spinner from "../../../../Commun/Spinner/Spinner";
import Badge from "../../../../Commun/Badge/Badge";
import moment from "moment";
import DatePickerFieldRedux from "../../../../Commun/Input/DatePicker/DatePickerFieldRedux";


class RevContrat extends Component {
    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit(form) {
        if (form.date === undefined) {
            form.date = moment()
        }
        form.motif = this.props.contract.situation.motifCode
        this.props.onSubmit(form)
    }

    render() {
        const {handleSubmit, errorRev, contract, loading} = this.props

        return (
            <div className={'contract-container container'}>
                <Fragment>
                    <div className={'row return-content'}>
                        <div className={'col'}>
                            <button onClick={() => this.props.history.go(-1)} className={'btn btn-return'}>Retour
                            </button>
                        </div>
                    </div>
                    {contract && <div className={'row'}>
                        <div className={'col-12'}>
                            <h1 className={'title-primary'}>{contract.souscripteur.titre} {contract.souscripteur.nom} {contract.souscripteur.id}</h1>
                            <Badge status={contract.situation.statut}/>
                        </div>
                        <div className={'col-12'}>
                            <span
                                className={'bold text-uppercase subtitle'}>Contrat n°{contract.numeroPolice} - {contract.title}</span>
                        </div>
                    </div>}
                </Fragment>
                <div className="row header-row">
                    <div className={'col-12'}>
                        <form className={`form-content`} onSubmit={handleSubmit(this.handleSubmit)}>
                            <Field
                                name='date'
                                component={DatePickerFieldRedux}
                                label={'Date de la remise en vigueur'}
                                minDate={moment(contract.situation.dateAffaireNouvelle)}
                            />
                            {loading
                                ? <Spinner/>
                                : <MyButton
                                    variant='raised'
                                    color='secondary'
                                    size='large'
                                    className={'btn btn-primary'}
                                    disabled={loading}
                                >
                                    {loading && <Spinner absolute/>}
                                    Valider
                                </MyButton>
                            }
                            {errorRev && <h2 className={'title-primary text-uppercase'}>Une erreur est survenue</h2>}
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default RevContrat;
