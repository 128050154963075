import {createSelector} from 'reselect'

const casIdaSelector = state => state.cas_ida.data || {}

export const getRc = createSelector(
    casIdaSelector,
    cas_ida => cas_ida.rc
)

export const getIda = createSelector(
    casIdaSelector,
    cas_ida => cas_ida.ida
)
