import React from 'react';

const ImmatDoublon = (props) => {

    return (
        <div className={'container'}>
            <div className={'header-row row'}>
                <div className={'col-12'}>
                    <div className={'alert alert-warning'}>
                        <p>L'immatriculation de ce contrat est en doublon.</p>
                        <p className={'m-0'}>Merci de rechercher tous les contrats avec cette immatriculation et de
                            faire le nécessaire.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImmatDoublon;
