import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import ChangeFractionnement from "./ChangeFractionnement";
import {change, formValueSelector, reduxForm} from "redux-form";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {
    CHANGE_FRACTIONNEMENT,
    postChangeFractionnement
} from "../../../../../redux/actions/app/change_fractionnement/change_fractionnement.actions";
import {
    changeFractionnementLink,
} from "../../../../../redux/selectors/links/links.selectors";
import _ from "lodash";


const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector('change-fractionnement')

    return {
        prelevement: ownProps.location.state.prelevement,
        fractionnement: ownProps.location.state.fractionnement,
        contractId: ownProps.match.params.id,
        uri: changeFractionnementLink(state),
        loading: getLoadingEntity(state, CHANGE_FRACTIONNEMENT),
        ibanIsCheck: Boolean(selector(state, 'domiciliation') && selector(state, 'bic')),
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    postChangeFractionnement,
    changeValue: (field, value) => change('change-fractionnement', field, value)
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uri, contractId} = stateProps
    const {postChangeFractionnement} = dispatchProps


    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: form => postChangeFractionnement({query: _.replace(uri, '{id}', contractId), form})
    }
}


const ChangeFractionnementContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'change-fractionnement'
    })
)(ChangeFractionnement)


export default ChangeFractionnementContainer
