import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import {getErrorEntity, getLoadingEntity} from '../../../../../../redux/selectors/ui/ui.selectors'
import {apporteursGetLink} from '../../../../../../redux/selectors/links/links.selectors'
import ErrorHttpComponent from '../../../../../Commun/Error/ErrorHttpComponent'
import Apporteurs from "./Apporteurs";
import _ from "lodash";
import {APPORTEURS, getApporteurs} from "../../../../../../redux/actions/app/apporteurs/apporteurs.actions";
import {getApporteursData} from "../../../../../../redux/selectors/apporteurs/apporteurs.selector";
import loader from "../../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    return {
        apporteurs: getApporteursData(state),
        uri: apporteursGetLink(state),
        loaded: getLoadingEntity(state, APPORTEURS) === false,
        error: getErrorEntity(state, APPORTEURS)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getApporteurs: (query) => getApporteurs({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getApporteurs} = dispatchProps
    const {uri} = stateProps
    const {id} = ownProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getApporteurs(_.replace(uri, '{id}', id))
    }
}

const ApporteursContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(Apporteurs)

export default ApporteursContainer
