import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {
    POST_EXTRACTION_CV, POST_GENERATE_BORDEREAUX,
    POST_REVERSEMENT,
    REVERSEMENT,
    setReversement
} from "../../../actions/app/requetes/requetes.actions";


export const requetesMiddleware = () => next => action => {
    next(action)

    const {payload} = action
    let message = null

    switch (action.type) {
        case POST_REVERSEMENT:
            next([
                apiRequest({
                    body: (action.payload.body),
                    method: 'POST',
                    url: action.payload.data,
                    entity: REVERSEMENT
                }),
                setLoader({state: true, entity: REVERSEMENT})
            ])
            break

        case `${REVERSEMENT} ${API_SUCCESS}`:
            message = `<p>L'export est en cours de traitement, un mail va vous être envoyé une fois que la requête sera terminée.</p>`
            next([
                setReversement({state: true}),
                setNotification({entity: REVERSEMENT, body: '', type: 'success', title: message}),
                setLoader({state: false, entity: REVERSEMENT}),
                setError({state: false, entity: REVERSEMENT}),
            ])
            break

        case `${REVERSEMENT} ${API_ERROR}`:
            message = `
                <p>Votre demande n'a pas pu être envoyée. ${payload.data.response.data.message}</p>
            `
            next([
                setNotification({entity: REVERSEMENT, body: message, type: 'error', title: 'Erreur !', timeout: '0'}),
                setError({state: true, entity: REVERSEMENT}),
                setLoader({state: false, entity: REVERSEMENT}),
            ])
            break

        case POST_EXTRACTION_CV:
            next([
                apiRequest({body: null, method: 'POST', url: action.payload.data, entity: POST_EXTRACTION_CV}),
                setLoader({state: true, entity: POST_EXTRACTION_CV}),
            ])
            break

        case `${POST_EXTRACTION_CV} ${API_SUCCESS}`:
            message = `<p>L'export est en cours de traitement, un mail va vous être envoyé une fois que la requête sera terminée.</p>`
            next([
                setNotification({entity: REVERSEMENT, body: '', type: 'success', title: message}),
                setLoader({state: false, entity: POST_EXTRACTION_CV}),
                setError({state: false, entity: POST_EXTRACTION_CV}),
            ])
            break

        case `${POST_EXTRACTION_CV} ${API_ERROR}`:
            message = `
                <p>Votre demande n'a pas pu être envoyée. ${payload.data.response.data.message}</p>
            `
            next([
                setNotification({
                    entity: POST_EXTRACTION_CV,
                    body: message,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_EXTRACTION_CV}),
                setLoader({state: false, entity: POST_EXTRACTION_CV}),
            ])
            break

        case POST_GENERATE_BORDEREAUX:
            next([
                apiRequest({body: null, method: 'POST', url: action.payload.data, entity: POST_GENERATE_BORDEREAUX}),
                setLoader({state: true, entity: POST_GENERATE_BORDEREAUX}),
            ])
            break

        case `${POST_GENERATE_BORDEREAUX} ${API_SUCCESS}`:
            message = `La génération des bordereaux est en cours de traitement, un mail va vous être envoyé une fois que la requête sera terminée.`
            next([
                setNotification({entity: REVERSEMENT, body: '', type: 'success', title: message}),
                setLoader({state: false, entity: POST_GENERATE_BORDEREAUX}),
                setError({state: false, entity: POST_GENERATE_BORDEREAUX}),
            ])
            break

        case `${POST_GENERATE_BORDEREAUX} ${API_ERROR}`:
            message = `
                <p>Votre demande n'a pas pu être envoyée. ${payload.data.response.data.message}</p>
            `
            next([
                setNotification({
                    entity: POST_GENERATE_BORDEREAUX,
                    body: message,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_GENERATE_BORDEREAUX}),
                setLoader({state: false, entity: POST_GENERATE_BORDEREAUX}),
            ])
            break

        default:
            break
    }
    return null
}
