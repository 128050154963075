import {connect} from 'react-redux';
import DetailsReglement from "./DetailsReglement";
import {getBcaData} from "../../../../../../../redux/selectors/bca/bca.selectors";
import {
    getAccessoiresAntivol,
    getAccessoiresData,
    getAccessoiresReparation, getAccessoiresVehicule, getAccessoiresVehiculeMontant
} from "../../../../../../../redux/selectors/accessoires/accessoires.selectors";
import {
    getAccidentDocumentFacture,
    getAccidentFranchiseReglee,
    getAccidentOppositions,
    getAccidentData,
    getAccidentDocuments,
    getAccidentPenalites,
    getAccidentDocumentAntivolRecuTraite, getAccidentGarantiesValMaj,
} from "../../../../../../../redux/selectors/accident/accident.selectors";
import _ from "lodash";
import {formValueSelector} from "redux-form";
import {
    getContractTitulaire
} from "../../../../../../../redux/selectors/contract/contract.selectors";

const mapStateToProps = state => {
    const selector = formValueSelector('virement-sinistre')

    return {
        accident: getAccidentData(state),
        titulaire_banque: getContractTitulaire(state),
        beneficiaire: selector(state, 'beneficiaire'),
        documents: getAccidentDocuments(state),
        rapports_bca: getBcaData(state),
        accessoires: getAccessoiresData(state),
        franchise_reglee: getAccidentFranchiseReglee(state) ? parseFloat(_.replace(getAccidentFranchiseReglee(state), ',', '.')) : null,
        hasDocFactureReparation: !_.isEmpty(getAccidentDocumentFacture(state)),
        hasFactureReparation: !_.isEmpty(getAccessoiresReparation(state)),
        hasFactureVehicule: !_.isEmpty(getAccessoiresVehicule(state)),
        montantFactureVehicule: getAccessoiresVehiculeMontant(state),
        hasDocFactureAntivolRecuTraite: !_.isEmpty(getAccidentDocumentAntivolRecuTraite(state)),
        hasFactureAntivol: !_.isEmpty(getAccessoiresAntivol(state)),
        oppositions: getAccidentOppositions(state),
        penalites: getAccidentPenalites(state),
        penalite_ok: selector(state, 'penalite_ok'),
        remboursement_franchise: selector(state, 'remboursement_franchise'),
        montant_remboursement_franchise: selector(state, 'montant_remboursement_franchise'),
        blocageVerificationClientOk: selector(state, 'blocage_verification_client_ok'),
        hasValMaj: !_.isEmpty(getAccidentGarantiesValMaj(state))
    }
}

const DetailsReglementContainer = connect(mapStateToProps)(DetailsReglement)

export default DetailsReglementContainer
