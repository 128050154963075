export const DASHBOARD_CONSEILLER_TLV = '[Dashboard Conseiller Télévente]'
export const GET_DASHBOARD_CONSEILLER_TLV = `${DASHBOARD_CONSEILLER_TLV} Get`
export const SET_DASHBOARD_CONSEILLER_TLV = `${DASHBOARD_CONSEILLER_TLV} Set`

export const getDashboardConseillerTelevente = ({query, filters}) => ({
    type: GET_DASHBOARD_CONSEILLER_TLV,
    payload: {
        data: query,
        filters
    }
})

export const setDashboardConseillerTelevente = ({data}) => ({
    type: SET_DASHBOARD_CONSEILLER_TLV,
    payload: {
        data
    }
})