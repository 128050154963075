import {SET_INSURERS} from '../../actions/app/insurers/insurers.actions'

export const insurersReducer = (insurers = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_INSURERS) {
        return payload.data
    } else {
        return insurers
    }
}
