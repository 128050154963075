import {connect} from 'react-redux'
import FormAvenantInfosClient from "./FormAvenantInfosClient";
import {getContractCodeProduit} from "../../../../../../../redux/selectors/contract/contract.selectors";

const mapStateToProps = state => {
    return {
        isVAE: getContractCodeProduit(state) === "7311_VAE",
    }
}
const FormAvenantInfosClientContainer = connect(mapStateToProps)(FormAvenantInfosClient)


export default FormAvenantInfosClientContainer