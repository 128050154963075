import React from 'react'
import Check from "../../../../../Commun/Icons/IconCheck";

const Decompte = (props) => {
    const {decompte} = props

    return (
        <div className={'content row'}>
            <table className="table text-center table-reglement">
                <thead>
                <tr>
                    <th scope="col">&nbsp;</th>
                    <th scope="col">Type</th>
                    <th scope="col">Montant</th>
                    <th scope="col">Complément</th>
                    <th scope="col">Vetusté</th>
                    <th scope="col">Commentaire</th>
                </tr>
                </thead>
                <tbody>
                {decompte.indems.map((indem, index) => (
                    <tr>
                        <td className={'text-left'}>{indem.libelle}</td>
                        <td>{indem.type}</td>
                        <td>{indem.montant + '€' || ''}</td>
                        <td>{indem.complement + '€' || ''}</td>
                        <td><Check isCheck={indem.vetuste}/></td>
                        <td>{indem.commentaire}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )
}

export default Decompte
