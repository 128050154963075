import {RESET_FORM} from '../../../actions/core/reduxForm/reduxForm.actions'
import {reset} from 'redux-form'

export const reduxFormMiddleware = () => next => action => {
    next(action)

    if (action.type.includes(RESET_FORM)) {
        next(reset(action.payload.data))
    }
}
