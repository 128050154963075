import {SET_ANTECEDENT_PERIOD} from "../../actions/app/antecedents/antecedents.actions";

export const antecedentPeriodReducer = (antecedent = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_ANTECEDENT_PERIOD) {
        return payload
    } else {
        return antecedent
    }
}
