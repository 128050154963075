export const REVERSEMENT = '[Reversement]'
export const BORDEREAUX = '[Bordereaux]'

export const POST_REVERSEMENT = `${REVERSEMENT} Post`
export const SET_REVERSEMENT = `${REVERSEMENT} Set`

export const POST_EXTRACTION_CV = `${REVERSEMENT} Post`
export const POST_GENERATE_BORDEREAUX = `${BORDEREAUX} Post`

export const postReversement = ({form, query}) => ({
    type: POST_REVERSEMENT,
    payload: {
        data: query,
        body: form
    }
})

export const setReversement = ({state}) => ({
    type: SET_REVERSEMENT,
    payload: state
})

export const postExtractionCartesVertes = ({query}) => ({
    type: POST_EXTRACTION_CV,
    payload: {
        data: query
    }
})

export const postGenerateBordereaux = ({query}) => ({
    type: POST_GENERATE_BORDEREAUX,
    payload: {
        data: query
    }
})
