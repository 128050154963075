import React from 'react'
import {Link} from "react-router-dom";
import _ from "lodash";
import CorrespondantItemContainer from "./CorrespondantItem/CorrespondantItemContainer";

const Correspondants = (props) => {
    const {correspondants, id, permissions} = props

    return (
        correspondants.length >= 1 &&
        <div className={'bloc-info bloc-activity'}>
            <h2>Correspondants</h2>
            <div className={'content row'}>
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">Titre</th>
                        <th scope="col">Nom</th>
                        <th scope="col">Prénom</th>
                        <th scope="col">Rôle</th>
                        <th scope="col">Téléphone</th>
                        <th scope="col">Email</th>
                        <th scope="col">Principal</th>
                        {
                            _.includes(permissions, 'edit correspondent') &&
                            <>
                                <th scope="col"></th>
                                <th scope="col"></th>
                            </>
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {correspondants.map((c, index) =>
                        <CorrespondantItemContainer key={index} {...props} correspondant={c} permissions={permissions}/>
                    )}
                    </tbody>
                </table>
                {
                    _.includes(permissions, 'edit correspondent') &&
                    <div className={'col-12'}>
                        <Link to={`/tiers/${id}/correspondant`} className={'no-background btn'}>
                            + Ajouter un correspondant
                        </Link>
                    </div>
                }
            </div>
        </div>
    )
}

export default Correspondants
