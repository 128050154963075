import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import DetailsReglement from "./DetailsReglement";
import {postRegulationDetailAccidentGetLink} from "../../../../../../redux/selectors/links/links.selectors";
import {getErrorEntity, getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {
    getReglementDetail,
    REGLEMENT_DETAIL
} from "../../../../../../redux/actions/app/reglement_detail/reglement_detail.actions";
import _ from "lodash";
import ErrorHttpComponent from "../../../../../Commun/Error/ErrorHttpComponent";
import {getReglementDetailData} from "../../../../../../redux/selectors/reglement_detail/reglement_detail.selectors";
import loader from "../../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    return {
        uri: postRegulationDetailAccidentGetLink(state),
        accidentId: ownProps.match.params.ids,
        contractId: ownProps.match.params.id,
        loaded: getLoadingEntity(state, REGLEMENT_DETAIL) === false,
        error: getErrorEntity(state, REGLEMENT_DETAIL),
        reglementDetail: getReglementDetailData(state)
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    getReglementDetail: (query) => getReglementDetail({query})
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uri, contractId, accidentId} = stateProps
    const {getReglementDetail} = dispatchProps


    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => {
            getReglementDetail(_.replace(_.replace(uri, '{id}', contractId), '{ids}', accidentId))
        }
    }
}


const DetailsReglementContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(DetailsReglement)


export default DetailsReglementContainer
