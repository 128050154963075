import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {reduxForm, change, formValueSelector, clearFields} from 'redux-form'
import {ADD_ACTIVITY, postAddActivity} from '../../../../../redux/actions/app/activity/activity.actions'
import AddActivity from './AddActivity'
import {getErrorEntity, getLoadingEntity} from '../../../../../redux/selectors/ui/ui.selectors'
import validate from "./validate";
import moment from "moment";
import {getBelairName, getUserRoles} from "../../../../../redux/selectors/user/user.selectors";
import _ from "lodash";
import {contractDefaultGetLink, postActivityLink} from "../../../../../redux/selectors/links/links.selectors";
import {getContractData} from "../../../../../redux/selectors/contract/contract.selectors";
import {CONTRACT, getContract} from "../../../../../redux/actions/app/contracts/contracts.actions";
import ErrorHttpComponent from "../../../../Commun/Error/ErrorHttpComponent";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector(`addActivityForm`)
    const contract = getContractData(state)
    const loaded = getLoadingEntity(state, CONTRACT) === false
    const type = selector(state, 'type');

    return {
        loaded: loaded,
        error: getErrorEntity(state, CONTRACT) === false,
        loading: getLoadingEntity(state, ADD_ACTIVITY),
        url: _.replace(postActivityLink(state), '{id}', ownProps.match.params.id),
        idContract: ownProps.match.params.id,
        uri_contract: contractDefaultGetLink(state),
        title: contract.souscripteur ? `${contract.souscripteur.titre} ${contract.souscripteur.nom} ${contract.souscripteur.id}` : '',
        subtitle: loaded ? `Contrat n°${contract.numeroPolice} - ${contract.title}` : '',
        situation: loaded ? contract.situation.statutCode : '',
        categorie: selector(state, 'categorie'),
        type: type,
        paniere: selector(state, 'paniere'),
        isAuthorizedToCreate: !_.includes(['FIDE', 'FIDS'], type) || selector(state, 'attenteClient') === 'T' || selector(state, 'clientInjoignable') === 'T' || selector(state, 'clientNonEligible') === 'T' || selector(state, 'gesteFIDRefuser') === 'T' || selector(state, 'carteCarburant') === 'T' || selector(state, 'avRegul') === 'T' || selector(state, 'fraisModif') === 'T' || selector(state, 'revueContrat') === 'T',
        initialValues: {
            'date_rappel': moment(),
        },
        nameConseiller: getBelairName(state),
        roles: getUserRoles(state),
        activityType: 'contract'
    }
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
    getContract: (query) => getContract({query}),
    submitPostAddActivity: ({form, query}) => postAddActivity({form, query}),
    onChangeValue: (name, file) => change('addActivityForm', name, file),
    unsetValue: (field) => clearFields('addActivityForm', false, false, field),
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {submitPostAddActivity, getContract} = dispatchProps
    const {url, uri_contract, idContract} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => {
            getContract(_.replace(uri_contract, '{id}', idContract))
        },
        onSubmit: (form) => submitPostAddActivity({form, query: url})
    }
}
const AddActivityContractContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'addActivityForm',
        validate
    }),
    loader({ErrorIndicator: ErrorHttpComponent})
)(AddActivity)

export default AddActivityContractContainer
