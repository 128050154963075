import {
    ACCIDENT,
    DOCUMENT_NEEDED,
    GET_ACCIDENT,
    GET_DOCUMENT_NEEDED,
    POST_ACCIDENT,
    POST_COMMENT_ACCIDENT,
    POST_EMAIL_COMPANY_ACCIDENT,
    POST_REJECT_ACCIDENT,
    POST_REPAIRER_ACCIDENT,
    POST_STATUS_ACCIDENT,
    POST_ATTENTE_PV_ACCIDENT,
    setAccident,
    POST_GARANTIE_ACCIDENT,
    POST_UPDATE_ACCIDENT,
    POST_DEFINE_AS_COVERED,
    POST_VEHICLE_TRANSFER,
    POST_UPDATE_RESP_ACCIDENT,
    POST_COMPAGNIE_ADVERSE_ACCIDENT,
    POST_UPDATE_CONDUCTEUR_ACCIDENT,
    POST_ACCIDENT_NON_DEC,
    POST_DELETE_EXPERT_ACCIDENT,
    POST_ADD_EXPERT_ACCIDENT,
    POST_DELETE_REPAIRER_ACCIDENT,
    POST_CHANGE_DATE_EXPERT_VISIT,
    POST_REFUND_REPAIRER_ACCIDENT,
    POST_ADD_PENALTIES,
    POST_DELETE_PENALTIES,
    POST_ADD_OPPOSITIONS,
    POST_DELETE_OPPOSITIONS,
    POST_PROCESSING_PV,
    POST_UPDATE_ACCIDENT_BY_TASK,
     POST_ADD_INTERVENANT, POST_DECES_CIE_ACCIDENT
} from '../../../actions/app/accidents/accidents.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {
    setAccidentSuccess,
    setDocumentsNeededForAccident,
    setError,
    setLoader, setValidatePostAccident
} from '../../../actions/app/ui/ui.actions'
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import moment from "moment";
import {logAction} from "../../../actions/core/logs/logs.actions";
import _ from "lodash";

import {reset} from "redux-form";
import {CLOSE_TASK} from "../../../actions/app/tasks/tasks.actions";

export const accidentMiddleware = () => next => action => {
    next(action)

    let body;
    let message = null
    let clone;
    let nextAction = []
    let newValues;

    switch (action.type) {
        case GET_ACCIDENT:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: ACCIDENT}),
                setLoader({state: true, entity: ACCIDENT}),
                logAction({type: 'SHOW_ACCIDENT', entity: ACCIDENT, sinistre: action.payload.otherData.id})
            ])
            break

        case `${ACCIDENT} ${API_SUCCESS}`:
            next([
                setAccident({data: action.payload.data}),
                setLoader({state: false, entity: ACCIDENT}),
                setError({state: false, entity: ACCIDENT})
            ])
            break

        case `${ACCIDENT} ${API_ERROR}`:
            next([
                setError({state: true, entity: ACCIDENT}),
                setLoader({state: false, entity: ACCIDENT})
            ])
            break

        case POST_ACCIDENT:

            let data = new FormData()
            newValues = action.payload.body.form

            /* Format form before POST */
            /* datesurv veux une date en / datesurvorigin est un objet moment */
            newValues.datesurv = moment(newValues.datesurvorigin).format('L')
            /* catsinorigin est slice parce que le select de la categorie est très précise */
            const arrayCategory = (newValues.catsinorigin).split('.')
            newValues.catsin = arrayCategory[0]
            newValues.catsinsubcategory = arrayCategory[1]

            if(newValues.birthdate_conduct){
                newValues.birthdate_conduct = moment(newValues.birthdate_conduct).format('L')
            }

            /* Gestion si un autre garage a été saisie */
            if (newValues.reparateur) {
                if (newValues.reparateur === 'NONE') {
                    newValues.reparateur_autre = `${newValues.reparateurAutreNom} ${newValues.reparateurAutreAdresse}, ${newValues.cpreparateur} ${newValues.reparateurAutreVille}`
                    delete newValues.reparateur
                }
            }

            /* Envoie de la liste des documents pour le mail */
            action.payload.body.documents.forEach((doc, index) => {
                data.append(`documents[${index}]`, doc.description)
            })


            /* Mise à plat des files, adversaires, témoins, victimes */
            Object.keys(newValues).forEach(key => {
                if (key === 'files') {
                    Object.keys(newValues[key]).forEach(doc => {
                        if (newValues[key][doc] !== null && newValues[key][doc].name !== undefined) {
                            data.append(`files[${doc}]`, newValues[key][doc])
                        }
                    })
                } else if (key === 'adversaires' || key === 'temoins' || key === 'victimes') {
                    newValues[key].forEach((person, index) => {
                        Object.keys(person).forEach(info => {
                            data.append(`${key}[${index}][${info}]`, person[info])
                        })
                    })
                } else if (key === 'garanties') {
                    newValues[key].forEach((garantie, index) => {
                        Object.keys(garantie).forEach(info => {
                            data.append(`${key}[${index}][${info}]`, garantie[info])
                        })
                    })
                } else if (key === 'circonstances') {
                    Object.keys(newValues[key]).forEach(circ => {
                        data.append(`${key}[${circ}]`, true)
                    })
                } else {
                    data.append(key, newValues[key])
                }
                return true
            })

            /* Ajout categorie */

            switch (newValues.catsinorigin) {
                case '10.0':
                case '105.1':
                case '9.0':
                case '105.2':
                    data.append('categorie', 'vol')
                    break;
                default:
                    data.append('categorie', 'autre')
            }

            /*new Response(data).text().then(console.log)*/


            next([
                apiRequest({body: data, method: 'POST', url: action.payload.data, entity: POST_ACCIDENT}),
                setLoader({state: true, entity: POST_ACCIDENT})
            ])
            break

        case `${POST_ACCIDENT} ${API_SUCCESS}`:
            body = `<p>La pré-déclaration du sinistre à bien été prise en compte.</p>`

            next([
                setNotification({entity: POST_ACCIDENT, body, type: 'success', title: 'Sinistre pré-déclaré'}),
                setAccidentSuccess({idAccident: action.payload.data.id, idContract: action.payload.data.id_contrat}),
                setValidatePostAccident({state: true}),
                setLoader({state: false, entity: POST_ACCIDENT}),
                setError({state: false, entity: POST_ACCIDENT})
            ])

            break

        case `${POST_ACCIDENT} ${API_ERROR}`:
            body = `<p>Impossible de pré-déclarer le sinistre !</p>
                    <p>${action.payload.data.response.data?.message ?? ''}</p>`

            next([
                setNotification({entity: POST_ACCIDENT, body, type: 'error', title: 'Attention !', timeout: '0'}),
                setError({state: true, entity: POST_ACCIDENT}),
                setLoader({state: false, entity: POST_ACCIDENT})
            ])
            break

        case POST_REJECT_ACCIDENT:
            next([
                apiRequest({
                    body: action.payload.body,
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_REJECT_ACCIDENT
                }),
                setLoader({state: true, entity: POST_REJECT_ACCIDENT})
            ])
            break

        case `${POST_REJECT_ACCIDENT} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_REJECT_ACCIDENT,
                    body: `<p>Une notification du refus vient d'être envoyée au client par email.</p>`,
                    type: 'error',
                    title: 'Attention !',
                    timeout: '0'
                }),
                setError({state: false, entity: POST_REJECT_ACCIDENT}),
                setLoader({state: false, entity: POST_REJECT_ACCIDENT})
            ])
            break

        case `${POST_REJECT_ACCIDENT} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_REJECT_ACCIDENT,
                    body: `
                        <p>Une Erreur est survenue lors de l'envoi de la notification.</p>
                        <p>${action.payload.data.response.data?.message ?? ''}</p>
                    `,
                    type: 'error',
                    title: 'Attention !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_REJECT_ACCIDENT}),
                setLoader({state: false, entity: POST_REJECT_ACCIDENT})
            ])
            break

        case GET_DOCUMENT_NEEDED:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: DOCUMENT_NEEDED}),
                setLoader({state: true, entity: DOCUMENT_NEEDED})
            ])
            break;
        case `${DOCUMENT_NEEDED} ${API_SUCCESS}`:
            next([
                setDocumentsNeededForAccident({documents: action.payload.data}),
                setLoader({state: false, entity: DOCUMENT_NEEDED}),
                setError({state: false, entity: DOCUMENT_NEEDED})
            ])
            break;
        case `${DOCUMENT_NEEDED} ${API_ERROR}`:
            next([
                setLoader({state: false, entity: DOCUMENT_NEEDED}),
                setError({state: true, entity: DOCUMENT_NEEDED})
            ])
            break;

        case POST_COMMENT_ACCIDENT:
            next([
                apiRequest({
                    body: action.payload.body,
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_COMMENT_ACCIDENT
                }),
                setLoader({state: true, entity: POST_COMMENT_ACCIDENT})
            ])
            break

        case `${POST_COMMENT_ACCIDENT} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_COMMENT_ACCIDENT,
                    body: 'Le commentaire a été mis à jour.',
                    type: 'success',
                    title: 'Modification'
                }),
                setError({state: false, entity: POST_COMMENT_ACCIDENT}),
                setLoader({state: false, entity: POST_COMMENT_ACCIDENT})
            ])
            break

        case `${POST_COMMENT_ACCIDENT} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_COMMENT_ACCIDENT,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_COMMENT_ACCIDENT}),
                setLoader({state: false, entity: POST_COMMENT_ACCIDENT})
            ])
            break

        case POST_REPAIRER_ACCIDENT:
            next([
                apiRequest({
                    body: action.payload.body,
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_REPAIRER_ACCIDENT
                }),
                setLoader({state: true, entity: POST_REPAIRER_ACCIDENT})
            ])
            break

        case `${POST_REPAIRER_ACCIDENT} ${API_SUCCESS}`:
            nextAction = [
                setError({state: false, entity: POST_REPAIRER_ACCIDENT}),
                setLoader({state: false, entity: POST_REPAIRER_ACCIDENT}),
                setNotification({
                    entity: POST_REPAIRER_ACCIDENT,
                    body: 'Le réparateur a été mis à jour.',
                    type: 'success',
                    title: 'Modification'
                })
            ]

            if (action.payload.data.data && action.payload.data.data.message !== "") {
                nextAction.push(setNotification({
                    entity: POST_REPAIRER_ACCIDENT,
                    body: `<p>${action.payload.data.data.message}</p>`,
                    type: 'error',
                    title: 'Attention !',
                    timeout: '0'
                }))
            }

            next(nextAction)
            break

        case `${POST_REPAIRER_ACCIDENT} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_REPAIRER_ACCIDENT,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_REPAIRER_ACCIDENT}),
                setLoader({state: false, entity: POST_REPAIRER_ACCIDENT})
            ])
            break

        case POST_EMAIL_COMPANY_ACCIDENT:
            next([
                apiRequest({
                    body: action.payload.body,
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_EMAIL_COMPANY_ACCIDENT
                }),
                setLoader({state: true, entity: POST_EMAIL_COMPANY_ACCIDENT})
            ])
            break

        case `${POST_EMAIL_COMPANY_ACCIDENT} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_EMAIL_COMPANY_ACCIDENT,
                    body: 'L\'email compagnie a été mis à jour.',
                    type: 'success',
                    title: 'Modification'
                }),
                setError({state: false, entity: POST_EMAIL_COMPANY_ACCIDENT}),
                setLoader({state: false, entity: POST_EMAIL_COMPANY_ACCIDENT})
            ])
            break

        case `${POST_EMAIL_COMPANY_ACCIDENT} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_EMAIL_COMPANY_ACCIDENT,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_EMAIL_COMPANY_ACCIDENT}),
                setLoader({state: false, entity: POST_EMAIL_COMPANY_ACCIDENT})
            ])
            break

        case POST_ATTENTE_PV_ACCIDENT:
            next([
                apiRequest({
                    body: action.payload.body,
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_ATTENTE_PV_ACCIDENT
                }),
                setLoader({state: true, entity: POST_ATTENTE_PV_ACCIDENT})
            ])
            break

        case `${POST_ATTENTE_PV_ACCIDENT} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_ATTENTE_PV_ACCIDENT,
                    body: 'Le champ a été mis à jour.',
                    type: 'success',
                    title: 'Modification'
                }),
                setError({state: false, entity: POST_ATTENTE_PV_ACCIDENT}),
                setLoader({state: false, entity: POST_ATTENTE_PV_ACCIDENT})
            ])
            break

        case `${POST_ATTENTE_PV_ACCIDENT} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_ATTENTE_PV_ACCIDENT,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_ATTENTE_PV_ACCIDENT}),
                setLoader({state: false, entity: POST_ATTENTE_PV_ACCIDENT})
            ])
        break;

        case POST_DECES_CIE_ACCIDENT:
            next([
                apiRequest({
                    body: action.payload.body,
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_DECES_CIE_ACCIDENT
                }),
                setLoader({state: true, entity: POST_DECES_CIE_ACCIDENT})
            ])
            break

        case `${POST_DECES_CIE_ACCIDENT} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_DECES_CIE_ACCIDENT,
                    body: 'La déclaration de décès du conducteur a bien été prise en compte',
                    type: 'success',
                    title: 'Déclaration de décès du conducteur'
                }),
                setLoader({state: false, entity: POST_DECES_CIE_ACCIDENT}),
                setError({state: false, entity: POST_DECES_CIE_ACCIDENT})
            ])
            break

        case `${POST_DECES_CIE_ACCIDENT} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_DECES_CIE_ACCIDENT,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur lors de la déclaration de décès du conducteur',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_DECES_CIE_ACCIDENT}),
                setLoader({state: false, entity: POST_DECES_CIE_ACCIDENT})
            ])
            break

        case POST_STATUS_ACCIDENT:
            next([
                apiRequest({
                    body: action.payload.body,
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_STATUS_ACCIDENT
                }),
                setLoader({state: true, entity: POST_STATUS_ACCIDENT})
            ])
            break

        case `${POST_STATUS_ACCIDENT} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_STATUS_ACCIDENT,
                    body: 'Le statut du sinistre a été mis à jour.',
                    type: 'success',
                    title: 'Modification'
                }),
                setError({state: false, entity: POST_STATUS_ACCIDENT}),
                setLoader({state: false, entity: POST_STATUS_ACCIDENT})
            ])
            break

        case `${POST_STATUS_ACCIDENT} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_STATUS_ACCIDENT,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_STATUS_ACCIDENT}),
                setLoader({state: false, entity: POST_STATUS_ACCIDENT})
            ])
            break

        case POST_GARANTIE_ACCIDENT:
            next([
                apiRequest({
                    body: action.payload.body,
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_GARANTIE_ACCIDENT,
                    otherData: action.payload.formName
                }),
                setLoader({state: true, entity: POST_GARANTIE_ACCIDENT})
            ])
            break

        case `${POST_GARANTIE_ACCIDENT} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_GARANTIE_ACCIDENT,
                    body: 'La garantie a été mise à jour.',
                    type: 'success',
                    title: 'Modification'
                }),
                setError({state: false, entity: POST_GARANTIE_ACCIDENT}),
                setLoader({state: false, entity: POST_GARANTIE_ACCIDENT})
            ])
            break

        case `${POST_GARANTIE_ACCIDENT} ${API_ERROR}`:
            message = `
                <p>Erreur lors de la modification de la garantie, les modifications ne seront pas prises en compte.</p>
                <p>${action.payload.data.response.data?.message ?? ''}</p>
            `
            next([
                setNotification({
                    entity: POST_GARANTIE_ACCIDENT,
                    body: message,
                    title: 'Attention !',
                    type: 'error',
                    timeout: '0'
                }),
                reset(action.payload.meta.otherData),
                setError({state: true, entity: POST_GARANTIE_ACCIDENT}),
                setLoader({state: false, entity: POST_GARANTIE_ACCIDENT})
            ])
            break

        case POST_UPDATE_ACCIDENT:
            clone = _.cloneDeep(action.payload.body)

            if (clone.dateSurv) {
                clone.dateSurv = clone.dateSurv.format('YYYY-MM-DD')
            }

            next([
                apiRequest({
                    body: (clone),
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_UPDATE_ACCIDENT
                }),
                setLoader({state: true, entity: POST_UPDATE_ACCIDENT})
            ])
            break

        case `${POST_UPDATE_ACCIDENT} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_UPDATE_ACCIDENT,
                    body: 'Le sinistre a été mis à jour.',
                    type: 'success',
                    title: 'Modification'
                }),
                setError({state: false, entity: POST_UPDATE_ACCIDENT}),
                setLoader({state: false, entity: POST_UPDATE_ACCIDENT})
            ])
            break

        case `${POST_UPDATE_ACCIDENT} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_UPDATE_ACCIDENT,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_UPDATE_ACCIDENT}),
                setLoader({state: false, entity: POST_UPDATE_ACCIDENT})
            ])
            break

        case POST_DEFINE_AS_COVERED:
            next([
                apiRequest({body: null, method: 'POST', url: action.payload.data, entity: POST_DEFINE_AS_COVERED}),
                setLoader({state: true, entity: POST_DEFINE_AS_COVERED}),
            ])
            break

        case `${POST_DEFINE_AS_COVERED} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_DEFINE_AS_COVERED,
                    body: 'L\'email de prise en charge a été envoyé avec succès.',
                    type: 'success',
                    title: 'Modification'
                }),
                setLoader({state: false, entity: POST_DEFINE_AS_COVERED}),
                setError({state: false, entity: POST_DEFINE_AS_COVERED})
            ])
            break

        case `${POST_DEFINE_AS_COVERED} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_DEFINE_AS_COVERED,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_DEFINE_AS_COVERED}),
                setLoader({state: false, entity: POST_DEFINE_AS_COVERED})
            ])
            break

        case POST_VEHICLE_TRANSFER:
            next([
                apiRequest({body: null, method: 'POST', url: action.payload.data, entity: POST_VEHICLE_TRANSFER}),
                setLoader({state: true, entity: POST_VEHICLE_TRANSFER}),
            ])
            break

        case `${POST_VEHICLE_TRANSFER} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_VEHICLE_TRANSFER,
                    body: 'La création de l\'agenda de cession a été effectuée avec succès.',
                    type: 'success',
                    title: 'Cession compagnie'
                }),
                setLoader({state: false, entity: POST_VEHICLE_TRANSFER}),
                setError({state: false, entity: POST_VEHICLE_TRANSFER})
            ])
            break

        case `${POST_VEHICLE_TRANSFER} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_VEHICLE_TRANSFER,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_VEHICLE_TRANSFER}),
                setLoader({state: false, entity: POST_VEHICLE_TRANSFER})
            ])
            break

        case POST_UPDATE_RESP_ACCIDENT:
            next([
                apiRequest({
                    body: action.payload.body,
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_UPDATE_RESP_ACCIDENT
                }),
                setLoader({state: true, entity: POST_UPDATE_RESP_ACCIDENT})
            ])
            break

        case `${POST_UPDATE_RESP_ACCIDENT} ${API_SUCCESS}`:
            let nextac = [
                setError({state: false, entity: POST_UPDATE_RESP_ACCIDENT}),
                setLoader({state: false, entity: POST_UPDATE_RESP_ACCIDENT}),
                setNotification({
                    entity: POST_UPDATE_RESP_ACCIDENT,
                    body: 'Le taux de responsabilité a été mis à jour.',
                    type: 'success',
                    title: 'Modification'
                })
            ]

            if (action.payload.data.data && action.payload.data.data.message !== "") {
                nextac.push(setNotification({
                    entity: POST_UPDATE_RESP_ACCIDENT,
                    body: `<p>${action.payload.data.data.message}</p>`,
                    type: 'error',
                    title: 'Attention !',
                    timeout: '0'
                }))
            }

            next(nextac)
            break

        case `${POST_UPDATE_RESP_ACCIDENT} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_UPDATE_RESP_ACCIDENT,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_UPDATE_RESP_ACCIDENT}),
                setLoader({state: false, entity: POST_UPDATE_RESP_ACCIDENT})
            ])
            break

        case POST_COMPAGNIE_ADVERSE_ACCIDENT:
            next([
                apiRequest({
                    body: action.payload.body,
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_COMPAGNIE_ADVERSE_ACCIDENT
                }),
                setLoader({state: true, entity: POST_COMPAGNIE_ADVERSE_ACCIDENT})
            ])
            break

        case `${POST_COMPAGNIE_ADVERSE_ACCIDENT} ${API_SUCCESS}`:
            let nextA = [
                setError({state: false, entity: POST_COMPAGNIE_ADVERSE_ACCIDENT}),
                setLoader({state: false, entity: POST_COMPAGNIE_ADVERSE_ACCIDENT}),
                setNotification({
                    entity: POST_COMPAGNIE_ADVERSE_ACCIDENT,
                    body: 'La compagnie adverse a été mise à jour.',
                    type: 'success',
                    title: 'Changement de compagnie adverse'
                })
            ]

            if (action.payload.data.data && action.payload.data.data.message !== "") {
                nextA.push(setNotification({
                    entity: POST_COMPAGNIE_ADVERSE_ACCIDENT,
                    body: `<p>${action.payload.data.data.message}</p>`,
                    type: 'error',
                    title: 'Attention !',
                    timeout: '0'
                }))
            }

            next(nextA)
            break

        case `${POST_COMPAGNIE_ADVERSE_ACCIDENT} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_COMPAGNIE_ADVERSE_ACCIDENT,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_COMPAGNIE_ADVERSE_ACCIDENT}),
                setLoader({state: false, entity: POST_COMPAGNIE_ADVERSE_ACCIDENT})
            ])
            break

        case POST_UPDATE_CONDUCTEUR_ACCIDENT:
            next([
                apiRequest({
                    body: action.payload.body,
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_UPDATE_CONDUCTEUR_ACCIDENT
                }),
                setLoader({state: true, entity: POST_UPDATE_CONDUCTEUR_ACCIDENT})
            ])
            break

        case `${POST_UPDATE_CONDUCTEUR_ACCIDENT} ${API_SUCCESS}`:
            nextAction = [
                setError({state: false, entity: POST_UPDATE_CONDUCTEUR_ACCIDENT}),
                setLoader({state: false, entity: POST_UPDATE_CONDUCTEUR_ACCIDENT}),
                setNotification({
                    entity: POST_UPDATE_CONDUCTEUR_ACCIDENT,
                    body: 'Le conducteur a été mis à jour.',
                    type: 'success',
                    title: 'Modification'
                })
            ]

            if (action.payload.data.data && action.payload.data.data.message !== "") {
                nextAction.push(setNotification({
                    entity: POST_UPDATE_CONDUCTEUR_ACCIDENT,
                    body: `<p>${action.payload.data.data.message}</p>`,
                    type: 'error',
                    title: 'Attention !',
                    timeout: '0'
                }))
            }

            next(nextAction)
            break

        case `${POST_UPDATE_CONDUCTEUR_ACCIDENT} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_UPDATE_CONDUCTEUR_ACCIDENT,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_UPDATE_CONDUCTEUR_ACCIDENT}),
                setLoader({state: false, entity: POST_UPDATE_CONDUCTEUR_ACCIDENT})
            ])
            break

        case POST_ACCIDENT_NON_DEC:
            clone = _.cloneDeep(action.payload.body)

            if (clone.dateSurv) {
                clone.dateSurv = clone.dateSurv.format('YYYY-MM-DD')
            }

            next([
                apiRequest({
                    body: (clone),
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_ACCIDENT_NON_DEC
                }),
                setLoader({state: true, entity: POST_ACCIDENT_NON_DEC})
            ])
            break

        case `${POST_ACCIDENT_NON_DEC} ${API_SUCCESS}`:
            next([
                setValidatePostAccident({state: true}),
                setNotification({
                    entity: POST_ACCIDENT_NON_DEC,
                    body: 'Votre sinistre non-déclaré a bien été enregistré. Le courrier de DEC non-déclarée va partir automatiquement au client.',
                    type: 'success',
                    title: 'Sinistre non-déclaré enregistré'
                }),
                setError({state: false, entity: POST_ACCIDENT_NON_DEC}),
                setLoader({state: false, entity: POST_ACCIDENT_NON_DEC})
            ])
            break

        case `${POST_ACCIDENT_NON_DEC} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_ACCIDENT_NON_DEC,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_ACCIDENT_NON_DEC}),
                setLoader({state: false, entity: POST_ACCIDENT_NON_DEC})
            ])
            break

        case POST_ADD_EXPERT_ACCIDENT:
            next([
                apiRequest({body: null, method: 'POST', url: action.payload.data, entity: POST_ADD_EXPERT_ACCIDENT}),
                setLoader({state: true, entity: POST_ADD_EXPERT_ACCIDENT})
            ])
            break

        case `${POST_ADD_EXPERT_ACCIDENT} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_ADD_EXPERT_ACCIDENT,
                    body: 'L\'expert a été ajouté avec succès.',
                    type: 'success',
                    title: 'Ajout effectué'
                }),
                setError({state: false, entity: POST_ADD_EXPERT_ACCIDENT}),
                setLoader({state: false, entity: POST_ADD_EXPERT_ACCIDENT})
            ])
            break

        case `${POST_ADD_EXPERT_ACCIDENT} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_ADD_EXPERT_ACCIDENT,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_ADD_EXPERT_ACCIDENT}),
                setLoader({state: false, entity: POST_ADD_EXPERT_ACCIDENT})
            ])
            break

        case POST_ADD_INTERVENANT:
            next([
                apiRequest({body: action.payload.body, method: 'POST', url: action.payload.data, entity: POST_ADD_INTERVENANT}),
                setLoader({state: true, entity: POST_ADD_INTERVENANT})
            ])
            break

        case `${POST_ADD_INTERVENANT} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_ADD_INTERVENANT,
                    body: 'L\'intervenant a bien été ajouté.',
                    type: 'success',
                    title: 'Ajout effectué'
                }),
                setError({state: false, entity: POST_ADD_INTERVENANT}),
                setLoader({state: false, entity: POST_ADD_INTERVENANT})
            ])
            break

        case `${POST_ADD_INTERVENANT} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_ADD_INTERVENANT,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_ADD_INTERVENANT}),
                setLoader({state: false, entity: POST_ADD_INTERVENANT})
            ])
            break

        case POST_DELETE_EXPERT_ACCIDENT:
            next([
                apiRequest({body: null, method: 'POST', url: action.payload.data, entity: POST_DELETE_EXPERT_ACCIDENT}),
                setLoader({state: true, entity: POST_DELETE_EXPERT_ACCIDENT}),
            ])
            break

        case `${POST_DELETE_EXPERT_ACCIDENT} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_DELETE_EXPERT_ACCIDENT,
                    body: 'L\'expert a été supprimé avec succès.',
                    type: 'success',
                    title: 'Suppression effectuée'
                }),
                setLoader({state: false, entity: POST_DELETE_EXPERT_ACCIDENT}),
                setError({state: false, entity: POST_DELETE_EXPERT_ACCIDENT})
            ])
            break

        case `${POST_DELETE_EXPERT_ACCIDENT} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_DELETE_EXPERT_ACCIDENT,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_DELETE_EXPERT_ACCIDENT}),
                setLoader({state: false, entity: POST_DELETE_EXPERT_ACCIDENT})
            ])
            break

        case POST_DELETE_REPAIRER_ACCIDENT:
            next([
                apiRequest({
                    body: action.payload.body,
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_DELETE_REPAIRER_ACCIDENT
                }),
                setLoader({state: true, entity: POST_DELETE_REPAIRER_ACCIDENT})
            ])
            break

        case `${POST_DELETE_REPAIRER_ACCIDENT} ${API_SUCCESS}`:
            nextAction = [
                setError({state: false, entity: POST_DELETE_REPAIRER_ACCIDENT}),
                setLoader({state: false, entity: POST_DELETE_REPAIRER_ACCIDENT}),
                setNotification({
                    entity: POST_DELETE_REPAIRER_ACCIDENT,
                    body: 'Le réparateur a été supprimé.',
                    type: 'success',
                    title: 'Suppression'
                })
            ]

            if (action.payload.data.data && action.payload.data.data.message !== "") {
                nextAction.push(setNotification({
                    entity: POST_DELETE_REPAIRER_ACCIDENT,
                    body: `<p>${action.payload.data.data.message}</p>`,
                    type: 'error',
                    title: 'Attention !',
                    timeout: '0'
                }))
            }

            next(nextAction)
            break

        case `${POST_DELETE_REPAIRER_ACCIDENT} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_DELETE_REPAIRER_ACCIDENT,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_DELETE_REPAIRER_ACCIDENT}),
                setLoader({state: false, entity: POST_DELETE_REPAIRER_ACCIDENT})
            ])
            break

        case POST_CHANGE_DATE_EXPERT_VISIT:
            next([
                apiRequest({
                    body: (action.payload.body),
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_CHANGE_DATE_EXPERT_VISIT
                }),
                setLoader({state: true, entity: POST_CHANGE_DATE_EXPERT_VISIT})
            ])
            break

        case `${POST_CHANGE_DATE_EXPERT_VISIT} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_CHANGE_DATE_EXPERT_VISIT,
                    body: 'La date de visite de l\'expert a bien été mise à jour',
                    type: 'success',
                    title: 'Modification'
                }),
                setError({state: false, entity: POST_CHANGE_DATE_EXPERT_VISIT}),
                setLoader({state: false, entity: POST_CHANGE_DATE_EXPERT_VISIT})
            ])
            break

        case `${POST_CHANGE_DATE_EXPERT_VISIT} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_CHANGE_DATE_EXPERT_VISIT,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_CHANGE_DATE_EXPERT_VISIT}),
                setLoader({state: false, entity: POST_CHANGE_DATE_EXPERT_VISIT})
            ])
            break

        case POST_REFUND_REPAIRER_ACCIDENT:
            next([
                apiRequest({
                    body: action.payload.body,
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_REFUND_REPAIRER_ACCIDENT
                }),
                setLoader({state: true, entity: POST_REFUND_REPAIRER_ACCIDENT})
            ])
            break

        case `${POST_REFUND_REPAIRER_ACCIDENT} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_REFUND_REPAIRER_ACCIDENT,
                    body: 'La prise en charge du remboursement du réparateur a bien été mise à jour',
                    type: 'success',
                    title: 'Modification'
                }),
                setError({state: false, entity: POST_REFUND_REPAIRER_ACCIDENT}),
                setLoader({state: false, entity: POST_REFUND_REPAIRER_ACCIDENT})
            ])
            break

        case `${POST_REFUND_REPAIRER_ACCIDENT} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_REFUND_REPAIRER_ACCIDENT,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_REFUND_REPAIRER_ACCIDENT}),
                setLoader({state: false, entity: POST_REFUND_REPAIRER_ACCIDENT})
            ])
            break

        case POST_ADD_PENALTIES:
            next([
                apiRequest({
                    body: (action.payload.body),
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_ADD_PENALTIES
                }),
                setLoader({state: true, entity: POST_ADD_PENALTIES})
            ])
            break

        case `${POST_ADD_PENALTIES} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_ADD_PENALTIES,
                    body: 'Les pénalités du sinistre ont été mises à jour',
                    type: 'success',
                    title: 'Modification'
                }),
                setError({state: false, entity: POST_ADD_PENALTIES}),
                setLoader({state: false, entity: POST_ADD_PENALTIES})
            ])
            break

        case `${POST_ADD_PENALTIES} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_ADD_PENALTIES,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_ADD_PENALTIES}),
                setLoader({state: false, entity: POST_ADD_PENALTIES})
            ])
            break

        case POST_DELETE_PENALTIES:
            next([
                apiRequest({body: null, method: 'POST', url: action.payload.data, entity: POST_DELETE_PENALTIES}),
                setLoader({state: true, entity: POST_DELETE_PENALTIES})
            ])
            break

        case `${POST_DELETE_PENALTIES} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_DELETE_PENALTIES,
                    body: 'La pénalité a été supprimée avec succès.',
                    type: 'success',
                    title: 'Suppression effectuée'
                }),
                setError({state: false, entity: POST_DELETE_PENALTIES}),
                setLoader({state: false, entity: POST_DELETE_PENALTIES})
            ])
            break

        case `${POST_DELETE_PENALTIES} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_DELETE_PENALTIES,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_DELETE_PENALTIES}),
                setLoader({state: false, entity: POST_DELETE_PENALTIES})
            ])
            break

        case POST_ADD_OPPOSITIONS:
            next([
                apiRequest({
                    body: (action.payload.body),
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_ADD_OPPOSITIONS
                }),
                setLoader({state: true, entity: POST_ADD_OPPOSITIONS})
            ])
            break

        case `${POST_ADD_OPPOSITIONS} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_ADD_OPPOSITIONS,
                    body: 'L\'opposition du sinistre a été mise à jour',
                    type: 'success',
                    title: 'Modification'
                }),
                setError({state: false, entity: POST_ADD_OPPOSITIONS}),
                setLoader({state: false, entity: POST_ADD_OPPOSITIONS})
            ])
            break

        case `${POST_ADD_OPPOSITIONS} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_ADD_OPPOSITIONS,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_ADD_OPPOSITIONS}),
                setLoader({state: false, entity: POST_ADD_OPPOSITIONS})
            ])
            break

        case POST_DELETE_OPPOSITIONS:
            next([
                apiRequest({body: null, method: 'POST', url: action.payload.data, entity: POST_DELETE_OPPOSITIONS}),
                setLoader({state: true, entity: POST_DELETE_OPPOSITIONS})
            ])
            break

        case `${POST_DELETE_OPPOSITIONS} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_DELETE_OPPOSITIONS,
                    body: 'L\'opposition a été supprimée avec succès.',
                    type: 'success',
                    title: 'Suppression effectuée'
                }),
                setError({state: false, entity: POST_DELETE_OPPOSITIONS}),
                setLoader({state: false, entity: POST_DELETE_OPPOSITIONS})
            ])
            break

        case `${POST_DELETE_OPPOSITIONS} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_DELETE_OPPOSITIONS,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_DELETE_OPPOSITIONS}),
                setLoader({state: false, entity: POST_DELETE_OPPOSITIONS})
            ])
            break

        case POST_PROCESSING_PV:
            newValues = action.payload.body

            newValues.date_debut = moment(newValues.date_debut).format('L')
            newValues.date_fin = moment(newValues.date_fin).format('L')

            nextAction = [
                apiRequest({
                    body: (newValues),
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_PROCESSING_PV,
                    otherData: action.payload.otherData
                }),
                setLoader({state: true, entity: POST_PROCESSING_PV})
            ]

            if (action.payload.otherData.terminate !== undefined) {
                nextAction.push(setLoader({state: true, entity: CLOSE_TASK}))
            }

            next(nextAction)
            break

        case `${POST_PROCESSING_PV} ${API_SUCCESS}`:
            nextAction = [
                setError({state: false, entity: POST_PROCESSING_PV}),
                setLoader({state: false, entity: POST_PROCESSING_PV})
            ]

            if (action.payload.meta.otherData.terminate !== undefined) {
                nextAction.push(apiRequest({
                    body: null,
                    method: 'POST',
                    url: action.payload.meta.otherData.terminate,
                    entity: CLOSE_TASK
                }))
            } else {
                nextAction.push(setNotification({
                    entity: CLOSE_TASK,
                    body: `<p>Les tâche PV a été traitée avec succès</p>`,
                    type: 'success',
                    title: 'Tâche traitée !'
                }))
            }
            next(nextAction)
            break

        case `${POST_PROCESSING_PV} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_PROCESSING_PV,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_PROCESSING_PV}),
                setLoader({state: false, entity: POST_PROCESSING_PV})
            ])
            break

        case POST_UPDATE_ACCIDENT_BY_TASK:
            clone = _.cloneDeep(action.payload.body)

            if (clone.dateSurv) {
                clone.dateSurv = clone.dateSurv.format('YYYY-MM-DD')
            }

            nextAction = [
                apiRequest({
                    body: (clone),
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_UPDATE_ACCIDENT_BY_TASK,
                    otherData: action.payload.otherData
                }),
                setLoader({state: true, entity: POST_UPDATE_ACCIDENT_BY_TASK})
            ]
            if (action.payload.otherData.terminate !== undefined) {
                nextAction.push(setLoader({state: true, entity: CLOSE_TASK}))
            }


            next(nextAction)
            break

        case `${POST_UPDATE_ACCIDENT_BY_TASK} ${API_SUCCESS}`:
            nextAction = [
                setError({state: false, entity: POST_UPDATE_ACCIDENT_BY_TASK}),
                setLoader({state: false, entity: POST_UPDATE_ACCIDENT_BY_TASK})
            ]

            if (action.payload.meta.otherData.terminate !== undefined) {
                nextAction.push(apiRequest({
                    body: null,
                    method: 'POST',
                    url: action.payload.meta.otherData.terminate,
                    entity: CLOSE_TASK
                }))
            } else {
                nextAction.push(setNotification({
                    entity: POST_UPDATE_ACCIDENT_BY_TASK,
                    body: `<p>Le sinistre a été mis à jour.</p>`,
                    type: 'success',
                    title: 'Modification'
                }))
            }
            next(nextAction)
            break

        case `${POST_UPDATE_ACCIDENT_BY_TASK} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_UPDATE_ACCIDENT_BY_TASK,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_UPDATE_ACCIDENT_BY_TASK}),
                setLoader({state: false, entity: POST_UPDATE_ACCIDENT_BY_TASK})
            ])
            break

        default:
            break
    }
    return null
}
