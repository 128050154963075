import React from "react";
import cas_10 from '../../../../../../../../assets/img/cas_ida/cas_10.jpg'
import cas_13 from '../../../../../../../../assets/img/cas_ida/cas_13.jpg'
import cas_15 from '../../../../../../../../assets/img/cas_ida/cas_15.jpg'
import cas_17 from '../../../../../../../../assets/img/cas_ida/cas_17.jpg'
import cas_20 from '../../../../../../../../assets/img/cas_ida/cas_20.jpg'
import cas_21 from '../../../../../../../../assets/img/cas_ida/cas_21.jpg'
import cas_40 from '../../../../../../../../assets/img/cas_ida/cas_40.jpg'
import cas_43 from '../../../../../../../../assets/img/cas_ida/cas_43.jpg'
import cas_50 from '../../../../../../../../assets/img/cas_ida/cas_50.jpg'
import cas_51 from '../../../../../../../../assets/img/cas_ida/cas_51.jpg'

export const getImgCasIda = (cas) => {
    switch (cas) {
        case '10X':
        case '10Y':
            return <img src={cas_10} alt={'Cas IDA'}/>
        case '13X':
        case '13Y':
            return <img src={cas_13} alt={'Cas IDA'}/>
        case '15X':
        case '15Y':
            return <img src={cas_15} alt={'Cas IDA'}/>
        case '17X':
        case '17Y':
            return <img src={cas_17} alt={'Cas IDA'}/>
        case '20X':
        case '20Y':
            return <img src={cas_20} alt={'Cas IDA'}/>
        case '21X':
        case '21Y':
            return <img src={cas_21} alt={'Cas IDA'}/>
        case '40X':
        case '40Y':
            return <img src={cas_40} alt={'Cas IDA'}/>
        case '43X':
        case '43Y':
            return <img src={cas_43} alt={'Cas IDA'}/>
        case '50X':
        case '50Y':
            return <img src={cas_50} alt={'Cas IDA'}/>
        case '51X':
        case '51Y':
            return <img src={cas_51} alt={'Cas IDA'}/>
        default:
            return ''
    }
}
