export const NAVIGATION_ACTIVITIES = '[Navigation Activities]'

export const GET_NAVIGATION_ACTIVITIES = `${NAVIGATION_ACTIVITIES} Get`
export const SET_NAVIGATION_ACTIVITIES = `${NAVIGATION_ACTIVITIES} Set`

export const getNavigationActivities = ({query}) => ({
    type: GET_NAVIGATION_ACTIVITIES,
    payload: {
        data: query
    }
})

export const setNavigationActivities = ({data}) => ({
    type: SET_NAVIGATION_ACTIVITIES,
    payload: {data},
})
