import React, {Component, Fragment} from 'react';
import moment from "moment";
import CallToAction from "../../../../Commun/Button/CallToAction/CallToAction";

class Notes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            disabledDelete: false
        }
    }

    handleClick = (id) => {
        this.props.postDelete({
            id: id
        })
        this.setState({
            disabledDelete: true
        })
    }

    render() {
        const {notes, contractId, accident, souscripteur} = this.props

        return (
            <Fragment>
                {notes &&
                    <div className={'row header-row'}>
                        <div className={'col'}>
                            <div className={'row'}>
                                {notes.map((note, index) =>
                                    <div className="alert alert-info col-6 note" role="alert" key={index}>
                                        <div className={'top'}>
                                            <h4 className="alert-heading">Note d'information sur le dossier</h4>
                                            <CallToAction
                                                action={'delete-note'}
                                                toolText={'Supprimer une note'}
                                                onClick={() => {
                                                    this.handleClick(note.id)
                                                }}
                                                url={'#'}
                                                disabled={this.state.disabledDelete}
                                            />
                                            <CallToAction
                                                url={{
                                                    pathname: `/contrat/${contractId}/sinistre/${accident.id}/note`,
                                                    state: {
                                                        edit: true,
                                                        title: `${souscripteur.titre} ${souscripteur.nom} ${souscripteur.id} - Sinistre n°${accident.numCbt} - ${accident.marqueVehicule} ${accident.modeleVehicule}`,
                                                        note: note
                                                    }
                                                }}
                                                action={'edit-note'}
                                                toolText={'Editer une note'}
                                            />
                                        </div>
                                        <small>Créée le {moment(note.created_at).format('L')}</small>
                                        <p>{note.titre}</p>
                                        <hr/>
                                        <p>{note.commentaire}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                }
            </Fragment>
        )
    }
};

export default Notes;
