export const COMPANYS = '[Companys]'

export const GET_COMPANYS = `${COMPANYS} Get`
export const SET_COMPANYS = `${COMPANYS} Set`

export const getCompanys = ({query}) => ({
    type: GET_COMPANYS,
    payload: {
        data: query
    }
})

export const setCompanys = ({companys}) => ({
    type: SET_COMPANYS,
    payload: {
        data: companys
    }
})
