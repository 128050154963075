import React, {Fragment} from 'react'
import {Link} from "react-router-dom"
import Badge from "../../../../Commun/Badge/Badge";
import _ from 'lodash'

const NavigationContrats = (props) => {
    const {navigation_contract, contract_id} = props

    return (
        navigation_contract &&
        <div className={'row'}>
            <div className={'col text-center'}>
                {_.reverse(navigation_contract).map((contract, index) =>
                    <Fragment key={index}>
                        <Link
                            to={`/contrat/${contract.id}`}
                            className={`btn btn-return btn-smaller ${contract_id < contract.id ? 'btn-next' : 'btn-prev'} ${index === 0 ? 'mr-1' : 'ml-1'}`}
                        >
                            Contrat : {contract.numeroPolice}
                            &nbsp;&nbsp;&nbsp;<Badge status={contract.situation_statut}/>
                        </Link>
                    </Fragment>
                )}
            </div>
        </div>
    )
}

export default NavigationContrats