import {connect} from 'react-redux'
import {compose} from "redux";
import Hivernage from "./Hivernage";
import _ from "lodash";
import loader from "../../../../Commun/Spinner/Loader/Loader";
import {
    getContractData,
    getIsEligibleHivernageContract
} from "../../../../../redux/selectors/contract/contract.selectors";
import {contractDefaultGetLink} from "../../../../../redux/selectors/links/links.selectors";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {CONTRACT, getContract} from "../../../../../redux/actions/app/contracts/contracts.actions";

const mapStateToProps = (state, ownProps) => {
    return {
        uriContract: contractDefaultGetLink(state),
        id_contrat: ownProps.match.params.id,
        is_eligible_hivernage : getIsEligibleHivernageContract(state),
        loaded: getLoadingEntity(state, CONTRACT) === false,
        contract: getContractData(state),
    }
}
const mapDispatchToProps = {
    getContract
}
const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { uriContract, id_contrat } = stateProps
    const { getContract} = dispatchProps

    return {
        load: () => getContract({query: _.replace(uriContract, '{id}', id_contrat), id: id_contrat}),
        ...ownProps, ...stateProps, ...dispatchProps
    }
}
const HivernageContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Hivernage)
export default HivernageContainer