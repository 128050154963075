import GarantieItemContainer from "./GarantieItem/GarantieItemContainer";

const Garanties = (props) => {
    const {garanties, isWakam} = props
    return (
        <div className={'bloc-info bloc-garanties'}>
            <h2>Garanties</h2>
            <div className={'content row'}>
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">Sinistrée</th>
                        <th scope="col">Libellé</th>
                        <th scope="col">Code</th>
                        <th scope="col">Franchise</th>
                        <th scope="col">Plafond</th>
                        {isWakam &&
                            <>
                                <th scope="col">Eval. Cabinet</th>
                                <th scope="col">Eval. recours</th>
                            </>
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {garanties.map((garantie, index) =>
                        (garantie.code !== 'PVRCC' && garantie.code !== 'PVRCM') &&
                        <GarantieItemContainer garantie={garantie} key={index}
                                               form={`garantie-${garantie.code}`} {...props}/>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Garanties
