import React, {Fragment, useState} from 'react'
import SearchFieldContainer from "../Search/SearchFieldContainer";

const Home = () => {
    const [searchOpen, setSearchOpen] = useState(false)

    const toggleSearch = () => {
        setSearchOpen(!searchOpen)
    }

    return (
        <div className={'container-fluid'}>
            <div className={'row'}>
                <div className={'home-televente col-12'}>
                    <h3>Bonjour et bienvenue sur l'espace APRIL Moto.</h3>
                    <p>Pour rechercher un client, vous pouvez cliquer ci-dessous ou sur l'onglet "Recherche" dans le
                        menu de navigation.</p>
                    <span className={'btn btn-secondary'} onClick={toggleSearch}>Rechercher un client</span>

                    {searchOpen &&
                        <Fragment>
                            <span className={'close-search'} onClick={toggleSearch}>x</span>
                            <SearchFieldContainer toggleSearch={toggleSearch}/>
                        </Fragment>
                    }
                </div>
            </div>
        </div>

    )
}

export default Home
