import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {getErrorEntity, getLoadingEntity} from '../../../../../redux/selectors/ui/ui.selectors'
import NavigationAccidents from './NavigationAccidents'
import {accidentsGetLinkWithoutIdUrl} from '../../../../../redux/selectors/links/links.selectors'
import ErrorHttpComponent from '../../../../Commun/Error/ErrorHttpComponent'
import _ from "lodash";
import {getAccidentsData} from "../../../../../redux/selectors/accidents/accidents.selectors";
import {ACCIDENTS, getAccidents} from "../../../../../redux/actions/app/accidents/accidents.actions";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    return {
        tiers: ownProps.tiers,
        accidents: getAccidentsData(state),
        uri: accidentsGetLinkWithoutIdUrl(state),
        loaded: getLoadingEntity(state, ACCIDENTS) === false,
        error: getErrorEntity(state, ACCIDENTS)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getAccidents: (query) => getAccidents({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getAccidents} = dispatchProps
    const {uri, tiers} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getAccidents((_.replace(uri, '{id}', tiers)))
    }
}

const NavigationAccidentsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(NavigationAccidents)

export default NavigationAccidentsContainer
