import React from 'react';
import {Field} from "redux-form";
import TextFieldRedux from "../../../../../../Commun/Input/Text/TextFieldRedux";
import PostalCodeCity from "../../../../../../Commun/PostalCodeCity/PostalCodeCity";
import InfosVehicule from "./InfosVehicule/InfosVehicule";
import InfosVehiculeVAE from "./InfosVehiculeVAE/InfosVehiculeVAE";

const FormAvenantInfosClient = (props) => {
    const {changeValue, defaultValueCodepostalCity, isManagerQualite, isVAE, isNVEI, isWakam} = props

    return (
        <div>
            {
                !isWakam && <div className="row">
                    <div className="col">
                        <Field
                            name='souscripteur_nom'
                            data-test-id="avenant_simple_souscripteur_nom_input"
                            component={TextFieldRedux}
                            label={'Nom du souscripteur :'}
                            normalize={v => v.toUpperCase()}
                        />
                    </div>
                    <div className="col">
                        <Field
                            name='souscripteur_prenom'
                            data-test-id="avenant_simple_souscripteur_prenom_input"
                            component={TextFieldRedux}
                            label={'Prénom du souscripteur :'}
                            normalize={v => v.toUpperCase()}
                        />
                    </div>
                </div>
            }


            {
                !isWakam && <div className="row">
                    <div className="col">
                        <Field
                            name='conducteur_nom'
                            data-test-id="avenant_simple_conducteur_nom_input"
                            component={TextFieldRedux}
                            label={'Nom du conducteur :'}
                            normalize={v => v.toUpperCase()}
                        />
                    </div>
                    <div className="col">
                        <Field
                            name='conducteur_prenom'
                            data-test-id="avenant_simple_conducteur_prenom_input"
                            component={TextFieldRedux}
                            label={'Prénom du conducteur :'}
                            normalize={v => v.toUpperCase()}
                        />
                    </div>
                </div>
            }

            <div className="row">
                <div className="col">
                    <Field
                        name='adresse1'
                        data-test-id="avenant_simple_adresse1_input"
                        component={TextFieldRedux}
                        label={'Adresse 1 :'}
                    />
                </div>
                <div className="col">
                    <Field
                        name='adresse2'
                        data-test-id="avenant_simple_adresse2_input"
                        component={TextFieldRedux}
                        label={'Adresse 2 :'}
                    />
                </div>
                <div className="col">
                    <Field
                        name='adresse3'
                        data-test-id="avenant_simple_adresse3_input"
                        component={TextFieldRedux}
                        label={'Adresse 3 :'}
                    />
                </div>
            </div>

            <PostalCodeCity
                nameCodePostal='code_postal'
                nameVille='ville'
                label={'Code postal du souscripteur (attention ceci ne change pas le lieu de stationnement !) :'}
                changeValue={changeValue}
                id={'address'}
                defaultValue={defaultValueCodepostalCity}
            />

            <div className="row">
                {!isVAE && !isNVEI && !isWakam &&
                    <div className="col-6">
                        <Field
                            name='immatriculation'
                            data-test-id="avenant_simple_immatriculation_input"
                            component={TextFieldRedux}
                            label={'Immatriculation :'}
                            normalize={v => v.toUpperCase()}
                        />
                    </div>
                }
                {
                    isManagerQualite && <div className="col-6">
                        <Field
                            name='apporteur1'
                            data-test-id="avenant_simple_apporteur1_input"
                            component={TextFieldRedux}
                            label={'ID Apporteur 1 :'}
                        />
                    </div>
                }
            </div>

            <div className="row">
                {
                    isManagerQualite && !isVAE && !isNVEI && <InfosVehicule/>
                }

                {
                    isVAE && <InfosVehiculeVAE/>
                }
            </div>
        </div>
    );
};


export default FormAvenantInfosClient;
