import {connect} from 'react-redux'
import FormAvenantSimpleCivilite from "./FormAvenantSimpleCivilite";
import {formValueSelector} from "redux-form";

const mapStateToProps = state => {
    const selector = formValueSelector('avenant-simple')
    return {
        souscripteur_civilite: selector(state, 'souscripteur_civilite'),
    }
}


const FormAvenantSimpleCiviliteContainer = connect(mapStateToProps)(FormAvenantSimpleCivilite)


export default FormAvenantSimpleCiviliteContainer