import React from 'react';
import SelectFieldRedux from "../../../../../Commun/Input/Select/SelectFieldRedux";
import moment from "moment";
import {Field} from "redux-form";
import Spinner from "../../../../../Commun/Spinner/Spinner";

const FormChangeDateExpertVisitAccident = (props) => {
    const {garage, handleSubmit, loading} = props

    return (
        <form className={'form-content'} onSubmit={handleSubmit}>
            <Field
                name='visit'
                component={SelectFieldRedux}
                label={'Date de passage de l\'expert'}
                disabled={garage && garage.datesVisite.length === 0}
            >
                <option value={""}>Sélectionnez la date</option>
                {
                    garage && garage.datesVisite.map((date_visite, index) =>
                        <option key={index}
                                value={moment(date_visite).format('L')}>{moment(date_visite).format('dddd DD MMMM YYYY')}</option>
                    )
                }
            </Field>

            {
                loading ? <Spinner style={{display: 'block', margin: 'auto'}}/> :
                    <button className={'btn btn-primary d-block'} type={'submit'}>
                        Mettre à jour
                    </button>
            }
        </form>
    );
};

export default FormChangeDateExpertVisitAccident;
