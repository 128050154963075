import {createSelector} from 'reselect'
import _ from 'lodash'

const commandeProduitsSelector = state => state.commande_produits

export const getCommandeProduits = createSelector(
    commandeProduitsSelector,
    produits => produits || []
)

export const getPlvCommandeProduits = createSelector(
    commandeProduitsSelector,
    produits => _.filter(produits, p => {
        return p.category.id === 1
    })
)

export const getDispositionGeneralesCommandeProduits = createSelector(
    commandeProduitsSelector,
    produits => _.filter(produits, p => {
        return p.category.id === 2
    })
)
