import React, {Fragment} from 'react'
import TaskCard from "../../../../../Commun/Card/TaskCard";

const Tasks = (props) => {
    const {tasks} = props

    return (
        <Fragment>
            {tasks.length > 0 &&
                <div className={'row header-row'}>
                    <div className={'col'}>
                        {tasks.map((task, index) => (
                            <TaskCard task={task} key={index} showColTiers={false} showColContrat={false}
                                      showColSinistre={true}/>
                        ))}
                    </div>
                </div>
            }
        </Fragment>
    )
}

export default Tasks
