import React from 'react';
import DerogationItemContainer from './DerogationItem/DerogationItemContainer';

const Derogations = (props) => {
    const { derogations, isManager, idAccident } = props;

    return (
        <div className={'bloc-info bloc-reclamations'}>
            <h2>Dérogation</h2>
            <table className='table'>
                <thead>
                    <tr>
                        <th scope='col'>Date de dérogation</th>
                        <th scope='col'>Thème</th>
                        <th scope='col'>Motif</th>
                        <th scope='col'>Montant</th>

                        {isManager && <th scope='col'>&nbsp;</th>}
                    </tr>
                </thead>
                <tbody>
                    {derogations.map((derogation) => (
                        <DerogationItemContainer key={derogation.id} derogation={derogation} isManager={isManager} idAccident={idAccident} />
                    ))}

                    {derogations.length === 0 && (
                        <tr>
                            <td colSpan={5} className={'font-weight-normal'}>
                                Aucune dérogation
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default Derogations;
