export const getReason = (reason) => {
    switch (reason) {
        case 'CONTENTIEUX':
            return 'Un contentieux est en cours sur ce contrat.';
        case 'SINISTRE_PREDECLARE':
            return 'Un sinistre est déjà pré-déclaré sur ce contrat.';
        case 'PRODUIT_AUTO':
            return 'Indisponible pour les produits Auto.';
        case 'CONTRAT_RESILIE':
            return 'Contrat résilié.';
        case 'CONTRAT_SUSPENDU_DEPUIS_PLUS_DUN_AN':
            return 'Contrat suspendu depuis plus d\'un an.';
        case 'CONTRAT_EN_ETABLISSEMENT':
            return 'Ce contrat est en établissement.';
        case 'PERMISSION_DENIED':
            return 'Vous n\'avez pas la permission pour faire cette action.';
        case 'PAIEMENT_EN_ATTENTE':
            return 'Il y a déjà un paiement en attente d\'encaissement sur cette quittance.';
        case 'RECOUVREMENT':
            return 'Cette quittance est en recouvrement : merci de payer votre quittance auprès de notre partenaire Effico, au 0247648767.';
        case 'PRELEVEMENT':
            return 'Prélévement programmé.';
        case 'SOLDEE':
            return 'Cette quittance est déjà soldée.';
        case 'CONTENTIEUX_OU_LR':
            return 'Un contentieux est en cours sur ce contrat.'
        case 'PRELEVEMENT_REPRESENTE':
            return 'Le prélèvement rejeté est de nouveau présenté à la banque.'
        case 'AVENANT_EN_ATTENTE':
            return 'Un avenant est en cours sur ce contrat.'
        case 'PRODUIT_NVEI_ONLY':
            return ''
        case 'DEJA_UN_SINISTRE_GARANTIE_MOBILITE':
            return 'Vous avez utilisé votre garantie mobilité'
        case 'CARENCE_1_MOIS':
            return 'Vous ne pouvez pas utiliser votre garantie mobilité le premier mois de souscription'
        case 'AVENANT_AUJOURDHUI':
            return 'Un avenant a déjà été effectué sur ce contrat aujourd\'hui'
        default:
            return reason;
    }
}
