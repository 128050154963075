import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setHivernageReduction, setHivernageSuccess, setLoader} from '../../../actions/app/ui/ui.actions'
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {
    GET_HIVERNAGE_REDUC,
    POST_FIN_HIVERNAGE,
    POST_HIVERNAGE
} from "../../../actions/app/hivernage/hivernage.actions";
import _ from "lodash";
import moment from "moment";

export const hivernageMiddleware = () => next => action => {
    next(action)

    const {payload} = action
    let message = null
    let clone

    switch (action.type) {
        case POST_HIVERNAGE:
            clone = _.cloneDeep(action.payload.body);
            if(clone.dateFin){
                clone.dateFin = moment(clone.dateFin).format('YYYY-MM-DD')
            }
            next([
                apiRequest({body: (clone), method: 'POST', url: action.payload.data, entity: POST_HIVERNAGE}),
                setLoader({state: true, entity: POST_HIVERNAGE})
            ])
            break

        case `${POST_HIVERNAGE} ${API_SUCCESS}`:
            message =  `<p class='font-weight-bold'>Votre demande a bien été enregistrée.</p><p>Pour valider la demande, le client devra signer électroniquement la lettre avenant qui va lui être envoyée par e-mail</p>`;

            next([
                setNotification({ entity: POST_HIVERNAGE, body: message, type: 'success', title: 'Merci !'}),
                setHivernageSuccess({state: true}),
                setLoader({state: false, entity: POST_HIVERNAGE}),
                setError({state: false, entity: POST_HIVERNAGE}),
            ])
            break

        case `${POST_HIVERNAGE} ${API_ERROR}`:
            message = `<p>Votre demande n'a pas pu être envoyée. ${payload.data.response.data?.message ?? ''}</p>`
            next([
                setNotification({ entity: POST_HIVERNAGE, body: message, type: 'error', title: 'Erreur lors de l\'activation', timeout: '0' }),
                setError({state: true, entity: POST_HIVERNAGE}),
                setLoader({state: false, entity: POST_HIVERNAGE}),
            ])
            break

        case POST_FIN_HIVERNAGE:
            next([
                apiRequest({body: (action.payload.body), method: 'POST', url: action.payload.data, entity: POST_FIN_HIVERNAGE, otherData: action.payload.meta}),
                setLoader({state: true, entity: POST_FIN_HIVERNAGE})
            ])
            break

        case `${POST_FIN_HIVERNAGE} ${API_SUCCESS}`:
            message =  `<p class='font-weight-bold'>Ma période hivernale est bien désactivée</p>`;

            next([
                setNotification({ entity: POST_FIN_HIVERNAGE, body: message, type: 'success', title: 'Merci !' }),
                setHivernageSuccess({state: true}),
                setLoader({state: false, entity: POST_FIN_HIVERNAGE}),
                setError({state: false, entity: POST_FIN_HIVERNAGE}),
            ])
            break

        case `${POST_FIN_HIVERNAGE} ${API_ERROR}`:
            message = `<p>Votre demande n'a pas pu être envoyée. ${payload.data.response.data?.message ?? ''}</p>`
            next([
                setNotification({ entity: POST_FIN_HIVERNAGE, body: message, type: 'error', title: 'Erreur lors de la désactivation', timeout: '0' }),
                setError({state: true, entity: POST_FIN_HIVERNAGE}),
                setLoader({state: false, entity: POST_FIN_HIVERNAGE}),
            ])
            break
        
        case GET_HIVERNAGE_REDUC:
            clone = _.cloneDeep(action.payload.body);
            clone.getTarif = 1;
            
            if(clone.dateFin){
                clone.dateFin = moment(clone.dateFin).format('YYYY-MM-DD')
            }
            next([
                apiRequest({body: (clone), method: 'POST', url: action.payload.data, entity: GET_HIVERNAGE_REDUC}),
                setLoader({state: true, entity: GET_HIVERNAGE_REDUC})
            ])
            break

        case `${GET_HIVERNAGE_REDUC} ${API_SUCCESS}`:
            next([
                setHivernageReduction({state: action.payload.data?.data?.reduction}),
                setLoader({state: false, entity: GET_HIVERNAGE_REDUC}),
                setError({state: false, entity: GET_HIVERNAGE_REDUC}),
            ])
            break

        case `${GET_HIVERNAGE_REDUC} ${API_ERROR}`:
            message = `<p>Votre demande n'a pas pu être envoyée. ${payload.data.response.data?.message ?? ''}</p>`
            next([
                setNotification({ entity: GET_HIVERNAGE_REDUC, body: message, type: 'error', title: 'Erreur lors de la récupération de la réduction', timeout: '0' }),
                setError({state: true, entity: GET_HIVERNAGE_REDUC}),
                setLoader({state: false, entity: GET_HIVERNAGE_REDUC}),
            ])
            break

        default:
            break
    }
    return null
}
