export const FICHE = '[Fiche]'

export const GET_FICHE = `${FICHE} Get`
export const SET_FICHE = `${FICHE} Set`

export const getFiche = ({query}) => ({
    type: GET_FICHE,
    payload: {
        data: query
    }
})

export const setFiche = ({data}) => ({
    type: SET_FICHE,
    payload: {
        data
    }
})