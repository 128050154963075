import React, {useEffect, useState} from 'react';
import {Field} from "redux-form";
import AutoComplete from "../../../../Commun/Input/Autocomplete/Autocomplete";
import Spinner from "../../../../Commun/Spinner/Spinner";
import {getReparateurAccident} from "../../../../../utils/function";
import _ from "lodash";


const ChangeReparateurAccident = (props) => {
    const {changeValue, handleSubmit, loading} = props
    const [reparateurs, setReparateurs] = useState([])

    useEffect(() => {
        fetchReparateurs();
    }, [])

    async function fetchReparateurs() {
        let arrayReparateurs = await getReparateurAccident()
        let listReparateurs = _.orderBy(arrayReparateurs, ['raisonSociale'], ['asc'])

        listReparateurs = listReparateurs.map(reparateur => ({
            value: reparateur.id,
            label: reparateur.raisonSociale + ' - ' + reparateur.adresse + ' - ' + reparateur.codePostal + ' ' + reparateur.ville + ' - ' + reparateur.phone
        }))

        setReparateurs(listReparateurs)
        changeValue('reparateur', '')
    }

    return (
        <div className={'contract-container container'}>
            <div className={'row return-content'}>
                <div className={'col'}>
                    <button onClick={() => props.history.go(-1)} className={'btn btn-return'}>Retour</button>
                </div>
            </div>
            <div className={'row header-row'}>
                <div className={'col-12'}>
                    <form onSubmit={handleSubmit} className={'change-reparateur form-content'}>
                        <Field
                            name={'reparateur'}
                            component={AutoComplete}
                            label="Chercher un réparateur par nom, code postal ou téléphone"
                            data={reparateurs}
                        />

                        {
                            loading ? <Spinner style={{display: 'block', margin: 'auto'}}/> :
                                <button className={'btn btn-primary d-block'} type={'submit'}>
                                    Mettre à jour
                                </button>
                        }
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ChangeReparateurAccident;
