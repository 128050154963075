import React from 'react';
import {Field} from "redux-form";
import moment from "moment";
import APIFrame from "../../../../../Commun/APILink/APIFrame";
import ReaderJpeg from "../../../../../Commun/ReaderJPEG/ReaderJPEG";
import DatePickerFieldRedux from "../../../../../Commun/Input/DatePicker/DatePickerFieldRedux";
import RadioFieldRedux from "../../../../../Commun/Input/Radio/RadioFieldRedux";
import _ from "lodash";
import CheckboxFieldRedux from "../../../../../Commun/Input/Checkbox/CheckboxFieldRedux";
import {normalizeBooleanForNoobBelair} from "../../../../../../utils/normalizeInputs";
import Spinner from "../../../../../Commun/Spinner/Spinner";
import MyButton from "../../../../../Commun/Button/Button";
import TextFieldRedux from "../../../../../Commun/Input/Text/TextFieldRedux";

const TachePv = (props) => {
    const {task: {title, description, date, type, paniere, tiers_id, contract, sinistre}, files,handleSubmit, immat_contrat, vol_violence, local, elements_voles, loadingTerminate, loadingProcessingPV, unsetValue, vol_autres} = props

    const clearEquipementsVoles = () => {
        unsetValue('vol_casque')
        unsetValue('vol_gants')
        unsetValue('vol_blouson')
        unsetValue('vol_pantalon')
        unsetValue('vol_carte_grise')
        unsetValue('vol_antivol')
        unsetValue('vol_autres')
        unsetValue('vol_autres_detail')
    }

    return (
        <div className={'container-fluid'}>
            <div className={'row'}>
                <div className={'col text-center'}>
                    <h2 className={'title-primary text-uppercase'}>{paniere} - Tiers n°{tiers_id}</h2>
                    <p className={'subtitle'}>Tâche du {moment(date).format('L')}. Recu par {type}</p>
                </div>
            </div>
            <div className={'header-row row'}>
                <div className={'col-12'}>
                    <p className={'form-content text-center'}>
                        Type : {title}<br/>
                        Description : {description ? <span dangerouslySetInnerHTML={{__html: description}}/> : '-'}
                    </p>
                </div>
            </div>

            <div className={'row'}>
                <div className={'col-8'} style={{marginBottom: 30}}>
                    {files !== undefined && files.map((file, i) => (
                        file.extension.toLowerCase() === 'pdf' ?
                            <div className={'row'} key={i}>
                                <div className={'col-12 mt-5'} style={{marginBottom: 30}}>
                                    <APIFrame src={`${file.url}`} title={`${file.code}`} frameBorder="0"
                                              style={{height: '100%', width: '100%', marginBottom: 30}}/>
                                </div>
                            </div>
                            :
                            <div className={'row justify-content-center'} key={i}>
                                <ReaderJpeg url={file.url}/>
                            </div>
                    ))}
                </div>
                <div className={'col-4'}>

                    {
                        (sinistre && _.includes(['10', '105', '9'], sinistre.categorieCode)) ?
                        <form className={`form-content`} onSubmit={handleSubmit}>

                            <Field
                                name='date_debut'
                                component={DatePickerFieldRedux}
                                label={'Entre le'}
                                minDate={moment(contract.situation.dateAffaireNouvelle)}
                                maxDate={moment()}
                            />
                            <Field
                                name='date_fin'
                                component={DatePickerFieldRedux}
                                label={'Et le'}
                                minDate={moment(contract.situation.dateAffaireNouvelle)}
                                maxDate={moment()}
                            />

                            <Field
                                name={`immat_contrat`}
                                component={RadioFieldRedux}
                                label={'L\'immatriculation correspond-elle bien au contrat ?'}
                                data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                                required
                                onChange={() => {
                                    unsetValue('vol_violence');
                                    unsetValue('vehicule_attache');
                                    unsetValue('local');
                                    unsetValue('lieux_accident');
                                    unsetValue('elements_voles');
                                    clearEquipementsVoles();
                                }}
                            />
                            {
                                (immat_contrat && immat_contrat === 'T') &&
                                <>
                                    <Field
                                        name={`vol_violence`}
                                        component={RadioFieldRedux}
                                        label={'S\'agit-il d\'un vol avec violence ?'}
                                        data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                                        required
                                        onChange={() => {
                                            unsetValue('vehicule_attache');
                                            unsetValue('local');
                                            unsetValue('lieux_accident');
                                        }}
                                    />
                                    {
                                        (vol_violence && vol_violence === 'F') && <Field
                                            name={`vehicule_attache`}
                                            component={RadioFieldRedux}
                                            label={'Le véhicule était-il attaché au moment du vol ?'}
                                            data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                                            required
                                            onChange={() => {
                                                unsetValue('local');
                                                unsetValue('lieux_accident');
                                            }}
                                        />
                                    }

                                    {
                                        (contract.vehicule.codeProduit && _.includes(['7311_PVE', '7311_PVK', '7311_VAE'], contract.vehicule.codeProduit) && vol_violence && vol_violence === 'F') &&
                                        <>
                                            <Field
                                                name={`local`}
                                                component={RadioFieldRedux}
                                                label={'Le véhicule était-il stationné à son domicile dans un local privé et sécurisé entre 22h et 7h ?'}
                                                data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                                                required
                                                onChange={() => {
                                                    unsetValue('lieux_accident');
                                                }}
                                            />
                                            {
                                                (local && local === 'F') &&
                                                <>
                                                    <Field
                                                        name={`lieux_accident`}
                                                        component={RadioFieldRedux}
                                                        label={'Le client était-il en sortie ou à son domicile ?'}
                                                        data={[{value: "sortie", label: "En sortie"}, {value: "domicile", label: "A son domicile"}]}
                                                        required
                                                    />
                                                </>
                                            }
                                        </>
                                    }

                                    <Field
                                        name={`elements_voles`}
                                        component={RadioFieldRedux}
                                        label={'Il y a-t-il des éléments déclarés volés, autre que le véhicule ?'}
                                        data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                                        required
                                        onChange={() => clearEquipementsVoles()}
                                    />

                                    {
                                        (elements_voles && elements_voles === 'T') &&
                                        <div className={'row'}>
                                            <div className={'col-12 col-sm-6'}>
                                                <Field
                                                    name={'vol_casque'}
                                                    component={CheckboxFieldRedux}
                                                    label={'Casque'}
                                                    normalize={normalizeBooleanForNoobBelair}
                                                />
                                            </div>
                                            <div className={'col-12 col-sm-6'}>
                                                <Field
                                                    name={'vol_gants'}
                                                    component={CheckboxFieldRedux}
                                                    label={'Gants'}
                                                    normalize={normalizeBooleanForNoobBelair}
                                                />
                                            </div>
                                            <div className={'col-12 col-sm-6'}>
                                                <Field
                                                    name={'vol_blouson'}
                                                    component={CheckboxFieldRedux}
                                                    label={'Blouson'}
                                                    normalize={normalizeBooleanForNoobBelair}
                                                />
                                            </div>
                                            <div className={'col-12 col-sm-6'}>
                                                <Field
                                                    name={'vol_pantalon'}
                                                    component={CheckboxFieldRedux}
                                                    label={'Pantalon'}
                                                    normalize={normalizeBooleanForNoobBelair}
                                                />
                                            </div>
                                            <div className={'col-12 col-sm-6'}>
                                                <Field
                                                    name={'vol_carte_grise'}
                                                    component={CheckboxFieldRedux}
                                                    label={'Carte grise'}
                                                    normalize={normalizeBooleanForNoobBelair}
                                                />
                                            </div>
                                            <div className={'col-12 col-sm-6'}>
                                                <Field
                                                    name={'vol_antivol'}
                                                    component={CheckboxFieldRedux}
                                                    label={'Antivol'}
                                                    normalize={normalizeBooleanForNoobBelair}
                                                />
                                            </div>
                                            <div className={'col-12 col-sm-6'}>
                                                <Field
                                                    name={'vol_autres'}
                                                    component={CheckboxFieldRedux}
                                                    label={'Autres'}
                                                    normalize={normalizeBooleanForNoobBelair}
                                                />
                                            </div>
                                            {
                                                (vol_autres && vol_autres === 'T') &&
                                                <div className={'col-12'}>
                                                    <Field
                                                        name={'vol_autres_detail'}
                                                        component={TextFieldRedux}
                                                        label={false}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    }
                                </>
                            }

                            {loadingTerminate ? <Spinner/>
                                : <MyButton
                                    variant='raised'
                                    color='secondary'
                                    size='large'
                                    className={'btn btn-primary mt-5'}
                                    disabled={loadingProcessingPV}
                                >
                                    {loadingProcessingPV && <Spinner absolute/>}
                                    Valider
                                </MyButton>
                            }
                        </form>
                        : <div className={'header-row row'}>
                            <div className={'col-12'}>
                                <p className={'alert alert-warning'}>Le formulaire de saisie d’un PV est uniquement accessible pour les sinistres Vol total (code 10), Tentative de vol (code 105) et Volé et retrouvé (code 9)</p>
                            </div>
                        </div>
                    }
                </div>
            </div>

        </div>
    );
};

export default TachePv;