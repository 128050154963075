import React from 'react';
import SelectFieldRedux from "../../../../../Commun/Input/Select/SelectFieldRedux";
import {Field} from "redux-form";
import Spinner from "../../../../../Commun/Spinner/Spinner";
import {motifInsatisfactionFeedback} from "../../../../../../utils/optionsSelect";
import _ from 'lodash'
import MyButton from "../../../../../Commun/Button/Button";
import TextAreaFieldRedux from "../../../../../Commun/Input/Text/TextAreaFieldRedux";

const Feedback = (props) => {
    const {handleSubmit, loadingPost, loadingTerminate, changeValue} = props

    return (
        <div className={'container'}>
            <form className={'form-content'} onSubmit={handleSubmit}>
                <Field
                    name='insatisfaction_reason'
                    component={SelectFieldRedux}
                    label='Sélectionnez le motif d’insatisfaction :'
                    onChange={e => changeValue('insatisfaction_reason_label', _.find(motifInsatisfactionFeedback, ['code', e.target.value]).libelle)}
                    required={true}
                >
                    <option value={null}>Sélectionnez le motif d’insatisfaction</option>
                    {motifInsatisfactionFeedback.map((motif, index) => (
                        <option key={index} value={motif.code}>{motif.libelle}</option>
                    ))}
                </Field>

                <Field
                    name='commentaire'
                    component={TextAreaFieldRedux}
                    label={'Commentaire'}
                    required={true}
                />

                {loadingTerminate ? <Spinner/>
                    : <MyButton
                        variant='raised'
                        color='secondary'
                        size='large'
                        className={'btn btn-primary mt-5'}
                        disabled={loadingPost}
                    >
                        {loadingPost && <Spinner absolute/>}
                        Valider
                    </MyButton>
                }
            </form>
        </div>
    );
};

export default Feedback;
