import {connect} from 'react-redux';
import _ from 'lodash';
import AccuseReception from "./AccuseReception";
import {
    POST_ACCUSE_RECLAMATION,
    postAccuseReceptionReclamation
} from "../../../../../../redux/actions/app/reclamation/reclamation.actions";
import {complainReceiptLink} from "../../../../../../redux/selectors/links/links.selectors";
import {getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";

const mapStateToProps = (state, ownProps) => {
    const {id, ids} = ownProps

    return {
        query: _.replace(_.replace(complainReceiptLink(state), '{id}', id), '{ids}', ids),
        loading: getLoadingEntity(state, POST_ACCUSE_RECLAMATION)
    }
}

const mapDispatchToProps = {
    postAccuseReceptionReclamation
}

const AccuseReceptionContainer = connect(mapStateToProps, mapDispatchToProps)(AccuseReception)

export default AccuseReceptionContainer
