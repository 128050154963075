import {SET_DOCUMENTATION} from '../../actions/app/documentation/documentation.actions'

export const documentationReducer = (documentation = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_DOCUMENTATION) {
        return payload.data
    } else {
        return documentation
    }
}
