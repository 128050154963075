const validate = values => {
    const errors = {}

    const requiredFields = [
        'password',
        'password_confirmation'
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Le champ est obligatoire'
        }

        if (values['password'] && !/^.{6,}$/.test(values.password)) {
            errors.password = 'Le mot de passe doit contenir 12 caractères minimum'
        }

        // regex qui est uniquement les majuscules .*[A-Z].*
        if (values['password'] && !/.*[A-Z].*/.test(values.password)) {
            errors.password = 'Le mot de passe doit contenir au moins une majuscule'
        }
        // regex qui est uniquement les minuscules .*[a-z].*
        if (values['password'] && !/.*[a-z].*/.test(values.password)) {
            errors.password = 'Le mot de passe doit contenir au moins une minuscule'
        }
        // regex qui est uniquement les chiffres .*[0-9].*
        if (values['password'] && !/.*[0-9].*/.test(values.password)) {
            errors.password = 'Le mot de passe doit contenir au moins un chiffre'
        }

        if (values.password !== values.password_confirmation) {
            errors.password = "la confirmation du mot de passe n'est pas valide"
            errors.password_confirmation = "la confirmation du mot de passe n'est pas valide"
        }
    })

    return errors
}

export default validate
