import React from 'react';
import Logo from "../../../assets/img/logos/logo_light.png";

const Maintenance = () => {
    return (
        <div className={'login-page maintenance'}>
            <div className={'container'}>
                <div className={'row'}>
                    <div className={'logo-content'}>
                        <img src={Logo} alt="APRIL Moto" className={'img-responsive'}/>
                        <h1>Votre espace conseiller est en maintenance</h1>
                        <p>Cela signifie que de nouvelles fonctionnalités sont en train d’être déployées ! Ou que des
                            bugs vont être résolus …</p>
                        <p>Dans tous les cas, votre équipe informatique fait le maximum pour que votre espace soit
                            fonctionnel le plus rapidement possible.</p>
                        <h5>Nous vous souhaitons une bonne journée.</h5>
                        <a href="/" className={'btn btn-primary'}>Réessayer de me connecter</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Maintenance;
