import React from 'react'

const ErrorHttpComponent = () => {
    return (
        <div style={{margin: '10px auto', width: 300, padding: 10}}>
            <p>Une erreur HTTP est survenue, nos équipes techniques ont été averties.</p>
            <p>Merci de réessayer ultérieurement.</p>
        </div>
    )
}

export default ErrorHttpComponent
