const validate = values => {
    const errors = {}

    const requiredFields = [
        'nom',
        'prenom',
        'adresse',
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Le champ est obligatoire'
        }
    })

    // if (values['cp'] === null) {
    //     errors['postalcodecity-cp-ville'] = 'Ce champ est obligatoire'
    // }
    // if (values['ville'] === null) {
    //     errors['postalcodecity-cp-ville'] = 'Ce champ est obligatoire'
    // }


    return errors
}

export default validate
