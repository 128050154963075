import {Field} from "redux-form";
import MyButton from "../../../../Commun/Button/Button";
import Spinner from "../../../../Commun/Spinner/Spinner";
import SelectFieldRedux from "../../../../Commun/Input/Select/SelectFieldRedux";
import moment from "moment";
import DatePickerFieldRedux from "../../../../Commun/Input/DatePicker/DatePickerFieldRedux";

const TracfinForm = (props) => {
    const { tiers, handleSubmit, type_tracfin, loading } = props

    return (
        <div className={'contract-container container'}>
            <div className={'row'}>
                <div className={'col'}>
                    <h1 className={'title-primary'}>{tiers.belair_id} - {tiers.titre} {tiers.name}</h1>
                </div>
            </div>
            <div className={'row header-row'}>
                <div className={'col-12'}>
                    <form className={'form-content'} onSubmit={handleSubmit}>
                        <div className={'row'}>
                            <div className={'col-6'}>
                                <Field
                                    name='detection_tracfin'
                                    component={SelectFieldRedux}
                                    label={'Sélectionnez la détection correspondante'}
                                    required
                                >
                                    <option value={""}>Sélectionnez la détection</option>
                                    <option value={'GDA'}>GDA</option>
                                    <option value={'PPE'}>PPE</option>
                                </Field>
                            </div>
                            {type_tracfin === 'GDA' &&
                                <div className={'col-6'}>
                                    <Field
                                        name={"date_fin"}
                                        component={DatePickerFieldRedux}
                                        label={'Date de fin'}
                                        minDate={moment()}
                                        required
                                    />
                                </div>
                            }
                        </div>
                        <MyButton
                            variant='raised'
                            color='secondary'
                            size='large'
                            className={'btn btn-primary'}
                            disabled={loading}
                        >
                            {loading && <Spinner absolute/>}
                            Valider
                        </MyButton>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default TracfinForm
