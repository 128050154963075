import {connect} from 'react-redux'
import Page from './Page'
import {
    getUserIdBelair,
    getUserName,
    getUserPermissions,
    getUserRoles
} from '../../../redux/selectors/user/user.selectors'

const mapStateToProps = state => {
    return {
        idBelair: getUserIdBelair(state),
        nameConseiller: getUserName(state),
        permissions: getUserPermissions(state),
        roles: getUserRoles(state)
    }
}

const PageContainer = connect(mapStateToProps)(Page)

export default PageContainer
