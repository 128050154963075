import React from 'react';
import Spinner from "../../../../../Commun/Spinner/Spinner";

const AccuseReception = (props) => {
    const {postAccuseReceptionReclamation, query, loading} = props

    return (
        <>
            {
                loading ? <span className={'mx-5'}><Spinner/></span> :
                    <span data-test="post-accuse" className='btn btn-primary btn-inline-block mr-2'
                          onClick={() => postAccuseReceptionReclamation({query})}>
                Envoyer un accusé de réception
            </span>
            }
        </>
    );
};

export default AccuseReception;
