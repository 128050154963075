import {Fragment, Component} from 'react'
import SearchFieldContainer from "./SearchFieldContainer";

class Search extends Component {
    constructor(props) {
        super(props)
        this.state = {
            search: false,
        }
        this.toggleSearch = this.toggleSearch.bind(this)
    }

    toggleSearch() {
        this.setState({search: !this.state.search})
    }

    render() {
        const searchQuery = this.props.location.search.split('=')[1]

        return (
            <Fragment>
                {searchQuery && <SearchFieldContainer search={searchQuery} toggleSearch={this.toggleSearch}/>}
            </Fragment>
        )
    }
}

export default Search