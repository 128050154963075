import React from 'react'
import moment from "moment";
import Badge from "../../../../../Commun/Badge/Badge";
import {Link} from "react-router-dom";

const FlotteList = (props) => {
    const {flotte, contractId} = props

    return (
        <div className={'content row'}>
            {flotte.length >= 1 ?
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">Numéro adhesion</th>
                        <th scope="col">Marque, Modèle</th>
                        <th scope="col">Immatriculation</th>
                        <th scope="col">Mise en circulation</th>
                        <th scope="col">Actif / Inactif</th>
                        <th scope="col">Détails</th>
                    </tr>
                    </thead>
                    <tbody>
                    {flotte.map((v, index) =>
                        <tr key={index}>
                            <td>{v.adhesion}</td>
                            <td>{v.marque} {v.modele}</td>
                            <td><b>{v.immatriculation ? v.immatriculation : '-'}</b></td>
                            <td>{v.dateMEC ? moment(v.dateMEC).format('L') : '-'}</td>
                            <td><Badge status={v.inactif ? 'Inactif' : 'Actif'} noText/></td>
                            <td>
                                <Link to={{pathname: `${contractId}/vehicule/${v.adhesion}`}}
                                      className={'btn btn-more'}/>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
                :
                <p className={'no-result'}>Il n'y a pas de sinistre déclaré sur le contrat</p>
            }
        </div>
    )
}

export default FlotteList
