import {createSelector} from 'reselect'
import _ from "lodash";
import moment from "moment";

const accessoiresSelector = state => state.accessoires
const accessoireSelector = state => state.accessoire

export const getAccessoiresData = createSelector(
    accessoiresSelector,
    accessoires => accessoires.data || {}
)
export const getAccessoireDetailData = createSelector(
    accessoireSelector,
    accessoire => accessoire.data || {}
)

export const getAccessoiresReparation = createSelector(
    getAccessoiresData,
    data => _.filter(data, ['libelle', 'reparation'])
)

export const getAccessoiresAntivol = createSelector(
    getAccessoiresData,
    data => _.filter(data, ['libelle', 'antivol'])
)

export const getAccessoiresVehicule = createSelector(
    getAccessoiresData,
    data => _.filter(data, ['libelle', 'vehicule'])
)

export const getAccessoiresVehiculeMontant = createSelector(
    getAccessoiresData,
    data => {
        const vehiculeItem = _.find(data, { 'libelle': 'vehicule' });
        if (vehiculeItem) {
            return moment(vehiculeItem.date_achat);
        }
        return null;
    }
);

