export const QUOTE = '[Quote]'
export const QUOTES = '[Quotes]'


export const FETCH_QUOTES = `${QUOTES} Fetch`
export const SET_QUOTES = `${QUOTES} Set`
export const FETCH_QUOTE = `${QUOTE} Fetch`
export const SET_QUOTE = `${QUOTE} Set`
export const CANCEL_QUOTE = `${QUOTE} Cancel`

export const fetchQuotes = ({query, filters}) => ({
    type: FETCH_QUOTES,
    payload: {
        data: query,
        filters
    }
})
export const setQuotes = ({data}) => ({
    type: SET_QUOTES,
    payload: {
        data
    }
})

export const fetchQuote = ({query, filters}) => ({
    type: FETCH_QUOTE,
    payload: {
        data: query,
        filters
    }
})
export const setQuote = ({data}) => ({
    type: SET_QUOTE,
    payload: {
        data
    }
})
export const cancelQuote = ({query}) => ({
    type: CANCEL_QUOTE,
    payload: {
        data: query
    }
})
