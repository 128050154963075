import React, {Fragment, Component} from 'react';
import {getCity, getCityBySearch} from "../../../utils/function";
import {Field} from "redux-form";
import TextFieldRedux from "../Input/Text/TextFieldRedux";
import _ from 'lodash'
import './postalCodeCity.scss'

class PostalCodeCity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            city: [],
            selected: false,
            labelSelected: this.props.defaultValue || this.props.myDefaultValue
        };
    }

    async fetchCityByPostalCode(value) {
        let cities = await getCity(value)
        let obj = this.formatObj({codePostal: value, villes: cities})
        this.setState({
            city: obj
        })
    }

    async fetchCityBySearch(value) {
        let cities = await getCityBySearch(value)
        this.setState({
            city: cities
        })
    }

    formatObj({codePostal, villes}) {
        let data = []
        _.forEach(villes, ville => {
            data.push({ville, codePostal})
        })
        return data
    }

    getCity(value) {
        this.setState({
            selected: false
        })
        if (_.parseInt(value)) {
            value.length === 5 && this.fetchCityByPostalCode(value)
        } else {
            this.fetchCityBySearch(value)
        }
    }

    setCityAndPostalCode(city) {
        if (this.props.nameVille) {
            this.props.changeValue(this.props.nameVille, city.ville)
        }
        if (this.props.nameCodePostal) {
            this.props.changeValue(this.props.nameCodePostal, city.codePostal)
        }
        this.setState({
            selected: true,
            labelSelected: `${city.ville} ${city.codePostal}`
        })
    }

    render() {
        const {city, selected, labelSelected} = this.state
        const {label, id, disabled} = this.props

        return (
            <Fragment>
                <div className="row">
                    <div className="col">
                        <Field
                            name={`postalcodecity${id ? `-${id}` : ''}`}
                            component={TextFieldRedux}
                            label={label}
                            disabled={disabled}
                            input={{
                                value: labelSelected,
                                onChange: (e) => e.target.value.length >= 3 && this.getCity(e.target.value),
                                onFocus: () => this.setState({labelSelected: null})
                            }}
                        />
                    </div>
                </div>
                {
                    !selected && <div className="row">
                        <div className="col form-group">
                            <ul className={'postal-code-city'}>
                                {
                                    city.map((city, index) =>
                                        <li key={index} value={city.ville}
                                            onClick={() => this.setCityAndPostalCode(city)}>{city.ville} {city.codePostal}</li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                }
            </Fragment>

        );
    }
}

export default PostalCodeCity;
