import {connect} from 'react-redux'
import {settingsLink, updateSettingsLink} from "../../../../redux/selectors/links/links.selectors";
import {bindActionCreators, compose} from "redux";
import TraitementCheckdoc from "./TraitementCheckdoc";
import {getSettingsData} from "../../../../redux/selectors/settings/settings.selector";
import {getSettings, postUpdateSettings} from "../../../../redux/actions/app/settings/settings.actions";

import ErrorHttpComponent from "../../../Commun/Error/ErrorHttpComponent";
import loader from "../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    return {
        settingRes: getSettingsData(state),
        query: settingsLink(state),
        queryUpdate: updateSettingsLink(state)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getSettings,
    postUpdateSettings
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getSettings, postUpdateSettings} = dispatchProps
    const {query, queryUpdate} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getSettings({query}),
        submitUpdateSettings: () => postUpdateSettings({query: queryUpdate})
    }
}

const TraitementCheckdocContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(TraitementCheckdoc)

export default TraitementCheckdocContainer
