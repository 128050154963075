import {connect} from 'react-redux'
import ActivitiesList from "./ActivitiesList";
import {bindActionCreators, compose} from "redux";
import {ACTIVITIES, getActivities, postCloseActivity} from "../../../redux/actions/app/grc/grc.actions";
import {
    accidentGetLink,
    activitiesAccidentGetLink,
    activitiesContractGetLink,
    activitiesTiersGetLink,
    contractGetLink, tiersGetLink,
    closeActivityGetLink, complainActivityLink
} from "../../../redux/selectors/links/links.selectors";
import {getErrorEntity, getLoadingEntity} from "../../../redux/selectors/ui/ui.selectors";
import _ from "lodash";

import ErrorHttpComponent from "../Error/ErrorHttpComponent";
import {getActivitiesData} from "../../../redux/selectors/grc/grc.selectors";
import {getTiers, TIERS} from "../../../redux/actions/app/tiers/tiers.actions";
import {ACCIDENT, getAccident} from "../../../redux/actions/app/accidents/accidents.actions";
import {CONTRACT, getContract} from "../../../redux/actions/app/contracts/contracts.actions";
import {getContractData} from "../../../redux/selectors/contract/contract.selectors";
import {getTiersData} from "../../../redux/selectors/tiers/tiers.selectors";
import {getAccidentData} from "../../../redux/selectors/accident/accident.selectors";
import {setLocalStorage} from "../../../redux/actions/core/localStorage/localStorage.actions";
import {logAction} from "../../../redux/actions/core/logs/logs.actions";
import {getUserRoles} from "../../../redux/selectors/user/user.selectors";
import loader from "../Spinner/Loader/Loader";

const getLoaded = (state, type) => {
    switch (type) {
        case 'tiers':
        case 'reclamation':
            return getLoadingEntity(state, ACTIVITIES) === false && getLoadingEntity(state, TIERS) === false
        case 'contrat':
            return getLoadingEntity(state, CONTRACT) === false && getLoadingEntity(state, ACTIVITIES) === false
        case 'accident':
            return getLoadingEntity(state, CONTRACT) === false && getLoadingEntity(state, ACTIVITIES) === false && getLoadingEntity(state, ACCIDENT) === false
        default:
            return getLoadingEntity(state, ACTIVITIES) === false
    }
}

const getUriActivity = (state, params) => {
    const {type, firstId, secondId} = params

    switch (type) {
        case 'tiers':
            return _.replace(activitiesTiersGetLink(state), '{id}', firstId)
        case 'contrat':
            return activitiesContractGetLink(state)
        case 'accident':
            return _.replace(_.replace(activitiesAccidentGetLink(state), '{id}', secondId), '{ids}', firstId)
        case 'reclamation':
            return _.replace(_.replace(complainActivityLink(state), '{id}', secondId), '{ids}', firstId)
        default:
            return _.replace(activitiesTiersGetLink(state), '{id}', firstId)
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        currentId: ownProps.match.params.firstId,
        uriActivity: getUriActivity(state, ownProps.match.params),
        uriContrat: contractGetLink(state),
        uriAccident: accidentGetLink(state),
        uriTiers: tiersGetLink(state),
        uriClose: closeActivityGetLink(state),
        loaded: getLoaded(state, ownProps.match.params.type),
        error: getErrorEntity(state, ACTIVITIES),
        activities: getActivitiesData(state),
        type: ownProps.match.params.type,
        contract: getContractData(state),
        tiers: getTiersData(state),
        accident: getAccidentData(state),
        roles: getUserRoles(state)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getActivities: ({query, type, filters}) => getActivities({query, type, filters}),
    getTiers: ({query, id}) => getTiers({query, id}),
    getAccident: (query) => getAccident({query}),
    getContract: (query) => getContract({query}),
    setLocalStorage: (storage) => setLocalStorage({entity: ACTIVITIES, storage, name: 'GRC_activity'}),
    logAction: ({contrat, tiers, sinistre, type}) => logAction({entity: ACTIVITIES, type, contrat, tiers, sinistre}),
    postCloseActivity: (query) => postCloseActivity({query}),
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getActivities, getContract, getAccident, getTiers, logAction} = dispatchProps
    const {uriActivity, uriContrat, uriAccident, uriTiers} = stateProps
    const {type, firstId, secondId} = ownProps.match.params

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => {
            switch (type) {
                case 'tiers':
                    return (
                        getTiers({query: (_.replace(uriTiers, '{id}', firstId)), id: firstId}),
                        logAction({type: 'SHOW_ACTIVITY', tiers: firstId}),
                        getActivities({
                            query: uriActivity, type, filters: { }
                        })
                    )
                case 'contrat':
                    return (
                        getContract(_.replace(uriContrat, '{id}', firstId)),
                        logAction({type: 'SHOW_ACTIVITY', contrat: firstId}),
                        getActivities({
                            query: uriActivity, type, filters: {
                                doc_nature_contract_DE: localStorage.getItem('task_doc_nature_contract_DE') || '',
                                doc_type_contract_FID: localStorage.getItem('task_doc_type_contract_FID') || '',
                                doc_interlocuteur_contract_AN: localStorage.getItem('task_doc_interlocuteur_contract_AN') || ''
                            }
                        })
                    )
                case 'accident':
                    return (
                        getAccident(_.replace(_.replace(uriAccident, '{id}', secondId), '{ids}', firstId)),
                        getContract(_.replace(uriContrat, '{id}', secondId)),
                        logAction({type: 'SHOW_ACTIVITY', sinistre: firstId, contrat: secondId}),
                        getActivities({
                            query: uriActivity, type, filters: {
                                a_traiter: localStorage.getItem('task_a_traiter') || '',
                                doc_nature_CT: localStorage.getItem('task_doc_nature_CT') || '',
                                doc_nature_RX: localStorage.getItem('task_doc_nature_RX') || '',
                                doc_nature_FA: localStorage.getItem('task_doc_nature_FA') || '',
                                doc_nature_PV: localStorage.getItem('task_doc_nature_PV') || '',
                                doc_nature_PW: localStorage.getItem('task_doc_nature_PW') || ''
                            }
                        })
                    )
                case 'reclamation':
                    return (
                        getTiers({query: (_.replace(uriTiers, '{id}', secondId)), id: secondId}),
                        logAction({type: 'SHOW_ACTIVITY', reclamation: firstId}),
                        getActivities({
                            query: uriActivity, type, filters: { }
                        })
                    )
                default:
                    return (
                        getTiers({query: (_.replace(uriTiers, '{id}', firstId)), id: firstId}),
                        getActivities({
                            query: uriActivity, type, filters: { }
                        })
                    )
            }
        }
    }
}

const ActivitiesListContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(ActivitiesList)

export default ActivitiesListContainer
