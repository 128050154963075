export const DASHBOARD_TLV = '[Dashboard Télévente]'
export const DASHBOARD_TLV_GEST = '[Dashboard Télévente Gestionnaires]'
export const DASHBOARD_TASK_TLV = '[Dashboard tâche Télévente]'

export const GET_DASHBOARD_TLV = `${DASHBOARD_TLV} Get`
export const SET_DASHBOARD_TLV = `${DASHBOARD_TLV} Set`

export const GET_DASHBOARD_TLV_GEST = `${DASHBOARD_TLV_GEST} Get`
export const SET_DASHBOARD_TLV_GEST = `${DASHBOARD_TLV_GEST} Set`

export const GET_DASHBOARD_TASK_TLV = `${DASHBOARD_TASK_TLV} Get`
export const SET_DASHBOARD_TASK_TLV = `${DASHBOARD_TASK_TLV} Set`

export const getDashboardTelevente = ({query, sort}) => ({
    type: GET_DASHBOARD_TLV,
    payload: {
        data: query,
        filters: {
            sort
        }
    }
})

export const setDashboardTelevente = ({data, meta, links}) => ({
    type: SET_DASHBOARD_TLV,
    payload: {data, meta, links}
})

export const getGestionnairesDashboardTelevente = ({query}) => ({
    type: GET_DASHBOARD_TLV_GEST,
    payload: {
        data: query
    }
})

export const setGestionnairesDashboardTelevente = ({gestionnaires}) => ({
    type: SET_DASHBOARD_TLV_GEST,
    payload: {
        data: gestionnaires.data
    }
})

export const getDashboardTeleventeTaskDetails = ({query, task_id}) => ({
    type: GET_DASHBOARD_TASK_TLV,
    payload: {
        data: query,
        otherData: {
            task_id: task_id
        }
    }
})

export const setDashboardTeleventeTaskDetails = ({task, task_id}) => ({
    type: SET_DASHBOARD_TASK_TLV,
    payload: {
        data: task.data,
        task_id: task_id
    }
})