import _ from 'lodash'

const checkDefaultGarantie = (props) => {
    const {codegta, responsabilite, garantiesContrat, changeValue, blesse, adversaires} = props

    /* Reset des coches */
    _.map(garantiesContrat, (g, i) => {
        changeValue(`garanties[${i}][${g.code}]`, false)
    })

    /* Coches communes */
    const checkGarantieCommune = () => {
        /* Garanties coché quoi qu'il arrive */
        const codeGarantieCommuneArray = ['ICP', 'ACCEQ', 'EQ', 'RFDTA', 'VALMAJ']

        /* Garanties sous condition de blesse === F */
        const codeGarantieCommuneConditionBlesse = ['PCC0', 'PCC1', 'PCC2', 'PCC3', 'IC']

        _.map(codeGarantieCommuneArray, code => {
            _.findIndex(garantiesContrat, ['code', code]) >= 0 &&
            changeValue(`garanties[${_.findIndex(garantiesContrat, ['code', code])}][${code}]`, true)
        })

        if (blesse === 'F') {
            _.map(codeGarantieCommuneConditionBlesse, code => {
                _.findIndex(garantiesContrat, ['code', code]) >= 0 &&
                changeValue(`garanties[${_.findIndex(garantiesContrat, ['code', code])}][${code}]`, false)
            })
        } else {
            _.map(codeGarantieCommuneConditionBlesse, code => {
                _.findIndex(garantiesContrat, ['code', code]) >= 0 &&
                changeValue(`garanties[${_.findIndex(garantiesContrat, ['code', code])}][${code}]`, true)
            })
        }
    }

    /*
        Les règles qui s'appliquent sur la DA, la DR et la RC
        doivent être les mêmes pour SCRC, SCDR, SCDA et PVRC PVRCC, RC2, RC35, PVRCM PCDR et PVDA
        DA = SCDA = PVDA
        RC = SCRC = PVRC = PVRCC = PVRCM = RC2 = RC35
        DR = SCDR = PVDR
    */

    const findDA = () => {
        /* DA = SCDA = PVDA */
        let garantie = _.find(garantiesContrat, ['code', 'DA']) || _.find(garantiesContrat, ['code', 'SCDA']) || _.find(garantiesContrat, ['code', 'PVDA'])

        return garantie ? garantie.code : undefined
    }

    const findRC = () => {
        /* RC = SRC = PVRC = PVRCC = PVRCM = RC2 = RC35 */
        let garantie = _.find(garantiesContrat, ['code', 'RC']) ||
            _.find(garantiesContrat, ['code', 'SCRC']) ||
            _.find(garantiesContrat, ['code', 'PVRC']) ||
            _.find(garantiesContrat, ['code', 'PVRCC']) ||
            _.find(garantiesContrat, ['code', 'PVRCM']) ||
            _.find(garantiesContrat, ['code', 'RC2']) ||
            _.find(garantiesContrat, ['code', 'RC35'])

        return garantie ? garantie.code : undefined
    }

    const findDR = () => {
        /* DR = SCDR = PVDR */
        let garantie = _.find(garantiesContrat, ['code', 'DR']) || _.find(garantiesContrat, ['code', 'SCDR']) || _.find(garantiesContrat, ['code', 'PVDR'])

        return garantie ? garantie.code : undefined
    }

    switch (responsabilite) {
        case 0:
        case '0':
            /* Si Hors convention */
            if (codegta === undefined || codegta === "") {
                if (findDA() !== undefined && _.find(garantiesContrat, ['code', findDA()])) {
                    changeValue(`garanties[${_.findIndex(garantiesContrat, ['code', findDA()])}][${findDA()}]`, true)
                } else {
                    changeValue(`garanties[${_.findIndex(garantiesContrat, ['code', findDR()])}][${findDR()}]`, true)
                }

                checkGarantieCommune()
            } else {
                /* Si en Convention */
                changeValue(`garanties[${_.findIndex(garantiesContrat, ['code', findDR()])}][${findDR()}]`, true)
            }
            break;
        case 50:
        case '50':
            /* Priorité à la DTA sinon Défense Recours */
            if (findDA() !== undefined && _.find(garantiesContrat, ['code', findDA()])) {
                changeValue(`garanties[${_.findIndex(garantiesContrat, ['code', findDA()])}][${findDA()}]`, true)
            } else {
                changeValue(`garanties[${_.findIndex(garantiesContrat, ['code', findDR()])}][${findDR()}]`, true)
            }

            /* Si présence d'un tiers on coche RC */
            if (adversaires?.length > 0){
                changeValue(`garanties[${_.findIndex(garantiesContrat, ['code', findRC()])}][${findRC()}]`, true)
            }

            checkGarantieCommune()
            break;
        case 100:
        case '100':
            if (findDA() !== undefined && _.find(garantiesContrat, ['code', findDA()])) {
                changeValue(`garanties[${_.findIndex(garantiesContrat, ['code', findDA()])}][${findDA()}]`, true)
            }

            /* Si présence d'un tiers on coche RC */
            if (adversaires?.length > 0){
                changeValue(`garanties[${_.findIndex(garantiesContrat, ['code', findRC()])}][${findRC()}]`, true)
            }

            checkGarantieCommune()
            break;
        default:
            return false;
    }
};

export default checkDefaultGarantie;
