import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {FICHE, GET_FICHE, setFiche} from "../../../actions/app/fiche/fiche.actions";

export const ficheMiddleware = () => next => action => {
    next(action)

    const {payload} = action

    switch (action.type) {
        case GET_FICHE:
            next([
                apiRequest({method: 'GET', url: payload.data, entity: FICHE}),
                setLoader({state: true, entity: FICHE})
            ])
            break

        case `${FICHE} ${API_SUCCESS}`:
            next([
                setFiche({data: payload.data}),
                setError({state: false, entity: FICHE}),
                setLoader({state: false, entity: FICHE})
            ])
            break

        case `${FICHE} ${API_ERROR}`:
            next([
                setError({state: true, entity: FICHE}),
                setLoader({state: false, entity: FICHE})
            ])
            break

        default:
            break
    }
    return null
}
