import React, {Component, Fragment, useEffect, useState} from 'react';
import TextFieldRedux from "../../Input/Text/TextFieldRedux";
import {Field} from "redux-form";
import SelectFieldRedux from "../../Input/Select/SelectFieldRedux";
import {getCity, getCountries} from "../../../../utils/function";
import DatePickerFieldRedux from "../../Input/DatePicker/DatePickerFieldRedux";
import _ from "lodash";
import AutoComplete from "../../Input/Autocomplete/Autocomplete";
import RadioFieldRedux from "../../Input/Radio/RadioFieldRedux";
import moment from "moment/moment";
import {tiersPayeurRelation} from "../../../../utils/optionsSelect";

class ChangeTitulaire extends Component {
    constructor(props) {
        super(props)
        this.state = {
            changeTitulaire: false,
            listCountries: false,
            city: [],
            type: null,
            titulaire: null,
            relationship: null,
        }
    }

    handleChangeTitulaire() {
        this.setState({
            changeTitulaire: !this.state.changeTitulaire
        })

        if(this.state.changeTitulaire){
            this.setState({
                type: null,
                relationship: null,
            })

            this.props.changeValue('titulaire', null)
            this.props.changeValue('type', null)
            this.props.changeValue('firstname', null)
            this.props.changeValue('lastname', null)
            this.props.changeValue('birthname', null)
            this.props.changeValue('birthdate', null)
            this.props.changeValue('birthcity', null)
            this.props.changeValue('birthcountry', null)
            this.props.changeValue('relationship', null)
            this.props.changeValue('third_payeur_reason', null)
            this.props.changeValue('adresse', null)
            this.props.changeValue('cp', null)
            this.props.changeValue('ville', null)
        }
    }

    handleChangeTitulaireField(e) {
        if(this.props.contract_id){
            this.setState({ titulaire: e })
            if(!e || _.toUpper(_.trim(this.props.souscripteur)) === _.toUpper(_.trim(e))){
                this.handleChangeType(null);
                this.clearFieldsThirdPartyPayer();
            } else {
                this.props.changeValue('contract', this.props.contract_id)
            }
        }
    }

    handleChangeRelation(e) {
        this.setState({
            relationship: e,
        })
        if(e !== 'A') {
            this.props.changeValue('third_payeur_reason', null)
        }
    }

    handleChangeType(e) {
        this.setState({ type: e  })
        this.clearFieldsThirdPartyPayer(e);
    }

    clearFieldsThirdPartyPayer(type = 'all'){
        if(type === "P" || type === 'all'){
            this.props.changeValue('birthdate', moment().subtract(14, 'year'))
        }

        if(type === "M"|| type === 'all'){
            this.props.changeValue('firstname', null)
            this.props.changeValue('birthname', null)
            this.props.changeValue('birthdate', null)
            this.props.changeValue('birthcountry', null)
            this.props.changeValue('birthcity', null)
            this.props.changeValue('relationship', null)
            this.props.changeValue('third_payeur_reason', null)
        }

        if(type === 'all'){
            this.props.changeValue('type', null)
            this.props.changeValue('lastname', null)
            this.props.changeValue('contract', null)
        }
    }


    async fetchCityByPostalCode(value) {
        let cities = await getCity(value)
        this.setState({
            city: cities
        })
        let city = cities.length === 1 ? cities[0] : null
        this.props.changeValue('ville', city)
    }

    async fetchCountries() {
        let countries = await getCountries()
        let list = _.orderBy(countries, ['name'], ['asc'])

        list = list.map(c => ({
            value: c.id,
            label: c.name
        }))

        this.setState({
            listCountries: list
        })
    }

    componentDidMount() {
        if (!this.state.listCountries) {
            this.fetchCountries()
        }
    }

    render() {
        const {souscripteur, contract_id } = this.props
        const {city, changeTitulaire, listCountries, type, relationship, titulaire} = this.state

        return (
            <Fragment>
                {changeTitulaire &&
                    <Fragment>
                        <Field
                            name='titulaire'
                            component={TextFieldRedux}
                            label={`Titulaire du compte`}
                            normalize={v => v.toUpperCase()}
                            maxLength={50}
                            onChange={(e) => this.handleChangeTitulaireField(e.target.value)}
                            required
                        />
                        {/*On ne peut lier un tiers payeur qu'à un contrat*/}
                        { contract_id && titulaire && _.toUpper(_.trim(souscripteur)) !== _.toUpper(_.trim(titulaire)) ?
                            <>
                                <Field
                                    name='type'
                                    component={RadioFieldRedux}
                                    label={'Type de tiers payeur'}
                                    data={[{value: "P", label: "Physique"}, {value: "M", label: "Morale"}]}
                                    onChange={(e) => this.handleChangeType(e.target.value)}
                                    required
                                />
                                {type &&
                                    <>
                                        <Field
                                            name='lastname'
                                            component={TextFieldRedux}
                                            label={`${type === 'P' ? 'Nom du' : 'Nom de la société'} titulaire du compte`}
                                            normalize={v => v.toUpperCase()}
                                            maxLength={50}
                                            required
                                        />

                                        {type === 'P' &&
                                            <>
                                                <Field
                                                    name={'birthname'}
                                                    component={TextFieldRedux}
                                                    label={'Nom de naissance du titulaire du compte'}
                                                    normalize={v => v.toUpperCase()}
                                                    maxLength={20}
                                                    required
                                                />
                                                
                                                <Field
                                                    name='firstname'
                                                    component={TextFieldRedux}
                                                    label={'Prénom du titulaire du compte'}
                                                    normalize={v => v.toUpperCase()}
                                                    maxLength={20}
                                                    required
                                                />

                                                <Field
                                                    name={"birthdate"}
                                                    component={DatePickerFieldRedux}
                                                    label={'Date de naissance du titulaire du compte'}
                                                    maxDate={moment().subtract(14, 'Y').format('YYYY-MM-DD')}
                                                    required
                                                />

                                                <Field
                                                    name={'birthcity'}
                                                    component={TextFieldRedux}
                                                    label={'Ville de naissance'}
                                                    required
                                                />

                                                <Field
                                                    name={'birthcountry'}
                                                    component={AutoComplete}
                                                    label="Pays de naissance"
                                                    data={listCountries}
                                                    required
                                                />

                                                <Field
                                                    name='relationship'
                                                    component={SelectFieldRedux}
                                                    label={'Lien souscripteur / payeur'}
                                                    onChange={(e) => this.handleChangeRelation(e.target.value)}
                                                >
                                                    <option value={""}>Sélectionnez une relation</option>
                                                    {tiersPayeurRelation.map((relation, index) => (
                                                        <option key={index}
                                                                value={relation.code}>{relation.libelle}</option>
                                                    ))}
                                                </Field>

                                                {
                                                    relationship && relationship === 'A' ?
                                                        <Field
                                                            name={'third_payeur_reason'}
                                                            component={TextFieldRedux}
                                                            label={'Motif d\'intervention du payeur'}
                                                            maxLength={250}
                                                        /> : null
                                                }
                                            </>
                                        }
                                    </>
                                }
                            </> : null
                        }


                        <Field
                            name='adresse'
                            component={TextFieldRedux}
                            label={'Adresse du titulaire du compte'}
                        />

                        <Field
                            name='cp'
                            component={TextFieldRedux}
                            label={'Code postal'}
                            input={{
                                maxLength: '5',
                                onChange: (e) => e.target.value.length === 5 && this.fetchCityByPostalCode(e.target.value)
                            }}
                        />

                        <Field
                            name='ville'
                            component={SelectFieldRedux}
                            label={'Ville'}
                            disabled={city.length === 0}
                        >
                            {
                                city.map((city, index) =>
                                    <option key={index} value={city}>{city}</option>
                                )
                            }
                        </Field>
                    </Fragment>
                }

                <a
                    onClick={() => this.handleChangeTitulaire()}
                    className={'pointer d-block mb-3'}
                >
                    {changeTitulaire ? 'Ne pas changer le titulaire' : 'Changer le titulaire du compte'}
                </a>
            </Fragment>
        );
    }
}

export default ChangeTitulaire;
