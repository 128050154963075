import React from 'react';
import moment from "moment/moment";

const FeedbackNote = (props) => {
    const { feedback, accidentFeedback } = props;
    const feedbackItems = [];

    if (accidentFeedback) {
        const { feedback_date, note } = accidentFeedback;

        if (feedback_date) {
            feedbackItems.push(
                <p key="accidentFeedback">
                    Moment de vie du {moment(accidentFeedback.feedback_date).format('DD/MM/YYYY')} : {note}/10
                </p>
            );
        }
    }

    if (feedback && feedback.length > 0) {
        const feedbackWithService = feedback.filter(item => item.service !== null);

        if (feedbackWithService.length > 0) {
            const firstFeedbackWithService = feedbackWithService[0];

            feedbackItems.push(
                <p key="feedbackWithService">
                    Feedback à chaud du {moment(firstFeedbackWithService.feedback_date).format('DD/MM/YYYY')} : {firstFeedbackWithService.note}/10
                </p>
            );
        }
    }

    return feedbackItems.length > 0 ? feedbackItems : null;
};

export default FeedbackNote;
