import {apiMiddleware} from './api/api.middleware'
import {actionSplitterMiddleware} from './actionSplitter/actionSplitter'
import {normalizeMiddleware} from './normalize/normalize.middleware'
import {localStorageMiddleware} from './localStorage/localStorage.middleware'
import {analyticsMiddleware} from './analytics/analytics.middleware'
import {reduxFormMiddleware} from './reduxForm/reduxForm.middleware'
import {notificationsMiddleware} from './notifications/notifications.middleware'
import {logsMiddleware} from './logs/logs.middleware'

export const coreMiddleware = [
    actionSplitterMiddleware,
    apiMiddleware,
    normalizeMiddleware,
    reduxFormMiddleware,
    localStorageMiddleware,
    analyticsMiddleware,
    notificationsMiddleware,
    logsMiddleware
]
