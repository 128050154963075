import {SET_FICHE} from "../../actions/app/fiche/fiche.actions";

export const ficheReducer = (fiche = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_FICHE) {
        return payload.data
    } else {
        return fiche;
    }
}