export const NAVIGATION_CONTRACT = '[Navigation Contrat]'

export const GET_NAVIGATION_CONTRACT = `${NAVIGATION_CONTRACT} Get`
export const SET_NAVIGATION_CONTRACT = `${NAVIGATION_CONTRACT} Set`

export const getNavigationContract = ({query}) => ({
    type: GET_NAVIGATION_CONTRACT,
    payload: {
        data: query
    }
})

export const setNavigationContract = ({data}) => ({
    type: SET_NAVIGATION_CONTRACT,
    payload: {data},
})
