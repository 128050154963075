import {connect} from 'react-redux'
import {compose} from "redux";
import Penalites from "./Penalites";
import {deletePenaltiesAccidentGetLink} from "../../../../../redux/selectors/links/links.selectors";
import _ from "lodash";
import {postDeletePenalties} from "../../../../../redux/actions/app/accidents/accidents.actions";

const mapStateToProps = (state, ownProps) => {
    return {
        uri_delete: deletePenaltiesAccidentGetLink(state),
        accidentId: ownProps.accident,
        contractId: ownProps.contract,
    }
}

const mapDispatchToProps = {
    postDeletePenalties,
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uri_delete, contractId, accidentId } = stateProps
    const { postDeletePenalties } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        submitDeletePenalties: () => postDeletePenalties({query: _.replace(_.replace(uri_delete, '{id}', contractId), '{ids}', accidentId)}),
    }
}

const PenalitesContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(Penalites)

export default PenalitesContainer