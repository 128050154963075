import {Field} from "redux-form";
import TextFieldRedux from "../../../../Commun/Input/Text/TextFieldRedux";
import MyButton from "../../../../Commun/Button/Button";
import Spinner from "../../../../Commun/Spinner/Spinner";
import React, {Component, Fragment} from "react";
import SelectFieldRedux from "../../../../Commun/Input/Select/SelectFieldRedux";
import {waitingDocsContract, waitingDocsSinistres} from "../../../../../utils/optionsSelect";

class AddDocument extends Component {
    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit(form) {
        let dataForm = new FormData()
        let jsonForm = {}
        dataForm.append('pieces[0][codeatt]', form.codeatt)
        form.commentaire && dataForm.append('pieces[0][commentaire]', form.commentaire)

        for (const [key, value] of dataForm.entries()) {
            jsonForm[key] = value;
        }
        this.props.onSubmit(jsonForm)
    }

    render() {
        const {loading, handleSubmit, type} = this.props

        return (
            <form onSubmit={handleSubmit(this.handleSubmit)} className={'small-form'}>
                <Field
                    name='codeatt'
                    ref={this.input}
                    component={SelectFieldRedux}
                    label='Type de document'
                    placeholder='Type de document'
                >
                    <option value={null}>Autres</option>
                    {type === 'sinistre'
                        ? <Fragment>
                            {waitingDocsSinistres.map((doc, index) => (
                                <option key={index} value={doc.code}>{doc.libelle}</option>
                            ))}
                        </Fragment>
                        : <Fragment>
                            {waitingDocsContract.map((doc, index) => (
                                <option key={index} value={doc.code}>{doc.libelle}</option>
                            ))}
                        </Fragment>
                    }
                </Field>
                <Field
                    name='commentaire'
                    ref='commentaire'
                    component={TextFieldRedux}
                    label={'Commentaire'}
                    placeholder='Commentaire (facultatif)'
                />
                <MyButton
                    variant='raised'
                    color='secondary'
                    size='large'
                    className={'btn btn-primary btn-small float-right'}
                    disabled={loading}
                >
                    {loading && <Spinner absolute/>}
                    Ajouter le document
                </MyButton>
            </form>
        )
    }
}

export default AddDocument
