import React from 'react';
import {getBoolean} from "../../../../utils/function";

const ActivitiesListAccidentFilters = (props) => {
    const {uriActivity, type, getActivities} = props
    const aTraiter = getBoolean(localStorage.getItem('task_a_traiter')),
        docNatureCT = getBoolean(localStorage.getItem('task_doc_nature_CT')),
        docNatureRX = getBoolean(localStorage.getItem('task_doc_nature_RX')),
        docNatureFA = getBoolean(localStorage.getItem('task_doc_nature_FA')),
        docNaturePV = getBoolean(localStorage.getItem('task_doc_nature_PV')),
        docNaturePW = getBoolean(localStorage.getItem('task_doc_nature_PW')),
        docNatureBD = getBoolean(localStorage.getItem('task_doc_nature_BD')),
        docNatureTous = getBoolean(localStorage.getItem('filter_doc_nature_tous'));

    const getActivitiesPost = () => {
        getActivities({
            query: uriActivity, type, filters: {
                a_traiter: localStorage.getItem('task_a_traiter'),
                doc_nature_CT: localStorage.getItem('task_doc_nature_CT'),
                doc_nature_RX: localStorage.getItem('task_doc_nature_RX'),
                doc_nature_FA: localStorage.getItem('task_doc_nature_FA'),
                doc_nature_PV: localStorage.getItem('task_doc_nature_PV'),
                doc_nature_PW: localStorage.getItem('task_doc_nature_PW'),
                doc_nature_BD: localStorage.getItem('task_doc_nature_BD')
            }
        });
        window.scrollTo(0, 0)
    }

    const handleClearFilters = () => {
        localStorage.setItem('task_a_traiter', false)
        localStorage.setItem('filter_doc_nature_tous', false)
        localStorage.setItem('task_doc_nature_CT', false)
        localStorage.setItem('task_doc_nature_RX', false)
        localStorage.setItem('task_doc_nature_FA', false)
        localStorage.setItem('task_doc_nature_PV', false)
        localStorage.setItem('task_doc_nature_PW', false)
        localStorage.setItem('task_doc_nature_BD', false)
        getActivitiesPost();
    }

    const handleChangeATraiterFilter = () => {
        localStorage.setItem('task_a_traiter', !aTraiter)
        getActivitiesPost();
    }

    let handleChangeDocFilter = (oldVal, type) => {
        localStorage.setItem(`task_doc_nature_${type}`, !oldVal)
        const allChecked = localStorage.getItem('task_doc_nature_CT') === 'true' && localStorage.getItem('task_doc_nature_RX') === 'true' && localStorage.getItem('task_doc_nature_FA') === 'true' && localStorage.getItem('task_doc_nature_PV') === 'true' && localStorage.getItem('task_doc_nature_PW') === 'true' && localStorage.getItem('task_doc_nature_BD') === 'true';

        if (allChecked && !docNatureTous) {
            localStorage.setItem('filter_doc_nature_tous', true)
        } else if (!allChecked && docNatureTous) {
            localStorage.setItem('filter_doc_nature_tous', false)
        }
        getActivitiesPost();
    }

    const handleChangeDocFilterTous = () => {
        localStorage.setItem('filter_doc_nature_tous', !docNatureTous)
        localStorage.setItem('task_doc_nature_CT', !docNatureTous)
        localStorage.setItem('task_doc_nature_RX', !docNatureTous)
        localStorage.setItem('task_doc_nature_FA', !docNatureTous)
        localStorage.setItem('task_doc_nature_PV', !docNatureTous)
        localStorage.setItem('task_doc_nature_PW', !docNatureTous)
        localStorage.setItem('task_doc_nature_BD', !docNatureTous)

        getActivitiesPost();
    }

    return (
        <>
            <div className={'d-flex justify-content-between mt-3'}>
                <small><u>Nature de l'activité :</u></small>
                <small onClick={handleClearFilters}><u>Réinitialiser les filtres</u></small>
            </div>
            <div className={'d-inline-block'}>
                <div className={'d-inline-block'}>
                    <div className={'styled-checkbox'}>
                        <input
                            type="checkbox"
                            name="filter_doc_nature_tous"
                            id="docNatureTous"
                            onChange={handleChangeDocFilterTous}
                            value={docNatureTous}
                            defaultChecked={docNatureTous}
                        />
                        <label
                            htmlFor="docNatureTous">{!docNatureTous ? 'Sélectionner' : 'Désélectionner '} tous</label>
                    </div>
                </div>
                <div className={'d-inline-block ml-2'}>
                    <div className={'styled-checkbox'}>
                        <input
                            type="checkbox"
                            name="filter_doc_nature_CT"
                            id="docNatureCT"
                            onChange={() => handleChangeDocFilter(docNatureCT, 'CT')}
                            value={docNatureCT}
                            defaultChecked={docNatureCT}
                        />
                        <label htmlFor="docNatureCT">Constat</label>
                    </div>
                </div>
                <div className={'d-inline-block ml-2'}>
                    <div className={'styled-checkbox'}>
                        <input
                            type="checkbox"
                            name="filter_doc_nature_RX"
                            id="docNatureRX"
                            onChange={() => handleChangeDocFilter(docNatureRX, 'RX')}
                            value={docNatureRX}
                            defaultChecked={docNatureRX}
                        />
                        <label htmlFor="docNatureRX">Rapport expert</label>
                    </div>
                </div>
                <div className={'d-inline-block ml-2'}>
                    <div className={'styled-checkbox'}>
                        <input
                            type="checkbox"
                            name="filter_doc_nature_FA"
                            id="docNatureFA"
                            onChange={() => handleChangeDocFilter(docNatureFA, 'FA')}
                            value={docNatureFA}
                            defaultChecked={docNatureFA}
                        />
                        <label htmlFor="docNatureFA">Facture</label>
                    </div>
                </div>
                <div className={'d-inline-block ml-2'}>
                    <div className={'styled-checkbox'}>
                        <input
                            type="checkbox"
                            name="filter_doc_nature_PW"
                            id="docNaturePW"
                            onChange={() => handleChangeDocFilter(docNaturePW, 'PW')}
                            value={docNaturePW}
                            defaultChecked={docNaturePW}
                        />
                        <label htmlFor="docNaturePW">Prise en charge</label>
                    </div>
                </div>
                <div className={'d-inline-block ml-2'}>
                    <div className={'styled-checkbox'}>
                        <input
                            type="checkbox"
                            name="filter_doc_nature_PV"
                            id="docNaturePV"
                            onChange={() => handleChangeDocFilter(docNaturePV, 'PV')}
                            value={docNaturePV}
                            defaultChecked={docNaturePV}
                        />
                        <label htmlFor="docNaturePV">Procès verbal</label>
                    </div>
                </div>
                <div className={'d-inline-block ml-2'}>
                    <div className={'styled-checkbox'}>
                        <input
                            type="checkbox"
                            name="filter_doc_nature_BD"
                            id="docNatureBD"
                            onChange={() => handleChangeDocFilter(docNatureBD, 'BD')}
                            value={docNatureBD}
                            defaultChecked={docNatureBD}
                        />
                        <label htmlFor="docNatureBD">Contrat</label>
                    </div>
                </div>
            </div>
            <span className={'mx-3'}>|</span>

            <div className={'d-inline-block'}>
                <div className={'styled-checkbox'}>
                    <input
                        type="checkbox"
                        name="filter_a_traiter"
                        id="a_traiter"
                        onChange={handleChangeATraiterFilter}
                        value={aTraiter}
                        defaultChecked={aTraiter}
                    />
                    <label htmlFor="a_traiter">Tâches à traiter</label>
                </div>
            </div>
        </>
    )
        ;
};

export default ActivitiesListAccidentFilters;
