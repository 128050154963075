import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import EditAccessoires from "./EditAccessoires";
import {formValueSelector, reduxForm, change} from "redux-form";
import ErrorHttpComponent from "../../../../Commun/Error/ErrorHttpComponent";
import {getErrorEntity, getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {
    ACCESSOIRE_DETAIL,
    ADD_ACCESSOIRES, getAccessoireDetail,
    postAddAccessoires
} from "../../../../../redux/actions/app/accessoires/accessoires.actions";
import {
    accessoireDetailGetLink,
    updateAccidentAccessoiresGetLink
} from "../../../../../redux/selectors/links/links.selectors";
import _ from "lodash";
import {getAccessoireDetailData} from "../../../../../redux/selectors/accessoires/accessoires.selectors";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector('edit_accessoires')

    return {
        uriDetail: accessoireDetailGetLink(state),
        uri: updateAccidentAccessoiresGetLink(state),
        loaded: getLoadingEntity(state, ACCESSOIRE_DETAIL) === false,
        error: getErrorEntity(state, ACCESSOIRE_DETAIL),
        loadingAddAccessoires: getLoadingEntity(state, ADD_ACCESSOIRES),
        errorAddAccessoires: getErrorEntity(state, ADD_ACCESSOIRES),
        contract: ownProps.match.params.id,
        accident: ownProps.match.params.ids,
        accessoireId: ownProps.match.params.accessoire,
        useCalculette: selector(state, 'accessoires[0].useCalculette'),
        valeurAchat: selector(state, 'accessoires[0].valeurAchat'),
        dateAchat: selector(state, 'accessoires[0].dateAchat'),
        explication: selector(state, 'accessoires[0].explication'),
        palier: selector(state, 'accessoires[0].palier'),
        vetusteValue: selector(state, 'accessoires[0].vetuste'),
        libelle: selector(state, 'accessoires[0].libelle'),
        accessoire: getAccessoireDetailData(state),
    }
}
const mapDispatchToProps = dispatch => bindActionCreators({
    getAccessoireDetail: (query) => getAccessoireDetail({query}),
    postAddAccessoires: ({query, form, accident}) => postAddAccessoires({query, form, accident}),
    change: (field, value) => change('edit_accessoires', field, value)
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {postAddAccessoires, getAccessoireDetail} = dispatchProps
    const {uri, contract, accident, uriDetail, accessoireId} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getAccessoireDetail(_.replace(_.replace(_.replace(uriDetail, '{id}', contract), '{ids}', accident), '{accessoire}', accessoireId)),
        onSubmit: (form) => postAddAccessoires({
            query: _.replace(_.replace(uri, '{id}', contract), '{ids}', accident),
            form
        }),
    }
}

const EditAccessoiresContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'edit_accessoires'
    }),
    loader({ErrorIndicator: ErrorHttpComponent})
)(EditAccessoires)

export default EditAccessoiresContainer
