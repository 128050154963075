import React from 'react';
import {Field} from "redux-form";
import SelectFieldRedux from "../../../../Commun/Input/Select/SelectFieldRedux";
import Spinner from "../../../../Commun/Spinner/Spinner";
import {
    accidentClosMotif,
    accidentStatus, accidentStatusEnAttentePreDec,
    accidentStatusOuvert
} from "../../../../../utils/optionsSelect";
import _ from "lodash";
import TextAreaFieldRedux from "../../../../Commun/Input/Text/TextAreaFieldRedux";


const ChangeAccidentStatus = (props) => {
    const {
        loading,
        handleSubmit,
        situationCode,
        decDommages,
        tauxResp,
        garanties,
        statusSelected,
        motifSelected
    } = props
    const sinistree = _.includes(garanties, true)
    let status = accidentStatus;

    if (situationCode === '3' || situationCode === 'A') {
        status = accidentStatusEnAttentePreDec;

        if ((situationCode === 'A') && (decDommages === '' || (tauxResp === null || tauxResp === '-') || !sinistree)) {
            status = _.reject(status, ['value', '1']);
        }
    } else if (situationCode === '1') {
        status = accidentStatusOuvert;
    }

    return (
        <div className={'contract-container container'}>
            <div className={'row return-content'}>
                <div className={'col'}>
                    <button onClick={() => props.history.go(-1)} className={'btn btn-return'}>Retour</button>
                </div>
            </div>
            <div className={'row header-row'}>
                <div className={'col-12'}>
                    <form className={'form-content'} onSubmit={handleSubmit}>
                        <Field
                            name='status'
                            component={SelectFieldRedux}
                            label='Statut du sinistre'
                        >
                            <option>Sélectionnez un statut</option>
                            {
                                status.map((status, index) =>
                                    <option key={index} value={status.value}>{status.label}</option>
                                )
                            }
                        </Field>
                        {
                            statusSelected === '8' &&
                            <Field
                                name='motif'
                                component={SelectFieldRedux}
                                label='Motif'
                            >
                                <option>Sélectionnez un motif</option>
                                {
                                    accidentClosMotif.map((motif, index) =>
                                        <option key={index} value={motif.label}>{motif.label}</option>
                                    )
                                }
                            </Field>
                        }
                        {(statusSelected === '9' || (statusSelected === '8' && motifSelected && motifSelected === 'Autre')) &&
                            <Field
                                name={'commentaire'}
                                component={TextAreaFieldRedux}
                                label={'Commentaire (obligatoire)'}
                                subLabel={'500 caractères maximum'}
                                maxLength={'500'}
                                required
                            />
                        }

                        {
                            loading ? <Spinner style={{display: 'block', margin: 'auto'}}/> :
                                <button className={'btn btn-primary d-block'} type={'submit'}>
                                    Mettre à jour
                                </button>
                        }
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ChangeAccidentStatus;
