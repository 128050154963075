import React from 'react';
import {tokenUser} from "../../../redux/selectors/localStorage/localStorage";
import Swal from 'sweetalert2'
import {toast} from "../../../redux/middlewares/core/notifications/notifications.middleware";
import _ from "lodash";
import * as Sentry from "@sentry/react";

export const truncateMiddle = (fullStr) => {
    if (!fullStr) {
        return ''
    }
    return fullStr.slice(0, 8) + '...' + fullStr.slice(-8)
}

const APILink = (props) => {
    let url = new URL(props.href)
    url.searchParams.delete('access_token');
    url = url.toString()

    const downloadWithAuth = async (e) => {
        e.preventDefault()

        let anchor = document.createElement("a");
        document.body.appendChild(anchor);

        const urlSlices = /^.*\/(.*)\.([^\?]*)\??.*$/.exec(url)
        const fileName = `${urlSlices[1]}.${urlSlices[2]}`

        const loadingToast = Swal.fire({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading();
            },
            titleText: `Téléchargement du fichier ${truncateMiddle(fileName)}...`
        });

        let headers = new Headers();
        headers.append('Authorization', `Bearer ${tokenUser.trim()}`);

        const blob = await fetch(url, {headers})
            .then((response) => {
                if (response.ok) {
                    return response.blob();
                }
            })
            .catch((error) => {
                Sentry.captureMessage(`APILink - ${error}`);
                return undefined;
            });
        if (blob) {
            let objectUrl = window.URL.createObjectURL(blob);
            anchor.href = objectUrl;

            if (props.download || _.includes(['png', 'jpg', 'jpeg'], urlSlices[2].toLowerCase())) {
                anchor.download = fileName.split('?')[0];
            } else {
                anchor.target = props.target || "_blank";
            }
            anchor.click();

            setTimeout(function() {
                document.body.removeChild(anchor);
                window.URL.revokeObjectURL(objectUrl);
            }, 100);
        } else {
            toast({
                type: 'error',
                title: 'Téléchargement échoué'
            })
        }

        loadingToast.close()

        props.onClick && props.onClick(e)
    }

    if (url.indexOf('pass.april-moto.com') < 0 && url.indexOf('pass-recette.april-moto.com') < 0 && url.indexOf('pass-live.april-moto.com') < 0) {
        // ce n'est pas un dl du pass, pas d'auth
        return <a {...props}>
            {props.children}
        </a>
    }

    const {onClick, download, target, href, ...otherProps} = props;

    return <span onClick={downloadWithAuth} {...otherProps}>
        {props.children}
    </span>
}

export default APILink;
