export const FLOTTE = '[Flotte]'
export const FLOTTE_DETAIL = '[Flotte Detail]'
export const FLOTTE_ACCIDENTS = '[Flotte Accidents]'
export const DELETE_VEHICLES = '[Delete Vehicles]'

export const GET_FLOTTE = `${FLOTTE} Get`
export const SET_FLOTTE = `${FLOTTE} Set`
export const GET_FLOTTE_DETAIL = `${FLOTTE_DETAIL} Get`
export const SET_FLOTTE_DETAIL = `${FLOTTE_DETAIL} Set`

export const GET_FLOTTE_ACCIDENTS = `${FLOTTE_ACCIDENTS} Get`

export const POST_DELETE_VEHICLES = `${DELETE_VEHICLES} Post`

export const getFlotte = ({query, sort, contrat, immat, active}) => ({
    type: GET_FLOTTE,
    payload: {
        data: query,
        filters: {
            sort,
            contrat,
            immat,
            active
        }
    }
})

export const setFlotte = ({data, meta, links}) => ({
    type: SET_FLOTTE,
    payload: {data, meta, links}
})

export const getFlotteDetail = ({query, id}) => ({
    type: GET_FLOTTE_DETAIL,
    payload: {
        data: query,
        otherData: {
            id: id
        }
    }
})

export const setFlotteDetail = ({data}) => ({
    type: SET_FLOTTE_DETAIL,
    payload: {
        data
    }
})


export const getAccidentsFlotte = ({query, immat}) => ({
    type: GET_FLOTTE_ACCIDENTS,
    payload: {
        data: query,
        filters: {
            immat
        }
    }
})

export const postDeleteVehicles = ({form, query}) => ({
    type: POST_DELETE_VEHICLES,
    payload: {
        data: query,
        body: form
    }
})