const validate = values => {
    const errors = {};

    const requiredFields = [
        'type_doc',
        'dateSurvOk',
        'catsinOk',
        'delaiPec',
        'signPec',
        'abandonPec'
    ];

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Ce champ est obligatoire';
        }
    });

    if (values.type_doc === 'rapport') {
        if (values.catsinOk === 'F' && !values.catsin) {
            errors.catsin = 'Ce champ est obligatoire';
        }

        if (values.dateSurvOk === 'F' && !values.dateSurv) {
            errors.dateSurv = 'Ce champ est obligatoire';
        }
    } else if (values.type_doc === 'pec') {
        if (!values.delaiPec) {
            errors.delaiPec = 'Ce champ est obligatoire';
        }

        if (!values.signPec) {
            errors.signPec = 'Ce champ est obligatoire';
        }

        if (values.signPec === 'F' && !values.abandonPec) {
            errors.abandonPec = 'Ce champ est obligatoire';
        }
    }

    return errors;
};

export default validate;