import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import ErrorHttpComponent from '../../../../Commun/Error/ErrorHttpComponent'
import _ from "lodash";
import {
    contractDefaultGetLink,
    postChangeSituationContractLink,
} from "../../../../../redux/selectors/links/links.selectors";
import {
    postChangeSituationContract,
    CHANGE_SITUATION_CONTRACT
} from "../../../../../redux/actions/app/change_situation_contract/change_situation_contract.actions";
import {getErrorEntity, getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import RevContrat from './RevContrat'
import {CONTRACT, getContract} from "../../../../../redux/actions/app/contracts/contracts.actions";
import {getContractData} from "../../../../../redux/selectors/contract/contract.selectors";
import {getInsurersData} from "../../../../../redux/selectors/insurers/insurers.selector";
import {reduxForm} from "redux-form";
import validate from "../../Forms/ChangeRib/validate";
import loader from "../../../../Commun/Spinner/Loader/Loader";
import moment from "moment/moment";

const mapStateToProps = (state, ownProps) => {
    const contract = getContractData(state)

    return {
        contract: contract,
        uri: postChangeSituationContractLink(state),
        loading: getLoadingEntity(state, CHANGE_SITUATION_CONTRACT),
        uriContrat: contractDefaultGetLink(state),
        loaded: getLoadingEntity(state, CONTRACT) === false,
        error: getErrorEntity(state, CONTRACT),
        id: ownProps.match.params.id,
        errorRev: getErrorEntity(state, CHANGE_SITUATION_CONTRACT),
        insurers: getInsurersData(state),
        initialValues: {
            'date': !_.isEmpty(contract) ? moment(contract.situation.dateAffaireNouvelle) : moment()
        }
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    postRevContract: ({form, type, query}) => postChangeSituationContract({form, type, query}),
    getContract: (query) => getContract({query}),
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {postRevContract, getContract} = dispatchProps
    const {uri, uriContrat, id} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getContract(_.replace(uriContrat, '{id}', id)),
        onSubmit: (form) => postRevContract({
            form,
            type: 'rev',
            query: _.replace(uri, '{id}', id)
        })
    }
}

const RevContratContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({form: 'rev_contrat', validate}),
    loader({ErrorIndicator: ErrorHttpComponent})
)(RevContrat)

export default RevContratContainer
