export const DEROGATIONS = '[Derogations]';

export const GET_DEROGATIONS = `${DEROGATIONS} Get`;
export const SET_DEROGATIONS = `${DEROGATIONS} Set`;
export const POST_DEROGATIONS = `${DEROGATIONS} Post`;
export const DELETE_DEROGATIONS = `${DEROGATIONS} Delete`;

export const getDerogations = ({ query }) => ({
    type: GET_DEROGATIONS,
    payload: {
        data: query,
    },
});

export const setDerogations = ({ derogations }) => ({
    type: SET_DEROGATIONS,
    payload: {
        data: derogations,
    },
});

export const postDerogations = ({ form, query }) => ({
    type: POST_DEROGATIONS,
    payload: {
        data: query,
        body: form,
    },
});

export const deleteDerogations = ({ form, query }) => ({
    type: DELETE_DEROGATIONS,
    payload: {
        data: query,
        body: form,
    },
});
