import {SET_TASKS} from '../../actions/app/tasks/tasks.actions'

export const tasksReducer = (tasks = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_TASKS) {
        return payload
    } else {
        return tasks
    }
}
