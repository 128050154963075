import {SET_RAPPORT_BCA} from "../../actions/app/bca/bca.actions";

export const rapportBcaReducer = (rapportBca = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_RAPPORT_BCA) {
        return payload.data
    } else {
        return rapportBca
    }
}
