export const DOCUMENTS_UTILES = '[Documents Utiles]'

export const GET_DOCUMENTS_UTILES = `${DOCUMENTS_UTILES} Get`
export const SET_DOCUMENTS_UTILES = `${DOCUMENTS_UTILES} Set`

export const getDocumentsUtiles = ({query}) => ({
    type: GET_DOCUMENTS_UTILES,
    payload: {
        data: query
    }
})

export const setDocumentsUtiles = ({documents_utiles}) => ({
    type: SET_DOCUMENTS_UTILES,
    payload: {
        data: documents_utiles
    }
})
