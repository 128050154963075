const validate = values => {
    const errors = {}

    const requiredFields = [
        'nouvel_apporteur',
        'transfert'
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Ce champ est obligatoire'
        }
    })

    return errors
}

export default validate
