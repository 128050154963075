import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {
    POST_EXPIRATION_DATE_SEJOUR,
    EXPIRATION_DATE_SEJOUR,
    setExpirationDateSejour
} from "../../../actions/app/date_sejour/date_sejour.actions";
import moment from "moment";

import _ from "lodash";

export const dateSejourMiddleware = () => next => action => {
    next(action)

    const {payload} = action
    let message = null

    switch (action.type) {
        case POST_EXPIRATION_DATE_SEJOUR:
            let clone = _.cloneDeep(payload.body)
            clone.date = clone.date ? moment(clone.date).format('DD/MM/YYYY') : null

            next([
                apiRequest({
                    body: (clone),
                    method: 'POST',
                    url: action.payload.data,
                    entity: EXPIRATION_DATE_SEJOUR
                }),
                setLoader({state: true, entity: EXPIRATION_DATE_SEJOUR})
            ])
            break

        case `${EXPIRATION_DATE_SEJOUR} ${API_SUCCESS}`:
            message = `<p>La date d'expiration du titre administratif a bien été mise à jour</p>`
            next([
                setExpirationDateSejour({state: true}),
                setNotification({
                    entity: EXPIRATION_DATE_SEJOUR,
                    body: message,
                    type: 'success',
                    title: 'Informations modifiées!'
                }),
                setLoader({state: false, entity: EXPIRATION_DATE_SEJOUR}),
                setError({state: false, entity: EXPIRATION_DATE_SEJOUR}),
            ])
            break

        case `${EXPIRATION_DATE_SEJOUR} ${API_ERROR}`:
            message = `
                <p>Votre demande n'a pas pu être envoyée. ${payload.data.response.data?.message ?? ''}</p>
            `
            next([
                setNotification({
                    entity: EXPIRATION_DATE_SEJOUR,
                    body: message,
                    type: 'error',
                    title: 'Erreur lors de l\'edition !',
                    timeout: '0'
                }),
                setError({state: true, entity: EXPIRATION_DATE_SEJOUR}),
                setLoader({state: false, entity: EXPIRATION_DATE_SEJOUR}),
            ])
            break

        default:
            break
    }
    return null
}
