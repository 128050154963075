import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import ReportAccident from './ReportAccident'
import {getContractData, getIsFleetContract} from "../../../../../redux/selectors/contract/contract.selectors";
import {CONTRACT, getContract} from "../../../../../redux/actions/app/contracts/contracts.actions";
import {contractDefaultGetLink} from "../../../../../redux/selectors/links/links.selectors";
import _ from "lodash";
import {
    getErrorEntity,
    getAccidentPost,
    getLoadingEntity
} from "../../../../../redux/selectors/ui/ui.selectors";
import ErrorHttpComponent from "../../../../Commun/Error/ErrorHttpComponent";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    return {
        idVehicule: ownProps.match.params.idVehicule,
        contract: getContractData(state),
        isFleet: getIsFleetContract(state),
        uri: contractDefaultGetLink(state),
        idContract: ownProps.match.params.id,
        loaded: getLoadingEntity(state, CONTRACT) === false,
        error: getErrorEntity(state, CONTRACT),
        accidentAlreadyPost: getAccidentPost(state)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getContract: (query) => getContract({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getContract} = dispatchProps
    const {contract, uri, idContract} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => contract.id === undefined && getContract(_.replace(uri, '{id}', idContract))
    }
}

const ReportAccidentContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(ReportAccident)

export default ReportAccidentContainer
