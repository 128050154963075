import React from 'react';
import PropTypes from 'prop-types'
import {formatThousandSpace} from "../../../../../utils/function";
import DashboardPaniereRowContainer
    from "../../Dashboard/DashboardList/DashboardPaniereRow/DashboardPaniereRowContainer";

const DashboardTeleventeConseillerDetail = (props) => {
    const {belair_id, user_result, team_results} = props
	
    return (
        <div>
            <div className={'total-tasks m-0'}>
                <h2 className={'total-tasks content text-left'}>Mes ventes : {formatThousandSpace(user_result)}</h2>
            </div>

            <div>
                <div className={'m-0 total-tasks'}>
                    <h2 className={'total-tasks content text-left border-0'}>Les résultats de mon équipe :</h2>
                </div>

                <table className="table">
                    <tbody>
                    {team_results.map((result, index) =>
                        <tr key={index} className={result.code_belair === belair_id ? 'bg-white' : ''}>
                            <td className={'text-left'}>{result.realisateur}</td>
                            <td className={'text-right'}>{formatThousandSpace(result.total)}</td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

DashboardTeleventeConseillerDetail.defaultProps = {

};

DashboardTeleventeConseillerDetail.propTypes = {

};

export default DashboardTeleventeConseillerDetail;