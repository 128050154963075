import React from 'react'
import moment from "moment";

const ReclaNiv2 = (props) => {
    const {task: {date, type, paniere, tiers_id, description}} = props

    return (
        <div className={'container'}>
            <div className={'row'}>
                <div className={'col text-center'}>
                    <h2 className={'title-primary text-uppercase'}>Tâche {paniere} - Tiers n°{tiers_id}</h2>
                    <p className={'subtitle'}>Tâche du {moment(date).format('L')}. Recu par {type}</p>
                </div>
            </div>
            <div className={'header-row row'}>
                <div className={'col-12'}>
                    <p className={'form-content text-center'} dangerouslySetInnerHTML={{__html: description}}/>
                </div>
            </div>
        </div>
    )
}

export default ReclaNiv2
