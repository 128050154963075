import LogoutContainer from "./Logout/LogoutContainer";
import SupportContainer from "./Support/SupportContainer"
import TasksContentContainer from './Tasks/TasksContentContainer'
import ContractContainer from './Contract/ContractContainer'
import TaskDetailContainer from './Tasks/TaskDetail/TaskDetailContainer'
import TiersContainer from './Tiers/TiersContainer'
import PaymentContainer from './Forms/Payment/PaymentContainer'
import ReportAccidentContainer from "./Forms/ReportAccident/ReportAccidentContainer";
import ChangePasswordContainer from './Forms/ChangePassword/ChangePasswordContainer'
import AccidentContainer from './Accident/AccidentContainer'
import ChangePersonalInfosContainer from "./Forms/ChangePersonalInfos/ChangePersonalInfosContainer";
import ReassignTaskContainer from "./Forms/ReassignTask/ReassignTaskContainer";
import ActivitiesListContainer from "../../Commun/ActivitiesList/ActivitiesListContainer";
import ChangeRibContainer from "./Forms/ChangeRib/ChangeRibContainer";
import ActivityDetailContainer from "../../Commun/ActivitiesList/ActivityDetail/ActivityDetailContainer";
import AddNoteContainer from "./Forms/AddNote/AddNoteContainer";
import VirementDetailContainer from "./Virements/Sinistres/VirementDetail/VirementDetailContainer";
import UpdateHamonContainer from "./Forms/UpdateHamon/UpdateHamonContainer";
import EmailContainer from "./Forms/Email/EmailContainer";
import AgendaContainer from "./Agenda/AgendaContainer";
import RedirectToListContainer from "./Virements/RedirectToListContainer";
import RefuseVirementContainer from "./Forms/RefuseVirement/RefuseVirementContainer";
import StatistiquesContainer from "./Statistiques/StatistiquesContainer";
import QuittanceDetailContainer from "./Contract/Quittances/Detail/QuittanceDetailContainer";
import FoiresAuxQuestionsContainer from "./FoiresAuxQuestions/FoiresAuxQuestionsContainer";
import ChangeAddressContainer from "./Forms/ChangeAddress/ChangeAddressContainer";
import ResilContratContainer from "./Contract/ResilContrat/ResilContratContainer";
import ReclamationContainer from "./Forms/Reclamation/ReclamationContainer";
import ReclamationDetailContainer from "./Reclamations/ReclamationDetail/ReclamationDetailContainer";
import ReclamationTransfertContainer from "./Forms/Reclamation/ReclamationTransfert/ReclamationTransfertContainer";
import AvenantSimpleContainer from "./Forms/AvenantSimple/AvenantSimpleContainer";
import ReclamationCloseContainer from "./Forms/Reclamation/ReclamationClose/ReclamationCloseContainer";
import RevContratContainer from "./Contract/RevContrat/RevContratContainer";
import ExpirationDateSejourContainer from "./Forms/ExpirationDateSejour/ExpirationDateSejourContainer";
import ChangeReparateurAccidentContainer from "./Forms/ChangeReparateurAccident/ChangeReparateurAccidentContainer";
import ChangeAccidentStatusContainer from "./Forms/ChangeAccidentStatus/ChangeAccidentStatusContainer";
import UpdateAccidentContainer from "./Forms/UpdateAccident/UpdateAccidentContainer";
import AddActivityContractContainer from "./Forms/AddActivity/AddActivityContractContainer";
import AddActivityAccidentContainer from "./Forms/AddActivity/AddActivityAccidentContainer";
import AddActivityTiersContainer from "./Forms/AddActivity/AddActivityTiersContainer";
import AddActivityReclamationContainer from "./Forms/AddActivity/AddActivityReclamationContainer";
import RequetesContainer from "./Requetes/RequetesContainer";
import ReversementCompagniesContainer from "./Requetes/ReversementCompagnies/ReversementCompagniesContainer";
import FlotteDetailContainer from "./Contract/Flotte/FlotteDetail/FlotteDetailContainer";
import AccidentsContainer from "./Accidents/AccidentsContainer";
import AddCorrespondantContainer from "./Forms/AddCorrespondant/AddCorrespondantContainer";
import EditCorrespondantContainer from "./Forms/AddCorrespondant/EditCorrespondantContainer";
import DetailsReglementContainer from "./Accident/Reglement/DetailsReglement/DetailsReglementContainer";
import CommandeProduitsContainer from "./Forms/CommandeProduits/CommandeProduitsContainer";
import ChangeResponsabiliteContainer from "./Forms/ChangeResponsabilite/ChangeResponsabiliteContainer";
import ChangeCompagnieAccidentContainer from "./Forms/ChangeCompagnieAccident/ChangeCompagnieAccidentContainer";
import ShiftDateTaskContainer from "./Forms/ShiftDateTask/ShiftDateTaskContainer";
import EditDocumentContainer from "./Forms/EditDocument/EditDocumentContainer";
import SendSignatureContainer from "./Forms/SendSignature/SendSignatureContainer";
import ActivityTransferContainer from "./Forms/ActivityTransfer/ActivityTransferContainer";
import ExtractWalletContainer from "./Forms/ExtractWallet/ExtractWalletContainer";
import AddTiersContainer from "./Forms/AddTiers/AddTiersContainer";
import EditTiersContainer from "./Forms/AddTiers/EditTiersContainer";
import ChangeRibAccidentContainer from "./Forms/ChangeRibAccident/ChangeRibAccidentContainer";
import ChangeConducteurContainer from "./Forms/ChangeConducteur/ChangeConducteurContainer";
import ConstatContainer from "./Forms/Constat/ConstatContainer";
import ReportAccidentNonDeclareContainer from "./Forms/ReportAccidentNonDeclare/ReportAccidentNonDeclareContainer";
import DeclarationAccidentNavigationContainer
    from "./Contract/DeclarationAccidentNavigation/DeclarationAccidentNavigationContainer";
import AddAccessoiresContainer from "./Forms/AddAccessoires/AddAccessoiresContainer";
import EditAccessoiresContainer from "./Forms/AddAccessoires/EditAccessoiresContainer";
import ChangeDateExpertVisitAccidentContainer
    from "./Forms/ChangeDateExpertVisitAccident/ChangeDateExpertVisitAccidentContainer";
import ChangeRemboursementAccidentContainer
    from "./Forms/ChangeRemboursementAccident/ChangeRemboursementAccidentContainer";
import DevisListeContainer from "./Devis/DevisListe/DevisListeContainer";
import NouveauDevisContainer from "./Devis/NouveauDevis/NouveauDevisContainer";
import DevisDetailContainer from "./Devis/DevisDetail/DevisDetailContainer";
import VirementsSinistreInitFormContainer from "./Forms/VirementsSinistre/VirementsSinistreInitFormContainer";
import AddExpertReportContainer from "./Forms/AddExpertReport/AddExpertReportContainer";
import DashboardTeleventeContainer from "./DashboardTelevente/DashboardTeleventeContainer";
import ExtractQuotesTlvContainer from "./Forms/ExtractQuotesTlv/ExtractQuotesTlvContainer";
import DashboardTaskDetailsTeleventeContainer from "./DashboardTelevente/DashboardTaskDetailsTelevente/DashboardTaskDetailsTeleventeContainer";
import ChangeFractionnementContainer from "./Forms/ChangeFractionnement/ChangeFractionnementContainer";
import InformationsNaissanceContainer from "./Forms/InformationsNaissance/InformationsNaissanceContainer";
import EditTiersPayeurContainer from "./Forms/AddTiersPayeur/EditTiersPayeurContainer";
import AddTiersPayeurContainer from "./Forms/AddTiersPayeur/AddTiersPayeurContainer";
import AddPenaltiesContainer from "./Forms/AddPenalty/AddPenaltiesContainer";
import DerogationContainer from './Forms/Derogation/DerogationContainer';
import DashboardTeleventeConseillerContainer from "./DashboardTeleventeConseiller/DashboardTeleventeConseillerContainer";
import HelpRedirection from "./HelpRedirection/HelpRedirection";
import TransfertWalletContainer from "./Forms/TransfertWallet/TransfertWalletContainer";
import AddOppositionsContainer from "./Forms/AddOppositions/AddOppositionsContainer";
import NouveauDevisCourtier from "./Devis/NouveauDevisCourtier/NouveauDevisCourtier";
import HivernageContainer from "./Forms/Hivernage/HivernageContainer";
import Search from './Search/Search';
import EditRIAntecedentContainer from "./Forms/EditRIAntecedent/EditRIAntecedentContainer";
import DeleteVehiculesFlotteContainer from "./Forms/DeleteVehiculesFlotte/DeleteVehiculesFlotteContainer";
import TracfinFormContainer from "./Forms/TracfinForm/TracfinFormContainer";
import AddRIAntecedentContainer from "./Forms/EditRIAntecedent/AddRIAntecedentContainer";
import ReclamationEditContainer from "./Forms/Reclamation/ReclamationEdit/ReclamationEditContainer";
import ChangeImmatContainer from "./Forms/ChangeImmat/ChangeImmatContainer";
import AddIntervenantContainer from "./Forms/AddIntervenant/AddIntervenantContainer";
import CreateFidelisationContainer from "./Forms/Fidelisation/CreateFidelisationContainer";
import EditFidelisationContainer from "./Forms/Fidelisation/EditFidelisationContainer";
import AddChronoTlvContainer from "./Forms/AddChronoTlv/AddChronoTlvContainer";

const PageRoutes = [
    {
      path: '/recherche',
        exact: true,
        sidebar: true,
        title: 'Recherche',
        icon: '',
        permission: 'all',
        main: (props) => <Search className='loader' {...props}/>
    },
    {
        path: '/deconnexion',
        exact: true,
        sidebar: true,
        title: 'Déconnexion',
        icon: '',
        permission: 'all',
        main: (props) => <LogoutContainer className='loader' {...props}/>
    },
    {
        path: '/help-redirection',
        exact: true,
        sidebar: true,
        title: 'Aide',
        icon: '',
        permission: 'all',
        main: (props) => <HelpRedirection className='loader' {...props}/>
    },
    {
        path: '/tableau-de-bord',
        exact: true,
        sidebar: true,
        title: 'Mon tableau de bord',
        icon: '',
        permission: 'all',
        main: (props) => <SupportContainer className='loader' {...props} />
    },
    {
        path: '/mon-agenda',
        exact: true,
        sidebar: true,
        title: 'Mon agenda',
        icon: '',
        permission: 'all',
        main: (props) => <AgendaContainer className='loader' {...props} />
    },
    {
        path: '/mes-taches',
        exact: true,
        sidebar: true,
        title: 'Mes tâches assignées',
        icon: '',
        permission: 'all',
        main: (props) => <TasksContentContainer className='loader' {...props} />
    },
    {
        path: '/faq-support',
        exact: true,
        sidebar: true,
        title: 'FAQ / Support',
        icon: '',
        permission: 'all',
        main: (props) => <SupportContainer className='loader' {...props} />
    },
    {
        path: '/foire-aux-questions',
        exact: true,
        sidebar: true,
        title: 'Foire aux Questions',
        icon: '',
        permission: 'all',
        main: (props) => <FoiresAuxQuestionsContainer className='loader' {...props} />
    },
    {
        path: '/envoyer-email/:type/:idEmail/:idTiers?/:idContract?/:idSinistre?',
        exact: true,
        sidebar: true,
        title: 'Envoyer un email',
        icon: '',
        permission: 'all',
        main: (props) => <EmailContainer className='loader' {...props} />
    },
    {
        path: '/derogation/:idTiers/:idContract/:idAccident?',
        exact: true,
        sidebar: true,
        title: 'Dérivation',
        icon: '',
        permission: 'all',
        main: (props) => <DerogationContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id',
        exact: true,
        sidebar: false,
        title: 'Contrat',
        icon: '',
        permission: 'all',
        main: (props) => <ContractContainer className='loader' {...props}/>
    },
    {
        path: '/contrat/:id/fidelisation',
        exact: true,
        sidebar: false,
        title: 'Contrat',
        icon: '',
        permission: 'all',
        main: (props) => <CreateFidelisationContainer className='loader' {...props}/>
    },
    {
        path: '/contrat/:id/fidelisation/:ids',
        exact: true,
        sidebar: false,
        title: 'Contrat',
        icon: '',
        permission: 'all',
        main: (props) => <EditFidelisationContainer className='loader' {...props}/>
    },
    {
        path: '/contrat/:id/sortie-vehicule',
        sidebar: false,
        title: 'Véhicule',
        permission: 'all',
        main: (props) => <DeleteVehiculesFlotteContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/vehicule/:ids',
        sidebar: false,
        title: 'Véhicule',
        permission: 'all',
        main: (props) => <FlotteDetailContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/ajout-tiers-payeur',
        exact: true,
        sidebar: false,
        title: 'Ajout d\'un tiers payeur',
        icon: '',
        permission: 'all',
        main: (props) => <AddTiersPayeurContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/edition-tiers-payeur/:ids',
        exact: true,
        sidebar: false,
        title: 'Modification tiers payeur',
        icon: '',
        permission: 'all',
        main: (props) => <EditTiersPayeurContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/edition-periode-antecedents/:ids',
        exact: true,
        sidebar: false,
        title: 'Modification période antécédents',
        icon: '',
        permission: 'all',
        main: (props) => <EditRIAntecedentContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/ajout-antecedents/:type',
        exact: true,
        sidebar: false,
        title: 'Ajout période antécédents',
        icon: '',
        permission: 'all',
        main: (props) => <AddRIAntecedentContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/sinistre/:ids',
        exact: true,
        sidebar: false,
        title: 'Sinistre',
        icon: '',
        permission: 'all',
        main: (props) => <AccidentContainer className='loader' {...props}/>
    },
    {
        path: '/contrat/:id/sinistre/:ids/ajout-suivi',
        exact: true,
        sidebar: false,
        title: 'Ajouter un chrono / agenda',
        icon: '',
        permission: 'all',
        main: (props) => <AddActivityAccidentContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/sinistre/:ids/virements',
        exact: true,
        sidebar: false,
        title: 'Faire un règlement',
        icon: '',
        permission: 'all',
        main: (props) => <VirementsSinistreInitFormContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/sinistre/:ids/detail-reglement',
        exact: true,
        sidebar: false,
        title: 'Détail du règlement',
        icon: '',
        permission: 'all',
        main: (props) => <DetailsReglementContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/sinistre/:ids/note',
        exact: true,
        sidebar: false,
        title: 'Ajouter une note',
        icon: '',
        permission: 'all',
        main: (props) => <AddNoteContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/sinistre/:ids/ajout-intervenant',
        exact: true,
        sidebar: false,
        title: 'Ajouter un intervenant',
        icon: '',
        permission: 'all',
        main: (props) => <AddIntervenantContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/paiement',
        exact: true,
        sidebar: false,
        title: 'Paiement',
        icon: '',
        permission: 'all',
        main: (props) => <PaymentContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/changement-fractionnement',
        exact: true,
        sidebar: false,
        title: 'Changer de fractionnement',
        icon: '',
        permission: 'all',
        main: (props) => <ChangeFractionnementContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/ajout-suivi',
        exact: true,
        sidebar: false,
        title: 'Ajouter un chrono / agenda',
        icon: '',
        permission: 'all',
        main: (props) => <AddActivityContractContainer className='loader' {...props} />
    },

    {
        path: '/contrat/:id/ajout-chrono',
        exact: true,
        sidebar: false,
        title: 'Ajouter un chrono',
        icon: '',
        permission: 'all',
        main: (props) => <AddChronoTlvContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/note',
        exact: true,
        sidebar: false,
        title: 'Ajouter une note',
        icon: '',
        permission: 'all',
        main: (props) => <AddNoteContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/modifie-rib',
        exact: true,
        sidebar: false,
        title: 'Modifier RIB',
        icon: '',
        permission: 'all',
        main: (props) => <ChangeRibContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/resilier',
        exact: true,
        sidebar: false,
        title: 'Résilier / Suspendre le contrat',
        icon: '',
        permission: 'all',
        main: (props) => <ResilContratContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/hivernage',
        exact: true,
        sidebar: false,
        title: 'Gérer la période hivernale',
        icon: '',
        permission: 'all',
        main: (props) => <HivernageContainer className='loader' {...props} />
    },
    {
        path: '/tiers/:id/tracfin',
        exact: true,
        sidebar: false,
        title: 'Tracfin',
        icon: '',
        permission: 'all',
        main: (props) => <TracfinFormContainer className='loader' {...props} />
    },
    {
        path: '/tiers/:id/modifie-rib',
        exact: true,
        sidebar: false,
        title: 'Modifier RIB',
        icon: '',
        permission: 'all',
        main: (props) => <ChangeRibContainer className='loader' {...props} />
    },
    {
        path: '/tiers/:id/modifier-informations-naissance',
        exact: true,
        sidebar: false,
        title: 'Modifier les informations de naissance du Tiers',
        icon: '',
        permission: 'all',
        main: (props) => <InformationsNaissanceContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/sinistre/:ids/modifie-rib',
        exact: true,
        sidebar: false,
        title: 'Modifier RIB',
        icon: '',
        permission: 'all',
        main: (props) => <ChangeRibAccidentContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/modifie-adresse',
        exact: true,
        sidebar: false,
        title: 'Modifier l\'adresse',
        icon: '',
        permission: 'create typed amendment - change address',
        main: (props) => <ChangeAddressContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/avenant-simple',
        exact: true,
        sidebar: false,
        title: 'Modifier les informations non tarifantes',
        icon: '',
        permission: 'all',
        main: (props) => <AvenantSimpleContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/avenant-simple-immat',
        exact: true,
        sidebar: false,
        title: 'Modifier l\'immatriculation',
        icon: '',
        permission: 'all',
        main: (props) => <ChangeImmatContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/declaration-de-sinistre/:idVehicule?',
        exact: true,
        sidebar: false,
        title: 'Déclaration de sinistre',
        icon: '',
        permission: 'all',
        main: (props) => <DeclarationAccidentNavigationContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/declaration-accident/:idVehicule?',
        exact: true,
        sidebar: false,
        title: 'Déclaration d\'un accident',
        icon: '',
        permission: 'all',
        main: (props) => <ConstatContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/declaration-accident-bo/:idVehicule?',
        exact: true,
        sidebar: false,
        title: 'Déclaration d\'un accident',
        icon: '',
        permission: 'all',
        main: (props) => <ConstatContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/declaration-vol/:idVehicule?',
        exact: true,
        sidebar: false,
        title: 'Déclaration d\'un sinistre Vol-Tentative de Vol',
        icon: '',
        permission: 'all',
        main: (props) => <ReportAccidentContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/declaration-autres/:idVehicule?',
        exact: true,
        sidebar: false,
        title: 'Déclaration d\'un sinistre de type autre',
        icon: '',
        permission: 'all',
        main: (props) => <ReportAccidentContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/declaration-non-declare/:idVehicule?',
        exact: true,
        sidebar: false,
        title: 'Déclaration d\'un sinistre non-déclaré',
        icon: '',
        permission: 'all',
        main: (props) => <ReportAccidentNonDeclareContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/modifier-informations-hamon',
        exact: true,
        sidebar: false,
        title: 'Modification information Loi Hamon',
        icon: '',
        permission: 'all',
        main: (props) => <UpdateHamonContainer className={'loader'} {...props} />
    },
    {
        path: '/tiers/create',
        exact: true,
        sidebar: false,
        title: 'Ajout d\'un tiers',
        icon: '',
        permission: 'create tiers',
        main: (props) => <AddTiersContainer className='loader' {...props} />
    },
    {
        path: '/tiers/:id',
        exact: true,
        sidebar: false,
        title: 'Tiers',
        icon: '',
        permission: 'all',
        main: (props) => <TiersContainer className='loader' {...props} />
    },
    {
        path: '/tiers/:id/ajout-suivi',
        exact: true,
        sidebar: false,
        title: 'Ajouter un chrono / agenda',
        icon: '',
        permission: 'all',
        main: (props) => <AddActivityTiersContainer className='loader' {...props} />
    },
    {
        path: '/tiers/:id/mot-de-passe',
        exact: true,
        sidebar: false,
        title: 'Changement du mot de passe',
        icon: '',
        permission: 'all',
        main: (props) => <ChangePasswordContainer className='loader' {...props} />
    },
    {
        path: '/tiers/:id/modifier-informations',
        exact: true,
        sidebar: false,
        title: 'Changement des informations personnelles',
        icon: '',
        permission: 'all',
        main: (props) => <ChangePersonalInfosContainer className='loader' {...props} />
    },
    {
        path: '/tiers/:id/extraction-portefeuille',
        exact: true,
        sidebar: false,
        title: 'Extraction portefeuille',
        icon: '',
        permission: 'all',
        main: (props) => <ExtractWalletContainer className='loader' {...props} />
    },
    {
        path: '/tiers/:id/transfert-portefeuille',
        exact: true,
        sidebar: false,
        title: 'Transfert de portefeuille',
        icon: '',
        permission: 'all',
        main: (props) => <TransfertWalletContainer className='loader' {...props} />
    },
    {
        path: '/tache/:id',
        exact: true,
        sidebar: false,
        title: 'Tâche',
        icon: '',
        permission: 'all',
        main: (props) => <TaskDetailContainer className='loader' {...props} />
    },
    {
        path: '/tache/:id/decaler',
        exact: true,
        sidebar: false,
        title: 'Décaler la tâche',
        icon: '',
        permission: 'all',
        main: (props) => <ShiftDateTaskContainer className='loader' {...props} />
    },
    {
        path: '/tache/:id/reaffecter',
        exact: true,
        sidebar: false,
        title: 'Réaffecter la tâche',
        icon: '',
        permission: 'all',
        main: (props) => <ReassignTaskContainer className='loader' {...props} />
    },
    {
        path: '/activite/:id/:tiers_id/transferer',
        exact: true,
        sidebar: false,
        title: 'Transférer une activité',
        icon: '',
        permission: 'all',
        main: (props) => <ActivityTransferContainer className='loader' {...props} />
    },
    {
        path: '/les-activites/document/:activity_id/:tiers_id',
        exact: true,
        sidebar: false,
        title: "Document de l'activité",
        icon: '',
        permission: 'all',
        main: (props) => <ActivityDetailContainer className='loader' {...props} />
    },
    {
        path: '/les-activites/:type/:firstId?/:secondId?',
        exact: true,
        sidebar: false,
        title: 'Les activités du tiers',
        icon: '',
        permission: 'all',
        main: (props) => <ActivitiesListContainer className='loader' {...props}/>
    },
    {
        path: '/virements',
        exact: true,
        sidebar: true,
        title: 'Virements en attente',
        icon: '',
        permission: 'all',
        main: (props) => <RedirectToListContainer className='loader' {...props} />
    },
    {
        path: '/virements/:tiers_id/:id/:ids',
        exact: true,
        sidebar: true,
        title: 'Detail du virement',
        icon: '',
        permission: 'all',
        main: (props) => <VirementDetailContainer className='loader' {...props} />
    },
    {
        path: '/virements/:id/refuse',
        exact: true,
        sidebar: true,
        title: 'Refus du virement',
        icon: '',
        permission: 'all',
        main: (props) => <RefuseVirementContainer className='loader' {...props} />
    },
    {
        path: '/quittances/:id',
        exact: true,
        sidebar: true,
        title: 'Detail de la quittance',
        icon: '',
        permission: 'all',
        main: (props) => <QuittanceDetailContainer className='loader' {...props} />
    },
    {
        path: '/statistiques',
        exact: true,
        sidebar: true,
        title: 'Statistiques',
        icon: '',
        permission: 'all',
        main: (props) => <StatistiquesContainer className='loader' {...props} />
    },
    {
        path: '/dashboard-tlv',
        exact: true,
        sidebar: true,
        title: 'Dashboard',
        icon: '',
        permission: 'all',
        main: (props) => <DashboardTeleventeContainer className='loader' {...props} />
    },
    {
        path: '/dashboard-tlv/tache/:id',
        exact: true,
        sidebar: true,
        title: 'Extraction',
        icon: '',
        permission: 'all',
        main: (props) => <DashboardTaskDetailsTeleventeContainer className='loader' {...props} />
    },
    {
        path: '/dashboard-tlv-extraction',
        exact: true,
        sidebar: true,
        title: 'Extraction',
        icon: '',
        permission: 'all',
        main: (props) => <ExtractQuotesTlvContainer className='loader' {...props} />
    },
    {
        path: '/mon-tableau-bord',
        exact: true,
        sidebar: true,
        title: 'Mon tableau de bord',
        icon: '',
        permission: 'all',
        main: (props) => <DashboardTeleventeConseillerContainer className='loader' {...props} />
    },
    {
        path: '/reclamation/:id/:idc?/:ids?',
        exact: true,
        sidebar: false,
        title: 'Réclamation',
        icon: '',
        permission: 'all',
        main: (props) => <ReclamationContainer className={"loader"} {...props} />
    },
    {
        path: '/ma-reclamation/:id/:ids/ajout-suivi',
        exact: true,
        sidebar: false,
        title: 'Ajouter un chrono / agenda',
        icon: '',
        permission: 'all',
        main: (props) => <AddActivityReclamationContainer className='loader' {...props} />
    },
    {
        path: '/ma-reclamation/:id/:ids/editer-document',
        exact: true,
        sidebar: false,
        title: 'Editer un document',
        icon: '',
        permission: 'all',
        main: (props) => <EditDocumentContainer className='loader' {...props} />
    },
    {
        path: '/ma-reclamation/:id/:ids/ajout-suivi-et-cloture',
        exact: true,
        sidebar: false,
        title: 'Ajouter un chrono / agenda',
        icon: '',
        permission: 'all',
        main: (props) => <AddActivityReclamationContainer className='loader' {...props} cloture={true}/>
    },
    {
        path: '/ma-reclamation/:id/:ids',
        exact: true,
        sidebar: false,
        title: 'Ma réclamation',
        icon: '',
        permission: 'all',
        main: (props) => <ReclamationDetailContainer className={"loader"} {...props} />
    },
    {
        path: '/ma-reclamation/:id/:idc/edit',
        exact: true,
        sidebar: false,
        title: 'Modifier ma réclamation',
        icon: '',
        permission: 'all',
        main: (props) => <ReclamationEditContainer className={"loader"} {...props} />
    },
    {
        path: '/ma-reclamation/transfert-niveau-2/:id/:ids',
        exact: true,
        sidebar: false,
        title: 'Transfert de ma réclamation',
        icon: '',
        permission: 'all',
        main: (props) => <ReclamationTransfertContainer className={"loader"} {...props} />
    },
    {
        path: '/ma-reclamation/cloturer/:id/:ids',
        exact: true,
        sidebar: false,
        title: 'Cloturer ma réclamation',
        icon: '',
        permission: 'all',
        main: (props) => <ReclamationCloseContainer className={"loader"} {...props} />
    },
    {
        path: '/contrat/:id/remise-en-vigueur',
        exact: true,
        sidebar: false,
        title: 'Remise en vigueur du contrat',
        icon: '',
        permission: 'all',
        main: (props) => <RevContratContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/expiration-sejour',
        exact: true,
        sidebar: false,
        title: 'Ajouter une date d\'expiration du titre administratif',
        icon: '',
        permission: 'all',
        main: (props) => <ExpirationDateSejourContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/procedure-signature',
        exact: true,
        sidebar: false,
        title: 'Envoyer la procédure de signature électronique',
        icon: '',
        permission: 'all',
        main: (props) => <SendSignatureContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/sinistre/:ids/modification-reparateur',
        exact: true,
        sidebar: false,
        title: 'Modification du réparateur',
        icon: '',
        permission: 'update accident informations',
        main: (props) => <ChangeReparateurAccidentContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/sinistre/:ids/ajout-accessoires',
        exact: true,
        sidebar: false,
        title: 'Ajout d\'accessoires',
        icon: '',
        permission: 'update accident informations',
        main: (props) => <AddAccessoiresContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/sinistre/:ids/ajout-rapport-expert',
        exact: true,
        sidebar: false,
        title: 'Ajout d\'accessoires',
        icon: '',
        permission: 'update accident informations',
        main: (props) => <AddExpertReportContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/sinistre/:ids/edit-accessoires/:accessoire',
        exact: true,
        sidebar: false,
        title: 'Edition d\'accessoires',
        icon: '',
        permission: 'update accident informations',
        main: (props) => <EditAccessoiresContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/sinistre/:ids/modification-remboursement-reparateur',
        exact: true,
        sidebar: false,
        title: 'Modification du remboursement',
        icon: '',
        permission: 'update accident informations',
        main: (props) => <ChangeRemboursementAccidentContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/sinistre/:ids/modification-compagnie',
        exact: true,
        sidebar: false,
        title: 'Modification de la compagnie adverse',
        icon: '',
        permission: 'update accident company',
        main: (props) => <ChangeCompagnieAccidentContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/sinistre/:ids/modification-statut',
        exact: true,
        sidebar: false,
        title: 'Modification du statut du sinistre',
        icon: '',
        permission: 'update accident informations',
        main: (props) => <ChangeAccidentStatusContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/sinistre/:ids/modification-responsabilite',
        exact: true,
        sidebar: false,
        title: 'Modification du taux de responsabilité',
        icon: '',
        permission: 'update accident responsabilite',
        main: (props) => <ChangeResponsabiliteContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/sinistre/:ids/modification-conducteur',
        exact: true,
        sidebar: false,
        title: 'Modification du taux de responsabilité',
        icon: '',
        permission: 'update accident informations',
        main: (props) => <ChangeConducteurContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/sinistre/:ids/modification-date-expert',
        exact: true,
        sidebar: false,
        title: 'Modification de la date de passage de l\'expert',
        icon: '',
        permission: 'update accident informations',
        main: (props) => <ChangeDateExpertVisitAccidentContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/sinistre/:ids/modification-penalites',
        exact: true,
        sidebar: false,
        title: 'Ajout/Modification de la pénalité du dossier',
        icon: '',
        permission: 'update accident informations',
        main: (props) => <AddPenaltiesContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/sinistre/:ids/modification-oppositions',
        exact: true,
        sidebar: false,
        title: 'Ajout/Modification de l\'opposition du dossier',
        icon: '',
        permission: 'update accident informations',
        main: (props) => <AddOppositionsContainer className='loader' {...props} />
    },
    {
        path: '/contrat/:id/sinistre/:ids/edition-sinistre',
        exact: true,
        sidebar: false,
        title: 'Modifier le sinistre',
        icon: '',
        permission: 'update accident informations',
        main: (props) => <UpdateAccidentContainer className='loader' {...props} />
    },
    {
        path: '/requetes',
        exact: true,
        sidebar: true,
        title: 'Requetes',
        icon: '',
        permission: 'all',
        main: (props) => <RequetesContainer className='loader' {...props} />
    },
    {
        path: '/comptabilite/reversement-compagnies',
        exact: true,
        sidebar: true,
        title: 'Comptabilité - Reversement compagnies',
        icon: '',
        permission: 'all',
        main: (props) => <ReversementCompagniesContainer className='loader' {...props} />
    },
    {
        path: '/tiers/:id/sinistres',
        exact: true,
        sidebar: false,
        title: 'Liste des sinistres',
        icon: '',
        permission: 'all',
        main: (props) => <AccidentsContainer className='loader' {...props} />
    },
    {
        path: '/tiers/:id/correspondant',
        exact: true,
        sidebar: false,
        title: 'Ajout d\'un correspondant',
        icon: '',
        permission: 'edit correspondent',
        main: (props) => <AddCorrespondantContainer className='loader' {...props} />
    },
    {
        path: '/tiers/:id/edition',
        exact: true,
        sidebar: false,
        title: 'Edition d\'un tiers',
        icon: '',
        permission: 'create tiers',
        main: (props) => <EditTiersContainer className='loader' {...props} />
    },
    {
        path: '/tiers/:id/correspondant/:ids',
        exact: true,
        sidebar: false,
        title: 'Modification d\'un correspondant',
        icon: '',
        permission: 'edit correspondent',
        main: (props) => <EditCorrespondantContainer className='loader' {...props} />
    },
    {
        path: '/tiers/:id/commande',
        exact: true,
        sidebar: false,
        title: 'Commande de produits',
        icon: '',
        permission: 'order flyers',
        main: (props) => <CommandeProduitsContainer className='loader' {...props} />
    },
    {
        path: '/mes-devis',
        exact: true,
        sidebar: true,
        icon: '',
        title: 'Mes devis',
        permission: 'all',
        main: (props) => <DevisListeContainer {...props} />
    },
    {
        path: '/devis/:id',
        exact: true,
        sidebar: true,
        icon: '',
        title: 'Détail du devis',
        permission: 'all',
        main: (props) => <DevisDetailContainer {...props} />
    },
    {
        path: '/nouveau-devis',
        exact: true,
        sidebar: true,
        icon: '',
        title: 'Nouveau devis',
        permission: 'all',
        main: (props) => <NouveauDevisContainer {...props} />
    },
    {
        path: '/nouveau-devis-courtier',
        exact: true,
        sidebar: true,
        icon: '',
        title: 'Nouveau devis',
        permission: 'all',
        main: (props) => <NouveauDevisCourtier{...props} />
    },
]

export default PageRoutes
