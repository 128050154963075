import {createSelector} from 'reselect'
import _ from 'lodash'

const activitiesSelector = state => state.activities

export const getActivitiesData = createSelector(
    activitiesSelector,
    activities => activities.data
)

export const getHasLRSuiteRejets = createSelector(
    getActivitiesData,
    data => _.find(data, ['titre', 'Edition Courrier LR Suite Rejets']) || false
)
