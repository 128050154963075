import React from 'react';
import MyButton from "../../../../Commun/Button/Button";
import Spinner from "../../../../Commun/Spinner/Spinner";
import SelectFieldRedux from "../../../../Commun/Input/Select/SelectFieldRedux";
import {Field} from "redux-form";
import TextFieldRedux from "../../../../Commun/Input/Text/TextFieldRedux";


const ActivityTransfer = (props) => {
    const {history, handleSubmit, loading, type} = props

    return (
        <div className={'container'}>
            <div className={'row return-content'}>
                <div className={'col'}>
                    <button onClick={() => history.go(-1)} className={'btn btn-return'}>Retour</button>
                </div>
            </div>
            <div className={'header-row'}>
                <div className={'col-12'}>
                    <form className={'form-content row'} onSubmit={handleSubmit}>
                        <div className={'col-12'}>
                            <Field
                                name='type'
                                component={SelectFieldRedux}
                                label={'Vous souhaitez transférer le chrono sur :'}
                                required
                            >
                                <option value={""}>Sélectionnez un type</option>
                                <option value="contrat">un contrat</option>
                                <option value="sinistre">un sinistre</option>
                                <option value="reclamation">une réclamation</option>
                            </Field>

                            {
                                type &&
                                <>
                                    <Field
                                        name={'new_id'}
                                        component={TextFieldRedux}
                                        label={`Numéro de ${type === "reclamation" ? 'réclamation' : type}`}
                                        required
                                    />

                                    <div className={'alert alert-warning'}>
                                        <p>Attention à bien vérifier le numéro saisi avant de valider le transfert.</p>
                                    </div>
                                </>
                            }

                            <MyButton
                                variant='raised'
                                color='secondary'
                                size='large'
                                className={'btn btn-primary'}
                                disabled={loading}
                            >
                                {loading && <Spinner absolute/>}
                                Valider
                            </MyButton>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};


export default ActivityTransfer;