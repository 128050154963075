import React, {Fragment} from 'react'
import _ from "lodash";
import CallToAction from "../../../../Commun/Button/CallToAction/CallToAction";

const Opposition = (props) => {
    const {oppositions, permissions, contract, accident, submitDeleteOppositions} = props
    return (
        <div className={'bloc-info bloc-penalites'}>
            <h2>Opposition</h2>

            {_.includes(permissions, 'update accident informations') &&
                <Fragment>
                    {
                        oppositions &&
                        <CallToAction
                            onClick={() => {
                                if (window.confirm(`Etes-vous sûr de vouloir supprimer l'opposition`)) submitDeleteOppositions()
                            }}
                            action={'delete-penalties'}
                            toolText={'Supprimer l\'opposition'}
                        />
                    }
                    <CallToAction
                        url={{
                            pathname: `/contrat/${contract}/sinistre/${accident}/modification-oppositions`,
                        }}
                        action={'edit-repairer'}
                        toolText={ `${oppositions ? 'Modifier la' : 'Ajouter une'} opposition`}
                    />
                </Fragment>
            }
            <div className={'content row'}>
                <div className={'col-12'}>
                    <p>{oppositions ? `Montant de l'opposition: ${Number(oppositions).toFixed(2)}€` : 'Aucune opposition de renseignée pour ce sinistre'} </p>
                </div>
            </div>
        </div>
    )
}

export default Opposition
