const validate = values => {
    const errors = {}

    const requiredFields = [
        'nature',
        'issue',
        'description',
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Ce champ est obligatoire'
        }
    })


    return errors
}

export default validate
