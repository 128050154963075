import React, {useEffect} from 'react';
import {FieldArray} from "redux-form";
import FieldArrayTemoins from "./FieldArrayTemoins";

const Temoins = (props) => {
    useEffect(() => {
        document.getElementById('declaration-pers').scrollIntoView({behavior: 'smooth'})
    }, [])

    return (
        <div className="col-md-6 col-sm-12" style={{marginBottom: 30}}>
            <div className={`form-content h-100`}>
                <h1 className={'title-primary'}>Témoin(s)</h1>

                <FieldArray
                    name='temoins'
                    component={FieldArrayTemoins}
                    changeValue={props.changeValue}
                />
            </div>
        </div>
    );
};

export default Temoins;
