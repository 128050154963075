import React from 'react';
import FormAvenantSimpleContainer from "./FormAvenantSimple/FormAvenantSimpleContainer";
import Badge from "../../../../Commun/Badge/Badge";

const AvenantSimple = (props) => {
    const {history, contract, isWakam} = props

    return (
        <div className={'contract-container container'}>
            <div className={'row return-content'}>
                <div className={'col'}>
                    <button onClick={() => history.go(-1)} className={'btn btn-return'}>Retour</button>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-12'}>
                    <h1 className={'title-primary'}>{contract.souscripteur.titre} {contract.souscripteur.nom} {contract.souscripteur.id}</h1>
                    <Badge status={contract.situation.statut}/>
                </div>
                <div className={'col-12'}>
                    <span
                        className={'bold text-uppercase subtitle'}>Contrat n°{contract.numeroPolice} {!isWakam && `- ${contract.title}`}</span>
                </div>
            </div>
            <div className="row header-row">
                <div className={'col-12'}>
                    <FormAvenantSimpleContainer/>
                </div>
            </div>
        </div>
    );
};

export default AvenantSimple;
