import {SET_TARIF_CONTRACT} from '../../actions/app/contracts/contracts.actions'

export const tarifContractReducer = (tarif_contract = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_TARIF_CONTRACT) {
        return payload
    } else {
        return tarif_contract
    }
}
