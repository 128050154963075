import React from 'react'

export const Badge = ({status, code}) => {
    switch (code) {
        case '2':
            return <span className={`badge badge-secondary`}>{status}</span>
        case '1':
            return <span className={`badge badge-success`}>{status}</span>
        case '0':
        default:
            return <span className={`badge badge-warning`}>{status}</span>
    }
}

export default Badge
