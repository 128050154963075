import {useState, useEffect, Fragment} from 'react'
import {Field} from "redux-form";
import TextFieldRedux from "../../../../Commun/Input/Text/TextFieldRedux";
import MyButton from "../../../../Commun/Button/Button";
import Spinner from "../../../../Commun/Spinner/Spinner";
import SelectFieldRedux from "../../../../Commun/Input/Select/SelectFieldRedux";
import {
    typeSuivi,
    typeAgenda,
    typeAgendaAN,
    typeAgendaAG_AV,
    typeAgendaIndemn,
    typeAgendaResil,
    typeAgendaCompta,
    typeAgendaQualite,
    natureAppelSuivi,
    natureAutreSuivi,
    typeAgendaFid,
    motifSuivi,
    typePaniere,
    typeAgendaImpayes,
    paniereActivityCompta,
    typeAgendaSuiviFid
} from "../../../../../utils/optionsSelect";
import FileFieldRedux from "../../../../Commun/Input/File/FileFieldRedux";
import TextAreaFieldRedux from "../../../../Commun/Input/Text/TextAreaFieldRedux";
import RadioFieldRedux from "../../../../Commun/Input/Radio/RadioFieldRedux";
import DatePickerFieldRedux from "../../../../Commun/Input/DatePicker/DatePickerFieldRedux";
import moment from "moment";
import _ from "lodash";
import PropTypes from "prop-types";
import CheckboxFieldRedux from "../../../../Commun/Input/Checkbox/CheckboxFieldRedux";
import {normalizeBooleanForNoobBelair} from "../../../../../utils/normalizeInputs";

const AddActivity = (props) => {
    const [typeAgendaList, setTypeAgendaList] = useState(typeAgenda);

    const {
        loading,
        handleSubmit,
        onChangeValue,
        unsetValue,
        title,
        subtitle,
        nameConseiller,
        roles,
        clotureReclamation,
        categorie,
        type,
        paniere,
        activityType,
        isAuthorizedToCreate,
    } = props;

    const typeSuiviListe = typeSuivi;
    const [listeTypePaniere, setListeTypePaniere] = useState(typePaniere);
    const [list_categorie, setListeCategorie] = useState([{value: "chrono", label: "Un chrono"}, {value: "agenda", label: "Un agenda"}]);

    useEffect(() => {
        if (activityType === 'contract') {
            setListeTypePaniere([
                ...typePaniere,
                {code: 'AG_FID', libelle: 'Agenda Fidélisation'},
                {code: 'AG_SUIVI_FID', libelle: 'Agenda Suivi Fidélisation'}
            ]);
        }

        if (_.includes(roles, 'manager qualite')) {
            setListeTypePaniere(prev => [
                ...prev,
                {code: 'AG_QUALITE', libelle: 'Agenda Qualité'}
            ])
        }

        if (clotureReclamation) {
            setListeCategorie([{value: "chrono", label: "Un chrono"}]);
        } else if (_.includes(roles, 'gestionnaire compta')) {
            setListeCategorie([{value: "chrono", label: "Un chrono"}, {value: "agenda", label: "Un agenda"}, {value: "popup", label: "Un popup"}]);
        }
    }, [activityType, roles, clotureReclamation]);

    const handleChangeTitle = (value) => {
        if (value === 'Virement rejeté') {
            onChangeValue('commentaire', 'Le virement concernant le remboursement sinistre a été rejeté pour compte soldé. Effectuer un remboursement par chèque.');
        } else if (value === 'CB avant rejet') {
            onChangeValue('commentaire', 'Le client a réglé par CB son futur rejet. Lors de l\'import du rejet, affecter l\'acompte client sur le tableau de prélèvement.');
        }
    };

    const onChangeNature = (value) => {
        if (value === 'RJ') {
            onChangeValue('titre', 'Remboursement rejeté suite compte soldé');
            onChangeValue('commentaire', 'Une lettre-chèque sera envoyée dans la semaine');
        }
    };

    const changePaniere = (newValue) => {
        unsetValue('titre');

        if (newValue === 'AG_AN') {
            setTypeAgendaList(typeAgendaAN);
        } else if (newValue === 'AG_AV') {
            setTypeAgendaList(typeAgendaAG_AV);
        } else if (newValue === 'AG_INDEM') {
            setTypeAgendaList(typeAgendaIndemn);
        } else if (newValue === 'AG_RES') {
            setTypeAgendaList(typeAgendaResil);
        } else if (newValue === 'AG_COMPTA') {
            setTypeAgendaList(typeAgendaCompta);
        } else if (newValue === 'AG_IMPAYES') {
            setTypeAgendaList(typeAgendaImpayes);
        } else if (newValue === 'AG_QUALITE') {
            setTypeAgendaList(typeAgendaQualite);
            onChangeValue('titre', 'Suivi de la réclamation');
        } else if (newValue === 'AG_FID') {
            setTypeAgendaList(typeAgendaFid);
        } else if (newValue === 'AG_SUIVI_FID') {
            setTypeAgendaList(typeAgendaSuiviFid);
        } else {
            setTypeAgendaList(typeAgenda);
        }
    };

    return (
        <div className={'contract-container container'}>
            <div className={'row'}>
                <div className={'col-12'}>
                    <h1 className={'title-primary'}>{title}</h1>
                </div>
                {subtitle && (
                    <div className={'col-12'}>
                        <span className={'bold subtitle'}>
                            {subtitle}
                        </span>
                    </div>
                )}
            </div>
            <div className={'row header-row'}>
                <div className={'col-12'}>
                    <div className={'alert alert-info alert-rgpd'}>
                        <p>Merci de veiller à rester objectif dans votre saisie en évitant tout jugement de valeur,
                            ou collecte d'informations sensibles*. En cas de doute dans vos écrits, n'hésitez pas à
                            consulter votre Manager.</p>
                        <em>* État de santé, origine raciale, appartenance syndicale, opinion politique, opinion
                            religieuse, orientation sexuelle...( cf article 9-1 du RGPD)</em>
                    </div>
                </div>
                <div className={'col-12 header-row'}>
                    <form className={'form-content'} onSubmit={handleSubmit}>
                        <Field
                            name={'categorie'}
                            component={RadioFieldRedux}
                            label={'Choisissez un type de suivi :'}
                            data={list_categorie}
                            onChange={props.handleChange}
                        />
                        {categorie === 'agenda' && (
                            <Fragment>
                                <Field
                                    name={'paniere'}
                                    component={SelectFieldRedux}
                                    label={'A qui souhaitez-vous affecter l\'agenda ?'}
                                    required
                                    onChange={(e) => changePaniere(e.target.value)}
                                >
                                    <option value={null}>Sélectionner</option>
                                    <option value={`AG_${nameConseiller}`}>A moi-même</option>
                                    {_.orderBy(listeTypePaniere, ['libelle'], ['asc']).map((type, index) => (
                                        <option key={index} value={type.code}>{type.libelle}</option>
                                    ))}
                                </Field>
                                <Field
                                    name={"date_rappel"}
                                    component={DatePickerFieldRedux}
                                    label={'Date à laquelle déclencher l\'agenda'}
                                    minDate={moment()}
                                    maxDate={moment().add(1, 'Y')}
                                    value={moment()}
                                />
                                <Field
                                    name={'titre'}
                                    component={SelectFieldRedux}
                                    label={'Titre'}
                                    required
                                    onChange={(e) => handleChangeTitle(e.target.value)}
                                >
                                    <option value={null}>Sélectionner le titre</option>
                                    {typeAgendaList.map((type, index) => (
                                        <option key={index} value={type.code}>{type.libelle}</option>
                                    ))}
                                </Field>
                            </Fragment>
                        )}
                        {categorie === 'chrono' && (
                            <Fragment>
                                <Field
                                    name={'type'}
                                    component={SelectFieldRedux}
                                    label={'Type de suivi'}
                                    required
                                >
                                    <option value={null}>Sélectionner le type</option>
                                    {_.orderBy(typeSuiviListe, ['libelle'], ['asc']).map((type, index) => (
                                        <option key={index} value={type.code}>{type.libelle}</option>
                                    ))}
                                </Field>

                                {_.includes(['TELS', 'TELE', 'AUTRE', 'AUTRS', 'FIDE', 'FIDS'], type) && (
                                    <Field
                                        name={'nature'}
                                        component={SelectFieldRedux}
                                        label={'Nature'}
                                        required
                                        onChange={(e) => onChangeNature(e.target.value)}
                                    >
                                        <option value={null}>Sélectionner la nature</option>

                                        {_.includes(['TELS', 'TELE', 'FIDE', 'FIDS'], type) && (
                                            natureAppelSuivi.map((type, index) => (
                                                <option key={index} value={type.code}>{type.libelle}</option>
                                            ))
                                        )}
                                        {(type === 'AUTRE' || type === 'AUTRS') && (
                                            natureAutreSuivi.map((type, index) => (
                                                <option key={index} value={type.code}>{type.libelle}</option>
                                            ))
                                        )}
                                    </Field>
                                )}

                                {(_.includes(['AUTRE', 'EMAIE', 'TELE', 'COURE', 'FAXE', 'TCHAT', 'FDBCK', 'RESC', 'WDE', 'WEBE', 'FIDE'], type) && activityType === 'contract') && (
                                    <Field
                                        name="erreur_sous"
                                        component={CheckboxFieldRedux}
                                        label="Cela concerne-t-il une erreur de souscription ?"
                                        normalize={normalizeBooleanForNoobBelair}
                                    />
                                )}

                                {_.includes(['FIDE', 'FIDS'], type) ?
                                    <Field
                                        name={'titre'}
                                        component={SelectFieldRedux}
                                        label={'Motif'}
                                        required
                                    >
                                        <option value={null}>Sélectionner le motif</option>

                                        {
                                            motifSuivi.map((motif, index) => (
                                                <option key={index} value={motif.label}>{motif.label}</option>
                                            ))
                                        }
                                    </Field>
                                    :
                                    <Field
                                        name={'titre'}
                                        component={TextFieldRedux}
                                        label={'Motif'}
                                        subLabel={'50 caractères maximum'}
                                        maxLength={'50'}
                                        required
                                    />
                                }
                            </Fragment>
                        )}
                        {
                            categorie === 'popup' &&
                            <Fragment>
                                <Field
                                    name={'paniere'}
                                    component={SelectFieldRedux}
                                    label={'Sélectionnez le type de popup'}
                                    onChange={e => onChangeValue('titre', _.find(paniereActivityCompta, ['code', e.target.value]).title)}
                                    required
                                >
                                    <option value={null}>Sélectionner</option>
                                    {paniereActivityCompta.map((paniere, index) => (
                                        <option key={index} value={paniere.code}>{paniere.code}</option>
                                    ))}
                                </Field>
                            </Fragment>
                        }
                        <Field
                            name={'commentaire'}
                            component={TextAreaFieldRedux}
                            label={
                                paniere && paniere === 'REG_CHQ' ?
                                    'Motif du rejet' :
                                    _.includes(['FIDE', 'FIDS'], type) ?
                                        'Description (Mettre ici ce qu’on a proposé au client)' :
                                        'Description'
                            }
                            subLabel={'500 caractères maximum'}
                            maxLength={'500'}
                        />
                        {
                            categorie !== 'popup' &&
                            <Field
                                name={'files'}
                                component={FileFieldRedux}
                                label={'Fichier lié'}
                                onChangeValue={onChangeValue}
                            />
                        }
                        <MyButton
                            variant='raised'
                            color='secondary'
                            size='large'
                            className={'btn btn-primary'}
                            disabled={loading || !isAuthorizedToCreate}
                        >
                            {loading && <Spinner absolute/>}
                            Ajout du suivi
                        </MyButton>
                    </form>
                </div>
            </div>
        </div>
    )
}

AddActivity.defaultProps = {
    subtitle: '',
    situation: '',
    clotureReclamation: false,
    categorie: ''
};

AddActivity.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    situation: PropTypes.string,
    clotureReclamation: PropTypes.bool,
    loading: PropTypes.bool,
    categorie: PropTypes.string,
    nameConseiller: PropTypes.string.isRequired,
};

export default AddActivity;
