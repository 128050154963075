import {connect} from 'react-redux'
import {compose} from "redux";
import Tracfin from "./Tracfin";
import {clearFields, reduxForm} from "redux-form";
import loader from "../../../../../Commun/Spinner/Loader/Loader";
import ErrorHttpComponent from "../../../../../Commun/Error/ErrorHttpComponent";
import {
    postManageTracfinGetLink
} from "../../../../../../redux/selectors/links/links.selectors";
import _ from "lodash";
import {getErrorEntity, getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {
    POST_MANAGE_TRACFIN_TASK,
    postManageTracfinTask
} from "../../../../../../redux/actions/app/tasks/tasks.actions";
import validate from "./validate";

const mapStateToProps = (state,ownProps) => {
    return {
        task: ownProps.task,
        uri: postManageTracfinGetLink(state),
        terminateQuery: ownProps.terminateTask,
        loading: getLoadingEntity(state, POST_MANAGE_TRACFIN_TASK),
        error: getErrorEntity(state, POST_MANAGE_TRACFIN_TASK),
        isReassign: getErrorEntity(state, POST_MANAGE_TRACFIN_TASK) === false && getLoadingEntity(state, POST_MANAGE_TRACFIN_TASK) === false,
    }
}

const mapDispatchToProps = {
    postManageTracfinTask,
    unsetValue: (field) => clearFields('tache_tracfin', false, false, field),
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { uri, terminateQuery, task } = stateProps
    const { postManageTracfinTask } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form) => postManageTracfinTask({
            query: _.replace(uri, '{id}', task.tiers_id),
            form,
            task_id: task.id,
            terminateQuery
        })
    }
}

const TracfinContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'tache_tracfin',
        validate
    }),
    loader({ErrorIndicator: ErrorHttpComponent})
)(Tracfin)

export default TracfinContainer