import {
    ACTIVITIES,
    CLOSE_ACTIVITY,
    GET_ACTIVITIES,
    POST_CLOSE_ACTIVITY,
    setActivities, setCloseActivity
} from '../../../actions/app/grc/grc.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import _ from "lodash";

export const activitiesMiddleware = () => next => action => {
    next(action)

    let message = null
    switch (action.type) {

        case GET_ACTIVITIES:
            const {type, filters} = action.payload
            let doc_natures = [];
            let doc_types = [];
            let doc_interlocuteurs = [];
            let filter = [];

            if (type === "accident") {
                if (filters["a_traiter"] === 'true') {
                    filter = [`a_traiter=${filters["a_traiter"]}`, ...filter]
                }
                if (filters["doc_nature_CT"] === 'true') {
                    doc_natures = [`CT`, ...doc_natures]
                }
                if (filters["doc_nature_RX"] === 'true') {
                    doc_natures = [`RX`, ...doc_natures]
                }
                if (filters["doc_nature_FA"] === 'true') {
                    doc_natures = [`FA`, ...doc_natures]
                }
                if (filters["doc_nature_PV"] === 'true') {
                    doc_natures = [`PV`, ...doc_natures]
                }
                if (filters["doc_nature_PW"] === 'true') {
                    doc_natures = [`PW`, ...doc_natures]
                }
                if (filters["doc_nature_BD"] === 'true') {
                    doc_natures = [`BD`, ...doc_natures]
                }
            } else if (type === "contrat") {
                if (filters["doc_nature_contract_DE"] === 'true') {
                    doc_natures = [`DE`, ...doc_natures]
                }
                if (filters["doc_type_contract_FID"] === 'true') {
                    doc_types = ['FIDE', 'FIDS', ...doc_types]
                }

                if (filters["doc_interlocuteur_contract_AN"] === 'true') {
                    doc_interlocuteurs = ['ANUPLOAD', 'AN', 'AN_HAM', 'REP_AN', ...doc_interlocuteurs]
                }
            }

            if (!_.isEmpty(doc_natures)) {
                filter = [`doc_nature=${doc_natures.join()}`, ...filter]
            }

            if (!_.isEmpty(doc_types)) {
                filter = [`doc_type=${doc_types.join()}`, ...filter]
            }

            if (!_.isEmpty(doc_interlocuteurs)) {
                filter = [`doc_interlo=${doc_interlocuteurs.join()}`, ...filter]
            }

            let url = `${action.payload.data}?${filter.join('&')}`

            next([
                apiRequest({body: null, method: 'GET', url: url, entity: ACTIVITIES}),
                setLoader({state: true, entity: ACTIVITIES})
            ])
            break

        case `${ACTIVITIES} ${API_SUCCESS}`:
            const {data} = action.payload.data

            next([
                setActivities({data}),
                setError({state: false, entity: ACTIVITIES}),
                setLoader({state: false, entity: ACTIVITIES})
            ])
            break

        case `${ACTIVITIES} ${API_ERROR}`:
            next([
                setError({state: true, entity: ACTIVITIES}),
                setLoader({state: false, entity: ACTIVITIES})
            ])
            break

        case `${POST_CLOSE_ACTIVITY}`:
            next([
                apiRequest({body: null, method: 'POST', url: action.payload.data, entity: CLOSE_ACTIVITY}),
                setLoader({state: true, entity: CLOSE_ACTIVITY})
            ])
            break

        case `${CLOSE_ACTIVITY} ${API_SUCCESS}`:
            message = `<p>Le chrono a bien été clotûré</p>`
            next([
                setCloseActivity({state: true}),
                setNotification({entity: CLOSE_ACTIVITY, body: message, type: 'success', title: 'Chrono clotûré !'}),
                setError({state: false, entity: CLOSE_ACTIVITY}),
                setLoader({state: false, entity: CLOSE_ACTIVITY})
            ])
            break

        case `${CLOSE_ACTIVITY} ${API_ERROR}`:
            message = `<p>Erreur lors de la clotûre du chrono: ${action.payload.data.response.data?.message ?? ''}</p>`
            next([
                setNotification({
                    entity: CLOSE_ACTIVITY,
                    body: message,
                    type: 'error',
                    title: 'Attention !',
                    timeout: '0'
                }),
                setError({state: true, entity: CLOSE_ACTIVITY}),
                setLoader({state: false, entity: CLOSE_ACTIVITY})
            ])
            break

        default:
            break
    }
    return null
}
