import React from 'react';
import moment from "moment";

const DashboardTaskDetailsTelevente = (props) => {
    const {task} = props

    return (
        <div className={'contract-container container'}>
            <div className={'d-flex return-content'}>
                <div>
                    <button onClick={() => props.history.go(-1)} className={'btn btn-return'}>Retour</button>
                </div>
            </div>
            <div className={'d-flex'}>
                <div>
                    <h2 className={'title-primary'}>Détail de la tâche {task.subject}</h2>
                </div>
            </div>
            <div className={'d-flex header-d-flex'}>
                <div className={'col-12'}>
                    <div className={'form-content'}>
                        <div className={'row mb-4'}>
                            <div className={'col-2 text-right'}>Sujet&#8239;:</div>
                            <div className={'col-10'}><b>{task.subject}</b></div>
                        </div>
                        <div className={'row mb-4'}>
                            <div className={'col-2 text-right'}>Description&#8239;:</div>
                            <div className={'col-10'}><b dangerouslySetInnerHTML={{__html: task.description}}/></div>
                        </div>
                        <div className={'row mb-4'}>
                            <div className={'col-2 text-right'}>Date d'échéance&#8239;:</div>
                            <div className={'col-10'}>
                                <b>{task.due_date ? moment(task.due_date).format('DD/MM/YYYY HH:mm') : ''}</b></div>
                        </div>
                        <div className={'row mb-4'}>
                            <div className={'col-2 text-right'}>Priorité&#8239;:</div>
                            <div className={'col-10'}><b>{task.task_priority_name}</b></div>
                        </div>
                        <div className={'row mb-4'}>
                            <div className={'col-2 text-right'}>Assigné à&#8239;:</div>
                            <div className={'col-10'}><b>{task.assigne}</b></div>
                        </div>
                        <div className={'row mb-4'}>
                            <div className={'col-2 text-right'}>Statut&#8239;:</div>
                            <div className={'col-10'}><b>{task.statut}</b></div>
                        </div>
                        <div className={'row mb-4'}>
                            <div className={'col-2 text-right'}>Type&#8239;:</div>
                            <div className={'col-10'}><b>{task.type ?? '-'}</b></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardTaskDetailsTelevente;
