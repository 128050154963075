import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import SendSignature from './SendSignature'
import {postSendSignature, SEND_SIGNATURE} from '../../../../../redux/actions/app/signatures/signatures.actions'
import {contractDefaultGetLink, sendSignatureGetLink} from '../../../../../redux/selectors/links/links.selectors'
import ErrorHttpComponent from '../../../../Commun/Error/ErrorHttpComponent'
import _ from "lodash";
import {change, reduxForm} from "redux-form";
import validate from "../AddActivity/validate";
import {
    getContractData,
    getContractEmailSouscripteur, getContractPhoneSouscripteur
} from "../../../../../redux/selectors/contract/contract.selectors";
import {CONTRACT, getContract} from "../../../../../redux/actions/app/contracts/contracts.actions";
import {getErrorEntity, getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state) => {
    return {
        contract: getContractData(state),
        uri: sendSignatureGetLink(state),
        loaded: getLoadingEntity(state, CONTRACT) === false,
        loading: getLoadingEntity(state, SEND_SIGNATURE),
        error: getErrorEntity(state, CONTRACT),
        uriContrat: contractDefaultGetLink(state),
        phone: getContractPhoneSouscripteur(state),
        email: getContractEmailSouscripteur(state)
    }
}
const mapDispatchToProps = dispatch => bindActionCreators({
    changeValue: (field, value) => change('sendSignature', field, value),
    submitPostSendSignature: ({query, customer_email, customer_phone}) => postSendSignature({
        query,
        customer_email,
        customer_phone
    }),
    onChangeValue: (name, file) => change('sendSignature', name, file),
    getContract: (query) => getContract({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {submitPostSendSignature, getContract} = dispatchProps
    const {uri, uriContrat} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getContract(_.replace(uriContrat, '{id}', ownProps.match.params.id)),
        onSubmit: (form) => submitPostSendSignature({
            customer_email: form.email || null,
            customer_phone: form.phone || null,
            query: _.replace(uri, '{id}', ownProps.match.params.id)
        })
    }
}

const SendSignatureContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'sendSignature',
        validate
    }),
    loader({ErrorIndicator: ErrorHttpComponent})
)(SendSignature)

export default SendSignatureContainer
