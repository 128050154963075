import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import Facture from "./Facture";
import {formValueSelector, reduxForm, change} from "redux-form";
import ErrorHttpComponent from "../../../../../Commun/Error/ErrorHttpComponent";
import {getErrorEntity, getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {CLOSE_TASK} from "../../../../../../redux/actions/app/tasks/tasks.actions";
import {
    ADD_ACCESSOIRES,
    postAddAccessoires
} from "../../../../../../redux/actions/app/accessoires/accessoires.actions";
import {getAccident} from "../../../../../../redux/actions/app/accidents/accidents.actions";
import {updateAccidentAccessoiresGetLink} from "../../../../../../redux/selectors/links/links.selectors";
import _ from "lodash";
import loader from "../../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector('facture')

    return {
        task: ownProps.task,
        uri: updateAccidentAccessoiresGetLink(state),
        terminateQuery: ownProps.terminateTask,
        loadingTerminate: getLoadingEntity(state, CLOSE_TASK),
        errorTerminate: getErrorEntity(state, CLOSE_TASK),
        loadingAddFacture: getLoadingEntity(state, ADD_ACCESSOIRES),
        errorAddFacture: getErrorEntity(state, ADD_ACCESSOIRES),
        accessoires: selector(state, 'accessoires'),
    }
}
const mapDispatchToProps = dispatch => bindActionCreators({
    getAccident: (query) => getAccident({query}),
    postAddAccessoires: ({query, form, accident, terminateQuery}) => postAddAccessoires({
        query,
        form,
        accident,
        terminateQuery
    }),
    change: (field, value) => change('facture', field, value)
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {postAddAccessoires} = dispatchProps
    const {uri, terminateQuery, task} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form) => postAddAccessoires({
            query: _.replace(_.replace(uri, '{id}', task.contract.id), '{ids}', task.sinistre.id),
            form,
            accident: task.sinistre.id,
            terminateQuery
        }),
    }
}

const FactureContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'facture'
    }),
    loader({ErrorIndicator: ErrorHttpComponent})
)(Facture)

export default FactureContainer
