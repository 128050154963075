
import {APP_CLIENT_ID, APP_GRANT_TYPE, APP_PROVIDER} from "../../../../constants/constants";

export const LOGIN = '[Login]'
export const LOGOUT = '[Logout]'

export const POST_LOGIN = `${LOGIN} Post`
export const POST_LOGOUT = `${LOGOUT} Post`
export const POST_LOGIN_FAILED = `${LOGIN} Failed`
export const POST_LOGIN_SUCCESS = `${LOGIN} Success`

export const postLogin = ({username, password, query}) => ({
    type: POST_LOGIN,
    payload: {
        data: query,
        body: ({
            username,
            password,
            client_id: APP_CLIENT_ID,
            grant_type: APP_GRANT_TYPE,
            provider: APP_PROVIDER,
            scope: '*'
        })
    }
})

export const postLoginSuccess = () => ({
    type: POST_LOGIN_SUCCESS,
    meta: {
        analytics: {
            event: 'click',
            actionGoogle: 'loginSucces',
            libelle: 'Connexion réussie',
        },
        entity: LOGIN
    }
})

export const postLoginFailed = () => ({
    type: POST_LOGIN_FAILED,
    meta: {
        analytics: {
            event: 'click',
            actionGoogle: 'loginEchec',
            libelle: 'Connexion échouée',
        },
        entity: LOGIN
    }
})

export const postLogout = ({query}) => ({
    type: POST_LOGOUT,
    payload: {
        data: query
    },
    meta: {
        analytics: {
            event: 'click',
            actionGoogle: 'clickButton',
            libelle: 'Déconnexion',
        }
    }
})
