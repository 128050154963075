import {SET_FAQ_CAT} from "../../actions/app/faq_categories/faq_categories.actions";

export const faqCategoriesReducer = (faq_cat = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_FAQ_CAT) {
        return payload.data
    } else {
        return faq_cat
    }
}
