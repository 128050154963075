import React, {Fragment} from 'react'
import moment from "moment";
import {redirectInNewWindow} from "../../../../../utils/function";

const Signatures = (props) => {
    const {signatures, contract} = props

    return (
        <Fragment>
            {signatures && signatures.length > 0 &&
                <div className={'row'}>
                    <div className={'col-12'}>
                        <div className={'alert alert-warning row'}>
                            <div className={'col-8'}>
                                <strong>Signature électronique</strong>
                                <p>Une procédure de signature électronique est en cours sur ce
                                    contrat. {signatures[0].started_at && `Elle expire le ${moment(signatures[0].started_at).add(30, 'days').format('L')}`}</p>
                            </div>
                            <div className={'col-4'}>
                            <span className={'btn btn-secondary btn-small float-right'}
                                  onClick={(e) => redirectInNewWindow({
                                      e,
                                      url: `/contrat/${contract}/procedure-signature`
                                  })}>
                                Renvoyer la procédure
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    )
}

export default Signatures
