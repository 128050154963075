import { useQuery } from '@tanstack/react-query';
import { requestPass, requestServices } from '../utils/request';

export const useAddress = (postalCode, locality, value, notFind, isFound) =>
    useQuery({
        queryKey: ['address', postalCode, locality, value],
        queryFn: () =>
            requestServices
                .get('/check-address', {
                    params: {
                        postalCode,
                        locality,
                        value,
                    },
                })
                .then((res) => res.data),
        enabled: !!postalCode && !!value && value.length >= 5 && !notFind && !isFound,
        retry: 0,
    });

export const useTownByZipCode = (zipCode) => {
    return useQuery({
        queryKey: ['towns', zipCode],
        queryFn: () => requestPass.get(`/towns/${zipCode}`).then((res) => res.data),
        enabled: Boolean(zipCode) && zipCode.toString().length === 5,
    });
};

export const useIban = (iban) =>
    useQuery({
        queryKey: ['iban', iban],
        queryFn: () => requestServices.get(`/iban/${iban}`).then((res) => res.data),
        enabled: !!iban && iban.length >= 24 && iban.length <= 32,
        retry: 0,
    });
