import {connect} from 'react-redux'
import {formValueSelector} from 'redux-form'
import DommageVehicule from './DommageVehicule'

const mapStateToProps = state => {
    const selector = formValueSelector('constat')

    return {
        garage: selector(state, 'vehicule'),
    }
}

const DommageVehiculeContainer = connect(mapStateToProps)(DommageVehicule)

export default DommageVehiculeContainer
