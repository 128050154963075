import React, { Fragment, useState } from 'react';
import { Field } from "redux-form";
import _ from 'lodash';
import RadioFieldRedux from "../../../../../../../Commun/Input/Radio/RadioFieldRedux";
import TextFieldRedux from "../../../../../../../Commun/Input/Text/TextFieldRedux";
import SelectFieldRedux from "../../../../../../../Commun/Input/Select/SelectFieldRedux";
import moment from "moment/moment";
import DatePickerFieldRedux from "../../../../../../../Commun/Input/DatePicker/DatePickerFieldRedux";

const DeclarationBis = ({
                            codeProduit,
                            setEmailToClient,
                            moreDamages,
                            cylindree,
                            sraCertified,
                            categorie,
                            vehiculeDommage,
                            type_sinistre,
                            vehiculeIsAttached,
                            antiVolMecanique,
                            garanties,
                            changeValidSecondCheck,
                            blesse,
                            indentify,
                            temoin,
                            isWakam,
                            corpoconduct
                        }) => {
    const [alert, setAlert] = useState(false);

    const categoryNeedIdentify = ['104.1', '104.4', '104.3'];

    const checkIfNotDTA = () => !_.find(garanties, ['code_famille', 'DTA']);
    const checkIfNotIncWakam = () => !_.find(garanties, ['code_famille', 'INC']);
    const checkIfNotVI = () => !_.find(garanties, ['code_famille', 'VI']);
    const checkIfNotPCC = () => !_.find(garanties, g => _.includes(['PCC1', 'PCC2', 'PCC3', 'PCC0'], g.code_famille));
    const checkIfNotIC = () => !_.find(garanties, g => _.includes(['IC1', 'IC2'], g.code_famille));
    const checkIfNotASSorASSP = () => !_.find(garanties, g => _.includes(['ASS', 'ASSP', 'ASSVR', 'ASST'], g.code_famille));

    const setRefusContinuePreDec = (cause) => {
        handleAlert(true);
        changeValidSecondCheck(false);
        setEmailToClient(cause);
    };

    const checkStep = () => {
        /* Accident Seul */
        if (categorie === "104.2") {
            if ((corpoconduct === "F" && checkIfNotDTA() && blesse === "F") ||
                (blesse === "F" && checkIfNotPCC() && checkIfNotIC() && checkIfNotDTA() && corpoconduct === "T")
            ) {
                if (checkIfNotASSorASSP()) {
                    setRefusContinuePreDec("accident_seul");
                } else {
                    setRefusContinuePreDec("accident_seul_ASS");
                }
                return false;
            }
        }
        /* Délit de fuite */
        if (categorie === "104.1") {
            if (indentify === "F" && temoin === "F" && checkIfNotPCC() && checkIfNotIC() && checkIfNotDTA()) {
                if (checkIfNotASSorASSP()) {
                    setRefusContinuePreDec("delit_fuite");
                } else {
                    setRefusContinuePreDec("delit_fuite_ASS");
                }
                return false;
            }
        }
        /* Stationnement */
        if (categorie === "104.3") {
            if (indentify === "F" && temoin === "F" && checkIfNotDTA()) {
                setRefusContinuePreDec("stationnement");
                return false;
            }
        }
        /* Animal */
        if (categorie === "104.4") {
            if (indentify === "F" && checkIfNotPCC() && checkIfNotIC() && checkIfNotDTA()) {
                if (checkIfNotASSorASSP()) {
                    setRefusContinuePreDec("choc_animal");
                } else {
                    setRefusContinuePreDec("choc_animal_ASS");
                }
                return false;
            }
        }
        /* Vol, Tentative de Vol, Vol retrouvé, Vol d'accessoires */
        if (categorie === "10.0" || categorie === "105.1" || categorie === "9.0" || categorie === "105.2") {
            if ((sraCertified === "F" || vehiculeIsAttached === "F") && cylindree === 50) {
                setRefusContinuePreDec("vehicule_non_attache_agree_sra");
                return false;
            }
        }
        /* Incendie */
        if (categorie === "18.0") {
            if(isWakam && checkIfNotIncWakam()){
                setRefusContinuePreDec("incendie");
            }
            if (!isWakam && moreDamages === "F" && checkIfNotVI()) {
                if (checkIfNotASSorASSP()) {
                    setRefusContinuePreDec("incendie");
                } else {
                    setRefusContinuePreDec("incendie_ASS");
                }
                return false;
            }
        }

        handleAlert(false);
        changeValidSecondCheck(true);
    };


    const handleAlert = (alert) => {
        setAlert(alert);
    };

    return (
        <div className={'form-content'}>
            <h1 className={'title-primary'}>Dites-nous en plus...</h1>

            {
                type_sinistre === 'accident' || (codeProduit === '7311_VAE' && (categorie === '103.1' || categorie === '104.2')) ?
                    <Fragment>
                        {
                            _.includes(categoryNeedIdentify, categorie) && <Field
                                name='indentify'
                                component={RadioFieldRedux}
                                label={'La partie adverse est elle identifiée ?'}
                                data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                            />
                        }

                        <Field
                            name='corpoconduct'
                            component={RadioFieldRedux}
                            label={'Le conducteur est-il blessé ?'}
                            data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                        />

                        <Field
                            name='blesse'
                            component={RadioFieldRedux}
                            label={'Y-a-t-il d\'autre(s) blessé(s) ?'}
                            data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                        />


                        <Field
                            name='temoin'
                            component={RadioFieldRedux}
                            label={'Y-a-t-il des témoins ?'}
                            data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                        />

                        { isWakam && categorie === '103.2' &&
                            <Field
                                name='nb_vehicules'
                                component={SelectFieldRedux}
                                label={'Combien de véhicules sont impliqués sur ce sinistre ?'}
                            >
                                <option value={null}>Sélectionnez le nombre</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                            </Field>
                        }

                    </Fragment>
                    : type_sinistre === 'vol' || (codeProduit === '7311_VAE' && (categorie === '10.0' || categorie === '105.1')) ?
                        <Fragment>
                            <Field
                                name='vehiculeIsInGarage'
                                component={RadioFieldRedux}
                                label={'Votre véhicule était-il dans un garage ?'}
                                data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}, {
                                    value: "unknown",
                                    label: "Ne sais pas"
                                }]}
                            />

                            <Field
                                name='vehiculeIsMark'
                                component={RadioFieldRedux}
                                label={'Votre véhicule était-il gravé ?'}
                                data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}, {
                                    value: "unknown",
                                    label: "Ne sais pas"
                                }]}
                            />

                            <Field
                                name='vehiculeIsAttached'
                                component={RadioFieldRedux}
                                label={'Votre véhicule était-il attaché avec un antivol au moment du vol ?'}
                                data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}, {
                                    value: "unknown",
                                    label: "Ne sais pas"
                                }]}
                            />

                            {
                                vehiculeIsAttached === 'T' && <Fragment>
                                    <Field
                                        name='antiVolMecanique'
                                        component={RadioFieldRedux}
                                        label={'Quel(s) anti-vol(s) possédez vous ?'}
                                        type={'checkbox'}
                                        data={[{label: "Mécanique"}]}
                                    />
                                    {
                                        antiVolMecanique && <Field
                                            name='sraCertified'
                                            component={RadioFieldRedux}
                                            label={'Agréé SRA ?'}
                                            data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                                        />
                                    }
                                    <Field
                                        name='antiVolElectronique'
                                        component={RadioFieldRedux}
                                        type={'checkbox'}
                                        data={[{label: "Electronique"}]}
                                    />
                                    <Field
                                        name='antiVolAntiDemarrage'
                                        component={RadioFieldRedux}
                                        type={'checkbox'}
                                        data={[{label: "Anti démarrage"}]}
                                    />
                                </Fragment>
                            }
                        </Fragment>
                        : <Field
                            name='moreDamages'
                            component={RadioFieldRedux}
                            label={"Votre véhicule a-t-il causé des dommages à d'autres biens ou personnes ?"}
                            data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                        />
            }

            {
                isWakam && categorie !== "10.0" && <Field
                    name={"birthdate_conduct"}
                    component={DatePickerFieldRedux}
                    label={'Date de naissance'}
                />
            }

            {alert && <div className="alert alert-danger" role="alert">
                <p>Les garanties du contrat et les éléments de la déclaration ne permettent pas de donner suite à
                    cette déclaration.</p>
                <p>Une confirmation est adressée au client.</p>
            </div>}

            <span className={'btn btn-primary btn-lg float-right'}
                  onClick={() => checkStep()}
            >
                Poursuivre
            </span>
        </div>
    );
};

export default DeclarationBis;
