import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {change, reduxForm} from 'redux-form'
import _ from "lodash";
import ShiftDateTask from "./ShiftDateTask";
import {getTask, postShiftDateTask, SHIFT_DATE_TASK, TASK} from "../../../../../redux/actions/app/tasks/tasks.actions";
import validate from './validate'
import {getErrorEntity, getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";

import {taskDetailGetLink, taskShiftDateGetLink} from "../../../../../redux/selectors/links/links.selectors";
import {getTaskDetailData} from "../../../../../redux/selectors/tasks/task.selectors";
import moment from "moment";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {

    return {
        task: getTaskDetailData(state),
        loaded: getLoadingEntity(state, TASK) === false,
        uri_task: taskDetailGetLink(state),
        task_id: ownProps.match.params.id,
        loading: getLoadingEntity(state, SHIFT_DATE_TASK),
        is_shifted: getLoadingEntity(state, SHIFT_DATE_TASK) === false && getErrorEntity(state, SHIFT_DATE_TASK) === false,
        uri: taskShiftDateGetLink(state),
        initialValues: {
            date: moment(),
        }
    }
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
    getTask: (query) => getTask({query}),
    changeValue: (field, value) => change('shiftDateTaskForm', field, value),
    postShiftDateTask,
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {postShiftDateTask, getTask} = dispatchProps
    const {task_id, uri, uri_task} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getTask(_.replace(uri_task, '{id}', task_id)),
        onSubmit: (form) => {
            postShiftDateTask({
                query: _.replace(uri, '{id}', task_id),
                form
            })
        }
    }
}
const ShiftDateTaskContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'shiftDateTaskForm',
        validate
    }),
    loader()
)(ShiftDateTask)

export default ShiftDateTaskContainer
