import React from 'react'
import moment from "moment";
import ReaderJpeg from "../../../../../Commun/ReaderJPEG/ReaderJPEG";
import APIFrame from "../../../../../Commun/APILink/APIFrame";

const InfoCoti = (props) => {
    const {task: {title, description, date, type, paniere, tiers_id,contract}, files} = props
    let type_contrat = contract.apporteur1 === '43397' ? 'client' : 'courtier'

    return (
        <div className={'container'}>
            <div className={'row'}>
                <div className={'col text-center'}>
                    <h2 className={'title-primary text-uppercase'}>{paniere} - Tiers n°{tiers_id}</h2>
                    <p className={'subtitle'}>Tâche du {moment(date).format('L')}. Recu par {type}</p>
                </div>
            </div>
            <div className={'header-row row'}>
                <div className={'col-12'}>
                    <p className={'form-content text-center'}>
                        Type : {title}<br/>
                        Description : {description ? <span dangerouslySetInnerHTML={{__html: description}}/> : '-'}
                    </p>

                    <p className={'form-content text-center'}>
                        Nom du {type_contrat} :&nbsp;
                        {contract.apporteur1 === '43397' ? contract.souscripteur.nom : contract.apporteurs.apporteur1.raisonSociale}
                        <br/>
                        Numéro du {type_contrat} :&nbsp;
                        {contract.apporteur1 === '43397' ? contract.souscripteur.phone : contract.apporteurs.apporteur1.phone}
                        <br/>
                        Email du {type_contrat} :&nbsp;
                        {contract.apporteur1 === '43397' ? contract.souscripteur.email : contract.apporteurs.apporteur1.email}
                    </p>
                </div>
            </div>

            {files !== undefined && files.map((file) => (
                file.extension.toLowerCase() === 'pdf' ?
                    <div className={'row'}>
                        <div className={'col-12 mt-5'} style={{marginBottom: 30}}>
                            <APIFrame src={`${file.url}`} title={`${file.code}`} frameBorder="0"
                                    style={{height: '100%', width: '100%', marginBottom: 30}}/>
                        </div>
                    </div>
                    :
                    <div className={'row justify-content-center'}>
                        <ReaderJpeg url={file.url}/>
                    </div>
            ))}
        </div>
    )
}

export default InfoCoti
