import {SET_ACCIDENT} from '../../actions/app/accidents/accidents.actions'

export const accidentReducer = (accident = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_ACCIDENT) {
        return payload
    } else {
        return accident
    }
}
