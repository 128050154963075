import {useQuery} from "@tanstack/react-query";
import {requestPass} from "../utils/request";

export const useIntervenants = (type, search) => {
    return useQuery({
        queryKey: ['intervenants', type, search],
        queryFn: () => requestPass.get('tiers-intervenants/search', {
            params : {
                type,
                search
            }
        }).then(res => res.data),
        enabled: !!type && !!search
    });
};
