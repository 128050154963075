export const VALIDATE_DOCUMENT = '[Validation Document]'
export const VALIDATE_DOCUMENTS_TASK = '[Validation Documents Task]'

export const POST_VALIDATE_DOCUMENT = `${VALIDATE_DOCUMENT} Post`
export const POST_VALIDATE_DOCUMENTS_TASK = `${VALIDATE_DOCUMENTS_TASK} Post`

export const postValidateDocument = ({body, form, query, addDoc = false, formName, kofax}) => ({
    type: POST_VALIDATE_DOCUMENT,
    payload: {
        data: query,
        body: {...body, ...form},
        otherData: {
            addDoc,
            formName,
            kofax,
            sinistre: body.sinistre
        }
    }
})

export const postValidateDocumentsTask = ({tiers, risque, pieces, query, queryCloseTask, sinistre = null}) => ({
    type: POST_VALIDATE_DOCUMENTS_TASK,
    payload: {
        data: query,
        body: {tiers, risque, sinistre, pieces},
        otherData: {
            queryCloseTask
        }
    }
})
