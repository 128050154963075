import {connect} from 'react-redux'
import {formValueSelector} from 'redux-form'
import Declaration from './Declaration'
import {
    getAdhesionPrincipale,
    getContractAfnDate, getContractCodeProduit,
    getContractGarantiesOptions, getContractId, getContractPrelevement,
    getContractResilDate, getContractSituationDate, getIsFleetContract
} from "../../../../../../../../redux/selectors/contract/contract.selectors";
import {bindActionCreators, compose} from "redux";
import {
    accidentsContractGetLinkWithoutIdUrl, contractsActivityGetLink,
    documentsNeededAccidentGet, piecesContractWithoutIdGetLink
} from "../../../../../../../../redux/selectors/links/links.selectors";
import {
    getAccidents,
    getDocumentsAccident
} from "../../../../../../../../redux/actions/app/accidents/accidents.actions";
import _ from 'lodash'
import {getAccidentsData} from "../../../../../../../../redux/selectors/accidents/accidents.selectors";

import ErrorHttpComponent from "../../../../../../../Commun/Error/ErrorHttpComponent";
import {getGarantiesFlotteDetailData} from "../../../../../../../../redux/selectors/flotte/flotte.selectors";
import {getPiecesData} from "../../../../../../../../redux/selectors/pieces/pieces.selector";
import {getPieces, PIECES} from "../../../../../../../../redux/actions/app/pieces/pieces.actions";
import {getLoadingEntity} from "../../../../../../../../redux/selectors/ui/ui.selectors";
import {getActivities} from "../../../../../../../../redux/actions/app/grc/grc.actions";
import {getHasLRSuiteRejets} from "../../../../../../../../redux/selectors/grc/grc.selectors";
import loader from "../../../../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    const selector = formValueSelector('declaration-sinistre')
    const pieces = getPiecesData(state)
    let pieceSelected;
    if (pieces) {
        pieceSelected = _.find(pieces, ['adhesionPrincipale', selector(state, 'risque')])
    }

    return {
        uri_pieces: piecesContractWithoutIdGetLink(state),
        hasLRSuiteRejets: getHasLRSuiteRejets(state),
        uri_activities: contractsActivityGetLink(state),
        pieces: pieces,
        type_sinistre: selector(state, 'type_sinistre'),
        catsinorigin: selector(state, 'catsinorigin'),
        garanties: getIsFleetContract(state) ? getGarantiesFlotteDetailData(state) : pieceSelected ? pieceSelected.garanties : getContractGarantiesOptions(state),
        dateFinContrat: pieceSelected ? pieceSelected.dateFin : getContractResilDate(state),
        datesurvorigin: selector(state, 'datesurvorigin'),
        pays: selector(state, 'pays'),
        cplieu: selector(state, 'cplieu'),
        pays_lieu_sinistre: selector(state, 'pays_lieu_sinistre'),
        uriGetDocument: documentsNeededAccidentGet(state),
        dateAfnNouvelle: getContractAfnDate(state),
        dateDerPiece: getContractSituationDate(state),
        codeProduit: getContractCodeProduit(state),
        sinistres: getAccidentsData(state),
        uri: accidentsContractGetLinkWithoutIdUrl(state),
        isFleet: getIsFleetContract(state),
        idContract: getContractId(state),
        isPrelevement: getContractPrelevement(state),
        derniereAdhesionPrincipale: getAdhesionPrincipale(state),
        loaded: getLoadingEntity(state, PIECES) === false
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getDocumentsAccident: ({query}) => getDocumentsAccident({query}),
    getAccidents: (query) => getAccidents({query}),
    getPieces: (query) => getPieces({query}),
    getActivities: (query) => getActivities({
        query,
        type: 'contrat',
        filters: {doc_nature_contract_DE: false, doc_type_contract_FID: false, doc_interlocuteur_contract_AN: false}
    }),
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getDocumentsAccident, getAccidents, getPieces, getActivities} = dispatchProps
    const {uriGetDocument, codeProduit, uri, idContract, uri_pieces, uri_activities, isPrelevement} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        getDocumentsAccident: (categorie) => getDocumentsAccident({
            query: _.replace(_.replace(_.replace(_.replace(_.replace(_.replace(_.replace(uriGetDocument,
                                    '{souscategorie}', categorie[1]),
                                '{categorie}', categorie[0]),
                            '{codeProduit}', codeProduit),
                        '/{docPolice?}', '/' + null),
                    '/{DTA?}', '/' + false),
                '/{DommageACCESSEQP?}', '/' + false),
                '/{prelevement?}', '/' + isPrelevement),
        }),
        load: () => {
            getAccidents(_.replace(uri, '{id}', idContract))
            getPieces(_.replace(uri_pieces, '{id}', idContract))
            getActivities(_.replace(uri_activities, '{id}', idContract))
        }
    }
}

const DeclarationContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(Declaration)

export default DeclarationContainer
