import React from 'react';
import moment from "moment";

const Reglement = props => {
    const {accident, contract, reglements} = props

    return (
        <div className={'bloc-info bloc-reglement'}>
            <h2>Règlement</h2>

            <div className={'content row'}>
                <table className="table col-12">
                    <thead>
                    <tr>
                        <th scope="col">N/Ordre</th>
                        <th scope="col">Intervenant réglé</th>
                        <th scope="col">Le</th>
                        <th scope="col">Type</th>
                        <th scope="col">Montant débit</th>
                        <th scope="col">Montant crédit</th>
                        <th scope="col">Référence règlement</th>
                        <th scope="col">Code validation</th>
                    </tr>
                    </thead>
                    <tbody>
                    {reglements.map((reglement, index) =>
                        <tr key={index}>
                            <td>{reglement.ordre}</td>
                            <td>{reglement.belair_id} - {reglement.raisonSociale}</td>
                            <td>{moment(reglement.date).format('L')}</td>
                            <td>{reglement.type}</td>
                            <td>{reglement.debit} €</td>
                            <td>{reglement.credit} €</td>
                            <td>{reglement.reference}</td>
                            <td>{reglement.codeval}</td>
                        </tr>
                    )}
                    {
                        reglements.length === 0
                        ? <tr><td colSpan={8} className={'font-weight-normal'}>Aucun règlement n'a encore été effectué</td></tr>
                        : <tr><td colSpan={8} className={'text-center'}><a href={`/contrat/${contract}/sinistre/${accident}/detail-reglement`} target={'_blank'} rel="noopener noreferrer">Voir le détail du règlement</a></td></tr>
                    }

                    </tbody>
                </table>
            </div>
        </div>
    );
};


export default Reglement;
