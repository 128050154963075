export const getLabelLoyaltyCode = code => {
    switch (code) {
        case 'BON_CLIENT_MONDIAL_2021':
            return 'Mondial Assistance'
        default:
            return null
    }
}

export const getCodeTypeReglement = code => {
    switch (code) {
        case '1':
            return 'DMGVEHICULE'
        case '2':
            return 'VALACHAT'
        case '3':
            return 'VALEXPERT'
        default:
            return code ? 'REMBCOMPL' : null
    }
}

export const reformatPosteIndem = code => {
    switch (code) {
        case 'majorationValeurExpertise' :
            return 'MAJVALEXP'
        case 'casque':
            return 'CASQUEGANTS'
        case 'accessoires10' :
            return 'ACC10'
        case 'accessoires20':
            return 'ACC20'
        case 'accessoires':
            return 'ACCESSOIRES'
        case 'objetPerso':
            return 'EFFETPERSO'
        case 'remorquage' :
            return 'REMORQUAGE'
        case 'equipement':
            return 'EQUPMTS'
        case 'moyenProtection':
            return 'MOYPROTECTIONS'
        case 'franchise' :
            return 'FRANCHISE'
        default:
            return null
    }
}

export const reformatPenaliteIndem = code => {
    switch (code) {
        case 'absenceAntivol' :
            return 'ABSVOLSRA'
        case 'absenceGravage':
            return 'ABSGRAVAGE'
        case 'absenceRemisage' :
            return 'ABSREMISNUIT'
        case 'franchise':
            return 'FRANCHISE'
        case 'conduiteExclu':
            return 'FRANCONDEXCLU'
        case 'autresPenalites' :
            return 'AUTREPEN'
        default:
            return null
    }
}

export const etatDevis = code => {
    switch (code) {
        case '2':
            return 'Contrats en attente de paiement immédiat'
        case '3' :
            return 'Contrat payé'
        case '4':
            return 'Contrat paiement différé'
        case '5':
            return 'Expiré'
        case '1' :
        default:
            return 'Devis'
    }
}

export const statutDevis = code => {
    switch (code) {
        case 'in_progress':
            return {color: 'grey', label: 'En cours', badge: 'success'};
        case 'cancelled' :
            return {color: 'red', label: 'Sans suite', badge: 'warning'};
        case 'won':
            return {color: 'green', label: 'Transformé', badge: 'secondary'};
        default:
            return null
    }
}

export const getLabelSituationVehicule = code => {
    switch (code) {
        case 1:
            return 'Moto possédée';
        case 2:
            return 'Remplacement moto';
        case 3:
            return 'Moto supplémentaire';
        case 4:
            return 'Première moto';
        default:
            return null;
    }
};