import {SET_ACCESSOIRES} from "../../actions/app/accessoires/accessoires.actions";

export const accessoiresReducer = (accessoires = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_ACCESSOIRES) {
        return payload.data
    } else {
        return accessoires
    }
}
