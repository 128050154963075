export const CHANGE_SITUATION_CONTRACT = '[Change Situation Contract]'

export const POST_CHANGE_SITUATION_CONTRACT = `${CHANGE_SITUATION_CONTRACT} Post`
export const SET_CHANGE_SITUATION_CONTRACT = `${CHANGE_SITUATION_CONTRACT} Set`

export const postChangeSituationContract = ({type, task_id, form, query, terminateQuery}) => ({
    type: POST_CHANGE_SITUATION_CONTRACT,
    payload: {
        data: query,
        body: {type, task_id, ...form},
        otherData: {
            type: type,
            terminate: terminateQuery
        }
    }
})

export const setChangeSituationContract = ({state}) => ({
    type: SET_CHANGE_SITUATION_CONTRACT,
    payload: state
})
