import React from 'react';
import GiftCardDetails from './GiftCardDetails';

const GiftCards = (props) => {
    const {gift_cards} = props

    return (
        <div className={'bloc-info bloc-promo'}>
            <h2>Cartes cadeaux</h2>
            <div className={'content row'}>
                {gift_cards && gift_cards.length > 0 ?
                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">Montant</th>
                            <th scope="col">Date affectation</th>
                            <th scope="col">Motif</th>
                            <th scope="col">Date péremption</th>
                        </tr>
                        </thead>
                        <tbody>
                            {gift_cards.map((gift_card, index) =>
                                <GiftCardDetails key={index} gift_card={gift_card}/>
                            )}
                        </tbody>
                    </table>
                    : <p className={'no-result'}>Aucune carte cadeau  envoyée</p>
                }
            </div>
        </div>
    );
};

export default GiftCards;