import loader from "../../../../Commun/Spinner/Loader/Loader";
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {change, formValueSelector, getFormValues, reduxForm} from "redux-form";
import validate from "./validate";
import {
    createFidelisationLink,
    getFidelisationDetailLink,
    contractDefaultGetLink,
    tarifContractGetLink, updateFidelisationLink
} from "../../../../../redux/selectors/links/links.selectors";
import CreateFidelisation from "./CreateFidelisation";
import _ from "lodash";
import {
    GET_FIDELISATION,
    getFidelisation,
    updateFidelisations
} from "../../../../../redux/actions/app/fidelisations/fidelisations.actions";
import {getFidelisationData} from "../../../../../redux/selectors/fidelisations/fidelisations.selectors";
import {getUserRoles} from "../../../../../redux/selectors/user/user.selectors";
import {getContract, getTarifContract} from "../../../../../redux/actions/app/contracts/contracts.actions";
import {getContractData, getTarifContractData} from "../../../../../redux/selectors/contract/contract.selectors";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {TARIF_CONTRACT, CONTRACT} from "../../../../../redux/actions/app/contracts/contracts.actions";
import moment from "moment/moment";

const mapStateToProps = (state, ownProps) => {
    const fidelisation = getFidelisationData(state);
    const selector = formValueSelector('form-fidelisation-edit')

    const { id, ids } = ownProps.match.params;

    const arrayGestes = fidelisation?.gestes || [];
    let gestes = {}, gestes_attributes = {};

    arrayGestes.forEach(geste => {
        gestes[geste.type] = true;
        gestes_attributes[geste.type] = {...geste, type: undefined, date: undefined};
    });

    return {
        idFidelisation: ownProps.match.params.ids,
        idContract: id,
        uri_fid: getFidelisationDetailLink(state),
        uri_update: updateFidelisationLink(state),
        uri: contractDefaultGetLink(state),
        uri_tarif: tarifContractGetLink(state),
        roles: getUserRoles(state),
        fidelisation,
        contract: getContractData(state),
        tarif: getTarifContractData(state),
        loading_tarif: getLoadingEntity(state, TARIF_CONTRACT) === true,
        loaded: getLoadingEntity(state, CONTRACT) === false && getLoadingEntity(state, GET_FIDELISATION) === false,
        edit: true,
        formValues: selector(state, 'reponse', 'cas_usage', 'interlocuteur', 'type', 'gestes', 'gestes_attributes', 'accept_rdv'),
        initialValues: fidelisation ? {
            date_demande: moment(fidelisation.date_demande, 'YYYY-MM-DD'),
            eligible: fidelisation.eligible,
            type: fidelisation.suivi ?? null,
            interlocuteur: fidelisation.interlocuteur ?? null,
            canal: fidelisation.canal ?? null,
            cas_usage: fidelisation.usage ?? null,
            description: fidelisation.description ?? null,
            reponse: fidelisation.reponse ?? null,
            accept_rdv: fidelisation.accept_rdv ?? null,
            date_rdv: fidelisation.date_rdv ?? null,
            gestes,
            gestes_attributes,
        } : null,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    changeValue: (field, value) => change(`form-fidelisation-edit`, field, value),
    getFidelisation: (query) => getFidelisation({query}),
    getContract: (query) => getContract({query}),
    getTarifContract,
    updateFidelisations
}, dispatch);

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { uri_fid, idFidelisation, uri, uri_tarif, idContract, uri_update } = stateProps;
    const { getFidelisation, getContract, getTarifContract, updateFidelisations } = dispatchProps;

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => {
            const replacedUriFid = _.replace(_.replace(uri_fid, '{ids}', idFidelisation), '{id}', idContract);
            getFidelisation(replacedUriFid);
            getContract(_.replace(uri, '{id}', idContract));
        },
        onSubmit: (form) => updateFidelisations({ query: _.replace(_.replace(uri_update, '{ids}', idFidelisation), '{id}', idContract), form }),
        postGetTarifContract: () => getTarifContract({ query: _.replace(uri_tarif, '{id}', idContract) }),
    };
};


const EditFidelisationContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader(),
    reduxForm({
        form: 'form-fidelisation-edit',
        validate
    }),
)(CreateFidelisation);

export default EditFidelisationContainer;
