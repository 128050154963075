import {createSelector} from 'reselect'

const contractsSelector = state => state.contracts

export const getContractsData = createSelector(
    contractsSelector,
    contracts => contracts.data
)

export const getContractsItemsData = createSelector(
    contractsSelector,
    contracts => contracts || {}
)
