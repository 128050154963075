import React from 'react'
import moment from "moment";
import {Link} from "react-router-dom"

const Accidents = (props) => {
    const {idTiers, accidents} = props

    return (
        <div className={'bloc-info bloc-accidents'}>
            <h2>Liste des sinistres</h2>
            <div className={'content row'}>
                {accidents && accidents.data.length >= 1 ?
                    <>
                        <table className="table">
                            <thead>
                            <tr>
                                <th scope="col">Date du sinistre</th>
                                <th scope="col">Numero du sinistre</th>
                                <th scope="col">Situation</th>
                                <th scope="col">Catégorie</th>
                                <th scope="col">Détails</th>
                            </tr>
                            </thead>
                            <tbody>
                            {accidents.data.map((accident, i) =>
                                <tr key={i}>
                                    <td>{moment(accident.dateSurv).format('L')}</td>
                                    <td>{accident.numCbt}</td>
                                    <td>{accident.situation}</td>
                                    <td>{accident.categorie}</td>
                                    <td>
                                        <Link to={`/contrat/${accident.id_contrat}/sinistre/${accident.id}`}
                                              target="_blank" rel="noopener noreferrer" className={'btn btn-more'}/>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>

                        {
                            accidents && accidents.data.length >= 10 &&
                            <p><Link to={`/tiers/${idTiers}/sinistres`} target="_blank" rel="noopener noreferrer"
                                     className={'btn btn-primary btn-small mt-4'}>Voir tous les sinistres</Link></p>
                        }
                    </>
                    :
                    <p className={'no-result'}>Le courtier n'a pas de sinistre déclaré</p>
                }
            </div>
        </div>
    )
}

export default Accidents
