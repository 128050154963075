import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {reduxForm} from 'redux-form'
import validate from './validate'
import ReversementCompagnies from "./ReversementCompagnies";

import ErrorHttpComponent from "../../../../Commun/Error/ErrorHttpComponent";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {ReversementGetLink} from "../../../../../redux/selectors/links/links.selectors";
import {postReversement, REVERSEMENT} from "../../../../../redux/actions/app/requetes/requetes.actions";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state) => {
    return {
        loading: getLoadingEntity(state, REVERSEMENT),
        uri: ReversementGetLink(state),
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    submitPostReversement: ({form, query}) => postReversement({form, query})
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {submitPostReversement} = dispatchProps
    const {uri} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form) => submitPostReversement({form, query: uri})
    }
}
const ReversementCompagniesContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'reversementCompagnies',
        validate
    }),
    loader({ErrorIndicator: ErrorHttpComponent})
)(ReversementCompagnies)

export default ReversementCompagniesContainer
