import React from 'react';
import moment from 'moment'
import APILink from "../../../../../Commun/APILink/APILink";

const DocumentItemLight = (props) => {
    const {document, type} = props


    return (
        <tr>
            <td>{document.libelle}</td>
            <td>{document.commentaire}</td>
            {
                type === "sinistre" &&
                <td>{moment(document.dateAttendu).format('L')}</td>
            }
            {document.recu
                ? <td>{moment(document.dateReception).format('L')}</td>
                : <td><strong>En attente</strong></td>
            }
            {document.traite
                ? <td><span className={'active'}>&nbsp;</span></td>
                : <td><span className={`inactive ${(document.doc_temporaire && document.nb_piece === '1') ? 'orange' : ''}`}>&nbsp;</span><em>En
                    attente</em></td>
            }
            <td>
                {document.download_link &&
                    <APILink rel="noopener noreferrer" href={document.download_link} className={'btn btn-more'}>&nbsp;</APILink>
                }
            </td>
            <td>&nbsp;</td>
        </tr>
    );
};

export default DocumentItemLight;
