import React from 'react'
import disconnect from '../../../assets/img/icons/disconnect.png'
import support from '../../../assets/img/icons/support2.png'
import dashboard from '../../../assets/img/icons/graph_dark.png'
import taches from '../../../assets/img/icons/taches.png'
import home from '../../../assets/img/icons/home.png'
import contrat from '../../../assets/img/icons/contract.png'
import stats from '../../../assets/img/icons/stats.png'
import loupe from '../../../assets/img/icons/loupe_white.png'
import warning from '../../../assets/img/icons/warning.png'
import valid from '../../../assets/img/icons/check.png'
import documents from '../../../assets/img/icons/contracts.png'
import virements from '../../../assets/img/icons/card_dark.png'
import agenda from '../../../assets/img/icons/coordonnee_dark.png'

export const Disconnect = props => {
    return (
        <img alt='icon' src={disconnect} {...props} />
    )
}

export const Support = props => {
    return (
        <img alt='icon' src={support} {...props} />
    )
}

export const Taches = props => {
    return (
        <img alt='icon' src={taches} {...props} />
    )
}

export const Home = props => {
    return (
        <img alt='icon' src={home} {...props} />
    )
}

export const Stats = props => {
    return (
        <img alt='icon' src={stats} {...props} />
    )
}

export const Contrat = props => {
    return (
        <img alt='icon' src={contrat} {...props} />
    )
}

export const Loupe = props => {
    return (
        <img alt='icon' src={loupe} {...props} />
    )
}

export const Warning = props => {
    return (
        <img alt='icon' src={warning} {...props} />
    )
}

export const Valid = props => {
    return (
        <img alt='icon' src={valid} {...props} />
    )
}

export const Documents = props => {
    return (
        <img alt='icon' src={documents} {...props} />
    )
}

export const Virements = props => {
    return (
        <img alt='icon' src={virements} {...props} />
    )
}

export const Agenda = props => {
    return (
        <img alt='icon' src={agenda} {...props} />
    )
}

export const Dashboard = props => {
    return (
        <img alt='icon' src={dashboard} {...props} />
    )
}
