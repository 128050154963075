import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import FlotteList from './FlotteList'
import ErrorHttpComponent from '../../../../../Commun/Error/ErrorHttpComponent'
import {getErrorEntity, getLoadingEntity} from '../../../../../../redux/selectors/ui/ui.selectors'
import {getFlotte, FLOTTE} from "../../../../../../redux/actions/app/flotte/flotte.actions";
import {getFlotteData, getFlotteMeta} from "../../../../../../redux/selectors/flotte/flotte.selectors";
import {getFleetLink} from "../../../../../../redux/selectors/links/links.selectors";
import {getContractId} from "../../../../../../redux/selectors/contract/contract.selectors";
import loader from "../../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    return {
        contractId: getContractId(state),
        flotte: getFlotteData(state),
        meta: getFlotteMeta(state),
        loaded: getLoadingEntity(state, FLOTTE) === false,
        loading: getLoadingEntity(state, FLOTTE),
        error: getErrorEntity(state, FLOTTE),
        query: getFleetLink(state),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getFlotte: ({query}) => getFlotte({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getFlotte} = dispatchProps
    const {query} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        getFlotte: () => getFlotte({query: query})
    }
}

const FlotteListContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(FlotteList)

export default FlotteListContainer
