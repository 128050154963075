import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import {CONTRACTS, getContracts} from '../../../../../../redux/actions/app/contracts/contracts.actions'
import {getErrorEntity, getLoadingEntity} from '../../../../../../redux/selectors/ui/ui.selectors'
import Contracts from './Contracts'
import {contractsTiersGetLink} from '../../../../../../redux/selectors/links/links.selectors'
import ErrorHttpComponent from '../../../../../Commun/Error/ErrorHttpComponent'
import {getContractsItemsData} from '../../../../../../redux/selectors/contracts/contracts.selectors'
import _ from "lodash";
import loader from "../../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    return {
        idTiers: ownProps.tiers,
        contracts: getContractsItemsData(state),
        uri: contractsTiersGetLink(state),
        loaded: getLoadingEntity(state, CONTRACTS) === false,
        error: getErrorEntity(state, CONTRACTS)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getContracts: (query) => getContracts({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getContracts} = dispatchProps
    const {uri, idTiers} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getContracts((_.replace(uri, '{id}', idTiers)))
    }
}

const ContractsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(Contracts)

export default ContractsContainer
