import React, {useEffect} from 'react';
import SelectFieldRedux from "../../../../Commun/Input/Select/SelectFieldRedux";
import {Field} from "redux-form";
import DatePickerFieldRedux from "../../../../Commun/Input/DatePicker/DatePickerFieldRedux";
import moment from "moment";
import Spinner from "../../../../Commun/Spinner/Spinner";


const ExtractWallet = (props) => {
    const {handleSubmit, isCourtier, type, loading, changeValue} = props

    useEffect(() => {
        changeValue('isCourtier', isCourtier);
    }, [isCourtier])

    return (
        <div className={'contract-container container'}>
            <div className={'row header-row'}>
                <div className={'col-12'}>
                    <form className={'form-content'} onSubmit={handleSubmit}>
                        <Field
                            component={SelectFieldRedux}
                            name='type'
                            required
                        >
                            <option value={""}>Sélectionnez une requête</option>
                            {
                                isCourtier && <>
                                    <option value={"PortefeuilleApp1Adresse"}>Extraction portefeuille avec adresses
                                        clients
                                    </option>
                                    <option value={"PorteFeuilleApp1All"}>Extraction portefeuille par contrat avec
                                        commissions
                                    </option>
                                </>
                            }
                            <option value={"Portefeuille"}>Extraction portefeuille spécifique sur les contrats actifs
                                (non-résiliés)
                            </option>
                            <option value={"PortefeuilleAll"}>Extraction portefeuille tous contrats</option>
                        </Field>

                        {
                            type === "PorteFeuilleApp1All" ?
                                <>
                                    <Field
                                        name={"dateStart"}
                                        component={DatePickerFieldRedux}
                                        label={'Date de début'}
                                        value={moment()}
                                        required
                                    />
                                    <Field
                                        name={"dateEnd"}
                                        component={DatePickerFieldRedux}
                                        label={'Date de fin'}
                                        value={moment()}
                                        required
                                    />
                                </> : ''
                        }

                        {
                            loading ? <Spinner style={{display: 'block', margin: 'auto'}}/> :
                                <button className={'btn btn-primary d-block m-auto'} type={'submit'}>
                                    Lancer l'extraction
                                </button>
                        }
                    </form>
                </div>
            </div>
        </div>
    );
};


export default ExtractWallet;
