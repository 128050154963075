import React from 'react';
import {Field} from "redux-form";
import RadioFieldRedux from "../../../../../../../Commun/Input/Radio/RadioFieldRedux";
import SelectFieldRedux from "../../../../../../../Commun/Input/Select/SelectFieldRedux";
import _ from "lodash";

const Police = (props) => {
    const {interventionPolice, getDocumentsAccident, catsin, equipementsDommage} = props

    return (
        <div className={'form-content h-100'}>
            <h1 className={'title-primary'}>Les forces de police sont-elles intervenues ?</h1>

            <Field
                name='intervention_police'
                component={RadioFieldRedux}
                label={'Il y a-t-il eu intervention des forces de police ?'}
                data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                required
            />
            {interventionPolice === 'T' &&
                <Field
                    name='document_police'
                    component={SelectFieldRedux}
                    label={'Sélectionnez le type de document reçu par le client'}
                    onChange={(e) => getDocumentsAccident(_.split(catsin, '.'), equipementsDommage === 'T' ?? false, e.target.value)}
                >
                    <option value={null}>Choisissez le document</option>
                    <option value={'O'}>Procès verbal</option>
                    <option value={'M'}>Main courante</option>
                    <option value={'V'}>Rapport des autorités</option>
                    <option value={null}>Ne sait pas</option>
                </Field>
            }
        </div>
    );
};

export default Police;
