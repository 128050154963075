import loader from "../../../Commun/Spinner/Loader/Loader";
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { DEROGATIONS, getDerogations } from '../../../../redux/actions/app/derogations/derogations.actions';
import { getContractData } from '../../../../redux/selectors/contract/contract.selectors';
import { getDerogationsData } from '../../../../redux/selectors/derogations/derogations.selectors';
import { getDerogationsLink } from '../../../../redux/selectors/links/links.selectors';
import { getErrorEntity, getLoadingEntity } from '../../../../redux/selectors/ui/ui.selectors';
import { getUserRoles } from '../../../../redux/selectors/user/user.selectors';
import { getMajorRoles, r } from '../Forms/Derogation/derogationMotifs';
import Derogations from './Derogations';

const mapStateToProps = (state, ownProps) => {
    const contract = getContractData(state);

    return {
        idTiers: contract.souscripteur.id,
        idContract: contract.id,
        idAccident: ownProps.idAccident ?? null,
        uri: getDerogationsLink(state),
        loaded: getLoadingEntity(state, DEROGATIONS) === false,
        derogations: getDerogationsData(state),
        error: getErrorEntity(state, DEROGATIONS),
        isManager: _.includes(getUserRoles(state), 'manager') || _.includes(getUserRoles(state), 'manager prod'),
    };
};

const mapDispatchToProps = {
    getDerogations,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { getDerogations } = dispatchProps;
    const { uri, idTiers, idContract, idAccident } = stateProps;

    let query = _.replace(uri, '{tiers_id}', idTiers);
    query = _.replace(query, '{contract_id}', idContract);
    query = _.replace(query, '{accident_id}', idAccident);

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        load: () => getDerogations({ query }),
    };
};

const DerogationsContainer = compose(connect(mapStateToProps, mapDispatchToProps, mergeProps), loader())(Derogations);

export default DerogationsContainer;
