import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import ChangeRibAccident from "./ChangeRibAccident";
import {ACCIDENT, getAccident} from "../../../../../redux/actions/app/accidents/accidents.actions";
import {
    accidentGetLink,
    amendmentChangeIbanAccidentGetLink
} from "../../../../../redux/selectors/links/links.selectors";
import _ from "lodash";

import {getAccidentData} from "../../../../../redux/selectors/accident/accident.selectors";
import {change, formValueSelector, reduxForm} from "redux-form";
import validate from "../ChangeRib/validate";
import {getErrorEntity, getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {
    postValidateRibAccident,
    VALIDATE_RIB_ACCIDENT
} from "../../../../../redux/actions/app/validate_rib/validate_rib.actions";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector('validate_rib_accident')

    return {
        uri: accidentGetLink(state),
        uriPostRib: amendmentChangeIbanAccidentGetLink(state),
        idContract: ownProps.match.params.id,
        idAccident: ownProps.match.params.ids,
        accident: getAccidentData(state),
        loaded: getLoadingEntity(state, ACCIDENT) === false,
        loadingValidateRib: getLoadingEntity(state, VALIDATE_RIB_ACCIDENT),
        errorRib: getErrorEntity(state, VALIDATE_RIB_ACCIDENT),
        ibanIsCheck: Boolean(selector(state, 'domiciliation') && selector(state, 'bic')),
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    getAccident,
    postValidateRibAccident,
    changeValue: (field, value) => change('validate_rib_accident', field, value),
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uri, uriPostRib, idContract, idAccident} = stateProps
    const {getAccident, postValidateRibAccident} = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getAccident({
            query: _.replace(_.replace(uri, '{id}', idContract), '{ids}', idAccident),
            id: idAccident
        }),
        onSubmit: (form) => postValidateRibAccident({query: uriPostRib, form})
    }
}


const ChangeRibAccidentContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'validate_rib_accident',
        validate
    }),
    loader()
)(ChangeRibAccident)


export default ChangeRibAccidentContainer
