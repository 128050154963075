import {
    ACTIVITY,
    ADD_ACTIVITY,
    GET_ACTIVITY,
    GET_ACTIVITY_FILES,
    POST_ADD_ACTIVITY, POST_TRANSFER_ACTIVITY,
    setActivity, setActivityFiles,
    setAddActivity, TRANSFER_ACTIVITY
} from '../../../actions/app/activity/activity.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {reset} from "redux-form";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {PAYMENT} from "../../../actions/app/payment/payment.actions";
import moment from "moment";

import _ from "lodash";

export const activityMiddleware = () => next => action => {
    next(action)

    const {payload} = action
    let message = null

    switch (action.type) {
        case GET_ACTIVITY:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: ACTIVITY}),
                setLoader({state: true, entity: ACTIVITY})
            ])
            break

        case `${ACTIVITY} ${API_SUCCESS}`:
            const {data} = action.payload.data

            next([
                setActivity({data}),
                setError({state: false, entity: ACTIVITY}),
                setLoader({state: false, entity: ACTIVITY})
            ])
            break

        case `${ACTIVITY} ${API_ERROR}`:
            next([
                setError({state: true, entity: ACTIVITY}),
                setLoader({state: false, entity: ACTIVITY})
            ])
            break

        case POST_ADD_ACTIVITY:
            let dataForm = new FormData()
            const clotureReclamation = action.payload.body.clotureReclamation !== undefined ? action.payload.body.clotureReclamation : false;
            const id = action.payload.otherData.id !== undefined ? action.payload.otherData.id : false;
            const ids = action.payload.otherData.ids !== undefined ? action.payload.otherData.ids : false;

            dataForm.set('categorie', action.payload.body.categorie)
            if (action.payload.body.categorie === 'agenda') {
                dataForm.set('paniere', action.payload.body.paniere)
                dataForm.set('date_rappel', moment(action.payload.body.date_rappel).format('L'))
            } else if (action.payload.body.categorie === 'popup') {
                dataForm.set('paniere', action.payload.body.paniere)
            } else {
                dataForm.set('type', action.payload.body.type)
            }

            if (action.payload.body.categorie === 'chrono' && _.includes(['FIDE', 'FIDS'], action.payload.body.type)) {
                dataForm.set('fid[attenteClient]', action.payload.body.attenteClient !== undefined ? action.payload.body.attenteClient : 'F')
                dataForm.set('fid[clientInjoignable]', action.payload.body.clientInjoignable !== undefined ? action.payload.body.clientInjoignable : 'F')
                dataForm.set('fid[clientNonEligible]', action.payload.body.clientNonEligible !== undefined ? action.payload.body.clientNonEligible : 'F')
                dataForm.set('fid[gesteFIDRefuser]', action.payload.body.gesteFIDRefuser !== undefined ? action.payload.body.gesteFIDRefuser : 'F')
                dataForm.set('fid[carteCarburant]', action.payload.body.carteCarburant !== undefined ? action.payload.body.carteCarburant : '')
                dataForm.set('fid[carteCarburant30]', action.payload.body.carteCarburant30 !== undefined ? action.payload.body.carteCarburant30 : '')
                dataForm.set('fid[carteCarburant50]', action.payload.body.carteCarburant50 !== undefined ? action.payload.body.carteCarburant50 : '')
                dataForm.set('fid[avRegul]', action.payload.body.avRegul !== undefined ? action.payload.body.avRegul : '')
                dataForm.set('fid[avRegulPrimeDepart]', action.payload.body.avRegulPrimeDepart !== undefined ? action.payload.body.avRegulPrimeDepart : '')
                dataForm.set('fid[avRegulPrimeRegul]', action.payload.body.avRegulPrimeRegul !== undefined ? action.payload.body.avRegulPrimeRegul : '')
                dataForm.set('fid[fraisModif]', action.payload.body.fraisModif !== undefined ? action.payload.body.fraisModif : '')
                dataForm.set('fid[fraisModifVal]', action.payload.body.fraisModifVal !== undefined ? action.payload.body.fraisModifVal : '')
                dataForm.set('fid[revueContrat]', action.payload.body.revueContrat !== undefined ? action.payload.body.revueContrat : '')
                dataForm.set('fid[revueContratVal]', action.payload.body.revueContratVal !== undefined ? action.payload.body.revueContratVal : '')
                dataForm.set('fid[revueContratPrime]', action.payload.body.revueContratPrime !== undefined ? action.payload.body.revueContratPrime : '')
                dataForm.set('fid[revueContratPrimeGeste]', action.payload.body.revueContratPrimeGeste !== undefined ? action.payload.body.revueContratPrimeGeste : '')
                dataForm.set('fid[sansGeste]', action.payload.body.sansGeste !== undefined ? action.payload.body.sansGeste : '')
                dataForm.set('fid[sansGesteMotif]', action.payload.body.sansGesteMotif !== undefined ? action.payload.body.sansGesteMotif : '')
            }

            dataForm.set('nature', action.payload.body.nature !== undefined ? action.payload.body.nature : '')
            dataForm.set('clotureReclamation', clotureReclamation)
            if(action.payload.body.erreur_sous) {
                dataForm.set('erreur_sous', action.payload.body.erreur_sous)
            }
            dataForm.set('files[]', action.payload.body.files)
            dataForm.set('titre', action.payload.body.titre)
            dataForm.set('commentaire', action.payload.body.commentaire !== undefined ? action.payload.body.commentaire : '')

            next([
                apiRequest({
                    body: dataForm,
                    method: 'POST',
                    url: action.payload.data,
                    entity: ADD_ACTIVITY,
                    otherData: {cloture: clotureReclamation, id: id, ids: ids}
                }),
                setLoader({state: true, entity: ADD_ACTIVITY})
            ])
            break

        case `${ADD_ACTIVITY} ${API_SUCCESS}`:
            message = `<p>Le suivi a bien été ajouté</p>`;

            next([
                setAddActivity({state: true}),
                setNotification({entity: ADD_ACTIVITY, body: message, type: 'success', title: 'Suivi créé!'}),
                setLoader({state: false, entity: ADD_ACTIVITY}),
                setError({state: false, entity: ADD_ACTIVITY}),
                reset('addActivityForm')
            ])

            if (action.payload.meta.otherData.cloture) {
                window.location = `/ma-reclamation/cloturer/${action.payload.meta.otherData.id}/${action.payload.meta.otherData.ids}`
            }
            break

        case `${ADD_ACTIVITY} ${API_ERROR}`:
            const reason = payload.data.response.data?.message ?? '' ?? (payload.data.response.data.message ?? '')
            message = `<p>Erreur lors de l'ajout du suivi: ${reason}</p>`
            next([
                setNotification({entity: PAYMENT, body: message, type: 'error', title: 'Attention !', timeout: '0'}),
                setError({state: true, entity: ADD_ACTIVITY}),
                setLoader({state: false, entity: ADD_ACTIVITY}),
            ])
            break

        case GET_ACTIVITY_FILES:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: GET_ACTIVITY_FILES}),
                setLoader({state: true, entity: GET_ACTIVITY_FILES})
            ])
            break

        case `${GET_ACTIVITY_FILES} ${API_SUCCESS}`:
            next([
                setActivityFiles({data: action.payload.data}),
                setError({state: false, entity: GET_ACTIVITY_FILES}),
                setLoader({state: false, entity: GET_ACTIVITY_FILES})
            ])
            break

        case `${GET_ACTIVITY_FILES} ${API_ERROR}`:
            next([
                setError({state: true, entity: GET_ACTIVITY_FILES}),
                setLoader({state: false, entity: GET_ACTIVITY_FILES})
            ])
            break

        case `${POST_TRANSFER_ACTIVITY}`:
            next([
                apiRequest({
                    body: (action.payload.body),
                    method: 'POST',
                    url: action.payload.data,
                    entity: TRANSFER_ACTIVITY
                }),
                setLoader({state: true, entity: TRANSFER_ACTIVITY})
            ])
            break

        case `${TRANSFER_ACTIVITY} ${API_SUCCESS}`:
            message = `<p>L'activité a bien été transférée.</p>`
            next([
                setNotification({
                    entity: TRANSFER_ACTIVITY,
                    body: message,
                    type: 'success',
                    title: 'Activité transférée !'
                }),
                setError({state: false, entity: TRANSFER_ACTIVITY}),
                setLoader({state: false, entity: TRANSFER_ACTIVITY})
            ])
            break

        case `${TRANSFER_ACTIVITY} ${API_ERROR}`:
            next([
                setError({state: true, entity: TRANSFER_ACTIVITY}),
                setLoader({state: false, entity: TRANSFER_ACTIVITY})
            ])
            break

        default:
            break;
    }
    return null
}
