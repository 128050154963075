import React from 'react'
import APILink from "../APILink/APILink";

const DocumentCard = ({document, type}) => {
    return (
        <div className={`card-doc col-6`}>
            <div className={'card-header'}>
                <APILink href={document.url} target={'_blank'} rel="noopener noreferrer">
                    <h2><i className={`icon icon-${type}`}></i>{document.title}</h2>
                </APILink>
            </div>
        </div>
    )
}

export default DocumentCard
