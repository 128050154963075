import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import DashboardPaniereRow from "./DashboardPaniereRow";
import {dashboardPaniereDetailGetLink} from "../../../../../../redux/selectors/links/links.selectors";
import {getManagerPaniereDetail} from "../../../../../../redux/actions/app/dashboard/dashboard.actions";
import _ from "lodash";

const mapStateToProps = (state, ownProps) => {
    return {
        uri: dashboardPaniereDetailGetLink(state),
        emetteur: ownProps.emetteur,
        detail: ownProps.emetteur.detail ? ownProps.emetteur.detail : []
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    getManagerPaniereDetail,
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getManagerPaniereDetail} = dispatchProps
    const {emetteur, uri} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getManagerPaniereDetail({
            query: _.replace(uri, '{paniere}', emetteur.paniere),
            emetteur: emetteur.paniere
        })
    }
}


const DashboardPaniereRowContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(DashboardPaniereRow)


export default DashboardPaniereRowContainer
