import React from 'react';
import {Field} from "redux-form";
import _ from "lodash";
import LargeSelectFieldRedux from "../../../../../Commun/Input/Select/LargeSelectFieldRedux/LargeSelectFieldRedux";
import Card from "../../../../../Commun/Card/Card";
import newImg from "../../../../../../assets/img/commande_produits/new.svg";


const ChoixProduits = (props) => {
    const {plv, dispositions} = props

    return (
        <div>
            {
                plv.length > 0 && <div className={'mt-5'}>
                    <h1 className="title-primary">Publicités sur point de vente (PLV)</h1>
                    <div className="row">
                        {
                            plv.map(p => (
                                <div className="col-lg-3 col-md-6 mt-3 position-relative" key={p.id}>
                                    {p.new && <img src={newImg} style={{zIndex: 10}} alt="Nouveauté" width={50}
                                                   className={'position-absolute'}/>}
                                    <Field
                                        name={`commande[${p.id}]`}
                                        component={LargeSelectFieldRedux}
                                        label={p.title_flyer}
                                        sublabel={p.size_flyer}
                                        img={p.flyer_image}
                                        disabled={!p.available}
                                        unit={p.unit}
                                    >
                                        <option value={''}>Quantité</option>
                                        {
                                            _.range(p.quantity_max).map(q => (
                                                <option value={q + 1} key={q}>{q + 1}</option>
                                            ))
                                        }
                                    </Field>
                                    {
                                        !p.available && <div className={'w-100 h-100 position-absolute overlay'}>
                                            <div className={'text_overlay w-100 text-center'}>
                                                <Card>Bientôt disponible</Card>
                                            </div>
                                        </div>
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
            }

            {
                dispositions.length > 0 && <div className={'mt-5'}>
                    <h1 className="title-primary">Dispositions Générales</h1>
                    <div className="row">
                        {
                            dispositions.map(d => (
                                <div className="col-lg-3 col-md-6 mt-3 position-relative" key={d.id}>
                                    {d.new && <img src={newImg} style={{zIndex: 10}} alt="Nouveauté" width={50}
                                                   className={'position-absolute'}/>}
                                    <Field
                                        name={`commande[${d.id}]`}
                                        component={LargeSelectFieldRedux}
                                        label={d.title_flyer}
                                        sublabel={d.size_flyer}
                                        img={d.flyer_image}
                                        disabled={!d.available}
                                        unit={d.unit}
                                    >
                                        <option value={''}>Quantité</option>
                                        {
                                            _.range(d.quantity_max).map(q => (
                                                <option value={q + 1} key={q}>{q + 1}</option>
                                            ))
                                        }
                                    </Field>
                                    {
                                        !d.available && <div className={'w-100 h-100 position-absolute overlay'}>
                                            <div className={'text_overlay w-100 text-center'}>
                                                <Card>Bientôt disponible</Card>
                                            </div>
                                        </div>
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
            }

            {
                plv.length === 0 && dispositions.length === 0 &&
                <div className={'mt-5'}>
                    <p>Pas de produit disponible</p>
                </div>
            }
        </div>
    );
};

export default ChoixProduits;
