export const ACTIVITIES = '[Activities]'
export const CLOSE_ACTIVITY = '[Close Activity]'

export const GET_ACTIVITIES = `${ACTIVITIES} Get`
export const SET_ACTIVITIES = `${ACTIVITIES} Set`

export const POST_CLOSE_ACTIVITY = `${CLOSE_ACTIVITY} Post`
export const SET_CLOSE_ACTIVITY = `${CLOSE_ACTIVITY} Set`

export const getActivities = ({query, type, filters}) => ({
    type: GET_ACTIVITIES,
    payload: {
        data: query,
        type: type,
        filters: filters,
    }
})

export const setActivities = ({data}) => ({
    type: SET_ACTIVITIES,
    payload: {data},
    meta: {
        entity: ACTIVITIES
    }
})

export const postCloseActivity = ({query}) => ({
    type: POST_CLOSE_ACTIVITY,
    payload: {
        data: query,
    }
})

export const setCloseActivity = ({state}) => ({
    type: SET_CLOSE_ACTIVITY,
    payload: {
        data: state
    }
})
