import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import VirementsProdContent from './VirementsProdContent'
import ErrorHttpComponent from '../../../../Commun/Error/ErrorHttpComponent'
import {virementsGetLink} from '../../../../../redux/selectors/links/links.selectors'
import {getVirementsMeta} from "../../../../../redux/selectors/virements/virements.selectors";
import {getVirementsProd} from "../../../../../redux/actions/app/virements/virements.actions";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    return {
        uri: virementsGetLink(state),
        meta: getVirementsMeta(state) || {},
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getVirementsProd: ({
       query,
       sort,
       multiple,
       fractionnement,
       duplicate,
       contrat_situation,
       contrat,
       montant,
       motif,
       date,
       page
   }) => getVirementsProd({
        query,
        sort,
        multiple,
        fractionnement,
        duplicate,
        contrat_situation,
        contrat,
        montant,
        motif,
        date,
        page
    }),
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getVirementsProd} = dispatchProps
    const {uri} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getVirementsProd({
            query: uri,
            fractionnement: localStorage.getItem('fractionnement') || '',
            contrat_situation: localStorage.getItem('contrat_situation_virements') || '',
            motif: localStorage.getItem('motif_virements') || '',
            montant: localStorage.getItem('montant') || '',
            contrat: localStorage.getItem('contrat') || '',
            sort: localStorage.getItem('sort'),
            multiple: localStorage.getItem('multiple_virements') || '',
            duplicate: localStorage.getItem('duplicate_amount') || '',
        }),
    }
}

const VirementsProdContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(VirementsProdContent)

export default VirementsProdContainer
