import {SET_ACTIVITIES} from '../../actions/app/grc/grc.actions'

export const activitiesReducer = (activities = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_ACTIVITIES) {
        return payload
    } else {
        return activities
    }
}
