import React from 'react';
import moment from "moment";


const AcpteFrac = (props) => {
    const {task: {date, type, paniere, tiers_id}} = props

    return (
        <div className={'container'}>
            <div className={'row'}>
                <div className={'col text-center'}>
                    <h2 className={'title-primary text-uppercase'}>{paniere} - Tiers n°{tiers_id}</h2>
                    <p className={'subtitle'}>Tâche du {moment(date).format('L')}. Recu par {type}</p>
                </div>
            </div>
            <div className={'header-row row'}>
                <div className={'col-12'}>
                    <p className={'form-content text-center'}>
                        - Effacer la quittance émise sur la période<br/>
                        - Création d'une nouvelle quittance IMPORT si contrat en établissement ou quittance TERME si
                        contrat en cours.<br/>
                        - Création du tableau de prélèvement pour les contrats en cours<br/>
                    </p>
                </div>
            </div>
        </div>
    );
};


export default AcpteFrac;
