
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setChangeAddress, setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {CHANGE_ADDRESS, POST_CHANGE_ADDRESS} from "../../../actions/app/change_address/change_address.actions";

export const validateAddressMiddleware = () => next => action => {
    next(action)

    const {payload} = action
    let message;

    switch (action.type) {

        case POST_CHANGE_ADDRESS:
            let body = payload.body

            if (!body.sansStationnement) {
                if (body.new_adress_is_new_lieuStationnement === 'T') {
                    body.villeB = body.villeNew
                    body.codePostalB = body.codePostalNew
                } else {
                    body.villeB = body.ville_new_lieuStationnement
                    body.codePostalB = body.cp_new_lieuStationnement
                }
            }

            next([
                apiRequest({
                    body: (body),
                    method: 'POST',
                    url: payload.data,
                    entity: CHANGE_ADDRESS,
                    otherData: payload.otherData
                }),
                setLoader({state: true, entity: payload.otherData.formName})
            ])
            break

        case `${CHANGE_ADDRESS} ${API_SUCCESS}`:
            message = `<p>La modification d'adresse a bien été enregistrée.</p>`

            let nextAction = [
                setChangeAddress({data: payload.data, id: payload.meta.otherData.id}),
                setError({state: false, entity: payload.meta.otherData.formName}),
                setLoader({state: false, entity: payload.meta.otherData.formName})
            ]

            if (payload.data.url) {
                window.open(payload.data.url, '_blank');
            } else {
                nextAction.push(setNotification({
                    entity: payload.meta.otherData.formName,
                    body: message,
                    type: 'success',
                    title: "Modification d'adresse enregistrée !"
                }))
            }

            next(nextAction)

            break

        case `${CHANGE_ADDRESS} ${API_ERROR}`:
            message = `<p>${payload.data.response.data?.message ?? ''}</p>`

            next([
                setError({state: true, entity: payload.meta.otherData.formName}),
                setLoader({state: false, entity: payload.meta.otherData.formName}),
                setNotification({
                    entity: payload.meta.otherData.formName,
                    body: message,
                    type: 'error',
                    title: 'Une erreur est survenue !',
                    timeout: '0'
                }),

            ])
            break

        default:
            break
    }
    return null
}
