import React, {useRef, useState} from 'react';
import {Field} from "redux-form";
import Spinner from "../../../../Commun/Spinner/Spinner";
import SelectFieldRedux from "../../../../Commun/Input/Select/SelectFieldRedux";
import {checkIban} from "../../../../../utils/function";
import _ from "lodash";
import TextFieldRedux from "../../../../Commun/Input/Text/TextFieldRedux";
import {capitalizeInput} from "../../../../../utils/normalizeInputs";

const ChangeFractionnement = (props) => {
    const {handleSubmit, loading, fractionnement, prelevement, changeValue, ibanIsCheck} = props
    const [errorIban, setErrorIban] = useState(null)
    const [loadingIban, setLoadingIban] = useState(false)
    const inputIban = useRef("");

    const fetchCheckIban = async (value) => {
        setLoadingIban(true)
        let iban = await checkIban(value)
        setLoadingIban(false)
        changeValue('domiciliation', iban.bank_data.bank)
        changeValue('bic', iban.bank_data.bic)

        /* Vérifie si le format de l'iban est correct */
        const codeValidationsSuccess = ['001', '002', '003', '004', '005', '006', '007'];
        const fieldValidations = ['account', 'country_support', 'iban', 'length', 'structure'];
        let error = null

        _.forEach(fieldValidations, field => {
            if (!_.includes(codeValidationsSuccess, iban.validations[field].code)) {
                error = iban.validations[field].message
                return false;
            }
        });

        /* Vérifie si l'iban accepte les prélèvement */
        if (iban.sepa_data.SDD === "NO" || iban.sepa_data.SCT === "NO") {
            return setErrorIban("L'IBAN renseignée n'autorise pas les prélèvements bancaires")
        }

        return error ? setErrorIban(`Le format de l'IBAN n'est pas valide. <br /> ${error}`) : setErrorIban(null)
    }

    return (
        <div className={'contract-container container'}>
            <div className={'row return-content'}>
                <div className={'col'}>
                    <button onClick={() => props.history.go(-1)} className={'btn btn-return'}>Retour</button>
                </div>
            </div>
            <div className={'row header-row'}>
                <div className={'col-12'}>
                    <form onSubmit={handleSubmit} className={'change-compagnie form-content'}>
                        <Field
                            name={'fractionnement'}
                            component={SelectFieldRedux}
                            label={'Sélectionner le nouveau fractionnement'}
                            required
                        >
                            <option value={null}>Sélectionner un fractionnement</option>
                            {(fractionnement === 'Prélévement mensuel' && prelevement === true) &&
                                <option value={`A`}>Fractionnement annuel en prélèvement</option>
                            }
                            {(fractionnement === 'Prélévement annuel' && prelevement === true) &&
                                <option value={`M`}>Fractionnement mensuel en prélèvement</option>
                            }
                            {prelevement === false &&
                                <>
                                    <option value={`A`}>Fractionnement annuel en prélèvement</option>
                                    <option value={`M`}>Fractionnement mensuel en prélèvement</option>
                                </>
                            }
                        </Field>
                        {
                            prelevement === false &&
                            <>
                                <Field
                                    name='iban'
                                    component={TextFieldRedux}
                                    label={'IBAN'}
                                    ref={inputIban}
                                    normalize={value => value && value.toUpperCase()}
                                />

                                {
                                    ibanIsCheck && <>
                                        <Field
                                            name='domiciliation'
                                            component={TextFieldRedux}
                                            label={'Domiciliation de la banque'}
                                            disabled
                                            normalize={capitalizeInput}
                                        />
                                        <Field
                                            name='bic'
                                            component={TextFieldRedux}
                                            label={'Code BIC'}
                                            disabled
                                            normalize={capitalizeInput}
                                        />
                                    </>
                                }

                                {
                                    errorIban && <div className={'alert-danger alert mb-0'}
                                                      dangerouslySetInnerHTML={{__html: errorIban}}/>
                                }

                                {
                                    ibanIsCheck && !errorIban && <div className={'alert-success alert mb-0'}>
                                        l'IBAN est valide
                                    </div>
                                }

                                <span
                                    color='secondary'
                                    className={`${loadingIban && 'disabled'} btn btn-secondary position-relative my-3 position-relative`}
                                    style={{minHeight: 50, minWidth: 230}}
                                    onClick={loadingIban ? null : () => fetchCheckIban(inputIban.current.value)}
                                >
                                {loadingIban && <Spinner absolute/>}
                                    Valider l'IBAN du client
                            </span>
                            </>
                        }
                        {
                            loading ? <Spinner style={{display: 'block', margin: 'auto'}}/> :
                                <button className={'btn btn-primary d-block'} type={'submit'}>
                                    Mettre à jour
                                </button>
                        }
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ChangeFractionnement;
