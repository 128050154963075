import React from 'react';
import InitFormContainer from "../InitForm/InitFormContainer";

const InitFormFleet = (props) => {
    const {idVehicule, contract} = props

    return <InitFormContainer idVehicule={idVehicule} contract={contract}/>;
};

export default InitFormFleet;
