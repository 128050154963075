import React, {Component} from 'react';
import CallToAction from "../../../../../../../Commun/Button/CallToAction/CallToAction";
import TextFieldRedux from "../../../../../../../Commun/Input/Text/TextFieldRedux";
import {Field} from 'redux-form'
import RadioFieldRedux from "../../../../../../../Commun/Input/Radio/RadioFieldRedux";
import MyButton from '../../../../../../../Commun/Button/Button'

class FieldArrayBlesses extends Component {

    componentDidMount() {
        this.props.fields.push({})
    }

    render() {
        const {fields} = this.props

        return (
            <div>
                {
                    fields.map((victime, index) => (
                        <div key={index} className={'cadre'}>
                            <span className={'title-cadre'}>Victime {index + 1}</span>
                            {' '}
                            <CallToAction
                                action={'delete'}
                                toolText={'Supprimer'}
                                onClick={(e) => {
                                    e.preventDefault()
                                    fields.remove(index)
                                }}
                            />
                            <Field
                                name={`${victime}.nom`}
                                component={TextFieldRedux}
                                label='Nom'
                            />

                            <Field
                                name={`${victime}.prenom`}
                                component={TextFieldRedux}
                                label='Prénom'
                            />

                            <Field
                                name={`${victime}.type_victime`}
                                component={RadioFieldRedux}
                                label={'Situation'}
                                data={[
                                    {value: "P", label: "Passager"},
                                    {value: "N", label: "Passager adverse"},
                                    {value: "W", label: "Piéton"},
                                    {value: "V", label: "Cycliste"}
                                ]}
                            />

                        </div>
                    ))
                }
                <MyButton
                    onClick={(e) => {
                        e.preventDefault();
                        fields.push({})
                    }}
                    className={'btn btn-secondary'}
                >
                    Ajouter une victime
                </MyButton>
            </div>
        );
    }
}

export default FieldArrayBlesses;
