import React from 'react'
import DocumentCard from "../../../../Commun/Card/DocumentCard";
import Collapse from "../../../../Commun/Collapse/Collapse";

const Documentation = (props) => {
    const {documentation, expandQuestion} = props

    return (
        <div>
            {Object.keys(documentation).map((key, index) => {
                return (
                    <Collapse index={index} title={`Dispositions générales ${key}`} id={`dispositionsgen-${key}`}
                              onClickOpen={() => expandQuestion({
                                  question: `Dispositions générales ${key}`,
                                  category: 'Dispositions générales'
                              })}>
                        <div className={'row'}>
                            {documentation[key].map((doc, index) => (
                                <DocumentCard document={doc} type={key.replace(/\s/g, '')} index={index} key={index}/>
                            ))}
                        </div>
                    </Collapse>
                )
            })}
        </div>
    )
}

export default Documentation
