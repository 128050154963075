import React, {Fragment} from 'react'
import {BrowserRouter, Route, Redirect} from 'react-router-dom'
import PageRoutes from './PageRoutes'
import WithTrackerComponent from '../../Commun/GTM/WithTrackerComponent'
import Nav from "../Nav/Nav";
import ErrorBoundaryComponent from "../../Commun/Error/ErrorBoundary";
import _ from 'lodash'
import GoToTop from "../../Commun/Button/GoToTop/GoToTop";
import DarkModeToggle from "../../Commun/Toggle/DarkModeToggle";
import ListenLocationContainer from "../ListenLocation/ListenLocationContainer";
import TasksProcessedContainer from "./TasksProcessed/TasksProcessedContainer";
import GoToBottom from "../../Commun/Button/GoToBottom/GoToBottom";
import Home from "./Homepage/Home";
import {nouvelOnglet} from "../../../utils/function";
import TraitementCheckdoc from "./TraitementCheckdoc/TraitementCheckdoc";
import TraitementCheckdocContainer from "./TraitementCheckdoc/TraitementCheckdocContainer";

const Page = (props) => {
    const {idBelair, nameConseiller, permissions, roles} = props

    window.dataLayer.push({
        'belair_id': idBelair
    })

    return (
        <BrowserRouter>
            <Fragment>
                <div className={'container-fluid main-nav'}>
                    <Nav routes={PageRoutes} nameConseiller={nameConseiller} permissions={permissions} roles={roles}/>
                </div>
                <div className={'container-fluid'}>
                    <div className={'row'}>
                        {(!nouvelOnglet() && !_.includes(roles, 'gestionnaire televente')) && <TasksProcessedContainer/>}
                        <div className={'col-1 offset-md-9'}>
                            <DarkModeToggle/>
                        </div>
                    </div>
                </div>
                <div className={'page-container'}>
                    <ErrorBoundaryComponent>
                        <ListenLocationContainer roles={roles}>
                            {PageRoutes.map((route, index) => (
                                route.permission === 'all' || _.includes(permissions, route.permission) ?
                                    <Route key={index} exact={route.exact} path={route.path}
                                           component={WithTrackerComponent(route.main)}/>
                                    : null
                            ))}
                            {!_.includes(roles, 'gestionnaire televente')
                                ? <Redirect to={'/mes-taches'}/>
                                : <Home/>
                            }
                        </ListenLocationContainer>
                    </ErrorBoundaryComponent>
                </div>
                <GoToBottom/>
                <GoToTop/>
            </Fragment>
        </BrowserRouter>
    )
}

export default Page
