import {
    SET_DASHBOARD, SET_DASHBOARD_MORE_TASK_DETAIL,
    SET_DASHBOARD_PANIERE_DETAIL,
    SET_DASHBOARD_TASK_DETAIL
} from '../../actions/app/dashboard/dashboard.actions'
import _ from "lodash";

export const dashboardReducer = (dashboard = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_DASHBOARD) {
        return payload
    } else if (action.type === SET_DASHBOARD_TASK_DETAIL) {
        let index = _.findIndex(dashboard.data.users, ['pro_id', payload.pro_id]);
        dashboard.data.users[index].detail = payload.data

        return dashboard
    } else if (action.type === SET_DASHBOARD_PANIERE_DETAIL) {
        let index = _.findIndex(dashboard.data.panieres, ['paniere', payload.emetteur]);
        dashboard.data.panieres[index].detail = payload.data

        return dashboard
    } else if (action.type === SET_DASHBOARD_MORE_TASK_DETAIL) {
        return {data: {...dashboard.data, gestionnaire_detail: payload.data}};
    } else {
        return dashboard
    }
}
