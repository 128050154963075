import _ from "lodash";

const validate = values => {
    const errors = {}

    const requiredFields = [
        'status',
        'motif',
    ]

    requiredFields.forEach(field => {
        if (!values[field] || _.startsWith(values[field], 'Sélectionnez')) {
            errors[field] = 'Le champ est obligatoire'
        }
    })

    return errors
}

export default validate
