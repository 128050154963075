export const RAPPORT_BCA = '[Rapport Bca]'
export const DELETE_RAPPORT_BCA = '[Delete Rapport Bca]'
export const RESEND_MISSION_BCA = '[Resend Mission Bca]'

export const POST_DELETE_RAPPORT_BCA = `${DELETE_RAPPORT_BCA} Post`
export const POST_RESEND_MISSION_BCA = `${RESEND_MISSION_BCA} Post`

export const GET_RAPPORT_BCA = `${RAPPORT_BCA} Get`
export const SET_RAPPORT_BCA = `${RAPPORT_BCA} Set`

export const postResendMissionBca = ({query, task_id, terminateQuery}) => ({
    type: POST_RESEND_MISSION_BCA,
    payload: {
        data: query,
        otherData: {
            terminate: terminateQuery,
            task_id
        }
    }
})

export const getRapportBca = ({query}) => ({
    type: GET_RAPPORT_BCA,
    payload: {
        data: query
    }
})

export const setRapportBca = ({rapport_bca}) => ({
    type: SET_RAPPORT_BCA,
    payload: {
        data: rapport_bca
    }
})


export const postDeleteRapportBca = ({id, query}) => ({
    type: POST_DELETE_RAPPORT_BCA,
    payload: {
        data: query,
        body: { id }
    }
})
