import React, {Component} from 'react'
import KofaxFieldCard from "../../../../../Commun/Card/KofaxFieldCard";
import moment from "moment";
import {Link} from "react-router-dom";

class KofaxDetail extends Component {

    componentDidMount() {
        this.props.logAction()
    }

    render() {

        const {task, files} = this.props

        return (
            <div className={'kofax-container container'}>
                <div className={'row'}>
                    <div className={'col text-center'}>
                        <h2 className={'title-primary'}>{files.length} {files.length > 1 ? 'pièces justificatives reçues' : 'pièce justificative reçue'}</h2>
                        <p className={'subtitle'}>
                            <span className={'text-uppercase'}>Reçu le {moment(task.date).format('L')} </span>
                            Par {task.type}
                        </p>
                    </div>
                </div>
                {
                    files.length > 0 &&
                    files.map((file, index) => (
                        file.status !== 'C' &&
                        <KofaxFieldCard file={file} key={index} loopIndex={index + 1}/>
                    ))
                }
                <div className={'button-container'}>
                    <Link
                        to={{pathname: `/tache/${task.id}`, state: {goToForm: true}}}
                        className={'btn btn-primary'}
                    >
                        Vérifier
                    </Link>
                </div>
            </div>
        )
    }
}

export default KofaxDetail
