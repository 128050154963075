import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {getErrorEntity, getLoadingEntity} from '../../../../../redux/selectors/ui/ui.selectors'
import Notes from './Notes'
import {NOTES, getNotes, postDeleteNote} from '../../../../../redux/actions/app/notes/notes.actions'
import {getNotesData} from '../../../../../redux/selectors/notes/notes.selector'
import ErrorHttpComponent from '../../../../Commun/Error/ErrorHttpComponent'
import {notesContractGetLink, postNoteDeleteGetLink} from "../../../../../redux/selectors/links/links.selectors";
import {getUserRoles} from "../../../../../redux/selectors/user/user.selectors";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    return {
        notes: getNotesData(state),
        uri: notesContractGetLink(state),
        loaded: getLoadingEntity(state, NOTES) === false,
        error: getErrorEntity(state, NOTES),
        contract: ownProps.contract,
        uri_delete: postNoteDeleteGetLink(state),
        roles: getUserRoles(state)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getNotes: (query) => getNotes({query}),
    postDeleteNote: ({id, query}) => postDeleteNote({id, query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getNotes, postDeleteNote} = dispatchProps
    const {uri, uri_delete} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getNotes(uri),
        postDelete: ({id}) => postDeleteNote({id, query: uri_delete})
    }
}

const NotesContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(Notes)

export default NotesContainer
