import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {reduxForm, formValueSelector} from 'redux-form'
import CommandeProduits from "./CommandeProduits";

import {getFlyersLink, getFlyersOrderLink, tiersGetLink} from "../../../../../redux/selectors/links/links.selectors";
import {
    fetchProduits,
    PRODUITS,
    PUT_PRODUITS, putProduits
} from "../../../../../redux/actions/app/commande_produits/commande_produits";
import {getErrorEntity, getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import _ from "lodash";
import {
    getCommandeProduits,
    getDispositionGeneralesCommandeProduits,
    getPlvCommandeProduits
} from "../../../../../redux/selectors/commande_produits/commande_produits.selectors";
import {getTiers, TIERS} from "../../../../../redux/actions/app/tiers/tiers.actions";
import {
    getTiersAddressPostalCode, getTiersAddressCity,
    getTiersAddressStreet1, getTiersEmail,
    getTiersName
} from "../../../../../redux/selectors/tiers/tiers.selectors";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector('commande-produits')

    return {
        commande: selector(state, 'commande'),
        queryTiers: tiersGetLink(state),
        loaded: getLoadingEntity(state, PRODUITS) === false && getLoadingEntity(state, TIERS) === false,
        error: getErrorEntity(state, PRODUITS) && getErrorEntity(state, TIERS),
        putLoading: getLoadingEntity(state, PUT_PRODUITS),
        putError: getErrorEntity(state, PUT_PRODUITS),
        uriFetch: getFlyersLink(state),
        uriPut: getFlyersOrderLink(state),
        idTiers: ownProps.match.params.id,
        plv: getPlvCommandeProduits(state),
        dispositions: getDispositionGeneralesCommandeProduits(state),
        produits: getCommandeProduits(state),
        initialValues: {
            name: getTiersName(state),
            email: getTiersEmail(state),
            delivery_address: getTiersAddressStreet1(state),
            zip_code: getTiersAddressPostalCode(state),
            city: getTiersAddressCity(state),
            commande: {}
        }
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    fetchProduits: ({query}) => fetchProduits({query}),
    putProduits: ({query, form}) => putProduits({query, form}),
    getTiers,
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uriFetch, idTiers, queryTiers, uriPut} = stateProps
    const {fetchProduits, getTiers, putProduits} = dispatchProps


    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => {
            getTiers({query: _.replace(queryTiers, '{id}', idTiers), idTiers})
            fetchProduits({query: _.replace(uriFetch, '{id}', idTiers)})
        },
        onSubmit: (form) => putProduits({query: _.replace(uriPut, '{id}', idTiers), form})
    }
}


const CommandeProduitsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader(),
    reduxForm({
        form: 'commande-produits'
    })
)(CommandeProduits)


export default CommandeProduitsContainer
