const validate = values => {
    const errors = {}

    const requiredFields = [
        'titre',
        'nom',
        'prenom',
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Le champ est obligatoire'
        }
    })

    if (values.email !== undefined && values.email !== '' && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = "L'adresse email saisi est invalide"
    }

    return errors
}

export default validate
