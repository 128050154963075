export const VALIDATE_RIB = '[Validate RIB]'
export const VALIDATE_RIB_ACCIDENT = '[Validate RIB Accident]'

export const POST_VALIDATE_RIB = `${VALIDATE_RIB} Post`
export const POST_VALIDATE_RIB_ACCIDENT = `${VALIDATE_RIB_ACCIDENT} Post`

export const postValidateRib = ({query, form, tiers, terminateQuery}) => ({
    type: POST_VALIDATE_RIB,
    payload: {
        data: query,
        body: {tiers, ...form},
        otherData: {
            terminate: terminateQuery
        }
    }
})

export const postValidateRibAccident = ({query, form}) => ({
    type: POST_VALIDATE_RIB_ACCIDENT,
    payload: {
        data: query,
        body: form
    }
})
