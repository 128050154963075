import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import ChangeResponsabilite from "./ChangeResponsabilite";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {
    POST_UPDATE_RESP_ACCIDENT,
    postUpdateRespAccident
} from "../../../../../redux/actions/app/accidents/accidents.actions";
import {reduxForm} from "redux-form";
import {updateAccidentResponsabiliteGetLink} from "../../../../../redux/selectors/links/links.selectors";
import _ from "lodash";

const mapStateToProps = (state, ownProps) => {
    return {
        uri: updateAccidentResponsabiliteGetLink(state),
        accidentId: ownProps.location.state.accident.id,
        contractId: ownProps.match.params.id,
        loading: getLoadingEntity(state, POST_UPDATE_RESP_ACCIDENT),
        initialValues: {
            "resp": ownProps.location.state.accident.tauxResp,
        }
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    postUpdateRespAccident,
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uri, accidentId, contractId} = stateProps
    const {postUpdateRespAccident} = dispatchProps


    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: form => postUpdateRespAccident({
            query: _.replace(_.replace(uri, '{id}', contractId), '{ids}', accidentId),
            form
        })
    }
}


const ChangeResponsabiliteContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'accident-responsabilite'
    })
)(ChangeResponsabilite)


export default ChangeResponsabiliteContainer
