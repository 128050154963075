import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, getFormValues, reduxForm } from 'redux-form';
import { POST_CREATE_TIERS } from '../../../../../redux/actions/app/tiers/tiers.actions';
import { addAccidentIntervenantGetLink } from '../../../../../redux/selectors/links/links.selectors';
import { getLoadingEntity } from '../../../../../redux/selectors/ui/ui.selectors';
import AddIntervenant from './AddIntervenant';
import validate from './validate';
import {postAddIntervenant} from "../../../../../redux/actions/app/accidents/accidents.actions";

const mapStateToProps = (state, ownProps) => {
    return {
        uri: addAccidentIntervenantGetLink(state),
        loading: getLoadingEntity(state, POST_CREATE_TIERS),
        formValues: getFormValues('AddIntervenant')(state),
        contractId: ownProps.match.params.id,
        accidentId: ownProps.match.params.ids,
    };
};

const mapDispatchToProps = {
    changeValue: (field, value) => change(`AddIntervenant`, field, value),
    postAddIntervenant,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { uri, accidentId, contractId } = stateProps;
    const { postAddIntervenant } = dispatchProps;

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        onSubmit: (form) => {
            postAddIntervenant({ query: _.replace(_.replace(uri, '{id}', contractId), '{ids}', accidentId), form });
        },
    };
};

const AddIntervenantContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'AddIntervenant',
        validate,
    }),
)(AddIntervenant);

export default AddIntervenantContainer;
