import React from 'react';
import VirementCard from "../../../../../Commun/Card/VirementCard";

const VirementSinistreList = (props) => {
    const {virements, idBelair} = props

    return (
        <div className={'row'}>
            <div className={'col'}>
                {virements && virements.length > 0 ?
                    virements.map((virement, index) => (
                        <VirementCard virement={virement} key={index} idBelair={idBelair}/>
                    ))
                    : <p className={'text-center'}>Vous n'avez pas de virements en attente de validation</p>
                }
            </div>
        </div>
    );
};

export default VirementSinistreList;
