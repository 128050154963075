import {connect} from 'react-redux'
import {compose} from 'redux'

import _ from 'lodash'
import ReclamationDetail from "./ReclamationDetail";
import {getReclamation, RECLAMATION} from "../../../../../redux/actions/app/reclamation/reclamation.actions";
import {
    complainGetLink,
    tiersGetLink
} from "../../../../../redux/selectors/links/links.selectors";
import {getErrorEntity, getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {getReclamationData} from "../../../../../redux/selectors/reclamation/reclamation.selectors";
import {getTiers, TIERS} from "../../../../../redux/actions/app/tiers/tiers.actions";
import {getTiersData} from "../../../../../redux/selectors/tiers/tiers.selectors";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    return {
        query: complainGetLink(state),
        reclamation: getReclamationData(state),
        tier: getTiersData(state),
        loaded: getLoadingEntity(state, RECLAMATION) === false && getLoadingEntity(state, TIERS) === false && !Array.isArray(getReclamationData(state)),
        error: getErrorEntity(state, RECLAMATION),
        queryTiers: tiersGetLink(state)
    }
}

const mapDispatchToProps = {
    getReclamation, getTiers
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {query, queryTiers} = stateProps
    const {getReclamation, getTiers} = dispatchProps
    const {id, ids} = ownProps.match.params

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => {
            return (
                getReclamation({query: _.replace(_.replace(query, '{id}', id), '{ids}', ids)}),
                    getTiers({query: _.replace(queryTiers, '{id}', id), id})
            )
        }
    }
}

const ReclamationDetailContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(ReclamationDetail)

export default ReclamationDetailContainer
