import React, {useEffect, Fragment} from 'react';
import {withRouter} from "react-router";
import {Switch} from 'react-router-dom';

const ListenLocation = (props) => {
    const {children, query, getNbTasks} = props

    useEffect(() => {
        getNbTasks(query)
    })

    return (
        <Fragment>
            <Switch>
                {children}
            </Switch>
        </Fragment>
    );
};

export default withRouter(ListenLocation);
