import React, {Fragment, useRef} from 'react'
import moment from "moment";
import APIFrame from "../../../../../Commun/APILink/APIFrame";
import APIImage from "../../../../../Commun/APILink/APIImage";

const DdeMail = (props) => {
    const {task: {date, type, tiers_id, paniere}, files} = props
    const refImg = useRef(null);

    return (
        <Fragment>
            <div className={'row'}>
                <div className={'col text-center'}>
                    <h2 className={'title-primary text-uppercase'}>Tâche DDE_MAIL - Tiers n°{tiers_id}</h2>
                    <p className={'subtitle'}>Tâche du {moment(date).format('L')}. Recu par {type}</p>
                </div>
            </div>
            <div className={'header-row row'}>
                {files !== undefined &&
                    files.map((file, index) => (
                        file.extension.toLowerCase() === 'pdf' ?
                            <div className={'col-8'} style={{marginBottom: 30}} key={index}>
                                <APIFrame src={`${file.url}`} title={`${file.code}`} frameBorder="0"
                                        style={{height: '100%', width: '100%', marginBottom: 30}}/>
                            </div>
                            :
                            <div className={'col-7 task-img-container'} key={index}>
                                <APIImage src={`${file.url}`} ref={refImg} alt={'Document'}/>
                            </div>
                    ))
                }
                <div className={`col-md-4 ${files !== undefined ? '' : 'offset-md-4'}`}>
                    <p className={'form-content text-center'}>
                        {
                            (paniere === 'DDE_MAIL' || paniere === 'DDE_MAIL_AV' || paniere === 'DDE_MAIL_NIV2')
                                ? 'Identifier la demande du client, lui apporter la réponse qu\'il convient et effectuer l\'action demandée.'
                                : 'Identifier la demande, apporter la réponse qu\'il convient et effectuer l\'action demandée.'
                        }
                    </p>
                </div>
            </div>
        </Fragment>
    )
}

export default DdeMail
