import {bindActionCreators, compose} from 'redux'
import _ from 'lodash'
import {connect} from 'react-redux'
import {getErrorEntity} from '../../../../../redux/selectors/ui/ui.selectors'
import AddDocument from './AddDocument'
import {validateDocumentGetLink} from '../../../../../redux/selectors/links/links.selectors'
import {reduxForm, reset} from "redux-form";
import {
    postValidateDocument,
    VALIDATE_DOCUMENT
} from "../../../../../redux/actions/app/validate_documents/validate_documents.actions";
import validate from './validate'

const mapStateToProps = (state, ownProps) => {
    return {
        uri: validateDocumentGetLink(state),
        risqueNum: ownProps.risqueNum,
        tiersId: ownProps.tiersId,
        contractId: ownProps.contractId,
        sinistreId: ownProps.sinistre,
        error: getErrorEntity(state, VALIDATE_DOCUMENT),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    postValid: ({body, form, query}) => postValidateDocument({body, form, query, addDoc: true}),
    resetForm: () => reset('validate_documents')
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {postValid} = dispatchProps
    const {uri, risqueNum, tiersId, contractId, sinistreId} = stateProps
    let body = {
        tiers: tiersId,
        risque: risqueNum,
    }

    if (sinistreId) {
        body.sinistre = sinistreId
    }

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form) => postValid({
            body,
            form,
            query: _.replace(uri, '{id}', contractId),
        })
    }
}

const AddDocumentContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'validate_documents',
        validate
    }),
)(AddDocument)

export default AddDocumentContainer
