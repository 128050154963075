import React from 'react'

const DocumentsUtilesCard = ({document}) => {
    return (
        <div className={'card-doc'}>
            <div className={'card-header'}>
                <a href={document.url} target={'_blank'} rel="noopener noreferrer">
                    <h2>{document.name}</h2>
                </a>
            </div>
        </div>
    )
}

export default DocumentsUtilesCard
