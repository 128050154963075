import React, {Component, Fragment} from 'react'
import moment from "moment";
import MyButton from "../Button/Button";
import Vehicule from "../../App/Page/Contract/Vehicule/Vehicule";
import _ from "lodash";

export default class PieceCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            question: false
        };
        this.toggleDetail = this.toggleDetail.bind(this);
    }

    toggleDetail() {
        this.setState({question: !this.state.question})
    }


    render() {
        const {piece, show = this.state.question, isWakam} = this.props
        const dg = piece.piece_docs && _.find(piece.piece_docs, ['type_doc', 'DG']);
        let dp = piece.piece_docs && _.find(piece.piece_docs, ['type_doc', 'DP_SIGN']);

        if(!dp && piece.piece_docs){
            dp = _.find(piece.piece_docs, ['type_doc', 'DP']);
        }

        return (
            <Fragment>
                <tr>
                    <td>{piece.numPiece}</td>
                    <td>{piece.motif}</td>
                    <td>{moment(piece.date).format('L')} à {piece.heure}</td>
                    <td>{piece.statut}</td>
                    <td>{piece.avenant_id}</td>
                    <td>{dg && <a rel="noopener noreferrer" target={'_blank'} href={dg.url}
                                        className={'btn btn-more'}>&nbsp;</a>}</td>
                    <td>{dp && <a rel="noopener noreferrer" target={'_blank'} href={dp.url}
                                        className={'btn btn-more'}>&nbsp;</a>}</td>
                    {!isWakam && <MyButton
                        type='submit'
                        variant='raised'
                        color='secondary'
                        size='large'
                        className={'btn btn-rounded'}
                        onClick={this.toggleDetail}
                    >
                        {!show ? '+' : '-'}
                    </MyButton> }
                </tr>
                <div className={`info-piece row ${!show ? 'hidden' : ''}`}>
                    <div className={'col-6'}>
                        <Vehicule vehicule={piece.vehicule}/>
                    </div>
                    <div className={'bloc-info bloc-garanties col-6'}>
                        <h2>Garanties / options</h2>
                        <div className={'content row'}>
                            {piece.garanties.map((garantie, index) =>
                                <p key={index} className={'col-12'}>{garantie.libelle}</p>
                            )}
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
