import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import {postLogout, LOGOUT} from '../../../../redux/actions/app/login/login.actions'
import Logout from './Logout'
import {userLogoutLink} from '../../../../redux/selectors/links/links.selectors'
import {getLoadingEntity} from "../../../../redux/selectors/ui/ui.selectors";
import loader from "../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    return {
        uri: userLogoutLink(state),
        logout: getLoadingEntity(state, LOGOUT) === false,
    }
}
const mapDispatchToProps = dispatch => bindActionCreators({
    logout: (query) => postLogout({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {logout} = dispatchProps
    const {uri} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => logout(uri)
    }
}

const LogoutContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Logout)

export default LogoutContainer
