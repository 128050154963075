import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {API_URL} from '../../constants/constants'
import {getLinks, LINKS} from '../../redux/actions/app/links/links.actions'
import App from './App'
import {getMaintenance, getLoadingEntity, getLoading} from '../../redux/selectors/ui/ui.selectors'
import favicon from '../../assets/img/favicon.ico'
import loader from "../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    return {
        maintenance: getMaintenance(state),
        loaded: getLoading(state) !== undefined && !getLoadingEntity(state, LINKS),
        favicon: favicon
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    load: () => getLinks({query: API_URL})
}, dispatch)

const AppContainer = compose(
    connect(mapStateToProps, mapDispatchToProps),
    loader()
)(App)

export default AppContainer
