import {memo, useEffect} from 'react';
import { Field } from 'redux-form';
import { useIban } from '../../../../../api/services';
import { capitalizeInput } from '../../../../../utils/normalizeInputs';
import TextFieldRedux from '../../../../Commun/Input/Text/TextFieldRedux';
import Spinner from '../../../../Commun/Spinner/Spinner';

const IbanField =  memo(function IbanField ({ nameIban = 'iban', formValues, changeValue }) {
    const queryIBAN = useIban(formValues?.[nameIban]);

    const errorMessage = queryIBAN.error?.response?.data?.message;

    useEffect(() => {
        const detail = queryIBAN.data;

        if (queryIBAN.isSuccess && detail.bic) {
            changeValue('bic', detail.bic);
            changeValue('domiciliation', detail.bank);
        } else if (queryIBAN.isError && !queryIBAN.isFetching) {
            changeValue('bic', '');
            changeValue('domiciliation', '');
        }
    }, [queryIBAN.isSuccess, queryIBAN.isError]);

    return (
        <div className="row">
            <div className="col">
                <Field
                    name={nameIban}
                    component={TextFieldRedux}
                    label={'IBAN'}
                    validate={[
                        (value) => {
                            if (queryIBAN.isError && !queryIBAN.isFetching) {
                                return errorMessage;
                            }
                            if (value && (value.length < 22 || value.length > 32)) {
                                return "L'IBAN doit contenir entre 22 et 32 caractères";
                            }
                        },
                    ]}
                    onChange={(e) => {
                        changeValue('bic', '');
                        changeValue('domiciliation', '');
                    }}
                />
                {queryIBAN.isLoading && <Spinner />}
            </div>
            <div className="col">
                <Field
                    name="domiciliation"
                    component={TextFieldRedux}
                    label={'Domiciliation de la banque'}
                    disabled
                    normalize={capitalizeInput}
                />
            </div>
            <div className="col">
                <Field
                    name="bic"
                    component={TextFieldRedux}
                    label={'Code BIC'}
                    disabled
                    normalize={capitalizeInput}
                />
            </div>
        </div>
    );
}, (prevProps, nextProps) => {
    return prevProps.formValues === nextProps.formValues;
});

export default IbanField;
