import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import Rgt from "./Rgt";
import {formValueSelector, reduxForm, change} from "redux-form";
import ErrorHttpComponent from "../../../../../Commun/Error/ErrorHttpComponent";
import {getErrorEntity, getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {CLOSE_TASK} from "../../../../../../redux/actions/app/tasks/tasks.actions";
import {
    updateAccidentByTaskGetLink
} from "../../../../../../redux/selectors/links/links.selectors";
import _ from "lodash";
import loader from "../../../../../Commun/Spinner/Loader/Loader";
import {POST_UPDATE_ACCIDENT_BY_TASK, postUpdateAccidentByTask} from "@/redux/actions/app/accidents/accidents.actions";
import validate from "./validate";

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector('task-rgt')

    return {
        task: ownProps.task,
        uri: updateAccidentByTaskGetLink(state),
        terminateQuery: ownProps.terminateTask,
        loadingTerminate: getLoadingEntity(state, CLOSE_TASK),
        errorTerminate: getErrorEntity(state, CLOSE_TASK),
        loadingRgt: getLoadingEntity(state, POST_UPDATE_ACCIDENT_BY_TASK),
        errorRgt: getErrorEntity(state, POST_UPDATE_ACCIDENT_BY_TASK),
        datesurv_ok: selector(state, 'dateSurvOk'),
        categorie_ok: selector(state, 'catsinOk'),
        categorie_correctif: selector(state, 'catsin'),
        type_doc: selector(state, 'type_doc'),
        sign_pec: selector(state, 'signPec')
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    postUpdateAccidentByTask: ({query, form, accident, terminateQuery}) => postUpdateAccidentByTask({
        query,
        form,
        accident,
        terminateQuery
    }),
    change: (field, value) => change('task-rgt', field, value)
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {postUpdateAccidentByTask} = dispatchProps
    const {uri, terminateQuery, task} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form) => postUpdateAccidentByTask({
            query: _.replace(_.replace(uri, '{id}', task.contract.id), '{ids}', task.sinistre.id),
            form,
            accident: task.sinistre.id,
            terminateQuery
        }),
    }
}

const RgtContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'task-rgt',
        validate,
    }),
    loader({ErrorIndicator: ErrorHttpComponent})
)(Rgt)

export default RgtContainer
