import React, { useState } from 'react';
import { Field } from "redux-form";
import DatePickerFieldRedux from "../../../../../../../Commun/Input/DatePicker/DatePickerFieldRedux";
import RadioFieldRedux from "../../../../../../../Commun/Input/Radio/RadioFieldRedux";
import { getCategoryByTypeAccident } from "./getCategoryByTypeAccident";
import moment from 'moment';
import _ from 'lodash';
import PostalCodeCity from "../../../../../../../Commun/PostalCodeCity/PostalCodeCity";
import SelectFieldRedux from "../../../../../../../Commun/Input/Select/SelectFieldRedux";
import { paysSinistre } from "../../../../../../../../utils/optionsSelect";

const Declaration = (props) => {
    const [alert, setAlert] = useState(false);

    const {
        getDocumentsAccident,
        setEmailToClient,
        pays_lieu_sinistre,
        changeValue,
        type_sinistre,
        dateFinContrat,
        changeValidFirstCheck,
        changeValidSecondCheck,
        datesurvorigin,
        catsinorigin,
        dateAfnNouvelle,
        sinistres,
        codeProduit,
        pays,
        cplieu,
        isFleet,
        dateDerPiece,
        pieces,
        derniereAdhesionPrincipale,
        garanties
    } = props;

    const sinistreDejaDeclare = _.find(sinistres.data, s => {
        return moment(s.dateSurv).format('L') === moment(datesurvorigin).format('L');
    });

    const checkStep = () => {
        if (dateFinContrat != null && moment(dateFinContrat).add(1, 'days') < moment(datesurvorigin)) {
            console.log('test1')
            handleAlert(true);
            changeValidFirstCheck(false);
            setEmailToClient('contrat_resilie');
            return false;
        }

        if (moment(dateAfnNouvelle) > moment(datesurvorigin)) {
            console.log('test2')
            handleAlert(true);
            changeValidFirstCheck(false);
            setEmailToClient('contrat_resilie');
            return false;
        }

        // Attention fonctionnalitée retiré le 10/04/24 et remis en plus suite MEP WAKAM à cause d'un doute du service IDEM
        if (!_.find(garanties, ['code_famille', 'VI']) && _.includes(['10.0', '105.1', '9.0', '105.2', '19.0'], catsinorigin)) {
            handleAlert(true)
            changeValidFirstCheck(false)
            if (catsinorigin === '19.0') {
                setEmailToClient('cat_naturelle')
            } else {
                setEmailToClient('vol')
            }
            return false
        }

        if (_.includes(['104.5', '19.0'], catsinorigin)) {
            console.log('test3')
            handleAlert(false);
            changeValidFirstCheck(true);
            changeValidSecondCheck(true);
            return false;
        }

        handleAlert(false);
        if ((catsinorigin && pays_lieu_sinistre && (pays || cplieu)) || codeProduit === '7311_VAE') {
            changeValidFirstCheck(true);
        } else {
            changeValidFirstCheck(false);
        }
    };

    const handleAlert = (alert) => {
        setAlert(alert);
    };

    return (
        <div className={'form-content'}>
            <h1 className={'title-primary'}>Que s'est-il passé ?</h1>

            {codeProduit === '7311_VAE' ? (
                <>
                    <Field
                        name='catsinorigin'
                        component={RadioFieldRedux}
                        label='Catégorie du sinistre'
                        data={[
                            {value: '103.1', label: 'Accident avec Tiers'},
                            {value: '104.2', label: 'Accident sans Tiers'},
                            {value: '17.0', label: 'Casse'},
                            {value: '10.0', label: 'Vol'},
                            {value: '105.1', label: 'Tentative de vol'},
                            {value: '28.0', label: 'Catastrophes naturelles'}
                        ]}
                        onChange={(e) => getDocumentsAccident(_.split(e.target.value, '.'))}
                    />
                </>
            ) : (
                <>
                    <Field
                        name='type_sinistre'
                        component={RadioFieldRedux}
                        label={'Le Type de sinistre'}
                        data={[
                            {value: "accident", label: "Accident"},
                            {value: "vol", label: "Vol - Tentative de vol"},
                            {value: "autres", label: "Autres"}
                        ]}
                        onChange={() => changeValue('catsinorigin', "")}
                        hidden
                    />

                    {type_sinistre && (
                        <Field
                            name='catsinorigin'
                            component={RadioFieldRedux}
                            label='Catégorie du sinistre'
                            data={getCategoryByTypeAccident(type_sinistre)}
                            onChange={(e) => getDocumentsAccident(_.split(e.target.value, '.'))}
                        />
                    )}
                </>
            )}

            <Field
                name={"datesurvorigin"}
                component={DatePickerFieldRedux}
                label={<p>Date du sinistre {type_sinistre === 'vol' ? <span style={{color: "#ff0000"}}>(Si la date du vol se trouve entre 2 date, indiquer la date la plus ancienne)</span> : ''}</p>}
                maxDate={moment()}
                onChange={(e) => !isFleet && e && e.isValid() && e.isBefore(dateDerPiece) ? null : changeValue('risque', derniereAdhesionPrincipale.toString())}
            />

            {!isFleet && datesurvorigin && datesurvorigin.isBefore(dateDerPiece) && (
                <>
                    <div className={'alert alert-warning'}>
                        <p>Attention, il y a une incohérence sur la date de survenance par rapport à la date de la dernière pièce.</p>
                        <p>Merci de vérifier les données.</p>
                    </div>

                    <Field
                        name='risque'
                        component={SelectFieldRedux}
                        label={'Changer de pièce sur le contrat'}
                    >
                        {pieces.map((p, index) => (
                            <option key={index} value={p.adhesionPrincipale}>{p.motif} : {moment(p.date).format('L')}{p.dateFin ? ` - ${moment(p.dateFin).format('L')}` : ''}</option>
                        ))}
                    </Field>
                </>
            )}

            {codeProduit !== '7311_VAE' ? (
                <>
                    <Field
                        name='pays_lieu_sinistre'
                        component={RadioFieldRedux}
                        label={'Le sinistre a-t-il eu lieu dans un pays étranger ?'}
                        data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                        onChange={() => changeValue('lieu', "")}
                    />

                    {pays_lieu_sinistre === "T" && (
                        <Field
                            name='pays'
                            component={SelectFieldRedux}
                            label={'Pays où le sinistre au eu lieu'}
                            input={{
                                onChange: (e) => {
                                    changeValue('lieu', e.target.value);
                                    changeValue('cplieu', "99000");
                                }
                            }}
                        >
                            <option value={""}>Choisissez un pays</option>
                            {paysSinistre.map((pays, index) => (
                                <option key={index} value={pays.value}>{pays.label}</option>
                            ))}
                        </Field>
                    )}

                    {pays_lieu_sinistre === "F" && <PostalCodeCity
                        nameCodePostal='cplieu'
                        nameVille='lieu'
                        label={'Lieu du sinistre'}
                        changeValue={changeValue}
                    />}
                </>
            ) : (
                <PostalCodeCity
                    nameCodePostal='cplieu'
                    nameVille='lieu'
                    label={'Lieu du sinistre'}
                    changeValue={changeValue}
                />
            )}

            {alert && (
                <div className="alert alert-danger" role="alert">
                    <p>Les garanties du contrat et les éléments de la déclaration ne permettent pas de donner suite à cette déclaration.</p>
                    <p>Une confirmation est adressée au client.</p>
                </div>
            )}

            {sinistreDejaDeclare && !isFleet && (
                <div className="alert alert-danger" role="alert">
                    <p>Attention, un sinistre a déjà été déclaré à cette date, vous ne pouvez pas poursuivre la déclaration.</p>
                </div>
            )}

            <span className={`btn btn-primary float-right ${sinistreDejaDeclare && !isFleet && 'disabled'}`}
                  onClick={() => sinistreDejaDeclare && !isFleet ? null : checkStep()}
            >
                Poursuivre
            </span>

        </div>
    );
};

export default Declaration;
