import React from 'react'

const InfoCompagnie = ({tiers}) => {
    return (
        <div className={'bloc-info bloc-souscripteur'}>
            <h2>Informations de la compagnie</h2>
            <div className={'content row'}>
                <div className={'col-5 left-content'}>
                    <p>Raison Sociale :</p>
                    <p>Adresse :</p>
                    {tiers.address_street_2 && <p>&nbsp;</p>}
                    <p>Code Postal - Ville :</p>
                    <p>Téléphone :</p>
                    <p>Email :</p>
                    <p>IBAN :</p>
                    <p>Titulaire du compte :</p>
                </div>
                <div className={'col-7 right-content'}>
                    <p>{tiers.name}</p>
                    <p>{tiers.address_street_1}</p> {tiers.address_street_2 && <p>{tiers.address_street_2}</p>}
                    <p>{tiers.address_postalcode} {tiers.address_city}</p>
                    <p>{tiers.address_phone || '-'}</p>
                    <p>{tiers.address_mail || '-'}</p>
                    <p>{tiers.banque.iban || '-'}</p>
                    <p>{tiers.banque.titulaire || '-'}</p>
                </div>
            </div>
        </div>
    )
}

export default InfoCompagnie
