export const CHANGE_ADDRESS = '[Change Address]'

export const POST_CHANGE_ADDRESS = `${CHANGE_ADDRESS} Post`

export const postChangeAddress = ({query, form, formName}) => ({
    type: POST_CHANGE_ADDRESS,
    payload: {
        data: query,
        body: form,
        otherData: {
            id: form.contrat,
            formName
        }
    }
})
