import {connect} from 'react-redux'
import {formValueSelector} from 'redux-form'
import Reparation from './Reparation'
import {
    getContractGarantiesOptions,
    getContractImmatriculation,
    getContractMarqueModele, getIsFleetContract, getIsWarkamContract
} from "../../../../../../../../redux/selectors/contract/contract.selectors";
import {getGarantiesFlotteDetailData} from "../../../../../../../../redux/selectors/flotte/flotte.selectors";

const mapStateToProps = state => {
    const selector = formValueSelector('declaration-sinistre')

    return {
        isWakam: getIsWarkamContract(state),
        reparateur: selector(state, 'reparateur'),
        showVisitExpert: selector(state, 'showVisitExpert'),
        garanties: getIsFleetContract(state) ? getGarantiesFlotteDetailData(state) : getContractGarantiesOptions(state),
        categorie: selector(state, 'catsinorigin'),
        reparateur_autre: selector(state, 'reparateur_autre'),
        cpreparateur: selector(state, 'cpreparateur'),
        reparateurAutreVille: selector(state, 'reparateurAutreVille'),
        vehicule: {
            immatriculation: getContractImmatriculation(state),
            marqueModele: getContractMarqueModele(state)
        }
    }
}

const ReparationContainer = connect(mapStateToProps)(Reparation)

export default ReparationContainer
