import {createSelector} from 'reselect'

const dashboardTeleventeSelectors = state => state.dashboard_televente

export const getDashboardTeleventeData = createSelector(
    dashboardTeleventeSelectors,
    dashboard => dashboard
)
export const getDashboardTeleventeTasks = createSelector(
    dashboardTeleventeSelectors,
    dashboard => dashboard.tasks
)
export const getDashboardTeleventeTasksData = createSelector(
    getDashboardTeleventeTasks,
    tasks => tasks.data || {}
)

export const getDashboardTeleventeTasksMeta = createSelector(
    getDashboardTeleventeTasks,
    tasks => tasks.meta
)

export const getDashboardTeleventeTasksLinks = createSelector(
    getDashboardTeleventeTasks,
    tasks => tasks.links
)

export const getDashboardTeleventeTaskData = createSelector(
    getDashboardTeleventeData,
    dashboard => dashboard.task
)

export const getDashboardTeleventeGestionnairesData = createSelector(
    getDashboardTeleventeData,
    dashboard => dashboard.gestionnaires
)
