import React from 'react';

const RadioSimpleFieldRedux = (props) => {
    const {
        input: {onChange, value, name, checked},
        meta: {touched, error},
        label,
        data,
        type = 'radio',
        className
    } = props

    let newChecked = checked
    if (value === "F") {
        newChecked = false
    }

    return (
        <div className={`form-group ${className}`}>
            {
                label && <label
                    className={`d-block ${touched ? Boolean(error) ? 'is-invalid text-danger' : 'is-valid' : ''}`}>{label}</label>
            }
            <div className="" data-toggle="buttons">
                {
                    data.map((radio, index) => (
                        <div className="form-check mb-2" key={index}>
                            <input className="form-check-input" type={type} name={name} checked={radio.value === value}
                                   value={radio.value} id={`input-radio-${name}-${index}`} onChange={onChange}/>
                            <label className="form-check-label mb-0" htmlFor={`input-radio-${name}-${index}`}>
                                {radio.label}
                            </label>
                        </div>
                    ))
                }
                {touched && error && <small className="invalid-feedback">{error}</small>}
            </div>
        </div>
    );
};

export default RadioSimpleFieldRedux;
