import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import {ACTIVITY, getActivity} from '../../../../../../redux/actions/app/activity/activity.actions'
import {getErrorEntity, getLoadingEntity} from '../../../../../../redux/selectors/ui/ui.selectors'
import Activity from './Activity'
import {activityGetLink} from '../../../../../../redux/selectors/links/links.selectors'
import ErrorHttpComponent from '../../../../../Commun/Error/ErrorHttpComponent'
import {getActivityData} from '../../../../../../redux/selectors/activity/activity.selectors'
import {TIERS} from "../../../../../../redux/actions/app/tiers/tiers.actions";
import loader from "../../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    return {
        activity_logs: getActivityData(state),
        uri: activityGetLink(state, TIERS),
        loaded: getLoadingEntity(state, ACTIVITY) === false,
        error: getErrorEntity(state, ACTIVITY)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getActivity: (query) => getActivity({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getActivity} = dispatchProps
    const {uri} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getActivity(uri)
    }
}

const ActivityLogsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(Activity)

export default ActivityLogsContainer
