import {
    CONTRACT, GET_CONTRACT, POST_CONFIRM_RESIL_HAMON, POST_CONTRACT_DRIVER_NOT_MINOR, GET_TARIF_CONTRACT,
    POST_DEACTIVATE_LOYALTY_CODE, setContract, setTarifContract, TARIF_CONTRACT
} from '../../../actions/app/contracts/contracts.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {PAYMENT} from "../../../actions/app/payment/payment.actions";
import {logAction} from "../../../actions/core/logs/logs.actions";

export const contractMiddleware = () => next => action => {
    next(action)
    let message = null

    switch (action.type) {
        case GET_CONTRACT:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: CONTRACT}),
                setLoader({state: true, entity: CONTRACT}),
                logAction({type: 'SHOW_CONTRACT', entity: CONTRACT, contrat: action.payload.otherData.id})
            ])
            break

        case `${CONTRACT} ${API_SUCCESS}`:
            let params = window.location.search

            if (params.paid === '1') {
                message = `<p>Le paiement d'un montant de <strong>${params.amount / 100}€</strong> a été effectué avec succès. Un email va être envoyé au client.</p>`
            } else if (params.paid === '0') {
                message = `<p>Erreur lors du paiement. Le paiement a été annulé.</p>`
            }
            if (message !== null) {
                next([
                    setNotification({
                        entity: PAYMENT,
                        body: message,
                        type: params.paid === '1' ? 'success' : 'error',
                        title: params.paid === '1' ? 'Paiement effectué' : 'Attention !',
                        timeout: params.paid === '1' ? '10000' : '0'
                    })
                ])
            }
            next([
                setTarifContract({data: null}),
                setContract({data: action.payload.data}),
                setLoader({state: false, entity: CONTRACT}),
                setError({state: false, entity: CONTRACT})
            ])
            break

        case `${CONTRACT} ${API_ERROR}`:
            next([
                setError({state: true, entity: CONTRACT}),
                setLoader({state: false, entity: CONTRACT})
            ])
            break

        case POST_DEACTIVATE_LOYALTY_CODE:
            next([
                apiRequest({
                    body: null,
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_DEACTIVATE_LOYALTY_CODE
                }),
                setLoader({state: true, entity: POST_DEACTIVATE_LOYALTY_CODE}),
            ])
            break

        case `${POST_DEACTIVATE_LOYALTY_CODE} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_DEACTIVATE_LOYALTY_CODE,
                    body: 'La modification a bien été prise en compte',
                    type: 'success',
                    title: 'Modification'
                }),
                setLoader({state: false, entity: POST_DEACTIVATE_LOYALTY_CODE}),
                setError({state: false, entity: POST_DEACTIVATE_LOYALTY_CODE})
            ])
            break

        case `${POST_DEACTIVATE_LOYALTY_CODE} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_DEACTIVATE_LOYALTY_CODE,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_DEACTIVATE_LOYALTY_CODE}),
                setLoader({state: false, entity: POST_DEACTIVATE_LOYALTY_CODE})
            ])
            break


        case POST_CONTRACT_DRIVER_NOT_MINOR:
            next([
                apiRequest({
                    body: null,
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_CONTRACT_DRIVER_NOT_MINOR
                }),
                setLoader({state: true, entity: POST_CONTRACT_DRIVER_NOT_MINOR}),
            ])
            break

        case `${POST_CONTRACT_DRIVER_NOT_MINOR} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_CONTRACT_DRIVER_NOT_MINOR,
                    body: 'La modification a bien été prise en compte',
                    type: 'success',
                    title: 'Modification'
                }),
                setLoader({state: false, entity: POST_CONTRACT_DRIVER_NOT_MINOR}),
                setError({state: false, entity: POST_CONTRACT_DRIVER_NOT_MINOR})
            ])
            break

        case `${POST_CONTRACT_DRIVER_NOT_MINOR} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_CONTRACT_DRIVER_NOT_MINOR,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_CONTRACT_DRIVER_NOT_MINOR}),
                setLoader({state: false, entity: POST_CONTRACT_DRIVER_NOT_MINOR})
            ])
            break

        case POST_CONFIRM_RESIL_HAMON:
            const confirmResil = action.payload.body.confirm_resil;
            next([
                apiRequest({
                    body: action.payload.body,
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_CONFIRM_RESIL_HAMON,
                    otherData: {
                        ...action.payload.otherData,
                        confirmResil
                    }
                }),
                setLoader({ state: true, entity: POST_CONFIRM_RESIL_HAMON })
            ]);
            break;

        case `${POST_CONFIRM_RESIL_HAMON} ${API_SUCCESS}`:
            next([
                setError({ state: false, entity: POST_CONFIRM_RESIL_HAMON }),
                setLoader({ state: false, entity: POST_CONFIRM_RESIL_HAMON }),
                setNotification({
                    entity: POST_CONFIRM_RESIL_HAMON,
                    // html: `<div><p>${notificationMessage}</p></div>`,
                    type: 'success',
                    title: 'Loi hamon',
                    body: 'Vous avez modifié les informations de la Loi Hamon',
                    confirmButtonText: 'J\'ai compris',
                    successFunction: action.payload.meta.otherData.successFunction
                })
            ]);
            break;

        case `${POST_CONFIRM_RESIL_HAMON} ${API_ERROR}`:
            next([
                setError({ state: true, entity: POST_CONFIRM_RESIL_HAMON }),
                setLoader({ state: false, entity: POST_CONFIRM_RESIL_HAMON }),
                setNotification({
                    entity: POST_CONFIRM_RESIL_HAMON,
                    html: `<p>${action.payload.data.response.data?.message ?? ''}</p>`,
                    icon: 'error',
                    title: 'Attention !',
                    confirmButtonText: 'J\'ai compris'
                })
            ]);
            break;

        case GET_TARIF_CONTRACT:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: TARIF_CONTRACT}),
                setLoader({state: true, entity: TARIF_CONTRACT}),
            ])
            break

        case `${TARIF_CONTRACT} ${API_SUCCESS}`:

            next([
                setTarifContract({data: action.payload.data.data}),
                setLoader({state: false, entity: TARIF_CONTRACT}),
                setError({state: false, entity: TARIF_CONTRACT})
            ])
            break

        case `${TARIF_CONTRACT} ${API_ERROR}`:
            next([
                setError({state: true, entity: TARIF_CONTRACT}),
                setLoader({state: false, entity: TARIF_CONTRACT})
            ])
            break
        default:
            break
    }
    return null
}
