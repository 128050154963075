export const PIECES = '[Pieces]'

export const GET_PIECES = `${PIECES} Get`
export const SET_PIECES = `${PIECES} Set`

export const getPieces = ({query}) => ({
    type: GET_PIECES,
    payload: {
        data: query
    }
})

export const setPieces = ({data}) => ({
    type: SET_PIECES,
    payload: {data},
    meta: {
        entity: PIECES
    }
})
