import React, {useEffect, useState} from 'react';
import {getCountries} from "../../../../../utils/function";
import _ from "lodash";
import {Field} from "redux-form";
import TextFieldRedux from "../../../../Commun/Input/Text/TextFieldRedux";
import DatePickerFieldRedux from "../../../../Commun/Input/DatePicker/DatePickerFieldRedux";
import AutoComplete from "../../../../Commun/Input/Autocomplete/Autocomplete";
import Spinner from "../../../../Commun/Spinner/Spinner";
import RadioFieldRedux from "../../../../Commun/Input/Radio/RadioFieldRedux";
import moment from "moment";
import SelectFieldRedux from "../../../../Commun/Input/Select/SelectFieldRedux";
import {tiersPayeurRelation} from "../../../../../utils/optionsSelect";

const AddTiersPayeur = (props) => {
    const {handleSubmit, edit, loading, type, changeValue, relationship} = props
    const [listCountries, setListCountries] = useState(false)

    async function fetchCountries() {
        let countries = await getCountries()
        let list = _.orderBy(countries, ['name'], ['asc'])

        list = list.map(c => ({
            value: c.id,
            label: c.name
        }))

        setListCountries(list)
    }

    useEffect(() => {
        if (!listCountries) {
            fetchCountries()
        }
    }, [listCountries]);

    return (
        <div className={'contract-container container'}>
            <div className={'row return-content'}>
                <div className={'col'}>
                    <button onClick={() => props.history.go(-1)} className={'btn btn-return'}>Retour</button>
                </div>
            </div>
            <div className={'row header-row'}>
                <div className={'col-12'}>
                    <form onSubmit={handleSubmit} className={'form-content'}>
                        <div className="row">
                            <div className="col-12">
                                <Field
                                    name='type'
                                    component={RadioFieldRedux}
                                    label={'Type de tiers payeur'}
                                    data={[{value: "P", label: "Physique"}, {value: "M", label: "Morale"}]}
                                    onChange={(e) => {
                                        if(e.target.value === "P"){
                                            changeValue('birthdate', moment().subtract(14, 'year'))
                                        } else {
                                            changeValue('firstname', null)
                                            changeValue('birthname', null)
                                            changeValue('birthdate', null)
                                            changeValue('birthcountry', null)
                                            changeValue('birthcity', null)
                                            changeValue('relationship', null)
                                            changeValue('third_payeur_reason', null)
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <Field
                                    name='lastname'
                                    component={TextFieldRedux}
                                    label={type === 'P' ? 'Nom' : 'Nom de la société'}
                                    normalize={v => v.toUpperCase()}
                                    maxLength={50}
                                />
                            </div>
                            { type === 'P' &&
                                <>
                                    <div className="col-6">
                                        <Field
                                            name='firstname'
                                            component={TextFieldRedux}
                                            label={'Prénom'}
                                            normalize={v => v.toUpperCase()}
                                            maxLength={20}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <Field
                                            name={'birthname'}
                                            component={TextFieldRedux}
                                            label={'Nom de naissance'}
                                            normalize={v => v.toUpperCase()}
                                            maxLength={20}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <Field
                                            name={"birthdate"}
                                            component={TextFieldRedux}
                                            label={'Date de naissance'}
                                            maxDate={moment().subtract(16, 'Y')}
                                            typeFormat={'date'}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <Field
                                            name={'birthcity'}
                                            component={TextFieldRedux}
                                            label={'Ville de naissance'}
                                            maxLength={250}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <Field
                                            name={'birthcountry'}
                                            component={AutoComplete}
                                            label="Pays de naissance"
                                            data={listCountries}
                                        />
                                    </div>

                                    <div className="col-6">
                                        <Field
                                            name='relationship'
                                            component={SelectFieldRedux}
                                            label={'Lien souscripteur / payeur'}
                                            onChange={(e) => {
                                                if(e.target.value !== 'A'){
                                                    changeValue('third_payeur_reason', null)
                                                }
                                            }}
                                        >
                                            <option value={""}>Sélectionnez une relation</option>
                                            {tiersPayeurRelation.map((relation, index) => (
                                                <option key={index} value={relation.code}>{relation.libelle}</option>
                                            ))}
                                        </Field>
                                    </div>
                                    {
                                        relationship && relationship === 'A' ?
                                        <div className="col-6">
                                            <Field
                                                name={'third_payeur_reason'}
                                                component={TextFieldRedux}
                                                label={'Motif d\'intervention du payeur'}
                                                maxLength={250}
                                            />
                                        </div> : null
                                    }
                                </>
                            }
                        </div>
                        {
                            loading ? <Spinner style={{display: 'block', margin: 'auto'}}/> :
                                <button className={'btn btn-primary d-block'}
                                        type={'submit'}>
                                    {edit ? 'Editer le tiers payeur' : 'Ajouter le tiers payeur'}
                                </button>
                        }
                    </form>
                </div>
            </div>
        </div>
    );
};
export default AddTiersPayeur;
