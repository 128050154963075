import {SET_FIDELISATION} from "../../actions/app/fidelisations/fidelisations.actions";

export const fidelisationReducer = (fidelisation = [], action = {}) => {
    const { payload } = action;

    if (action.type === SET_FIDELISATION) {
        return payload.data;
    } else {
        return fidelisation;
    }
};
