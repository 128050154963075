import React from 'react'

const TextFieldSearchRedux = ({
                                  input,
                                  type,
                                  ...custom
                              }) => {
    return (
        <input
            className={'form-control'}
            type={type}
            {...input}
            {...custom}
        />
    )
}

export default (TextFieldSearchRedux)
