import {connect} from 'react-redux'
import {compose} from "redux";
import EditAntecedent from "./EditRIAntecedent";
import {getLoadingEntity, getUpdateAntecedentPeriod} from "../../../../../redux/selectors/ui/ui.selectors";
import {
    antecedentsPeriodDetailGetLink,
    antecedentsPeriodUpdateLink
} from "../../../../../redux/selectors/links/links.selectors";
import moment from "moment";
import _ from "lodash";

import {change, reduxForm} from "redux-form";
import validate from "./validate";
import loader from "../../../../Commun/Spinner/Loader/Loader";
import {
    GET_ANTECEDENT_PERIOD,
    getAntecedentPeriod, POST_UPDATE_ANTECEDENT_PERIOD,
    postUpdateAntecedentPeriod
} from "../../../../../redux/actions/app/antecedents/antecedents.actions";
import {getAntecedentPeriodData} from "../../../../../redux/selectors/antecedent_period/antecedent_period.selectors";

const mapStateToProps = (state, ownProps) => {
    const antecedent = getAntecedentPeriodData(state);
    return {
        antecedent: antecedent,
        update_antecedent: getUpdateAntecedentPeriod(state),
        query: antecedentsPeriodDetailGetLink(state),
        query_update: antecedentsPeriodUpdateLink(state),
        loaded: getLoadingEntity(state, GET_ANTECEDENT_PERIOD) === false,
        loading: getLoadingEntity(state, POST_UPDATE_ANTECEDENT_PERIOD) === true,
        idContract: ownProps.match.params.id,
        idVersionning: ownProps.match.params.ids,
        initialValues: {
            'startDate': moment(antecedent.start, 'DD/MM/YYYY'),
            'endDate': moment(antecedent.end, 'DD/MM/YYYY'),
        }
    }
}

const mapDispatchToProps = {
    getAntecedentPeriod,
    postUpdateAntecedentPeriod,
    changeValue: (field, value) => change('edit_antecedents_ri', field, value)
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query, query_update, idContract, idVersionning } = stateProps
    const { getAntecedentPeriod, postUpdateAntecedentPeriod } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getAntecedentPeriod(_.replace(_.replace(query, '{id}', idContract), '{ids}', idVersionning)),
        onSubmit: form => postUpdateAntecedentPeriod({
            query: _.replace(_.replace(query_update, '{id}', idContract), '{ids}', idVersionning),
            form
        })
    }
}

const EditRIAntecedentContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader(),
    reduxForm({
        form: 'edit_antecedents_ri',
        validate
    }),
)(EditAntecedent)

export default EditRIAntecedentContainer