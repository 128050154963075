import {createSelector} from 'reselect'

const antecedentsSelector = state => state.antecedents

export const getAntecedentsData = createSelector(
    antecedentsSelector,
    antecedents => antecedents.data || {}
)
export const getAntecedentsMoto = createSelector(
    getAntecedentsData,
    antecedents => antecedents?.moto
)

export const getAntecedentsAuto = createSelector(
    getAntecedentsData,
    antecedents => antecedents?.auto
)
