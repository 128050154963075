import {CLOSE_TASK} from "../../actions/app/tasks/tasks.actions";

export const close_taskReducer = (terminate = false, action = {}) => {
    const {payload} = action

    if (action.type === CLOSE_TASK) {
        return payload
    } else {
        return terminate
    }
}
