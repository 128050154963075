import React from 'react';
import {FieldArray} from "redux-form";
import FieldArrayBlesses from "./FieldArrayBlesses";

const Blesses = (props) => {
    return (
        <div className={`form-content`}>
            <h1 className={'title-primary'}>Bléssé(e)(s)</h1>

            <FieldArray
                name='victimes'
                component={FieldArrayBlesses}
            />
        </div>
    );
};

export default Blesses;
