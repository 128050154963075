import React from 'react'
import moment from "moment";
import ReassignTaskContainer from "../../../Forms/ReassignTask/ReassignTaskContainer";
import APIFrame from "../../../../../Commun/APILink/APIFrame";
import APIImage from "../../../../../Commun/APILink/APIImage";

const TacheReassign = (props) => {
    const {task, task: {date, type, paniere, tiers_id}, files} = props

    return (
        <div className={`${files !== undefined ? '' : 'container'}`}>
            <div className={'row'}>
                <div className={'col text-center'}>
                    <h2 className={'title-primary text-uppercase'}>{paniere} - Tiers n°{tiers_id}</h2>
                    <p className={'subtitle'}>Tâche du {moment(date).format('L')}. Recu par {type}</p>
                </div>
            </div>
            <div className={'header-row row'}>
                {files !== undefined &&
                    files.map((file) => (
                        file.extension.toLowerCase() === 'pdf' ?
                            <div className={'col-8'} style={{marginBottom: 30}}>
                                <APIFrame src={`${file.url}`} title={`${file.code}`} frameBorder="0"
                                        style={{height: '100%', width: '100%', marginBottom: 30}}/>
                            </div>
                            :
                            <div className={'col-7 task-img-container'}>
                                <APIImage src={`${file.url}`} alt={'Document'}/>
                            </div>
                    ))
                }
                <div className={` ${files !== undefined ? 'col-md-4' : 'col-12'}`}>
                    <ReassignTaskContainer task={task} keep_old={0}/>
                </div>
            </div>
        </div>
    )
}

export default TacheReassign
