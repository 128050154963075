const validate = values => {
    const errors = {}
    const requiredFields = [
        'startDate',
        'endDate'
    ];

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Le champ est obligatoire'
        }
    })

    if (values.startDate && values.endDate && values.startDate.isValid() && values.endDate.isValid() && values.endDate.isBefore(values.startDate)) {
        errors.endDate = 'La date de fin ne peut pas être avant la date de début'
    }

    return errors
}

export default validate
