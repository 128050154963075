import {createSelector} from 'reselect'

const taskSelector = state => state.task.data

export const getTaskDetailData = createSelector(
    taskSelector,
    task => task
)

export const getFilesTask = createSelector(
    taskSelector,
    task => task.files_url
)

export const getIdContractInTask = createSelector(
    taskSelector,
    task => task.contract.id
)

export const getIdBelairInTask = createSelector(
    taskSelector,
    task => task.contract.souscripteur.id
)

export const getTerminateUrlInTask = createSelector(
    taskSelector,
    task => task.terminate_url
)
