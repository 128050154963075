import {connect} from 'react-redux'
import {compose} from 'redux'
import Commentaires from "./Commentaires";
import {postUpdateAccidentComment} from "../../../../../redux/selectors/links/links.selectors";
import {reduxForm} from "redux-form";
import _ from "lodash";
import './commentaires.scss'
import {postCommentAccident} from "../../../../../redux/actions/app/accidents/accidents.actions";


const mapStateToProps = (state, ownProps) => {
    return {
        uri: postUpdateAccidentComment(state),
        accidentId: ownProps.accident.id,
        contractId: ownProps.accident.id_contrat,
        initialValues: {
            "commentaire": ownProps.accident.commentaire
        }
    }
}

const mapDispatchToProps = {
    postCommentAccident
}


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uri, accidentId, contractId} = stateProps
    const {postCommentAccident} = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: form => postCommentAccident({
            query: _.replace(_.replace(uri, '{id}', contractId), '{ids}', accidentId),
            form
        })
    }
}


const CommentairesContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'accident-commentaire'
    })
)(Commentaires)


export default CommentairesContainer
