import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import {change} from 'redux-form'
import Upload from './Upload'


const mapDispatchToProps = (dispatch,) => bindActionCreators({
    changeValue: (formName, field, value) => change(formName, field, value)
}, dispatch)

const UploadContainer = connect(null, mapDispatchToProps)(Upload)

export default UploadContainer
