import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {getLoadingEntity} from '../../../../../redux/selectors/ui/ui.selectors'
import {
    EXPIRATION_DATE_SEJOUR,
    postExpirationDateSejour
} from "../../../../../redux/actions/app/date_sejour/date_sejour.actions";
import {dateSejourLink} from "../../../../../redux/selectors/links/links.selectors";
import _ from "lodash";
import Antecedents from "./Antecedents";
import {getUserRoles} from "../../../../../redux/selectors/user/user.selectors";

const mapStateToProps = (state, ownProps) => {
    return {
        tiers: ownProps.tiers,
        contract: ownProps.contract,
        uri: dateSejourLink(state),
        loading: getLoadingEntity(state, EXPIRATION_DATE_SEJOUR),
        roles: getUserRoles(state)
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    submitPostExpirationDateSejour: ({form, query}) => postExpirationDateSejour({form, query}),
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {submitPostExpirationDateSejour} = dispatchProps
    const {uri, contract} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        PostExpirationDateSejour: (form) => submitPostExpirationDateSejour({
            form,
            query: _.replace(uri, '{id}', contract)
        })
    }
}
const AntecedentsContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Antecedents)

export default AntecedentsContainer
