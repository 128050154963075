import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {ACCIDENTS, getAccidents} from '../../../../../redux/actions/app/accidents/accidents.actions'
import {getErrorEntity, getLoadingEntity} from '../../../../../redux/selectors/ui/ui.selectors'
import Accidents from './Accidents'
import {accidentsContractGetLink} from '../../../../../redux/selectors/links/links.selectors'
import ErrorHttpComponent from '../../../../Commun/Error/ErrorHttpComponent'
import {getAccidentsData} from '../../../../../redux/selectors/accidents/accidents.selectors'
import {CONTRACT} from "../../../../../redux/actions/app/contracts/contracts.actions";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    return {
        accidents: getAccidentsData(state),
        uri: accidentsContractGetLink(state, CONTRACT),
        loaded: getLoadingEntity(state, ACCIDENTS) === false,
        error: getErrorEntity(state, ACCIDENTS),
        contract: ownProps.contract
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getAccidents: (query) => getAccidents({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getAccidents} = dispatchProps
    const {uri} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getAccidents(uri)
    }
}

const AccidentsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(Accidents)

export default AccidentsContainer
