import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import {dashboardTeleventeGetLink} from '../../../../redux/selectors/links/links.selectors'
import DashboardTelevente from "./DashboardTelevente";
import {
    DASHBOARD_TLV,
    getDashboardTelevente
} from "../../../../redux/actions/app/dashboard_televente/dashboard_televente.actions";
import {getLoadingEntity} from "../../../../redux/selectors/ui/ui.selectors";
import loader from "../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state) => {
    return {
        loaded: getLoadingEntity(state, DASHBOARD_TLV) === false,
        uri: dashboardTeleventeGetLink(state),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getDashboardTelevente,
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getDashboardTelevente} = dispatchProps
    const {uri} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getDashboardTelevente({query: uri, sort: ''})
    }
}

const DashboardContentContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(DashboardTelevente)

export default DashboardContentContainer
