import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import {getErrorEntity, getLoadingEntity} from '../../../../../../redux/selectors/ui/ui.selectors'
import {commerciauxGetLink} from '../../../../../../redux/selectors/links/links.selectors'
import ErrorHttpComponent from '../../../../../Commun/Error/ErrorHttpComponent'
import Commerciaux from "./Commerciaux";
import _ from "lodash";
import {COMMERCIAUX, getCommerciaux} from "../../../../../../redux/actions/app/commerciaux/commerciaux.actions";
import {getCommerciauxData} from "../../../../../../redux/selectors/commerciaux/commerciaux.selector";
import loader from "../../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    return {
        commerciaux: getCommerciauxData(state),
        uri: commerciauxGetLink(state),
        loaded: getLoadingEntity(state, COMMERCIAUX) === false,
        error: getErrorEntity(state, COMMERCIAUX)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getCommerciaux: (query) => getCommerciaux({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getCommerciaux} = dispatchProps
    const {uri} = stateProps
    const {id} = ownProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getCommerciaux(_.replace(uri, '{id}', id))
    }
}

const CommerciauxContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(Commerciaux)

export default CommerciauxContainer
