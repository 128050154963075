
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {
    POST_ADD_ACCESSOIRES,
    ADD_ACCESSOIRES,
    GET_ACCESSOIRES,
    ACCESSOIRES,
    setAccessoires,
    POST_DELETE_ACCESSOIRE,
    DELETE_ACCESSOIRE,
    GET_ACCESSOIRE_DETAIL,
    ACCESSOIRE_DETAIL,
    setAccessoireDetail
} from "../../../actions/app/accessoires/accessoires.actions";
import {CLOSE_TASK} from "../../../actions/app/tasks/tasks.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import moment from "moment";

export const addAccessoiresMiddleware = () => next => action => {
    next(action)
    const {payload} = action
    let nextAction = []

    switch (action.type) {
        case GET_ACCESSOIRES:
            next([
                apiRequest({body: null, method: 'GET', url: payload.data, entity: ACCESSOIRES}),
                setLoader({state: true, entity: ACCESSOIRES})
            ])
            break

        case `${ACCESSOIRES} ${API_SUCCESS}`:
            next([
                setAccessoires({accessoires: payload.data}),
                setError({state: false, entity: ACCESSOIRES}),
                setLoader({state: false, entity: ACCESSOIRES})
            ])
            break

        case `${ACCESSOIRES} ${API_ERROR}`:
            next([
                setError({state: true, entity: ACCESSOIRES}),
                setLoader({state: false, entity: ACCESSOIRES})
            ])
            break

        case GET_ACCESSOIRE_DETAIL:
            next([
                apiRequest({body: null, method: 'GET', url: payload.data, entity: ACCESSOIRE_DETAIL}),
                setLoader({state: true, entity: ACCESSOIRE_DETAIL})
            ])
            break

        case `${ACCESSOIRE_DETAIL} ${API_SUCCESS}`:
            next([
                setAccessoireDetail({accessoire: payload.data}),
                setError({state: false, entity: ACCESSOIRE_DETAIL}),
                setLoader({state: false, entity: ACCESSOIRE_DETAIL})
            ])
            break

        case `${ACCESSOIRE_DETAIL} ${API_ERROR}`:
            next([
                setError({state: true, entity: ACCESSOIRE_DETAIL}),
                setLoader({state: false, entity: ACCESSOIRE_DETAIL})
            ])
            break

        case POST_ADD_ACCESSOIRES:
            let data = new FormData()
            Object.keys(payload.body).forEach(key => {
                if (key === 'accessoires') {
                    payload.body[key].forEach((accessoire, index) => {
                        Object.keys(accessoire).forEach(i => {
                            switch (i) {
                                case 'dateAchat':
                                    data.append(`${key}[${index}][${i}]`, moment(accessoire[i]).format('YYYY-MM-DD'))
                                    break;
                                case 'libelle':
                                    payload.body[key][index][i] === 'autres' ? data.append(`${key}[${index}][${i}]`, 'Autres : ' + payload.body[key][index]['libelle_autres']) : data.append(`${key}[${index}][${i}]`, accessoire[i])
                                    break;
                                default:
                                    data.append(`${key}[${index}][${i}]`, accessoire[i])
                                    break;

                            }
                        })
                    })
                }
            })

            nextAction = [
                apiRequest({
                    body: data,
                    method: 'POST',
                    url: payload.data,
                    entity: ADD_ACCESSOIRES,
                    otherData: payload.otherData
                }),
                setLoader({state: true, entity: ADD_ACCESSOIRES})
            ]

            if (payload.otherData.terminate !== undefined) {
                nextAction.push(setLoader({state: true, entity: CLOSE_TASK}))
            }

            next(nextAction)
            break

        case `${ADD_ACCESSOIRES} ${API_SUCCESS}`:
            nextAction = [
                setError({state: false, entity: ADD_ACCESSOIRES}),
                setLoader({state: false, entity: ADD_ACCESSOIRES})
            ]

            if (action.payload.meta.otherData.terminate !== undefined) {
                nextAction.push(apiRequest({
                    body: null,
                    method: 'POST',
                    url: action.payload.meta.otherData.terminate,
                    entity: CLOSE_TASK
                }))
            } else {
                nextAction.push(setNotification({
                    entity: CLOSE_TASK,
                    body: `<p>Les factures ont été ajoutées</p>`,
                    type: 'success',
                    title: 'Factures ajoutés !'
                }))
            }
            next(nextAction)
            break

        case `${ADD_ACCESSOIRES} ${API_ERROR}`:
            next([
                setError({state: true, entity: ADD_ACCESSOIRES}),
                setLoader({state: false, entity: ADD_ACCESSOIRES})
            ])
            break

        case POST_DELETE_ACCESSOIRE:
            next([
                apiRequest({
                    body: (action.payload.body),
                    method: 'POST',
                    url: action.payload.data,
                    entity: DELETE_ACCESSOIRE
                }),
                setLoader({state: true, entity: DELETE_ACCESSOIRE})
            ])
            break

        case `${DELETE_ACCESSOIRE} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: DELETE_ACCESSOIRE,
                    body: `<p>L'accessoire a bien été supprimé</p>`,
                    type: 'success',
                    title: 'Accessoire supprimé !'
                }),
                setLoader({state: false, entity: DELETE_ACCESSOIRE}),
                setError({state: false, entity: DELETE_ACCESSOIRE})
            ])
            break

        case `${DELETE_ACCESSOIRE} ${API_ERROR}`:
            next([
                setNotification({
                    entity: DELETE_ACCESSOIRE,
                    body: `<p>Erreur lors de la suppression de l'accessoire: ${payload.data.response ? payload.data.response.data?.message ?? '' : ''}</p>`,
                    type: 'error',
                    title: 'Attention !',
                    timeout: '0'
                }),
                setError({state: true, entity: DELETE_ACCESSOIRE}),
                setLoader({state: false, entity: DELETE_ACCESSOIRE}),
            ])
            break

        default:
            break
    }
    return null
}
