import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {reduxForm, change, formValueSelector, clearFields} from 'redux-form'
import {ADD_ACTIVITY, postAddActivity} from '../../../../../redux/actions/app/activity/activity.actions'
import AddActivity from './AddActivity'
import {getErrorEntity, getLoadingEntity} from '../../../../../redux/selectors/ui/ui.selectors'
import validate from "./validate";
import moment from "moment";
import {getBelairName, getUserRoles} from "../../../../../redux/selectors/user/user.selectors";
import {CONTRACT, getContract} from "../../../../../redux/actions/app/contracts/contracts.actions";
import _ from "lodash";
import {
    accidentGetLink, contractDefaultGetLink,
    postAccidentActivityLink
} from "../../../../../redux/selectors/links/links.selectors";
import {ACCIDENT, getAccident} from "../../../../../redux/actions/app/accidents/accidents.actions";
import {getContractData} from "../../../../../redux/selectors/contract/contract.selectors";
import {getAccidentData} from "../../../../../redux/selectors/accident/accident.selectors";

import ErrorHttpComponent from "../../../../Commun/Error/ErrorHttpComponent";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector(`addActivityForm`)
    const contract = getContractData(state)
    const accident = getAccidentData(state)
    const loaded = getLoadingEntity(state, ACCIDENT) === false && getLoadingEntity(state, CONTRACT) === false

    return {
        loaded: loaded,
        error: getErrorEntity(state, ACCIDENT) && getErrorEntity(state, CONTRACT) === false,
        loading: getLoadingEntity(state, ADD_ACTIVITY),
        url: _.replace(_.replace(postAccidentActivityLink(state), '{id}', ownProps.match.params.id), '{ids}', ownProps.match.params.ids),
        idContract: ownProps.match.params.id,
        idAccident: ownProps.match.params.ids,
        uri_contract: contractDefaultGetLink(state),
        uri_accident: accidentGetLink(state),
        title: loaded ? `${contract.souscripteur.titre} ${contract.souscripteur.nom} ${contract.souscripteur.id} - Sinistre n°${accident.numCbt} - ${accident.marqueVehicule} ${accident.modeleVehicule}` : '',
        categorie: selector(state, 'categorie'),
        type: selector(state, 'type'),
        paniere: selector(state, 'paniere'),
        titre: selector(state, 'titre'),
        initialValues: {
            'date_rappel': moment()
        },
        nameConseiller: getBelairName(state),
        roles: getUserRoles(state),
        activityType: 'accident',
        isAuthorizedToCreate: true
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getContract: (query) => getContract({query}),
    getAccident: ({query, id}) => getAccident({query, id}),
    submitPostAddActivity: ({form, query}) => postAddActivity({form, query}),
    onChangeValue: (name, file) => change('addActivityForm', name, file),
    unsetValue: (field) => clearFields('addActivityForm', false, false, field),
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {submitPostAddActivity, getAccident, getContract} = dispatchProps
    const {url, uri_accident, idContract, idAccident, uri_contract} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => {
            getAccident({
                query: _.replace(_.replace(uri_accident, '{id}', idContract), '{ids}', idAccident),
                id: idAccident
            })
            getContract(_.replace(uri_contract, '{id}', idContract))
        },
        onSubmit: (form) => submitPostAddActivity({form, query: url})
    }
}
const AddActivityAccidentContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'addActivityForm',
        validate
    }),
    loader({ErrorIndicator: ErrorHttpComponent})
)(AddActivity)

export default AddActivityAccidentContainer
