import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { postResendMissionBca, POST_RESEND_MISSION_BCA } from '../../../../../../redux/actions/app/bca/bca.actions';
import { postBcaResendLink } from '../../../../../../redux/selectors/links/links.selectors';
import { getErrorEntity, getLoadingEntity } from '../../../../../../redux/selectors/ui/ui.selectors';
import ErrorHttpComponent from '../../../../../Commun/Error/ErrorHttpComponent';
import loader from '../../../../../Commun/Spinner/Loader/Loader';
import BcaErr from './BcaErr';

const mapStateToProps = (state, ownProps) => {
    return {
        task: ownProps.task,
        uri: postBcaResendLink(state),
        terminateQuery: ownProps.terminateTask,
        loading: getLoadingEntity(state, POST_RESEND_MISSION_BCA),
        error: getErrorEntity(state, POST_RESEND_MISSION_BCA),
        isReassign:
            getErrorEntity(state, POST_RESEND_MISSION_BCA) === false &&
            getLoadingEntity(state, POST_RESEND_MISSION_BCA) === false,
    };
};

const mapDispatchToProps = {
    postResendMissionBca,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { uri, terminateQuery, task } = stateProps;
    const { postResendMissionBca } = dispatchProps;

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        postResendMissionBca: () => postResendMissionBca({
            query: _.replace(_.replace(uri, '{contract_id}', task.contract?.id), '{accident_id}', task.sinistre?.id),
            task_id: task.id,
            terminateQuery,
        })
    };
};

const BcaErrContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ ErrorIndicator: ErrorHttpComponent }),
)(BcaErr);

export default BcaErrContainer;
