export const TASKS = '[Tasks]'
export const TASK = '[Task]'
export const TASK_FEEDBACK = '[Task Feedback]'

export const GET_TASKS = `${TASKS} Get`
export const SET_TASKS = `${TASKS} Set`
export const POST_CLOSE_TASKS = `${TASKS} Post`
export const POST_REASSIGN_TASKS = `${TASKS} Post Reassign`
export const GET_NB_TASKS = `${TASKS} Nb Get`
export const POST_SHIFT_DATE_TASKS = `${TASKS} Post Shift Date`
export const POST_MANAGE_TRACFIN_TASK = `${TASK} Manage Tracfin`
export const POST_ANALYSE_FRAUDE_TASK = `${TASK} Analyse Fraude`
export const POST_FLUX_MAIL = `${TASK} Flux Mail`;
export const POST_UPDATE_INSATISFACTION_REASON_TASK_FEEDBACK = `${TASK_FEEDBACK} Post Insatisfaction reason feedback`

export const GET_TASK = `${TASK} Get`
export const SET_TASK = `${TASK} Set`
export const CLOSE_TASK = `${TASK} Close`
export const REASSIGN_TASK = `${TASK} Reassign`
export const SHIFT_DATE_TASK = `${TASK} Shift Date`
export const MANAGE_TRACFIN_TASK = `${TASK} Manage Tracfin`


export const getTasks = ({
                             query,
                             sort = localStorage.getItem('sort') || 'date',
                             courtier = localStorage.getItem('courtier') || '',
                             contrat_situation = localStorage.getItem('contrat_situation') || '',
                             portefeuille = localStorage.getItem('portefeuille') || '',
                             paniere = localStorage.getItem('paniere') || '',
                             type_agenda = localStorage.getItem('type_agenda') || '',
                             date_start = localStorage.getItem('date_start_create_contract') || '',
                             date_end = localStorage.getItem('date_end_create_contract') || '',
                             tiers = '',
                             agendas = localStorage.getItem('agendas') || '',
                             multiple = localStorage.getItem('multiple') || '',
                             page = '1',
                             date_start_create_popup_range = localStorage.getItem('date_start_create_popup_range') || '',
                             date_end_create_popup_range = localStorage.getItem('date_end_create_popup_range') || '',
                             date_create_task = localStorage.getItem('date_create_task') || '',
                             produit = localStorage.getItem('produit') || '',
                             resil_date = localStorage.getItem('resil_date') || ''
                         }) => ({
    type: GET_TASKS,
    payload: {
        data: query,
        sort,
        page,
        filters: {
            courtier,
            contrat_situation,
            portefeuille,
            paniere,
            type_agenda,
            date_start,
            date_end,
            tiers,
            agendas,
            multiple,
            date_start_create_popup_range,
            date_end_create_popup_range,
            date_create_task,
            produit,
            resil_date
        }
    }
})

export const setTasks = ({data, meta, links}) => ({
    type: SET_TASKS,
    payload: {data, meta, links}
})

export const getTask = ({query}) => ({
    type: GET_TASK,
    payload: {data: query}
})

export const setTask = ({data}) => ({
    type: SET_TASK,
    payload: {data}
})

export const postCloseTask = ({query}) => ({
    type: POST_CLOSE_TASKS,
    payload: {
        data: query
    }
})

export const setCloseTask = ({state}) => ({
    type: CLOSE_TASK,
    payload: state
})

export const postReassignTask = ({paniere, contrat, sinistre, keep_old, query}) => ({
    type: POST_REASSIGN_TASKS,
    payload: {
        data: query,
        body: {paniere, contrat, sinistre, keep_old}
    }
})

export const setReassignTask = ({state}) => ({
    type: REASSIGN_TASK,
    payload: state
})

export const getNbTasks = ({query}) => ({
    type: GET_NB_TASKS,
    payload: {
        data: query
    }
})

export const postShiftDateTask = ({query, form}) => ({
    type: POST_SHIFT_DATE_TASKS,
    payload: {
        data: query,
        body: form
    }
})

export const postUpdateInsatisfactionReasonFeedbackTask = ({query, queryCloseTask, form}) => ({
    type: POST_UPDATE_INSATISFACTION_REASON_TASK_FEEDBACK,
    payload: {
        data: query,
        body: form,
        otherData: {
            queryCloseTask
        }
    }
})


export const postManageTracfinTask = ({query, form, task_id, terminateQuery}) => ({
    type: POST_MANAGE_TRACFIN_TASK,
    payload: {
        data: query,
        body: form,
        otherData: {
            terminate: terminateQuery,
            task_id
        }
    }
})

export const postAnalyseFraudeTask = ({query, form, task_id, terminateQuery}) => ({
    type: POST_ANALYSE_FRAUDE_TASK,
    payload: {
        data: query,
        body: form,
        otherData: {
            terminate: terminateQuery,
            task_id
        }
    }
})

export const postTaskFluxMail = ({query, form, isTerminate}) => ({
    type: POST_FLUX_MAIL,
    payload: {
        data: query,
        body: form,
        otherData: {
            terminate: isTerminate,
        }
    }
})
