export const GARAGE = '[Garage]'
export const GET_GARAGE = `${GARAGE} Get`
export const SET_GARAGE = `${GARAGE} Set`

export const getGarage = ({query}) => ({
    type: GET_GARAGE,
    payload: {
        data: query
    }
})

export const setGarage = ({garage}) => ({
    type: SET_GARAGE,
    payload: {
        data: garage
    }
})