import {LOG_ACTION} from "../../../actions/core/logs/logs.actions";
import {apiRequest} from "../../../actions/core/api/api.actions";
import {API_URL} from "../../../../constants/constants";

export const logsMiddleware = ({dispatch}) => next => action => {
    next(action)
    if (action.type.includes(LOG_ACTION)) {
        const {data} = action.payload

        let dataForm = new FormData()
        dataForm.set('type', data.type)
        dataForm.set('contrat', data.contrat)
        dataForm.set('sinistre', data.sinistre)
        dataForm.set('tiers', data.tiers)
        dataForm.set('quittance', data.quittance)
        dataForm.set('task', data.task)
        dataForm.set('document_code', data.document_code)
        dataForm.set('paniere', data.paniere)

        dispatch(apiRequest({
            body: dataForm,
            method: 'POST',
            url: API_URL + '/user/log',
            entity: 'LOG_ACTIVITY_CONSEILLER'
        }))
    }
}
