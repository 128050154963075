import {connect} from 'react-redux'
import PrivateRoute from './PrivateRoute'
import {compose} from 'redux'
import {getUser, USER} from '../../../redux/actions/app/user/user.actions'

import {localStorageAMO} from '../../../redux/selectors/localStorage/localStorage'
import {getLogin, getLoadingEntity, getErrorEntity, getLoading} from '../../../redux/selectors/ui/ui.selectors'
import {userGetLink} from "../../../redux/selectors/links/links.selectors";
import loader from "../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {

    return {
        login: getLogin(state),
        fail: getErrorEntity(state, USER),
        urlUser: userGetLink(state),
        token: localStorageAMO && localStorageAMO.token ? localStorageAMO.token : null,
        loaded: getLoading(state) !== undefined && !getLoadingEntity(state, USER)
    }
}

const mapDispatchToProps = {
    getUser: (query) => getUser({query})
}

const PrivateRouteContainer = compose(
    connect(mapStateToProps, mapDispatchToProps),
    loader()
)(PrivateRoute)

export default PrivateRouteContainer
