import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import ActivityTransfer from "./ActivityTransfer";
import {formValueSelector, reduxForm} from "redux-form";
import validate from "../ShiftDateTask/validate";
import _ from "lodash";
import {postTransferActivity, TRANSFER_ACTIVITY} from "../../../../../redux/actions/app/activity/activity.actions";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {transferActivityGetLink} from "../../../../../redux/selectors/links/links.selectors";

const mapStateToProps = state => {
    const selector = formValueSelector('activity-transfer')

    return {
        loading: getLoadingEntity(state, TRANSFER_ACTIVITY),
        uri: transferActivityGetLink(state),
        type: selector(state, 'type')
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    postTransferActivity,
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {postTransferActivity} = dispatchProps
    const {uri} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form) => {
            postTransferActivity({
                query: _.replace(_.replace(uri, '{tiers_id}', ownProps.match.params.tiers_id), '{ordre_id}', ownProps.match.params.id),
                form: form
            })
        }
    }
}

const ActivityTransferContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'activity-transfer',
        validate
    })
)(ActivityTransfer)


export default ActivityTransferContainer
