const validate = values => {
    const errors = {};

    // Valider le champ theme
    if (!values.theme) {
        errors.theme = 'Veuillez sélectionner un thème';
    }

    // Valider le champ description
    if (!values.description) {
        errors.description = 'Ce champ est obligatoire';
    }

    // Valider le champ motif (si informations est vide)
    if (!values.informations && (!values.motif || values.motif === '')) {
        errors.motif = 'Ce champ est obligatoire';
    }

    // Valider le champ montant (si informations est vide et montant_libre est faux and there are options available)
    if (!values.informations && !values.montant_libre && (!values.montant || values.montant === '')) {
        const hasMontantOptions = values.montants && values.montants.length > 0; // Replace 'montants' with the actual field name for your montant options
        if (hasMontantOptions) {
            errors.montant = 'Ce champ est obligatoire';
        }
    }

    return errors;
};

export default validate;
