import {SET_NAVIGATION_CONTRACT} from "../../actions/app/navigation_contract/navigation_contract.actions";

export const navigationContractsReducer = (activities = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_NAVIGATION_CONTRACT) {
        return payload
    } else {
        return activities
    }
}
