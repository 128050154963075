import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import TransfertWallet from "./TransfertWallet";
import {change, formValueSelector, reduxForm} from "redux-form";
import validate from './validate'
import ErrorHttpComponent from "../../../../Commun/Error/ErrorHttpComponent";
import _ from "lodash";
import {getTiers, TIERS} from "../../../../../redux/actions/app/tiers/tiers.actions";
import {getErrorEntity, getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {
    contractsTiersGetLink,
    tiersGetLink, userWalletTransfertLink
} from "../../../../../redux/selectors/links/links.selectors";
import {getTiersData} from "../../../../../redux/selectors/tiers/tiers.selectors";
import {postTransfert, TRANSFERT} from "../../../../../redux/actions/app/wallet/wallet.actions";
import loader from "../../../../Commun/Spinner/Loader/Loader";
import {getContractsItemsData} from "../../../../../redux/selectors/contracts/contracts.selectors";
import {CONTRACTS, getContracts} from "../../../../../redux/actions/app/contracts/contracts.actions";
import moment from "moment";

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector('transfert-wallet')

    return {
        loading: getLoadingEntity(state, TRANSFERT),
        loadingContracts: getLoadingEntity(state, CONTRACTS),
        url: userWalletTransfertLink(state),
        uri_tiers: tiersGetLink(state),
        idTiers: ownProps.match.params.id,
        loaded: getLoadingEntity(state, TIERS) === false,
        tiers: getTiersData(state),
        type_transfert: selector(state, 'type_transfert'),
        contracts: getContractsItemsData(state),
        uri_contracts: contractsTiersGetLink(state),
        error: getErrorEntity(state, CONTRACTS),
        selected_contracts: selector(state, 'selected_contracts'),
        nouvel_apporteur: selector(state, 'nouvel_apporteur'),
        initialValues: {
            selected_contracts: [],
            date_transfert: moment()
        }
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    getContracts: (query) => getContracts({query}),
    getTiers: ({query, id}) => getTiers({query, id}),
    changeValue: (field, value) => change('transfert-wallet', field, value),
    postTransfert,
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {postTransfert, getTiers, getContracts} = dispatchProps
    const {url, uri_tiers, idTiers, uri_contracts} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => {
            getTiers({query: _.replace(uri_tiers, '{id}', idTiers), id: idTiers})
            getContracts((_.replace(uri_contracts, '{id}', idTiers)))
        },
        onSubmit: form => postTransfert({form, query: _.replace(url, '{id}', idTiers)})
    }
}


const TransfertWalletContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'transfert-wallet',
        validate
    }),
    loader({ErrorIndicator: ErrorHttpComponent})
)(TransfertWallet)


export default TransfertWalletContainer
