import React, {useEffect} from 'react';
import {Field} from "redux-form";
import Spinner from "../../../../Commun/Spinner/Spinner";
import vetuste from "../../../../../utils/vetuste";
import moment from "moment";
import SelectFieldRedux from "../../../../Commun/Input/Select/SelectFieldRedux";
import DatePickerFieldRedux from "../../../../Commun/Input/DatePicker/DatePickerFieldRedux";
import TextFieldRedux from "../../../../Commun/Input/Text/TextFieldRedux";
import _ from "lodash";


const EditAccessoires = (props) => {
    const {handleSubmit, loading, change, accident, valeurAchat, explication, palier, dateAchat, accessoire} = props
    const arrayAccessoires = ['casque', 'gants', 'blouson', 'bottes', 'top_case', 'tablier', 'combinaison', 'echappement', 'top_bloc', 'autres']

    const calculVetuste = (index) => {
        const result = vetuste('accessoire', moment(accident.dateSurv), moment(dateAchat), valeurAchat)

        change(`accessoires[${index}][montant]`, result.valeurRemboursement)
        change(`accessoires[${index}][palier]`, result.palier)
        change(`accessoires[${index}][explication]`, result.explication)
        change(`accessoires[${index}][niveau_vetuste]`, result.niveau_vetuste)
    }

    const getNameAccessoires = (libelle) => {
        switch (libelle) {
            case 'reparation':
                return 'Réparation'
            case 'antivol':
                return 'Antivol SRA'
            case 'airbag':
                return 'Gilet Airbag'
            case 'casque':
                return 'Casque'
            case 'gants':
                return 'Gants'
            case 'blouson':
                return 'Blouson'
            case 'bottes':
                return 'Bottes'
            case 'top_case':
                return 'Top Case'
            case 'tablier':
                return 'Tablier'
            case 'combinaison':
                return 'Combinaison'
            case 'echappement':
                return 'Pot d\'échappement'
            case 'top_bloc':
                return 'Top Bloc'
            default:
                return libelle
        }
    }

    useEffect(() => {
        change('accessoires[0].id', accessoire.id)
        change('accessoires[0].libelle', accessoire.libelle)
        change('accessoires[0].dateAchat', moment(accessoire.date_achat))
        change('accessoires[0].valeurAchat', String(accessoire.valeur_achat).replace(',', '.'))
        change('accessoires[0].montant', String(accessoire.montant).replace(',', '.'))
    }, [])


    return (
        <div className={'contract-container container'}>
            <div className={'row header-row'}>
                <div className={'col-12'}>
                    <form onSubmit={handleSubmit} className={'form-content'}>
                        <div className="row">
                            <div className="col">
                                <div>
                                    <div>
                                        <div className="mb-3">
                                            <span
                                                className={'mr-2'}>Remboursement - {getNameAccessoires(accessoire.libelle)}</span>
                                        </div>

                                        {(_.includes(arrayAccessoires, accessoire.libelle) || _.startsWith(accessoire.libelle, 'Autres : ')) &&
                                            <>
                                                <Field
                                                    name={`accessoires[0].libelle`}
                                                    component={SelectFieldRedux}
                                                    label="Sélectionner les accessoires et équipements"
                                                >
                                                    <option value={''}>Sélectionner les accessoires et équipements
                                                    </option>
                                                    <option value={'casque'}>Casque</option>
                                                    <option value={'gants'}>Gants</option>
                                                    <option value={'blouson'}>Blouson</option>
                                                    <option value={'bottes'}>Bottes</option>
                                                    <option value={'autres'}>Autre équipement</option>
                                                </Field>
                                                <Field
                                                    name={`accessoires[0].dateAchat`}
                                                    component={DatePickerFieldRedux}
                                                    label={'Date d\'achat'}
                                                    maxDate={moment()}
                                                />

                                                <Field
                                                    name={`accessoires[0].valeurAchat`}
                                                    component={TextFieldRedux}
                                                    label={'Valeur d\'achat'}
                                                    type={'number'}
                                                />

                                                <button
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        calculVetuste(0)
                                                    }}
                                                    className={'btn btn-primary btn-small mb-3'}
                                                    disabled={!valeurAchat}
                                                >
                                                    <u>Calculer la vétusté</u>
                                                </button>
                                            </>
                                        }

                                        <Field
                                            name={`accessoires[0].montant`}
                                            component={TextFieldRedux}
                                            label={'Valeur de la facture'}
                                            type={'number'}
                                        />

                                        {
                                            explication && <div>
                                                <p>Durée de vétusté : <b>{palier}</b></p>
                                                <p>Explication : <b>{explication}</b></p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            loading ?
                                <Spinner style={{display: 'block', margin: 'auto'}}/> :
                                <button className={'btn btn-primary d-block mt-5'}
                                        data-test-id="avenant_simple_btn_submit" type={'submit'}>
                                    Editer
                                </button>
                        }
                    </form>
                </div>
            </div>
        </div>
    );
};


EditAccessoires.defaultProps = {};


EditAccessoires.propTypes = {};


export default EditAccessoires;
