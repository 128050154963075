import {SET_REGLEMENT_DETAIL} from "../../actions/app/reglement_detail/reglement_detail.actions";

export const reglementDetailReducer = (reglement = false, action = {}) => {
    const {payload} = action

    if (action.type === SET_REGLEMENT_DETAIL) {
        return payload
    } else {
        return reglement
    }
}
