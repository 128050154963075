import {connect} from 'react-redux'
import {compose} from 'redux'

import Tasks from './Tasks'
import ErrorHttpComponent from '../../../../Commun/Error/ErrorHttpComponent'
import {TASKS} from '../../../../../redux/actions/app/tasks/tasks.actions'
import {getErrorEntity, getLoadingEntity} from '../../../../../redux/selectors/ui/ui.selectors'
import {getTasksData} from "../../../../../redux/selectors/tasks/tasks.selectors";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    return {
        tasks: getTasksData(state),
        loaded: getLoadingEntity(state, TASKS) === false,
        error: getErrorEntity(state, TASKS)
    }
}

const TasksContainer = compose(
    connect(mapStateToProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(Tasks)

export default TasksContainer
