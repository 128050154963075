import {Field} from "redux-form";
import TextFieldRedux from "../../../../Commun/Input/Text/TextFieldRedux";
import MyButton from "../../../../Commun/Button/Button";
import Spinner from "../../../../Commun/Spinner/Spinner";
import SelectFieldRedux from "../../../../Commun/Input/Select/SelectFieldRedux";
import TextAreaFieldRedux from "../../../../Commun/Input/Text/TextAreaFieldRedux";
import FileFieldRedux from "../../../../Commun/Input/File/FileFieldRedux";
import PropTypes from "prop-types";
import {natureTlv} from "../../../../../utils/optionsSelect";
import CheckboxFieldRedux from "../../../../Commun/Input/Checkbox/CheckboxFieldRedux";
import {normalizeBooleanForNoobBelair} from "../../../../../utils/normalizeInputs";
import React from "react";

const AddChronoTlv = (props) => {
    const {
        loading,
        handleSubmit,
        onChangeValue,
        title,
        subtitle
    } = props;

    return (
        <div className="contract-container container">
            <div className="row">
                <div className="col-12">
                    <h1 className="title-primary">{title}</h1>
                </div>
                {subtitle && (
                    <div className="col-12">
                        <span className="bold subtitle">{subtitle}</span>
                    </div>
                )}
            </div>

            <div className="row header-row">
                <div className="col-12">
                    <div className="alert alert-info alert-rgpd">
                        <p>Merci de veiller à rester objectif dans votre saisie en évitant tout jugement de valeur,
                            ou collecte d'informations sensibles*. En cas de doute dans vos écrits, n'hésitez pas à
                            consulter votre Manager.
                        </p>
                        <em>* État de santé, origine raciale, appartenance syndicale, opinion politique, opinion religieuse, orientation sexuelle... (cf article 9-1 du RGPD)</em>
                    </div>
                </div>

                <div className="col-12 header-row">
                    <form className="form-content" onSubmit={handleSubmit}>
                        <Field
                            name="type"
                            component={SelectFieldRedux}
                            label="Type de suivi"
                            required
                            disabled
                        >
                            <option value="TELED">TELEDOC</option>
                        </Field>

                        <Field
                            name="nature"
                            component={SelectFieldRedux}
                            label="Nature"
                            required
                        >
                            <option value={null}>Sélectionner la nature</option>
                            {natureTlv.map((type, index) => (
                                <option key={index} value={type.code}>{type.libelle}</option>
                            ))}
                        </Field>

                        <Field
                            name="titre"
                            component={TextFieldRedux}
                            label="Motif"
                            subLabel="50 caractères maximum"
                            maxLength="50"
                            required
                            disabled
                        />

                        <Field
                            name="commentaire"
                            component={TextAreaFieldRedux}
                            label="Description"
                            subLabel="500 caractères maximum"
                            maxLength="500"
                        />
                        <Field
                            name="erreur_sous"
                            component={CheckboxFieldRedux}
                            label="Cela concerne-t-il une erreur de souscription ?"
                            normalize={normalizeBooleanForNoobBelair}
                        />
                        <Field
                            name="files"
                            component={FileFieldRedux}
                            label="Fichier lié"
                            onChangeValue={onChangeValue}
                        />

                        <MyButton
                            variant="raised"
                            color="secondary"
                            size="large"
                            className="btn btn-primary"
                            disabled={loading}
                        >
                            {loading && <Spinner absolute />}
                            Ajout du suivi
                        </MyButton>
                    </form>
                </div>
            </div>
        </div>
    );
};

AddChronoTlv.defaultProps = {
    subtitle: '',
    situation: '',
    clotureReclamation: false,
    categorie: ''
};

AddChronoTlv.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    situation: PropTypes.string,
    clotureReclamation: PropTypes.bool,
    loading: PropTypes.bool,
    categorie: PropTypes.string,
    nameConseiller: PropTypes.string.isRequired,
    onChangeValue: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isAuthorizedToCreate: PropTypes.bool.isRequired
};

export default AddChronoTlv;
