import React, {Fragment} from 'react'
import {formatEuro} from "../../../utils/function";

const StatsCard = ({obj, montant}) => {
    return (
        <Fragment>
            {
                Object.keys(obj).map((key, i) => (
                    <td key={i}>
                        <span>{montant ? formatEuro(obj[key]) : obj[key]}</span>
                    </td>
                ))
            }</Fragment>
    )
}

export default StatsCard
