import React from 'react';
import _ from "lodash";
import DetailsAntecedentsBlocContainer from "./DetailsAntecedentsBloc/DetailsAntecedentsBlocContainer";
import {redirectInNewWindow} from "../../../../../utils/function";
import {listMailJet, openInWindow} from "../../../../../constants/constants";

const DetailsAntecedents = (props) => {
    const {antecedents, documents, contract} = props
    const antecedentsMoto = antecedents.antecedentType1 === 'MOTO' ? antecedents?.antecedentAssurance1 : antecedents?.antecedentAssurance2;
    const antecedentsAuto = antecedents.antecedentType1 === 'MOTO' ? antecedents?.antecedentAssurance2 : antecedents?.antecedentAssurance1;

    return (
        <div className={'bloc-info bloc-details-antecedents'}>
            <h2>Détails des antécédents</h2>
            {antecedentsMoto > 0 &&
                <DetailsAntecedentsBlocContainer
                    type='moto'
                    antecedentDeclare={antecedentsMoto}
                    antecedentsManquants={documents && !_.find(documents, {'code': 'C', 'traite': true})}
                    contract={contract}
                />
            }
            {antecedentsAuto > 0 &&
                <DetailsAntecedentsBlocContainer
                    type='auto'
                    antecedentDeclare={antecedentsAuto}
                    antecedentsManquants={documents && !_.find(documents, {'code': 'D', 'traite': true})}
                    contract={contract}
                />
            }
        </div>
    );
};

export default DetailsAntecedents;