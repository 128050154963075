import React from 'react'
import moment from "moment";

const LoiHamonLR = (props) => {
    const {task: {date, type, paniere, tiers_id}} = props

    return (
        <div className={'container'}>
            <div className={'row'}>
                <div className={'col text-center'}>
                    <h2 className={'title-primary text-uppercase'}>Tâche {paniere} - Tiers n°{tiers_id}</h2>
                    <p className={'subtitle'}>Tâche du {moment(date).format('L')}. Recu par {type}</p>
                </div>
            </div>
            <div className={'header-row row'}>
                <div className={'col-12'}>
                    <p className={'form-content text-center'}>
                        Si à la souscription l'assuré a renseigné son assureur actuel, vous pouvez télécharger le
                        document "Lettre recommandée Hamon" et le glisser dans le répertoire pour envoi via Neopost<br/><br/>
                        Si à la souscription, l'assuré a renseigné "Autres assureurs", merci d'appeler le client ou le
                        courtier pour obtenir l'information et envoyer la lettre recommandée.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default LoiHamonLR
