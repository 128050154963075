import React, {Component} from 'react';
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'

class ErrorBoundary extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: false
        }
    }

    componentDidCatch(error, infos) {
        this.setState({error});
        Sentry.configureScope(scope => {
            Object.keys(infos).forEach(key => {
                scope.setExtra(key, infos[key]);
            });
        });
        Sentry.captureException(error);
    }

    render() {

        if (this.state.error) {
            return (
                <div className={'card-faq'}>
                    <p>Une erreur technique a été isolée et le reste de l'application ne devrait pas être impacté.</p>
                    <p>Nos équipes techniques ont été averties.</p>
                    <p>Merci de réessayer ultérieurement.</p>
                </div>
            )
        }

        const {children} = this.props
        return children

    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.element
}

export default ErrorBoundary
