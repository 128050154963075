import { useState } from 'react';
import AddDocumentContainer from "../../Forms/AddDocument/AddDocumentContainer";
import './documents.scss';
import DocumentItemContainer from "./DocumentItem/DocumentItemContainer";
import { redirectInNewWindow } from "../../../../../utils/function";
import { listMailJet, openInWindow } from "../../../../../constants/constants";
import _ from "lodash";
import DocumentItemLight from "./DocumentItem/DocumentItemLight";

const Documents = ({ documents, contractId, tiersId, risqueNum, type = "sinistre", logAction, idSinistre, roles }) => {
    const [addDoc, setAddDoc] = useState(false);

    const handleAddDoc = () => {
        setAddDoc(!addDoc);
    };

    return (
        <div className={'bloc-info bloc-documents'}>
            <h2>Base documentaire</h2>
            {!_.includes(roles, 'gestionnaire televente') && (
                <div className={'call-to-action-bloc-info'}>
                    <span className={'btn btn-cta btn-email float-right'} onClick={(e) => redirectInNewWindow({
                        e,
                        url: `/envoyer-email/pmq/${type !== 'sinistre' ? listMailJet.pmq : listMailJet.pmqAccident}/${tiersId}/${contractId}${idSinistre ? `/${idSinistre}` : ''}`,
                        name: openInWindow.email
                    })}>
                        Envoyer par email
                    </span>
                </div>
            )}
            <div className={'content row'}>
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">Document</th>
                        <th scope="col">Commentaire</th>
                        <th scope="col">Date de création</th>
                        <th scope="col" className={'text-center'}>Recu</th>
                        <th scope="col" className={'text-center'}>Traité</th>
                        <th scope="col">Détails</th>
                        <th scope="col">&nbsp;</th>
                    </tr>
                    </thead>
                    <tbody>
                    {documents.map((document, index) => (
                        !_.includes(roles, 'gestionnaire televente') ? (
                            <DocumentItemContainer
                                roles={roles}
                                logAction={logAction}
                                document={document}
                                key={index}
                                form={`document-${document.code}-${document.id_sinistre}`}
                                type={type}
                            />
                        ) : (
                            <DocumentItemLight
                                logAction={logAction}
                                document={document}
                                key={index}
                                form={`document-${document.code}-${document.id_sinistre}`}
                                type={type}
                            />
                        )
                    ))}
                    </tbody>
                </table>
                {!_.includes(roles, 'gestionnaire televente') && (
                    <div className={'col-12'}>
                        {addDoc ?
                            <AddDocumentContainer
                                risqueNum={risqueNum}
                                contractId={contractId}
                                tiersId={tiersId}
                                type={type}
                                sinistre={idSinistre}
                            />
                         : <button className={'no-background'} onClick={handleAddDoc}>+ Ajouter un document attendu</button>
                        }
                    </div>
                )}
            </div>
        </div>
    );
};

export default Documents;
