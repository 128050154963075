import {
    DELETE_DOCUMENT,
    DELETE_TASK_DOCUMENT,
    DOCUMENTS,
    GET_DOCUMENTS, POST_DELETE_DOCUMENT,
    POST_DELETE_TASK_DOCUMENT,
    PUT_DOCUMENTS,
    setDocuments
} from '../../../actions/app/documents/documents.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'

import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {reset} from "redux-form";

export const documentsMiddleware = () => next => action => {
    next(action)
    let message = null

    switch (action.type) {

        case GET_DOCUMENTS:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: DOCUMENTS}),
                setLoader({state: true, entity: DOCUMENTS})
            ])
            break

        case `${DOCUMENTS} ${API_SUCCESS}`:
            const {data} = action.payload.data

            next([
                setDocuments({data}),
                setError({state: false, entity: DOCUMENTS}),
                setLoader({state: false, entity: DOCUMENTS})
            ])
            break

        case `${DOCUMENTS} ${API_ERROR}`:
            next([
                setError({state: true, entity: DOCUMENTS}),
                setLoader({state: false, entity: DOCUMENTS})
            ])
            break

        case PUT_DOCUMENTS:
            next([
                apiRequest({
                    body: (action.payload.body),
                    method: 'POST',
                    url: action.payload.data,
                    entity: PUT_DOCUMENTS,
                    otherData: action.payload.formName
                }),
                setLoader({state: true, entity: PUT_DOCUMENTS})
            ])
            break

        case `${PUT_DOCUMENTS} ${API_SUCCESS}`:
            next([
                setError({state: false, entity: PUT_DOCUMENTS}),
                setLoader({state: false, entity: PUT_DOCUMENTS})
            ])
            break

        case `${PUT_DOCUMENTS} ${API_ERROR}`:
            message = `
                <p>Erreur lors de la modification du document, les modifications ne seront pas prises en compte.</p>
                <p>${action.payload.data.response.data?.message ?? ''}</p>
            `

            next([
                setNotification({
                    entity: PUT_DOCUMENTS,
                    body: message,
                    title: 'Attention !',
                    type: 'error',
                    timeout: '0'
                }),
                reset(action.payload.meta.otherData),
                setError({state: true, entity: PUT_DOCUMENTS}),
                setLoader({state: false, entity: PUT_DOCUMENTS})
            ])
            break

        case POST_DELETE_TASK_DOCUMENT:
            next([
                apiRequest({
                    body: (action.payload.body),
                    method: 'POST',
                    url: action.payload.data,
                    entity: DELETE_TASK_DOCUMENT
                }),
                setLoader({state: true, entity: DELETE_TASK_DOCUMENT})
            ])
            break

        case `${DELETE_TASK_DOCUMENT} ${API_SUCCESS}`:
            message = `<p>Le document a été supprimé avec succès</p>`

            next([
                setNotification({
                    entity: DELETE_TASK_DOCUMENT,
                    body: message,
                    type: 'success',
                    title: 'Document Supprimé!'
                }),
                setLoader({state: false, entity: DELETE_TASK_DOCUMENT}),
                setError({state: false, entity: DELETE_TASK_DOCUMENT}),
            ])
            break

        case `${DELETE_TASK_DOCUMENT} ${API_ERROR}`:
            message = `<p>Erreur lors de la suppression du document: ${action.payload.data.response.data?.message ?? ''}</p>`
            next([
                setNotification({
                    entity: DELETE_TASK_DOCUMENT,
                    body: message,
                    type: 'error',
                    title: 'Attention !',
                    timeout: '0'
                }),
                setError({state: true, entity: DELETE_TASK_DOCUMENT}),
                setLoader({state: false, entity: DELETE_TASK_DOCUMENT})
            ])
            break

        case POST_DELETE_DOCUMENT:
            next([
                apiRequest({
                    body: (action.payload.body),
                    method: 'POST',
                    url: action.payload.data,
                    entity: DELETE_DOCUMENT
                }),
                setLoader({state: true, entity: DELETE_DOCUMENT})
            ])
            break

        case `${DELETE_DOCUMENT} ${API_SUCCESS}`:
            message = `<p>Le document a été supprimé avec succès</p>`

            next([
                setNotification({entity: DELETE_DOCUMENT, body: message, type: 'success', title: 'Document Supprimé!'}),
                setLoader({state: false, entity: DELETE_DOCUMENT}),
                setError({state: false, entity: DELETE_DOCUMENT}),
            ])
            break

        case `${DELETE_DOCUMENT} ${API_ERROR}`:
            message = `<p>Erreur lors de la suppression du document: ${action.payload.data.response.data?.message ?? ''}</p>`
            next([
                setNotification({
                    entity: DELETE_DOCUMENT,
                    body: message,
                    type: 'error',
                    title: 'Attention !',
                    timeout: '0'
                }),
                setError({state: true, entity: DELETE_DOCUMENT}),
                setLoader({state: false, entity: DELETE_DOCUMENT})
            ])
            break

        default:
            break
    }
    return null
}
