import { Field } from 'redux-form';
import { useIntervenants } from '../../../../../api/pass';
import { normalizeAndUppercase } from '../../../../../utils/normalizeInputs';
import SelectFieldRedux from '../../../../Commun/Input/Select/SelectFieldRedux';
import TextFieldRedux from '../../../../Commun/Input/Text/TextFieldRedux';
import Spinner from '../../../../Commun/Spinner/Spinner';

const AddIntervenant = (props) => {
    const { handleSubmit, loading, edit, changeValue, formValues } = props;

    const isSelected = formValues?.intervenant_id && formValues?.intervenant;
    const { data: dataIntervenants, isLoading } = useIntervenants(formValues?.type, formValues?.recherche_intervenant);

    return (
        <div className={'contract-container container'}>
            <div className={'row return-content'}>
                <div className={'col'}>
                    <button onClick={() => props.history.go(-1)} className={'btn btn-return'}>
                        Retour
                    </button>
                </div>
            </div>
            <div className={'row mb-4'}>
                <div className={'col-12'}>
                    <h1 className={'title-primary'}>Ajout d'un intervenant</h1>
                </div>
            </div>
            <form onSubmit={handleSubmit} className={'form-content'}>
                <Field
                    name="type"
                    component={SelectFieldRedux}
                    label={'Quel intervenant souhaitez-vous ajouter ?'}
                    required
                    onChange={(e) => {
                        changeValue('type', e.target.value);
                        changeValue('intervenant_id', null);
                        changeValue('intervenant', null);
                    }}
                >
                    <option value={''} disabled>
                        Choisir un type
                    </option>
                    <option value={'9'}>Une société financière</option>
                    <option value={'V'}>Un bénéficaire</option>
                </Field>

                <div className="row mb-2">
                    <div className="col">
                        <Field
                            classNameGroup="mb-0"
                            name="recherche_intervenant"
                            component={TextFieldRedux}
                            // label={'Chercher une société de financement ou opposition financière'}
                            label={
                                formValues?.type === '9'
                                    ? 'Chercher une société de financement ou opposition financière'
                                    : 'Chercher un bénéficiaire'
                            }
                            normalize={normalizeAndUppercase}
                            onChange={() => {
                                changeValue('intervenant_id', null);
                                changeValue('intervenant', null);
                            }}
                            required
                        />
                        {dataIntervenants?.data.length > 0 && !isSelected ? (
                            dataIntervenants?.data.map((tiers, i) => (
                                <div
                                    key={i}
                                    onClick={() => {
                                        changeValue('intervenant_id', tiers.id);
                                        changeValue('intervenant', tiers.name);
                                    }}
                                    className={'list-group'}
                                >
                                    <p className={'pointer m-0 list-group-item list-group-item-action'}>
                                        {tiers.id} - {tiers.name}
                                    </p>
                                </div>
                            ))
                        ) : isLoading ? (
                            <div className={'list-group'}>
                                <p className={'m-0 list-group-item list-group-item-action'}>Chargement...</p>
                            </div>
                        ) : dataIntervenants?.data.length === 0 ? (
                            <div className={'list-group'}>
                                <p className={'m-0 list-group-item list-group-item-action'}>
                                    Aucun résultat trouvé pour cette recherche
                                </p>
                            </div>
                        ) : null}
                    </div>
                </div>

                {isSelected && (
                    <p className="text-muted">
                        {formValues?.intervenant_id} - {formValues?.intervenant}
                    </p>
                )}

                {loading ? (
                    <Spinner style={{ display: 'block', margin: 'auto' }} />
                ) : (
                    <button className={'btn btn-primary d-block'} type={'submit'}>
                        {edit ? 'Editer le tiers' : 'Ajouter le tiers'}
                    </button>
                )}
            </form>
        </div>
    );
};

export default AddIntervenant;
