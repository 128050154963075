import {SET_STATS} from '../../actions/app/stats_courtier/stats_courtier.actions'

export const statsReducer = (stats = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_STATS) {
        return payload
    } else {
        return stats
    }
}
