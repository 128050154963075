export const SIGNATURES = '[Signatures]'
export const SEND_SIGNATURE = '[Send Signature]'

export const FETCH_SIGNATURES = `${SIGNATURES} Fetch`
export const SET_SIGNATURES = `${SIGNATURES} Set`

export const POST_SEND_SIGNATURE = `${SEND_SIGNATURE} Fetch`
export const SET_SEND_SIGNATURE = `${SEND_SIGNATURE} Set`


export const fetchSignatures = ({query}) => ({
    type: FETCH_SIGNATURES,
    payload: {
        data: query
    }
})

export const setSignatures = ({data}) => ({
    type: SET_SIGNATURES,
    payload: {data},
    meta: {
        entity: SIGNATURES
    }
})

export const postSendSignature = ({query, customer_email, customer_phone}) => ({
    type: POST_SEND_SIGNATURE,
    payload: {
        data: query,
        body: {customer_email, customer_phone},
    }
})

export const setSendSignature = ({state}) => ({
    type: SET_SEND_SIGNATURE,
    payload: state
})
