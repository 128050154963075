import React from 'react';

const Versionning = ({ versionning, submitPostValidateVersionning }) => {
    const handleClick = (id) => {
        submitPostValidateVersionning(id);
    };

    // Liste des champs spécifiques à vérifier
    const specificFields = [
        'DATE_SOUS_MO',
        'DATE_SITUATION_MO',
        'DATE_SITUATION_AU',
        'DATE_SOUS_AU'
    ];

    // Vérifier si toutes les lignes concernées (avec old_value vide) sont présentes
    const shouldHideAll = versionning.every(v =>
        specificFields.includes(v.field) && v.old_value.trim() === ''
    );

    // Filtrer les lignes à afficher
    const filteredVersionning = versionning.filter(v =>
        !specificFields.includes(v.field) || v.old_value.trim() !== ''
    );

    return !shouldHideAll && filteredVersionning.length > 0 ? (
        <div className={'row margin-top'}>
            <div className={'col'}>
                <div className={'bloc-info bloc-documents'}>
                    <h2>Mise à jour des données à effectuer</h2>
                    <div className={'call-to-action-bloc-info'}>
                        <span className={'btn btn-cta btn-version float-right'} onClick={() => handleClick(null)}>
                            J'ai traité les modifications
                        </span>
                    </div>

                    <div className={'content row'}>
                        <table className="table">
                            <thead>
                            <tr>
                                <th scope="col">Champs</th>
                                <th scope="col">Ancienne valeur</th>
                                <th scope="col">Nouvelle valeur</th>
                            </tr>
                            </thead>
                            <tbody>
                            {filteredVersionning.map((v, index) => (
                                <tr key={index}>
                                    <td>{v.field}</td>
                                    <td>{v.old_value}</td>
                                    <td>{v.new_value}</td>
                                    <td>
                                        <button onClick={() => handleClick(v.id)} className={'btn btn-delete-red'} />
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

export default Versionning;
