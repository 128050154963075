import {
    SET_DASHBOARD_TASK_TLV,
    SET_DASHBOARD_TLV,
    SET_DASHBOARD_TLV_GEST
} from "../../actions/app/dashboard_televente/dashboard_televente.actions";

export const dashboardTeleventeReducer = (dashboard = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_DASHBOARD_TLV) {
        return {...dashboard, tasks: payload}
    } else if (action.type === SET_DASHBOARD_TLV_GEST) {
        return {...dashboard, gestionnaires: payload.data}
    } else if (action.type === SET_DASHBOARD_TASK_TLV) {
        return {...dashboard, task: payload.data}
    } else {
        return dashboard
    }
}
