export const COURTIERS = '[Courtiers]'

export const GET_COURTIERS = `${COURTIERS} Get`
export const SET_COURTIERS = `${COURTIERS} Set`

export const getCourtiers = ({query}) => ({
    type: GET_COURTIERS,
    payload: {
        data: query
    }
})

export const setCourtiers = ({courtiers}) => ({
    type: SET_COURTIERS,
    payload: {
        data: courtiers
    }
})