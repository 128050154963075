import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {reduxForm} from 'redux-form'
import ReassignTask from './ReassignTask'
import {taskReassignGetLink} from '../../../../../redux/selectors/links/links.selectors'
import validate from './validate'
import {getLoadingEntity, getErrorEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {postReassignTask, REASSIGN_TASK} from "../../../../../redux/actions/app/tasks/tasks.actions";
import _ from "lodash";
import {logAction} from '../../../../../redux/actions/core/logs/logs.actions'

const mapStateToProps = (state, ownProps) => {
    return {
        loading: getLoadingEntity(state, REASSIGN_TASK),
        is_reassign: getLoadingEntity(state, REASSIGN_TASK) === false && getErrorEntity(state, REASSIGN_TASK) === false,
        uri: taskReassignGetLink(state),
        task: ownProps.location ? ownProps.location.state.task : ownProps.task,
        keep_old: ownProps.location ? ownProps.location.state.keep_old : ownProps.keep_old,
        notTask: ownProps.location !== undefined
    }
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
    postReassignTask: ({paniere, contrat, sinistre, keep_old, query}) => postReassignTask({
        paniere,
        contrat,
        sinistre,
        keep_old,
        query
    }),
    logAction: (paniere) => logAction({
        type: 'CLICK_REASSIGN_TASK',
        task: ownProps.location ? ownProps.location.state.task.id : ownProps.task.id,
        paniere
    })
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {postReassignTask, logAction} = dispatchProps
    const {task, uri, keep_old} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form) => {
            postReassignTask({
                paniere: form.paniere,
                contrat: form.contrat,
                sinistre: form.sinistre,
                keep_old: keep_old,
                query: _.replace(uri, '{id}', task.id)
            })
            logAction(form.paniere)
        },
    }
}
const ReassignTaskContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'reassignTaskForm',
        validate
    })
)(ReassignTask)

export default ReassignTaskContainer
