import {connect} from 'react-redux'
import {compose} from "redux";
import AddTiersPayeur from "./AddTiersPayeur";
import {getTiersPayeurData} from "../../../../../redux/selectors/tiers_payeur/tiers_payeur.selector";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {
    getTiersPayeur,
    POST_UPDATE_THIRD_PARTY_PAYER, postUpdateTiersPayeur,
    THIRD_PARTY_PAYER
} from "../../../../../redux/actions/app/tiers_payeur/tiers_payeur.actions";
import {
    ThirdPartyPayerGetLink,
    updateThirdPartyPayerGetLink
} from "../../../../../redux/selectors/links/links.selectors";
import moment from "moment";
import _ from "lodash";

import {change, formValueSelector, reduxForm} from "redux-form";
import validate from "./validate";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    const tiers_payeur = getTiersPayeurData(state)
    const selector = formValueSelector('edit_tiers_payeur')
    const tiers_payeur_type = tiers_payeur.relation ? 'P' : 'M'

    return {
        type: selector(state, 'type'),
        relationship: selector(state, 'relationship'),
        loaded: getLoadingEntity(state, THIRD_PARTY_PAYER) === false,
        loading: getLoadingEntity(state, POST_UPDATE_THIRD_PARTY_PAYER) === true,
        uri: ThirdPartyPayerGetLink(state),
        uri_update: updateThirdPartyPayerGetLink(state),
        idContract: ownProps.match.params.id,
        idTiersPayeur: ownProps.match.params.ids,
        edit: true,
        initialValues: {
            'type' : tiers_payeur_type,
            'firstname': tiers_payeur.prenom,
            'lastname': tiers_payeur.nom,
            'birthname': tiers_payeur.nom_naissance,
            'birthdate': tiers_payeur.naissance ? moment(tiers_payeur.naissance, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
            'birthcity': tiers_payeur.ville_naissance,
            'relationship': tiers_payeur.relation,
            'third_payeur_reason': tiers_payeur.raison_autre
        }
    }
}

const mapDispatchToProps = {
    getTiersPayeur,
    postUpdateTiersPayeur,
    changeValue: (field, value) => change('edit_tiers_payeur', field, value)
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { uri, uri_update, idContract, idTiersPayeur } = stateProps
    const { getTiersPayeur, postUpdateTiersPayeur } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getTiersPayeur({query: _.replace(uri, '{id}', idContract)}),
        onSubmit: form => postUpdateTiersPayeur({
            query: _.replace(uri_update, '{id}', idContract),
            form
        })
    }
}

const EditTiersPayeurContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader(),
    reduxForm({
        form: 'edit_tiers_payeur',
        validate
    }),
)(AddTiersPayeur)

export default EditTiersPayeurContainer