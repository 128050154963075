export const LOG_ACTION = 'LOG_ACTION'

export const logAction = ({
                              entity,
                              type,
                              description = '',
                              tiers = '',
                              contrat = '',
                              quittance = '',
                              sinistre = '',
                              document_code = '',
                              task = '',
                              paniere = ''
                          }) => ({
    type: `${entity} ${LOG_ACTION}`,
    payload: {
        data: {
            type,
            description,
            tiers,
            contrat,
            quittance,
            sinistre,
            document_code,
            task,
            paniere
        }
    }
})
