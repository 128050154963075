import React from 'react';
import Spinner from "../../../Commun/Spinner/Spinner";

const Logout = (props) => {
    return <div className={'text-center'}>
        Déconnexion en cours...
        <Spinner/>
    </div>;
};

export default Logout;
