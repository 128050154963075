import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {
    GET_REGLEMENT_DETAIL,
    REGLEMENT_DETAIL,
    setReglementDetail
} from "../../../actions/app/reglement_detail/reglement_detail.actions";

export const reglementDetailMiddleware = () => next => action => {
    next(action)

    switch (action.type) {
        case GET_REGLEMENT_DETAIL:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: REGLEMENT_DETAIL}),
                setLoader({state: true, entity: REGLEMENT_DETAIL})
            ])
            break

        case `${REGLEMENT_DETAIL} ${API_SUCCESS}`:

            next([
                setReglementDetail({data: action.payload.data}),
                setError({state: false, entity: REGLEMENT_DETAIL}),
                setLoader({state: false, entity: REGLEMENT_DETAIL})
            ])
            break

        case `${REGLEMENT_DETAIL} ${API_ERROR}`:
            next([
                setError({state: true, entity: REGLEMENT_DETAIL}),
                setLoader({state: false, entity: REGLEMENT_DETAIL})
            ])
            break

        default:
            break
    }
    return null
}
