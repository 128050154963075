import React from 'react'
import moment from "moment";

const Antecedents = ({conducteur}) => {

    return (
        <div className={'bloc-info bloc-antecedents'}>
            <h2>Antécédents</h2>
            <div className={'content row'}>
                <div className={'col-5 left-content'}>
                    <p>Ancienneté Moto :</p>
                    <p>CRM Moto :</p>
                    <p>Ancienneté Auto :</p>
                    <p>CRM Auto :</p>
                    <p>Nombre de sinistres :</p>
                </div>
                <div className={'col-7 right-content'}>
                    <p>{conducteur.nbMoisAssuranceMoto} mois</p>
                    <p>{conducteur.bonusMoto} à la date du {moment(conducteur.createdDate).format('L')}</p>
                    <p>{conducteur.nbMoisAssuranceAuto} mois</p>
                    <p>{conducteur.bonusAuto} à la date du {moment(conducteur.createdDate).format('L')}</p>
                    <p>{conducteur.nbSinistres}</p>
                </div>
            </div>
        </div>
    )
}

export default Antecedents
