import _ from "lodash";

const vetuste = (type, dateSurv, dateAchat, valeurAchat) => {
    const paliers = {
        'accessoire': {
            12: 0.14,
            14: 0.25,
            24: 0.407296,
            48: 0.50212864,
            60: 0.581788058,
            72: 0.648701968,
            84: 0.704909653,
            96: 0.752124109,
            108: 0.791784251,
            120: 0.825098771,
            132: 0.853082968,
            144: 0.876589693,
            156: 0.896335342,
            999: 0.9
        }
    }

    if (!paliers[type]) {
        throw new Error('type introuvable (' + type + ')')
    }

    if (dateAchat.isAfter()) {
        throw new Error('Date de survenance avant la date d\'achat du véhicule')
    }

    const period = dateSurv.diff(dateAchat, 'months', true);

    const keys = _.keys(paliers[type]);

    let item = _.find(keys, function (k) {
        return Number(k) >= period;
    })

    let key
    let index

    if (item === undefined) {
        key = _.last(keys);
    } else {
        index = _.indexOf(keys, item)
        if (index === 0) {
            key = keys[index]
        } else {
            key = keys[index - 1]
        }
    }

    let palier;
    if (key < 12) {
        palier = '< ' + key + ' mois'
    } else if (key === "999") {
        palier = '> ' + (_.findLast(keys, function (k) {
            return k !== "999"
        }) / 12) + ' ans'
    } else if (key === "12") {
        palier = '< 1 an'
    } else {
        palier = '> ' + Math.round(key / 12) + ' ans'
    }

    return {
        valeurRemboursement: (valeurAchat * (1 - paliers[type][key])).toFixed(2),
        explication: Math.round(paliers[type][key] * 100) + '% de décôte',
        palier: palier,
        niveau_vetuste: Math.round(paliers[type][key] * 100)
    };
}

export default vetuste
