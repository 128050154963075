import React from 'react'
import {Link} from "react-router-dom";

const Parrainages = (props) => {
    const {parrainages} = props

    return (
        <div className={'bloc-info bloc-contracts'}>
            <h2>Filleuls</h2>
            <div className={'content row'}>
                {parrainages.data.length >= 1 ?
                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">Email du filleul</th>
                            <th scope="col">Date du mail de parrainage</th>
                            <th scope="col">Date de souscription</th>
                            <th scope="col">Date du passage en cours</th>
                            <th scope="col">&nbsp;</th>
                        </tr>
                        </thead>
                        <tbody>
                        {parrainages.data.map((p) =>
                            <tr key={p.id}>
                                <td>{p.email}</td>
                                <td>{p.date_envoi}</td>
                                <td>{p.date_souscription || '-'}</td>
                                <td>{p.date_en_cours || 'Non valide'}</td>
                                <td>
                                    {p.id_tiers
                                        ? <Link to={{pathname: `/tiers/${p.id_tiers}`}} className={'btn btn-more'}/>
                                        : ``
                                    }
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                    :
                    <p className={'no-result'}>Le tiers n'a pas de filleuls</p>
                }
            </div>
        </div>
    )
}

export default Parrainages
