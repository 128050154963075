import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, getFormValues, reduxForm } from 'redux-form';
import { postCreateTiers, POST_CREATE_TIERS } from '../../../../../redux/actions/app/tiers/tiers.actions';
import { createTiersGetLink } from '../../../../../redux/selectors/links/links.selectors';
import { getLoadingEntity } from '../../../../../redux/selectors/ui/ui.selectors';
import AddTiers from './AddTiers';
import validate from './validate';

const mapStateToProps = (state, ownProps) => {
    return {
        uri: createTiersGetLink(state),
        loading: getLoadingEntity(state, POST_CREATE_TIERS),
        formValues: getFormValues('add_tiers')(state),
        accidentId: ownProps.location?.state?.accident ?? null,
        contractId: ownProps.location?.state?.contract ?? null,
        type: ownProps.location?.state?.type,
    };
};

const mapDispatchToProps = {
    changeValue: (field, value) => change(`add_tiers`, field, value),
    submitPostCreateTiers: ({ form, query }) => postCreateTiers({ form, query }),
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { uri, accidentId, contractId } = stateProps;
    const { submitPostCreateTiers } = dispatchProps;

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        onSubmit: (form) => {
            if (accidentId) {
                form.accident = accidentId;
                form.contract = contractId;
            }
            submitPostCreateTiers({ query: uri, form });
        },
    };
};

const AddTiersContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'add_tiers',
        validate,
    }),
)(AddTiers);

export default AddTiersContainer;
