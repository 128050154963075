export const CHANGE_PERSONAL_INFOS = '[Change Personal Infos]'

export const POST_CHANGE_PERSONAL_INFOS = `${CHANGE_PERSONAL_INFOS} Post`
export const SET_CHANGE_PERSONAL_INFOS = `${CHANGE_PERSONAL_INFOS} Set`

export const postChangePersonalInfos = ({query, form}) => ({
    type: POST_CHANGE_PERSONAL_INFOS,
    payload: {
        data: query,
        body: form,
    }
})

export const setChangePersonalInfos = ({state}) => ({
    type: SET_CHANGE_PERSONAL_INFOS,
    payload: state
})
