import {SET_THIRD_PARTY_PAYER} from "../../actions/app/tiers_payeur/tiers_payeur.actions";

export const tiersPayeurReducer = (tiers_payeur = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_THIRD_PARTY_PAYER) {
        return payload.data
    } else {
        return tiers_payeur
    }
}
