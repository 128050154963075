import React from 'react';
import _ from "lodash";
import {Field} from "redux-form";
import CheckboxFieldRedux from "../../../../../Commun/Input/Checkbox/CheckboxFieldRedux";

const GarantieItem = (props) => {
    const {garantie, handleSubmit, code, franchiseValue, onSubmit, permissions, formName, loaded, isWakam} = props

    return (
        <tr>
            {
                _.includes(permissions, "update accident informations") ?
                    <>
                        <td>
                            <Field
                                component={CheckboxFieldRedux}
                                id={`sinistree-${formName}`}
                                name={'sinistree'}
                                label={`Oui`}
                                type={'checkbox'}
                                disabled={!loaded}
                                onChange={e => onSubmit({
                                    sinistree: e.target.checked,
                                    code: code,
                                    franchise: franchiseValue
                                })}
                            />
                        </td>
                        <td>{garantie.libelle}</td>
                        <td>{garantie.code}</td>
                        <td className={'td-franchise'}>
                            <Field
                                name={"franchise"}
                                component={"textarea"}
                                className={"garanties-input"}
                                disabled={!loaded}
                                onBlur={handleSubmit(values => onSubmit(values))}
                            />
                            <span className={'tooltips'}>Editer la franchise</span>
                        </td>
                        <td>{garantie.plafond}</td>
                        {isWakam &&
                            <>
                                <td className={'td-cabinet'}>
                                    {garantie.sinistree === "OUI" &&
                                        <>
                                            <Field
                                                name={"cabinet"}
                                                component={"textarea"}
                                                className={"cabinet-input d-inline"}
                                                disabled={!loaded}
                                                onBlur={handleSubmit(values => onSubmit(values))}
                                            />
                                            <span className={'tooltips'}>Editer l'évaluation cabinet</span>
                                        </>
                                    }
                                </td>
                                <td className={'td-recours'}>
                                    {garantie.sinistree === "OUI" &&
                                        <>
                                            <Field
                                                name={"recours"}
                                                component={"textarea"}
                                                className={"recours-input"}
                                                disabled={!loaded}
                                                onBlur={handleSubmit(values => onSubmit(values))}
                                            />
                                            <span className={'tooltips'}>Editer l'évaluation recours</span>
                                        </>
                                    }
                                </td>
                            </>
                        }
                    </>
                    :
                    <>
                        <td>{garantie.sinistree}</td>
                        <td>{garantie.libelle}</td>
                        <td>{garantie.code}</td>
                        <td>{garantie.franchise}</td>
                        <td>{garantie.plafond}</td>
                    </>
            }
        </tr>
    );
};

export default GarantieItem;
