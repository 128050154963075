export const LINKS = '[Links]'

export const GET_LINKS = `${LINKS} Get`
export const SET_LINKS = `${LINKS} Set`

export const getLinks = ({query}) => ({
    type: GET_LINKS,
    payload: {
        data: query
    }
})

export const setLinks = ({links}) => ({
    type: SET_LINKS,
    payload: {
        data: links
    }
})
